import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { NOUSER } from 'app/navigation/navigation';
import {AuthService} from '../../../main/auth/services/auth.service';
import {Router} from '@angular/router';
import {FuseNavigationService} from '../../../../@fuse/components/navigation/navigation.service';
import {SyncMsgs} from '../../../../@fuse/services/sync-msgs.service';

@Component({
    selector     : 'toolbar',
    templateUrl  : './toolbar.component.html',
    styleUrls    : ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy
{
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    navigation: any;
    userStatusOptions: any[];
    userProfile: any;
    userMsgs: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param _fuseNavigationService
     * @param authService
     * @param syncMsgs
     * @param _router
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseNavigationService: FuseNavigationService,
        private authService: AuthService,
        private syncMsgs: SyncMsgs,
        private _router: Router
    )
    {
        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon : 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon : 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon : 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon : 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon : 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];

        // Change to default then by rol
        this.navigation = NOUSER;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.authService.authState
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: any) => {
                this.userProfile = user ? user['attributes'] : false;
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {

      this.syncMsgs.onUserNotifyChanged$.subscribe(msgs => {
          this.userMsgs = msgs.length;
      });

      this._fuseNavigationService.onNavigationChanged
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(() => {

          // Load the navigation
          this.navigation = this._fuseNavigationService.getCurrentNavigation();
          console.log('----> Toolbar Redraw');

        });
        // Subscribe to the config changes
      this._fuseConfigService.config
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((settings) => {
            this.horizontalNavbar = settings.layout.navbar.position === 'top';
            this.rightNavbar = settings.layout.navbar.position === 'right';
            this.hiddenNavbar = settings.layout.navbar.hidden === true;
        });

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void
    {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void
    {
        // Do your search here...
        console.log(value);
    }

    userAccount(): void {
        this._router.navigate(['profile/me']);
    }

    // userPrivacy(): void {
    //     this._router.navigate(['privacy/settings']);
    // }

    logOut(): void {
        this.authService.logout().then(() => {
            this._router.navigate(['auth/login']);
        });
    }
}
