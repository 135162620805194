import {Inject, Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {
    APIService,
    elStatus,
    ModelCourseCategoryFilterInput,
    ModelCourseFilterInput, ModelCourseSubsFilterInput,
    ModelSortDirection
} from '../../API.service';
import {ACADEMY_MANAGER_OPTIONS} from './academy.options';
import * as _ from 'lodash';
import {getDeepFromObject} from '../auth/helpers';
import {SyncSettings} from '../../../@fuse/services/sync-settings.service';
import {AuthService} from '../auth/services/auth.service';
import {FuseProgressBarService} from "../../../@fuse/components/progress-bar/progress-bar.service";
import {Logger} from "aws-amplify";

@Injectable()
export class AcademyCoursesService implements Resolve<any>
{

    private logger = new Logger('Course Academy Catolog Service');
    filter: ModelCourseFilterInput;
    nextToken: string = null;
    categoryFilter: string = 'init';

    private onCategoriesChanged = new BehaviorSubject<any>(null);
    onCategoriesChanged$ = this.onCategoriesChanged.asObservable();

    private onCoursesChanged = new BehaviorSubject<any>(null);
    onCoursesChanged$ = this.onCoursesChanged.asObservable();

    private onCourseCategoryFilterChanged = new BehaviorSubject<any>(this.categoryFilter);
    onCourseCategoryFilterChanged$ = this.onCourseCategoryFilterChanged.asObservable();

    private nextPageToken = new BehaviorSubject<any>(this.nextToken);
    nextPageToken$ = this.nextPageToken.asObservable();

    courses: any[];
    pageSize: number;


    /**
     * Constructor
     *
     * @param options
     * @param api
     * @param syncSettings
     * @param authService
     * @param _fuseProgressBarService
     */
    constructor(
        @Inject(ACADEMY_MANAGER_OPTIONS) protected options = {},
        private api: APIService,
        private syncSettings: SyncSettings,
        private authService: AuthService,
        private _fuseProgressBarService: FuseProgressBarService,

    )
    {
      this.pageSize = this.getConfigValue('pageSize');
      this.syncSettings.onSettingsChanged$.subscribe((res) => {
        this.pageSize = res.pageSize;
      });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getCategories('', 10),
                this.getCourses()
            ]).then(
                () => {
                    this.onCourseCategoryFilterChanged$
                        .subscribe((res) => {
                            if (res==='init') {
                                this.categoryFilter = null;
                            } else {
                                this.categoryFilter = res;
                                of(this.getCourses(null, null, this.categoryFilter));
                            }
                    });
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get categories
     *
     * @returns {Promise<any>}
     */
    getCategories(searchTxt?: string, pageSize?: number): Promise<any> {
        let filter: ModelCourseCategoryFilterInput;
        if (searchTxt) {
            filter = {
                category: {contains: searchTxt}
            };
        }
        const currentYear = new Date().getFullYear();
        return new Promise((resolve, reject) => {
          this.api.LIstCategoriesByStatus(
            elStatus.active,
            { beginsWith : currentYear.toString()},
            ModelSortDirection.DESC,
            filter,
            pageSize
          ).then((result) => {
            this.onCategoriesChanged.next(result.items);
            resolve(result.items);
          }).catch((err) => {
            reject(err);
          });
        });
    }

    public refresh() {
      of(this.getCourses());
    }

    public setFilter(filterTxt: string ) {
        this.onCourseCategoryFilterChanged.next(filterTxt);
    }

    public gotoNextPage(filterTxt: string, nextPageToken: string, filterCat: string): void {
      of(this.getCourses(filterTxt, nextPageToken, filterCat));
    }
    /**
     * Get courses
     *
     * @returns {Promise<any>}
     */
    getCourses(searchTxt?: string, nextToken?: string, categoryFilter?: string): Promise<any> {
        this._fuseProgressBarService.show();
        const userInfo = this.authService.getUserId();
        const userId = userInfo.getUsername();
        let filter: ModelCourseSubsFilterInput =  {
            userId: { eq: userId}
        };
        if (searchTxt !== undefined && searchTxt) {
            filter.and = [
                {
                    title:  { contains: searchTxt},
                }
            ];
        }
        if (categoryFilter !== undefined && categoryFilter) {
            filter.and = [
                {
                    category:  { contains: categoryFilter},
                }
            ];
        }
        this.nextToken = nextToken ? nextToken : null;
        return new Promise((resolve, reject) => {
            this.api.ListCourseSubss(filter, this.pageSize, this.nextToken)
                .then((result) => {
                    this.nextToken = !_.isEmpty(result['nextToken']) ? result['nextToken'] : null;
                    this.nextPageToken.next(this.nextToken );
                    this.courses = result.items;
                    this.onCoursesChanged.next(result.items);
                    this._fuseProgressBarService.hide();
                    // console.log(this.courses)
                    resolve(this.courses);
                })
                .catch((err) => {
                    this.catchError(err);
                    reject(err);
                });
        });
    }

    private catchError(error) {
        console.log(error);
        this.logger.debug('OOPS!', error);
        this._fuseProgressBarService.hide();
    }

    getConfigValue(key: string): any {
        return getDeepFromObject(this.options, key, null);
    }

}
