import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {Subject} from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import {FileUploadModel} from '../cc-file-upload/cc-file-upload.component';
import {ContentItemService} from '../content-item.service';

@Component({
    selector     : 'cc-file-upload',
    templateUrl  : './cc-file-details.component.html',
    styleUrls    : ['./cc-file-details.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations   : fuseAnimations
})
export class CcFileDetailsComponent implements OnInit, OnDestroy, OnChanges
{
    files: any;

    @Input()
    media: any;

    @Input()
    file: FileUploadModel;

    @Input()
    process: boolean;

    @Output()
    complete = new EventEmitter<any>();

    @Output()
    cancel = new EventEmitter<FileUploadModel>();

    @Output()
    fileData = new EventEmitter<any>();

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param _changeDetectorRef
     * @param {ContentItemService} _contentItemService
     */
    constructor(
      private _changeDetectorRef: ChangeDetectorRef,
      private _contentItemService: ContentItemService
    )
    {
      this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {}

    ngOnChanges(): void {
        if (this.process) {
            this.uploadFile();
        }
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // Methods
    cancelFile(file: FileUploadModel): void {
        this.cancel.emit(file);
    }

    retryFile(file: FileUploadModel): void {
        this.uploadFile();
        file.canRetry = false;
    }

    private uploadFile() {
        const typeArray = this.file.data.type.split('/');
        const cType = ['mp4', 'avi', 'mp3', 'mpeg'].indexOf(typeArray[1].toLowerCase()) ===  -1 ? 'file' : 'video';
        if (cType === 'file') {
            return;
        }
        const bLoc = `${this._contentItemService.bucket}/${this._contentItemService.bucketTarget}/video/${this.media.courseId}/${this.media.id}`;
        const newConn = this._contentItemService.syncS3();
        const params = {
            Bucket: bLoc,
            Key: this.file.data.name,
            ACL: 'authenticated-read',
            Body: this.file.data,
            ContentType: this.file.data.type
        };
        this.file.progress = 0;
        this.file.inProgress = true;
        newConn.upload(params)
            .on('httpUploadProgress', (evt) => {
              this.file.progress = Math.round((evt.loaded * 100) / evt.total);
              this._changeDetectorRef.detectChanges();
            }).send( (err, data) => {
                this.file.inProgress = false;
                if (err) {
                    console.log('There was an error uploading your file: ', err);
                    this.file.canRetry = true;
                } else {
                    this.complete.emit(this.file);
                    this.fileData.emit({ fileUpload: data, file: this.file.data } );
                    // console.log('Successfully uploaded file.', data);
                }
        });
    }

}

