import { Component, OnInit, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '../../../../../../../@fuse/animations';
import {DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, merge, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {FuseUtils} from '../../../../../../../@fuse/utils';
import {FormBuilder, FormGroup} from '@angular/forms';
import {CourseCat} from '../../../category.model';
import {CoursesCategoryService} from '../../../courses-category.service';

@Component({
  selector: 'course-category-sidebar',
  templateUrl: './course-category-sidebar.component.html',
  styleUrls: ['./course-category-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class CourseCategorySidebarComponent implements OnInit {

  dataSource: CategoriesDataSource| null;
  displayedColumns: string[] = ['category', 'buttons'];
  nextPage: any;
  filterTxt = '';
  catsList: any;
  catForm: FormGroup;
  category: CourseCat;
  showForm = false;
  editType = 'new';

  constructor(
    private _catsListService: CoursesCategoryService,
    private _formBuilder: FormBuilder,
  ) {
      this.category = new CourseCat();
      this.catForm = this.createCourseForm();
  }

  ngOnInit() {

    this._catsListService.nextPageToken$
      .subscribe(nextToken => {
        this.nextPage = nextToken;
      });

    this._catsListService.onCategoriesChanged$
      .subscribe(courseList => {
        this.catsList = courseList;
      });
    this.dataSource = new CategoriesDataSource(this._catsListService);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if ( !this.dataSource )
    {
      return;
    }
    this.filterTxt = filterValue.trim().toLowerCase();
    this._catsListService.getCategories( this.filterTxt )
      .then(( res ) => {
        this.dataSource.filter = filterValue.trim().toLowerCase();
      })
      .catch((err) => {
        return ;
      });
  }

  refreshCats(): void {
    this._catsListService.refresh();
  }

  gotoNextPage(nextPage): void {
    this._catsListService.goNextPage(this.filterTxt, nextPage);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  newCategory() {
    this.catForm.reset();
    this.showForm  = true;
    this.editType = 'new';
  }

  cancelOp() {
    this.showForm  = false;
    this.editType = 'new';
  }

  deleteCategory(category: any) {
    this._catsListService.courseListByCategory(category)
      .then(res => {
          if (res.length === 0) {
            this._catsListService.deleteCategory(category)
              .then(resCat => {
                this.cancelOp();
              });
          } else {
            const msgErr = `Can't Deleted ${res.length} Courses found related to it!!! `;
          }
      });
  }

  editCategory(category: any) {
    this.catForm.reset();
    this.category = category;
    this.catForm = this.createCourseForm();
    this.showForm  = true;
    this.editType = 'update';
  }


  saveData() {
      const catAttr = this.catForm.getRawValue();
      if (this.editType === 'new' && catAttr.id === null) {
        this._catsListService.createCategory(catAttr)
          .then(res => {
            this.cancelOp();
          }
        );
      } else {
        this._catsListService.updateCategory(catAttr)
          .then(res => {
            this.cancelOp();
          }
        );
      }
  }
  /**
   * Create cat form
   *
   * @returns {FormGroup}
   */
  createCourseForm(): FormGroup
  {
    return this._formBuilder.group({
      id         : [this.category.id],
      category   : [this.category.category],
      _version   : [this.category._version]
    });
  }


}

export class CategoriesDataSource extends DataSource<any> {

  private _filterChange = new BehaviorSubject('');
  private _filteredDataChange = new BehaviorSubject('');

  /**
   * Constructor
   *
   * @param _catsListService
   */
  constructor(
    private _catsListService: CoursesCategoryService
  ) {
    super();
    this.filteredData = this._catsListService.categories;
  }

  /**
   * Connect function called by the table to retrieve one stream containing the data to render.
   *
   * @returns {Observable<any[]>}
   */
  connect(): Observable<any[]>
  {
    const displayDataChanges = [
      this._catsListService.onCategoriesChanged$,
      this._filterChange
    ];
    return merge(...displayDataChanges)
      .pipe(
        map(() => {
            let data = this._catsListService.categories.slice();

            data = this.filterData(data);

            this.filteredData = [...data];

            // Grab the page's slice of data.
            return data;
          }
        ));
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // Filtered data
  get filteredData(): any {
    return this._filteredDataChange.value;
  }

  set filteredData(value: any) {
    this._filteredDataChange.next(value);
  }

  // Filter
  get filter(): string {
    return this._filterChange.value;
  }

  set filter(filter: string) {
    this._filterChange.next(filter);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Filter data
   *
   * @param data
   * @returns {any}
   */
  filterData(data): any {
    if ( !this.filter )
    {
      return data;
    }

    return FuseUtils.filterArrayByString(data, this.filter);
  }


  /**
   * Disconnect
   */
  disconnect(): void {
  }
}
