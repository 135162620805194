import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { QuizOptions, QUIZ_MANAGER_OPTIONS } from './quiz.options';
import { QuizItemComponent } from './quiz-item/quiz-item.component';
import { QuizService } from './quiz.service';
import { AuthGuard } from '../../auth/auth.guard';
import { FuseSidebarModule, FuseWidgetModule } from '../../../../@fuse/components';
import { FuseSharedModule } from '../../../../@fuse/shared.module';

import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule,  } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ImgCropModule } from '../../../core/img-crop/img-crop.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFileUploadModule} from 'mat-file-upload';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

import { QuizDetailsComponent } from './components/quiz-details/quiz-details.component';
import { QuizConfigComponent } from './components/quiz-config/quiz-config.component';
import { QuizResultComponent } from './components/quiz-result/quiz-result.component';
import { QuestionListComponent } from './components/question-list/question-list.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { QuizImgResourceComponent } from './components/quiz-img-resource/quiz-img-resource.component';

const routes: Routes = [
  {
    path: 'quiz/:courseId/:id',
    component: QuizItemComponent,
    resolve: {
      data: QuizService
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'quiz/:courseId/new',
    component: QuizItemComponent,
    resolve: {
      data: QuizService
    },
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [
    QuizItemComponent,
    QuizDetailsComponent,
    QuizConfigComponent,
    QuizResultComponent,
    QuestionListComponent,
    FileUploadComponent,
    QuizImgResourceComponent
  ],
  entryComponents: [
    QuizImgResourceComponent,
    FileUploadComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    FuseSidebarModule,
    FuseWidgetModule,
    FuseSharedModule,
    MatIconModule,
    MatTabsModule,
    MatInputModule,
    MatFormFieldModule,
    DragDropModule,
    MatToolbarModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatCheckboxModule,
    MatDividerModule,
    MatRadioModule,
    MatTableModule,
    MatSortModule,
    MatSnackBarModule,
    ImgCropModule,
    MatCardModule,
    MatExpansionModule,
    MatFileUploadModule,
    MatMenuModule,
    MatButtonModule
  ]
})
export class QuizModule {
  static forRoot(fmOptions?: QuizOptions): ModuleWithProviders {
    return {
        ngModule: QuizModule,
        providers: [
            { provide: QUIZ_MANAGER_OPTIONS, useValue:  fmOptions},
            QuizService
        ],
    } as ModuleWithProviders;
  }
}
