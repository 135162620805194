import {Injectable} from '@angular/core';
import AWSAppSyncClient, {AUTH_TYPE} from 'aws-appsync/lib';
import {Auth} from 'aws-amplify';
import AWSConfig from '../../../aws-exports';

@Injectable({
    providedIn: 'root'
})
export class AppSyncService {
    _hc;

    constructor() {
        this._hc = new AWSAppSyncClient({
            url: AWSConfig.aws_appsync_graphqlEndpoint,
            region: AWSConfig.aws_project_region,
            auth: {
                type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
                jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken()
            }
        });
    }

    // tslint:disable-next-line:typedef
    hc() {
        return this._hc.hydrate();
    }
}
