import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'terms-dialog',
    templateUrl: 'term-dialog.component.html',
})
export class TermDialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public acceptTerms: any) {
    }
}
