import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';
import { defaultAuthOptions } from './main/auth/auth.options';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import {AuthModule} from './main/auth/auth.module';
import {AuthService} from './main/auth/services/auth.service';
import {MatDialogModule} from '@angular/material/dialog';
import {AppSyncService} from './core/services/AppSyncService';
import {MatSelectSearchModule} from './core/mat-select-search/mat-select-search.module';
import {ProfileService} from './main/profile/profile.service';
import {AmplifyAngularModule, AmplifyService} from 'aws-amplify-angular';
import {ImgCropModule} from './core/img-crop/img-crop.module';
import {ProfileModule} from './main/profile/profile.module';
import {AdminModule} from './main/admin/admin.module';
import {AgmCoreModule} from '@agm/core';
import {SessionService} from './core/services/SessionService';
import {AuthGoComponent} from './main/auth/components/go/auth-go.component';
import {UnauthGuard} from './main/auth/unauth.guard';
import {SyncSettings} from '../@fuse/services/sync-settings.service';
import {defaultProfileOptions} from './main/profile/profile.options';
import {OnDemandPreloadStrategy} from './core/strategies';
import {AcademyModule} from './main/academy/academy.module';
import {defaultAcademyOptions} from './main/academy/academy.options';
import {CourseCatalogModule} from './main/course-catalog/course-catalog.module';
import {defaultCatalogOptions} from './main/course-catalog/course-catalog.options';
import {APPS_MANAGER_OPTIONS, defaultAppOptions} from "./app.options";
import {APIService} from "./API.service";
import {APICustomService} from "./API.customize.service";
import {InstructorsModule} from "./main/instructor/instructors.module";


const appRoutes: Routes = [
  {
    path        : 'auth',
    loadChildren: () => import('./main/auth/auth.module').then(m => m.AuthModule),
    data: { preload: true }
  },
  {
    path        : 'profile',
    loadChildren: () => import('./main/profile/profile.module').then(m => m.ProfileModule),
    data: { preload: true }
  },
  {
    path        : 'my',
    loadChildren: () => import('./main/academy/academy.module').then(m => m.AcademyModule),
    data: { preload: true }
  },
  {
    path        : 'school',
    loadChildren: () => import('./main/course-catalog/course-catalog.module').then(m => CourseCatalogModule),
    data: { preload: true }
  },
  {
    path        : 'go',
    component   : AuthGoComponent,
    // canActivate : [UnauthGuard]
  },
  {
    path        : 'admin',
    loadChildren: () => import('./main/admin/admin.module').then(m => m.AdminModule),
    data: { preload: true }
  },
    {
        path        : 'coach',
        loadChildren: () => import('./main/instructor/instructors.module').then(m => m.InstructorsModule),
        data: { preload: true }
    },
  {
    path      : '**',
    redirectTo: 'me'
  }

];

@NgModule({
  declarations: [
    AppComponent,
    AuthGoComponent,
  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes,
            {
                // enableTracing: true,
                preloadingStrategy: OnDemandPreloadStrategy,
            }),

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatDialogModule,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyByG0bzpOjPcwHTVImcMaFDEDNag9op44k'
        }),
        // App modules
        LayoutModule,
        AuthModule.forRoot(defaultAuthOptions),
        MatSelectSearchModule,
        AmplifyAngularModule,
        ImgCropModule.forRoot(),
        ProfileModule.forRoot(defaultProfileOptions),
        AdminModule,
        AcademyModule.forRoot(defaultAcademyOptions),
        CourseCatalogModule.forRoot(defaultCatalogOptions),
        CourseCatalogModule.forRoot(defaultCatalogOptions),
        InstructorsModule
    ],
  providers: [
    SessionService,
    SyncSettings,
    AuthService,
    ProfileService,
    AppSyncService,
    AmplifyService,
    APIService,
    APICustomService,
    { provide: APPS_MANAGER_OPTIONS, useValue:  defaultAppOptions},
    // { provide: HTTP_INTERCEPTORS, useClass:  LambdaAPIInterceptor, multi: true },
  ],
  bootstrap   : [
    AppComponent
  ]
})
export class AppModule {}
