import { InjectionToken } from '@angular/core';

export interface NotifyOptions {
    accessKeyId: string;
    secretAccessKey: string;
    UserPoolId?: any;
    AttributesToGet?: any;
    region: string;
    pageSize?: any;
    S3ButketName: string;
}

export const defaultNotyOptions: any = {
    accessKeyId: 'AKIAY6NY73HUV5NXALUR',
    secretAccessKey: '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',
    UserPoolId: 'us-east-1_I3aBWHf14',
    region: 'us-east-1',
    pageSize: 10,
    S3BucketName: 'kidsthatcode-media-resources',
};

export const NOTIFY_MANAGER_OPTIONS = new InjectionToken<NotifyOptions>('Nofitications Manager Options');

