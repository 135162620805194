import { Injectable, Inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';

import {Logger} from '@aws-amplify/core';
import { QUIZ_MANAGER_OPTIONS } from './quiz.options';
import { APIService, CreateQuizInput, CreateCourseAssetsInput, elAssetType, UpdateQuizInput, UpdateCourseAssetsInput, ModelQuizQuestionFilterInput } from 'app/API.service';
import { SyncSettings } from '@fuse/services/sync-settings.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CourseAsset } from '../course-content/media.model';
import { quizContent } from './quiz.model';
import * as S3 from 'aws-sdk/clients/s3';
import { getDeepFromObject } from 'app/main/auth/helpers';


@Injectable({
  providedIn: 'root'
})
export class QuizService {
  private logger = new Logger('Quiz Item Service');
  routeParams: any;
  content: CourseAsset;
  quizContent: quizContent;
  bucketTarget: any;
  
  private onContentChanged = new BehaviorSubject<any>(null);
  onContentChanged$ = this.onContentChanged.asObservable();

  private onQuizQuestionChanged = new BehaviorSubject<any>(null);
  onQuizQuestionChanged$ = this.onQuizQuestionChanged.asObservable();

  private _onErrorOrSuccess = new BehaviorSubject<any>(null);
  onErrorOrSuccess$ = this._onErrorOrSuccess.asObservable();

  constructor(
    @Inject(QUIZ_MANAGER_OPTIONS) protected options = {},
    private api: APIService,
    private syncSettings: SyncSettings,
    private _fuseProgressBarService: FuseProgressBarService,
  ) {
    this.bucketTarget = this.getConfigValue('S3BucketName');
  }

  /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any{
    this.routeParams = route.params;
    console.log("==== GETCONTENT NEW ====");
    if (!this.routeParams.id || !this.routeParams.courseId) {
      return false;
    }
    return new Promise((resolve, reject) => {
      Promise.all([
          this.getContent(this.routeParams.id, this.routeParams.courseId)
      ]).then(
          () => {
            resolve();
          },
          reject
      );
    });
  }

  /**
   * Get content
   *
   * @returns {Promise<any>}
   */
  getContent(contentId: string, courseId: string): Promise<any> {
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      if (contentId === 'new' ) {
        this.api.GetCourse(courseId)
          .then((cDetails) => {
            this.content = new CourseAsset();
            this.content.course = cDetails;
            this.onContentChanged.next(this.content);
            resolve(cDetails);
          })
          .catch((err) => {
            this.catchError(err);
            reject(err);
          });
      }else {
        this.api.GetCourseAssets(contentId)
          .then((resultAsset) => {
            this.content = resultAsset;
            this.quizContent = this.content.quiz;
            // console.log("resultAsset ==> ", this.content);
            return this.api.GetQuiz(this.content.quiz.id);
          }).then((resultQuiz) => {
            this.content.quiz = resultQuiz;

            let filter: ModelQuizQuestionFilterInput;
            filter = {
              quizContentId: {contains: this.content.id}
            };
            let pageSize = 999;
            let nextToken = null;
            return this.api.ListQuizQuestions(filter, pageSize, nextToken);
          }).then(resListQuestions =>{
            this.content.quiz.questions.items = resListQuestions.items;
            this.onContentChanged.next(this.content);
            resolve(this.content)
          }).catch((err) => {
            this.catchError(err);
            reject(err)
          });
      }
    });
  }

  addQuiz(newQuiz: any): Promise<any> {
    const dateAt = new Date().toISOString();
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      const quizInput: CreateQuizInput = {
        courseId: newQuiz.courseId,
        createdAt: dateAt,
        updatedAt: dateAt
      };
      this.api.CreateQuiz(quizInput)
        .then(result => {
            const assetInput: CreateCourseAssetsInput = {
                title: newQuiz.title,
                assetType: elAssetType.Quiz,
                description: newQuiz.description,
                courseId: newQuiz.courseId,
                courseAssetsCourseId: newQuiz.courseId,
                courseAssetsQuizId: result.id,
                timeAprox: newQuiz.timeAprox,
                starred: false,
                important: false,
                showAsOverview: false,
                showAsFree: false,
                order: 0,
                createdAt: dateAt,
                updatedAt: dateAt,
                status: newQuiz.status
            };
            console.log("createquiz --> ", assetInput);
            return this.api.CreateCourseAssets( assetInput);
        })
        .then((resultAsset) => {
          console.log("courseasset --> ", resultAsset);
          this.content = resultAsset;
          this.quizContent = this.content.quiz;
          this.onContentChanged.next(this.content);
          this._fuseProgressBarService.hide();
          resolve(resultAsset);
        })
        .catch((err) => {
          console.log("err ==> ", err);
          this.catchError(err);
          reject(err);
        });
    });
  }

  saveCourseAsset(courseAsset: any): Promise<any> {
    this._fuseProgressBarService.show();
    const cDate = new Date().toISOString();
    const courseAssetsInput: UpdateCourseAssetsInput ={
      id: courseAsset.id,
      courseId: courseAsset.courseId,
      title: courseAsset.title,
      description: courseAsset.description,
      timeAprox: courseAsset.timeAprox,
      status: courseAsset.status,
      updatedAt: cDate,
      _version: courseAsset._version
    };
    return new Promise((resolve, reject) => {
      this.api.UpdateCourseAssets(courseAssetsInput)
        .then((result) => {
          this.content = result;
          this._fuseProgressBarService.hide();
          this.onContentChanged.next(this.content);
          resolve(result);
        })
        .catch((err) => {
          this.catchError(err);
          reject(err);
        });
    });
  }

  saveContent(newContent: any): Promise<any> {
    this._fuseProgressBarService.show();
    const cDate = new Date().toISOString();
    const quizInput: UpdateQuizInput = {
      id: newContent.id,
      courseId: newContent.courseId || this.quizContent.courseId,
      questionsRandom: (typeof newContent.questionsRandom !== 'undefined')?
                        newContent.questionsRandom : this.quizContent.questionsRandom,

      passingScore: (typeof newContent.passingScore !== 'undefined')?
                    newContent.passingScore: this.quizContent.passingScore,

      noAttempts: (typeof newContent.noAttempts !== 'undefined')?
                  newContent.noAttempts : this.quizContent.noAttempts,

      score: (typeof newContent.score !== 'undefined')?
              newContent.score : this.quizContent.score,

      labelWhenPassed: (typeof newContent.labelWhenPassed !== 'undefined')?
                        newContent.labelWhenPassed : this.quizContent.labelWhenPassed,

      labelWhenFailed: (typeof newContent.labelWhenFailed !== 'undefined')?
                        newContent.labelWhenFailed : this.quizContent.labelWhenFailed,

      questionFeedback: (typeof newContent.questionFeedback !== 'undefined')?
                        newContent.questionFeedback : this.quizContent.questionFeedback,
      updatedAt: cDate,
      createdAt: cDate,
      _version: (typeof newContent._version !== 'undefined')?
                newContent._version : this.quizContent._version
    };

    return new Promise((resolve, reject) => {
      this.api.UpdateQuiz(quizInput)
        .then((result) => {
          this.quizContent = result;
          this._fuseProgressBarService.hide();
          resolve(result);
        })
        .catch((err) => {
          this.catchError(err);
          reject(err);
        });
    });
  }

  syncS3(): any {
    return new S3(
      {
        accessKeyId: this.getConfigValue('accessKeyId'),
        secretAccessKey: this.getConfigValue('secretAccessKey'),
        region: this.getConfigValue('region')
      });
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }

  // upload question file
  uploadQuestionFile(fileUpload: File, quizQuestionId: string, courseId: string): Promise<any> {
    if(!fileUpload){
      return new Promise(resolve => resolve());
    }else {
      return new Promise((resolve, reject) => {
        const bPath = `${this.bucketTarget}/quiz/${courseId}/${quizQuestionId}`;
        const fileName = new Date().getTime();
        const params = {
          Bucket: bPath,
          Key: fileName+"",
          ACL: 'authenticated-read',
          Body: fileUpload,
          ContentType: fileUpload.type
        };
        const self = this;
        this._fuseProgressBarService.setMode('determinate');
        this._fuseProgressBarService.setValue(0);
        this._fuseProgressBarService.show();
        const newConn = this.syncS3();
        newConn.upload(params)
        .on('httpUploadProgress', (evt) => {
          const perUp = Math.round((evt.loaded * 100) / evt.total);
          self._fuseProgressBarService.setValue(perUp);
        }).send((err, data) => {
          self._fuseProgressBarService.hide();
          self._fuseProgressBarService.setValue(0);
          if (err) {
            console.log(err);
            reject(err);
          } else {
            resolve(data.Location);
          }
        });
      });
    }
  }

  catchError(err: any) {
    throw new Error("Method not implemented.");
  }
}
