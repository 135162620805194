import { InjectionToken } from '@angular/core';
import { HttpRequest } from '@angular/common/http';

export interface AuthOptions {
  forms?: any;
  keys?: any;
  endPoints?: any;
  googleservice: boolean;
  sessionManagement: boolean;
  logoUrl: string;
  siteKey;
}

export interface AuthLayoutOptions {
  layout?: any;
  snackbar?: any;
}

export interface AuthSocialLink {
  facebook?: boolean;
  google?: boolean;
}

const socialLinks: AuthSocialLink = { facebook: false, google: false};

export const defaultAuthOptions: any = {
  forms: {
    login: {
      redirectDelay: 800,
      rememberMe: true,
      showMessages: {
        success: true,
        error: true,
      },
      socialLinks: socialLinks, // social links at the bottom of a page
      redirect_endpoint: '/'
    },
    register: {
      redirectDelay: 800,
      showMessages: {
        success: true,
        error: true,
      },
      terms: true,
      socialLinks: socialLinks,
      redirect_endpoint: 'auth/confirm'
    },
    requestPassword: {
      redirectDelay: 800,
      showMessages: {
        success: true,
        error: true,
      },
      socialLinks: socialLinks,
      redirect_endpoint: '/'
    },
    resetPassword: {
      redirectDelay: 2200,
      showMessages: {
        success: true,
        error: true,
      },
      socialLinks: socialLinks,
      redirect_endpoint: '/'
    },
    confirmMail: {
      redirectDelay: 800,
      redirect_endpoint: '/',
      showMessages: {
        success: true,
        error: true,
      }
    },
    logout: {
      redirectDelay: 1200,
      redirect_endpoint: '/'
    },
    validation: {
      password: {
        required: true,
        minLength: 4,
        maxLength: 50
      },
      confirmPassword:
      {
        required: true,
        minLength: 4,
        maxLength: 50
      },
      confirmCode:
      {
        required: true,
        minLength: 4,
        maxLength: 50
      },
      email: {
        required: true,
      },
      firstName: {
        required: true,
        minLength: 4,
        maxLength: 50
      },
      lastName: {
        required: true,
        minLength: 4,
        maxLength: 50
      },
    },
  },
  keys: {
    recaptchaSettingsv3: {
      siteKey: '6LdkhKMZAAAAAGrO5A-bNJVAcAySAr4FQMpB0dD-'
    }
  },
  endPoints: {
     redirectOnRefresh: '/',
     redirectOnUnAuthorized: 'auth/login',
     redirectOnAuthorized: 'me'
  },
  googleservice: false,
  sessionManagement: true,
  logoUrl: 'assets/images/logos/KTC_LOGO_blue3.png',
  siteKey: '6LdkhKMZAAAAAGrO5A-bNJVAcAySAr4FQMpB0dD-'
};

export const defaultAuthLayoutOptions: any = {
  layout: {
    navbar   : {
      hidden: true
    },
    toolbar  : {
      hidden: true
    },
    footer   : {
      hidden: true
    },
    sidepanel: {
      hidden: true
    }
  },
  snackbar: {
    horizontalPosition: 'center',
    verticalPosition: 'top',
    duration: 1800
  },
  recaptcha: {
      showInfo: true
  }
};

export const AUTH_LAYOUT = new InjectionToken<AuthLayoutOptions>('Auth Layout Options');
export const AUTH_OPTIONS = new InjectionToken<AuthOptions>('Auth Options');
export const AUTH_USER_OPTIONS = new InjectionToken<AuthOptions>('User Auth Options');
export const AUTH_INTERCEPTOR_HEADER = new InjectionToken<string>('Simple Interceptor Header');
export const AUTH_TOKEN_INTERCEPTOR_FILTER =
       new InjectionToken<(req: HttpRequest<any>) => boolean>('Interceptor Filter');

