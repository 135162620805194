import { Component, HostBinding, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { Subject } from 'rxjs';
import {CourseAsset} from '../../../course-content/media.model';
import {CourseService} from '../../course.service';

@Component({
    selector     : 'content-item-list',
    templateUrl  : './content-item-list.component.html',
    styleUrls    : ['./content-item-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ContentItemListComponent implements OnInit, OnDestroy
{
    @Input()
    courseStatus: string;

    @Input()
    cmedia: CourseAsset;

    @Input()
    isOrdering: boolean

    @HostBinding('class.selected')
    selected: boolean;

    @HostBinding('class.completed')
    completed: boolean;

    @HostBinding('class.move-disabled')
    moveDisabled: boolean;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param _courseService
     * @param {ActivatedRoute} _activatedRoute
     * @param _router
     */
    constructor(
        private _courseService: CourseService,
        private _activatedRoute: ActivatedRoute,
        private _router: Router
    )
    {
        // Disable move if path is not /all
        this.moveDisabled = false;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.cmedia = new CourseAsset(this.cmedia);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Toggle star
     */
    toggleStar(event): void {
      this._courseService.toggleStar(event)
        .then(result => {
            this._courseService.showAll(99);
        });
    }

    /**
     * Toggle Important
     */
    toggleImportant(event): void
    {
      this._courseService.toggleImportant(event)
        .then(result => {
            this._courseService.showAll(99);
        });
    }

    toggleShowAsPreview(event): void
    {
      this._courseService.toggleShowAsPreview(event)
        .then(result => {
            this._courseService.showAll(99);
        });
    }

    goEditTopic(event): void
    {
        this._router.navigate([`admin/content/${event.courseId}/${event.id}`]);
    }

    goEditQuiz(event): void
    {
        this._router.navigate([`admin/quiz/${event.courseId}/${event.id}`]);
    }

    toogleInactive(event): void
    {
        this._courseService.toggleInactive(event)
            .then(result => {
                this._courseService.showAll(99);
            });
    }


}
