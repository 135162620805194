import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import * as _ from 'lodash';

@Component({
    selector: 'core-file-upload',
    template: `
    <span class="file-input-text">{{ labelText }}</span>
    <button
      mat-button
      color="primary"
      class="file-input-button"
      (click)="fileInput.click()"
      [attr.aria-label]="selectButtonText"
    >
      <span>{{ selectButtonText }}</span>
      <input
        #fileInput
        type="file"
        style="display: none"
        [accept]="acceptedTypes"
        [multiple]="allowMultipleFiles"
        (change)="filesChanged($event)"
      />
    </button>
    <span class="file-input-text">{{ selectedFileText }}</span>
    <button
      mat-icon-button
      (click)="filesChanged(null)"
      aria-label="Remove Selected File(s)"
    >
      <mat-icon *ngIf="!customSvgIcon">close</mat-icon>
      <mat-icon *ngIf="customSvgIcon" [svgIcon]="customSvgIcon"></mat-icon>
    </button>
  `,
    styles: [
        '.file-input-button { margin-right: 8px !important }',
        '.file-input-text { font-size: 14px !important; margin-right: 8px !important }',
    ],
})
export class UploadFileComponent implements OnInit {
    @Input() labelText = 'Select File(s)';
    @Input() selectButtonText = 'Select File(s)';
    @Input() uploadButtonText = 'Upload File(s)';
    @Input() allowMultipleFiles = false;
    @Input() showUploadButton = true;
    @Input() acceptedTypes = '*.*';
    @Input() customSvgIcon?: string = null;
    @Output() uploadError: EventEmitter<any> = new EventEmitter<any>();
    @Output() selectedFilesChanged: EventEmitter<FileList> = new EventEmitter<FileList>();
    @Output() imageBase64: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('fileInput', { static: false }) fileInputRef: ElementRef;
    selectedFiles: FileList;
    selectedFileText = '';

    imageError: string;

    constructor() { }

    ngOnInit() { }

    filesChanged(fileInput?: any): void {
        this.selectedFiles = fileInput.target.files;
        this.selectedFilesChanged.emit(this.selectedFiles);
        if (this.selectedFiles) {
            const numSelectedFiles = this.selectedFiles.length;
            this.selectedFileText =
                numSelectedFiles === 1
                    ? this.selectedFiles[0].name
                    : `${numSelectedFiles} files selected`;
            this.fileChangeEvent(fileInput);
        } else {
            this.selectedFileText = '';
            this.resetFileInput();
        }
    }

    fileChangeEvent(fileInput: any) {
        this.imageError = null;
        if (fileInput.target.files && fileInput.target.files[0]) {
            // Size Filter Bytes
            const max_size = 20971520;
            const allowed_types = ['image/png', 'image/jpeg'];
            const max_height = 15200;
            const max_width = 25600;

            if (fileInput.target.files[0].size > max_size) {
                this.uploadError.emit(
                    'Maximum size allowed is ' + max_size / 1000 + 'Mb');
                return false;
            }

            if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
                this.uploadError.emit('Only Images are allowed ( JPG | PNG )');
                return false;
            }
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const image = new Image();
                image.src = e.target.result;
                image.onload = rs => {
                    const imgHeight = rs.currentTarget['height'];
                    const imgWidth = rs.currentTarget['width'];

                    console.log(imgHeight, imgWidth);

                    if (imgHeight > max_height && imgWidth > max_width) {
                        this.imageError =
                            'Maximum dimentions allowed ' +
                            max_height +
                            '*' +
                            max_width +
                            'px';
                        return false;
                    } else {
                        const imgBase64Path = e.target.result;
                        this.imageBase64.emit(imgBase64Path);
                    }
                };
            };

            reader.readAsDataURL(fileInput.target.files[0]);
        }
    }

    uploadFiles(): void {
        this.uploadError.emit(false);
        this.resetFileInput();
    }

    resetFileInput(): void {
        this.fileInputRef.nativeElement.value = '';
    }
}
