import { FuseUtils } from '@fuse/utils';

export class User
{
    id: string;
    email_verified: string;
    phone_verified: string;
    account_status: string;
    email: string;
    avatar_type: string;
    user_name: string;
    name: string;
    updatedAt: string;
    createdAt: string;
    user_status: string;
    attributes: any;
    external: boolean;
    picture: any;
    sub: any;
    orgId: any;
    /**
     * Constructor
     *
     * @param contact
     */
    constructor(user?) {
        user = user || {};
        this.id = user.id || FuseUtils.generateGUID();
        this.email_verified = user.email_verified || '';
        this.phone_verified = user.phone_verified || '';
        this.account_status = user.account_status || '';
        this.email = user.email || '';
        this.avatar_type = user.avatar_type || '';
        this.user_name = user.user_name || '';
        this.name = user.name || '';
        this.updatedAt = user.updatedAt || '';
        this.createdAt = user.createdAt || '';
        this.user_status = user.user_status || '';
        this.external = user.external || false;
        this.picture = user.picture || '';
        this.attributes = user.attributes || [];
        this.sub = user.sub || null;
        this.orgId = user.orgId || null;
    }
}

export class UserGroup {
    id: string;
    groupName: string;
    showName: string;
    precedence: number;
    updatedAt: string;
    selected: boolean;
    groupId: string;
    /**
     * Constructor
     *
     * @param contact
     */
    constructor(user)
    {
        this.id = user.id;
        this.groupName = user.groupName || '';
        this.precedence = user.precedence || '';
        this.updatedAt = user.updatedAt || '';
        this.showName = this.groupName.indexOf('Google') > 0  ?
            'GOOGLE' :
            (this.groupName.indexOf('Facebook') > 0 ? 'Facebook' : this.groupName);
        this.selected = false;
        this.groupId = null;
    }
}
