import { NgModule } from '@angular/core';

import {defaultUserOptions} from './users/users.options';
import {UsersModule} from './users/users.module';
import {ImgCropModule} from '../../core/img-crop/img-crop.module';
import {PushNotifyModule} from './push-notify/push-notify.module';
import {defaultNotyOptions} from './push-notify/notifications.options';
import {defaultCourseContentOptions} from './course-content/course-content.options';
import {defaultCoursesOptions} from './courses/courses.options';
import {CoursesModule} from './courses/courses.module';
import {CourseContentModule} from './course-content/course-content.module';
import {SyncSettings} from "../../../@fuse/services/sync-settings.service";
import {ContactsModule} from "./contacts/contacts.module";
import {defaultContactOptions} from "./contacts/contact.options";
import {defaultQuizQuestionOptions} from "./quiz-question/quiz-question.options";
import {QuizQuestionModule} from "./quiz-question/quiz-question.module";
import {QuizModule} from "./quiz/quiz.module";
import {defaultQuizOptions} from "./quiz/quiz.options";

@NgModule({
    imports     : [
        ImgCropModule,
        UsersModule.forRoot(defaultUserOptions),
        PushNotifyModule.forRoot(defaultNotyOptions),
        CoursesModule.forRoot(defaultCoursesOptions),
        CourseContentModule.forRoot(defaultCourseContentOptions),
        QuizModule.forRoot(defaultQuizOptions),
        QuizQuestionModule.forRoot(defaultQuizQuestionOptions),
        ContactsModule.forRoot(defaultContactOptions),
    ],
    providers: [SyncSettings]
})
export class AdminModule {}
