import {ContactType} from "../../../API.service";

export class Contact
{
    id: string;
    firstName: string;
    lastName: string;
    contactType: any;
    email: string;
    phone: string;
    birthday: string;
    avatar: string;
    avatarType: string;
    starred: boolean;
    frequent: boolean;
    status: string;
    syncID: string;
    userID: string;
    orgId: string;
    base64Img: string;
    pbProfileOptions: any;
    notes: string;
    connect: any;
    userRelatedId: any;
    orgMeta: any;
    _version: any;
    /**
     * Constructor
     *
     * @param contact
     */
    constructor(contact?)
    {
        {
            contact = contact || {};
            this.id = contact.id || null;
            this.firstName = contact.firstName || '';
            this.lastName = contact.lastName || '';
            this.email = contact.email || '';
            this.phone = contact.phone || '';
            this.contactType = contact.contactType || ContactType.Contact;
            this.birthday = contact.birthday || '';
            this.avatar = contact.avatar || 'assets/images/avatars/profile.jpg';
            this.avatarType = contact.avatarType || 'img';
            this.starred = contact.starred || false;
            this.frequent = contact.frequent || false;
            this.status = contact.status || 'new';
            this.syncID = contact.syncID || null;
            this.orgId = contact.orgId || null;
            this.userID = contact.userID || null;
            this.pbProfileOptions = contact.pbProfileOptions || '';
            this.notes = contact.notes || '';
            this.connect = contact.connect || '';
            this.orgMeta = contact.orgMeta || {};
            this.userRelatedId = contact.userRelatedId || null;
            this._version = contact._version || null;
        }
    }
}
