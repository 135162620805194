import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FuseProgressBarService} from "../../../../../../../@fuse/components/progress-bar/progress-bar.service";
import {QuizViewerService} from "../../../../quiz-viewer.service";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {elSubStatus} from "../../../../../../API.service";

@Component({
  selector: 'quiz-summary',
  templateUrl: './quiz-summary.component.html',
  styleUrls: ['./quiz-summary.component.scss']
})
export class QuizSummaryComponent implements OnInit {

  private _unsubscribeAll: Subject<any>;

  @Input()
  currentSubs: any;

  @Output()
  onResetQuiz: EventEmitter<any> = new EventEmitter();

  nQuestions: number;
  nCorrectAnswers: number;
  showSummary = false;
  data: any;
  scoring: boolean = false;

  /**
   * Constructor
   */
  constructor(
      private quizService: QuizViewerService,
      private _fuseProgressBarService: FuseProgressBarService,
  ){
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(){
    this.quizService.onStepChanged$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(step => {
          if (step==='summary') {
            this._fuseProgressBarService.show();
            this.quizService.getTotalSummary()
                .then(res => {
                  this.nCorrectAnswers = 0;
                  this.showSummary = false;
                  this.scoring = false;
                  this.data = res;
                  this.calculateSummary();
                  const statusMeta = JSON.stringify({ score: (this.nCorrectAnswers * 100 / this.nQuestions) }) ;
                  this.quizService.UpdateAssetSubscription(this.currentSubs, statusMeta, elSubStatus.Completed )
                      .then( updateOk => {
                        this._fuseProgressBarService.hide();
                        console.log('completed');
                      })
                });
          }
        });

  }

  calculateSummary(){
    let scored = 0;
    this.data.forEach(element => {
        if(element.userOptions){
            let allAnswersAreCorrect = true;
            for(let item of element.userOptions.answerSelected){
                element.questionAnswers.items.forEach( val => {
                    if(item.answer === val.answer){
                        val.check = true;
                        if(!val.isCorrect){
                            allAnswersAreCorrect = false;
                        }
                        if(val.qType === "TrueFalse"){
                            allAnswersAreCorrect = true;
                        }
                    }else{
                        if(val.qType === "TrueFalse"){
                            allAnswersAreCorrect = false;
                        }
                        if(!val.check){
                            val.check = false;
                        }
                    }
                });
            }
            if(allAnswersAreCorrect)
                scored++;
        }
    });
    this.nCorrectAnswers = scored;
    this.nQuestions = this.data.length;
  }

  displaySummary(){
    this.showSummary = true;
  }

  resetQuiz(){
    this.showSummary = false;
    this.onResetQuiz.emit(true);
  }
}
