import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {APIService,
  ModelSortDirection} from "../../../../API.service";

@Component({
  selector: 'msc-course-description',
  templateUrl: './msc-course-description.component.html',
  styleUrls: ['./msc-course-description.component.scss']
})
export class MscCourseDescriptionComponent implements OnInit, AfterViewInit {

  @Input() course;
  @Input() userId;
  color = 'primary';
  mode = 'indeterminate';
  displayProgressSpinner = false;
  value = 10;
  userSubs: boolean = false;
  courseSubId: string;


  constructor(
    private router: Router,
    private api: APIService,
    protected cd: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.displayProgressSpinner = true;
  }

  ngAfterViewInit() : void {
    this.getUserSubs(this.course.id, this.userId)
        .then( subsResult => {
          this.userSubs = subsResult && subsResult.length>0;
          if (this.userSubs) {
            this.courseSubId = subsResult[0];
          }
          this.cd.detectChanges();
        })
  }

  restartResume($event) {
    this.router.navigate(['my/courses/' + $event.id]);
  }

  seeOverView($event) {
    this.router.navigate(['school/courses/' + $event.id]);
  }

  private getUserSubs(courseId: string, userId: string): Promise<any> {
    this.displayProgressSpinner = true;
    return new Promise((resolve, reject) => {
      this.api.SubsCourseByIds(
          userId,
          { eq : courseId},
          ModelSortDirection.DESC,
          null,
          1
      ).then((result) => {
        this.displayProgressSpinner = false;
        resolve(result.items);
      }).catch((err) => {
        this.displayProgressSpinner = false;
        resolve(false);
      });
    });
  }

}
