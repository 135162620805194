import {ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {AUTH_LAYOUT, AUTH_OPTIONS} from '../../auth.options';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {FuseProgressBarService} from '../../../../../@fuse/components/progress-bar/progress-bar.service';
import {getDeepFromObject} from '../../helpers';
import {CognitoUser} from 'amazon-cognito-identity-js';
import {InvisibleReCaptchaComponent} from 'ngx-captcha';

@Component({
    selector     : 'forgot-password',
    templateUrl  : './forgot-password.component.html',
    styleUrls    : ['./forgot-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class ForgotPasswordComponent implements OnInit
{
    @ViewChild('captchaElem', { static: false }) captchaElem: InvisibleReCaptchaComponent;
    public captchaIsReady = false;

    redirectDelay = 0;
    showMessages: any = {};
    errors: string[] = [];
    messages: string[] = [];
    submitted = false;
    user: any = {
        // rememberMe :  false,
        email : null,
        password: null,
        captcha: ''
    };
    snackDuration: number;
    showInfoCaptcha: false;
    horizontalPosition: MatSnackBarHorizontalPosition = 'left';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param _fuseProgressBarService
     * @param authService
     * @param _router
     * @param cd
     * @param _snackBar
     * @param options
     * @param layoutOptions
     * @param recaptcha
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseProgressBarService: FuseProgressBarService,
        public authService: AuthService,
        private _router: Router,
        protected cd: ChangeDetectorRef,
        private _snackBar: MatSnackBar,
        @Inject(AUTH_OPTIONS) protected options = {},
        @Inject(AUTH_LAYOUT) protected layoutOptions = {}
    )
    {
        this.redirectDelay = this.getConfigValue('forms.login.redirectDelay');
        this.showMessages = this.getConfigValue('forms.login.showMessages');
        // Configure the layout
        this._fuseConfigService.config = {
            layout: this.layoutOptions['layout']
        };

        this.verticalPosition = this.layoutOptions['snackbar']['verticalPosition'];
        this.snackDuration = this.layoutOptions['snackbar']['duration'];
        this.showInfoCaptcha = this.layoutOptions['recaptcha']['showInfo'];
        this.authService.removeKey('AUTH_RESET');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    ngOnInit(): void
    {
        this._fuseProgressBarService.setMode('query');
    }

    forgot(): void {
        const currentResponse = this.captchaElem.getCurrentResponse();
        if (!currentResponse) {
          this.captchaElem.resetCaptcha();
          this._snackBar.open('Your Session Expired!, Please try Again!!', 'End now', {
            duration: this.snackDuration,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
          return;
        }
        this._fuseProgressBarService.show();
        this.errors = [];
        this.messages = [];
        this.submitted = true;
        this.authService.resetPassword(this.user, currentResponse)
            .then((user: CognitoUser|any) => {
                setTimeout (() => {
                    this.submitted = false;
                    this._fuseProgressBarService.hide();
                    this.authService.setUser('AUTH_RESET', this.user);
                    this._router.navigate(['auth/reset-pass']);
                    this.cd.detectChanges();
                },  this.snackDuration);
            })
            .catch((error: any) => {
                this.captchaElem.resetCaptcha();
                this._snackBar.open(error.message, 'End now', {
                    duration: this.snackDuration,
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                });
                setTimeout (() => {
                    this.submitted = false;
                    this._fuseProgressBarService.hide();
                },  this.snackDuration);
                // @ts-ignore
                switch (error.code) {
                    case 'UserNotConfirmedException':
                        this.authService.setUser('AUTH_RESET', this.user);
                        // this._router.navigate(['auth/confirm']);
                        break;
                    case 'UserNotFoundException':
                        break;
                }

            });
    }

    handleReset(): void {
      this.captchaIsReady = false;
      this.captchaElem.execute();
      this.cd.detectChanges();
    }

    handleSuccess(): void {
      this.captchaIsReady = true;
      this.cd.detectChanges();
    }

    handleReady(): void {
      this.captchaElem.execute();
      this.cd.detectChanges();
    }

    getConfigValue(key: string): any {
      return getDeepFromObject(this.options, key, null);
    }
}
