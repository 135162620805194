import {ModuleWithProviders, NgModule} from '@angular/core';
import { LoginModule } from 'app/main/auth/components/login/login.module';
import { RegisterModule } from 'app/main/auth/components/register/register.module';
import { ForgotPasswordModule } from 'app/main/auth/components/forgot-password/forgot-password.module';
import { ResetPasswordModule } from 'app/main/auth/components/reset-password/reset-password.module';
import { MailConfirmModule } from 'app/main/auth/components/mail-confirm/mail-confirm.module';
import {
    defaultAuthOptions,
    AUTH_OPTIONS,
    AUTH_USER_OPTIONS,
    AuthOptions,
    AUTH_LAYOUT,
    defaultAuthLayoutOptions
} from './auth.options';
import {AuthService} from './services/auth.service';
import {deepExtend} from './helpers';
import {PasswordStrengthModule} from './components/password-strength/password-strength.module';
import {TermDialogComponent} from './components/terms-dialog/term-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {CommonModule} from '@angular/common';
import {MatSnackBarModule} from '@angular/material/snack-bar';

export function OptionsFactory(options): any {
    return deepExtend(defaultAuthOptions, options);
}

@NgModule({
    declarations: [TermDialogComponent],
    exports: [TermDialogComponent],
    imports: [
        LoginModule,
        RegisterModule,
        ForgotPasswordModule,
        ResetPasswordModule,
        MailConfirmModule,
        PasswordStrengthModule.forRoot(),
        MatDialogModule,
        MatButtonModule,
        MatSnackBarModule,
        CommonModule,
    ],
    entryComponents: [TermDialogComponent]
})
export class AuthModule {
    static forRoot(authOptions?: AuthOptions): ModuleWithProviders {
        return {
            ngModule: AuthModule,
            providers: [
                { provide: AUTH_USER_OPTIONS, useValue: authOptions },
                { provide: AUTH_LAYOUT, useValue: defaultAuthLayoutOptions},
                { provide: AUTH_OPTIONS, useFactory: OptionsFactory, deps: [AUTH_USER_OPTIONS] },
                AuthService,
            ],
        } as ModuleWithProviders;
    }
}

