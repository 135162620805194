import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {Contact} from "../../contact.model";

@Component({
  selector: 'app-connect-popup',
  templateUrl: './connect-popup.component.html',
  styleUrls: ['./connect-popup.component.scss']
})
export class ConnectPopupComponent implements OnInit {

  contact: Contact;
  contactForm: FormGroup;
  showOnAvatar: any;
  customAvatarStyle = {
    backgroundColor: '#ff8420',
    border: '1px solid #7e7e7e',
    borderRadius: '50%',
    color: '#155a7e'
  };

  constructor(
      private _bottomSheetRef: MatBottomSheetRef<ConnectPopupComponent>,
      @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
      private _formBuilder: FormBuilder,
  ) {
    this.contact = new Contact(data);
    this.showOnAvatar = this.contact.avatarType;
    this.contactForm = this.createContactForm();

  }

  ngOnInit() {
  }

  createContactForm(): FormGroup
  {
    return this._formBuilder.group({
      id                  : [this.contact.id],
      firstName           : [this.contact.firstName, Validators.required],
      lastName            : [this.contact.lastName,  Validators.required],
      email               : [this.contact.email, [Validators.required, Validators.email]],
      phone               : [this.contact.phone],
      avatarType          : [this.contact.avatarType]
    });
  }

  addConnect() {
    this._bottomSheetRef.dismiss(this.contact);
  }

  cancelAll() {
    this._bottomSheetRef.dismiss(null );
  }

}
