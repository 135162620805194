export class UserSettingsModel
{
  id: string;
  userId: string;
  pageSize?: number;
  notiType?: any;
  _version?: any;
  /**
   * Constructor
   *
   * @param event
   */
  constructor(event?)
  {
    event = event || {};
    this.id = event.id || null;
    this.userId = event.userId || null;
    this.pageSize = event.pageSize || 10;
    this.notiType = event.notiType || 'NONE';
    this._version = event._version || null;
  }

}
