import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { fuseAnimations } from '@fuse/animations';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CourseAsset } from '../../course-content/media.model';
import { Subject } from 'rxjs';
import { QuizService } from '../quiz.service';
import { takeUntil } from 'rxjs/operators';
import { quizContent } from '../quiz.model';

@Component({
  selector: 'app-quiz-item',
  templateUrl: './quiz-item.component.html',
  styleUrls: ['./quiz-item.component.scss'],
  animations   : fuseAnimations,
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'always' } }
  ]
})
export class QuizItemComponent implements OnInit, OnDestroy {
  courseAsset: CourseAsset;
  quizContent: quizContent;
  pageType: string;
  refCourse: any  = null;
  private _unsubscribeAll: Subject<any>;

  constructor(
    private route: ActivatedRoute,
    private _quizService: QuizService,
    private _matSnackBar: MatSnackBar,
    private _location: Location,
    private _router: Router
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this._quizService.onContentChanged$
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(media => {
            this.courseAsset = media;
            // console.log("courseAsset ==> ", this.courseAsset);
            this.refCourse = media.course;
            this.pageType = ( media && media.id !== null ) ? 'edit' : 'new' ;
          });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  editQuizDetail(detailForm) {
    if (this.pageType === 'new') {
      this._quizService.addQuiz(detailForm)
        .then((res) => {
          this.eventMesssage(' Quiz Successfully  Added', 'success')
          this._location.go(`admin/quiz/${this.courseAsset.courseId}/${this.courseAsset.id}` );
        }).catch(error => {
          this.eventMesssage('Try Again Please or Call for Assistance!!', 'error')
      })
    } else {
      this._quizService.saveCourseAsset(detailForm)
        .then((res) => {
          this.eventMesssage(' Quiz Successfully updated', 'success')
          this._location.go(`admin/quiz/${this.courseAsset.courseId}/${this.courseAsset.id}` );
        }).catch(error => {
          this.eventMesssage('Try Again Please or Call for Assistance!!', 'error')
      })
    }
  }

  updateDataContent(detailForm) {
    this._quizService.saveContent(detailForm)
      .then((res) => {
        this.eventMesssage(' Quiz Successfully  updated', 'success')
        this._location.go(`admin/quiz/${this.courseAsset.courseId}/${this.courseAsset.id}` );
      }).catch(error => {
        this.eventMesssage('Try Again Please or Call for Assistance!!', 'error')
    })
  }

  addNewQuestion(){
    // console.log("courseAsset ==> ", this.courseAsset);
    this._router.navigate(['admin/quiz-question/' + this.courseAsset.id + '/new']);
  }

  private eventMesssage( msg: string, typeMsg: string ) {
    const customMsg = typeMsg === 'error' ? 'OOPS:' + msg : 'Success!!' + msg;
    this._matSnackBar.open(customMsg, 'OK', {
      verticalPosition: 'top',
      duration        : 2000
    });
  }
}
