import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {CourseService} from "../../../course.service";
import {Course} from "../../course.model";

@Component({
  selector: 'admin-course-video-upload',
  templateUrl: './admin-course-video-upload.component.html',
  styleUrls: ['./admin-course-video-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 100 })),
      transition('* => void', [animate(300, style({ opacity: 0 }))])
    ])
  ]
})
export class AdminCourseVideoUploadComponent implements OnInit {
  text = 'Drag a video file to this Drop Zone ...';
  /** Name used in form which will be sent in HTTP request. */
  param = 'file';
  /** File extension that accepted, same as 'accept' of <input type="file" />. By the default, it's set to 'image/*'. */
  accept = 'video/*';
  /** Allow you to configure drag and drop area shown or not. */
  ddarea = true;
  /** Allow you to add handler after its completion. Bubble up response text from remote. */
  multiple = false;

  files: Array<FileUploadModel> = [];
  startUploading: boolean;
  media: Course;
  constructor(
      private _bottomSheetRef: MatBottomSheetRef<AdminCourseVideoUploadComponent>,
      @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
      private ref: ChangeDetectorRef,
      private _courseService: CourseService,
      private _router: Router,
  ) {
    this.media = this.data;
  }

  ngOnInit() {
    this.startUploading = false;
  }

  onClick() {
    if (!this.multiple && this.files.length >= 1){
      return;
    }
    const fileUpload = document.getElementById(
        'fileUpload'
    ) as HTMLInputElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.addFileToList(file);
      }
      this.ref.detectChanges();
      this.resetUploadField();
    };
    fileUpload.click();
  }

  uploadAll() {
    this.startUploading = true;
    this.ddarea = false;
  }

  cancelAll() {
    this.files = [];
    this.startUploading = false;
    this.ddarea = true;
    this._bottomSheetRef.dismiss(null );
  }

  cancelFile(file: FileUploadModel) {
    if (file) {
      this.removeFileFromArray(file);
    }
  }

  successUpload(file: FileUploadModel) {
    if (file) {
      this.removeFileFromArray(file);
    }
    // if (this.files.length === 0) {
    //   this._bottomSheetRef.dismiss({} );
    // }
  }

  onFileUploaded(fileInfo) {
    const typeArray = fileInfo.file.type.split('/');
    const cType = ['mp4', 'avi', 'mp3', 'mpeg'].indexOf(typeArray[1].toLowerCase()) ===  -1 ? 'file' : 'video';
    if (cType === 'video') {
      this._courseService.saveMediaVideo(this.media, fileInfo )
          .then(result => {
            this._bottomSheetRef.dismiss(result );

          });
    }
  }

  private removeFileFromArray(file: FileUploadModel) {
    const index = this.files.indexOf(file);
    if (index > -1) {
      this.files.splice(index, 1);
    }
  }

  private dropHandler(ev: DragEvent) {
    ev.preventDefault();
    if (!this.multiple && this.files.length >= 1){
      return;
    }
    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (ev.dataTransfer.items[i].kind === 'file') {
          const file = ev.dataTransfer.items[i].getAsFile();
          this.addFileToList(file);
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.files.length; i++) {
        const file = ev.dataTransfer.files[i];
        this.addFileToList(file);
      }
    }
    this.resetUploadField();
  }

  private addFileToList(addedFile: any): void  {
    const fItem = this.files.filter((fU) => {
      return fU.data.name === addedFile.name;
    });
    if (fItem.length === 0 ) {
      this.files.push({
        data: addedFile,
        state: 'in',
        inProgress: false,
        progress: 0,
        canRetry: false,
        canCancel: true
      });
    }
  }

  private dragOverHandler(ev: DragEvent) {
    ev.preventDefault();
  }

  private resetUploadField() {
    const fileUpload = document.getElementById(
        'fileUpload'
    ) as HTMLInputElement;
    fileUpload.value = '';
  }

}

export class FileUploadModel {
  data: File;
  state: string;
  inProgress: boolean;
  progress: number;
  canRetry: boolean;
  canCancel: boolean;
}
