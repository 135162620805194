import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { fuseAnimations } from '@fuse/animations';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { QuizQuestionService } from '../quiz-question.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'question-item',
  templateUrl: './question-item.component.html',
  styleUrls: ['./question-item.component.scss'],
  animations   : fuseAnimations,
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'always' } }
  ]
})
export class QuestionItemComponent implements OnInit, OnDestroy {
  questionContent: any;
  pageType: string;
  refCourse: any  = null;
  courseAsset: any = null;
  qType: string;
  courseAssetId: string;
  quizId: string;
  private _unsubscribeAll: Subject<any>;

  constructor(
    private route: ActivatedRoute,
    private _questionService: QuizQuestionService,
    private _matSnackBar: MatSnackBar,
    private _location: Location,
    private _router: Router,
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this._questionService.onContentChanged$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(content => {
        // console.log("questioncontent --> ", content);
        this.questionContent = content;
        this.qType = this.questionContent.qType;
        if(this.questionContent.asset)
          this.courseAsset = this.questionContent.asset;
        this.questionContent.asset = this.courseAsset;
        this.pageType = (content && content.id !== null) ? 'edit' : 'new';
        this.courseAssetId = this.questionContent.asset.id;
        this.quizId = this.questionContent.asset.quiz.id;
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  saveData(values: any){
    if(this.pageType === "edit") {
      this.editQuestion(values);
    }else{
      this.createQuestion(values);
    }
  }

  createQuestion(values: any) {
    const data = {
      quizContentId: values.quizContentId,            // courseAssetId
      question: values.question,
      correctMessage: values.correctMessage,
      incorrectMessage: values.incorrectMessage,
      qType: values.qType,
      quizQuestionQuizId: values.quizQuestionQuizId   // quizId
    };
    this._questionService.addQuizQuestion(data)
      .then(res => {
        this.eventMesssage(' Question Successfully  Added', 'success');
        this._location.go(`admin/quiz-question/${this.courseAsset.id}/${res.id}` );
      })
      .catch(error => {
        this.eventMesssage(' Try Again Please or Call for Assistance!!', 'error')
      });
  }

  editQuestion(values: any){
    const data = {
      id: this.questionContent.id,
      quizContentId: values.quizContentId,
      question: values.question,
      correctMessage: values.correctMessage,
      incorrectMessage: values.incorrectMessage,
      qType: values.qType,
      sourceMedia: this.questionContent.sourceMedia,
      fileName: this.questionContent.fileName,
      fileType: this.questionContent.fileType,
      fileSize: this.questionContent.fileSize,
      quizQuestionQuizId: values.quizQuestionQuizId,
      _version: this.questionContent._version
    };
    this._questionService.updateQuizQuestion(data)
      .then(res => {
        this.eventMesssage(' Question Successfully Updated', 'success');
      })
      .catch(error => {
        this.eventMesssage(' Try Again Please or Call for Assistance!!', 'error');
      });
  }

  uploadFile(file: File){
    this._questionService.uploadQuestionFile(file, this.questionContent.id, this.courseAsset.id)
    .then(urlFile => {
      const fileName = urlFile.split("/").pop();
      const data = {
        id: this.questionContent.id,
        quizContentId: this.questionContent.quizContentId,
        question: this.questionContent.title,
        qType: this.questionContent.qType,
        labelCorrectMessage: this.questionContent.correctMessage,
        labelIncorrectMessage: this.questionContent.incorrectMessage,
        quizQuestionQuizId: this.questionContent.quizQuestionQuizId,
        sourceMedia: urlFile,
        fileName: fileName,
        fileType: file.type,
        fileSize: file.size,
        _version: this.questionContent._version
      }
      return this._questionService.updateQuizQuestion(data);
    })
    .then(res => {
      this.eventMesssage(' File Successfully Uploaded', 'success');
    })
    .catch(error => {
      this.eventMesssage(' Try Again Please or Call for Assistance!!', 'error');
    });
  }

  onChangeType(value: string){
    this.qType = value;
  }

  private eventMesssage( msg: string, typeMsg: string ) {
    const customMsg = typeMsg === 'error' ? 'OOPS:' + msg : 'Success!!' + msg;
    this._matSnackBar.open(customMsg, 'OK', {
      verticalPosition: 'top',
      duration        : 2000
    });
  }
}
