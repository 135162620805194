import { Component, OnInit, Inject } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 100 })),
      transition('* => void', [animate(300, style({ opacity: 0 }))])
    ])
  ]
})
export class FileUploadComponent implements OnInit {
  imgSrc: any = [];
  fileImg: File;

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<FileUploadComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  onSelect($event: any) {
    this.imgSrc = [];
    switch (typeof($event)) {
      case 'string':
        this.imgSrc = [$event];
        break;
      case 'object':
        this.imgSrc = $event;
        break;
      default:
    }
  }

  onReset() {
    this.imgSrc = [];
  }

  onCancel() {
    this.imgSrc = [];
    this._bottomSheetRef.dismiss(null);
  }

  onChangeImg($event) {
    this.fileImg = $event;
  }

  upload() {
    if (this.imgSrc.length === 0) {
      this.imgSrc = [];
      return;
    }
    const target = this.data.fileTarget;
    const base64Img = this.imgSrc[0].replace(/^data:image\/(png|jpg);base64,/, '');
    const base64data = Buffer.from(base64Img, 'base64');
    this._bottomSheetRef.dismiss({
      file : this.imgSrc[0].toString('utf8'),
      target : target
    } );
  }

}
