import {MatFormFieldModule} from '@angular/material/form-field';
import {RouterModule, Routes} from '@angular/router';
import {MatPaginatorModule} from '@angular/material/paginator';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {MatInputModule} from '@angular/material/input';
import {MatExpansionModule} from '@angular/material/expansion';
import {FuseSidebarModule, FuseWidgetModule, JwVideoPlayerModule} from '../../../../@fuse/components';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatChipsModule} from '@angular/material/chips';
import {MatRippleModule} from '@angular/material/core';
import {AmplifyAngularModule} from 'aws-amplify-angular';
import {ImgCropModule} from '../../../core/img-crop/img-crop.module';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSelectSearchModule} from '../../../core/mat-select-search/mat-select-search.module';
import {AuthGuard} from '../../auth/auth.guard';
import {COURSE_CONTENT_MANAGER_OPTIONS, CourseContentOptions} from './course-content.options';
import {ContentItemComponent} from './content-item/content-item.component';
import {ContentItemService} from './content-item.service';
import {CCFileUploadComponent} from './cc-file-upload/cc-file-upload.component';
import {CcFileDetailsComponent} from './cc-file-details/cc-file-details.component';
import {MatCardModule} from '@angular/material/card';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import { ContentDetailsComponent } from './components/content-details/content-details.component';
import {_MatMenuDirectivesModule, MatMenuModule, MatToolbarModule} from '@angular/material';
import { ContentMediaComponent } from './components/content-media/content-media.component';
import { ContentResourcesComponent } from './components/content-resources/content-resources.component';
import {MatFileUploadModule} from 'mat-file-upload';
import {ContentResourcesService} from './content-resources.service';
import { EditContentFileResourceComponent } from './components/edit-content-file-resource/edit-content-file-resource.component';
import { EditContentOtherResourceComponent } from './components/edit-content-other-resource/edit-content-other-resource.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatDialogModule} from "@angular/material/dialog";
import {MatVideoModule} from "mat-video";
import {ContentLoaderModule} from "../../../../core/loaders/content-loader.module";

const routes: Routes = [
    {
        path     : 'content/:courseId/:id',
        component: ContentItemComponent,
        resolve  : {
            data: ContentItemService
        },
        canActivate: [AuthGuard]
    },
    {
        path     : 'content/:courseId/new',
        component: ContentItemComponent,
        resolve  : {
            data: ContentItemService
        },
        canActivate: [AuthGuard]
    }
];

@NgModule({
    declarations: [
        ContentItemComponent,
        CCFileUploadComponent,
        CcFileDetailsComponent,
        ContentDetailsComponent,
        ContentMediaComponent,
        ContentResourcesComponent,
        EditContentFileResourceComponent,
        EditContentOtherResourceComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        FuseSharedModule,
        FuseWidgetModule,
        AmplifyAngularModule,
        ImgCropModule,
        MatGridListModule,
        MatProgressBarModule,
        MatSelectSearchModule,
        MatCardModule,
        MatBottomSheetModule,
        MatToolbarModule,
        FuseSidebarModule,
        MatFileUploadModule,
        _MatMenuDirectivesModule,
        MatMenuModule,
        JwVideoPlayerModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatVideoModule,
        ContentLoaderModule
    ],
  entryComponents: [CCFileUploadComponent,   EditContentFileResourceComponent, EditContentOtherResourceComponent]
})
export class CourseContentModule
{
    static forRoot(fmOptions?: CourseContentOptions): ModuleWithProviders {
        return {
            ngModule: CourseContentModule,
            providers: [
                { provide: COURSE_CONTENT_MANAGER_OPTIONS, useValue:  fmOptions},
                ContentItemService,
                ContentResourcesService
            ],
        } as ModuleWithProviders;
    }
}
