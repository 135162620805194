import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import {CourseCatalogService} from "../../../course-catalog.service";

@Component({
    selector   : 'catalog-main-sidebar',
    templateUrl: './catalog-side-bar.component.html',
    styleUrls  : ['./catalog-side-bar.component.scss']
})
export class CatalogSidebarComponent implements OnInit, OnDestroy
{
    user: any;
    filterBy: string;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param _courseCatalogService
     */
    constructor(
        private _courseCatalogService: CourseCatalogService
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.filterBy = this._courseCatalogService.filterBy || 'all';
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Change the filter
     *
     * @param filter
     */
    changeFilter(filter): void
    {
        this.filterBy = filter;
        this._courseCatalogService.setCatalogFilter(this.filterBy);
    }
}
