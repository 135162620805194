import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DataSource } from '@angular/cdk/collections';
import {Observable, of, Subject} from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';

import {UsersService} from '../users.service';
import {FuseSidebarService} from '../../../../../@fuse/components/sidebar/sidebar.service';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';

@Component({
    selector     : 'users-list',
    templateUrl  : './user-list.component.html',
    styleUrls    : ['./user-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class UserListComponent implements OnInit, OnDestroy
{
    @ViewChild('dialogContent', {static: false})
    dialogContent: TemplateRef<any>;
    contacts: any;
    user: any;
    dataSource: UserDataSource | null;
    displayedColumns = ['checkbox', 'name', 'email', 'phone_verified', 'email_verified',
        'account_status', 'user_status', 'updatedAt', 'createdAt', 'buttons'];
    selectedContacts: any[];
    checkboxes: {};
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    horizontalPosition: MatSnackBarHorizontalPosition = 'left';
    verticalPosition: MatSnackBarVerticalPosition = 'top';


    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param _usersService
     * @param _fuseSidebarService
     * @param {MatDialog} _matDialog
     * @param _snackBar
     */
    constructor(
        private _usersService: UsersService,
        private _fuseSidebarService: FuseSidebarService,
        public _matDialog: MatDialog,
        private _snackBar: MatSnackBar
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {


        this.dataSource = new UserDataSource(this._usersService);

        this._usersService.onUsersChanged$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(contacts => {
                this.contacts = contacts;

                this.checkboxes = {};
                contacts.map(contact => {
                    this.checkboxes[contact.id] = false;
                });
            });

        this._usersService.onSelectedUsersChanged$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(selectedContacts => {
                for ( const id in this.checkboxes )
                {
                    if ( !this.checkboxes.hasOwnProperty(id) )
                    {
                        continue;
                    }

                    this.checkboxes[id] = selectedContacts.includes(id);
                }
                this.selectedContacts = selectedContacts;
            });

        this._usersService.onUserDataChanged$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(user => {
                this.user = user;
            });

        this._usersService.onFilterChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this._usersService.deselectUsers();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    /**
     * On select
     *
     * @param selected
     */
    onSelect(selected): void {
        this._usersService.onUserSelected.next(selected);
        this._fuseSidebarService.getSidebar('user-details-sidebar').toggleOpen();
    }
    /**
     * Delete Contact
     */
    disableContact(contact): void {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Disable User(s) Are your sure?';
        this.confirmDialogRef.afterClosed().subscribe(result => {
            if ( result ) {
                this._usersService.disableUser(contact.id).then(res => {
                    of(this._usersService.refresh());
                }).catch(err => {
                  const msgShow = err && (err.Message || err.message) ? err.message
                    : 'OOPs!! Something went Wrong.. Try Again or Call for Support';
                  this.popMsg(msgShow);
                });
            }
            this.confirmDialogRef = null;
        });

    }

    enableContact(contact): void {
      this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
        disableClose: false
      });

      this.confirmDialogRef.componentInstance.confirmMessage = 'Enable User(s) Are your sure?';
      this.confirmDialogRef.afterClosed().subscribe(result => {
        if ( result ) {
          this._usersService.enableUser(contact.id).then(res => {
            of(this._usersService.refresh());
          }).catch(err => {
            const msgShow = err && (err.Message || err.message) ? err.message
              : 'OOPs!! Something went Wrong.. Try Again or Call for Support';
            this.popMsg(msgShow);
          });
        }
        this.confirmDialogRef = null;
      });

    }
    /**
     * On selected change
     *
     * @param contactId
     */
    onSelectedChange(contactId): void
    {
        this._usersService.toggleSelectedUser(contactId);
    }

    private popMsg(msgIn: string) {
      this._snackBar.open(msgIn, 'End now', {
        duration: 2000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }

}

export class UserDataSource extends DataSource<any> {
    /**
     * Constructor
     *
     */
    constructor(
        private _usersService: UsersService
    ) {
        super();
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]>
    {
        return this._usersService.onUsersChanged$;
    }

    /**
     * Disconnect
     */
    disconnect(): void {}
}
