import { elQuestionType, elStatus } from 'app/API.service';

export class QuestionContent {
  id: string;
  asset: any;
  quizContentId: string;
  order: number;
  title: string;
  qType: elQuestionType;
  labelCorrectMessage: string;
  labelIncorrectMessage: string;
  questionFeedBack: string;
  media: string;
  sourceMedia: string;
  fileName: string;
  fileType: string;
  fileSize: string;
  updatedAt: string;
  createdAt: string;
  status: elStatus;
  _version: number;
  quizQuestionQuizId: string;

  constructor(content?){
    content = content || {};
    this.id = content.id || null;
    this.quizContentId = content.quizContentId || null;
    this.order = content.order || null;
    this.title = content.title || null;
    this.qType = content.qType || elQuestionType.TrueFalse;
    this.labelCorrectMessage = content.labelCorrectMessage || null;
    this.labelIncorrectMessage = content.labelIncorrectMessage || null;
    this.questionFeedBack = content.questionFeedBack || null;
    this.media = content.media || null;
    this.sourceMedia = content.sourceMedia || null;
    this.fileName = content.fileName || null;
    this.fileType = content.fileType || null;
    this.fileSize = content.fileSize || null;
    this.updatedAt = content.updatedAt || new Date().toISOString();
    this.createdAt = content.createdAt || new Date().toISOString();
    this.status = content.status || elStatus.active;
    this._version = content._version || null;
    this.quizQuestionQuizId = content.quizQuestionQuizId || null;
  }
}
