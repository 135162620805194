import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { QuizQuestionOptions, QUIZ_QUESTION_MANAGER_OPTIONS } from './quiz-question.options';
import { QuestionItemComponent } from './question-item/question-item.component';
import { QuizQuestionService } from './quiz-question.service';
import { AuthGuard } from '../../auth/auth.guard';
import { FuseSidebarModule, FuseWidgetModule } from '../../../../@fuse/components';
import { FuseSharedModule } from '../../../../@fuse/shared.module';

import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule,  } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ImgCropModule } from '../../../core/img-crop/img-crop.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFileUploadModule} from 'mat-file-upload';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

import { QuestionDetailComponent } from './components/question-detail/question-detail.component';
import { QuestionChoicesComponent, DialogDeleteAnswer } from './components/question-choices/question-choices.component';
import { ModalAnswerComponent } from './components/modal-answer/modal-answer.component';

const routes: Routes = [
  {
    path: 'quiz-question/:courseAssetId/:id',
    component: QuestionItemComponent,
    resolve: {
      data: QuizQuestionService
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'quiz-question/:courseAssetId/new',
    component: QuestionItemComponent,
    resolve: {
      data: QuizQuestionService
    },
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [
    QuestionItemComponent,
    QuestionDetailComponent,
    QuestionChoicesComponent,
    ModalAnswerComponent,
    DialogDeleteAnswer
  ],
  entryComponents: [
    ModalAnswerComponent,
    DialogDeleteAnswer
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    FuseSidebarModule,
    FuseWidgetModule,
    FuseSharedModule,
    MatIconModule,
    MatTabsModule,
    MatInputModule,
    MatFormFieldModule,
    DragDropModule,
    MatToolbarModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatCheckboxModule,
    MatDividerModule,
    MatRadioModule,
    MatTableModule,
    MatSortModule,
    MatSnackBarModule,
    ImgCropModule,
    MatCardModule,
    MatExpansionModule,
    MatFileUploadModule,
    MatMenuModule,
    MatButtonModule
  ]
})
export class QuizQuestionModule {
  static forRoot(fmOptions?: QuizQuestionOptions): ModuleWithProviders {
    return {
        ngModule: QuizQuestionModule,
        providers: [
            { provide: QUIZ_QUESTION_MANAGER_OPTIONS, useValue:  fmOptions},
            QuizQuestionService
        ],
    } as ModuleWithProviders;
  }
}
