import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit, ViewChild,
  ViewEncapsulation
} from '@angular/core';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import { CognitoUser } from '@aws-amplify/auth';
import {AUTH_LAYOUT, AUTH_OPTIONS, AuthSocialLink} from '../../auth.options';
import {getDeepFromObject} from '../../helpers';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {FuseProgressBarService} from '../../../../../@fuse/components/progress-bar/progress-bar.service';
import {InvisibleReCaptchaComponent} from 'ngx-captcha';
import {SessionService} from '../../../../core/services/SessionService';

@Component({
    selector     : 'login',
    templateUrl  : './login.component.html',
    styleUrls    : ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit, OnDestroy
{
    redirectDelay = 0;
    showMessages: any = {};
    errors: string[] = [];
    messages: string[] = [];
    submitted : boolean = false;

    @ViewChild('captchaElem', { static: false }) captchaElem: InvisibleReCaptchaComponent;
    public captchaIsReady = false;
    // rememberMe = false;
    socialLinks: AuthSocialLink = {};
    user: any = {
        // rememberMe :  false,
        email : null,
        password: null,
        captcha: ''
    };
    snackDuration: number;
    showInfoCaptcha: false;
    horizontalPosition: MatSnackBarHorizontalPosition = 'left';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param _fuseProgressBarService
     * @param authService
     * @param _router
     * @param cd
     * @param _snackBar
     * @param sessionService
     * @param options
     * @param layoutOptions
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseProgressBarService: FuseProgressBarService,
        public authService: AuthService,
        private _router: Router,
        protected cd: ChangeDetectorRef,
        private _snackBar: MatSnackBar,
        private sessionService: SessionService,
        @Inject(AUTH_OPTIONS) protected options = {},
        @Inject(AUTH_LAYOUT) protected layoutOptions = {}
    )
    {
        this.redirectDelay = this.getConfigValue('forms.login.redirectDelay');
        this.showMessages = this.getConfigValue('forms.login.showMessages');
        // Configure the layout
        this._fuseConfigService.config = {
            layout: this.layoutOptions['layout']
        };
        // this.rememberMe = this.getConfigValue('forms.login.rememberMe');
        this.socialLinks = this.getConfigValue('forms.login.socialLinks');
        this.horizontalPosition = this.layoutOptions['snackbar']['horizontalPosition'];
        this.verticalPosition = this.layoutOptions['snackbar']['verticalPosition'];
        this.snackDuration = this.layoutOptions['snackbar']['duration'];
        this.showInfoCaptcha = this.layoutOptions['recaptcha']['showInfo'];

        // this.user.rememberMe =  this.rememberMe;
        this.authService.removeKey('AUTH_CONFIRM');
    }
    /**
     * On init
     */
    ngOnInit(): void {
      this._fuseProgressBarService.setMode('query');
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    login(): void {
        const currentResponse = this.captchaElem.getCurrentResponse();
        if (!currentResponse) {
            this.captchaElem.resetCaptcha();
            this._snackBar.open('Your Session Expired!, Please try Again!!', 'End now', {
              duration: this.snackDuration,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });
            return;
        }
        this.errors = [];
        this.messages = [];
        this.submitted = true;
        this._fuseProgressBarService.show();
        this.authService.login(this.user, currentResponse)
            .then((user: CognitoUser|any) => {
                return this.sessionService.saveAnalyticsWithUser('login', user);
            }).then((session: any) => {
                setTimeout(() => {
                  const redirect = this.getConfigValue('endPoints.redirectOnAuthorized');
                  this.submitted = false;
                  this._fuseProgressBarService.hide();
                  this._router.navigate([redirect]);
                  this.cd.detectChanges();
                }, this.snackDuration);
            }).catch((error: any) => {
                this.captchaElem.resetCaptcha();
                this._snackBar.open(error.message, 'End now', {
                  duration: this.snackDuration,
                  horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,
                });
                setTimeout(() => {
                  this.submitted = false;
                  this._fuseProgressBarService.hide();
                }, this.snackDuration);
                switch (error.code) {
                  case 'UserNotConfirmedException':
                    this.authService.setUser('AUTH_CONFIRM', this.user);
                    this._router.navigate(['auth/confirm']);
                    break;
                  case 'UserNotFoundException':
                    break;
                }
              });
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {}
    // code=9f27683d-00af-408c-9840-29b1c147d705&state=A7PrXmpXBXriNu65YYA4whkYJnTuQN9K
    async signInWithFacebook(): Promise<any> {
        const socialResult = await this.authService.socialSignIn(AuthService.FACEBOOK);
        console.log('fb Result:', socialResult);
    }

    async signInWithGoogle(): Promise<any> {
        const socialResult = await this.authService.socialSignIn(AuthService.GOOGLE);
        console.log('google Result:', socialResult);
    }

    handleReset(): void {
      this.captchaIsReady = false;
      this.captchaElem.execute();
      this.cd.detectChanges();
    }

    handleSuccess(): void {
      this.captchaIsReady = true;
      this.cd.detectChanges();
    }

    handleReady(): void {
      this.captchaElem.execute();
      this.cd.detectChanges();
    }

    getConfigValue(key: string): any {
        return getDeepFromObject(this.options, key, null);
    }
}
