import {Component, EventEmitter, Input, OnInit, Output,} from '@angular/core';
import {MatBottomSheet} from '@angular/material';
import {CCFileUploadComponent} from '../../cc-file-upload/cc-file-upload.component';
import * as moment from "moment";
import {ContentItemService} from "../../content-item.service";
import {Router} from "@angular/router";

@Component({
  selector: 'content-media',
  templateUrl: './content-media.component.html',
  styleUrls: ['./content-media.component.scss']
})
export class ContentMediaComponent implements OnInit {

  @Input()
  media: any;

  @Input()
  action: string;

  @Output()
  metadata = new EventEmitter<any>();
  onloadShow: boolean;
  videoExtensions : any[] =['mp4', 'OGG', 'ogg', 'MP4', 'WMV', 'wmv', 'WEBM', 'webm', 'FLV', 'flv', 'AVI', 'avi'];
  ifVideo: boolean = false;
  videoDuration: any;
  video: any;

  constructor(
    private _bottomSheet: MatBottomSheet,
    private _contentItemService: ContentItemService,
    private _router: Router,
  ) {
    this.onloadShow = false;
  }

  ngOnInit() {
    const extension = this.media.presignedUrl.split('.').pop();
    this.ifVideo = this.videoExtensions.includes(extension.slice(0, 3));
  }

  saveMetaData() {
    this.video = document.querySelector('video');
    this.videoDuration = moment.utc(moment.duration(this.video.duration, 'seconds').asMilliseconds()).format("HH:mm:ss");
    this.metadata.emit(this.videoDuration);
  }

  uploadMedia() {
    const dataToSave = this._bottomSheet.open(CCFileUploadComponent, {
      disableClose: true,
      data: this.media
    });
    dataToSave.afterDismissed().subscribe((data) => {
      this.onloadShow = true;
        setTimeout (() => {
          this.onloadShow = false;
          this._contentItemService.refreshAfterUpload(this.media.id);
        }, 1600);
    });
  }
}
