import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { MatBottomSheet } from '@angular/material';
import { FileUploadComponent } from '../file-upload/file-upload.component'
import { Course } from 'app/main/admin/courses/course/course.model';

@Component({
  selector: 'quiz-img-resource',
  templateUrl: './quiz-img-resource.component.html',
  styleUrls: ['./quiz-img-resource.component.scss']
})
export class QuizImgResourceComponent implements OnInit {
  @Input()
  eventData: Course;

  @Output()
  onFormChanges: EventEmitter<any> = new EventEmitter();

  constructor(
    private _bottomSheet: MatBottomSheet
  ) { }

  ngOnInit() {
  }

  openFileUploadSheet(target): void {
    const fileToUpload = this._bottomSheet.open(FileUploadComponent, {
      data: { fileTarget: target },
    });
  }
}
