import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {ReplaySubject, Subject} from "rxjs";
import {FuseSidebarService} from "../../../../@fuse/components/sidebar/sidebar.service";
import {MatDialog} from "@angular/material/dialog";
import {debounceTime, distinctUntilChanged, takeUntil} from "rxjs/operators";
import {StudentsService} from "./students.service";
import {fuseAnimations} from "../../../../@fuse/animations";
import {MemberShip} from "../../admin/contacts/org.model";

@Component({
  selector: 'students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class StudentsComponent implements OnInit, OnDestroy
{
  hasSelectedContacts: boolean;
  searchInput: FormControl;
  nextPage: any;
  userList: any;
  licForm: FormGroup;
  lic: MemberShip;

  selectedGroup: any;

  public licFilterCtrl: FormControl = new FormControl();
  public filteredServerSideContacts: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  // Private
  private _unsubscribeAll: Subject<any>;
  /**
   * Constructor
   *
   * @param _studentsService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {MatDialog} _matDialog
   * @param _formBuilder
   */
  constructor(
      private _studentsService: StudentsService,
      private _fuseSidebarService: FuseSidebarService,
      private _matDialog: MatDialog,
      private _formBuilder: FormBuilder,
  )
  {
    // Set the defaults
    this.searchInput = new FormControl('');
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this.lic = new MemberShip();
    this.licForm = this.createLicsForm();

  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------
  /**
   * On init
   */
  ngOnInit(): void {

      this.licFilterCtrl.valueChanges
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(() => {
              this._filterLicenses();
          });

      this._studentsService.onLicChanged$
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(licList => {
              console.log(licList);
              if (licList.length > 0) {
                  this.filteredServerSideContacts.next(licList);
                  this.licForm.controls['groupId'].setValue(licList[0]);
                  this._studentsService.setSelectedGroup(licList[0]);
              }
          });


      this.searchInput.valueChanges
          .pipe(
              takeUntil(this._unsubscribeAll),
              debounceTime(300),
              distinctUntilChanged()
          )
          .subscribe(searchText => {
              if(this.selectedGroup) {
                  const stxt = {
                      groupId: this.selectedGroup.groupId,
                      q : searchText
                  }
                  this._studentsService.onSearchTextChanged.next(stxt);
              }
          });



    this._studentsService.onSelectedGroup$
        .subscribe(selLic=> {
            if (selLic) {
                this.lic = new MemberShip(selLic);
                this.selectedGroup = selLic;
                this._studentsService.refreshStudents(this.selectedGroup.groupId);
            }

        });


    this._studentsService.onUsersChanged$
        .subscribe(userList => {
          this.userList = userList;
        });


    this._studentsService.onNextPageChanged$
        .subscribe(nextToken => {
          this.nextPage = nextToken;
        });

    this._studentsService.onSelectedUsersChanged$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(selectedContacts => {
          this.hasSelectedContacts = selectedContacts.length > 0;
        });


  }
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onSelectGroup($event) {
    if ($event.value !== undefined) {
      this._studentsService.setSelectedGroup($event.value);
    }
  }

  refreshUsers() {
    if (this.selectedGroup) {
      this._studentsService.setSelectedGroup(this.selectedGroup);
    }
  }

  gotoNextPage(nextPage): void {
    this._studentsService.goNextPage(nextPage);
  }
  /**
   * Toggle the sidebar
   *
   * @param name
   */
  toggleSidebar(name): void {
    this._fuseSidebarService.getSidebar(name).toggleOpen();
  }

  importUsers(): void {
    this._studentsService.openSideBar(this.selectedGroup);
    this._fuseSidebarService.getSidebar('import-users-sidebar').toggleOpen();
  }

  createLicsForm(): FormGroup
  {
    return this._formBuilder.group({
        groupId  : [this.lic.id]
    });
  }

  private _filterLicenses() {
    const search = this.licFilterCtrl.value;
    if (!search) {
      return;
    }
    this._studentsService.getMemberShips(search)
        .then((res) => {
          this.filteredServerSideContacts.next(res);
        });
  }
}
