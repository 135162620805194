import {Inject, Injectable} from '@angular/core';
import {getDeepFromObject} from '../../auth/helpers';
import {AuthService} from '../../auth/services/auth.service';
import {
    APIService,
    UpdateMembershipInput,
} from "../../../API.service";
import * as AWS from "aws-sdk";
import {STUDENTS_MANAGER_OPTIONS} from "./students.options";
import {API} from "aws-amplify";

@Injectable()
export class LicenseStudentService {

    pageSize: number;

    bucket: any;

    /**
     * Constructor
     *
     * @param options
     * @param api
     * @param _authService
     */
    constructor(
        @Inject(STUDENTS_MANAGER_OPTIONS) protected options = {},
        private api: APIService,
        private _authService: AuthService,
    )
    {
        // Set the defaults
        this.pageSize = this.getConfigValue('pageSize');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // ----------------------------------------------------------------------------------------------------

    syncCognito(): any {
        return new AWS.CognitoIdentityServiceProvider(
            {
                apiVersion: '2016-04-18',
                region: this.getConfigValue('region'),
                accessKeyId: this.getConfigValue('accessKeyId'),
                secretAccessKey: this.getConfigValue('secretAccessKey')
            }
        );
    }

    createUser(user: any, groupName: string, lic?:any): Promise<any> {
        const orgMeta= {
            id: user.orgMeta.id,
            contactName: user.orgMeta.contactName
        }
        return new Promise((resolve, reject) => {
            let newUser: any = { user : null, group: null, lic: null};
            const body = {
                body: {
                    email: user.email,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    phone: user.phone,
                    orgId: user.orgId,
                    orgMeta: orgMeta,
                }
            };
            let username = null;
            API.post('apiAdminQueries', '/createUser', body)
                .then(userCreation => {
                    newUser.user = userCreation;
                    username = userCreation.body.UserSub;
                    return this.addUserToGroup(groupName,username);
                }).then(userGroupCompany => {
                    newUser.group = userGroupCompany;
                    return this.addUserToGroup('USERS',username);
                }).then(userGroup => {
                    newUser.groupUser = userGroup;
                    const payload: UpdateMembershipInput = {
                        id : lic.id,
                        noLicenses: lic.noLicenses - 1,
                        noClaimed : lic.noClaimed + 1,
                        _version: lic._version,
                        updatedAt: new Date().toISOString()
                    }
                    return this.api.UpdateMembership(payload);
                }).then(updateLicence => {
                    newUser.lic = updateLicence;
                    resolve(newUser);
                }).catch(error => {
                    reject(error);
                });
        });
    }

    relateUser(user: any, groupName: string, lic?:any): Promise<any> {
        const orgMeta= {
            id: user.orgMeta.id,
            contactName: user.orgMeta.contactName
        }
        return new Promise((resolve, reject) => {
            let relate: any = { user : null, group: null, lic: null};
            const body = {
                body: {
                    id: user.id,
                    orgId: user.orgId,
                    orgMeta: orgMeta,
                }
            };
            API.post('apiAdminQueries', '/updateUserAttributes', body)
            .then(resultUpdateAttr => {
                relate.user = resultUpdateAttr;
                return this.addUserToGroup(groupName, groupName);
            }).then(result=> {
                relate.group = result;
                const payload: UpdateMembershipInput = {
                    id: lic.id,
                    noLicenses: lic.noLicenses - 1,
                    noClaimed: lic.noClaimed + 1,
                    _version: lic._version,
                    updatedAt: new Date().toISOString()
                }
                return this.api.UpdateMembership(payload);
            }).then(updateLicence=>{
                relate.lic = updateLicence;
                resolve(relate);
            }).catch(error => {
                reject(error);
            });
        });
    }

    addUserToGroup( groupName: string, userName: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const conn = this.syncCognito();
            const params = {
                UserPoolId: this.getConfigValue('UserPoolId'),
                GroupName: groupName,
                Username: userName
            };
            conn.adminAddUserToGroup(params, (err, data) => {
                if (err) {
                    reject(err);
                }
                resolve(data);
            });
        });
    }

    getListGroupsForUser(username: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const conn = this.syncCognito();
            const params = {
                UserPoolId: this.getConfigValue('UserPoolId'),
                Username: username,
                Limit: 10
            };
            conn.adminListGroupsForUser(params, (err, data) => {
                if (err) {
                    resolve([]);
                }
                resolve(data.Groups);
            });
        });
    }

    userExistsByEmail(byEmail: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const conn = this.syncCognito();
            const params = {
                UserPoolId: this.getConfigValue('UserPoolId'),
                Limit: 2
            };
            params['Filter'] = `email ^= "${byEmail}"`;
            conn.listUsers(params, (err, data) => {
                if (err) {
                    reject(err);
                }
                resolve(data.Users);
            });
        });
    }

    getConfigValue(key: string): any {
        return getDeepFromObject(this.options, key, null);
    }

}
