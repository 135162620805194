import {Component, Inject, OnInit} from '@angular/core';
import {Contact} from "../../../../admin/contacts/contact.model";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {LicenseStudentService} from "../../license-student.service";
import {getDeepFromObject} from "../../../../auth/helpers";
import * as _ from "lodash";

@Component({
  selector: 'student-import-popup',
  templateUrl: './student-import-popup.component.html',
  styleUrls: ['./student-import-popup.component.scss']
})
export class StudentImportPopupComponent implements OnInit {

  contact: Contact;
  contactForm: FormGroup;
  isUserExist: boolean = false;
  usersFound: any[];
  userLic : any;
  userOrgId: string;

  constructor(
      private _bottomSheetRef: MatBottomSheetRef<StudentImportPopupComponent>,
      @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
      private _formBuilder: FormBuilder,
      private _licenseStudentService: LicenseStudentService,
  ) {
    this.userLic = data;
    this.contact = new Contact(this.userLic.contact);
    this.isUserExist = false;
    this.contactForm = this.createContactForm();

  }

  ngOnInit() {
    this._licenseStudentService.userExistsByEmail(this.contact.email)
        .then(result=> {
          this.isUserExist = result.length>0;
          this.usersFound = result;
          this.userOrgId = null;

        });
  }

  createContactForm(): FormGroup
  {
    return this._formBuilder.group({
      id                  : [this.contact.id],
      firstName           : [this.contact.firstName, Validators.required],
      lastName            : [this.contact.lastName,  Validators.required],
      email               : [this.contact.email, [Validators.required, Validators.email]],
      phone               : [this.contact.phone],
    });
  }

  addConnect() {
    const data = this.contactForm.getRawValue();
    if (!this.isUserExist) {
      const user = {
        orgMeta : {
          id: this.userLic.contactId,
          contactName: this.userLic.license.org.contactName
        },
        email : data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phone ,
        orgId: this.userLic.license.contactId
      }
      this._licenseStudentService.createUser(user, this.userLic.license.groupId, this.userLic.license )
          .then(result=>{
            this._bottomSheetRef.dismiss(this.userLic.next);
          })
    }
  }

  relateConnect() {
    const data = this.contactForm.getRawValue();
    if (this.isUserExist && this.usersFound.length===1) {
      this.userOrgId = this.getAttrValue('custom:organizationId', this.usersFound[0]);
      this._licenseStudentService.getListGroupsForUser(data.usersFound[0].Username)
          .then(resultGroups => {
              const found = this.findGroupInLicense(resultGroups);
            if (!found) {
              const user = {
                orgMeta: {
                  id: this.userLic.license.contactId,
                  contactName: this.userLic.license.org.contactName
                },
                id: data.usersFound[0].Username,
                orgId: this.userLic.license.contactId
              }
              this._licenseStudentService.relateUser(user,
                  this.userLic.license.groupId,
                  this.userLic.license)
                  .then(result => {
                      this._bottomSheetRef.dismiss(this.userLic.next);
                  })
            }
          })
    }
  }

  cancelAll() {
    this._bottomSheetRef.dismiss(this.userLic.next );
  }

  findGroupInLicense(groupsList: any[], group?:string) {
    const filter = group ? group : this.userLic.license.groupId;
    return  _.find(groupsList, function(arrayValue){
      return arrayValue.GroupName === filter;
    });
  }

  getAttrValue(key: string, user: any): any {
    const userInfo = user.attributes;
    return getDeepFromObject(userInfo, key, null);
  }

}
