import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {fuseAnimations} from "../../../../../../@fuse/animations";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from "@angular/material/snack-bar";
import {of, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import * as _ from "lodash";
import {StudentsService} from "../../students.service";

@Component({
  selector: 'student-view-sidebar',
  templateUrl: './student-view-sidebar.component.html',
  styleUrls: ['./student-view-sidebar.component.scss'],
  animations : fuseAnimations
})
export class StudentViewSidebarComponent implements OnInit, OnDestroy
{
  selected: any;
  selectedAttr: any;
  identities: any;
  identityPicture: any;
  isDirty: boolean;
  horizontalPosition: MatSnackBarHorizontalPosition = 'left';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  @ViewChild('saveButton', {static: false}) saveButton: ElementRef;
  @ViewChild('enableButton', {static: false}) enableButton: ElementRef;
  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param _studentService
   * @param _matDialog
   * @param _snackBar
   */
  constructor(
      private _studentService: StudentsService,
      public _matDialog: MatDialog,
      private _snackBar: MatSnackBar
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this.isDirty = true;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._studentService.onUserSelected$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(selected => {
          if (!_.isEmpty(selected)){
            console.log(selected)
            this.selected = selected;
            this.selectedAttr = selected && selected['attributes'] ? selected['attributes'] : false;
            this.identities = selected.external && selected.identities;
            if (selected.external && selected.picture) {
              this.identityPicture = selected.picture;
            } else {
              console.log(selected.picture)
              this._studentService.getS3AvatarFileByName(selected.picture)
                  .then(imageBase64 => {
                    this.identityPicture = imageBase64;
                  });
            }
            this.isDirty = true;
          }
        });
  }
  
  /**
   * On destroy
   */
  ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  

  refreshUser(){
    of(this._studentService.getUser(this.selected.id));
  }

  private popMsg(msgIn: string) {
    this._snackBar.open(msgIn, 'End now', {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

}
