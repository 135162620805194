import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {
  APIService,
  ModelCourseFilterInput
} from '../../../API.service';
import {getDeepFromObject} from '../../auth/helpers';
import {COURSES_MANAGER_OPTIONS} from './courses.options';
import * as _ from 'lodash';
import {Hub, Logger} from '@aws-amplify/core';
import {SyncSettings} from '../../../../@fuse/services/sync-settings.service';
import {FuseProgressBarService} from '../../../../@fuse/components/progress-bar/progress-bar.service';
import {AuthService} from "../../auth/services/auth.service";
import {APICustomService} from "../../../API.customize.service";

@Injectable()
export class CourseListService implements Resolve<any>
{
  private logger = new Logger('Courses List');

  courses: any[];
  pageSize: number;
  nextToken: string = null;

  private onCoursesChanged: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public onCoursesChanged$: Observable<any[]> = this.onCoursesChanged.asObservable();

  private nextPageToken: BehaviorSubject<any> = new BehaviorSubject(this.nextToken);
  public nextPageToken$: Observable<any[]> = this.nextPageToken.asObservable();
  /**
   * Constructor
   *
   * @param options
   * @param api
   * @param customApi
   * @param _auth
   * @param _fuseProgressBarService
   */
  constructor(
      @Inject(COURSES_MANAGER_OPTIONS) protected options = {},
      private api: APIService,
      private customApi: APICustomService,
      private _auth: AuthService,
      private _fuseProgressBarService: FuseProgressBarService
  )
  {
    this.pageSize = this._auth.getUserSettings().pageSize;
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
  {
      return new Promise((resolve, reject) => {
          this.pageSize = this._auth.getUserSettings().pageSize;
          Promise.all([
              this.getCourses()
          ]).then(() => {
                  Hub.listen('settings', (data) => {
                      this.pageSize = data.payload.data.pageSize;
                      console.log(this.pageSize)
                      this.refresh();
                  });
                  resolve();
              },
              reject
          );
      });
  }

  public refresh() {
    of(this.getCourses());
  }

  public goNextPage(filterTxt: string, nextPageToken: string) {
    of(this.getCourses(filterTxt,  nextPageToken));
  }

  getCourses(searchTxt?: string, nextToken?: string): Promise<any> {
      let filter: ModelCourseFilterInput ;
      if (searchTxt !== undefined && searchTxt) {
          filter = {
              title: { contains: searchTxt}
          };
      }
      this.nextToken = nextToken ? nextToken : null;
      this._fuseProgressBarService.show();
      return new Promise((resolve, reject) => {
          this.customApi.ListCourses(filter, this.pageSize, this.nextToken)
              .then((result) => {
                  // console.log('courses', result, this.pageSize);
                  this.nextToken = !_.isEmpty(result['nextToken']) ? result['nextToken'] : null;
                  this.nextPageToken.next(this.nextToken );
                  this.courses = result.items;
                  this.onCoursesChanged.next(result.items);
                  this._fuseProgressBarService.hide();
                  resolve(this.courses);
              })
              .catch((err) => {
                this.catchError(err);
                reject(err);
              });
      });
  }

  private catchError(error) {
    console.log(error);
    this.logger.debug('OOPS!', error);
    this._fuseProgressBarService.hide();
  }

  getConfigValue(key: string): any {
      return getDeepFromObject(this.options, key, null);
  }
}
