import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';
import { CourseAsset } from 'app/main/admin/course-content/media.model';
import { FormGroup, FormBuilder } from '@angular/forms';

interface ContentStatus {
  value: string;
  name: string;
}

@Component({
  selector: 'quiz-details',
  templateUrl: './quiz-details.component.html',
  styleUrls: ['./quiz-details.component.scss']
})
export class QuizDetailsComponent implements OnInit{

  @Input()
  courseAsset: CourseAsset;

  @Input()
  pageType: string;

  @Output() formChanges: EventEmitter<any> = new EventEmitter<any>();

  detailForm: FormGroup;
  optCourses: any[] = [];
  statusOptions: ContentStatus[] = [
    { value: 'active', name: 'Active'},
    { value: 'inactive', name: 'Inactive'},
    { value: 'new', name: 'New'}
  ];

  constructor(
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.detailForm = this.createDetailForm();
    this.optCourses.push(this.courseAsset.course);
    this.detailForm.controls['courseId'].setValue(this.courseAsset.course.id);
    this.detailForm.controls['status'].setValue(this.statusOptions[2].value);
  }

  createDetailForm(): FormGroup {
    let timeAprox = "00:00";
    if(typeof this.courseAsset.timeAprox === "string"){
      timeAprox = this.courseAsset.timeAprox;
    }
    return this._formBuilder.group({
      id: [this.courseAsset.id],
      title: [this.courseAsset.title],
      description: [this.courseAsset.description],
      courseId: [this.courseAsset.courseId],
      timeAprox: timeAprox,
      status: [this.courseAsset.status],
      _version: [this.courseAsset._version]
    });
  }

  saveData(value:any){
    console.log(value);
    this.formChanges.emit(value)
  }

}
