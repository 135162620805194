import {Inject, Injectable, OnDestroy} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {
    APIService,
    ContentType,
    CreateContentInput,
    CreateCourseAssetsInput,
    elAssetType,
    ModelCourseFilterInput,
    UpdateContentInput, UpdateCourseAssetsInput
} from '../../../API.service';
import {getDeepFromObject} from '../../auth/helpers';
import AWSConfig from './../../../../aws-exports';
import * as S3 from 'aws-sdk/clients/s3';
import {COURSE_CONTENT_MANAGER_OPTIONS} from './course-content.options';
import {SyncSettings} from '../../../../@fuse/services/sync-settings.service';
import {API} from 'aws-amplify';
import {CourseAsset, Content} from './media.model';
import {FuseProgressBarService} from '../../../../@fuse/components/progress-bar/progress-bar.service';
import {Logger} from '@aws-amplify/core';


@Injectable()
export class ContentItemService implements Resolve<any>, OnDestroy
{
    private logger = new Logger('Content Item Service');
    routeParams: any;
    content: CourseAsset;
    mediaContent: Content;
    pageSize: number;
    nextToken: string;

    private onContentChanged = new BehaviorSubject<any>(null);
    onContentChanged$ = this.onContentChanged.asObservable();


    private onMediaContentChanged = new BehaviorSubject<any>(null);
    onMediaContentChanged$ = this.onMediaContentChanged.asObservable();

    private _onErrorOrSuccess = new BehaviorSubject<any>(null);
    onErrorOrSuccess$ = this._onErrorOrSuccess.asObservable();


    bucket: any;
    bucketTarget: any;
    bucketTranscoded: any;

    private _unsubscribeAll: Subject<any>;
    /**
     * Constructor
     *
     * @param options
     * @param api
     * @param syncSettings
     * @param _fuseProgressBarService
     */
    constructor(
        @Inject(COURSE_CONTENT_MANAGER_OPTIONS) protected options = {},
        private api: APIService,
        private syncSettings: SyncSettings,
        private _fuseProgressBarService: FuseProgressBarService,
    ) {
        // Set the defaults
        this.bucket = AWSConfig['aws_user_files_s3_bucket'];
        this.bucketTarget = this.getConfigValue('S3BucketName');
        this.bucketTranscoded = this.getConfigValue('S3BucketTranscoder');
        this.pageSize = this.getConfigValue('pageSize');

        this.syncSettings.onSettingsChanged$
          .subscribe((res) => {
            if (res !== null) {
              this.pageSize = res.pageSize;
              this.nextToken = null;
            }
          });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.routeParams = route.params;
        if (!this.routeParams.id || !this.routeParams.courseId) {
            return false;
        }
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getContent(this.routeParams.id, this.routeParams.courseId)
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    refreshVideo() {
        of(this.getContent(this.routeParams.id, this.routeParams.courseId));
    }

    refreshAfterUpload(contentId: string) {
        of(this.getContentAfterUpload(contentId));
    }

    /**
     * Get content
     *
     * @returns {Promise<any>}
     */
    getContent(contentId: string, courseId: string): Promise<any> {
      this._fuseProgressBarService.show();
      return new Promise((resolve, reject) => {
        if (contentId === 'new' ) {
            this.api.GetCourse(courseId)
                .then((cDetails) => {
                    this.content = new CourseAsset();
                    this.content.course = cDetails;
                    this.onContentChanged.next(this.content);
                    resolve(cDetails);
                })
                .catch((err) => {
                    this.catchError(err);
                    reject(err);
                });
        } else {
          this.api.GetCourseAssets(contentId)
            .then(resultAsset => {
                this.content = resultAsset;
                return this.api.GetContent(this.content.topic.id);
            }).then(resultTopic => {
                this.mediaContent = resultTopic;
                return this.getPresignedFile(this.mediaContent);
            }).then(result => {
                this.content.presignedUrl = result;
                return this.getPresignedPoster(this.mediaContent);
            }).then(resPoster => {
                this.content.posterFileUrl = resPoster;
                this.onContentChanged.next(this.content);
                this._fuseProgressBarService.hide();
                resolve(this.content);
            }).catch((err) => {
              this.catchError(err);
              reject(err)
            }) ;
        }
      });
    }

    getContentAfterUpload(contentId: string): Promise<any> {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this.api.GetCourseAssets(contentId)
                .then(resultAsset => {
                    this.content = resultAsset;
                    return this.api.GetContent(this.content.topic.id);
                }).then(resultTopic => {
                    this.mediaContent = resultTopic;
                    return this.getTransVideoInfo(this.mediaContent.transcoderJobId);
                }).then(resultTrans  => {
                    const readyTrans = resultTrans && resultTrans.Output.Status === 'Complete';
                    if (readyTrans) {
                        return this.getPresignedFile(this.mediaContent);
                    } else {
                        const sourceFile = `kidsthatcode-media-resources/video/${this.mediaContent.courseId}/${this.content.id}/${this.mediaContent.fileName}`;
                        return this.getPresignedSourceFile(sourceFile);
                    }
                }).then(result => {
                    this.content.presignedUrl = result;
                    this.content.posterFileUrl = 'assets/images/courses/course-default.png';
                    this.onContentChanged.next(this.content);
                    this._fuseProgressBarService.hide();
                    resolve(this.content);
                }).catch((err) => {
                    this.catchError(err);
                    reject(err)
                }) ;
        });
    }

    getCourses(searchTxt?: string, bySearch?: string): Promise<any> {
        let filter: ModelCourseFilterInput ;
        if (searchTxt !== undefined && searchTxt) {
            if (bySearch === 'title') {
                filter = {
                    [bySearch]: { contains: searchTxt}
                };
            } else {
                filter = {
                    [bySearch]: { eq: searchTxt}
                };
            }

        }
        return new Promise((resolve, reject) => {
            this.api.ListCourses(filter, this.pageSize)
                .then((result) => {
                    resolve(result.items);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    getTransVideoInfo(jobId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            if (jobId===null) {
                resolve(null);
            } else {
                const init = {
                    queryStringParameters: {
                        jobId: jobId
                    },
                };
                API.get('coreApiKTC', '/video', init)
                    .then(result => {
                        resolve(result);
                    }).catch((error: any) => {
                        this.catchError(error);
                        reject(error);
                    });
            }
        });
    }
    /**
     * @returns {Promise<any>}
     * @param media
     * @param fileInfo
     */
    saveMediaVideo(media: any,  fileInfo: any): Promise<any> {
      this._fuseProgressBarService.show();
      return new Promise((resolve, reject) => {
      const transTarget = `${this.bucketTarget}/video/${media.courseId}/${media.id}/${fileInfo.file.name}`;
      const videoTimeStamp = new Date().getTime();
      const body = {
        body: {
            fileKey: transTarget,
            filePath: `${media.courseId}/${media.id}`,
            filePrefix: `${fileInfo.file.name.split('.')[0]}-${videoTimeStamp}`
        }
      };
      API.post('coreApiKTC', '/video', body)
        .then(transResult => {
          const transVideoJson = JSON.stringify(transResult);
          const cDate = new Date().toISOString();
          const transVideoUrl = `${body.body.filePath}/${body.body.filePrefix}`;
          const payload: UpdateContentInput = {
            id: media.topic.id,
            contentType: ContentType.Video,
            fileName: fileInfo.file.name,
            fileType: fileInfo.file.type,
            fileSize: fileInfo.file.size,
            media: fileInfo.fileUpload.Location,
            sourceMedia: transVideoUrl,
            updatedAt: cDate,
            transcoderMeta: transVideoJson,
            transcoderJobId: transResult.id,
            _version: media.topic._version
          };
          return this.api.UpdateContent(payload);
        }).then((result) => {
          this._fuseProgressBarService.hide();
          this._onErrorOrSuccess.next({type: 'SUCCESS'});
          resolve(result);
        }).catch((err) => {
          this._onErrorOrSuccess.next({type: 'error', msg: err.message});
          this.catchError(err);
          reject(err);
        });
      });
    }

    addContent(newContent: any): Promise<any> {
        const dateAt = new Date().toISOString();
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            const contentInput: CreateContentInput = {
                courseId: newContent.courseId,
                contentType: ContentType.Video,
                createdAt: dateAt,
                updatedAt: dateAt
            };
            this.api.CreateContent( contentInput)
                .then(result => {
                    const assetInput: CreateCourseAssetsInput = {
                        title: newContent.title,
                        assetType: elAssetType.Topic,
                        description: newContent.notes,
                        courseId: newContent.courseId,
                        courseAssetsCourseId: newContent.courseId,
                        courseAssetsTopicId: result.id,
                        timeAprox: '0',
                        starred: false,
                        important: false,
                        showAsOverview: false,
                        showAsFree: false,
                        order: 0 ,
                        createdAt: dateAt,
                        updatedAt: dateAt,
                        status: newContent.status
                    };
                    return this.api.CreateCourseAssets( assetInput);
                })
                .then((resultAsset) => {
                    this.content = resultAsset;
                    this.onContentChanged.next(this.content);
                    this._fuseProgressBarService.hide();
                    resolve(resultAsset);
                })
                .catch((err) => {
                    this.catchError(err);
                    reject(err);
                });
        });

    }

    saveVideoMeta(newContent: any): Promise<any> {
        const cDate = new Date().toISOString();
        this._fuseProgressBarService.show();
        const courseInput: UpdateCourseAssetsInput= {
            id: newContent.id,
            timeAprox: newContent.timeAprox,
            updatedAt: cDate,
            _version: newContent._version
        };
        return new Promise((resolve, reject) => {
            this.api.UpdateCourseAssets( courseInput)
                .then((result) => {
                    this.content = result;
                    this._fuseProgressBarService.hide();
                    this.onContentChanged.next(this.content);
                    resolve(result);
                })
                .catch((err) => {
                    this.catchError(err);
                    reject(err);
                });
        });
    }

    saveContent(newContent: any): Promise<any> {
      this._fuseProgressBarService.show();
      const cDate = new Date().toISOString();
      const courseInput: UpdateCourseAssetsInput= {
        id: newContent.id,
        title: newContent.title,
        description: newContent.notes,
        timeAprox: newContent.timeAprox,
        order: newContent.order,
        status: newContent.status,
        updatedAt: cDate,
        _version: newContent._version
      };
      return new Promise((resolve, reject) => {
        this.api.UpdateCourseAssets( courseInput)
          .then((result) => {
            this.content = result;
            this._fuseProgressBarService.hide();
            this.onContentChanged.next(this.content);
            resolve(result);
          })
          .catch((err) => {
            this.catchError(err);
            reject(err);
          });
      });
    }

    syncS3(): any {
        return new S3(
            {
                accessKeyId: this.getConfigValue('accessKeyId'),
                secretAccessKey: this.getConfigValue('secretAccessKey'),
                region: this.getConfigValue('region')
            });
    }

    getPresignedFile(media: any): Promise<any> {
      return new Promise((resolve, reject) => {
          if (media.sourceMedia && media.sourceMedia.length > 10) {
            const params = {Bucket: '', Key: '', Expires: 3600};
            const newConn = this.syncS3();
            params.Bucket = this.bucketTranscoded;
            params.Key = `${media.sourceMedia}-web-720p.mp4`;
            newConn.getSignedUrl('getObject', params, (err, urlLink) => {
              if (err) {
                this.catchError({type: 'error', msg: err.message});
                resolve('assets/images/backgrounds/blue-background.jpg');
              } else {
                resolve(urlLink);
              }
            });
          } else {
            resolve('assets/images/backgrounds/blue-background.jpg');
          }
        });
    }

    getPresignedSourceFile(fileSource: string): Promise<any> {
        return new Promise((resolve, reject) => {
            if (fileSource && fileSource.length > 10) {
                const params = {Bucket: '', Key: '', Expires: 1000};
                const newConn = this.syncS3();
                params.Bucket = this.bucket;
                params.Key = fileSource;
                newConn.getSignedUrl('getObject', params, (err, urlLink) => {
                    if (err) {
                        this.catchError({type: 'error', msg: err.message});
                        resolve('assets/images/backgrounds/blue-background.jpg');
                    } else {
                        resolve(urlLink);
                    }
                });
            } else {
                resolve('assets/images/backgrounds/blue-background.jpg');
            }
        });
    }

    getPresignedPoster(media: any): Promise<any> {
        return new Promise((resolve, reject) => {
            if (media.sourceMedia && media.sourceMedia.length > 10) {
                const params = {
                  Bucket:  this.bucketTranscoded,
                  Key: `${media.sourceMedia}thumbs-720p-00001.png`,
                  Expires: 3600
                };
                const newConn = this.syncS3();
                newConn.getSignedUrl( 'getObject', params, (err, urlLink) => {
                  if (err) {
                    resolve('assets/images/courses/course-default.png');
                  } else {
                    resolve(urlLink);
                  }
                });
          } else {
            resolve('assets/images/courses/course-default.png');
          }
        });
    }

    getConfigValue(key: string): any {
        return getDeepFromObject(this.options, key, null);
    }

    private catchError(error) {
      console.log(error);
      this.logger.debug('OOPS!', error);
      this._fuseProgressBarService.hide();
    }
}
