// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const EventType = {
  "CONFERENCE": "Conference",
  "FUNDRAISER": "Fundraiser",
  "FESTIVAL": "Festival",
  "SOCIAL_E_VENT": "SocialEVent"
};
const EventStatus = {
  "INACTIVE": "inactive",
  "ACTICE": "actice",
  "DRAFT": "draft",
  "LIVE": "live"
};
const ElStatus = {
  "ACTIVE": "active",
  "INACTIVE": "inactive",
  "NEW": "new"
};
const ContentType = {
  "VIDEO": "Video",
  "FILE": "File"
};
const CourseType = {
  "EXTERNAL": "External",
  "INTERNAL": "Internal"
};
const ResourceType = {
  "FILE": "File",
  "LINK": "Link",
  "IFRAME": "Iframe"
};
const ElCourseTrend = {
  "TOP": "Top",
  "TRENDING": "Trending",
  "NEW": "New"
};
const ElAssetType = {
  "TOPIC": "Topic",
  "QUIZ": "Quiz"
};
const ElQuestionType = {
  "TRUE_FALSE": "TrueFalse",
  "MULTIPLE": "Multiple",
  "OPTIONAL": "Optional"
};
const ElSubStatus = {
  "ENROLLED": "Enrolled",
  "COMPLETED": "Completed",
  "PROGRESS": "Progress"
};
const ElCourseLevel = {
  "BEGINNER": "Beginner",
  "INTERMEDIATE": "Intermediate",
  "EXPERT": "Expert",
  "ALL": "All"
};
const ContactStatus = {
  "ACTIVE": "active",
  "INACTIVE": "inactive"
};
const ContactType = {
  "ORGANIZATION": "Organization",
  "CONTACT": "Contact"
};

const { Session, UserSettings, Notification, UserPrivacy, CourseCategory, Course, CourseAssets, Content, Quiz, QuizQuestion, QuestionAnswers, CourseAssetsResource, CourseResource, ContentResource, ContentSubs, CourseSubs, CourseAssetSubs, CourseTags, Contact, ContactAddress, Connects, Membership } = initSchema(schema);

export {
  Session,
  UserSettings,
  Notification,
  UserPrivacy,
  CourseCategory,
  Course,
  CourseAssets,
  Content,
  Quiz,
  QuizQuestion,
  QuestionAnswers,
  CourseAssetsResource,
  CourseResource,
  ContentResource,
  ContentSubs,
  CourseSubs,
  CourseAssetSubs,
  CourseTags,
  Contact,
  ContactAddress,
  Connects,
  Membership,
  EventType,
  EventStatus,
  ElStatus,
  ContentType,
  CourseType,
  ResourceType,
  ElCourseTrend,
  ElAssetType,
  ElQuestionType,
  ElSubStatus,
  ElCourseLevel,
  ContactStatus,
  ContactType
};