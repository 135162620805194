import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatSort} from "@angular/material/sort";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FuseConfirmDialogComponent} from "../../../../../../@fuse/components/confirm-dialog/confirm-dialog.component";
import {fromEvent, Observable, Subject} from "rxjs";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Location} from "@angular/common";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {debounceTime, distinctUntilChanged, takeUntil} from "rxjs/operators";
import {DataSource} from "@angular/cdk/collections";
import {LicenseService} from "../../license.service";
import {fuseAnimations} from "../../../../../../@fuse/animations";
import {LicensePopupComponent} from "../license-popup/license-popup.component";
import {MemberShip} from "../../org.model";
import {FuseSidebarService} from "../../../../../../@fuse/components/sidebar/sidebar.service";

@Component({
  selector: 'org-licenses-list',
  templateUrl: './org-licenses-list.component.html',
  styleUrls: ['./org-licenses-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class OrgLicensesListComponent implements OnInit, OnDestroy {

  @Input()
  contactId: string;

  @Input()
  contactName: string;


  @ViewChild('filter', {static: true})
  filter: ElementRef;

  @ViewChild(MatSort, {static: true})
  sort: MatSort;

  selectedLicense: any;
  licences: any[];
  nextPage: any;
  dataSource: LicensesDataSource | null;
  displayedColumns = ['group', 'noLicenses',  'noClaimed', 'buttons'];
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param _licenseService
   * @param {MatDialog} _matDialog
   * @param _matSnackBar
   * @param _location
   * @param _bottomSheet
   * @param _fuseSidebarService
   */
  constructor(
      private _licenseService: LicenseService,
      public _matDialog: MatDialog,
      private _matSnackBar: MatSnackBar,
      private _location: Location,
      private _bottomSheet: MatBottomSheet,
      private _fuseSidebarService: FuseSidebarService,
  ) {
    

    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this.licences = [];
    this.selectedLicense = { noLicenses: 0};
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void
  {
    this._licenseService.refreshAll(this.contactId);

    this.dataSource = new LicensesDataSource(this._licenseService,  this.sort);

    this._licenseService.onLicenseChanged$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(contacts => {
          this.licences = contacts;
        });

    this._licenseService.nextPageToken$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(nextToken => {
          this.nextPage = nextToken;
        });


    fromEvent(this.filter.nativeElement, 'keyup')
        .pipe(
            takeUntil(this._unsubscribeAll),
            debounceTime(150),
            distinctUntilChanged()
        )
        .subscribe(() => {
          if ( !this.dataSource )
          {
            return;
          }
          this._licenseService.onSearchTextChanged.next(this.filter.nativeElement.value);
          this._licenseService.refreshAll(this.contactId);
        });

  }

  /**
   * On destroy
   */
  ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  gotoNextPage(nextPage): void {
    this._licenseService.goNextPage(this.contactId, nextPage);
  }


  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  addLicense() {
      const newLicense = new MemberShip();
      newLicense.contactId = this.contactId;
      const dataToSave = this._bottomSheet.open(LicensePopupComponent, {
        disableClose: true,
        data: newLicense
      });
      dataToSave.afterDismissed().subscribe((data) => {
        if (data) {
          this._licenseService.addLicense(this.contactId, this.contactName, data)
              .then( newLicense => {
                this._matSnackBar.open('License Saved Sucessfully!', 'OK', {
                  verticalPosition: 'top',
                  duration        : 2000
                });
                console.log(newLicense);
                this._licenseService.refreshAll(this.contactId);
              }).catch(err => {
                this._matSnackBar.open('OOPS! Something is wrong or Duplicate.. Please try Again.', 'OK', {
                  verticalPosition: 'top',
                  duration        : 2000
                });
                console.log(err.errors[0].message);
            });
        }
      });
  }
  /**
   * Delete Contact
   */
  deleteLic(lic: any): void {
    this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });

    this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Suspend?';

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if ( result ) {
        this._licenseService.suspendLicence(lic.id, lic._version)
            .then((res) => {
              // Show the success message
              this._matSnackBar.open('Contact Sucessfully Suspended', 'OK', {
                verticalPosition: 'top',
                duration        : 2000
              });
              this._licenseService.refreshAll(this.contactId);
            }).catch(err => {
              this._matSnackBar.open('OOPS! Something is wrong .. Please try Again.', 'OK', {
                verticalPosition: 'top',
                duration        : 2000
              });
              console.log(err.errors[0].message);
        });
      }
      this.confirmDialogRef = null;
    });

  }

}

export class LicensesDataSource extends DataSource<any> {
  /**
   * Constructor
   *
   * @param {ContactsService} _licenseService
   * @param _matSort
   */
  constructor(
      private _licenseService: LicenseService,
      private _matSort: MatSort
  )
  {
    super();
  }

  /**
   * Connect function called by the table to retrieve one stream containing the data to render.
   * @returns {Observable<any[]>}
   */
  connect(): Observable<any[]>
  {
    return this._licenseService.onLicenseChanged$;
  }

  /**
   * Disconnect
   */
  disconnect(): void
  {
  }
}
