import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FileUploadModel} from "../../../../course-content/cc-file-upload/cc-file-upload.component";
import {Subject} from "rxjs";
import {CourseService} from "../../../course.service";
import {Course} from "../../course.model";

@Component({
  selector: 'admin-course-upload-details',
  templateUrl: './admin-course-upload-details.component.html',
  styleUrls: ['./admin-course-upload-details.component.scss']
})
export class AdminCourseUploadDetailsComponent implements OnInit {
  files: any;

  @Input()
  media: Course;

  @Input()
  file: FileUploadModel;

  @Input()
  process: boolean;

  @Output()
  complete = new EventEmitter<any>();

  @Output()
  cancel = new EventEmitter<FileUploadModel>();

  @Output()
  fileData = new EventEmitter<any>();

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param _changeDetectorRef
   * @param _courseService
   */
  constructor(
      private _changeDetectorRef: ChangeDetectorRef,
      private _courseService: CourseService,
  )
  {
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {}

  ngOnChanges(): void {
    if (this.process) {
      this.uploadFile();
    }
  }
  /**
   * On destroy
   */
  ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // Methods
  cancelFile(file: FileUploadModel): void {
    this.cancel.emit(file);
  }

  retryFile(file: FileUploadModel): void {
    this.uploadFile();
    file.canRetry = false;
  }

  private uploadFile() {
    const typeArray = this.file.data.type.split('/');
    const cType = ['mp4', 'avi', 'mp3', 'mpeg'].indexOf(typeArray[1].toLowerCase()) ===  -1 ? 'file' : 'video';
    let bLoc = '';
    if (cType === 'video') {
      bLoc = `${this._courseService.bucket}/${this._courseService.bucketTarget}/video/${this.media.id}/overview`;
    }
    const newConn = this._courseService.syncS3();
    const params = {
      Bucket: bLoc,
      Key: this.file.data.name,
      ACL: 'authenticated-read',
      Body: this.file.data,
      ContentType: this.file.data.type
    };
    this.file.progress = 0;
    this.file.inProgress = true;
    newConn.upload(params)
        .on('httpUploadProgress', (evt) => {
          this.file.progress = Math.round((evt.loaded * 100) / evt.total);
          this._changeDetectorRef.detectChanges();
        }).send( (err, data) => {
          this.file.inProgress = false;
          if (err) {
            console.log('There was an error uploading your file: ', err);
            this.file.canRetry = true;
          } else {
            this.complete.emit(this.file);
            this.fileData.emit({ fileUpload: data, file: this.file.data } );
            // console.log('Successfully uploaded file.', data);
          }
    });
  }

}

