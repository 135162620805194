import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {UploadFileComponent} from './upload-file.component';


@NgModule({
    declarations: [UploadFileComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        FormsModule,
    ],
    exports: [UploadFileComponent],
})
export class UploadFileModule {}
