import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { NOUSER } from 'app/navigation/navigation';
import {FuseNavigationService} from '../../../@fuse/components/navigation/navigation.service';

@Component({
    selector     : 'vertical-layout',
    templateUrl  : './vertical.component.html',
    styleUrls    : ['./vertical.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class VerticalLayoutComponent implements OnInit, OnDestroy
{
    fuseConfig: any;
    navigation: any;
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param _fuseNavigationService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
    )
    {
        // Set the defaults
        this.navigation = NOUSER;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this._fuseNavigationService.onNavigationChanged
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(() => {

            // Load the navigation
            this.navigation = this._fuseNavigationService.getCurrentNavigation();
            console.log('----> Vertical Component Redraw');

        });
      // Subs
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
            });

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
