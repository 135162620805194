import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ModalAnswerComponent } from '../modal-answer/modal-answer.component';
import { QuizQuestionService } from '../../quiz-question.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'question-choices',
  templateUrl: './question-choices.component.html',
  styleUrls: ['./question-choices.component.scss']
})
export class QuestionChoicesComponent implements OnChanges {
  @Input()
  question: any;

  @Input()
  state: string;

  MULTIPLE = 'multiple';
  showAlert = false;
  alertMessage = "Question is required";
  qType: string;
  questionContent: any;
  optionTFSelected = '1';
  tfFeedBack = "";
  tfAnswer = "";
  optionTFVersion: number;
  optionTFId: string;
  multipleOptions = [];
  optional = [];

  constructor(
    private dialog: MatDialog,
    private _questionService: QuizQuestionService,
    private _matSnackBar: MatSnackBar
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    this.questionContent = changes.question.currentValue;
    this.qType = this.questionContent.qType;
    if(this.questionContent.questionAnswers.items.length > 0){
      console.log(this.questionContent);
      const tempOptionArray = [];
      const questionAnswers = this.questionContent.questionAnswers.items;
      if(this.qType === "Multiple"){
        this.multipleOptions = [];
        questionAnswers.forEach(item => {
          if(item.status === "active" && item.qType === "Multiple"){
            tempOptionArray.push(item); 
          }
        });
        if(tempOptionArray.length > 0){
          this.multipleOptions = tempOptionArray;
        }
      }else if(this.qType === "Optional"){
        this.multipleOptions = [];
        questionAnswers.forEach(item => {
          if(item.status === "active" && item.qType === "Optional")
            tempOptionArray.push(item);
        });
        if(tempOptionArray.length > 0){
          this.multipleOptions = tempOptionArray;
        }
      }else{
        questionAnswers.forEach(item => {
          if(item.status === "active" && item.qType === "TrueFalse")
            tempOptionArray.push(item);
        });
        if(tempOptionArray.length > 0){
          this.optionTFId = tempOptionArray[0].id;
          this.optionTFSelected = tempOptionArray[0].answer;
          this.optionTFVersion = tempOptionArray[0]._version;
          this.tfFeedBack = tempOptionArray[0].answerFeedBack;
        }
      }
    }
  }

  // remove option row (answer)
  removeAnswer(index:number, type:string, item: any):void{
    let data = JSON.parse(JSON.stringify(item));
    data.status = "inactive";
    console.log(data);
    this.openDialogRemoveAnswer(data);
  }

  openDialogRemoveAnswer(data: any) {
    const dialogRef = this.dialog.open(DialogDeleteAnswer);
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this._questionService.updateQuestionAnswer(data)
          .then(res => {
            this.eventMesssage(' Answer Successfully Deleted', 'success');
          })
          .catch(error => {
            this.eventMesssage(' Try Again Please or Call for Assistance!!', 'error');
          });
      }
    });
  }

  openDialog(element: any){
    if(!element){
      element = {
        answer: "",
        answerFeedBack: "",
        isCorrect: false
      };
    }
    this.dialog.open(ModalAnswerComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      width: '750px',
      position: {
        top: '10vh'
      },
      data:{
        question: this.question,
        item: element,
        courseAssetId: null
      }
    });
  }

  // save answer
  saveAnswerTrueFalse(){
    if(this.optionTFId){
      const isCorrect = (this.optionTFSelected==='1')?true:false;
      let data = {
        id: this.optionTFId,
        answer: this.optionTFSelected,
        isCorrect: isCorrect,
        answerFeedBack: this.tfFeedBack,
        quizQuestionId: this.question.asset.quiz.id,
        qType: this.question.qType,
        status: "active",
        sourceMedia: null,
        fileName: null,
        fileType: null,
        fileSize: null,
        questionAnswersQuestionId: this.question.id,
        _version: this.optionTFVersion
      };
      this._questionService.updateQuestionAnswer(data)
      .then(res => {
        this.eventMesssage(' Answer Successfully Updated', 'success');
      })
      .catch(error => {
        this.eventMesssage(' Try Again Please or Call for Assistance!!', 'error');
      });
    }else{
      const isCorrect = (this.optionTFSelected==='1')?true:false;
      let data = {
        answer: this.optionTFSelected,
        isCorrect: isCorrect,
        answerFeedBack: this.tfFeedBack,
        quizQuestionId: this.question.asset.quiz.id,
        qType: this.question.qType,
        status: "active",
        sourceMedia: null,
        fileName: null,
        fileType: null,
        fileSize: null,
        questionAnswersQuestionId: this.question.id
      };
      this._questionService.addQuestionAnswer(data)
      .then(res => {
        this.eventMesssage(' Answer Successfully Added', 'success');
      })
      .catch(error => {
        this.eventMesssage(' Try Again Please or Call for Assistance!!', 'error');
      });
    }
  }

  private eventMesssage( msg: string, typeMsg: string ) {
    const customMsg = typeMsg === 'error' ? 'OOPS:' + msg : 'Success!!' + msg;
    this._matSnackBar.open(customMsg, 'OK', {
      verticalPosition: 'top',
      duration        : 2000
    });
  }
}

@Component({
  selector: 'dialog-delete-answer',
  templateUrl: 'dialog-delete-answer.component.html',
})
export class DialogDeleteAnswer {}