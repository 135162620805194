import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {of, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import * as _ from 'lodash';
import {fuseAnimations} from '@fuse/animations';
import {UsersService} from '../../users.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FuseConfirmDialogComponent} from '../../../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';


@Component({
    selector   : 'user-details-sidebar',
    templateUrl: './user-details.component.html',
    styleUrls  : ['./user-details.component.scss'],
    animations : fuseAnimations
})
export class UserDetailsSidebarComponent implements OnInit, OnDestroy
{
    selected: any;
    selectedAttr: any;
    identities: any;
    identityPicture: any;
    groups: string[] ;
    userGroups: any;
    isDirty: boolean;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    horizontalPosition: MatSnackBarHorizontalPosition = 'left';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    @ViewChild('saveButton', {static: false}) saveButton: ElementRef;
    @ViewChild('enableButton', {static: false}) enableButton: ElementRef;
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param _userService
     * @param _matDialog
     * @param _snackBar
     */
    constructor(
        private _userService: UsersService,
        public _matDialog: MatDialog,
        private _snackBar: MatSnackBar
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this._userService.onGroupsChanged$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(groups => {
                this.groups = groups;
            });
        this.isDirty = true;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this._userService.onUserSelected
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(selected => {
                if (!_.isEmpty(selected)){
                    this.resetGroups();
                    this.selected = selected;
                    this.selectedAttr = selected && selected['attributes'] ? selected['attributes'] : false;
                    this.identities = selected.external && selected.identities;
                    if (selected.external && selected.picture) {
                      this.identityPicture = selected.picture;
                    } else {
                      this._userService.getS3AvatarFileByName(selected.picture)
                        .then(imageBase64 => {
                          this.identityPicture = imageBase64;
                      });
                    }
                    this._userService.getListGroupsForUser(10, this.selected.id, null)
                        .then((data ) => {
                            this.userGroups  =  data;
                            if (this.userGroups.Groups.length > 0) {
                              this.groups = this.groups.map((group) => {
                                  const found = _.find(this.userGroups.Groups, ['GroupName', group['groupName']]);
                                  if (found !== undefined && found) {
                                    group['selected'] = true;
                                  }
                                  return group;
                                });
                            }
                        });
                    this.isDirty = true;
                }
            });
    }

    resetGroups() {
      this.groups = this.groups.map((group) => {
          group['selected'] = false;
          group['groupId'] = null;
          return group;
        });
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    updateGroup(role: any) {
        this.groups = this.groups.map((group) => {
            if (group['groupName'] === role['groupName']) {
                group['selected'] = !group['selected'];
            }
            return group;
        });
    }

    refreshUser(){
      of(this._userService.getUser(this.selected.id));
    }

    selectionChanged($event: any) {
        this.isDirty = false;
    }

    onSaveRolAssignment() {
        const updatePos = {
            top : this.saveButton.nativeElement.getBoundingClientRect().top,
            right : this.saveButton.nativeElement.getBoundingClientRect().right
        };
        const rightMostPos = window.innerWidth - Number(updatePos.right);
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false,
            autoFocus: true,
            hasBackdrop: true,
            panelClass: 'filter-popup',
            width: '250px',
            position: { top: `${updatePos.top}px`, right: `${rightMostPos}px`}
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Assigning new roles will enable/disable some features. Are you Sure?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if ( result ) {
                this._userService.removeAllGroupsForUser(this.userGroups.Groups, this.selected.id)
                  .then(res => {
                    this.groups.forEach((group) => {
                      if (group['selected']) {
                        this._userService.addUserToGroup(group['groupName'], this.selected.id)
                          .then(resAdd => {
                            this.popMsg('User Group/Rol(s) has been updated!!');
                            this.refreshUser();
                          }).catch(err => {
                            const msgShow = err && (err.Message || err.message) ? err.message
                              : 'OOPs!! Something went Wrong.. Try Again or Call for Support';
                            this.popMsg(msgShow);
                        });
                      }
                    });
                  }).catch(err => {
                    const msgShow = err && (err.Message || err.message) ? err.message
                      : 'OOPs!! Something went Wrong.. Try Again or Call for Support';
                    this.popMsg(msgShow);
                });
            }
            this.confirmDialogRef = null;
        });

    }

    enableUser(action: string) {
        const  message = action === 'enable' ? 'ENABLE' : 'DISABLE';
        const updatePos = {
            top : this.enableButton.nativeElement.getBoundingClientRect().top,
            right : this.enableButton.nativeElement.getBoundingClientRect().right
        };
        const rightMostPos = window.innerWidth - Number(updatePos.right);
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false,
            autoFocus: true,
            hasBackdrop: true,
            panelClass: 'filter-popup',
            width: '250px',
            position: { top: `${updatePos.top}px`, right: `${rightMostPos}px`}
        });

        this.confirmDialogRef.componentInstance.confirmMessage = `You want me to ${message} the user. Are you Sure?`;
        this.confirmDialogRef.afterClosed().subscribe(result => {
            if ( result )
            {
                if (action === 'enable'){
                  this._userService.enableUser(this.selected.id)
                    .then(res => {
                        this.popMsg('User Has Been Enable Succesfully!!!!');
                        this.refreshUser();
                    }).catch(err => {
                      const msgShow = err && (err.Message || err.message) ? err.message
                        :  'OOPs!! Something went Wrong.. Try Again or Call for Support';
                      this.popMsg(msgShow);
                    });
                }
                if (action === 'disable'){
                    this._userService.disableUser(this.selected.id)
                        .then(res => {
                          this.popMsg('User Has Been Disabled Succesfully!!!!');
                          this.refreshUser();
                        }).catch(err => {
                          const msgShow = err && (err.Message || err.message) ? err.message
                            :  'OOPs!! Something went Wrong.. Try Again or Call for Support';
                          this.popMsg(msgShow);
                        });
                }
            }
            this.confirmDialogRef = null;
        });
    }

    verifyPhone() {
      this._userService.getConfirmationCode()
        .then(res => {
          console.log(res);
        });
    }

    sendTempPassword() {
        this._userService.sendTempPassword()
            .then(res => {
                console.log(res);
            });
    }

    private popMsg(msgIn: string) {
      this._snackBar.open(msgIn, 'End now', {
        duration: 2000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }

}
