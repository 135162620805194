import { InjectionToken } from '@angular/core';

export interface ProfileOptions {
    accessKeyId: string;
    secretAccessKey: string;
    UserPoolId?: any;
    region: string;
    pageSize?: any;
    usersAvailable: boolean;
    bucketName: string;
}

export const defaultProfileOptions: any = {
  accessKeyId: 'AKIAY6NY73HUV5NXALUR',
  secretAccessKey: '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',
  UserPoolId: 'us-east-1_I3aBWHf14',
  region: 'us-east-1',
  pageSize: 10,
  bucketName: 'kidsthatcode-media-resources'
};

export const PROFILE_MANAGER_OPTIONS = new InjectionToken<ProfileOptions>('Profile Manager Options');

