import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {NotifyModel} from '../notification.model';
import {Subject} from 'rxjs';
import {NotifyListService} from '../notify-list.service';
import {takeUntil} from 'rxjs/operators';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-notify-details',
  templateUrl: './notify-details.component.html',
  styleUrls: ['./notify-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class NotifyDetailsComponent implements OnInit , OnDestroy {


  @Output()
  onFormChanges: EventEmitter<any> = new EventEmitter();

  @Output()
  onToogleResend: EventEmitter<any> = new EventEmitter();

  @Output()
  onToogleSilent: EventEmitter<any> = new EventEmitter();

  notify: NotifyModel;
  showExtraToFields: boolean;
  composeForm: FormGroup;
  selectedType = 'information';
  userInfo: any;
  notiTypes: any = [
    {
      full: 'System Notification',
      short: 'system'
    },
    {
      full: 'Info Notification',
      short: 'information'
    }
  ];
  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _notifyService: NotifyListService
  ) {
    // Set the defaults
    this.showExtraToFields = false;
    // Set the private defaults
    this._unsubscribeAll = new Subject();

  }

  ngOnInit() {
    // Subscribe to update the current mail
    this._notifyService.onCurrentNotifyChanged$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(currentNotify => {
        if ( currentNotify ) {
          this.userInfo = false;
          this.notify = currentNotify;
          this.composeForm = this.createComposeForm();
          if (this.notify.userId.length > 10) {
              this._notifyService.getUser(this.notify.userId).then(userInfo => {
                this.userInfo = userInfo;
              });
          }
        }
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  toggleSilent(event): void {
    event.stopPropagation();
    this.onToogleSilent.next(this.notify);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Create compose form
   *
   * @returns {FormGroup}
   */
  createComposeForm(): FormGroup {
    console.log(this.notify);
    return new FormGroup({
      id: new FormControl(this.notify.id),
      userId: new FormControl(this.notify.userId),
      renotify: new FormControl(this.notify.renotify),
      silent: new FormControl(this.notify.silent),
      subject: new FormControl(this.notify.subject),
      body: new FormControl(this.notify.body),
      typeNotify: new FormControl(this.notify.typeNotify),
      localUrl: new FormControl(this.notify.localUrl),
      _version: new FormControl(this.notify._version)
    });
  }

  saveData(formData: FormGroup ) {
    const data = formData.getRawValue();
    this.onFormChanges.emit(data);
  }
  /**
   * Toggle extra to fields
   */
  toggleExtraToFields(): void
  {
    this.showExtraToFields = !this.showExtraToFields;
  }

}
