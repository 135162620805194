import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material';
import {ProfileService} from '../../profile.service';

@Component({
  selector: 'app-upload-avatar',
  templateUrl: './upload-avatar.component.html',
  styleUrls: ['./upload-avatar.component.scss']
})
export class UploadAvatarComponent implements OnInit {
  imgSrc: any = [];
  fileImg: File;
  uploadProgress: number;
  constructor(
    private _bottomSheetRef: MatBottomSheetRef<UploadAvatarComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    public _profileService: ProfileService,
    private _changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
  }

// Upload Cover
  onSelect($event: any) {
    this.imgSrc = [];
    switch (typeof($event)) {
      case 'string':
        this.imgSrc = [$event];
        break;
      case 'object':
        this.imgSrc = $event;
        break;
      default:
    }
  }

  onReset() {
    this.imgSrc = [];
  }

  onCancel() {
    this.imgSrc = [];
    this._bottomSheetRef.dismiss(null);
  }

  onChangeImg($event) {
    this.fileImg = $event;
  }

  upload() {
    if (this.imgSrc.length === 0) {
      this.imgSrc = [];
      return;
    }
    const target = this.data.fileTarget;
    const base64Img = this.imgSrc[0].replace(/^data:image\/(png|jpg);base64,/, '');
    const base64data = Buffer.from(base64Img, 'base64');
    const newConn = this._profileService.syncS3();
    const bucket = this._profileService.getbucketTarget();
    const userId = this._profileService.getCurrentUserNameOrId();
    const params = {
      Bucket:   `${bucket}/userpics/${userId}`,
      Key: this.fileImg.name,
      ACL: 'authenticated-read',
      Body: base64data,
      ContentType: this.fileImg.type
    };
    this.uploadProgress = 0;
    const self = this;
    newConn.upload(params)
      .on('httpUploadProgress', (evt) => {
        this.uploadProgress = Math.round((evt.loaded * 100) / evt.total);
        this._changeDetectorRef.detectChanges();
      }).send( (err, data) => {
        this.imgSrc.inProgress = false;
        if (err) {
          console.log('There was an error uploading your file: ', err);
          self.uploadProgress = -1;
        } else {
          self.uploadProgress = -1;
          console.log('Successfully uploaded file.', data);
          this._bottomSheetRef.dismiss({
            file : data.Location,
            target : target
          });
        }
      });
  }
}
