import {elStatus} from '../../../API.service';

export class CourseCat
{
  id: string;
  category: string;
  status?: string;
  createdAt?: string;
  updatedAt?: string;
  _version?: number;

  /**
   * Constructor
   *
   * @param user
   */
  constructor(cat?) {
    cat = cat ? cat : {};
    this.id = cat.id || '';
    this.category = cat.category || null;
    this.status = cat.status || elStatus.new;

    this.createdAt = cat.createdAt || new Date().toUTCString();
    this.updatedAt = cat.updatedAt || new Date().toUTCString();
    this._version = cat._version || null;
  }
}
