import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {
  APIService,
  CreateCourseAssetsResourceInput,
  DeleteCourseAssetsResourceInput, elAssetType,
  elStatus,
  ModelCourseAssetsResourceFilterInput,
  ResourceType,
  UpdateCourseAssetsResourceInput,
} from '../../../API.service';
import {getDeepFromObject} from '../../auth/helpers';
import * as _ from 'lodash';
import {Logger} from '@aws-amplify/core';
import {SyncSettings} from '../../../../@fuse/services/sync-settings.service';
import {FuseProgressBarService} from '../../../../@fuse/components/progress-bar/progress-bar.service';
import * as S3 from 'aws-sdk/clients/s3';
import {API} from 'aws-amplify';
import {ContentResource} from './content-resource.model';
import {COURSE_CONTENT_MANAGER_OPTIONS} from './course-content.options';

type ModelPartial<T> = {
  [P in keyof  T]?: T[P]
};

@Injectable()
export class ContentResourcesService {
  private logger = new Logger('Content Resource List');

  currentCourseId: string;
  resource: ContentResource = new ContentResource();
  resources: ContentResource[];
  pageSize: number;
  nextToken: string = null;
  bucket: any;
  bucketTarget: any;

  private resourcesOnChanged = new BehaviorSubject<ContentResource[]>([]);
  resourcesOnChanged$ = this.resourcesOnChanged.asObservable();

  private onResourceChanged: BehaviorSubject<ContentResource> = new BehaviorSubject(this.resource);
  public onResourceChanged$: Observable<ContentResource> = this.onResourceChanged.asObservable();

  private nextPageToken: BehaviorSubject<any> = new BehaviorSubject(this.nextToken);
  public nextPageToken$: Observable<any> = this.nextPageToken.asObservable();

  private _onErrorOrSuccess = new BehaviorSubject<any>(null);
  onErrorOrSuccess$ = this._onErrorOrSuccess.asObservable();

  /**
   * Constructor
   *
   * @param options
   * @param _api
   * @param syncSettings
   * @param _fuseProgressBarService
   */
  constructor(
      @Inject(COURSE_CONTENT_MANAGER_OPTIONS) protected options = {},
      private _api: APIService,
      private syncSettings: SyncSettings,
      private _fuseProgressBarService: FuseProgressBarService
  )
  {
    this.bucketTarget = this.getConfigValue('S3BucketName');
    this.pageSize = this.getConfigValue('pageSize');
    this.syncSettings.onSettingsChanged$.subscribe((res) => {
      this.pageSize = res.pageSize;
      this._api.OnCreateCourseResourceListener
        .subscribe(resCreate => {
          if (this.currentCourseId) {
            this.refresh(this.currentCourseId);
          }
        });
      this._api.OnDeleteCourseResourceListener
        .subscribe(resDel => {
          if (this.currentCourseId) {
            this.refresh(this.currentCourseId);
          }
        });
      this._api.OnUpdateCourseResourceListener
        .subscribe(resDel => {
          if (this.currentCourseId) {
            this.refresh(this.currentCourseId);
          }
        });
    });
  }

  /**
   * Resolver
   *
   * @returns {Observable<any> | Promise<any> | any}
   * @param courseId
   */

  public refresh(courseId?: string) {
      this.currentCourseId = courseId;
      of(this.getResources(this.currentCourseId));
  }

  public goNextPage(courseId: string, filterTxt: string, nextPageToken: string) {
    of(this.getResources(courseId, filterTxt,  nextPageToken));
  }

  public sendMsg(msg: any) {
    this._onErrorOrSuccess.next(msg);
  }

  getResources(courseId?: string, searchTxt?: string, nextToken?: string): Promise<any> {
    let filter: ModelCourseAssetsResourceFilterInput = {
      courseAssetId: {eq: courseId},
      and : [
        { status: {ne: elStatus.inactive}}
      ]

    };
      if (searchTxt !== undefined && searchTxt.length > 0) {
          filter = {
              description: { contains: searchTxt},
              and : [
                { status: {ne: elStatus.inactive}}
              ]
          };
      }
      this.nextToken = nextToken ? nextToken : null;
      this._fuseProgressBarService.show();
      return new Promise((resolve, reject) => {
          this._api.ListCourseAssetsResources(
            filter,
            this.pageSize,
            this.nextToken
          ).then((result) => {
              this.nextToken = !_.isEmpty(result['nextToken']) ? result['nextToken'] : null;
              this.nextPageToken.next(this.nextToken );
              this.resources = result.items;
              this.resourcesOnChanged.next(result.items);
              this._fuseProgressBarService.hide();
              resolve(this.resources);
          }).catch((err) => {
            this.catchError(err);
            reject(err);
          });
      });
  }

  create(fileUrl: string , resourceAsset: any, file: File ): Promise<any>  {
    this._fuseProgressBarService.show();
    return new Promise(async (resolve, reject) => {
      const sizeStr = file.size.toString(11);
      const payload: CreateCourseAssetsResourceInput = {
        courseId: resourceAsset.courseId,
        description: file.name,
        contentType: ResourceType.File,
        sourceCode: fileUrl,
        fileName: file.name,
        fileType: file.type,
        fileSize: sizeStr,
        status: elStatus.active,
        updatedAt: new Date().toISOString(),
        createdAt: new Date().toISOString(),
        courseAssetId: resourceAsset.id,
        courseAssetsResourceCourseAssetId: resourceAsset.id,
        _version: null
      };
      this._api.CreateCourseAssetsResource(payload)
        .then(privacySetts => {
          this.resource = this.mergeObjs(this.resource, privacySetts);
          this.onResourceChanged.next(this.resource);
          this._onErrorOrSuccess.next({type: 'SUCCESS'});
          this._fuseProgressBarService.hide();
          resolve(this.resource);
        }).catch(error => {
          this._onErrorOrSuccess.next({type: 'error', msg: error.message});
          this.catchError({type: 'error', msg: error.message});
          reject(error);
      });
    });
  }

  updateFileResource(data: any, courseId: string): Promise<any> {
    this._fuseProgressBarService.show();
    data.updatedAt = new Date().toISOString();
    return new Promise((resolve, reject) => {
      const payload: UpdateCourseAssetsResourceInput = {
        id: data.id,
        courseId: courseId,
        description: data.description,
        contentType: ResourceType.File,
        status: elStatus.active,
        updatedAt: new Date().toISOString(),
        createdAt: new Date().toISOString(),
        _version: data._version
      };
      this._api.UpdateCourseAssetsResource(payload)
        .then(resSetts => {
          this._fuseProgressBarService.hide();
          this._onErrorOrSuccess.next({type: 'SUCCESS'});
          resolve(resSetts);
        }).catch( error => {
        this.catchError({type: 'error', msg: error});
        reject(error);
      });
    });
  }

  createOther(data: any , resourceAsset: any ): Promise<any>  {
    this._fuseProgressBarService.show();
    return new Promise(async (resolve, reject) => {
      const payload: CreateCourseAssetsResourceInput = {
        courseId: resourceAsset.courseId,
        description: data.description,
        contentType: ResourceType.Link,
        sourceCode: data.sourceCode,
        status: elStatus.active,
        updatedAt: new Date().toISOString(),
        createdAt: new Date().toISOString(),
        courseAssetId: resourceAsset.id,
        courseAssetsResourceCourseAssetId: resourceAsset.id,
        _version: null
      };
      this._api.CreateCourseAssetsResource(payload)
        .then(otherSetts => {
          this._onErrorOrSuccess.next({type: 'SUCCESS'});
          this._fuseProgressBarService.hide();
          resolve(otherSetts);
        }).catch(error => {
          this._onErrorOrSuccess.next({type: 'error', msg: error.message});
          this.catchError({type: 'error', msg: error.message});
          reject(error);
      });
    });
  }

  updateOther(data: any, courseId: string): Promise<any> {
    this._fuseProgressBarService.show();
    data.updatedAt = new Date().toISOString();
    return new Promise((resolve, reject) => {
      const payload: UpdateCourseAssetsResourceInput = {
        id: data.id,
        description: data.description,
        contentType: ResourceType.Link,
        sourceCode: data.sourceCode,
        status: elStatus.active,
        updatedAt: new Date().toISOString(),
        createdAt: new Date().toISOString(),
        _version: data._version
      };
      this._api.UpdateCourseAssetsResource(payload)
        .then(resSetts => {
          this._fuseProgressBarService.hide();
          this._onErrorOrSuccess.next({type: 'SUCCESS'});
          resolve(resSetts);
        }).catch( error => {
          this.catchError({type: 'error', msg: error});
          reject(error);
      });
    });
  }

  takeScreenShot(linkUrl: string): Promise<any> {
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      const init = {
        queryStringParameters: {
          url: linkUrl
        },
      };
      API.get('coreApiKTC', '/screenshot', init)
        .then(result => {
          this._fuseProgressBarService.hide();
          resolve(result);
        }).catch((error: any) => {
          this.catchError({type: 'error', msg: error.toString()});
          reject(error);
      });
    });
  }

  countResourcesByCourseIdAndName(courseAssetId: string, fileName: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let filter: ModelCourseAssetsResourceFilterInput ;
      if (fileName !== undefined && fileName) {
        filter = {
          fileName: { eq: fileName},
          courseAssetId: { eq: courseAssetId},
          status: { eq: elStatus.inactive }
        };
      }
      this._api.ListCourseAssetsResources(
        filter,
        10
      ).then((result) => {
        resolve(result.items.length);
      }).catch((err) => {
        this.catchError(err);
        reject(err);
      });
    });
  }

  deleteAssetResource(propsIn: any): Promise<any> {
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      const payload: UpdateCourseAssetsResourceInput = {
        id: propsIn.id,
        status: elStatus.inactive,
        _version: propsIn._version
      };
      this._api.UpdateCourseAssetsResource(payload)
          .then(resultUpdated => {
            const payloadDelete: DeleteCourseAssetsResourceInput = {
              id: propsIn.id,
              _version: resultUpdated._version,
            };
            return this._api.DeleteCourseAssetsResource(payloadDelete);
          }).then( resultaDel => {
            this._fuseProgressBarService.hide();
            this._onErrorOrSuccess.next({type: 'SUCCESS'});
            resolve(resultaDel);
          }).catch( error => {
            console.log(error);
            this.catchError({type: 'error', msg: error});
            reject(error);
          });
    });
  }

  syncS3(): any {
    return new S3(
      {
        accessKeyId: this.getConfigValue('accessKeyId'),
        secretAccessKey: this.getConfigValue('secretAccessKey'),
        region: this.getConfigValue('region')
      });
  }

  uploadFile(fileUpload: File, courseId: string): Promise<any>  {
    return new Promise((resolve, reject) => {
      const bPath = `${this.bucketTarget}/content/${courseId}`;
      const params = {
        Bucket: bPath,
        Key: fileUpload.name,
        ACL: 'authenticated-read',
        Body: fileUpload,
        ContentType: fileUpload.type
      };
      const self = this;
      this._fuseProgressBarService.setMode('determinate');
      this._fuseProgressBarService.setValue(0);
      this._fuseProgressBarService.show();
      const newConn = this.syncS3();
      newConn.upload(params)
        .on('httpUploadProgress', (evt) => {
          const perUp = Math.round((evt.loaded * 100) / evt.total);
          self._fuseProgressBarService.setValue(perUp);
        }).send((err, data) => {
          self._fuseProgressBarService.hide();
          self._fuseProgressBarService.setValue(0);
          if (err) {
            reject(err);
          } else {
            resolve(data.Location);
          }
      });
    });
  }

  getPresignedFile(fileName: string, courseAssetId: string): Promise<any> {
    const bPath = `${this.bucketTarget}/content/${courseAssetId}`;
    const newConn = this.syncS3();
    const params = {
      Bucket: bPath,
      Key: fileName,
      Expires: 3600
    };
    return new Promise((resolve, reject) => {
      newConn.getSignedUrl( 'getObject', params, (err, urlLink) => {
        if (err) {
          console.log('There was an error Downloading your file: ', err);
          reject(err);
        } else {
          resolve(urlLink);
        }
      });
    });
  }

  private catchError(error) {
    console.log(error);
    this.logger.debug('OOPS!', error);
    this._fuseProgressBarService.hide();
  }

  getConfigValue(key: string): any {
      return getDeepFromObject(this.options, key, null);
  }

  cleanObj(objB: any): any {
    const cleanArray = ['__typename', '_deleted', '_lastChangedAt', 'courses'];
    const newObj = {};
    for (const key in objB) {
      if (cleanArray.indexOf(key) === -1){
        newObj[key] = objB[key];
      }
    }
    return newObj;
  }

  mergeObjs(objA: any, objB: ModelPartial<any>) {
    return {...objA, ...objB};
  }
}
