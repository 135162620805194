import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from "@angular/material/snack-bar";
import {Subject} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {takeUntil} from "rxjs/operators";
import {NotifyDialogComponent} from "../../../../admin/users/notify-dialog/notify-dialog.component";
import {FormGroup} from "@angular/forms";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {StudentsService} from "../../students.service";
import {FuseSidebarService} from "../../../../../../@fuse/components/sidebar/sidebar.service";

@Component({
  selector: 'select-bar-students',
  templateUrl: './select-bar-students.component.html',
  styleUrls: ['./select-bar-students.component.scss']
})
export class SelectBarStudentsComponent implements OnInit, OnDestroy {
  hasSelectedUsers: boolean;
  isIndeterminate: boolean;
  selectedContacts: string[];
  dialogRef: any;
  horizontalPosition: MatSnackBarHorizontalPosition = 'left';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  nextPage: any;
  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param _studentsService
   * @param {MatDialog} _matDialog
   * @param _bottomSheet
   * @param _snackBar
   * @param _fuseSidebarService
   */
  constructor(
      private _studentsService: StudentsService,
      public _matDialog: MatDialog,
      private _bottomSheet: MatBottomSheet,
      private _snackBar: MatSnackBar,
      private _fuseSidebarService: FuseSidebarService,
  )
  {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void
  {
    this._studentsService.onSelectedUsersChanged$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(selectedContacts => {
          this.selectedContacts = selectedContacts;
          setTimeout(() => {
            this.hasSelectedUsers = selectedContacts.length > 0;
            this.isIndeterminate = (selectedContacts.length !== this._studentsService.users.length && selectedContacts.length > 0);
          }, 0);
        });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Select all
   */
  selectAll(): void
  {
    this._studentsService.selectUsers();
  }

  /**
   * Deselect all
   */
  deselectAll(): void
  {
    this._studentsService.deselectUsers();
  }

  /**
   * Delete selected contacts
   */
  notifySelected(): void {
    this.dialogRef = this._bottomSheet.open(NotifyDialogComponent, {
      data: { panelClass: 'notification-compose-dialog' },
    });
    this.dialogRef.afterDismissed()
        .subscribe(response => {
          if ( !response ) {
            return;
          }
          const actionType: string = response[0];
          const formData: FormGroup = response[1].value;
          this._studentsService.sendNotifications(formData).then((res) => {
            this.popMsg(`The Notitifactions has ben Sent! to ${this._studentsService.selectedUsers.length} users`);
          }).catch(err => {
            const msgShow = err && (err.Message || err.message) ? err.message
                :  'OOPs!! Something went Wrong.. Try Again or Call for Support';
            this.popMsg(msgShow);
          });
        });
  }

  openAssigmentSideBar(){
    this._studentsService.getCoursesByToken(null);
    this._studentsService.getSelectedUsers();
    this._fuseSidebarService.getSidebar('student-assigment-sidebar').toggleOpen();
  }

  private popMsg(msgIn: string) {
    this._snackBar.open(msgIn, 'End now', {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
}
