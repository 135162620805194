import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentLoaderComponent } from './content-loader/content-loader.component';
import {ListContentLoaderComponent} from './list-content-loader/list-content-loader.component';
import {FormContentLoaderComponent} from './form-content-loader/form-content-loader.component';
import {BulletListContentLoaderComponent} from './bullet-list-content-loader/bullet-list-content-loader.component';


@NgModule({
  declarations: [
    ContentLoaderComponent,
    ListContentLoaderComponent,
    FormContentLoaderComponent,
    BulletListContentLoaderComponent
  ],
  exports: [
    ContentLoaderComponent,
    ListContentLoaderComponent,
    FormContentLoaderComponent,
    BulletListContentLoaderComponent
  ],
  imports: [
    CommonModule
  ]
})
export class ContentLoaderModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ContentLoaderModule
    };
  }
}
