// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:b4e1d04c-68f9-4fb7-8417-c43b6bfb7277",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_I3aBWHf14",
    "aws_user_pools_web_client_id": "30a7t3qoeromb7koub6n59e5ef",
    "oauth": {
        "domain": "kidsthatcode010453b0-010453b0-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://kidsthatcode.de/go/",
        "redirectSignOut": "https://kidsthatcode.de.auth/login/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_appsync_graphqlEndpoint": "https://3co33dzo6rahvb52c7vxn2edzu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-pp64b3h4azcndlon426zkz4l3e",
    "aws_cloud_logic_custom": [
        {
            "name": "coreApiKTC",
            "endpoint": "https://m1c4adb9tf.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "academy",
            "endpoint": "https://7alau89d4j.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "AdminQueries",
            "endpoint": "https://d6vppnuz4l.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "apiAdminQueries",
            "endpoint": "https://epy7xr2iq6.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "kidsthatcode-bucket183752-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "predictions": {
        "interpret": {
            "interpretText": {
                "region": "us-east-1",
                "proxy": false,
                "defaults": {
                    "type": "SENTIMENT"
                }
            }
        }
    }
};


export default awsmobile;
