import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, of, Subject} from 'rxjs';
import {getDeepFromObject} from '../../auth/helpers';
import {AuthService} from '../../auth/services/auth.service';
import * as _ from 'lodash';
import {
    APIService,
    ContactType,
    CreateConnectsInput, ModelConnectsFilterInput,
    ModelContactFilterInput, UpdateConnectsInput, UpdateContactInput,
} from "../../../API.service";
import {SyncSettings} from "../../../../@fuse/services/sync-settings.service";
import {CONTACT_MANAGER_OPTIONS} from "./contact.options";

@Injectable()
export class ConnectsService {

    connects: any[];
    nextToken: string = null;

    private onConnectsChanged = new BehaviorSubject<any>(null);
    onConnectsChanged$ = this.onConnectsChanged.asObservable();

    private nextPageToken = new BehaviorSubject<any>(this.nextToken);
    nextPageToken$ = this.nextPageToken.asObservable();

    onLoading: Subject<any>;
    onSearchTextChanged: Subject<any>;
    onFilterChanged: Subject<any>;

    pageSize: number;


    searchText: string;
    filterBy: string;

    bucket: any;

    /**
     * Constructor
     *
     * @param options
     * @param api
     * @param _authService
     * @param syncSettings
     */
    constructor(
        @Inject(CONTACT_MANAGER_OPTIONS) protected options = {},
        private api: APIService,
        private _authService: AuthService,
        private syncSettings: SyncSettings,
    )
    {
        // Set the defaults
        this.onLoading = new Subject();
        this.onSearchTextChanged = new Subject();
        this.onFilterChanged = new Subject();
        this.onLoading.next(false);
        this.pageSize = this.getConfigValue('pageSize');
        this.syncSettings.onSettingsChanged$.subscribe((res) => {
            this.pageSize = res.pageSize;
        });
        this.onSearchTextChanged.subscribe(searchText => {
            this.searchText = searchText;
            this.nextToken = null;
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // ----------------------------------------------------------------------------------------------------

    refreshConnects(contactId: string) {
        of(this.getConnects(contactId))
    }

    refreshContacts() {
        of(this.getContacts())
    }


    public goNextPage(contactId: string, nextPageToken: string) {
        of(this.getConnects(contactId, this.pageSize,  nextPageToken));
    }


    getConnects(contactId?: string, pageSize?: number, nextToken?: string): Promise<any[]> {
        this.onLoading.next(true);
        this.pageSize  = pageSize > 0 ? pageSize : this.pageSize;
        this.nextToken = nextToken ? nextToken : null;
        return new Promise((resolve, reject) => {
            let filter: ModelConnectsFilterInput;
            filter = {
                contactId: {eq: contactId},
                status: {eq: 'active'}
            };
            if (this.searchText) {
                filter['contactName'] = {contains: this.searchText};
            }
            this.api.ListConnectss(filter, this.pageSize, this.nextToken)
                .then((connects) => {
                    this.nextToken = !_.isEmpty(connects['nextToken']) ? connects['nextToken'] : null;
                    this.nextPageToken.next(this.nextToken );
                    this.connects = connects.items;
                    this.onConnectsChanged.next(this.connects);
                    this.onLoading.next(false);
                    resolve(this.connects);
                })
                .catch((err) => reject(err));
        });
    }

    getContacts(sTxt?:string): Promise<any[]> {
        return new Promise((resolve, reject) => {
            const userInfo = this._authService.getUserId();
            const userId = userInfo.getUsername();
            let filter: ModelContactFilterInput;
            filter = {
                userID: {eq: userId},
                contactType: {eq : ContactType.Contact},
                status: {eq: 'active'}
            };
            if (sTxt) {
                filter.contactName = {contains: sTxt};
            }
            this.api.ListContacts(filter, 10, null)
                .then((contacts) => {
                    resolve(contacts.items);
                })
                .catch((err) => reject(err));
        });
    }


    getContact(contactId:string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.GetContact(contactId)
                .then(contact => {
                    resolve(contact);
                })
                .catch((err) => reject(err));
        });
    }

    addConnect(contactId: string, connect: any): Promise<any> {
        return new Promise((resolve, reject) => {
            let filter: ModelConnectsFilterInput;
            filter = {
                contactId: {eq : contactId},
                connsContactId : {eq: connect.id}
            };
            this.api.ListConnectss(filter, 1, null)
                .then(contacts => {
                    if (contacts.items.length > 0) {
                        const currentConn = contacts.items[0];
                        const upPayload: UpdateConnectsInput= {
                            id: currentConn.id,
                            status: 'active',
                            updatedAt: new Date().toISOString(),
                            _version: currentConn._version
                        };
                        return this.api.UpdateConnects(upPayload);
                    } else {
                        const payload: CreateConnectsInput = {
                            contactId: contactId,
                            connectsContactId: connect.id,
                            connsContactId: connect.id,
                            contactType: 'Primary',
                            contactName: connect.contactName,
                            status: 'active',
                            createdAt: new Date().toISOString(),
                            updatedAt: new Date().toISOString()
                        };
                        return this.api.CreateConnects(payload);
                    }
                }).then(updatedConnect=>{
                    const upPayload: UpdateContactInput= {
                        id: connect.id,
                        contactContactRelId:  contactId,
                        contactRelId:  contactId,
                        updatedAt: new Date().toISOString(),
                        _version: connect._version
                    };
                    return this.api.UpdateContact(upPayload);
                }).then(updatedConnect=>{
                    resolve(updatedConnect);
                }).catch((err) => reject(err));
        });
    }


    deleteConnect(connectId: string, _version: any ): Promise<any> {
        return new Promise((resolve, reject) => {
            const payload: UpdateConnectsInput = {
                id: connectId,
                status: 'inactive',
                updatedAt: new Date().toISOString(),
                _version: _version
            };
            this.api.UpdateConnects(payload)
                .then(connect => {
                    resolve(connect);
                }).catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    getConfigValue(key: string): any {
        return getDeepFromObject(this.options, key, null);
    }

}
