import {CourseType} from '../../../../API.service';


export class Course
{
    id: string;
    title: string;
    timeAprox: number;
    description: string;
    courseCategoryId: string;
    status: string;
    coverImage: any;
    base64Img: any;
    assets: any[];
    coverImage1: string;
    coverImage2: string;
    coverImage3: string;
    courseUrl: string;
    courseType: string;
    learnDescription: string;
    overviewSourceMedia: string;
    overviewFileName: string;
    overviewFileType: string;
    overviewFileSize: string;
    transcoderMeta: any;
    transcoderJobId: string;
    requirements: string;
    level: string;
    tags: string;

    _version: number;

    presignedUrl: string;
    posterFileUrl: string;
    /**
     * Constructor
     *
     * @param course
     */
    constructor(course?)
    {
        course = course || {};
        this.id = course.id || null;
        this.title = course.title || '';
        this.description = course.description || '';
        this.timeAprox = course.timeAprox || 0;
        this.courseCategoryId = (course.hasOwnProperty('category') ? course.category.id : '') || '';
        this.status = course.status || 'new';
        this.coverImage = course.coverImage || 'assets/images/courses/course-image-placeholder.png';
        this.base64Img = course.base64Img || null;
        this.assets = (course.hasOwnProperty('assets') ? course.assets.items : []) || [];
        this.coverImage1 = course.coverImage1 || 'assets/images/events/blue-background.jpg';
        this.coverImage2 = course.coverImage2 || 'assets/images/events/blue-background.jpg';
        this.coverImage3 = course.coverImage3 || 'assets/images/events/blue-background.jpg';
        this.courseUrl = course.courseUrl || ' ';
        this.courseType = course.courseType || CourseType.Internal;
        this.learnDescription = course.learnDescription || ' ';
        this.requirements = course.requirements || ' ';
        this.level = course.level || ' ';
        this.tags = course.tags || ' ';
        this.overviewSourceMedia = course.overviewSourceMedia || ' ';
        this.overviewFileName = course.overviewFileName || ' ';
        this.overviewFileType = course.overviewFileType || ' ';
        this.overviewFileSize = course.overviewFileSize || ' ';
        this.presignedUrl = course.presignedUrl || ' ';
        this.posterFileUrl = course.posterFileUrl || ' ';
        this.transcoderJobId = course.transcoderJobId || null;
        this.transcoderMeta = course.transcoderMeta && course.transcoderMeta.length> 0 ? JSON.parse(course.transcoderMeta) : null;
        this._version =  course._version || null;
    }
}
