import { Injectable } from '@angular/core';

import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, BehaviorSubject, of} from 'rxjs';

import {
  APIService,
  ModelQuizQuestionFilterInput,
  ModelQuestionAnswersFilterInput,
  elStatus,
  ModelStringKeyConditionInput, ModelSortDirection, elSubStatus, CreateCourseAssetSubsInput, UpdateCourseAssetSubsInput
} from 'app/API.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service.js';
import {AuthService} from "../auth/services/auth.service";
import {Logger} from "aws-amplify";

export interface ioption {
  text: string,
  correct: boolean
}

@Injectable()
export class QuizViewerService {
  private logger = new Logger('Quiz Overview Service');

  timeLeft: number;
  totalQuizTime: number = 0;

  questions: any;
  userOptionsSelected: any[] = [];

  courseAsset: any;
  listQuestions: any[];
  quiz: any;
  routeParams: any;
  parentRouteParams: any;

  private onQuizAssetChanged = new BehaviorSubject<any>(null);
  onQuizAssetChanged$ = this.onQuizAssetChanged.asObservable();

  private onAssetSubsChanged = new BehaviorSubject<any>(null);
  onAssetSubsChanged$ = this.onAssetSubsChanged.asObservable();

  private onStepChanged = new BehaviorSubject<any>(null);
  onStepChanged$ = this.onStepChanged.asObservable();

  constructor(
    private api: APIService,
    private _fuseProgressBarService: FuseProgressBarService,
    private authService: AuthService,
  ){}

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any{
    this.routeParams = route.params;
    this.parentRouteParams = route.parent.params;
    return new Promise((resolve, reject) => {
        Promise.all([
          this.getAsset(this.routeParams.assetId, this.parentRouteParams.courseId)
        ]).then(() => {
            resolve();
          },
          reject
        );
    });
  }

  getAsset(assetId: string, courseId: string): Promise<any>{
    console.log("===== GETQUIZCONTENT =====");
    return new Promise((resolve, reject) => {
      this.api.GetCourseAssets(assetId)
      .then(courseAsset => {
        this.courseAsset = courseAsset;
        return this.api.GetQuiz(this.courseAsset.quiz.id);
      })
      .then(quiz => {
        this.courseAsset.quiz = quiz;
        let filter: ModelQuizQuestionFilterInput;
        filter = {
          quizContentId: {contains: this.courseAsset.id}
        };
        return this.api.ListQuizQuestions(filter, 999);
      })
      .then(listQuestions => {
        console.log(listQuestions);
        this.listQuestions = listQuestions.items;
        if (this.listQuestions.length===0 || this.listQuestions.length ===1) {
          this.courseAsset.quiz.questions.items = this.listQuestions;
        } else {
          const arrShufle = this.fisherYates(this.listQuestions);
          this.courseAsset.quiz.questions.items = arrShufle.slice(0, this.courseAsset.quiz.questionsRandom);
        }
        this.questions = this.courseAsset.quiz.questions.items;
        return this.getAssetSubs(this.courseAsset.id);
      }).then(assetSub => {
        console.log(assetSub);
        if (assetSub.items.length=== 0) {
          return this.AssetSubscribe(this.courseAsset, courseId, elSubStatus.Enrolled )
        } else {
          return assetSub.items[0];
        }
      }).then(subsCompleted => {
        this.courseAsset.assetSub = subsCompleted;
        this.onAssetSubsChanged.next(subsCompleted);
        this.onQuizAssetChanged.next(this.courseAsset);
        this.userOptionsSelected = [];
        this.onStepChanged.next('intro');
        resolve(this.courseAsset);
      })
      .catch(err => {
        this.catchError(err);
        reject(err)
      })
    })
  }

  getAssetSubs(assetId: string): Promise<any> {
    if (!this.authService.loggedIn) {
      of(Promise.reject('User is not Logged In!'));
    }
    const userInfo = this.authService.getUserId();
    const userId = userInfo.getUsername();
    return new Promise((resolve, reject) => {
      const filterCond: ModelStringKeyConditionInput = {
        eq: assetId
      };
      this.api.SubsCourseAssetByIds(
          userId,
          filterCond,
          ModelSortDirection.DESC,
          null,
          1,
          null
      ).then((result) => {
        resolve(result);
      }).catch((err) => reject(err));
    });
  }

  AssetSubscribe(asset: any, courseId: string, status: elSubStatus): Promise<any> {
    if (!this.authService.loggedIn) {
      this.catchError('User is not Logged In!');
      of(Promise.reject('User is not Logged In!'));
    }
    const userInfo = this.authService.getUserId();
    const userId = userInfo.getUsername();
    return new Promise((resolve, reject) => {
      const subsInput: CreateCourseAssetSubsInput = {
        assetId: asset.id,
        courseAssetSubsAssetsSubsId: courseId,
        courseSubsId: asset.courseId,
        assetType: asset.assetType,
        title: asset.title,
        description: asset.description,
        userId: userId,
        status: status,
        updatedAt: new Date().toISOString(),
        createdAt: new Date().toISOString(),
      };
      return this.api.CreateCourseAssetSubs(subsInput )
          .then((result) => {
            resolve(result);
          })
          .catch((err) => reject(err));
    });
  }

  getQuestionAnswers(quizQuestionId: string): Promise<any> {
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      let filter: ModelQuestionAnswersFilterInput;
      filter = {
        quizQuestionId: {eq: quizQuestionId},
        status: {eq: elStatus.active}
      };
      let pageSize = 999;
      let nextToken = null;
      this.api.ListQuestionAnswerss(filter, pageSize, nextToken)
        .then(listQuestionAnswer => {
          if(listQuestionAnswer.items.length > 0){
            for(let i=0;i<this.questions.length;i++){
              if(this.questions[i].id === listQuestionAnswer.items[0].question.id){
                this.questions[i].questionAnswers.items = listQuestionAnswer;
                break;
              }
            }
          }
          const arrShufle = this.fisherYates(listQuestionAnswer.items);
          listQuestionAnswer.items = arrShufle.slice(0, arrShufle.length);
          this._fuseProgressBarService.hide();
          resolve(listQuestionAnswer);
        })
        .catch(err => {
          this.catchError(err);
          reject(err);
        })
    });
  }

  UpdateAssetSubscription(asset: any, statusMeta: string , status: elSubStatus): Promise<any> {
    return new Promise((resolve, reject) => {
      const subsInput: UpdateCourseAssetSubsInput = {
        id: asset.id,
        status: status,
        metaData: statusMeta,
        updatedAt: new Date().toISOString(),
        _version: asset._version
      };
      return this.api.UpdateCourseAssetSubs(subsInput )
          .then((result) => {
            this.onAssetSubsChanged.next(result);
            resolve(result);
          })
          .catch((err) => reject(err));
    });
  }

  evaluateUserAnswer(optionIndex: number, optionsSelected: any[], elapsedTime: number, timeLeft: number) {
    console.log("=== evaluate answer ===");
    this.totalQuizTime += elapsedTime;
    this.timeLeft = timeLeft;
    this.userOptionsSelected.push({
        questionId: "4123sad65465s4d65xcs64",
        timeLeft: timeLeft,
        elapsedTime: elapsedTime,
        isCorrect: true,
        order: 1,                       // number 1,2,3,4,5...
        answerSelected: optionsSelected // array
      }
    );
  }

  async getTotalSummary(){
    let newList=[];
    for(const item of this.userOptionsSelected){
      const questionId = item.answerSelected[0].question.id;
      let filter: ModelQuestionAnswersFilterInput;
      filter = {
        quizQuestionId: {eq: questionId},
        status: {eq: elStatus.active}
      };
      const list = await this.api.ListQuestionAnswerss(filter, 999);
      if(list.items.length > 0){
        for(let i=0; i<this.listQuestions.length;i++){
          if(this.listQuestions[i].id === list.items[0].question.id){
            this.listQuestions[i].questionAnswers.items = list.items;
            this.listQuestions[i].userOptions = item;
            newList.push(this.listQuestions[i]);
          }
        }
      }
    }
    return newList;
  }
  
  // Fisher-Yates Shuffle
  fisherYates(arr: any[] ): any[]{
    let j, temp, i;
    for (i = arr.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      temp = arr[i];
      arr[i] = arr[j];
      arr[j] = temp;
    }
    return arr;
  }

  changeStep(step: string) {
    if (step==="intro") {
      this.userOptionsSelected = [];
    }
    this.onStepChanged.next(step);
  }

  private catchError(error) {
    this.logger.debug('OOPS!', error);
    this._fuseProgressBarService.hide();
  }
}
