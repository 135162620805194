import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {
    APIService, CreateNotificationInput, DeleteNotificationInput, ModelNotificationFilterInput, UpdateNotificationInput
} from '../../../API.service';
import * as _ from 'lodash';
import {Logger} from '@aws-amplify/core';
import { Notification } from '../../../../models';
import {DataStore} from '@aws-amplify/datastore';
import {FuseProgressBarService} from '../../../../@fuse/components/progress-bar/progress-bar.service';
import {SyncSettings} from '../../../../@fuse/services/sync-settings.service';
import {NotifyModel} from './notification.model';
import { format } from 'date-fns';
import * as AWS from 'aws-sdk';
import {getDeepFromObject} from '../../auth/helpers';
import {NOTIFY_MANAGER_OPTIONS} from './notifications.options';

@Injectable()
export class NotifyListService implements Resolve<any>
{

  private logger = new Logger('NotificationsList');
  private onNotifyChanged: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public onNotifyChanged$: Observable<any[]> = this.onNotifyChanged.asObservable();

  private onNextPageChanged: BehaviorSubject<any> = new BehaviorSubject(null);
  public onNextPageChanged$: Observable<any> = this.onNextPageChanged.asObservable();

  public onFilterChanged: BehaviorSubject<any> = new BehaviorSubject(null);
  public onFilterChanged$: Observable<any> = this.onFilterChanged.asObservable();

  public onSearchTextChanged: BehaviorSubject<any> = new BehaviorSubject(null);
  public onSearchTextChanged$: Observable<any> = this.onSearchTextChanged.asObservable();

  private onSelectedNotifiesChanged: BehaviorSubject<any> = new BehaviorSubject([]);
  public onSelectedNotifiesChanged$: Observable<any> = this.onSelectedNotifiesChanged.asObservable();

  public onCurrentNotifyChanged: BehaviorSubject<any> = new BehaviorSubject(null);
  public onCurrentNotifyChanged$: Observable<any> = this.onCurrentNotifyChanged.asObservable();


  filters: any[];
  selectedNotifies: Notification[];

  notify: Notification[];
  pageSize: number;
  nextToken: string;
  searchTxt: string;
  filterByTxt: string;
  filterObj: ModelNotificationFilterInput;
  routeParams: any;
  currentNotification: Notification;
  /**
   * Constructor
   *
   * @param options
   * @param api
   * @param syncSettings
   * @param _fuseProgressBarService
   */
  constructor(
      @Inject(NOTIFY_MANAGER_OPTIONS) protected options = {},
      private api: APIService,
      private syncSettings: SyncSettings,
      private _fuseProgressBarService: FuseProgressBarService
  )
  {
    // Set the defaults
    this.pageSize = 10;
    this.nextToken = null;
    this.searchTxt = null;
    this.filterByTxt = null;
    this.selectedNotifies = [];
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
      this.routeParams = route.params;
      return new Promise((resolve, reject) => {
        Promise.all([
          this.listNotify(this.filterObj, this.nextToken)
        ]).then(
          () => {
            if ( this.routeParams.msgId )
            {
              this.setCurrentNotify(this.routeParams.msgId);
            }
            else
            {
              this.setCurrentNotify(null);
            }

            this.syncSettings.onSettingsChanged$
              .subscribe((res) => {
                if (res !== null) {
                  this.pageSize = res.pageSize;
                  this.nextToken = null;
                  this.refresh();
                }
            });
            this.onFilterChanged$.subscribe((filterBy: string) => {
              this.filterByTxt = filterBy;
              this.filterObj = this.buildFilter(this.searchTxt, this.filterByTxt);
              of(this.listNotify(this.filterObj, null));
            });

            this.onSearchTextChanged$.subscribe((searchBy: string) => {
              this.searchTxt = searchBy;
              this.filterObj = this.buildFilter(this.searchTxt, this.filterByTxt);
              of(this.listNotify(this.filterObj, null));
            });

            this.api.OnCreateNotificationListener.subscribe(msg => {
                this.refresh();
            });
            this.api.OnUpdateNotificationListener.subscribe(msg => {
                this.refresh();
            });

            this.api.OnDeleteNotificationListener.subscribe(msg => {
                this.refresh();
            });
            resolve();
          },
          reject
        );
      });
  }

  public refresh() {
    this.nextToken = null;
    this.onNextPageChanged.next(this.nextToken );
    of(this.listNotify(this.filterObj, this.nextToken));
  }

  public goNextPage( nextPageToken: string) {
    of(this.listNotify(this.filterObj,  nextPageToken));
  }

  setCurrentNotify(id: string): void {
    this.currentNotification = this.notify.find(msg => {
      return msg.id === id;
    });
    this.onCurrentNotifyChanged.next(this.currentNotification);
  }

  public buildFilter(searchTxt?: string, filterTxt?: string): ModelNotificationFilterInput {
      let filter: ModelNotificationFilterInput;
      const sTxt = searchTxt === undefined || _.isEmpty(searchTxt) || searchTxt === null;
      const fObj = filterTxt === undefined || _.isEmpty(filterTxt) || filterTxt === null;
      if (!sTxt && fObj) {
        filter = {
          subject: {contains: searchTxt}
        };
      } else if (sTxt && !fObj) {
        filter = {
          typeNotify: {eq: filterTxt}
        };
      } else if (!sTxt && !fObj) {
        filter = {
          subject: {contains: searchTxt},
          and: [
            {typeNotify: {eq: filterTxt}}
          ]
        };
      } else {
        filter = null;
      }
      return filter;
  }

  listNotify(filterIn?: ModelNotificationFilterInput, nextToken?: string): Promise<any[]> {
    this.nextToken = nextToken ? nextToken : null;
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this.api.ListNotifications(filterIn, this.pageSize, this.nextToken)
        .then((result) => {
            console.log(result);
          this.nextToken = !_.isEmpty(result['nextToken']) ? result['nextToken'] : null;
          this.onNextPageChanged.next(this.nextToken );
          this.notify = result.items;
          if (this.notify.length === 0) {
              this.onCurrentNotifyChanged.next(null);
          }
          this.onNotifyChanged.next(this.notify);
          this._fuseProgressBarService.hide();
          resolve(this.notify);
        })
        .catch((err) => {
          this.catchError(err);
          reject(err);
        });
    });
  }

  addData(data: any): Promise<Notification> {
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      const urlTxt = _.isEmpty(data.localUrl) ? ' ' : data.localUrl;
      const dateFor = format(new Date(), 'yyyy-MM-dd');
      const payload: CreateNotificationInput = {
          typeNotify: data.notiType,
          userId: ' ',
          body: data.message,
          subject: data.subject,
          localUrl: urlTxt,
          renotify: true,
          silent: false,
          dateFor: dateFor,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
      }
      this.api.CreateNotification(payload)
          .then(result => {
              this._fuseProgressBarService.hide();
              resolve(result);
          }).catch( error => {
            this.catchError(error);
            reject(error);
        });
    });
  }

  saveData(data: NotifyModel): Promise<Notification> {
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
        const payload: UpdateNotificationInput = {
            id: data.id,
            typeNotify: data.typeNotify,
            userId: data.userId,
            body: data.body,
            subject: data.subject,
            localUrl: data.localUrl,
            renotify: data.renotify,
            silent: data.silent,
            updatedAt: new Date().toISOString(),
            _version: data._version
        }
        this.api.UpdateNotification(payload)
            .then(result => {
                this.currentNotification = result;
                this.onCurrentNotifyChanged.next( this.currentNotification);
                this._fuseProgressBarService.hide();
                resolve(this.currentNotification);
            }).catch( error => {
                this.catchError(error);
                reject(error);
            });
    });
  }

  toggleResend(data: any): Promise<Notification> {
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      const dateFor = format(new Date(), 'yyyy-MM-dd');
      const payload: UpdateNotificationInput = {
            id: data.id,
            dateFor: dateFor,
            typeNotify: data.typeNotify,
            updatedAt: new Date().toISOString(),
            _version: data._version
        }
        this.api.UpdateNotification(payload)
            .then(result => {
                this.currentNotification = result;
                this.onCurrentNotifyChanged.next( this.currentNotification);
                this._fuseProgressBarService.hide();
                resolve(this.currentNotification);
            }).catch( error => {
                this.catchError(error);
                reject(error);
        });
    });
  }

  toggleSilent(data: any): Promise<Notification> {
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
        const dateFor = format(new Date(), 'yyyy-MM-dd');
        const payload: UpdateNotificationInput = {
            id: data.id,
            dateFor: dateFor,
            renotify: (!!data.silent),
            silent: (!data.silent),
            updatedAt: new Date().toISOString(),
            _version: data._version
        }
        this.api.UpdateNotification(payload)
            .then(result => {
                  this.currentNotification = result;
                  this.onCurrentNotifyChanged.next( this.currentNotification);
                  this._fuseProgressBarService.hide();
                  resolve(this.currentNotification);
            }).catch( error => {
                  this.catchError(error);
                  reject(error);
            });
    });
  }

  deleteNotify(data: any): Promise<Notification> {
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      const payload: DeleteNotificationInput = {
          id: data.id,
          _version: data._version
      }
      console.log(payload);
      this.api.DeleteNotification(payload)
          .then(result => {
              this._fuseProgressBarService.hide();
              resolve(result);
          }).catch( error => {
              this.catchError(error);
              reject(error);
      });
    });
  }

  syncCognito(): any {
    return new AWS.CognitoIdentityServiceProvider(
      {
        apiVersion: '2016-04-18',
        region: this.getConfigValue('region'),
        accessKeyId: this.getConfigValue('accessKeyId'),
        secretAccessKey: this.getConfigValue('secretAccessKey')
      }
    );
  }

  mapAttributeFromUser(attrName: string, attrs: any[]) {
    const found = _.find(attrs, ['Name', attrName]);
    if (found) {
      return found['Value'];
    }
    return null;
  }

  getUser(userName: string ): Promise<any> {
    return new Promise((resolve, reject) => {
      const conn = this.syncCognito();
      const params = {
        UserPoolId: this.getConfigValue('UserPoolId'),
        Username: userName
      };
      conn.adminGetUser(params, (err, data) => {
        if (err) {
          console.log(err);
          reject(err);
        }
        const email = this.mapAttributeFromUser('email', data.UserAttributes);
        resolve(email);
      });
    });
  }

  // UI aid functions
  /**
   * Toggle select all
   */
  toggleSelectAll(): void {
    if ( this.selectedNotifies.length > 0 )
    {
      this.deselectNotifications();
    }
    else
    {
      this.selectNotifications();
    }

  }

  getSelectedNotifications() {
    return this.selectedNotifies;
  }

  toggleSelectedNotification(id): void {
    // First, check if we already have that as selected...
    if ( this.selectedNotifies.length > 0 ) {
      for ( const row of this.selectedNotifies ) {
        // ...delete the selected mail
        if ( row.id === id ) {
          const index = this.selectedNotifies.indexOf(row);

          if ( index !== -1 ) {
            this.selectedNotifies.splice(index, 1);
            // Trigger the next event
            this.onSelectedNotifiesChanged.next(this.selectedNotifies);
            // Return
            return;
          }
        }
      }
    }

    // If we don't have it, push as selected
    this.selectedNotifies.push(
      this.notify.find(item => {
        return item.id === id;
      })
    );

    // Trigger the next event
    this.onSelectedNotifiesChanged.next(this.selectedNotifies);
  }


  /**
   * Select notifications
   *
   */
  selectNotifications(): void {
    this.selectedNotifies = [];
    this.selectedNotifies = [...this.notify];
    // Trigger the next event
    this.onSelectedNotifiesChanged.next(this.selectedNotifies);
  }


  /**
   * Deselect notifications
   */
  deselectNotifications(): void
  {
    this.selectedNotifies = [];
    this.onSelectedNotifiesChanged.next(this.selectedNotifies);
  }

  // Functions
  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }

  private catchError(error) {
      console.log(error);
      this.logger.debug('OOPS!', error);
      this._fuseProgressBarService.hide();
    }
}
