import {Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {UsersService} from "../../../../users/users.service";
import {CourseService} from "../../../course.service";
import {Subject} from "rxjs";

@Component({
  selector: 'admin-course-dashboard',
  templateUrl: './course-dashboard.component.html',
  styleUrls: ['./course-dashboard.component.scss']
})
export class CourseDashboardComponent implements OnInit, OnDestroy
{

  @Input()
  media: any;

  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };

  single: any[];
  multi: any[];

  view: any[] = [700, 400];
  legend: boolean = false;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param _usersService
   * @param _courseService
   */
  constructor(
      private _usersService: UsersService,
      private _courseService: CourseService,
  )
  {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void
  {

    const cDate = new Date().toISOString().slice(0,6);
    this._courseService.getUserSubscriptions(cDate)
        .then(subs => {
          this.single =  [{
            "name": "Subscribers",
            "value": subs.items.length
          }];
        });


  }

  /**
   * On destroy
   */
  ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }


}

