/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api/lib/types";
import * as Observable from "zen-observable";
import {
    ContentType,
    CourseType, elAssetType,
    elCourseTrend, elQuestionType,
    elStatus, elSubStatus, ListCourseAssetSubssQuery,
    ModelCourseAssetsFilterInput, ModelCourseAssetSubsFilterInput,
    ModelCourseTypeInput,
    ModelelCourseTrendInput,
    ModelelStatusInput,
    ModelIDInput, ModelIntKeyConditionInput, ModelSortDirection,
    ModelStringInput, ResourceType,
} from "./API.service";

export type ListCoursesQuery = {
  __typename: "ModelCourseConnection";
  items: Array<{
    __typename: "Course";
    id: string;
    title: string;
    description: string;
    category: {
      id: string;
      category: string;
      _deleted: boolean | null;
    };
    timeAprox: string;
    status: elStatus;
    coverImage1: string | null;
    courseType: CourseType | null;
    courseUrl: string | null;
    courseTrend: elCourseTrend | null;
    learnDescription: string | null;
    requirements: string | null;
    level: string | null;
    tags: string | null;
    trending: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    assets: {
      items: Array<{
          id: string;
          status: elStatus;
      } | null> | null;
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type ListCourseAssetssQuery = {
    __typename: "ModelCourseAssetsConnection";
    items: Array<{
        __typename: "CourseAssets";
        id: string;
        courseId: string;
        assetId: string | null;
        assetType: elAssetType;
        title: string;
        description: string;
        order: number | null;
        timeAprox: string | null;
        starred: boolean | null;
        important: boolean | null;
        showAsOverview: boolean | null;
        showAsFree: boolean | null;
        nextStep: string | null;
        prevStep: string | null;
        status: elStatus;
        updatedAt: string | null;
        createdAt: string | null;
        topic: {
            __typename: "Content";
            id: string;
            courseId: string;
            contentType: ContentType;
            media: string | null;
            sourceMedia: string | null;
            fileName: string | null;
            fileType: string | null;
            fileSize: string | null;
            updatedAt: string | null;
            createdAt: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
        } | null;
        quiz: {
            __typename: "Quiz";
            id: string;
            courseId: string;
            questionsRandom: number | null;
            passingScore: number | null;
            noAttempts: number | null;
            score: number | null;
            labelWhenPassed: string | null;
            labelWhenFailed: string | null;
            questionFeedback: boolean | null;
            updatedAt: string | null;
            createdAt: string | null;
            status: elStatus | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
        } | null;
        attachments: {
            __typename: "ModelCourseAssetsResourceConnection";
            items: Array<{
                id: string;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
        } | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
};

export type ListCourseAssetsByOrderQuery = {
    __typename: "ModelCourseAssetsConnection";
    items: Array<{
        __typename: "CourseAssets";
        id: string;
        courseId: string;
        assetId: string | null;
        assetType: elAssetType;
        title: string;
        description: string;
        order: number | null;
        timeAprox: string | null;
        starred: boolean | null;
        important: boolean | null;
        showAsOverview: boolean | null;
        showAsFree: boolean | null;
        nextStep: string | null;
        prevStep: string | null;
        status: elStatus;
        updatedAt: string | null;
        createdAt: string | null;
        topic: {
            __typename: "Content";
            id: string;
            courseId: string;
            contentType: ContentType;
            media: string | null;
            sourceMedia: string | null;
            fileName: string | null;
            fileType: string | null;
            fileSize: string | null;
            updatedAt: string | null;
            createdAt: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
        } | null;
        quiz: {
            __typename: "Quiz";
            id: string;
            courseId: string;
            questionsRandom: number | null;
            passingScore: number | null;
            noAttempts: number | null;
            score: number | null;
            labelWhenPassed: string | null;
            labelWhenFailed: string | null;
            questionFeedback: boolean | null;
            updatedAt: string | null;
            createdAt: string | null;
            status: elStatus | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
        } | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
};

export type GetCourseQuery = {
    __typename: "Course";
    id: string;
    title: string;
    description: string;
    category: {
        id: string;
        category: string;
        status: elStatus | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
    };
    timeAprox: string;
    status: elStatus;
    coverImage1: string | null;
    coverImage2: string | null;
    coverImage3: string | null;
    courseType: CourseType | null;
    courseUrl: string | null;
    overviewSourceMedia: string | null;
    overviewFileName: string | null;
    overviewFileType: string | null;
    overviewFileSize: string | null;
    courseTrend: elCourseTrend | null;
    learnDescription: string | null;
    requirements: string | null;
    level: string | null;
    tags: string | null;
    trending: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
};

export type ModelCourseFilterInput = {
    id?: ModelIDInput | null;
    title?: ModelStringInput | null;
    description?: ModelStringInput | null;
    categoryId?: ModelIDInput | null;
    coverImage?: ModelStringInput | null;
    timeAprox?: ModelStringInput | null;
    status?: ModelelStatusInput | null;
    coverImage1?: ModelStringInput | null;
    coverImage2?: ModelStringInput | null;
    coverImage3?: ModelStringInput | null;
    courseType?: ModelCourseTypeInput | null;
    courseUrl?: ModelStringInput | null;
    overviewSourceMedia?: ModelStringInput | null;
    overviewFileName?: ModelStringInput | null;
    overviewFileType?: ModelStringInput | null;
    overviewFileSize?: ModelStringInput | null;
    courseTrend?: ModelelCourseTrendInput | null;
    learnDescription?: ModelStringInput | null;
    requirements?: ModelStringInput | null;
    level?: ModelStringInput | null;
    tags?: ModelStringInput | null;
    trending?: ModelStringInput | null;
    updatedAt?: ModelStringInput | null;
    createdAt?: ModelStringInput | null;
    and?: Array<ModelCourseFilterInput | null> | null;
    or?: Array<ModelCourseFilterInput | null> | null;
    not?: ModelCourseFilterInput | null;
};

@Injectable({
  providedIn: "root"
})
export class APICustomService {
  async ListCourses(
    filter?: ModelCourseFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCoursesQuery> {
    const statement = `query ListCourses($filter: ModelCourseFilterInput, $limit: Int, $nextToken: String) {
        listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            title
            description
            category {
              id
              category
              _deleted
            }
            timeAprox
            status
            coverImage1
            courseType
            courseUrl
            courseTrend
            level
            tags
            trending
            updatedAt
            createdAt
            assets(limit: 9999) {
              items {
                id
                status
              }
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCoursesQuery>response.data.listCourses;
  }

    async GetCourse(id: string): Promise<GetCourseQuery> {
        const statement = `query GetCourse($id: ID!) {
        getCourse(id: $id) {
           __typename
          id
          title
          description
          category {
            id
            category
            _version
            _deleted
            _lastChangedAt
          }
          timeAprox
          status
          coverImage1
          coverImage2
          coverImage3
          courseType
          courseUrl
          overviewSourceMedia
          overviewFileName
          overviewFileType
          overviewFileSize
          courseTrend
          learnDescription
          requirements
          level
          tags
          trending
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
        const gqlAPIServiceArguments: any = {
            id
        };
        const response = (await API.graphql(
            graphqlOperation(statement, gqlAPIServiceArguments)
        )) as any;
        return <GetCourseQuery>response.data.getCourse;
    }

    async ListCourseAssetss(
        filter?: ModelCourseAssetsFilterInput,
        limit?: number,
        nextToken?: string
    ): Promise<ListCourseAssetssQuery> {
        const statement = `query ListCourseAssetss($filter: ModelCourseAssetsFilterInput, $limit: Int, $nextToken: String) {
        listCourseAssetss(filter: $filter, limit: $limit, nextToken: $nextToken) {
           __typename
          items {
            __typename
            id
            courseId
            assetId
            assetType
            title
            description
            order
            timeAprox
            starred
            important
            showAsOverview
            showAsFree
            nextStep
            prevStep
            status
            updatedAt
            createdAt
            topic {
              __typename
              id
              courseId
              contentType
              media
              sourceMedia
              fileName
              fileType
              fileSize
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            quiz {
              __typename
              id
              courseId
              questionsRandom
              passingScore
              noAttempts
              score
              labelWhenPassed
              labelWhenFailed
              questionFeedback
              updatedAt
              createdAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            attachments {
              __typename
              items {
                id
              }
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
        const gqlAPIServiceArguments: any = {};
        if (filter) {
            gqlAPIServiceArguments.filter = filter;
        }
        if (limit) {
            gqlAPIServiceArguments.limit = limit;
        }
        if (nextToken) {
            gqlAPIServiceArguments.nextToken = nextToken;
        }
        const response = (await API.graphql(
            graphqlOperation(statement, gqlAPIServiceArguments)
        )) as any;
        return <ListCourseAssetssQuery>response.data.listCourseAssetss;
    }

    async ListCourseAssetsByOrder(
        courseId?: string,
        order?: ModelIntKeyConditionInput,
        sortDirection?: ModelSortDirection,
        filter?: ModelCourseAssetsFilterInput,
        limit?: number,
        nextToken?: string
    ): Promise<ListCourseAssetsByOrderQuery> {
        const statement = `query ListCourseAssetsByOrder($courseId: ID, $order: ModelIntKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCourseAssetsFilterInput, $limit: Int, $nextToken: String) {
        ListCourseAssetsByOrder(courseId: $courseId, order: $order, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            courseId
            assetId
            assetType
            title
            description
            order
            timeAprox
            starred
            important
            showAsOverview
            showAsFree
            nextStep
            prevStep
            status
            updatedAt
            createdAt
            topic {
              __typename
              id
              courseId
              contentType
              media
              sourceMedia
              fileName
              fileType
              fileSize
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            quiz {
              __typename
              id
              courseId
              questionsRandom
              passingScore
              noAttempts
              score
              labelWhenPassed
              labelWhenFailed
              questionFeedback
              updatedAt
              createdAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
        const gqlAPIServiceArguments: any = {};
        if (courseId) {
            gqlAPIServiceArguments.courseId = courseId;
        }
        if (order) {
            gqlAPIServiceArguments.order = order;
        }
        if (sortDirection) {
            gqlAPIServiceArguments.sortDirection = sortDirection;
        }
        if (filter) {
            gqlAPIServiceArguments.filter = filter;
        }
        if (limit) {
            gqlAPIServiceArguments.limit = limit;
        }
        if (nextToken) {
            gqlAPIServiceArguments.nextToken = nextToken;
        }
        const response = (await API.graphql(
            graphqlOperation(statement, gqlAPIServiceArguments)
        )) as any;
        return <ListCourseAssetsByOrderQuery>response.data.ListCourseAssetsByOrder;
    }

    async ListCourseAssetssToCount(
        filter?: ModelCourseAssetsFilterInput,
        limit?: number,
        nextToken?: string
    ): Promise<ListCourseAssetssQuery> {
        const statement = `query ListCourseAssetss($filter: ModelCourseAssetsFilterInput, $limit: Int, $nextToken: String) {
        listCourseAssetss(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
         items {
            __typename
            id
            courseId
            assetType
            title
            order
            timeAprox
            starred
            important
            showAsOverview
            showAsFree
            status
            updatedAt
            createdAt
          }
          nextToken
          startedAt
        }
      }`;
        const gqlAPIServiceArguments: any = {};
        if (filter) {
            gqlAPIServiceArguments.filter = filter;
        }
        if (limit) {
            gqlAPIServiceArguments.limit = limit;
        }
        if (nextToken) {
            gqlAPIServiceArguments.nextToken = nextToken;
        }
        const response = (await API.graphql(
            graphqlOperation(statement, gqlAPIServiceArguments)
        )) as any;
        return <ListCourseAssetssQuery>response.data.listCourseAssetss;
    }

    async ListCourseAssetSubssToCount(
        filter?: ModelCourseAssetSubsFilterInput,
        limit?: number,
        nextToken?: string
    ): Promise<ListCourseAssetSubssQuery> {
        const statement = `query ListCourseAssetSubss($filter: ModelCourseAssetSubsFilterInput, $limit: Int, $nextToken: String) {
        listCourseAssetSubss(filter: $filter, limit: $limit, nextToken: $nextToken) {
           __typename
          items {
            __typename
            id
            courseSubsId
            assetId
            userId
            assetType
            metaData
            status
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            owner
          }
          nextToken
          startedAt
        }
      }`;
        const gqlAPIServiceArguments: any = {};
        if (filter) {
            gqlAPIServiceArguments.filter = filter;
        }
        if (limit) {
            gqlAPIServiceArguments.limit = limit;
        }
        if (nextToken) {
            gqlAPIServiceArguments.nextToken = nextToken;
        }
        const response = (await API.graphql(
            graphqlOperation(statement, gqlAPIServiceArguments)
        )) as any;
        return <ListCourseAssetSubssQuery>response.data.listCourseAssetSubss;
    }

    async ListCoursesSearchBar(
        filter?: ModelCourseFilterInput,
        limit?: number,
        nextToken?: string
    ): Promise<ListCoursesQuery> {
        const statement = `query ListCourses($filter: ModelCourseFilterInput, $limit: Int, $nextToken: String) {
        listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            title
            description
            category {
              __typename
              id
              category
            }
            status
            coverImage1
            courseType
            courseUrl
            courseTrend
            level
            tags
            trending
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
        const gqlAPIServiceArguments: any = {};
        if (filter) {
            gqlAPIServiceArguments.filter = filter;
        }
        if (limit) {
            gqlAPIServiceArguments.limit = limit;
        }
        if (nextToken) {
            gqlAPIServiceArguments.nextToken = nextToken;
        }
        const response = (await API.graphql(
            graphqlOperation(statement, gqlAPIServiceArguments)
        )) as any;
        return <ListCoursesQuery>response.data.listCourses;
    }

}
