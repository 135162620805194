import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit, ViewChild
} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {AVideoOverviewService} from '../../avideo-preview.service';
import {MatVideoComponent} from "mat-video/lib/video.component";
import {elSubStatus} from "../../../../API.service";
@Component({
  selector: 'academy-video-preview',
  templateUrl: './video-preview.component.html',
  styleUrls: ['./video-preview.component.scss'],
  animations   : fuseAnimations
})
export class VideoPreviewComponent implements OnInit, OnDestroy {

  currentStep: any;
  currentSubs: any;
  animationDirection: 'left' | 'right' | 'none';
  loadingProgress: boolean;
  @ViewChild('videoKtc', { read: true, static: false })
  matVideo: MatVideoComponent;
  video: any;

  private _unsubscribeAll: Subject<any>;

  constructor(
    private _videoOverviewService: AVideoOverviewService,
    private _changeDetectorRef: ChangeDetectorRef,
  ) {

    this._unsubscribeAll = new Subject();
    this.animationDirection = 'none';
    this.loadingProgress = false;
  }

  ngOnInit(): void {
    this.loadingProgress = true;
    this.video = document.querySelector('video');
    this.video.addEventListener('timeupdate',  event => {
      console.log(this.video.currentTime)
    });

    this.video.addEventListener('ended',  event => {
      const statusMeta = JSON.stringify({ time: this.video.currentTime }) ;
      this._videoOverviewService.UpdateAssetSubscription(this.currentSubs, statusMeta, elSubStatus.Completed )
          .then( updateOk => {
              console.log('completed');
          })

    });

    this._videoOverviewService.onAssetChanged$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(asset => {
        if (asset) {
          this.currentStep = asset;
          this.loadingProgress = false;
          this.animationDirection = 'right';
          this._changeDetectorRef.detectChanges();
        }
        this.loadingProgress = false;
      });

    this._videoOverviewService.onAssetSubsChanged$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(subs => {
        this.currentSubs = subs;
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    console.log('destroy');
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
