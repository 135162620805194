import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'quiz-intro',
  templateUrl: './quiz-intro.component.html',
  styleUrls: ['./quiz-intro.component.scss']
})
export class QuizIntroComponent implements OnInit {

  @Input()
  courseAsset: any;

  @Input()
  timer: any;

  @Output()
  onStartQuiz: EventEmitter<any> = new EventEmitter();

  constructor() {

  }

  ngOnInit() {
    console.log(this.courseAsset);
  }

  startQuiz() {
      this.onStartQuiz.emit(true)
  }
}
