import {ModuleWithProviders, NgModule} from '@angular/core';
import { StudentsComponent } from './students.component';
import {UsersService} from "../../admin/users/users.service";
import {STUDENTS_MANAGER_OPTIONS, StudentsOptions} from "./students.options";
import {RouterModule, Routes} from "@angular/router";
import {AuthGuard} from "../../auth/auth.guard";
import {StudentsService} from "./students.service";
import {MatIconModule} from "@angular/material/icon";
import {MatSelectModule} from "@angular/material/select";
import {MatSelectSearchModule} from "../../../core/mat-select-search/mat-select-search.module";
import {FuseConfirmDialogModule, FuseSidebarModule} from "../../../../@fuse/components";
import {MatButtonModule} from "@angular/material/button";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatMenuModule} from "@angular/material/menu";
import {MatRippleModule} from "@angular/material/core";
import {MatTableModule} from "@angular/material/table";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatTabsModule} from "@angular/material/tabs";
import {FuseSharedModule} from "../../../../@fuse/shared.module";
import {AvatarModule} from "ngx-avatar";
import {MatListModule} from "@angular/material/list";
import {MatBottomSheetModule} from "@angular/material/bottom-sheet";
import {MatCardModule} from "@angular/material/card";
import {ContentLoaderModule} from "../../../core/loaders/content-loader.module";
import { SelectBarStudentsComponent } from './components/select-bar-students/select-bar-students.component';
import { StudentListComponent } from './components/student-list/student-list.component';
import { StudentViewSidebarComponent } from './sidebars/student-view-sidebar/student-view-sidebar.component';
import { StudentProgressViewComponent } from './components/student-progress-view/student-progress-view.component';
import { StudentProgressCellComponent } from './components/student-progress-cell/student-progress-cell.component';
import { StudentStatusCellComponent } from './components/student-status-cell/student-status-cell.component';
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {ContactUserListComponent} from "./components/contact-user-list/contact-user-list.component";
import {MatFileUploadModule} from "mat-file-upload";
import {MatPaginatorModule} from "@angular/material/paginator";
import {LicenseStudentService} from "./license-student.service";
import { StudentImportPopupComponent } from './components/student-import-popup/student-import-popup.component';
import {MatStepperModule} from "@angular/material/stepper";
import {StudentAssigmentSidebarComponent} from "./sidebars/student-assigment-sidebar/student-assigment-sidebar.component";

const routes: Routes = [
  {
    path     : 'students',
    component: StudentsComponent,
    resolve : {
      users: StudentsService
    },
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [
    StudentsComponent,
    SelectBarStudentsComponent,
    StudentListComponent,
    StudentViewSidebarComponent,
    StudentProgressViewComponent,
    StudentProgressCellComponent,
    StudentStatusCellComponent,
    ContactUserListComponent,
    StudentImportPopupComponent,
    StudentAssigmentSidebarComponent
  ],

    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatTableModule,
        MatToolbarModule,
        MatTabsModule,
        FuseSharedModule,
        FuseConfirmDialogModule,
        FuseSidebarModule,
        AvatarModule,
        MatListModule,
        MatBottomSheetModule,
        MatCardModule,
        ContentLoaderModule,
        MatSelectModule,
        MatSelectSearchModule,
        MatProgressBarModule,
        MatFileUploadModule,
        MatPaginatorModule,
        MatStepperModule
    ],
  entryComponents: [StudentViewSidebarComponent,ContactUserListComponent, StudentImportPopupComponent, StudentAssigmentSidebarComponent ]
})
export class StudentsModule {
  static forRoot(fmOptions?: StudentsOptions): ModuleWithProviders {
    return {
      ngModule: StudentsModule,
      providers: [
        { provide: STUDENTS_MANAGER_OPTIONS, useValue:  fmOptions},
        UsersService,
        StudentsService,
        LicenseStudentService
      ],
    } as ModuleWithProviders;
  }
}
