import {
    AfterViewInit, ChangeDetectorRef,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';

import { fuseAnimations } from '@fuse/animations';
import {FormBuilder} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {AUTH_LAYOUT, AUTH_OPTIONS} from '../auth/auth.options';
import {getDeepFromObject} from '../auth/helpers';
import {ProfileService} from './profile.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {User} from "./profile.model";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {ProfileChgPasswordComponent} from "./components/profile-chg-password/profile-chg-password.component";
import {ProfilePhoneValidationComponent} from "./components/profile-phone-validation/profile-phone-validation.component";
import {AuthService} from "../auth/services/auth.service";

@Component({
    selector     : 'profile',
    templateUrl  : './profile.component.html',
    styleUrls    : ['./profile.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class ProfileComponent implements OnInit, OnDestroy, AfterViewInit
{
    horizontalPosition: MatSnackBarHorizontalPosition = 'left';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    snackDuration: number;
    currentUser: User;
    socialLogin: boolean;
    tempAvatarLink: string;
    phoneVerified: boolean;
    private _onDestroy = new Subject<void>();
    displayProgress = false;

    customAvatarStyle = {
        backgroundColor: '#ff8420',
        border: '1px solid #7e7e7e',
        borderRadius: '50%',
        color: '#155a7e'
    };
    /**
     * Constructor
     */
    constructor(
        public dialog: MatDialog,
        private _snackBar: MatSnackBar,
        private _formBuilder: FormBuilder,
        public _profileService: ProfileService,
        private _bottomSheet: MatBottomSheet,
        private _authService: AuthService,
        @Inject(AUTH_OPTIONS) protected options = {},
        @Inject(AUTH_LAYOUT) protected layoutOptions = {},
        private ref: ChangeDetectorRef,
    )
    {
        this.horizontalPosition = this.layoutOptions['snackbar']['horizontalPosition'];
        this.verticalPosition = this.layoutOptions['snackbar']['verticalPosition'];
        this.snackDuration = this.layoutOptions['snackbar']['duration'];
        this.socialLogin = false;
        this.currentUser = new User();
    }
    ngOnInit() {
        this._profileService.onAvatarChange$.subscribe((avatarImg: any) => {
          this.tempAvatarLink = avatarImg;
        });
        this._authService.userGroups$.subscribe((groups: any) => {
            this.verifyGroups(groups);
        })

    }

    ngAfterViewInit() {
        this._profileService.onPreferencesOnChanged$
            .pipe(takeUntil(this._onDestroy))
            .subscribe((user: any) => {
                if (user) {
                    this.currentUser = new User(user);
                    this.currentUser.theme = user['custom:theme'];
                    this.currentUser.language = user['custom:language'];
                    this.currentUser.avatarType = user['custom:avatarType'];
                    this.phoneVerified = user ? (!!(user.phone_number_verified || user.phone_number)) : false;
                    const identity = user.identities ? JSON.parse(user.identities)[0] : false;
                    this.socialLogin = !!(identity && identity.providerType);
                    this.ref.detectChanges();
                }
            });
    }


    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    getConfigValue(key: string): any {
        return getDeepFromObject(this.options, key, null);
    }

    onUserFormChanges($event) {
        // console.log($event);
    }

    onUserMessagesChanges($event) {
        if ($event.type === 'error') {
            this._snackBar.open( $event.message, 'End now', {
                duration: this.snackDuration,
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
            });
        } else {
            this._snackBar.open( $event.message, 'End now', {
                duration: this.snackDuration,
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
            });
        }
    }

    verifyGroups(groups: any[]){
        if(groups){
            groups.forEach(item => {
                if(item.GroupName === "USERS" ||
                    item.GroupName === "ADMIN" ||
                    item.GroupName === "INSTRUCTOR"){
                    this.displayProgress = true;
                }
            })
        }
    }

    changePassword() {
        if (!this.currentUser) {
            return;
        }
        const dataToSave = this._bottomSheet.open(ProfileChgPasswordComponent, {
            disableClose: true,
            data: this.currentUser
        });
        dataToSave.afterDismissed().subscribe((data) => {
            if (data && data.type!=='cancel') {
                this.onUserMessagesChanges(data);
            }
        });
    }

    verifyPhone(userAttr) {
        if (!this.currentUser|| userAttr.sub !== this.currentUser.sub) {
            return;
        }
        const dataToSave = this._bottomSheet.open(ProfilePhoneValidationComponent, {
            disableClose: true,
            data: this.currentUser
        });
        dataToSave.afterDismissed().subscribe((data) => {
            if (data && data.type!=='cancel') {
                this.onUserMessagesChanges(data);
            }
        });
    }
}
