import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ProfileService} from "../../profile.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {parsePhoneNumberFromString} from "libphonenumber-js";
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {takeUntil} from "rxjs/operators";
import {User} from "../../profile.model";

export class VerifyPhoneModel {
  id: string;
  verifyCode: string;
  phone: string;
  codeToVerified: string;
  /**
   * Constructor
   *
   * @param user
   */
  constructor(user?)
  {
    {
      user = user || {};
      this.id = user.sub || '';
      this.phone = user['custom:phone'] || '';
      this.codeToVerified = user['custom:verifycode'] || '';
      this.verifyCode = user.verifyCode || '';
    }
  }

  isCodeVerified(code: string): boolean {
    return  this.codeToVerified === code;
  }
}

@Component({
  selector: 'profile-phone-validation',
  templateUrl: './profile-phone-validation.component.html',
  styleUrls: ['./profile-phone-validation.component.scss']
})
export class ProfilePhoneValidationComponent implements OnInit {

  user: VerifyPhoneModel;
  onloadShow: boolean;
  notRequested: boolean;
  verifyForm: FormGroup;

  constructor(
      private _bottomSheetRef: MatBottomSheetRef<ProfilePhoneValidationComponent>,
      private _formBuilder: FormBuilder,
      public _profileService: ProfileService,
      private _snackBar: MatSnackBar,
      private _changeDetectorRef: ChangeDetectorRef,
      @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {
    this.user = new VerifyPhoneModel(data);
    this.verifyForm = this.createAttrForm();
    this.notRequested = false;
  }

  ngOnInit() {
    this._profileService.onPreferencesOnChanged$
        .subscribe((user: any) => {
          if (user) {
            this.user = new VerifyPhoneModel(user);
          }
        });
  }

  createAttrForm(): FormGroup {
    return this._formBuilder.group({
      id            : [ this.user.id ],
      phone         : [ this.user.phone, Validators.required],
      verifyCode    : [ this.user.verifyCode, Validators.required]
    });
  }

  verifyPhone(): void {
    const newAttr = this.verifyForm.value;
    const phoneNumber = parsePhoneNumberFromString(newAttr.phone);
    if (!phoneNumber || !phoneNumber.isValid()) {
      this.eventMesssage('A Valid Phone Number is Required!', 'error');
      return;
    }
    this.onloadShow = true;
    this.notRequested = false;
    this._profileService.sendCodeForVerification(newAttr)
        .then((results: any) => {
          this.onloadShow = false;
          this.notRequested = true;
          this._changeDetectorRef.detectChanges();
        }).catch(error => {
          this.onloadShow = false;
          this._changeDetectorRef.detectChanges();
          this.eventMesssage('Something went wrong please try again! Or Ask for Support', 'error');
    });

  }

  verifyCode(): void {
    const newAttr = this.verifyForm.value;
    const code = newAttr.verifyCode;
    if (!code) {
      this.eventMesssage('Valid Code needed!', 'error');
      return;
    }
    if (!this.user.isCodeVerified(code)) {
      this.eventMesssage('InValid Code.. Type the correct code sended by SMS !', 'error');
      return;
    }
    this.onloadShow = true;
    this._profileService.codeForVerification(newAttr)
        .then((results: any) => {
          this.onloadShow = false;
          this.notRequested = true;
          this.verifyForm.reset();
          this._changeDetectorRef.detectChanges();
          this._bottomSheetRef.dismiss({type: 'success', message: 'Phone Succesfully Verified!!'} );
        }).catch(error => {
          this.onloadShow = false;
          this.eventMesssage('Something went wrong please try again! Or Ask for Support', 'error');
        this._changeDetectorRef.detectChanges();
    });
  }

  reset(): void {
    this.verifyForm.reset();
    this.notRequested = false;
  }

  cancel() {
    this._bottomSheetRef.dismiss({type: 'cancel', message: 'cancel'} );
  }

  private eventMesssage( msg: string, typeMsg: string ) {
    const customMsg = typeMsg === 'error' ? 'OOPS:' + msg : 'Success!!' + msg;
    this._snackBar.open(customMsg, 'OK', {
      verticalPosition: 'top',
      duration        : 2000
    });
  }
}
