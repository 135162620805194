import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {UsersService} from '../users.service';
import {MatBottomSheet} from '@angular/material';
import {FormGroup} from '@angular/forms';
import {NotifyDialogComponent} from '../notify-dialog/notify-dialog.component';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';

@Component({
    selector   : 'selected-bar',
    templateUrl: './selected-bar.component.html',
    styleUrls  : ['./selected-bar.component.scss']
})
export class UsersSelectedBarComponent implements OnInit, OnDestroy {
    hasSelectedUsers: boolean;
    isIndeterminate: boolean;
    selectedContacts: string[];
    dialogRef: any;
    horizontalPosition: MatSnackBarHorizontalPosition = 'left';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param _usersService
     * @param {MatDialog} _matDialog
     * @param _bottomSheet
     * @param _snackBar
     */
    constructor(
        private _usersService: UsersService,
        public _matDialog: MatDialog,
        private _bottomSheet: MatBottomSheet,
        private _snackBar: MatSnackBar
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this._usersService.onSelectedUsersChanged$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(selectedContacts => {
                this.selectedContacts = selectedContacts;
                setTimeout(() => {
                    this.hasSelectedUsers = selectedContacts.length > 0;
                    this.isIndeterminate = (selectedContacts.length !== this._usersService.users.length && selectedContacts.length > 0);
                }, 0);
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Select all
     */
    selectAll(): void
    {
        this._usersService.selectUsers();
    }

    /**
     * Deselect all
     */
    deselectAll(): void
    {
        this._usersService.deselectUsers();
    }

    /**
     * Delete selected contacts
     */
    notifySelected(): void {
      this.dialogRef = this._bottomSheet.open(NotifyDialogComponent, {
        data: { panelClass: 'notification-compose-dialog' },
      });
      this.dialogRef.afterDismissed()
        .subscribe(response => {
          if ( !response ) {
            return;
          }
          const actionType: string = response[0];
          const formData: FormGroup = response[1].value;
          this._usersService.sendNotifications(formData).then((res) => {
              this.popMsg(`The Notitifactions has ben Sent! to ${this._usersService.selectedUsers.length} users`);
          }).catch(err => {
              const msgShow = err && (err.Message || err.message) ? err.message
                :  'OOPs!! Something went Wrong.. Try Again or Call for Support';
              this.popMsg(msgShow);
          });
        });
    }

    private popMsg(msgIn: string) {
      this._snackBar.open(msgIn, 'End now', {
        duration: 2000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }
}
