import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'msc-courses-card',
  templateUrl: './msc-courses-card.component.html',
  styleUrls: ['./msc-courses-card.component.scss']
})
export class MscCoursesCardComponent implements OnInit {

  @Input()
  course;

  constructor() { }

  ngOnInit() {
  }

  openCourseDetail(){
    const first = location.pathname.split('/')[1];
    if(first === 'horizontal'){
      // this.router.navigate(['/horizontal/courses/course-detail']);
    }else {
      // this.router.navigate(['/courses/course-detail']);
    }
  }

  onVideoPlayer(){
    // this.coreService.videoPlayerDialog(this.course.demoVideoUrl);
  }
}
