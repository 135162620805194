import {ModuleWithProviders, NgModule} from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { FuseSharedModule } from '@fuse/shared.module';
import { ProfileService } from 'app/main/profile/profile.service';
import { ProfileComponent } from 'app/main/profile/profile.component';
import { PreferencesComponent } from './tabs/preferences/preferences.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSelectModule} from '@angular/material/select';
import {MatSelectSearchModule} from '../../core/mat-select-search/mat-select-search.module';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatCardModule} from '@angular/material/card';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {AvatarModule} from 'ngx-avatar';
import {PasswordStrengthModule} from '../auth/components/password-strength/password-strength.module';
import {ContentLoaderModule} from '../../core/loaders/content-loader.module';
import {AuthGuard} from '../auth/auth.guard';
import {AmplifyAngularModule} from 'aws-amplify-angular';
import { UploadAvatarComponent } from './components/upload-avatar/upload-avatar.component';
import {ImgCropModule} from '../../../core/img-crop/img-crop.module';
import {PROFILE_MANAGER_OPTIONS, ProfileOptions} from './profile.options';
import { NgxMaskModule } from 'ngx-mask';
import {PhoneMaskDirective} from './components/profile-phone-validation/phone-mask.directive';
import {FuseSidebarModule} from '../../../@fuse/components';
import { ProfileHeaderComponent } from './components/profile-header/profile-header.component';
import { ProfileChgPasswordComponent } from './components/profile-chg-password/profile-chg-password.component';
import { ProfilePhoneValidationComponent } from './components/profile-phone-validation/profile-phone-validation.component';
import {ProfileProgressComponent} from "./tabs/profile-progress/profile-progress.component";
import {ProgressCellComponent} from "./components/progress-cell/progress-cell.component";
import {StatusCellComponent} from "./components/status-cell/status-cell.component";
import {MatTableModule} from "@angular/material/table";
const avatarColors = ['#FFB6C1', '#2c3e50', '#95a5a6', '#f39c12', '#1abc9c'];

const routes = [
    {
        path     : 'me',
        component: ProfileComponent,
        resolve  : {
            profile: ProfileService
        },
        canActivate: [AuthGuard]
    }
];

@NgModule({
    declarations: [
        ProfileComponent,
        PreferencesComponent,
        UploadAvatarComponent,
        PhoneMaskDirective,
        ProfileHeaderComponent,
        ProfileChgPasswordComponent,
        ProfilePhoneValidationComponent,
        ProfileProgressComponent,
        ProgressCellComponent,
        StatusCellComponent,
    ],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        MatDatepickerModule,
        MatDividerModule,
        MatIconModule,
        MatTabsModule,
        MatToolbarModule,
        MatProgressBarModule,
        MatAutocompleteModule,
        MatSlideToggleModule,
        MatSelectModule,
        FuseSharedModule,
        MatSelectSearchModule,
        AvatarModule.forRoot({
            colors: avatarColors
        }),
        PasswordStrengthModule.forRoot(),
        ContentLoaderModule,
        AmplifyAngularModule,
        ImgCropModule,
        NgxMaskModule.forRoot(),
        FuseSidebarModule,
        MatTableModule,
    ],
    entryComponents: [UploadAvatarComponent,
        ProfileChgPasswordComponent,
        ProfilePhoneValidationComponent]
})
export class ProfileModule {
  static forRoot(profileOptions?: ProfileOptions): ModuleWithProviders {
    return {
      ngModule: ProfileModule,
      providers: [
        { provide: PROFILE_MANAGER_OPTIONS, useValue: profileOptions },
        ProfileService
      ],
    } as ModuleWithProviders;
  }
}
