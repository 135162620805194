import {ModuleWithProviders, NgModule} from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseConfirmDialogModule, FuseSidebarModule } from '@fuse/components';

import {ContactComponent} from './contact/contact.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatSelectSearchModule} from '../../../../core/mat-select-search/mat-select-search.module';
import {MatSelectModule} from '@angular/material/select';
import {ImgCropModule} from '../../../../core/img-crop/img-crop.module';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatListModule} from '@angular/material/list';
import {NgSelectModule} from '@ng-select/ng-select';
import {ContactService} from './contact.service';
import {UploadFileModule} from '../../../../core/upload-file/upload-file.module';
import {AvatarModule} from 'ngx-avatar';
import {ContactsService} from "./contacts.service";
import {ContactsComponent} from "./contacts.component";
import {CoursesModule} from "../courses/courses.module";
import {ContactsSelectedBarComponent} from "./selected-bar/selected-bar.component";
import {ContactsMainSidebarComponent} from "./sidebars/main/main.component";
import {AuthGuard} from "../../auth/auth.guard";
import {ContactsContactListComponent} from "./contact-list/contact-list.component";
import { OrganizationComponent } from './organization/organization.component';
import {CompanyService} from "./company.service";
import { ConnectListComponent } from './components/connect-list/connect-list.component';
import {ConnectsService} from "./connects.service";
import { ConnectPopupComponent } from './components/connect-popup/connect-popup.component';
import {MatCardModule} from "@angular/material/card";
import {CONTACT_MANAGER_OPTIONS, ContactOptions} from "./contact.options";
import { LicensePopupComponent } from './components/license-popup/license-popup.component';
import { OrgLicensesListComponent } from './components/org-licenses-list/org-licenses-list.component';
import {LicenseService} from "./license.service";
import {MatFileUploadModule} from "mat-file-upload";
import {MatPaginatorModule} from "@angular/material/paginator";
import { AddContactAsUserPopupComponent } from './components/add-contact-as-user-popup/add-contact-as-user-popup.component';
import {MatStepperModule} from "@angular/material/stepper";

const routes: Routes = [
    {
        path     : 'contacts',
        component: ContactsComponent,
        resolve  : {
            contacts: ContactsService
        },
        canActivate: [AuthGuard]
    },
    {
        path     : 'contacts/:id',
        component: ContactComponent,
        resolve  : {
            data: ContactService
        },
        canActivate: [AuthGuard]
    },
    {
        path     : 'company/:id',
        component: OrganizationComponent,
        resolve  : {
            data: CompanyService
        },
        canActivate: [AuthGuard]
    }

];

@NgModule({
    declarations   : [
        ContactsComponent,
        ContactsContactListComponent,
        ContactsSelectedBarComponent,
        ContactsMainSidebarComponent,
        ContactComponent,
        OrganizationComponent,
        ConnectListComponent,
        ConnectPopupComponent,
        LicensePopupComponent,
        OrgLicensesListComponent,
        AddContactAsUserPopupComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatTableModule,
        MatToolbarModule,

        FuseSharedModule,
        FuseConfirmDialogModule,
        FuseSidebarModule,
        MatTabsModule,
        MatGridListModule,
        MatSelectSearchModule,
        MatSelectModule,
        ImgCropModule,
        MatProgressBarModule,
        CoursesModule,
        MatListModule,
        NgSelectModule,
        UploadFileModule,
        AvatarModule,
        MatCardModule,
        MatFileUploadModule,
        MatPaginatorModule,
        MatStepperModule
    ],
    entryComponents: [ConnectPopupComponent,
        LicensePopupComponent,
        AddContactAsUserPopupComponent]
})
export class ContactsModule
{
    static forRoot(fmOptions?: ContactOptions): ModuleWithProviders {
        return {
            ngModule: ContactsModule,
            providers: [
                { provide: CONTACT_MANAGER_OPTIONS, useValue:  fmOptions},
                ContactsService,
                ContactService,
                CompanyService,
                ConnectsService,
                LicenseService
            ],
        } as ModuleWithProviders;
    }
}

