import {
	Component,
	OnInit,
	OnDestroy,
	ViewEncapsulation,
	Inject,
	ChangeDetectorRef,
	ChangeDetectionStrategy
} from '@angular/core';
import { MatDialogRef } from '@angular/material';
import {fuseAnimations} from "../../../../../@fuse/animations";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
	selector: 'ms-video-player',
	templateUrl: './msc-video-player.component.html',
	styleUrls: ['./msc-video-player.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations   : fuseAnimations
})
export class MscVideoPlayerComponent implements OnInit, OnDestroy {

	animationDirection: 'left' | 'right' | 'none';
	loadingProgress: boolean;
	videoOptions: any;

	constructor(
		public matDialogRef: MatDialogRef<MscVideoPlayerComponent>,
		private ref: ChangeDetectorRef,
		@Inject(MAT_DIALOG_DATA) private _asset: any,
	) {
		this.animationDirection = 'none';
		this.loadingProgress = true;
	}

	ngOnInit(): void {
		this.videoOptions = {
			autoplay: false,
			controls: true,
			liveui: true,
			height: 240,
			width: 340,
			playbackRates: [0.5, 1, 1.5, 2],
			sources: [{
				src: this._asset.presignUrl,
				type: this._asset.fileType
			}]
		};
		this.loadingProgress = false;
		this.ref.detectChanges();
	}

	ngOnDestroy(){

	}

	videoProgress($event) : void {
		if ($event.event === 'ready' ) {
			this.animationDirection = 'left';
			this.loadingProgress = false;
			this.ref.detectChanges();
		}
	}
}
