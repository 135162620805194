import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {FuseProgressBarService} from "../../../../../../../@fuse/components/progress-bar/progress-bar.service";
import {QuizViewerService} from "../../../../quiz-viewer.service";
@Component({
  selector: 'quiz-question-item',
  templateUrl: './quiz-question-item.component.html',
  styleUrls: ['./quiz-question-item.component.scss']
})
export class QuizQuestionItemComponent implements OnChanges{

  @Input('data') question: any;

  @Output() answer = new EventEmitter();  // TODO no emitted
  @Output() nextQuestion = new EventEmitter();

  optionsSelected: any[] = [];
  /**
   * Constructor
   */
  constructor(
      private _quizService: QuizViewerService,
      private _fuseProgressBarService: FuseProgressBarService,
  ){}

  ngOnChanges(changes: SimpleChanges) {

    if (changes.question.currentValue) {
      this.question = changes.question.currentValue;
      this._fuseProgressBarService.show();
      this._quizService.getQuestionAnswers(this.question.id)
          .then( res => {
            this.question.questionAnswers.items = res.items;
            console.log(this.question.questionAnswers.items);
            this._fuseProgressBarService.hide();
          })
    }
  }

  onCheckboxChange(e, item: any) {
    // console.log("item --> ", item);
    if(e.checked){
      this.optionsSelected.push(item);
    }else{
      this.optionsSelected = this.optionsSelected.filter(value => value.answer != item.answer);
    }
  }

  onRadioButtonChange(item: any) {
    this.optionsSelected = [];
    if(this.question.qType === "TrueFalse"){
      const itemTemp = this.question.questionAnswers.items[0];
      itemTemp.answer = item?'1':'2';
      this.optionsSelected.push(itemTemp);
    }else{
      // console.log("item --> ", item);
      this.optionsSelected = [];
      this.optionsSelected.push(item);
    }
  }

  getNextQuestion(){
    this.nextQuestion.emit(this.optionsSelected);
    this.optionsSelected = [];
  }

}
