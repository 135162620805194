/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api/lib/types";
import * as Observable from "zen-observable";

export type CreateSessionInput = {
  id?: string | null;
  userId: string;
  location?: string | null;
  event?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  _version?: number | null;
};

export type ModelSessionConditionInput = {
  userId?: ModelIDInput | null;
  location?: ModelStringInput | null;
  event?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSessionConditionInput | null> | null;
  or?: Array<ModelSessionConditionInput | null> | null;
  not?: ModelSessionConditionInput | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type UpdateSessionInput = {
  id: string;
  userId?: string | null;
  location?: string | null;
  event?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  _version?: number | null;
};

export type DeleteSessionInput = {
  id?: string | null;
  _version?: number | null;
};

export type CreateUserSettingsInput = {
  id?: string | null;
  userId: string;
  pageSize?: number | null;
  notiType?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  _version?: number | null;
};

export type ModelUserSettingsConditionInput = {
  userId?: ModelIDInput | null;
  pageSize?: ModelIntInput | null;
  notiType?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelUserSettingsConditionInput | null> | null;
  or?: Array<ModelUserSettingsConditionInput | null> | null;
  not?: ModelUserSettingsConditionInput | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type UpdateUserSettingsInput = {
  id: string;
  userId?: string | null;
  pageSize?: number | null;
  notiType?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  _version?: number | null;
};

export type DeleteUserSettingsInput = {
  id?: string | null;
  _version?: number | null;
};

export type CreateNotificationInput = {
  id?: string | null;
  typeNotify?: string | null;
  userId?: string | null;
  subject?: string | null;
  body?: string | null;
  localUrl?: string | null;
  renotify?: boolean | null;
  silent?: boolean | null;
  dateFor?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  _version?: number | null;
};

export type ModelNotificationConditionInput = {
  typeNotify?: ModelStringInput | null;
  userId?: ModelStringInput | null;
  subject?: ModelStringInput | null;
  body?: ModelStringInput | null;
  localUrl?: ModelStringInput | null;
  renotify?: ModelBooleanInput | null;
  silent?: ModelBooleanInput | null;
  dateFor?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelNotificationConditionInput | null> | null;
  or?: Array<ModelNotificationConditionInput | null> | null;
  not?: ModelNotificationConditionInput | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type UpdateNotificationInput = {
  id: string;
  typeNotify?: string | null;
  userId?: string | null;
  subject?: string | null;
  body?: string | null;
  localUrl?: string | null;
  renotify?: boolean | null;
  silent?: boolean | null;
  dateFor?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  _version?: number | null;
};

export type DeleteNotificationInput = {
  id?: string | null;
  _version?: number | null;
};

export type CreateUserPrivacyInput = {
  id?: string | null;
  userId: string;
  profileShared: string;
  bgPhoto?: string | null;
  headLine?: string | null;
  secondHeadLine?: string | null;
  titleLine?: string | null;
  bioLine?: string | null;
  showEmail?: boolean | null;
  showPhone?: boolean | null;
  showEmailOn?: string | null;
  contactDirectlyByEmail?: boolean | null;
  showConnectionsTo?: string | null;
  oaConnInvitationAccepted?: boolean | null;
  oaEventUpdates?: boolean | null;
  oaUpdatesFromConnections?: boolean | null;
  oaBreakingNews?: boolean | null;
  oaTopsPicks?: boolean | null;
  oaWhoViewedYourProfile?: boolean | null;
  oaYourNewFollowers?: boolean | null;
  emailEventsInvitations?: boolean | null;
  emailEventsUpdates?: boolean | null;
  emailNewsInvitations?: boolean | null;
  emailNewsSubscriptions?: boolean | null;
  whoRequestForNotifications?: boolean | null;
  whoInvitationsToAttend?: boolean | null;
  locMap?: string | null;
  location?: string | null;
  locTitle?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  _version?: number | null;
};

export type ModelUserPrivacyConditionInput = {
  userId?: ModelIDInput | null;
  profileShared?: ModelStringInput | null;
  bgPhoto?: ModelStringInput | null;
  headLine?: ModelStringInput | null;
  secondHeadLine?: ModelStringInput | null;
  titleLine?: ModelStringInput | null;
  bioLine?: ModelStringInput | null;
  showEmail?: ModelBooleanInput | null;
  showPhone?: ModelBooleanInput | null;
  showEmailOn?: ModelStringInput | null;
  contactDirectlyByEmail?: ModelBooleanInput | null;
  showConnectionsTo?: ModelStringInput | null;
  oaConnInvitationAccepted?: ModelBooleanInput | null;
  oaEventUpdates?: ModelBooleanInput | null;
  oaUpdatesFromConnections?: ModelBooleanInput | null;
  oaBreakingNews?: ModelBooleanInput | null;
  oaTopsPicks?: ModelBooleanInput | null;
  oaWhoViewedYourProfile?: ModelBooleanInput | null;
  oaYourNewFollowers?: ModelBooleanInput | null;
  emailEventsInvitations?: ModelBooleanInput | null;
  emailEventsUpdates?: ModelBooleanInput | null;
  emailNewsInvitations?: ModelBooleanInput | null;
  emailNewsSubscriptions?: ModelBooleanInput | null;
  whoRequestForNotifications?: ModelBooleanInput | null;
  whoInvitationsToAttend?: ModelBooleanInput | null;
  locMap?: ModelStringInput | null;
  location?: ModelStringInput | null;
  locTitle?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelUserPrivacyConditionInput | null> | null;
  or?: Array<ModelUserPrivacyConditionInput | null> | null;
  not?: ModelUserPrivacyConditionInput | null;
};

export type UpdateUserPrivacyInput = {
  id: string;
  userId?: string | null;
  profileShared?: string | null;
  bgPhoto?: string | null;
  headLine?: string | null;
  secondHeadLine?: string | null;
  titleLine?: string | null;
  bioLine?: string | null;
  showEmail?: boolean | null;
  showPhone?: boolean | null;
  showEmailOn?: string | null;
  contactDirectlyByEmail?: boolean | null;
  showConnectionsTo?: string | null;
  oaConnInvitationAccepted?: boolean | null;
  oaEventUpdates?: boolean | null;
  oaUpdatesFromConnections?: boolean | null;
  oaBreakingNews?: boolean | null;
  oaTopsPicks?: boolean | null;
  oaWhoViewedYourProfile?: boolean | null;
  oaYourNewFollowers?: boolean | null;
  emailEventsInvitations?: boolean | null;
  emailEventsUpdates?: boolean | null;
  emailNewsInvitations?: boolean | null;
  emailNewsSubscriptions?: boolean | null;
  whoRequestForNotifications?: boolean | null;
  whoInvitationsToAttend?: boolean | null;
  locMap?: string | null;
  location?: string | null;
  locTitle?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  _version?: number | null;
};

export type DeleteUserPrivacyInput = {
  id?: string | null;
  _version?: number | null;
};

export type CreateCourseCategoryInput = {
  id?: string | null;
  category: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  status?: elStatus | null;
  _version?: number | null;
};

export enum elStatus {
  active = "active",
  inactive = "inactive",
  new = "new"
}

export type ModelCourseCategoryConditionInput = {
  category?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  status?: ModelelStatusInput | null;
  and?: Array<ModelCourseCategoryConditionInput | null> | null;
  or?: Array<ModelCourseCategoryConditionInput | null> | null;
  not?: ModelCourseCategoryConditionInput | null;
};

export type ModelelStatusInput = {
  eq?: elStatus | null;
  ne?: elStatus | null;
};

export enum CourseType {
  External = "External",
  Internal = "Internal"
}

export enum elCourseTrend {
  Top = "Top",
  Trending = "Trending",
  New = "New"
}

export type UpdateCourseCategoryInput = {
  id: string;
  category?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  status?: elStatus | null;
  _version?: number | null;
};

export type DeleteCourseCategoryInput = {
  id?: string | null;
  _version?: number | null;
};

export type CreateCourseResourceInput = {
  id?: string | null;
  courseId: string;
  description: string;
  contentType: ResourceType;
  sourceCode?: string | null;
  fileName?: string | null;
  fileType?: string | null;
  fileSize?: string | null;
  status: elStatus;
  updatedAt?: string | null;
  createdAt?: string | null;
  _version?: number | null;
};

export enum ResourceType {
  File = "File",
  Link = "Link",
  Iframe = "Iframe"
}

export type ModelCourseResourceConditionInput = {
  courseId?: ModelStringInput | null;
  description?: ModelStringInput | null;
  contentType?: ModelResourceTypeInput | null;
  sourceCode?: ModelStringInput | null;
  fileName?: ModelStringInput | null;
  fileType?: ModelStringInput | null;
  fileSize?: ModelStringInput | null;
  status?: ModelelStatusInput | null;
  updatedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelCourseResourceConditionInput | null> | null;
  or?: Array<ModelCourseResourceConditionInput | null> | null;
  not?: ModelCourseResourceConditionInput | null;
};

export type ModelResourceTypeInput = {
  eq?: ResourceType | null;
  ne?: ResourceType | null;
};

export type UpdateCourseResourceInput = {
  id: string;
  courseId?: string | null;
  description?: string | null;
  contentType?: ResourceType | null;
  sourceCode?: string | null;
  fileName?: string | null;
  fileType?: string | null;
  fileSize?: string | null;
  status?: elStatus | null;
  updatedAt?: string | null;
  createdAt?: string | null;
  _version?: number | null;
};

export type DeleteCourseResourceInput = {
  id?: string | null;
  _version?: number | null;
};

export type CreateContentInput = {
  id?: string | null;
  courseId: string;
  contentType: ContentType;
  media?: string | null;
  sourceMedia?: string | null;
  fileName?: string | null;
  fileType?: string | null;
  fileSize?: string | null;
  transcoderMeta?: string | null;
  transcoderJobId?: string | null;
  updatedAt?: string | null;
  createdAt?: string | null;
  _version?: number | null;
};

export enum ContentType {
  Video = "Video",
  File = "File"
}

export type ModelContentConditionInput = {
  courseId?: ModelIDInput | null;
  contentType?: ModelContentTypeInput | null;
  media?: ModelStringInput | null;
  sourceMedia?: ModelStringInput | null;
  fileName?: ModelStringInput | null;
  fileType?: ModelStringInput | null;
  fileSize?: ModelStringInput | null;
  transcoderMeta?: ModelStringInput | null;
  transcoderJobId?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelContentConditionInput | null> | null;
  or?: Array<ModelContentConditionInput | null> | null;
  not?: ModelContentConditionInput | null;
};

export type ModelContentTypeInput = {
  eq?: ContentType | null;
  ne?: ContentType | null;
};

export type UpdateContentInput = {
  id: string;
  courseId?: string | null;
  contentType?: ContentType | null;
  media?: string | null;
  sourceMedia?: string | null;
  fileName?: string | null;
  fileType?: string | null;
  fileSize?: string | null;
  transcoderMeta?: string | null;
  transcoderJobId?: string | null;
  updatedAt?: string | null;
  createdAt?: string | null;
  _version?: number | null;
};

export type DeleteContentInput = {
  id?: string | null;
  _version?: number | null;
};

export type CreateContentResourceInput = {
  id?: string | null;
  courseId: string;
  description: string;
  contentType: ResourceType;
  sourceCode?: string | null;
  fileName?: string | null;
  fileType?: string | null;
  fileSize?: string | null;
  status: elStatus;
  updatedAt?: string | null;
  createdAt?: string | null;
  _version?: number | null;
};

export type ModelContentResourceConditionInput = {
  courseId?: ModelStringInput | null;
  description?: ModelStringInput | null;
  contentType?: ModelResourceTypeInput | null;
  sourceCode?: ModelStringInput | null;
  fileName?: ModelStringInput | null;
  fileType?: ModelStringInput | null;
  fileSize?: ModelStringInput | null;
  status?: ModelelStatusInput | null;
  updatedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelContentResourceConditionInput | null> | null;
  or?: Array<ModelContentResourceConditionInput | null> | null;
  not?: ModelContentResourceConditionInput | null;
};

export type UpdateContentResourceInput = {
  id: string;
  courseId?: string | null;
  description?: string | null;
  contentType?: ResourceType | null;
  sourceCode?: string | null;
  fileName?: string | null;
  fileType?: string | null;
  fileSize?: string | null;
  status?: elStatus | null;
  updatedAt?: string | null;
  createdAt?: string | null;
  _version?: number | null;
};

export type DeleteContentResourceInput = {
  id?: string | null;
  _version?: number | null;
};

export type CreateCourseInput = {
  id?: string | null;
  title: string;
  description: string;
  category: CourseCategoryInput;
  categoryId: string;
  coverImage?: string | null;
  timeAprox: string;
  status: elStatus;
  coverImage1?: string | null;
  coverImage2?: string | null;
  coverImage3?: string | null;
  courseType?: CourseType | null;
  courseUrl?: string | null;
  overviewSourceMedia?: string | null;
  overviewFileName?: string | null;
  overviewFileType?: string | null;
  overviewFileSize?: string | null;
  transcoderMeta?: string | null;
  transcoderJobId?: string | null;
  courseTrend?: elCourseTrend | null;
  learnDescription?: string | null;
  requirements?: string | null;
  level?: string | null;
  tags?: string | null;
  trending?: string | null;
  updatedAt?: string | null;
  createdAt?: string | null;
  _version?: number | null;
  courseCategoryId: string;
};

export type CourseCategoryInput = {
  id: string;
  category: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  status?: elStatus | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ModelCourseConditionInput = {
  title?: ModelStringInput | null;
  description?: ModelStringInput | null;
  categoryId?: ModelIDInput | null;
  coverImage?: ModelStringInput | null;
  timeAprox?: ModelStringInput | null;
  status?: ModelelStatusInput | null;
  coverImage1?: ModelStringInput | null;
  coverImage2?: ModelStringInput | null;
  coverImage3?: ModelStringInput | null;
  courseType?: ModelCourseTypeInput | null;
  courseUrl?: ModelStringInput | null;
  overviewSourceMedia?: ModelStringInput | null;
  overviewFileName?: ModelStringInput | null;
  overviewFileType?: ModelStringInput | null;
  overviewFileSize?: ModelStringInput | null;
  transcoderMeta?: ModelStringInput | null;
  transcoderJobId?: ModelStringInput | null;
  courseTrend?: ModelelCourseTrendInput | null;
  learnDescription?: ModelStringInput | null;
  requirements?: ModelStringInput | null;
  level?: ModelStringInput | null;
  tags?: ModelStringInput | null;
  trending?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelCourseConditionInput | null> | null;
  or?: Array<ModelCourseConditionInput | null> | null;
  not?: ModelCourseConditionInput | null;
};

export type ModelCourseTypeInput = {
  eq?: CourseType | null;
  ne?: CourseType | null;
};

export type ModelelCourseTrendInput = {
  eq?: elCourseTrend | null;
  ne?: elCourseTrend | null;
};

export enum elAssetType {
  Topic = "Topic",
  Quiz = "Quiz"
}

export type UpdateCourseInput = {
  id: string;
  title?: string | null;
  description?: string | null;
  category?: CourseCategoryInput | null;
  categoryId?: string | null;
  coverImage?: string | null;
  timeAprox?: string | null;
  status?: elStatus | null;
  coverImage1?: string | null;
  coverImage2?: string | null;
  coverImage3?: string | null;
  courseType?: CourseType | null;
  courseUrl?: string | null;
  overviewSourceMedia?: string | null;
  overviewFileName?: string | null;
  overviewFileType?: string | null;
  overviewFileSize?: string | null;
  transcoderMeta?: string | null;
  transcoderJobId?: string | null;
  courseTrend?: elCourseTrend | null;
  learnDescription?: string | null;
  requirements?: string | null;
  level?: string | null;
  tags?: string | null;
  trending?: string | null;
  updatedAt?: string | null;
  createdAt?: string | null;
  _version?: number | null;
  courseCategoryId?: string | null;
};

export type DeleteCourseInput = {
  id?: string | null;
  _version?: number | null;
};

export type CreateContentSubsInput = {
  id?: string | null;
  contentId: string;
  userId: string;
  metaData?: string | null;
  status: elSubStatus;
  updatedAt?: string | null;
  createdAt?: string | null;
  _version?: number | null;
};

export enum elSubStatus {
  Enrolled = "Enrolled",
  Completed = "Completed",
  Progress = "Progress"
}

export type ModelContentSubsConditionInput = {
  contentId?: ModelIDInput | null;
  userId?: ModelIDInput | null;
  metaData?: ModelStringInput | null;
  status?: ModelelSubStatusInput | null;
  updatedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelContentSubsConditionInput | null> | null;
  or?: Array<ModelContentSubsConditionInput | null> | null;
  not?: ModelContentSubsConditionInput | null;
};

export type ModelelSubStatusInput = {
  eq?: elSubStatus | null;
  ne?: elSubStatus | null;
};

export type UpdateContentSubsInput = {
  id: string;
  contentId?: string | null;
  userId?: string | null;
  metaData?: string | null;
  status?: elSubStatus | null;
  updatedAt?: string | null;
  createdAt?: string | null;
  _version?: number | null;
};

export type DeleteContentSubsInput = {
  id?: string | null;
  _version?: number | null;
};

export type CreateCourseSubsInput = {
  id?: string | null;
  courseId: string;
  userId: string;
  title?: string | null;
  description?: string | null;
  category?: string | null;
  level?: string | null;
  tags?: string | null;
  metaData?: string | null;
  status: elSubStatus;
  course?: CourseInput | null;
  updatedAt?: string | null;
  createdAt?: string | null;
  _version?: number | null;
};

export type CourseInput = {
  id: string;
  title: string;
  description: string;
  category: CourseCategoryInput;
  categoryId: string;
  coverImage?: string | null;
  timeAprox: string;
  status: elStatus;
  coverImage1?: string | null;
  coverImage2?: string | null;
  coverImage3?: string | null;
  courseType?: CourseType | null;
  courseUrl?: string | null;
  overviewSourceMedia?: string | null;
  overviewFileName?: string | null;
  overviewFileType?: string | null;
  overviewFileSize?: string | null;
  transcoderMeta?: string | null;
  transcoderJobId?: string | null;
  courseTrend?: elCourseTrend | null;
  learnDescription?: string | null;
  requirements?: string | null;
  level?: string | null;
  tags?: string | null;
  trending?: string | null;
  updatedAt?: string | null;
  createdAt?: string | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ModelCourseSubsConditionInput = {
  courseId?: ModelIDInput | null;
  userId?: ModelIDInput | null;
  title?: ModelStringInput | null;
  description?: ModelStringInput | null;
  category?: ModelStringInput | null;
  level?: ModelStringInput | null;
  tags?: ModelStringInput | null;
  metaData?: ModelStringInput | null;
  status?: ModelelSubStatusInput | null;
  updatedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelCourseSubsConditionInput | null> | null;
  or?: Array<ModelCourseSubsConditionInput | null> | null;
  not?: ModelCourseSubsConditionInput | null;
};

export type UpdateCourseSubsInput = {
  id: string;
  courseId?: string | null;
  userId?: string | null;
  title?: string | null;
  description?: string | null;
  category?: string | null;
  level?: string | null;
  tags?: string | null;
  metaData?: string | null;
  status?: elSubStatus | null;
  course?: CourseInput | null;
  updatedAt?: string | null;
  createdAt?: string | null;
  _version?: number | null;
};

export type DeleteCourseSubsInput = {
  id?: string | null;
  _version?: number | null;
};

export type CreateCourseAssetsInput = {
  id?: string | null;
  courseId: string;
  assetId?: string | null;
  assetType: elAssetType;
  title: string;
  description: string;
  order?: number | null;
  timeAprox?: string | null;
  starred?: boolean | null;
  important?: boolean | null;
  showAsOverview?: boolean | null;
  showAsFree?: boolean | null;
  nextStep?: string | null;
  prevStep?: string | null;
  status: elStatus;
  updatedAt?: string | null;
  createdAt?: string | null;
  course?: CourseInput | null;
  topic?: ContentInput | null;
  _version?: number | null;
  courseAssetsCourseId?: string | null;
  courseAssetsTopicId?: string | null;
  courseAssetsQuizId?: string | null;
};

export type ContentInput = {
  id: string;
  courseId: string;
  contentType: ContentType;
  media?: string | null;
  sourceMedia?: string | null;
  fileName?: string | null;
  fileType?: string | null;
  fileSize?: string | null;
  transcoderMeta?: string | null;
  transcoderJobId?: string | null;
  updatedAt?: string | null;
  createdAt?: string | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ModelCourseAssetsConditionInput = {
  courseId?: ModelIDInput | null;
  assetId?: ModelIDInput | null;
  assetType?: ModelelAssetTypeInput | null;
  title?: ModelStringInput | null;
  description?: ModelStringInput | null;
  order?: ModelIntInput | null;
  timeAprox?: ModelStringInput | null;
  starred?: ModelBooleanInput | null;
  important?: ModelBooleanInput | null;
  showAsOverview?: ModelBooleanInput | null;
  showAsFree?: ModelBooleanInput | null;
  nextStep?: ModelStringInput | null;
  prevStep?: ModelStringInput | null;
  status?: ModelelStatusInput | null;
  updatedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelCourseAssetsConditionInput | null> | null;
  or?: Array<ModelCourseAssetsConditionInput | null> | null;
  not?: ModelCourseAssetsConditionInput | null;
};

export type ModelelAssetTypeInput = {
  eq?: elAssetType | null;
  ne?: elAssetType | null;
};

export type UpdateCourseAssetsInput = {
  id: string;
  courseId?: string | null;
  assetId?: string | null;
  assetType?: elAssetType | null;
  title?: string | null;
  description?: string | null;
  order?: number | null;
  timeAprox?: string | null;
  starred?: boolean | null;
  important?: boolean | null;
  showAsOverview?: boolean | null;
  showAsFree?: boolean | null;
  nextStep?: string | null;
  prevStep?: string | null;
  status?: elStatus | null;
  updatedAt?: string | null;
  createdAt?: string | null;
  course?: CourseInput | null;
  topic?: ContentInput | null;
  _version?: number | null;
  courseAssetsCourseId?: string | null;
  courseAssetsTopicId?: string | null;
  courseAssetsQuizId?: string | null;
};

export type DeleteCourseAssetsInput = {
  id?: string | null;
  _version?: number | null;
};

export type CreateQuizInput = {
  id?: string | null;
  courseId: string;
  questionsRandom?: number | null;
  passingScore?: number | null;
  noAttempts?: number | null;
  score?: number | null;
  labelWhenPassed?: string | null;
  labelWhenFailed?: string | null;
  questionFeedback?: boolean | null;
  updatedAt?: string | null;
  createdAt?: string | null;
  status?: elStatus | null;
  _version?: number | null;
};

export type ModelQuizConditionInput = {
  courseId?: ModelIDInput | null;
  questionsRandom?: ModelIntInput | null;
  passingScore?: ModelIntInput | null;
  noAttempts?: ModelIntInput | null;
  score?: ModelIntInput | null;
  labelWhenPassed?: ModelStringInput | null;
  labelWhenFailed?: ModelStringInput | null;
  questionFeedback?: ModelBooleanInput | null;
  updatedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  status?: ModelelStatusInput | null;
  and?: Array<ModelQuizConditionInput | null> | null;
  or?: Array<ModelQuizConditionInput | null> | null;
  not?: ModelQuizConditionInput | null;
};

export enum elQuestionType {
  TrueFalse = "TrueFalse",
  Multiple = "Multiple",
  Optional = "Optional"
}

export type UpdateQuizInput = {
  id: string;
  courseId?: string | null;
  questionsRandom?: number | null;
  passingScore?: number | null;
  noAttempts?: number | null;
  score?: number | null;
  labelWhenPassed?: string | null;
  labelWhenFailed?: string | null;
  questionFeedback?: boolean | null;
  updatedAt?: string | null;
  createdAt?: string | null;
  status?: elStatus | null;
  _version?: number | null;
};

export type DeleteQuizInput = {
  id?: string | null;
  _version?: number | null;
};

export type CreateQuizQuestionInput = {
  id?: string | null;
  quizContentId: string;
  quiz?: QuizInput | null;
  order?: number | null;
  title?: string | null;
  qType: elQuestionType;
  labelCorrectMessage?: string | null;
  labelIncorrectMessage?: string | null;
  questionFeedBack?: string | null;
  media?: string | null;
  sourceMedia?: string | null;
  fileName?: string | null;
  fileType?: string | null;
  fileSize?: string | null;
  updatedAt?: string | null;
  createdAt?: string | null;
  status?: elStatus | null;
  _version?: number | null;
  quizQuestionQuizId?: string | null;
};

export type QuizInput = {
  id: string;
  courseId: string;
  questionsRandom?: number | null;
  passingScore?: number | null;
  noAttempts?: number | null;
  score?: number | null;
  labelWhenPassed?: string | null;
  labelWhenFailed?: string | null;
  questionFeedback?: boolean | null;
  updatedAt?: string | null;
  createdAt?: string | null;
  status?: elStatus | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ModelQuizQuestionConditionInput = {
  quizContentId?: ModelIDInput | null;
  order?: ModelIntInput | null;
  title?: ModelStringInput | null;
  qType?: ModelelQuestionTypeInput | null;
  labelCorrectMessage?: ModelStringInput | null;
  labelIncorrectMessage?: ModelStringInput | null;
  questionFeedBack?: ModelStringInput | null;
  media?: ModelStringInput | null;
  sourceMedia?: ModelStringInput | null;
  fileName?: ModelStringInput | null;
  fileType?: ModelStringInput | null;
  fileSize?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  status?: ModelelStatusInput | null;
  and?: Array<ModelQuizQuestionConditionInput | null> | null;
  or?: Array<ModelQuizQuestionConditionInput | null> | null;
  not?: ModelQuizQuestionConditionInput | null;
};

export type ModelelQuestionTypeInput = {
  eq?: elQuestionType | null;
  ne?: elQuestionType | null;
};

export type UpdateQuizQuestionInput = {
  id: string;
  quizContentId?: string | null;
  quiz?: QuizInput | null;
  order?: number | null;
  title?: string | null;
  qType?: elQuestionType | null;
  labelCorrectMessage?: string | null;
  labelIncorrectMessage?: string | null;
  questionFeedBack?: string | null;
  media?: string | null;
  sourceMedia?: string | null;
  fileName?: string | null;
  fileType?: string | null;
  fileSize?: string | null;
  updatedAt?: string | null;
  createdAt?: string | null;
  status?: elStatus | null;
  _version?: number | null;
  quizQuestionQuizId?: string | null;
};

export type DeleteQuizQuestionInput = {
  id?: string | null;
  _version?: number | null;
};

export type CreateQuestionAnswersInput = {
  id?: string | null;
  quizQuestionId: string;
  question?: QuizQuestionInput | null;
  order?: number | null;
  isCorrect?: boolean | null;
  title?: string | null;
  answer?: string | null;
  answerFeedBack?: string | null;
  qType: elQuestionType;
  media?: string | null;
  sourceMedia?: string | null;
  fileName?: string | null;
  fileType?: string | null;
  fileSize?: string | null;
  status?: elStatus | null;
  updatedAt?: string | null;
  createdAt?: string | null;
  _version?: number | null;
  questionAnswersQuestionId?: string | null;
};

export type QuizQuestionInput = {
  id: string;
  quizContentId: string;
  quiz?: QuizInput | null;
  order?: number | null;
  title?: string | null;
  qType: elQuestionType;
  labelCorrectMessage?: string | null;
  labelIncorrectMessage?: string | null;
  questionFeedBack?: string | null;
  media?: string | null;
  sourceMedia?: string | null;
  fileName?: string | null;
  fileType?: string | null;
  fileSize?: string | null;
  updatedAt?: string | null;
  createdAt?: string | null;
  status?: elStatus | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ModelQuestionAnswersConditionInput = {
  quizQuestionId?: ModelIDInput | null;
  order?: ModelIntInput | null;
  isCorrect?: ModelBooleanInput | null;
  title?: ModelStringInput | null;
  answer?: ModelStringInput | null;
  answerFeedBack?: ModelStringInput | null;
  qType?: ModelelQuestionTypeInput | null;
  media?: ModelStringInput | null;
  sourceMedia?: ModelStringInput | null;
  fileName?: ModelStringInput | null;
  fileType?: ModelStringInput | null;
  fileSize?: ModelStringInput | null;
  status?: ModelelStatusInput | null;
  updatedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelQuestionAnswersConditionInput | null> | null;
  or?: Array<ModelQuestionAnswersConditionInput | null> | null;
  not?: ModelQuestionAnswersConditionInput | null;
};

export type UpdateQuestionAnswersInput = {
  id: string;
  quizQuestionId?: string | null;
  question?: QuizQuestionInput | null;
  order?: number | null;
  isCorrect?: boolean | null;
  title?: string | null;
  answer?: string | null;
  answerFeedBack?: string | null;
  qType?: elQuestionType | null;
  media?: string | null;
  sourceMedia?: string | null;
  fileName?: string | null;
  fileType?: string | null;
  fileSize?: string | null;
  status?: elStatus | null;
  updatedAt?: string | null;
  createdAt?: string | null;
  _version?: number | null;
  questionAnswersQuestionId?: string | null;
};

export type DeleteQuestionAnswersInput = {
  id?: string | null;
  _version?: number | null;
};

export type CreateCourseTagsInput = {
  id?: string | null;
  tag: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  status?: elStatus | null;
  _version?: number | null;
};

export type ModelCourseTagsConditionInput = {
  tag?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  status?: ModelelStatusInput | null;
  and?: Array<ModelCourseTagsConditionInput | null> | null;
  or?: Array<ModelCourseTagsConditionInput | null> | null;
  not?: ModelCourseTagsConditionInput | null;
};

export type UpdateCourseTagsInput = {
  id: string;
  tag?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  status?: elStatus | null;
  _version?: number | null;
};

export type DeleteCourseTagsInput = {
  id?: string | null;
  _version?: number | null;
};

export type CreateCourseAssetsResourceInput = {
  id?: string | null;
  courseId: string;
  courseAssetId: string;
  description: string;
  contentType: ResourceType;
  sourceCode?: string | null;
  fileName?: string | null;
  fileType?: string | null;
  fileSize?: string | null;
  status: elStatus;
  updatedAt?: string | null;
  createdAt?: string | null;
  courseAsset?: CourseAssetsInput | null;
  _version?: number | null;
  courseAssetsResourceCourseAssetId?: string | null;
};

export type CourseAssetsInput = {
  id: string;
  courseId: string;
  assetId?: string | null;
  assetType: elAssetType;
  title: string;
  description: string;
  order?: number | null;
  timeAprox?: string | null;
  starred?: boolean | null;
  important?: boolean | null;
  showAsOverview?: boolean | null;
  showAsFree?: boolean | null;
  nextStep?: string | null;
  prevStep?: string | null;
  status: elStatus;
  updatedAt?: string | null;
  createdAt?: string | null;
  course?: CourseInput | null;
  topic?: ContentInput | null;
  quiz?: QuizInput | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ModelCourseAssetsResourceConditionInput = {
  courseId?: ModelIDInput | null;
  courseAssetId?: ModelIDInput | null;
  description?: ModelStringInput | null;
  contentType?: ModelResourceTypeInput | null;
  sourceCode?: ModelStringInput | null;
  fileName?: ModelStringInput | null;
  fileType?: ModelStringInput | null;
  fileSize?: ModelStringInput | null;
  status?: ModelelStatusInput | null;
  updatedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelCourseAssetsResourceConditionInput | null> | null;
  or?: Array<ModelCourseAssetsResourceConditionInput | null> | null;
  not?: ModelCourseAssetsResourceConditionInput | null;
};

export type UpdateCourseAssetsResourceInput = {
  id: string;
  courseId?: string | null;
  courseAssetId?: string | null;
  description?: string | null;
  contentType?: ResourceType | null;
  sourceCode?: string | null;
  fileName?: string | null;
  fileType?: string | null;
  fileSize?: string | null;
  status?: elStatus | null;
  updatedAt?: string | null;
  createdAt?: string | null;
  courseAsset?: CourseAssetsInput | null;
  _version?: number | null;
  courseAssetsResourceCourseAssetId?: string | null;
};

export type DeleteCourseAssetsResourceInput = {
  id?: string | null;
  _version?: number | null;
};

export type CreateCourseAssetSubsInput = {
  id?: string | null;
  courseSubsId: string;
  assetId: string;
  userId: string;
  assetType: elAssetType;
  title?: string | null;
  description?: string | null;
  category?: string | null;
  metaData?: string | null;
  status: elSubStatus;
  asset?: CourseAssetsInput | null;
  updatedAt?: string | null;
  createdAt?: string | null;
  assetsSubs?: CourseSubsInput | null;
  _version?: number | null;
  courseAssetSubsAssetsSubsId?: string | null;
};

export type CourseSubsInput = {
  id: string;
  courseId: string;
  userId: string;
  title?: string | null;
  description?: string | null;
  category?: string | null;
  level?: string | null;
  tags?: string | null;
  metaData?: string | null;
  status: elSubStatus;
  course?: CourseInput | null;
  updatedAt?: string | null;
  createdAt?: string | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ModelCourseAssetSubsConditionInput = {
  courseSubsId?: ModelIDInput | null;
  assetId?: ModelIDInput | null;
  userId?: ModelIDInput | null;
  assetType?: ModelelAssetTypeInput | null;
  title?: ModelStringInput | null;
  description?: ModelStringInput | null;
  category?: ModelStringInput | null;
  metaData?: ModelStringInput | null;
  status?: ModelelSubStatusInput | null;
  updatedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelCourseAssetSubsConditionInput | null> | null;
  or?: Array<ModelCourseAssetSubsConditionInput | null> | null;
  not?: ModelCourseAssetSubsConditionInput | null;
};

export type UpdateCourseAssetSubsInput = {
  id: string;
  courseSubsId?: string | null;
  assetId?: string | null;
  userId?: string | null;
  assetType?: elAssetType | null;
  title?: string | null;
  description?: string | null;
  category?: string | null;
  metaData?: string | null;
  status?: elSubStatus | null;
  asset?: CourseAssetsInput | null;
  updatedAt?: string | null;
  createdAt?: string | null;
  assetsSubs?: CourseSubsInput | null;
  _version?: number | null;
  courseAssetSubsAssetsSubsId?: string | null;
};

export type DeleteCourseAssetSubsInput = {
  id?: string | null;
  _version?: number | null;
};

export type CreateContactInput = {
  id?: string | null;
  userID: string;
  syncID?: string | null;
  contactType?: ContactType | null;
  contactName: string;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  phone: string;
  avatar?: string | null;
  avatarType?: string | null;
  jobTitle?: string | null;
  birthday?: string | null;
  starred?: boolean | null;
  frequent?: boolean | null;
  status: string;
  pbProfileOptions?: string | null;
  userRelatedId?: string | null;
  notes?: string | null;
  businessId?: string | null;
  contactSince?: string | null;
  updatedAt?: string | null;
  createdAt?: string | null;
  contactRelId?: string | null;
  _version?: number | null;
  contactContactRelId?: string | null;
};

export enum ContactType {
  Organization = "Organization",
  Contact = "Contact"
}

export type ModelContactConditionInput = {
  userID?: ModelStringInput | null;
  syncID?: ModelStringInput | null;
  contactType?: ModelContactTypeInput | null;
  contactName?: ModelStringInput | null;
  firstName?: ModelStringInput | null;
  lastName?: ModelStringInput | null;
  email?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  avatar?: ModelStringInput | null;
  avatarType?: ModelStringInput | null;
  jobTitle?: ModelStringInput | null;
  birthday?: ModelStringInput | null;
  starred?: ModelBooleanInput | null;
  frequent?: ModelBooleanInput | null;
  status?: ModelStringInput | null;
  pbProfileOptions?: ModelStringInput | null;
  userRelatedId?: ModelStringInput | null;
  notes?: ModelStringInput | null;
  businessId?: ModelStringInput | null;
  contactSince?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  contactRelId?: ModelStringInput | null;
  and?: Array<ModelContactConditionInput | null> | null;
  or?: Array<ModelContactConditionInput | null> | null;
  not?: ModelContactConditionInput | null;
};

export type ModelContactTypeInput = {
  eq?: ContactType | null;
  ne?: ContactType | null;
};

export type UpdateContactInput = {
  id: string;
  userID?: string | null;
  syncID?: string | null;
  contactType?: ContactType | null;
  contactName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  avatar?: string | null;
  avatarType?: string | null;
  jobTitle?: string | null;
  birthday?: string | null;
  starred?: boolean | null;
  frequent?: boolean | null;
  status?: string | null;
  pbProfileOptions?: string | null;
  userRelatedId?: string | null;
  notes?: string | null;
  businessId?: string | null;
  contactSince?: string | null;
  updatedAt?: string | null;
  createdAt?: string | null;
  contactRelId?: string | null;
  _version?: number | null;
  contactContactRelId?: string | null;
};

export type DeleteContactInput = {
  id?: string | null;
  _version?: number | null;
};

export type CreateConnectsInput = {
  id?: string | null;
  contactId: string;
  connsContactId?: string | null;
  contactType?: string | null;
  contactName?: string | null;
  status: string;
  updatedAt?: string | null;
  createdAt?: string | null;
  contact?: ContactInput | null;
  _version?: number | null;
  connectsContactId?: string | null;
};

export type ContactInput = {
  id: string;
  userID: string;
  syncID?: string | null;
  contactType?: ContactType | null;
  contactName: string;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  phone: string;
  avatar?: string | null;
  avatarType?: string | null;
  jobTitle?: string | null;
  birthday?: string | null;
  starred?: boolean | null;
  frequent?: boolean | null;
  status: string;
  pbProfileOptions?: string | null;
  userRelatedId?: string | null;
  notes?: string | null;
  businessId?: string | null;
  contactSince?: string | null;
  updatedAt?: string | null;
  createdAt?: string | null;
  contactRelId?: string | null;
  contactRel?: ContactInput | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ModelConnectsConditionInput = {
  contactId?: ModelIDInput | null;
  connsContactId?: ModelStringInput | null;
  contactType?: ModelStringInput | null;
  contactName?: ModelStringInput | null;
  status?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelConnectsConditionInput | null> | null;
  or?: Array<ModelConnectsConditionInput | null> | null;
  not?: ModelConnectsConditionInput | null;
};

export type UpdateConnectsInput = {
  id: string;
  contactId?: string | null;
  connsContactId?: string | null;
  contactType?: string | null;
  contactName?: string | null;
  status?: string | null;
  updatedAt?: string | null;
  createdAt?: string | null;
  contact?: ContactInput | null;
  _version?: number | null;
  connectsContactId?: string | null;
};

export type DeleteConnectsInput = {
  id?: string | null;
  _version?: number | null;
};

export type CreateContactAddressInput = {
  id?: string | null;
  contactId: string;
  addressType?: string | null;
  street?: string | null;
  street1?: string | null;
  zipCode?: string | null;
  city?: string | null;
  state?: string | null;
  Country?: string | null;
  status: string;
  updatedAt?: string | null;
  createdAt?: string | null;
  lat?: string | null;
  lng?: string | null;
  contact?: ContactInput | null;
  _version?: number | null;
  contactAddressContactId?: string | null;
};

export type ModelContactAddressConditionInput = {
  contactId?: ModelIDInput | null;
  addressType?: ModelStringInput | null;
  street?: ModelStringInput | null;
  street1?: ModelStringInput | null;
  zipCode?: ModelStringInput | null;
  city?: ModelStringInput | null;
  state?: ModelStringInput | null;
  Country?: ModelStringInput | null;
  status?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  lat?: ModelStringInput | null;
  lng?: ModelStringInput | null;
  and?: Array<ModelContactAddressConditionInput | null> | null;
  or?: Array<ModelContactAddressConditionInput | null> | null;
  not?: ModelContactAddressConditionInput | null;
};

export type UpdateContactAddressInput = {
  id: string;
  contactId?: string | null;
  addressType?: string | null;
  street?: string | null;
  street1?: string | null;
  zipCode?: string | null;
  city?: string | null;
  state?: string | null;
  Country?: string | null;
  status?: string | null;
  updatedAt?: string | null;
  createdAt?: string | null;
  lat?: string | null;
  lng?: string | null;
  contact?: ContactInput | null;
  _version?: number | null;
  contactAddressContactId?: string | null;
};

export type DeleteContactAddressInput = {
  id?: string | null;
  _version?: number | null;
};

export type CreateMembershipInput = {
  id?: string | null;
  contactId: string;
  noLicenses?: number | null;
  noClaimed?: number | null;
  groupId?: string | null;
  group?: string | null;
  prefix?: string | null;
  status: string;
  updatedAt?: string | null;
  createdAt?: string | null;
  org?: ContactInput | null;
  _version?: number | null;
  membershipOrgId?: string | null;
};

export type ModelMembershipConditionInput = {
  contactId?: ModelIDInput | null;
  noLicenses?: ModelIntInput | null;
  noClaimed?: ModelIntInput | null;
  groupId?: ModelStringInput | null;
  group?: ModelStringInput | null;
  prefix?: ModelStringInput | null;
  status?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelMembershipConditionInput | null> | null;
  or?: Array<ModelMembershipConditionInput | null> | null;
  not?: ModelMembershipConditionInput | null;
};

export type UpdateMembershipInput = {
  id: string;
  contactId?: string | null;
  noLicenses?: number | null;
  noClaimed?: number | null;
  groupId?: string | null;
  group?: string | null;
  prefix?: string | null;
  status?: string | null;
  updatedAt?: string | null;
  createdAt?: string | null;
  org?: ContactInput | null;
  _version?: number | null;
  membershipOrgId?: string | null;
};

export type DeleteMembershipInput = {
  id?: string | null;
  _version?: number | null;
};

export type ModelSessionFilterInput = {
  id?: ModelIDInput | null;
  userId?: ModelIDInput | null;
  location?: ModelStringInput | null;
  event?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSessionFilterInput | null> | null;
  or?: Array<ModelSessionFilterInput | null> | null;
  not?: ModelSessionFilterInput | null;
};

export type ModelUserSettingsFilterInput = {
  id?: ModelIDInput | null;
  userId?: ModelIDInput | null;
  pageSize?: ModelIntInput | null;
  notiType?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelUserSettingsFilterInput | null> | null;
  or?: Array<ModelUserSettingsFilterInput | null> | null;
  not?: ModelUserSettingsFilterInput | null;
};

export type ModelNotificationFilterInput = {
  id?: ModelIDInput | null;
  typeNotify?: ModelStringInput | null;
  userId?: ModelStringInput | null;
  subject?: ModelStringInput | null;
  body?: ModelStringInput | null;
  localUrl?: ModelStringInput | null;
  renotify?: ModelBooleanInput | null;
  silent?: ModelBooleanInput | null;
  dateFor?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelNotificationFilterInput | null> | null;
  or?: Array<ModelNotificationFilterInput | null> | null;
  not?: ModelNotificationFilterInput | null;
};

export type ModelUserPrivacyFilterInput = {
  id?: ModelIDInput | null;
  userId?: ModelIDInput | null;
  profileShared?: ModelStringInput | null;
  bgPhoto?: ModelStringInput | null;
  headLine?: ModelStringInput | null;
  secondHeadLine?: ModelStringInput | null;
  titleLine?: ModelStringInput | null;
  bioLine?: ModelStringInput | null;
  showEmail?: ModelBooleanInput | null;
  showPhone?: ModelBooleanInput | null;
  showEmailOn?: ModelStringInput | null;
  contactDirectlyByEmail?: ModelBooleanInput | null;
  showConnectionsTo?: ModelStringInput | null;
  oaConnInvitationAccepted?: ModelBooleanInput | null;
  oaEventUpdates?: ModelBooleanInput | null;
  oaUpdatesFromConnections?: ModelBooleanInput | null;
  oaBreakingNews?: ModelBooleanInput | null;
  oaTopsPicks?: ModelBooleanInput | null;
  oaWhoViewedYourProfile?: ModelBooleanInput | null;
  oaYourNewFollowers?: ModelBooleanInput | null;
  emailEventsInvitations?: ModelBooleanInput | null;
  emailEventsUpdates?: ModelBooleanInput | null;
  emailNewsInvitations?: ModelBooleanInput | null;
  emailNewsSubscriptions?: ModelBooleanInput | null;
  whoRequestForNotifications?: ModelBooleanInput | null;
  whoInvitationsToAttend?: ModelBooleanInput | null;
  locMap?: ModelStringInput | null;
  location?: ModelStringInput | null;
  locTitle?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelUserPrivacyFilterInput | null> | null;
  or?: Array<ModelUserPrivacyFilterInput | null> | null;
  not?: ModelUserPrivacyFilterInput | null;
};

export type ModelCourseCategoryFilterInput = {
  id?: ModelIDInput | null;
  category?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  status?: ModelelStatusInput | null;
  and?: Array<ModelCourseCategoryFilterInput | null> | null;
  or?: Array<ModelCourseCategoryFilterInput | null> | null;
  not?: ModelCourseCategoryFilterInput | null;
};

export type ModelCourseResourceFilterInput = {
  id?: ModelIDInput | null;
  courseId?: ModelStringInput | null;
  description?: ModelStringInput | null;
  contentType?: ModelResourceTypeInput | null;
  sourceCode?: ModelStringInput | null;
  fileName?: ModelStringInput | null;
  fileType?: ModelStringInput | null;
  fileSize?: ModelStringInput | null;
  status?: ModelelStatusInput | null;
  updatedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelCourseResourceFilterInput | null> | null;
  or?: Array<ModelCourseResourceFilterInput | null> | null;
  not?: ModelCourseResourceFilterInput | null;
};

export type ModelContentFilterInput = {
  id?: ModelIDInput | null;
  courseId?: ModelIDInput | null;
  contentType?: ModelContentTypeInput | null;
  media?: ModelStringInput | null;
  sourceMedia?: ModelStringInput | null;
  fileName?: ModelStringInput | null;
  fileType?: ModelStringInput | null;
  fileSize?: ModelStringInput | null;
  transcoderMeta?: ModelStringInput | null;
  transcoderJobId?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelContentFilterInput | null> | null;
  or?: Array<ModelContentFilterInput | null> | null;
  not?: ModelContentFilterInput | null;
};

export type ModelContentResourceFilterInput = {
  id?: ModelIDInput | null;
  courseId?: ModelStringInput | null;
  description?: ModelStringInput | null;
  contentType?: ModelResourceTypeInput | null;
  sourceCode?: ModelStringInput | null;
  fileName?: ModelStringInput | null;
  fileType?: ModelStringInput | null;
  fileSize?: ModelStringInput | null;
  status?: ModelelStatusInput | null;
  updatedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelContentResourceFilterInput | null> | null;
  or?: Array<ModelContentResourceFilterInput | null> | null;
  not?: ModelContentResourceFilterInput | null;
};

export type ModelCourseFilterInput = {
  id?: ModelIDInput | null;
  title?: ModelStringInput | null;
  description?: ModelStringInput | null;
  categoryId?: ModelIDInput | null;
  coverImage?: ModelStringInput | null;
  timeAprox?: ModelStringInput | null;
  status?: ModelelStatusInput | null;
  coverImage1?: ModelStringInput | null;
  coverImage2?: ModelStringInput | null;
  coverImage3?: ModelStringInput | null;
  courseType?: ModelCourseTypeInput | null;
  courseUrl?: ModelStringInput | null;
  overviewSourceMedia?: ModelStringInput | null;
  overviewFileName?: ModelStringInput | null;
  overviewFileType?: ModelStringInput | null;
  overviewFileSize?: ModelStringInput | null;
  transcoderMeta?: ModelStringInput | null;
  transcoderJobId?: ModelStringInput | null;
  courseTrend?: ModelelCourseTrendInput | null;
  learnDescription?: ModelStringInput | null;
  requirements?: ModelStringInput | null;
  level?: ModelStringInput | null;
  tags?: ModelStringInput | null;
  trending?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelCourseFilterInput | null> | null;
  or?: Array<ModelCourseFilterInput | null> | null;
  not?: ModelCourseFilterInput | null;
};

export type ModelContentSubsFilterInput = {
  id?: ModelIDInput | null;
  contentId?: ModelIDInput | null;
  userId?: ModelIDInput | null;
  metaData?: ModelStringInput | null;
  status?: ModelelSubStatusInput | null;
  updatedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelContentSubsFilterInput | null> | null;
  or?: Array<ModelContentSubsFilterInput | null> | null;
  not?: ModelContentSubsFilterInput | null;
};

export type ModelCourseAssetsFilterInput = {
  id?: ModelIDInput | null;
  courseId?: ModelIDInput | null;
  assetId?: ModelIDInput | null;
  assetType?: ModelelAssetTypeInput | null;
  title?: ModelStringInput | null;
  description?: ModelStringInput | null;
  order?: ModelIntInput | null;
  timeAprox?: ModelStringInput | null;
  starred?: ModelBooleanInput | null;
  important?: ModelBooleanInput | null;
  showAsOverview?: ModelBooleanInput | null;
  showAsFree?: ModelBooleanInput | null;
  nextStep?: ModelStringInput | null;
  prevStep?: ModelStringInput | null;
  status?: ModelelStatusInput | null;
  updatedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelCourseAssetsFilterInput | null> | null;
  or?: Array<ModelCourseAssetsFilterInput | null> | null;
  not?: ModelCourseAssetsFilterInput | null;
};

export type ModelQuizFilterInput = {
  id?: ModelIDInput | null;
  courseId?: ModelIDInput | null;
  questionsRandom?: ModelIntInput | null;
  passingScore?: ModelIntInput | null;
  noAttempts?: ModelIntInput | null;
  score?: ModelIntInput | null;
  labelWhenPassed?: ModelStringInput | null;
  labelWhenFailed?: ModelStringInput | null;
  questionFeedback?: ModelBooleanInput | null;
  updatedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  status?: ModelelStatusInput | null;
  and?: Array<ModelQuizFilterInput | null> | null;
  or?: Array<ModelQuizFilterInput | null> | null;
  not?: ModelQuizFilterInput | null;
};

export type ModelQuizQuestionFilterInput = {
  id?: ModelIDInput | null;
  quizContentId?: ModelIDInput | null;
  order?: ModelIntInput | null;
  title?: ModelStringInput | null;
  qType?: ModelelQuestionTypeInput | null;
  labelCorrectMessage?: ModelStringInput | null;
  labelIncorrectMessage?: ModelStringInput | null;
  questionFeedBack?: ModelStringInput | null;
  media?: ModelStringInput | null;
  sourceMedia?: ModelStringInput | null;
  fileName?: ModelStringInput | null;
  fileType?: ModelStringInput | null;
  fileSize?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  status?: ModelelStatusInput | null;
  and?: Array<ModelQuizQuestionFilterInput | null> | null;
  or?: Array<ModelQuizQuestionFilterInput | null> | null;
  not?: ModelQuizQuestionFilterInput | null;
};

export type ModelQuestionAnswersFilterInput = {
  id?: ModelIDInput | null;
  quizQuestionId?: ModelIDInput | null;
  order?: ModelIntInput | null;
  isCorrect?: ModelBooleanInput | null;
  title?: ModelStringInput | null;
  answer?: ModelStringInput | null;
  answerFeedBack?: ModelStringInput | null;
  qType?: ModelelQuestionTypeInput | null;
  media?: ModelStringInput | null;
  sourceMedia?: ModelStringInput | null;
  fileName?: ModelStringInput | null;
  fileType?: ModelStringInput | null;
  fileSize?: ModelStringInput | null;
  status?: ModelelStatusInput | null;
  updatedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelQuestionAnswersFilterInput | null> | null;
  or?: Array<ModelQuestionAnswersFilterInput | null> | null;
  not?: ModelQuestionAnswersFilterInput | null;
};

export type ModelCourseTagsFilterInput = {
  id?: ModelIDInput | null;
  tag?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  status?: ModelelStatusInput | null;
  and?: Array<ModelCourseTagsFilterInput | null> | null;
  or?: Array<ModelCourseTagsFilterInput | null> | null;
  not?: ModelCourseTagsFilterInput | null;
};

export type ModelCourseAssetsResourceFilterInput = {
  id?: ModelIDInput | null;
  courseId?: ModelIDInput | null;
  courseAssetId?: ModelIDInput | null;
  description?: ModelStringInput | null;
  contentType?: ModelResourceTypeInput | null;
  sourceCode?: ModelStringInput | null;
  fileName?: ModelStringInput | null;
  fileType?: ModelStringInput | null;
  fileSize?: ModelStringInput | null;
  status?: ModelelStatusInput | null;
  updatedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelCourseAssetsResourceFilterInput | null> | null;
  or?: Array<ModelCourseAssetsResourceFilterInput | null> | null;
  not?: ModelCourseAssetsResourceFilterInput | null;
};

export type ModelContactFilterInput = {
  id?: ModelIDInput | null;
  userID?: ModelStringInput | null;
  syncID?: ModelStringInput | null;
  contactType?: ModelContactTypeInput | null;
  contactName?: ModelStringInput | null;
  firstName?: ModelStringInput | null;
  lastName?: ModelStringInput | null;
  email?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  avatar?: ModelStringInput | null;
  avatarType?: ModelStringInput | null;
  jobTitle?: ModelStringInput | null;
  birthday?: ModelStringInput | null;
  starred?: ModelBooleanInput | null;
  frequent?: ModelBooleanInput | null;
  status?: ModelStringInput | null;
  pbProfileOptions?: ModelStringInput | null;
  userRelatedId?: ModelStringInput | null;
  notes?: ModelStringInput | null;
  businessId?: ModelStringInput | null;
  contactSince?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  contactRelId?: ModelStringInput | null;
  and?: Array<ModelContactFilterInput | null> | null;
  or?: Array<ModelContactFilterInput | null> | null;
  not?: ModelContactFilterInput | null;
};

export type ModelConnectsFilterInput = {
  id?: ModelIDInput | null;
  contactId?: ModelIDInput | null;
  connsContactId?: ModelStringInput | null;
  contactType?: ModelStringInput | null;
  contactName?: ModelStringInput | null;
  status?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelConnectsFilterInput | null> | null;
  or?: Array<ModelConnectsFilterInput | null> | null;
  not?: ModelConnectsFilterInput | null;
};

export type ModelContactAddressFilterInput = {
  id?: ModelIDInput | null;
  contactId?: ModelIDInput | null;
  addressType?: ModelStringInput | null;
  street?: ModelStringInput | null;
  street1?: ModelStringInput | null;
  zipCode?: ModelStringInput | null;
  city?: ModelStringInput | null;
  state?: ModelStringInput | null;
  Country?: ModelStringInput | null;
  status?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  lat?: ModelStringInput | null;
  lng?: ModelStringInput | null;
  and?: Array<ModelContactAddressFilterInput | null> | null;
  or?: Array<ModelContactAddressFilterInput | null> | null;
  not?: ModelContactAddressFilterInput | null;
};

export type ModelMembershipFilterInput = {
  id?: ModelIDInput | null;
  contactId?: ModelIDInput | null;
  noLicenses?: ModelIntInput | null;
  noClaimed?: ModelIntInput | null;
  groupId?: ModelStringInput | null;
  group?: ModelStringInput | null;
  prefix?: ModelStringInput | null;
  status?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelMembershipFilterInput | null> | null;
  or?: Array<ModelMembershipFilterInput | null> | null;
  not?: ModelMembershipFilterInput | null;
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC"
}

export type ModelStringKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ModelIDKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ModelCourseAssetsAssetsByCourseIdCompositeKeyConditionInput = {
  eq?: ModelCourseAssetsAssetsByCourseIdCompositeKeyInput | null;
  le?: ModelCourseAssetsAssetsByCourseIdCompositeKeyInput | null;
  lt?: ModelCourseAssetsAssetsByCourseIdCompositeKeyInput | null;
  ge?: ModelCourseAssetsAssetsByCourseIdCompositeKeyInput | null;
  gt?: ModelCourseAssetsAssetsByCourseIdCompositeKeyInput | null;
  between?: Array<ModelCourseAssetsAssetsByCourseIdCompositeKeyInput | null> | null;
  beginsWith?: ModelCourseAssetsAssetsByCourseIdCompositeKeyInput | null;
};

export type ModelCourseAssetsAssetsByCourseIdCompositeKeyInput = {
  assetId?: string | null;
  assetType?: elAssetType | null;
};

export type ModelIntKeyConditionInput = {
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelCourseSubsFilterInput = {
  id?: ModelIDInput | null;
  courseId?: ModelIDInput | null;
  userId?: ModelIDInput | null;
  title?: ModelStringInput | null;
  description?: ModelStringInput | null;
  category?: ModelStringInput | null;
  level?: ModelStringInput | null;
  tags?: ModelStringInput | null;
  metaData?: ModelStringInput | null;
  status?: ModelelSubStatusInput | null;
  updatedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelCourseSubsFilterInput | null> | null;
  or?: Array<ModelCourseSubsFilterInput | null> | null;
  not?: ModelCourseSubsFilterInput | null;
};

export type ModelCourseAssetSubsFilterInput = {
  id?: ModelIDInput | null;
  courseSubsId?: ModelIDInput | null;
  assetId?: ModelIDInput | null;
  userId?: ModelIDInput | null;
  assetType?: ModelelAssetTypeInput | null;
  title?: ModelStringInput | null;
  description?: ModelStringInput | null;
  category?: ModelStringInput | null;
  metaData?: ModelStringInput | null;
  status?: ModelelSubStatusInput | null;
  updatedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelCourseAssetSubsFilterInput | null> | null;
  or?: Array<ModelCourseAssetSubsFilterInput | null> | null;
  not?: ModelCourseAssetSubsFilterInput | null;
};

export type CreateSessionMutation = {
  __typename: "Session";
  id: string;
  userId: string;
  location: string | null;
  event: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type UpdateSessionMutation = {
  __typename: "Session";
  id: string;
  userId: string;
  location: string | null;
  event: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type DeleteSessionMutation = {
  __typename: "Session";
  id: string;
  userId: string;
  location: string | null;
  event: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type CreateUserSettingsMutation = {
  __typename: "UserSettings";
  id: string;
  userId: string;
  pageSize: number | null;
  notiType: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type UpdateUserSettingsMutation = {
  __typename: "UserSettings";
  id: string;
  userId: string;
  pageSize: number | null;
  notiType: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type DeleteUserSettingsMutation = {
  __typename: "UserSettings";
  id: string;
  userId: string;
  pageSize: number | null;
  notiType: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type CreateNotificationMutation = {
  __typename: "Notification";
  id: string;
  typeNotify: string | null;
  userId: string | null;
  subject: string | null;
  body: string | null;
  localUrl: string | null;
  renotify: boolean | null;
  silent: boolean | null;
  dateFor: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type UpdateNotificationMutation = {
  __typename: "Notification";
  id: string;
  typeNotify: string | null;
  userId: string | null;
  subject: string | null;
  body: string | null;
  localUrl: string | null;
  renotify: boolean | null;
  silent: boolean | null;
  dateFor: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type DeleteNotificationMutation = {
  __typename: "Notification";
  id: string;
  typeNotify: string | null;
  userId: string | null;
  subject: string | null;
  body: string | null;
  localUrl: string | null;
  renotify: boolean | null;
  silent: boolean | null;
  dateFor: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type CreateUserPrivacyMutation = {
  __typename: "UserPrivacy";
  id: string;
  userId: string;
  profileShared: string;
  bgPhoto: string | null;
  headLine: string | null;
  secondHeadLine: string | null;
  titleLine: string | null;
  bioLine: string | null;
  showEmail: boolean | null;
  showPhone: boolean | null;
  showEmailOn: string | null;
  contactDirectlyByEmail: boolean | null;
  showConnectionsTo: string | null;
  oaConnInvitationAccepted: boolean | null;
  oaEventUpdates: boolean | null;
  oaUpdatesFromConnections: boolean | null;
  oaBreakingNews: boolean | null;
  oaTopsPicks: boolean | null;
  oaWhoViewedYourProfile: boolean | null;
  oaYourNewFollowers: boolean | null;
  emailEventsInvitations: boolean | null;
  emailEventsUpdates: boolean | null;
  emailNewsInvitations: boolean | null;
  emailNewsSubscriptions: boolean | null;
  whoRequestForNotifications: boolean | null;
  whoInvitationsToAttend: boolean | null;
  locMap: string | null;
  location: string | null;
  locTitle: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type UpdateUserPrivacyMutation = {
  __typename: "UserPrivacy";
  id: string;
  userId: string;
  profileShared: string;
  bgPhoto: string | null;
  headLine: string | null;
  secondHeadLine: string | null;
  titleLine: string | null;
  bioLine: string | null;
  showEmail: boolean | null;
  showPhone: boolean | null;
  showEmailOn: string | null;
  contactDirectlyByEmail: boolean | null;
  showConnectionsTo: string | null;
  oaConnInvitationAccepted: boolean | null;
  oaEventUpdates: boolean | null;
  oaUpdatesFromConnections: boolean | null;
  oaBreakingNews: boolean | null;
  oaTopsPicks: boolean | null;
  oaWhoViewedYourProfile: boolean | null;
  oaYourNewFollowers: boolean | null;
  emailEventsInvitations: boolean | null;
  emailEventsUpdates: boolean | null;
  emailNewsInvitations: boolean | null;
  emailNewsSubscriptions: boolean | null;
  whoRequestForNotifications: boolean | null;
  whoInvitationsToAttend: boolean | null;
  locMap: string | null;
  location: string | null;
  locTitle: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type DeleteUserPrivacyMutation = {
  __typename: "UserPrivacy";
  id: string;
  userId: string;
  profileShared: string;
  bgPhoto: string | null;
  headLine: string | null;
  secondHeadLine: string | null;
  titleLine: string | null;
  bioLine: string | null;
  showEmail: boolean | null;
  showPhone: boolean | null;
  showEmailOn: string | null;
  contactDirectlyByEmail: boolean | null;
  showConnectionsTo: string | null;
  oaConnInvitationAccepted: boolean | null;
  oaEventUpdates: boolean | null;
  oaUpdatesFromConnections: boolean | null;
  oaBreakingNews: boolean | null;
  oaTopsPicks: boolean | null;
  oaWhoViewedYourProfile: boolean | null;
  oaYourNewFollowers: boolean | null;
  emailEventsInvitations: boolean | null;
  emailEventsUpdates: boolean | null;
  emailNewsInvitations: boolean | null;
  emailNewsSubscriptions: boolean | null;
  whoRequestForNotifications: boolean | null;
  whoInvitationsToAttend: boolean | null;
  locMap: string | null;
  location: string | null;
  locTitle: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type CreateCourseCategoryMutation = {
  __typename: "CourseCategory";
  id: string;
  category: string;
  createdAt: string | null;
  updatedAt: string | null;
  status: elStatus | null;
  courses: {
    __typename: "ModelCourseConnection";
    items: Array<{
      __typename: "Course";
      id: string;
      title: string;
      description: string;
      categoryId: string;
      coverImage: string | null;
      timeAprox: string;
      status: elStatus;
      coverImage1: string | null;
      coverImage2: string | null;
      coverImage3: string | null;
      courseType: CourseType | null;
      courseUrl: string | null;
      overviewSourceMedia: string | null;
      overviewFileName: string | null;
      overviewFileType: string | null;
      overviewFileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      courseTrend: elCourseTrend | null;
      learnDescription: string | null;
      requirements: string | null;
      level: string | null;
      tags: string | null;
      trending: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type UpdateCourseCategoryMutation = {
  __typename: "CourseCategory";
  id: string;
  category: string;
  createdAt: string | null;
  updatedAt: string | null;
  status: elStatus | null;
  courses: {
    __typename: "ModelCourseConnection";
    items: Array<{
      __typename: "Course";
      id: string;
      title: string;
      description: string;
      categoryId: string;
      coverImage: string | null;
      timeAprox: string;
      status: elStatus;
      coverImage1: string | null;
      coverImage2: string | null;
      coverImage3: string | null;
      courseType: CourseType | null;
      courseUrl: string | null;
      overviewSourceMedia: string | null;
      overviewFileName: string | null;
      overviewFileType: string | null;
      overviewFileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      courseTrend: elCourseTrend | null;
      learnDescription: string | null;
      requirements: string | null;
      level: string | null;
      tags: string | null;
      trending: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type DeleteCourseCategoryMutation = {
  __typename: "CourseCategory";
  id: string;
  category: string;
  createdAt: string | null;
  updatedAt: string | null;
  status: elStatus | null;
  courses: {
    __typename: "ModelCourseConnection";
    items: Array<{
      __typename: "Course";
      id: string;
      title: string;
      description: string;
      categoryId: string;
      coverImage: string | null;
      timeAprox: string;
      status: elStatus;
      coverImage1: string | null;
      coverImage2: string | null;
      coverImage3: string | null;
      courseType: CourseType | null;
      courseUrl: string | null;
      overviewSourceMedia: string | null;
      overviewFileName: string | null;
      overviewFileType: string | null;
      overviewFileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      courseTrend: elCourseTrend | null;
      learnDescription: string | null;
      requirements: string | null;
      level: string | null;
      tags: string | null;
      trending: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type CreateCourseResourceMutation = {
  __typename: "CourseResource";
  id: string;
  courseId: string;
  description: string;
  contentType: ResourceType;
  sourceCode: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  status: elStatus;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type UpdateCourseResourceMutation = {
  __typename: "CourseResource";
  id: string;
  courseId: string;
  description: string;
  contentType: ResourceType;
  sourceCode: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  status: elStatus;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type DeleteCourseResourceMutation = {
  __typename: "CourseResource";
  id: string;
  courseId: string;
  description: string;
  contentType: ResourceType;
  sourceCode: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  status: elStatus;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type CreateContentMutation = {
  __typename: "Content";
  id: string;
  courseId: string;
  contentType: ContentType;
  media: string | null;
  sourceMedia: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  transcoderMeta: string | null;
  transcoderJobId: string | null;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type UpdateContentMutation = {
  __typename: "Content";
  id: string;
  courseId: string;
  contentType: ContentType;
  media: string | null;
  sourceMedia: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  transcoderMeta: string | null;
  transcoderJobId: string | null;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type DeleteContentMutation = {
  __typename: "Content";
  id: string;
  courseId: string;
  contentType: ContentType;
  media: string | null;
  sourceMedia: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  transcoderMeta: string | null;
  transcoderJobId: string | null;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type CreateContentResourceMutation = {
  __typename: "ContentResource";
  id: string;
  courseId: string;
  description: string;
  contentType: ResourceType;
  sourceCode: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  status: elStatus;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type UpdateContentResourceMutation = {
  __typename: "ContentResource";
  id: string;
  courseId: string;
  description: string;
  contentType: ResourceType;
  sourceCode: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  status: elStatus;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type DeleteContentResourceMutation = {
  __typename: "ContentResource";
  id: string;
  courseId: string;
  description: string;
  contentType: ResourceType;
  sourceCode: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  status: elStatus;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type CreateCourseMutation = {
  __typename: "Course";
  id: string;
  title: string;
  description: string;
  category: {
    __typename: "CourseCategory";
    id: string;
    category: string;
    createdAt: string | null;
    updatedAt: string | null;
    status: elStatus | null;
    courses: {
      __typename: "ModelCourseConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  };
  categoryId: string;
  coverImage: string | null;
  timeAprox: string;
  status: elStatus;
  coverImage1: string | null;
  coverImage2: string | null;
  coverImage3: string | null;
  courseType: CourseType | null;
  courseUrl: string | null;
  overviewSourceMedia: string | null;
  overviewFileName: string | null;
  overviewFileType: string | null;
  overviewFileSize: string | null;
  transcoderMeta: string | null;
  transcoderJobId: string | null;
  courseTrend: elCourseTrend | null;
  learnDescription: string | null;
  requirements: string | null;
  level: string | null;
  tags: string | null;
  trending: string | null;
  updatedAt: string | null;
  createdAt: string | null;
  assets: {
    __typename: "ModelCourseAssetsConnection";
    items: Array<{
      __typename: "CourseAssets";
      id: string;
      courseId: string;
      assetId: string | null;
      assetType: elAssetType;
      title: string;
      description: string;
      order: number | null;
      timeAprox: string | null;
      starred: boolean | null;
      important: boolean | null;
      showAsOverview: boolean | null;
      showAsFree: boolean | null;
      nextStep: string | null;
      prevStep: string | null;
      status: elStatus;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type UpdateCourseMutation = {
  __typename: "Course";
  id: string;
  title: string;
  description: string;
  category: {
    __typename: "CourseCategory";
    id: string;
    category: string;
    createdAt: string | null;
    updatedAt: string | null;
    status: elStatus | null;
    courses: {
      __typename: "ModelCourseConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  };
  categoryId: string;
  coverImage: string | null;
  timeAprox: string;
  status: elStatus;
  coverImage1: string | null;
  coverImage2: string | null;
  coverImage3: string | null;
  courseType: CourseType | null;
  courseUrl: string | null;
  overviewSourceMedia: string | null;
  overviewFileName: string | null;
  overviewFileType: string | null;
  overviewFileSize: string | null;
  transcoderMeta: string | null;
  transcoderJobId: string | null;
  courseTrend: elCourseTrend | null;
  learnDescription: string | null;
  requirements: string | null;
  level: string | null;
  tags: string | null;
  trending: string | null;
  updatedAt: string | null;
  createdAt: string | null;
  assets: {
    __typename: "ModelCourseAssetsConnection";
    items: Array<{
      __typename: "CourseAssets";
      id: string;
      courseId: string;
      assetId: string | null;
      assetType: elAssetType;
      title: string;
      description: string;
      order: number | null;
      timeAprox: string | null;
      starred: boolean | null;
      important: boolean | null;
      showAsOverview: boolean | null;
      showAsFree: boolean | null;
      nextStep: string | null;
      prevStep: string | null;
      status: elStatus;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type DeleteCourseMutation = {
  __typename: "Course";
  id: string;
  title: string;
  description: string;
  category: {
    __typename: "CourseCategory";
    id: string;
    category: string;
    createdAt: string | null;
    updatedAt: string | null;
    status: elStatus | null;
    courses: {
      __typename: "ModelCourseConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  };
  categoryId: string;
  coverImage: string | null;
  timeAprox: string;
  status: elStatus;
  coverImage1: string | null;
  coverImage2: string | null;
  coverImage3: string | null;
  courseType: CourseType | null;
  courseUrl: string | null;
  overviewSourceMedia: string | null;
  overviewFileName: string | null;
  overviewFileType: string | null;
  overviewFileSize: string | null;
  transcoderMeta: string | null;
  transcoderJobId: string | null;
  courseTrend: elCourseTrend | null;
  learnDescription: string | null;
  requirements: string | null;
  level: string | null;
  tags: string | null;
  trending: string | null;
  updatedAt: string | null;
  createdAt: string | null;
  assets: {
    __typename: "ModelCourseAssetsConnection";
    items: Array<{
      __typename: "CourseAssets";
      id: string;
      courseId: string;
      assetId: string | null;
      assetType: elAssetType;
      title: string;
      description: string;
      order: number | null;
      timeAprox: string | null;
      starred: boolean | null;
      important: boolean | null;
      showAsOverview: boolean | null;
      showAsFree: boolean | null;
      nextStep: string | null;
      prevStep: string | null;
      status: elStatus;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type CreateContentSubsMutation = {
  __typename: "ContentSubs";
  id: string;
  contentId: string;
  userId: string;
  metaData: string | null;
  status: elSubStatus;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type UpdateContentSubsMutation = {
  __typename: "ContentSubs";
  id: string;
  contentId: string;
  userId: string;
  metaData: string | null;
  status: elSubStatus;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type DeleteContentSubsMutation = {
  __typename: "ContentSubs";
  id: string;
  contentId: string;
  userId: string;
  metaData: string | null;
  status: elSubStatus;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type CreateCourseSubsMutation = {
  __typename: "CourseSubs";
  id: string;
  courseId: string;
  userId: string;
  title: string | null;
  description: string | null;
  category: string | null;
  level: string | null;
  tags: string | null;
  metaData: string | null;
  status: elSubStatus;
  course: {
    __typename: "Course";
    id: string;
    title: string;
    description: string;
    category: {
      __typename: "CourseCategory";
      id: string;
      category: string;
      createdAt: string | null;
      updatedAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    };
    categoryId: string;
    coverImage: string | null;
    timeAprox: string;
    status: elStatus;
    coverImage1: string | null;
    coverImage2: string | null;
    coverImage3: string | null;
    courseType: CourseType | null;
    courseUrl: string | null;
    overviewSourceMedia: string | null;
    overviewFileName: string | null;
    overviewFileType: string | null;
    overviewFileSize: string | null;
    transcoderMeta: string | null;
    transcoderJobId: string | null;
    courseTrend: elCourseTrend | null;
    learnDescription: string | null;
    requirements: string | null;
    level: string | null;
    tags: string | null;
    trending: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    assets: {
      __typename: "ModelCourseAssetsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
  owner: string | null;
  assetsSubs: {
    __typename: "ModelCourseAssetSubsConnection";
    items: Array<{
      __typename: "CourseAssetSubs";
      id: string;
      courseSubsId: string;
      assetId: string;
      userId: string;
      assetType: elAssetType;
      title: string | null;
      description: string | null;
      category: string | null;
      metaData: string | null;
      status: elSubStatus;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
      owner: string | null;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
};

export type UpdateCourseSubsMutation = {
  __typename: "CourseSubs";
  id: string;
  courseId: string;
  userId: string;
  title: string | null;
  description: string | null;
  category: string | null;
  level: string | null;
  tags: string | null;
  metaData: string | null;
  status: elSubStatus;
  course: {
    __typename: "Course";
    id: string;
    title: string;
    description: string;
    category: {
      __typename: "CourseCategory";
      id: string;
      category: string;
      createdAt: string | null;
      updatedAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    };
    categoryId: string;
    coverImage: string | null;
    timeAprox: string;
    status: elStatus;
    coverImage1: string | null;
    coverImage2: string | null;
    coverImage3: string | null;
    courseType: CourseType | null;
    courseUrl: string | null;
    overviewSourceMedia: string | null;
    overviewFileName: string | null;
    overviewFileType: string | null;
    overviewFileSize: string | null;
    transcoderMeta: string | null;
    transcoderJobId: string | null;
    courseTrend: elCourseTrend | null;
    learnDescription: string | null;
    requirements: string | null;
    level: string | null;
    tags: string | null;
    trending: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    assets: {
      __typename: "ModelCourseAssetsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
  owner: string | null;
  assetsSubs: {
    __typename: "ModelCourseAssetSubsConnection";
    items: Array<{
      __typename: "CourseAssetSubs";
      id: string;
      courseSubsId: string;
      assetId: string;
      userId: string;
      assetType: elAssetType;
      title: string | null;
      description: string | null;
      category: string | null;
      metaData: string | null;
      status: elSubStatus;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
      owner: string | null;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
};

export type DeleteCourseSubsMutation = {
  __typename: "CourseSubs";
  id: string;
  courseId: string;
  userId: string;
  title: string | null;
  description: string | null;
  category: string | null;
  level: string | null;
  tags: string | null;
  metaData: string | null;
  status: elSubStatus;
  course: {
    __typename: "Course";
    id: string;
    title: string;
    description: string;
    category: {
      __typename: "CourseCategory";
      id: string;
      category: string;
      createdAt: string | null;
      updatedAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    };
    categoryId: string;
    coverImage: string | null;
    timeAprox: string;
    status: elStatus;
    coverImage1: string | null;
    coverImage2: string | null;
    coverImage3: string | null;
    courseType: CourseType | null;
    courseUrl: string | null;
    overviewSourceMedia: string | null;
    overviewFileName: string | null;
    overviewFileType: string | null;
    overviewFileSize: string | null;
    transcoderMeta: string | null;
    transcoderJobId: string | null;
    courseTrend: elCourseTrend | null;
    learnDescription: string | null;
    requirements: string | null;
    level: string | null;
    tags: string | null;
    trending: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    assets: {
      __typename: "ModelCourseAssetsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
  owner: string | null;
  assetsSubs: {
    __typename: "ModelCourseAssetSubsConnection";
    items: Array<{
      __typename: "CourseAssetSubs";
      id: string;
      courseSubsId: string;
      assetId: string;
      userId: string;
      assetType: elAssetType;
      title: string | null;
      description: string | null;
      category: string | null;
      metaData: string | null;
      status: elSubStatus;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
      owner: string | null;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
};

export type CreateCourseAssetsMutation = {
  __typename: "CourseAssets";
  id: string;
  courseId: string;
  assetId: string | null;
  assetType: elAssetType;
  title: string;
  description: string;
  order: number | null;
  timeAprox: string | null;
  starred: boolean | null;
  important: boolean | null;
  showAsOverview: boolean | null;
  showAsFree: boolean | null;
  nextStep: string | null;
  prevStep: string | null;
  status: elStatus;
  updatedAt: string | null;
  createdAt: string | null;
  course: {
    __typename: "Course";
    id: string;
    title: string;
    description: string;
    category: {
      __typename: "CourseCategory";
      id: string;
      category: string;
      createdAt: string | null;
      updatedAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    };
    categoryId: string;
    coverImage: string | null;
    timeAprox: string;
    status: elStatus;
    coverImage1: string | null;
    coverImage2: string | null;
    coverImage3: string | null;
    courseType: CourseType | null;
    courseUrl: string | null;
    overviewSourceMedia: string | null;
    overviewFileName: string | null;
    overviewFileType: string | null;
    overviewFileSize: string | null;
    transcoderMeta: string | null;
    transcoderJobId: string | null;
    courseTrend: elCourseTrend | null;
    learnDescription: string | null;
    requirements: string | null;
    level: string | null;
    tags: string | null;
    trending: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    assets: {
      __typename: "ModelCourseAssetsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  topic: {
    __typename: "Content";
    id: string;
    courseId: string;
    contentType: ContentType;
    media: string | null;
    sourceMedia: string | null;
    fileName: string | null;
    fileType: string | null;
    fileSize: string | null;
    transcoderMeta: string | null;
    transcoderJobId: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  quiz: {
    __typename: "Quiz";
    id: string;
    courseId: string;
    questionsRandom: number | null;
    passingScore: number | null;
    noAttempts: number | null;
    score: number | null;
    labelWhenPassed: string | null;
    labelWhenFailed: string | null;
    questionFeedback: boolean | null;
    updatedAt: string | null;
    createdAt: string | null;
    status: elStatus | null;
    questions: {
      __typename: "ModelQuizQuestionConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  attachments: {
    __typename: "ModelCourseAssetsResourceConnection";
    items: Array<{
      __typename: "CourseAssetsResource";
      id: string;
      courseId: string;
      courseAssetId: string;
      description: string;
      contentType: ResourceType;
      sourceCode: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      status: elStatus;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type UpdateCourseAssetsMutation = {
  __typename: "CourseAssets";
  id: string;
  courseId: string;
  assetId: string | null;
  assetType: elAssetType;
  title: string;
  description: string;
  order: number | null;
  timeAprox: string | null;
  starred: boolean | null;
  important: boolean | null;
  showAsOverview: boolean | null;
  showAsFree: boolean | null;
  nextStep: string | null;
  prevStep: string | null;
  status: elStatus;
  updatedAt: string | null;
  createdAt: string | null;
  course: {
    __typename: "Course";
    id: string;
    title: string;
    description: string;
    category: {
      __typename: "CourseCategory";
      id: string;
      category: string;
      createdAt: string | null;
      updatedAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    };
    categoryId: string;
    coverImage: string | null;
    timeAprox: string;
    status: elStatus;
    coverImage1: string | null;
    coverImage2: string | null;
    coverImage3: string | null;
    courseType: CourseType | null;
    courseUrl: string | null;
    overviewSourceMedia: string | null;
    overviewFileName: string | null;
    overviewFileType: string | null;
    overviewFileSize: string | null;
    transcoderMeta: string | null;
    transcoderJobId: string | null;
    courseTrend: elCourseTrend | null;
    learnDescription: string | null;
    requirements: string | null;
    level: string | null;
    tags: string | null;
    trending: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    assets: {
      __typename: "ModelCourseAssetsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  topic: {
    __typename: "Content";
    id: string;
    courseId: string;
    contentType: ContentType;
    media: string | null;
    sourceMedia: string | null;
    fileName: string | null;
    fileType: string | null;
    fileSize: string | null;
    transcoderMeta: string | null;
    transcoderJobId: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  quiz: {
    __typename: "Quiz";
    id: string;
    courseId: string;
    questionsRandom: number | null;
    passingScore: number | null;
    noAttempts: number | null;
    score: number | null;
    labelWhenPassed: string | null;
    labelWhenFailed: string | null;
    questionFeedback: boolean | null;
    updatedAt: string | null;
    createdAt: string | null;
    status: elStatus | null;
    questions: {
      __typename: "ModelQuizQuestionConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  attachments: {
    __typename: "ModelCourseAssetsResourceConnection";
    items: Array<{
      __typename: "CourseAssetsResource";
      id: string;
      courseId: string;
      courseAssetId: string;
      description: string;
      contentType: ResourceType;
      sourceCode: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      status: elStatus;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type DeleteCourseAssetsMutation = {
  __typename: "CourseAssets";
  id: string;
  courseId: string;
  assetId: string | null;
  assetType: elAssetType;
  title: string;
  description: string;
  order: number | null;
  timeAprox: string | null;
  starred: boolean | null;
  important: boolean | null;
  showAsOverview: boolean | null;
  showAsFree: boolean | null;
  nextStep: string | null;
  prevStep: string | null;
  status: elStatus;
  updatedAt: string | null;
  createdAt: string | null;
  course: {
    __typename: "Course";
    id: string;
    title: string;
    description: string;
    category: {
      __typename: "CourseCategory";
      id: string;
      category: string;
      createdAt: string | null;
      updatedAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    };
    categoryId: string;
    coverImage: string | null;
    timeAprox: string;
    status: elStatus;
    coverImage1: string | null;
    coverImage2: string | null;
    coverImage3: string | null;
    courseType: CourseType | null;
    courseUrl: string | null;
    overviewSourceMedia: string | null;
    overviewFileName: string | null;
    overviewFileType: string | null;
    overviewFileSize: string | null;
    transcoderMeta: string | null;
    transcoderJobId: string | null;
    courseTrend: elCourseTrend | null;
    learnDescription: string | null;
    requirements: string | null;
    level: string | null;
    tags: string | null;
    trending: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    assets: {
      __typename: "ModelCourseAssetsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  topic: {
    __typename: "Content";
    id: string;
    courseId: string;
    contentType: ContentType;
    media: string | null;
    sourceMedia: string | null;
    fileName: string | null;
    fileType: string | null;
    fileSize: string | null;
    transcoderMeta: string | null;
    transcoderJobId: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  quiz: {
    __typename: "Quiz";
    id: string;
    courseId: string;
    questionsRandom: number | null;
    passingScore: number | null;
    noAttempts: number | null;
    score: number | null;
    labelWhenPassed: string | null;
    labelWhenFailed: string | null;
    questionFeedback: boolean | null;
    updatedAt: string | null;
    createdAt: string | null;
    status: elStatus | null;
    questions: {
      __typename: "ModelQuizQuestionConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  attachments: {
    __typename: "ModelCourseAssetsResourceConnection";
    items: Array<{
      __typename: "CourseAssetsResource";
      id: string;
      courseId: string;
      courseAssetId: string;
      description: string;
      contentType: ResourceType;
      sourceCode: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      status: elStatus;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type CreateQuizMutation = {
  __typename: "Quiz";
  id: string;
  courseId: string;
  questionsRandom: number | null;
  passingScore: number | null;
  noAttempts: number | null;
  score: number | null;
  labelWhenPassed: string | null;
  labelWhenFailed: string | null;
  questionFeedback: boolean | null;
  updatedAt: string | null;
  createdAt: string | null;
  status: elStatus | null;
  questions: {
    __typename: "ModelQuizQuestionConnection";
    items: Array<{
      __typename: "QuizQuestion";
      id: string;
      quizContentId: string;
      order: number | null;
      title: string | null;
      qType: elQuestionType;
      labelCorrectMessage: string | null;
      labelIncorrectMessage: string | null;
      questionFeedBack: string | null;
      media: string | null;
      sourceMedia: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type UpdateQuizMutation = {
  __typename: "Quiz";
  id: string;
  courseId: string;
  questionsRandom: number | null;
  passingScore: number | null;
  noAttempts: number | null;
  score: number | null;
  labelWhenPassed: string | null;
  labelWhenFailed: string | null;
  questionFeedback: boolean | null;
  updatedAt: string | null;
  createdAt: string | null;
  status: elStatus | null;
  questions: {
    __typename: "ModelQuizQuestionConnection";
    items: Array<{
      __typename: "QuizQuestion";
      id: string;
      quizContentId: string;
      order: number | null;
      title: string | null;
      qType: elQuestionType;
      labelCorrectMessage: string | null;
      labelIncorrectMessage: string | null;
      questionFeedBack: string | null;
      media: string | null;
      sourceMedia: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type DeleteQuizMutation = {
  __typename: "Quiz";
  id: string;
  courseId: string;
  questionsRandom: number | null;
  passingScore: number | null;
  noAttempts: number | null;
  score: number | null;
  labelWhenPassed: string | null;
  labelWhenFailed: string | null;
  questionFeedback: boolean | null;
  updatedAt: string | null;
  createdAt: string | null;
  status: elStatus | null;
  questions: {
    __typename: "ModelQuizQuestionConnection";
    items: Array<{
      __typename: "QuizQuestion";
      id: string;
      quizContentId: string;
      order: number | null;
      title: string | null;
      qType: elQuestionType;
      labelCorrectMessage: string | null;
      labelIncorrectMessage: string | null;
      questionFeedBack: string | null;
      media: string | null;
      sourceMedia: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type CreateQuizQuestionMutation = {
  __typename: "QuizQuestion";
  id: string;
  quizContentId: string;
  quiz: {
    __typename: "Quiz";
    id: string;
    courseId: string;
    questionsRandom: number | null;
    passingScore: number | null;
    noAttempts: number | null;
    score: number | null;
    labelWhenPassed: string | null;
    labelWhenFailed: string | null;
    questionFeedback: boolean | null;
    updatedAt: string | null;
    createdAt: string | null;
    status: elStatus | null;
    questions: {
      __typename: "ModelQuizQuestionConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  order: number | null;
  title: string | null;
  qType: elQuestionType;
  labelCorrectMessage: string | null;
  labelIncorrectMessage: string | null;
  questionFeedBack: string | null;
  media: string | null;
  sourceMedia: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  updatedAt: string | null;
  createdAt: string | null;
  status: elStatus | null;
  questionAnswers: {
    __typename: "ModelQuestionAnswersConnection";
    items: Array<{
      __typename: "QuestionAnswers";
      id: string;
      quizQuestionId: string;
      order: number | null;
      isCorrect: boolean | null;
      title: string | null;
      answer: string | null;
      answerFeedBack: string | null;
      qType: elQuestionType;
      media: string | null;
      sourceMedia: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      status: elStatus | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type UpdateQuizQuestionMutation = {
  __typename: "QuizQuestion";
  id: string;
  quizContentId: string;
  quiz: {
    __typename: "Quiz";
    id: string;
    courseId: string;
    questionsRandom: number | null;
    passingScore: number | null;
    noAttempts: number | null;
    score: number | null;
    labelWhenPassed: string | null;
    labelWhenFailed: string | null;
    questionFeedback: boolean | null;
    updatedAt: string | null;
    createdAt: string | null;
    status: elStatus | null;
    questions: {
      __typename: "ModelQuizQuestionConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  order: number | null;
  title: string | null;
  qType: elQuestionType;
  labelCorrectMessage: string | null;
  labelIncorrectMessage: string | null;
  questionFeedBack: string | null;
  media: string | null;
  sourceMedia: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  updatedAt: string | null;
  createdAt: string | null;
  status: elStatus | null;
  questionAnswers: {
    __typename: "ModelQuestionAnswersConnection";
    items: Array<{
      __typename: "QuestionAnswers";
      id: string;
      quizQuestionId: string;
      order: number | null;
      isCorrect: boolean | null;
      title: string | null;
      answer: string | null;
      answerFeedBack: string | null;
      qType: elQuestionType;
      media: string | null;
      sourceMedia: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      status: elStatus | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type DeleteQuizQuestionMutation = {
  __typename: "QuizQuestion";
  id: string;
  quizContentId: string;
  quiz: {
    __typename: "Quiz";
    id: string;
    courseId: string;
    questionsRandom: number | null;
    passingScore: number | null;
    noAttempts: number | null;
    score: number | null;
    labelWhenPassed: string | null;
    labelWhenFailed: string | null;
    questionFeedback: boolean | null;
    updatedAt: string | null;
    createdAt: string | null;
    status: elStatus | null;
    questions: {
      __typename: "ModelQuizQuestionConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  order: number | null;
  title: string | null;
  qType: elQuestionType;
  labelCorrectMessage: string | null;
  labelIncorrectMessage: string | null;
  questionFeedBack: string | null;
  media: string | null;
  sourceMedia: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  updatedAt: string | null;
  createdAt: string | null;
  status: elStatus | null;
  questionAnswers: {
    __typename: "ModelQuestionAnswersConnection";
    items: Array<{
      __typename: "QuestionAnswers";
      id: string;
      quizQuestionId: string;
      order: number | null;
      isCorrect: boolean | null;
      title: string | null;
      answer: string | null;
      answerFeedBack: string | null;
      qType: elQuestionType;
      media: string | null;
      sourceMedia: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      status: elStatus | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type CreateQuestionAnswersMutation = {
  __typename: "QuestionAnswers";
  id: string;
  quizQuestionId: string;
  question: {
    __typename: "QuizQuestion";
    id: string;
    quizContentId: string;
    quiz: {
      __typename: "Quiz";
      id: string;
      courseId: string;
      questionsRandom: number | null;
      passingScore: number | null;
      noAttempts: number | null;
      score: number | null;
      labelWhenPassed: string | null;
      labelWhenFailed: string | null;
      questionFeedback: boolean | null;
      updatedAt: string | null;
      createdAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    order: number | null;
    title: string | null;
    qType: elQuestionType;
    labelCorrectMessage: string | null;
    labelIncorrectMessage: string | null;
    questionFeedBack: string | null;
    media: string | null;
    sourceMedia: string | null;
    fileName: string | null;
    fileType: string | null;
    fileSize: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    status: elStatus | null;
    questionAnswers: {
      __typename: "ModelQuestionAnswersConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  order: number | null;
  isCorrect: boolean | null;
  title: string | null;
  answer: string | null;
  answerFeedBack: string | null;
  qType: elQuestionType;
  media: string | null;
  sourceMedia: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  status: elStatus | null;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type UpdateQuestionAnswersMutation = {
  __typename: "QuestionAnswers";
  id: string;
  quizQuestionId: string;
  question: {
    __typename: "QuizQuestion";
    id: string;
    quizContentId: string;
    quiz: {
      __typename: "Quiz";
      id: string;
      courseId: string;
      questionsRandom: number | null;
      passingScore: number | null;
      noAttempts: number | null;
      score: number | null;
      labelWhenPassed: string | null;
      labelWhenFailed: string | null;
      questionFeedback: boolean | null;
      updatedAt: string | null;
      createdAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    order: number | null;
    title: string | null;
    qType: elQuestionType;
    labelCorrectMessage: string | null;
    labelIncorrectMessage: string | null;
    questionFeedBack: string | null;
    media: string | null;
    sourceMedia: string | null;
    fileName: string | null;
    fileType: string | null;
    fileSize: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    status: elStatus | null;
    questionAnswers: {
      __typename: "ModelQuestionAnswersConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  order: number | null;
  isCorrect: boolean | null;
  title: string | null;
  answer: string | null;
  answerFeedBack: string | null;
  qType: elQuestionType;
  media: string | null;
  sourceMedia: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  status: elStatus | null;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type DeleteQuestionAnswersMutation = {
  __typename: "QuestionAnswers";
  id: string;
  quizQuestionId: string;
  question: {
    __typename: "QuizQuestion";
    id: string;
    quizContentId: string;
    quiz: {
      __typename: "Quiz";
      id: string;
      courseId: string;
      questionsRandom: number | null;
      passingScore: number | null;
      noAttempts: number | null;
      score: number | null;
      labelWhenPassed: string | null;
      labelWhenFailed: string | null;
      questionFeedback: boolean | null;
      updatedAt: string | null;
      createdAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    order: number | null;
    title: string | null;
    qType: elQuestionType;
    labelCorrectMessage: string | null;
    labelIncorrectMessage: string | null;
    questionFeedBack: string | null;
    media: string | null;
    sourceMedia: string | null;
    fileName: string | null;
    fileType: string | null;
    fileSize: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    status: elStatus | null;
    questionAnswers: {
      __typename: "ModelQuestionAnswersConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  order: number | null;
  isCorrect: boolean | null;
  title: string | null;
  answer: string | null;
  answerFeedBack: string | null;
  qType: elQuestionType;
  media: string | null;
  sourceMedia: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  status: elStatus | null;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type CreateCourseTagsMutation = {
  __typename: "CourseTags";
  id: string;
  tag: string;
  createdAt: string | null;
  updatedAt: string | null;
  status: elStatus | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type UpdateCourseTagsMutation = {
  __typename: "CourseTags";
  id: string;
  tag: string;
  createdAt: string | null;
  updatedAt: string | null;
  status: elStatus | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type DeleteCourseTagsMutation = {
  __typename: "CourseTags";
  id: string;
  tag: string;
  createdAt: string | null;
  updatedAt: string | null;
  status: elStatus | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type CreateCourseAssetsResourceMutation = {
  __typename: "CourseAssetsResource";
  id: string;
  courseId: string;
  courseAssetId: string;
  description: string;
  contentType: ResourceType;
  sourceCode: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  status: elStatus;
  updatedAt: string | null;
  createdAt: string | null;
  courseAsset: {
    __typename: "CourseAssets";
    id: string;
    courseId: string;
    assetId: string | null;
    assetType: elAssetType;
    title: string;
    description: string;
    order: number | null;
    timeAprox: string | null;
    starred: boolean | null;
    important: boolean | null;
    showAsOverview: boolean | null;
    showAsFree: boolean | null;
    nextStep: string | null;
    prevStep: string | null;
    status: elStatus;
    updatedAt: string | null;
    createdAt: string | null;
    course: {
      __typename: "Course";
      id: string;
      title: string;
      description: string;
      categoryId: string;
      coverImage: string | null;
      timeAprox: string;
      status: elStatus;
      coverImage1: string | null;
      coverImage2: string | null;
      coverImage3: string | null;
      courseType: CourseType | null;
      courseUrl: string | null;
      overviewSourceMedia: string | null;
      overviewFileName: string | null;
      overviewFileType: string | null;
      overviewFileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      courseTrend: elCourseTrend | null;
      learnDescription: string | null;
      requirements: string | null;
      level: string | null;
      tags: string | null;
      trending: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    topic: {
      __typename: "Content";
      id: string;
      courseId: string;
      contentType: ContentType;
      media: string | null;
      sourceMedia: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    quiz: {
      __typename: "Quiz";
      id: string;
      courseId: string;
      questionsRandom: number | null;
      passingScore: number | null;
      noAttempts: number | null;
      score: number | null;
      labelWhenPassed: string | null;
      labelWhenFailed: string | null;
      questionFeedback: boolean | null;
      updatedAt: string | null;
      createdAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    attachments: {
      __typename: "ModelCourseAssetsResourceConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type UpdateCourseAssetsResourceMutation = {
  __typename: "CourseAssetsResource";
  id: string;
  courseId: string;
  courseAssetId: string;
  description: string;
  contentType: ResourceType;
  sourceCode: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  status: elStatus;
  updatedAt: string | null;
  createdAt: string | null;
  courseAsset: {
    __typename: "CourseAssets";
    id: string;
    courseId: string;
    assetId: string | null;
    assetType: elAssetType;
    title: string;
    description: string;
    order: number | null;
    timeAprox: string | null;
    starred: boolean | null;
    important: boolean | null;
    showAsOverview: boolean | null;
    showAsFree: boolean | null;
    nextStep: string | null;
    prevStep: string | null;
    status: elStatus;
    updatedAt: string | null;
    createdAt: string | null;
    course: {
      __typename: "Course";
      id: string;
      title: string;
      description: string;
      categoryId: string;
      coverImage: string | null;
      timeAprox: string;
      status: elStatus;
      coverImage1: string | null;
      coverImage2: string | null;
      coverImage3: string | null;
      courseType: CourseType | null;
      courseUrl: string | null;
      overviewSourceMedia: string | null;
      overviewFileName: string | null;
      overviewFileType: string | null;
      overviewFileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      courseTrend: elCourseTrend | null;
      learnDescription: string | null;
      requirements: string | null;
      level: string | null;
      tags: string | null;
      trending: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    topic: {
      __typename: "Content";
      id: string;
      courseId: string;
      contentType: ContentType;
      media: string | null;
      sourceMedia: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    quiz: {
      __typename: "Quiz";
      id: string;
      courseId: string;
      questionsRandom: number | null;
      passingScore: number | null;
      noAttempts: number | null;
      score: number | null;
      labelWhenPassed: string | null;
      labelWhenFailed: string | null;
      questionFeedback: boolean | null;
      updatedAt: string | null;
      createdAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    attachments: {
      __typename: "ModelCourseAssetsResourceConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type DeleteCourseAssetsResourceMutation = {
  __typename: "CourseAssetsResource";
  id: string;
  courseId: string;
  courseAssetId: string;
  description: string;
  contentType: ResourceType;
  sourceCode: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  status: elStatus;
  updatedAt: string | null;
  createdAt: string | null;
  courseAsset: {
    __typename: "CourseAssets";
    id: string;
    courseId: string;
    assetId: string | null;
    assetType: elAssetType;
    title: string;
    description: string;
    order: number | null;
    timeAprox: string | null;
    starred: boolean | null;
    important: boolean | null;
    showAsOverview: boolean | null;
    showAsFree: boolean | null;
    nextStep: string | null;
    prevStep: string | null;
    status: elStatus;
    updatedAt: string | null;
    createdAt: string | null;
    course: {
      __typename: "Course";
      id: string;
      title: string;
      description: string;
      categoryId: string;
      coverImage: string | null;
      timeAprox: string;
      status: elStatus;
      coverImage1: string | null;
      coverImage2: string | null;
      coverImage3: string | null;
      courseType: CourseType | null;
      courseUrl: string | null;
      overviewSourceMedia: string | null;
      overviewFileName: string | null;
      overviewFileType: string | null;
      overviewFileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      courseTrend: elCourseTrend | null;
      learnDescription: string | null;
      requirements: string | null;
      level: string | null;
      tags: string | null;
      trending: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    topic: {
      __typename: "Content";
      id: string;
      courseId: string;
      contentType: ContentType;
      media: string | null;
      sourceMedia: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    quiz: {
      __typename: "Quiz";
      id: string;
      courseId: string;
      questionsRandom: number | null;
      passingScore: number | null;
      noAttempts: number | null;
      score: number | null;
      labelWhenPassed: string | null;
      labelWhenFailed: string | null;
      questionFeedback: boolean | null;
      updatedAt: string | null;
      createdAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    attachments: {
      __typename: "ModelCourseAssetsResourceConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type CreateCourseAssetSubsMutation = {
  __typename: "CourseAssetSubs";
  id: string;
  courseSubsId: string;
  assetId: string;
  userId: string;
  assetType: elAssetType;
  title: string | null;
  description: string | null;
  category: string | null;
  metaData: string | null;
  status: elSubStatus;
  asset: {
    __typename: "CourseAssets";
    id: string;
    courseId: string;
    assetId: string | null;
    assetType: elAssetType;
    title: string;
    description: string;
    order: number | null;
    timeAprox: string | null;
    starred: boolean | null;
    important: boolean | null;
    showAsOverview: boolean | null;
    showAsFree: boolean | null;
    nextStep: string | null;
    prevStep: string | null;
    status: elStatus;
    updatedAt: string | null;
    createdAt: string | null;
    course: {
      __typename: "Course";
      id: string;
      title: string;
      description: string;
      categoryId: string;
      coverImage: string | null;
      timeAprox: string;
      status: elStatus;
      coverImage1: string | null;
      coverImage2: string | null;
      coverImage3: string | null;
      courseType: CourseType | null;
      courseUrl: string | null;
      overviewSourceMedia: string | null;
      overviewFileName: string | null;
      overviewFileType: string | null;
      overviewFileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      courseTrend: elCourseTrend | null;
      learnDescription: string | null;
      requirements: string | null;
      level: string | null;
      tags: string | null;
      trending: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    topic: {
      __typename: "Content";
      id: string;
      courseId: string;
      contentType: ContentType;
      media: string | null;
      sourceMedia: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    quiz: {
      __typename: "Quiz";
      id: string;
      courseId: string;
      questionsRandom: number | null;
      passingScore: number | null;
      noAttempts: number | null;
      score: number | null;
      labelWhenPassed: string | null;
      labelWhenFailed: string | null;
      questionFeedback: boolean | null;
      updatedAt: string | null;
      createdAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    attachments: {
      __typename: "ModelCourseAssetsResourceConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
  assetsSubs: {
    __typename: "CourseSubs";
    id: string;
    courseId: string;
    userId: string;
    title: string | null;
    description: string | null;
    category: string | null;
    level: string | null;
    tags: string | null;
    metaData: string | null;
    status: elSubStatus;
    course: {
      __typename: "Course";
      id: string;
      title: string;
      description: string;
      categoryId: string;
      coverImage: string | null;
      timeAprox: string;
      status: elStatus;
      coverImage1: string | null;
      coverImage2: string | null;
      coverImage3: string | null;
      courseType: CourseType | null;
      courseUrl: string | null;
      overviewSourceMedia: string | null;
      overviewFileName: string | null;
      overviewFileType: string | null;
      overviewFileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      courseTrend: elCourseTrend | null;
      learnDescription: string | null;
      requirements: string | null;
      level: string | null;
      tags: string | null;
      trending: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
    owner: string | null;
    assetsSubs: {
      __typename: "ModelCourseAssetSubsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
  } | null;
  owner: string | null;
};

export type UpdateCourseAssetSubsMutation = {
  __typename: "CourseAssetSubs";
  id: string;
  courseSubsId: string;
  assetId: string;
  userId: string;
  assetType: elAssetType;
  title: string | null;
  description: string | null;
  category: string | null;
  metaData: string | null;
  status: elSubStatus;
  asset: {
    __typename: "CourseAssets";
    id: string;
    courseId: string;
    assetId: string | null;
    assetType: elAssetType;
    title: string;
    description: string;
    order: number | null;
    timeAprox: string | null;
    starred: boolean | null;
    important: boolean | null;
    showAsOverview: boolean | null;
    showAsFree: boolean | null;
    nextStep: string | null;
    prevStep: string | null;
    status: elStatus;
    updatedAt: string | null;
    createdAt: string | null;
    course: {
      __typename: "Course";
      id: string;
      title: string;
      description: string;
      categoryId: string;
      coverImage: string | null;
      timeAprox: string;
      status: elStatus;
      coverImage1: string | null;
      coverImage2: string | null;
      coverImage3: string | null;
      courseType: CourseType | null;
      courseUrl: string | null;
      overviewSourceMedia: string | null;
      overviewFileName: string | null;
      overviewFileType: string | null;
      overviewFileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      courseTrend: elCourseTrend | null;
      learnDescription: string | null;
      requirements: string | null;
      level: string | null;
      tags: string | null;
      trending: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    topic: {
      __typename: "Content";
      id: string;
      courseId: string;
      contentType: ContentType;
      media: string | null;
      sourceMedia: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    quiz: {
      __typename: "Quiz";
      id: string;
      courseId: string;
      questionsRandom: number | null;
      passingScore: number | null;
      noAttempts: number | null;
      score: number | null;
      labelWhenPassed: string | null;
      labelWhenFailed: string | null;
      questionFeedback: boolean | null;
      updatedAt: string | null;
      createdAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    attachments: {
      __typename: "ModelCourseAssetsResourceConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
  assetsSubs: {
    __typename: "CourseSubs";
    id: string;
    courseId: string;
    userId: string;
    title: string | null;
    description: string | null;
    category: string | null;
    level: string | null;
    tags: string | null;
    metaData: string | null;
    status: elSubStatus;
    course: {
      __typename: "Course";
      id: string;
      title: string;
      description: string;
      categoryId: string;
      coverImage: string | null;
      timeAprox: string;
      status: elStatus;
      coverImage1: string | null;
      coverImage2: string | null;
      coverImage3: string | null;
      courseType: CourseType | null;
      courseUrl: string | null;
      overviewSourceMedia: string | null;
      overviewFileName: string | null;
      overviewFileType: string | null;
      overviewFileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      courseTrend: elCourseTrend | null;
      learnDescription: string | null;
      requirements: string | null;
      level: string | null;
      tags: string | null;
      trending: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
    owner: string | null;
    assetsSubs: {
      __typename: "ModelCourseAssetSubsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
  } | null;
  owner: string | null;
};

export type DeleteCourseAssetSubsMutation = {
  __typename: "CourseAssetSubs";
  id: string;
  courseSubsId: string;
  assetId: string;
  userId: string;
  assetType: elAssetType;
  title: string | null;
  description: string | null;
  category: string | null;
  metaData: string | null;
  status: elSubStatus;
  asset: {
    __typename: "CourseAssets";
    id: string;
    courseId: string;
    assetId: string | null;
    assetType: elAssetType;
    title: string;
    description: string;
    order: number | null;
    timeAprox: string | null;
    starred: boolean | null;
    important: boolean | null;
    showAsOverview: boolean | null;
    showAsFree: boolean | null;
    nextStep: string | null;
    prevStep: string | null;
    status: elStatus;
    updatedAt: string | null;
    createdAt: string | null;
    course: {
      __typename: "Course";
      id: string;
      title: string;
      description: string;
      categoryId: string;
      coverImage: string | null;
      timeAprox: string;
      status: elStatus;
      coverImage1: string | null;
      coverImage2: string | null;
      coverImage3: string | null;
      courseType: CourseType | null;
      courseUrl: string | null;
      overviewSourceMedia: string | null;
      overviewFileName: string | null;
      overviewFileType: string | null;
      overviewFileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      courseTrend: elCourseTrend | null;
      learnDescription: string | null;
      requirements: string | null;
      level: string | null;
      tags: string | null;
      trending: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    topic: {
      __typename: "Content";
      id: string;
      courseId: string;
      contentType: ContentType;
      media: string | null;
      sourceMedia: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    quiz: {
      __typename: "Quiz";
      id: string;
      courseId: string;
      questionsRandom: number | null;
      passingScore: number | null;
      noAttempts: number | null;
      score: number | null;
      labelWhenPassed: string | null;
      labelWhenFailed: string | null;
      questionFeedback: boolean | null;
      updatedAt: string | null;
      createdAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    attachments: {
      __typename: "ModelCourseAssetsResourceConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
  assetsSubs: {
    __typename: "CourseSubs";
    id: string;
    courseId: string;
    userId: string;
    title: string | null;
    description: string | null;
    category: string | null;
    level: string | null;
    tags: string | null;
    metaData: string | null;
    status: elSubStatus;
    course: {
      __typename: "Course";
      id: string;
      title: string;
      description: string;
      categoryId: string;
      coverImage: string | null;
      timeAprox: string;
      status: elStatus;
      coverImage1: string | null;
      coverImage2: string | null;
      coverImage3: string | null;
      courseType: CourseType | null;
      courseUrl: string | null;
      overviewSourceMedia: string | null;
      overviewFileName: string | null;
      overviewFileType: string | null;
      overviewFileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      courseTrend: elCourseTrend | null;
      learnDescription: string | null;
      requirements: string | null;
      level: string | null;
      tags: string | null;
      trending: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
    owner: string | null;
    assetsSubs: {
      __typename: "ModelCourseAssetSubsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
  } | null;
  owner: string | null;
};

export type CreateContactMutation = {
  __typename: "Contact";
  id: string;
  userID: string;
  syncID: string | null;
  contactType: ContactType | null;
  contactName: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phone: string;
  avatar: string | null;
  avatarType: string | null;
  jobTitle: string | null;
  birthday: string | null;
  starred: boolean | null;
  frequent: boolean | null;
  status: string;
  pbProfileOptions: string | null;
  userRelatedId: string | null;
  notes: string | null;
  businessId: string | null;
  contactSince: string | null;
  updatedAt: string | null;
  createdAt: string | null;
  contactRelId: string | null;
  contactRel: {
    __typename: "Contact";
    id: string;
    userID: string;
    syncID: string | null;
    contactType: ContactType | null;
    contactName: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    phone: string;
    avatar: string | null;
    avatarType: string | null;
    jobTitle: string | null;
    birthday: string | null;
    starred: boolean | null;
    frequent: boolean | null;
    status: string;
    pbProfileOptions: string | null;
    userRelatedId: string | null;
    notes: string | null;
    businessId: string | null;
    contactSince: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    contactRelId: string | null;
    contactRel: {
      __typename: "Contact";
      id: string;
      userID: string;
      syncID: string | null;
      contactType: ContactType | null;
      contactName: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      phone: string;
      avatar: string | null;
      avatarType: string | null;
      jobTitle: string | null;
      birthday: string | null;
      starred: boolean | null;
      frequent: boolean | null;
      status: string;
      pbProfileOptions: string | null;
      userRelatedId: string | null;
      notes: string | null;
      businessId: string | null;
      contactSince: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      contactRelId: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    addresses: {
      __typename: "ModelContactAddressConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    connects: {
      __typename: "ModelConnectsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    licence: {
      __typename: "ModelMembershipConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  addresses: {
    __typename: "ModelContactAddressConnection";
    items: Array<{
      __typename: "ContactAddress";
      id: string;
      contactId: string;
      addressType: string | null;
      street: string | null;
      street1: string | null;
      zipCode: string | null;
      city: string | null;
      state: string | null;
      Country: string | null;
      status: string;
      updatedAt: string | null;
      createdAt: string | null;
      lat: string | null;
      lng: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  connects: {
    __typename: "ModelConnectsConnection";
    items: Array<{
      __typename: "Connects";
      id: string;
      contactId: string;
      connsContactId: string | null;
      contactType: string | null;
      contactName: string | null;
      status: string;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  licence: {
    __typename: "ModelMembershipConnection";
    items: Array<{
      __typename: "Membership";
      id: string;
      contactId: string;
      noLicenses: number | null;
      noClaimed: number | null;
      groupId: string | null;
      group: string | null;
      prefix: string | null;
      status: string;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type UpdateContactMutation = {
  __typename: "Contact";
  id: string;
  userID: string;
  syncID: string | null;
  contactType: ContactType | null;
  contactName: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phone: string;
  avatar: string | null;
  avatarType: string | null;
  jobTitle: string | null;
  birthday: string | null;
  starred: boolean | null;
  frequent: boolean | null;
  status: string;
  pbProfileOptions: string | null;
  userRelatedId: string | null;
  notes: string | null;
  businessId: string | null;
  contactSince: string | null;
  updatedAt: string | null;
  createdAt: string | null;
  contactRelId: string | null;
  contactRel: {
    __typename: "Contact";
    id: string;
    userID: string;
    syncID: string | null;
    contactType: ContactType | null;
    contactName: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    phone: string;
    avatar: string | null;
    avatarType: string | null;
    jobTitle: string | null;
    birthday: string | null;
    starred: boolean | null;
    frequent: boolean | null;
    status: string;
    pbProfileOptions: string | null;
    userRelatedId: string | null;
    notes: string | null;
    businessId: string | null;
    contactSince: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    contactRelId: string | null;
    contactRel: {
      __typename: "Contact";
      id: string;
      userID: string;
      syncID: string | null;
      contactType: ContactType | null;
      contactName: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      phone: string;
      avatar: string | null;
      avatarType: string | null;
      jobTitle: string | null;
      birthday: string | null;
      starred: boolean | null;
      frequent: boolean | null;
      status: string;
      pbProfileOptions: string | null;
      userRelatedId: string | null;
      notes: string | null;
      businessId: string | null;
      contactSince: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      contactRelId: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    addresses: {
      __typename: "ModelContactAddressConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    connects: {
      __typename: "ModelConnectsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    licence: {
      __typename: "ModelMembershipConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  addresses: {
    __typename: "ModelContactAddressConnection";
    items: Array<{
      __typename: "ContactAddress";
      id: string;
      contactId: string;
      addressType: string | null;
      street: string | null;
      street1: string | null;
      zipCode: string | null;
      city: string | null;
      state: string | null;
      Country: string | null;
      status: string;
      updatedAt: string | null;
      createdAt: string | null;
      lat: string | null;
      lng: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  connects: {
    __typename: "ModelConnectsConnection";
    items: Array<{
      __typename: "Connects";
      id: string;
      contactId: string;
      connsContactId: string | null;
      contactType: string | null;
      contactName: string | null;
      status: string;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  licence: {
    __typename: "ModelMembershipConnection";
    items: Array<{
      __typename: "Membership";
      id: string;
      contactId: string;
      noLicenses: number | null;
      noClaimed: number | null;
      groupId: string | null;
      group: string | null;
      prefix: string | null;
      status: string;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type DeleteContactMutation = {
  __typename: "Contact";
  id: string;
  userID: string;
  syncID: string | null;
  contactType: ContactType | null;
  contactName: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phone: string;
  avatar: string | null;
  avatarType: string | null;
  jobTitle: string | null;
  birthday: string | null;
  starred: boolean | null;
  frequent: boolean | null;
  status: string;
  pbProfileOptions: string | null;
  userRelatedId: string | null;
  notes: string | null;
  businessId: string | null;
  contactSince: string | null;
  updatedAt: string | null;
  createdAt: string | null;
  contactRelId: string | null;
  contactRel: {
    __typename: "Contact";
    id: string;
    userID: string;
    syncID: string | null;
    contactType: ContactType | null;
    contactName: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    phone: string;
    avatar: string | null;
    avatarType: string | null;
    jobTitle: string | null;
    birthday: string | null;
    starred: boolean | null;
    frequent: boolean | null;
    status: string;
    pbProfileOptions: string | null;
    userRelatedId: string | null;
    notes: string | null;
    businessId: string | null;
    contactSince: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    contactRelId: string | null;
    contactRel: {
      __typename: "Contact";
      id: string;
      userID: string;
      syncID: string | null;
      contactType: ContactType | null;
      contactName: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      phone: string;
      avatar: string | null;
      avatarType: string | null;
      jobTitle: string | null;
      birthday: string | null;
      starred: boolean | null;
      frequent: boolean | null;
      status: string;
      pbProfileOptions: string | null;
      userRelatedId: string | null;
      notes: string | null;
      businessId: string | null;
      contactSince: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      contactRelId: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    addresses: {
      __typename: "ModelContactAddressConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    connects: {
      __typename: "ModelConnectsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    licence: {
      __typename: "ModelMembershipConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  addresses: {
    __typename: "ModelContactAddressConnection";
    items: Array<{
      __typename: "ContactAddress";
      id: string;
      contactId: string;
      addressType: string | null;
      street: string | null;
      street1: string | null;
      zipCode: string | null;
      city: string | null;
      state: string | null;
      Country: string | null;
      status: string;
      updatedAt: string | null;
      createdAt: string | null;
      lat: string | null;
      lng: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  connects: {
    __typename: "ModelConnectsConnection";
    items: Array<{
      __typename: "Connects";
      id: string;
      contactId: string;
      connsContactId: string | null;
      contactType: string | null;
      contactName: string | null;
      status: string;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  licence: {
    __typename: "ModelMembershipConnection";
    items: Array<{
      __typename: "Membership";
      id: string;
      contactId: string;
      noLicenses: number | null;
      noClaimed: number | null;
      groupId: string | null;
      group: string | null;
      prefix: string | null;
      status: string;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type CreateConnectsMutation = {
  __typename: "Connects";
  id: string;
  contactId: string;
  connsContactId: string | null;
  contactType: string | null;
  contactName: string | null;
  status: string;
  updatedAt: string | null;
  createdAt: string | null;
  contact: {
    __typename: "Contact";
    id: string;
    userID: string;
    syncID: string | null;
    contactType: ContactType | null;
    contactName: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    phone: string;
    avatar: string | null;
    avatarType: string | null;
    jobTitle: string | null;
    birthday: string | null;
    starred: boolean | null;
    frequent: boolean | null;
    status: string;
    pbProfileOptions: string | null;
    userRelatedId: string | null;
    notes: string | null;
    businessId: string | null;
    contactSince: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    contactRelId: string | null;
    contactRel: {
      __typename: "Contact";
      id: string;
      userID: string;
      syncID: string | null;
      contactType: ContactType | null;
      contactName: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      phone: string;
      avatar: string | null;
      avatarType: string | null;
      jobTitle: string | null;
      birthday: string | null;
      starred: boolean | null;
      frequent: boolean | null;
      status: string;
      pbProfileOptions: string | null;
      userRelatedId: string | null;
      notes: string | null;
      businessId: string | null;
      contactSince: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      contactRelId: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    addresses: {
      __typename: "ModelContactAddressConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    connects: {
      __typename: "ModelConnectsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    licence: {
      __typename: "ModelMembershipConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type UpdateConnectsMutation = {
  __typename: "Connects";
  id: string;
  contactId: string;
  connsContactId: string | null;
  contactType: string | null;
  contactName: string | null;
  status: string;
  updatedAt: string | null;
  createdAt: string | null;
  contact: {
    __typename: "Contact";
    id: string;
    userID: string;
    syncID: string | null;
    contactType: ContactType | null;
    contactName: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    phone: string;
    avatar: string | null;
    avatarType: string | null;
    jobTitle: string | null;
    birthday: string | null;
    starred: boolean | null;
    frequent: boolean | null;
    status: string;
    pbProfileOptions: string | null;
    userRelatedId: string | null;
    notes: string | null;
    businessId: string | null;
    contactSince: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    contactRelId: string | null;
    contactRel: {
      __typename: "Contact";
      id: string;
      userID: string;
      syncID: string | null;
      contactType: ContactType | null;
      contactName: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      phone: string;
      avatar: string | null;
      avatarType: string | null;
      jobTitle: string | null;
      birthday: string | null;
      starred: boolean | null;
      frequent: boolean | null;
      status: string;
      pbProfileOptions: string | null;
      userRelatedId: string | null;
      notes: string | null;
      businessId: string | null;
      contactSince: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      contactRelId: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    addresses: {
      __typename: "ModelContactAddressConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    connects: {
      __typename: "ModelConnectsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    licence: {
      __typename: "ModelMembershipConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type DeleteConnectsMutation = {
  __typename: "Connects";
  id: string;
  contactId: string;
  connsContactId: string | null;
  contactType: string | null;
  contactName: string | null;
  status: string;
  updatedAt: string | null;
  createdAt: string | null;
  contact: {
    __typename: "Contact";
    id: string;
    userID: string;
    syncID: string | null;
    contactType: ContactType | null;
    contactName: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    phone: string;
    avatar: string | null;
    avatarType: string | null;
    jobTitle: string | null;
    birthday: string | null;
    starred: boolean | null;
    frequent: boolean | null;
    status: string;
    pbProfileOptions: string | null;
    userRelatedId: string | null;
    notes: string | null;
    businessId: string | null;
    contactSince: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    contactRelId: string | null;
    contactRel: {
      __typename: "Contact";
      id: string;
      userID: string;
      syncID: string | null;
      contactType: ContactType | null;
      contactName: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      phone: string;
      avatar: string | null;
      avatarType: string | null;
      jobTitle: string | null;
      birthday: string | null;
      starred: boolean | null;
      frequent: boolean | null;
      status: string;
      pbProfileOptions: string | null;
      userRelatedId: string | null;
      notes: string | null;
      businessId: string | null;
      contactSince: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      contactRelId: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    addresses: {
      __typename: "ModelContactAddressConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    connects: {
      __typename: "ModelConnectsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    licence: {
      __typename: "ModelMembershipConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type CreateContactAddressMutation = {
  __typename: "ContactAddress";
  id: string;
  contactId: string;
  addressType: string | null;
  street: string | null;
  street1: string | null;
  zipCode: string | null;
  city: string | null;
  state: string | null;
  Country: string | null;
  status: string;
  updatedAt: string | null;
  createdAt: string | null;
  lat: string | null;
  lng: string | null;
  contact: {
    __typename: "Contact";
    id: string;
    userID: string;
    syncID: string | null;
    contactType: ContactType | null;
    contactName: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    phone: string;
    avatar: string | null;
    avatarType: string | null;
    jobTitle: string | null;
    birthday: string | null;
    starred: boolean | null;
    frequent: boolean | null;
    status: string;
    pbProfileOptions: string | null;
    userRelatedId: string | null;
    notes: string | null;
    businessId: string | null;
    contactSince: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    contactRelId: string | null;
    contactRel: {
      __typename: "Contact";
      id: string;
      userID: string;
      syncID: string | null;
      contactType: ContactType | null;
      contactName: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      phone: string;
      avatar: string | null;
      avatarType: string | null;
      jobTitle: string | null;
      birthday: string | null;
      starred: boolean | null;
      frequent: boolean | null;
      status: string;
      pbProfileOptions: string | null;
      userRelatedId: string | null;
      notes: string | null;
      businessId: string | null;
      contactSince: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      contactRelId: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    addresses: {
      __typename: "ModelContactAddressConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    connects: {
      __typename: "ModelConnectsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    licence: {
      __typename: "ModelMembershipConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type UpdateContactAddressMutation = {
  __typename: "ContactAddress";
  id: string;
  contactId: string;
  addressType: string | null;
  street: string | null;
  street1: string | null;
  zipCode: string | null;
  city: string | null;
  state: string | null;
  Country: string | null;
  status: string;
  updatedAt: string | null;
  createdAt: string | null;
  lat: string | null;
  lng: string | null;
  contact: {
    __typename: "Contact";
    id: string;
    userID: string;
    syncID: string | null;
    contactType: ContactType | null;
    contactName: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    phone: string;
    avatar: string | null;
    avatarType: string | null;
    jobTitle: string | null;
    birthday: string | null;
    starred: boolean | null;
    frequent: boolean | null;
    status: string;
    pbProfileOptions: string | null;
    userRelatedId: string | null;
    notes: string | null;
    businessId: string | null;
    contactSince: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    contactRelId: string | null;
    contactRel: {
      __typename: "Contact";
      id: string;
      userID: string;
      syncID: string | null;
      contactType: ContactType | null;
      contactName: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      phone: string;
      avatar: string | null;
      avatarType: string | null;
      jobTitle: string | null;
      birthday: string | null;
      starred: boolean | null;
      frequent: boolean | null;
      status: string;
      pbProfileOptions: string | null;
      userRelatedId: string | null;
      notes: string | null;
      businessId: string | null;
      contactSince: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      contactRelId: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    addresses: {
      __typename: "ModelContactAddressConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    connects: {
      __typename: "ModelConnectsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    licence: {
      __typename: "ModelMembershipConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type DeleteContactAddressMutation = {
  __typename: "ContactAddress";
  id: string;
  contactId: string;
  addressType: string | null;
  street: string | null;
  street1: string | null;
  zipCode: string | null;
  city: string | null;
  state: string | null;
  Country: string | null;
  status: string;
  updatedAt: string | null;
  createdAt: string | null;
  lat: string | null;
  lng: string | null;
  contact: {
    __typename: "Contact";
    id: string;
    userID: string;
    syncID: string | null;
    contactType: ContactType | null;
    contactName: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    phone: string;
    avatar: string | null;
    avatarType: string | null;
    jobTitle: string | null;
    birthday: string | null;
    starred: boolean | null;
    frequent: boolean | null;
    status: string;
    pbProfileOptions: string | null;
    userRelatedId: string | null;
    notes: string | null;
    businessId: string | null;
    contactSince: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    contactRelId: string | null;
    contactRel: {
      __typename: "Contact";
      id: string;
      userID: string;
      syncID: string | null;
      contactType: ContactType | null;
      contactName: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      phone: string;
      avatar: string | null;
      avatarType: string | null;
      jobTitle: string | null;
      birthday: string | null;
      starred: boolean | null;
      frequent: boolean | null;
      status: string;
      pbProfileOptions: string | null;
      userRelatedId: string | null;
      notes: string | null;
      businessId: string | null;
      contactSince: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      contactRelId: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    addresses: {
      __typename: "ModelContactAddressConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    connects: {
      __typename: "ModelConnectsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    licence: {
      __typename: "ModelMembershipConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type CreateMembershipMutation = {
  __typename: "Membership";
  id: string;
  contactId: string;
  noLicenses: number | null;
  noClaimed: number | null;
  groupId: string | null;
  group: string | null;
  prefix: string | null;
  status: string;
  updatedAt: string | null;
  createdAt: string | null;
  org: {
    __typename: "Contact";
    id: string;
    userID: string;
    syncID: string | null;
    contactType: ContactType | null;
    contactName: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    phone: string;
    avatar: string | null;
    avatarType: string | null;
    jobTitle: string | null;
    birthday: string | null;
    starred: boolean | null;
    frequent: boolean | null;
    status: string;
    pbProfileOptions: string | null;
    userRelatedId: string | null;
    notes: string | null;
    businessId: string | null;
    contactSince: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    contactRelId: string | null;
    contactRel: {
      __typename: "Contact";
      id: string;
      userID: string;
      syncID: string | null;
      contactType: ContactType | null;
      contactName: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      phone: string;
      avatar: string | null;
      avatarType: string | null;
      jobTitle: string | null;
      birthday: string | null;
      starred: boolean | null;
      frequent: boolean | null;
      status: string;
      pbProfileOptions: string | null;
      userRelatedId: string | null;
      notes: string | null;
      businessId: string | null;
      contactSince: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      contactRelId: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    addresses: {
      __typename: "ModelContactAddressConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    connects: {
      __typename: "ModelConnectsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    licence: {
      __typename: "ModelMembershipConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type UpdateMembershipMutation = {
  __typename: "Membership";
  id: string;
  contactId: string;
  noLicenses: number | null;
  noClaimed: number | null;
  groupId: string | null;
  group: string | null;
  prefix: string | null;
  status: string;
  updatedAt: string | null;
  createdAt: string | null;
  org: {
    __typename: "Contact";
    id: string;
    userID: string;
    syncID: string | null;
    contactType: ContactType | null;
    contactName: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    phone: string;
    avatar: string | null;
    avatarType: string | null;
    jobTitle: string | null;
    birthday: string | null;
    starred: boolean | null;
    frequent: boolean | null;
    status: string;
    pbProfileOptions: string | null;
    userRelatedId: string | null;
    notes: string | null;
    businessId: string | null;
    contactSince: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    contactRelId: string | null;
    contactRel: {
      __typename: "Contact";
      id: string;
      userID: string;
      syncID: string | null;
      contactType: ContactType | null;
      contactName: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      phone: string;
      avatar: string | null;
      avatarType: string | null;
      jobTitle: string | null;
      birthday: string | null;
      starred: boolean | null;
      frequent: boolean | null;
      status: string;
      pbProfileOptions: string | null;
      userRelatedId: string | null;
      notes: string | null;
      businessId: string | null;
      contactSince: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      contactRelId: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    addresses: {
      __typename: "ModelContactAddressConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    connects: {
      __typename: "ModelConnectsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    licence: {
      __typename: "ModelMembershipConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type DeleteMembershipMutation = {
  __typename: "Membership";
  id: string;
  contactId: string;
  noLicenses: number | null;
  noClaimed: number | null;
  groupId: string | null;
  group: string | null;
  prefix: string | null;
  status: string;
  updatedAt: string | null;
  createdAt: string | null;
  org: {
    __typename: "Contact";
    id: string;
    userID: string;
    syncID: string | null;
    contactType: ContactType | null;
    contactName: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    phone: string;
    avatar: string | null;
    avatarType: string | null;
    jobTitle: string | null;
    birthday: string | null;
    starred: boolean | null;
    frequent: boolean | null;
    status: string;
    pbProfileOptions: string | null;
    userRelatedId: string | null;
    notes: string | null;
    businessId: string | null;
    contactSince: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    contactRelId: string | null;
    contactRel: {
      __typename: "Contact";
      id: string;
      userID: string;
      syncID: string | null;
      contactType: ContactType | null;
      contactName: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      phone: string;
      avatar: string | null;
      avatarType: string | null;
      jobTitle: string | null;
      birthday: string | null;
      starred: boolean | null;
      frequent: boolean | null;
      status: string;
      pbProfileOptions: string | null;
      userRelatedId: string | null;
      notes: string | null;
      businessId: string | null;
      contactSince: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      contactRelId: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    addresses: {
      __typename: "ModelContactAddressConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    connects: {
      __typename: "ModelConnectsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    licence: {
      __typename: "ModelMembershipConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type SyncSessionsQuery = {
  __typename: "ModelSessionConnection";
  items: Array<{
    __typename: "Session";
    id: string;
    userId: string;
    location: string | null;
    event: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type GetSessionQuery = {
  __typename: "Session";
  id: string;
  userId: string;
  location: string | null;
  event: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type ListSessionsQuery = {
  __typename: "ModelSessionConnection";
  items: Array<{
    __typename: "Session";
    id: string;
    userId: string;
    location: string | null;
    event: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SyncUserSettingsQuery = {
  __typename: "ModelUserSettingsConnection";
  items: Array<{
    __typename: "UserSettings";
    id: string;
    userId: string;
    pageSize: number | null;
    notiType: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type GetUserSettingsQuery = {
  __typename: "UserSettings";
  id: string;
  userId: string;
  pageSize: number | null;
  notiType: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type ListUserSettingssQuery = {
  __typename: "ModelUserSettingsConnection";
  items: Array<{
    __typename: "UserSettings";
    id: string;
    userId: string;
    pageSize: number | null;
    notiType: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SyncNotificationsQuery = {
  __typename: "ModelNotificationConnection";
  items: Array<{
    __typename: "Notification";
    id: string;
    typeNotify: string | null;
    userId: string | null;
    subject: string | null;
    body: string | null;
    localUrl: string | null;
    renotify: boolean | null;
    silent: boolean | null;
    dateFor: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type GetNotificationQuery = {
  __typename: "Notification";
  id: string;
  typeNotify: string | null;
  userId: string | null;
  subject: string | null;
  body: string | null;
  localUrl: string | null;
  renotify: boolean | null;
  silent: boolean | null;
  dateFor: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type ListNotificationsQuery = {
  __typename: "ModelNotificationConnection";
  items: Array<{
    __typename: "Notification";
    id: string;
    typeNotify: string | null;
    userId: string | null;
    subject: string | null;
    body: string | null;
    localUrl: string | null;
    renotify: boolean | null;
    silent: boolean | null;
    dateFor: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SyncUserPrivaciesQuery = {
  __typename: "ModelUserPrivacyConnection";
  items: Array<{
    __typename: "UserPrivacy";
    id: string;
    userId: string;
    profileShared: string;
    bgPhoto: string | null;
    headLine: string | null;
    secondHeadLine: string | null;
    titleLine: string | null;
    bioLine: string | null;
    showEmail: boolean | null;
    showPhone: boolean | null;
    showEmailOn: string | null;
    contactDirectlyByEmail: boolean | null;
    showConnectionsTo: string | null;
    oaConnInvitationAccepted: boolean | null;
    oaEventUpdates: boolean | null;
    oaUpdatesFromConnections: boolean | null;
    oaBreakingNews: boolean | null;
    oaTopsPicks: boolean | null;
    oaWhoViewedYourProfile: boolean | null;
    oaYourNewFollowers: boolean | null;
    emailEventsInvitations: boolean | null;
    emailEventsUpdates: boolean | null;
    emailNewsInvitations: boolean | null;
    emailNewsSubscriptions: boolean | null;
    whoRequestForNotifications: boolean | null;
    whoInvitationsToAttend: boolean | null;
    locMap: string | null;
    location: string | null;
    locTitle: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type GetUserPrivacyQuery = {
  __typename: "UserPrivacy";
  id: string;
  userId: string;
  profileShared: string;
  bgPhoto: string | null;
  headLine: string | null;
  secondHeadLine: string | null;
  titleLine: string | null;
  bioLine: string | null;
  showEmail: boolean | null;
  showPhone: boolean | null;
  showEmailOn: string | null;
  contactDirectlyByEmail: boolean | null;
  showConnectionsTo: string | null;
  oaConnInvitationAccepted: boolean | null;
  oaEventUpdates: boolean | null;
  oaUpdatesFromConnections: boolean | null;
  oaBreakingNews: boolean | null;
  oaTopsPicks: boolean | null;
  oaWhoViewedYourProfile: boolean | null;
  oaYourNewFollowers: boolean | null;
  emailEventsInvitations: boolean | null;
  emailEventsUpdates: boolean | null;
  emailNewsInvitations: boolean | null;
  emailNewsSubscriptions: boolean | null;
  whoRequestForNotifications: boolean | null;
  whoInvitationsToAttend: boolean | null;
  locMap: string | null;
  location: string | null;
  locTitle: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type ListUserPrivacysQuery = {
  __typename: "ModelUserPrivacyConnection";
  items: Array<{
    __typename: "UserPrivacy";
    id: string;
    userId: string;
    profileShared: string;
    bgPhoto: string | null;
    headLine: string | null;
    secondHeadLine: string | null;
    titleLine: string | null;
    bioLine: string | null;
    showEmail: boolean | null;
    showPhone: boolean | null;
    showEmailOn: string | null;
    contactDirectlyByEmail: boolean | null;
    showConnectionsTo: string | null;
    oaConnInvitationAccepted: boolean | null;
    oaEventUpdates: boolean | null;
    oaUpdatesFromConnections: boolean | null;
    oaBreakingNews: boolean | null;
    oaTopsPicks: boolean | null;
    oaWhoViewedYourProfile: boolean | null;
    oaYourNewFollowers: boolean | null;
    emailEventsInvitations: boolean | null;
    emailEventsUpdates: boolean | null;
    emailNewsInvitations: boolean | null;
    emailNewsSubscriptions: boolean | null;
    whoRequestForNotifications: boolean | null;
    whoInvitationsToAttend: boolean | null;
    locMap: string | null;
    location: string | null;
    locTitle: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SyncCourseCategoriesQuery = {
  __typename: "ModelCourseCategoryConnection";
  items: Array<{
    __typename: "CourseCategory";
    id: string;
    category: string;
    createdAt: string | null;
    updatedAt: string | null;
    status: elStatus | null;
    courses: {
      __typename: "ModelCourseConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type GetCourseCategoryQuery = {
  __typename: "CourseCategory";
  id: string;
  category: string;
  createdAt: string | null;
  updatedAt: string | null;
  status: elStatus | null;
  courses: {
    __typename: "ModelCourseConnection";
    items: Array<{
      __typename: "Course";
      id: string;
      title: string;
      description: string;
      categoryId: string;
      coverImage: string | null;
      timeAprox: string;
      status: elStatus;
      coverImage1: string | null;
      coverImage2: string | null;
      coverImage3: string | null;
      courseType: CourseType | null;
      courseUrl: string | null;
      overviewSourceMedia: string | null;
      overviewFileName: string | null;
      overviewFileType: string | null;
      overviewFileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      courseTrend: elCourseTrend | null;
      learnDescription: string | null;
      requirements: string | null;
      level: string | null;
      tags: string | null;
      trending: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type ListCourseCategorysQuery = {
  __typename: "ModelCourseCategoryConnection";
  items: Array<{
    __typename: "CourseCategory";
    id: string;
    category: string;
    createdAt: string | null;
    updatedAt: string | null;
    status: elStatus | null;
    courses: {
      __typename: "ModelCourseConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SyncCourseResourcesQuery = {
  __typename: "ModelCourseResourceConnection";
  items: Array<{
    __typename: "CourseResource";
    id: string;
    courseId: string;
    description: string;
    contentType: ResourceType;
    sourceCode: string | null;
    fileName: string | null;
    fileType: string | null;
    fileSize: string | null;
    status: elStatus;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type GetCourseResourceQuery = {
  __typename: "CourseResource";
  id: string;
  courseId: string;
  description: string;
  contentType: ResourceType;
  sourceCode: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  status: elStatus;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type ListCourseResourcesQuery = {
  __typename: "ModelCourseResourceConnection";
  items: Array<{
    __typename: "CourseResource";
    id: string;
    courseId: string;
    description: string;
    contentType: ResourceType;
    sourceCode: string | null;
    fileName: string | null;
    fileType: string | null;
    fileSize: string | null;
    status: elStatus;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SyncContentsQuery = {
  __typename: "ModelContentConnection";
  items: Array<{
    __typename: "Content";
    id: string;
    courseId: string;
    contentType: ContentType;
    media: string | null;
    sourceMedia: string | null;
    fileName: string | null;
    fileType: string | null;
    fileSize: string | null;
    transcoderMeta: string | null;
    transcoderJobId: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type GetContentQuery = {
  __typename: "Content";
  id: string;
  courseId: string;
  contentType: ContentType;
  media: string | null;
  sourceMedia: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  transcoderMeta: string | null;
  transcoderJobId: string | null;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type ListContentsQuery = {
  __typename: "ModelContentConnection";
  items: Array<{
    __typename: "Content";
    id: string;
    courseId: string;
    contentType: ContentType;
    media: string | null;
    sourceMedia: string | null;
    fileName: string | null;
    fileType: string | null;
    fileSize: string | null;
    transcoderMeta: string | null;
    transcoderJobId: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SyncContentResourcesQuery = {
  __typename: "ModelContentResourceConnection";
  items: Array<{
    __typename: "ContentResource";
    id: string;
    courseId: string;
    description: string;
    contentType: ResourceType;
    sourceCode: string | null;
    fileName: string | null;
    fileType: string | null;
    fileSize: string | null;
    status: elStatus;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type GetContentResourceQuery = {
  __typename: "ContentResource";
  id: string;
  courseId: string;
  description: string;
  contentType: ResourceType;
  sourceCode: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  status: elStatus;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type ListContentResourcesQuery = {
  __typename: "ModelContentResourceConnection";
  items: Array<{
    __typename: "ContentResource";
    id: string;
    courseId: string;
    description: string;
    contentType: ResourceType;
    sourceCode: string | null;
    fileName: string | null;
    fileType: string | null;
    fileSize: string | null;
    status: elStatus;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SyncCoursesQuery = {
  __typename: "ModelCourseConnection";
  items: Array<{
    __typename: "Course";
    id: string;
    title: string;
    description: string;
    category: {
      __typename: "CourseCategory";
      id: string;
      category: string;
      createdAt: string | null;
      updatedAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    };
    categoryId: string;
    coverImage: string | null;
    timeAprox: string;
    status: elStatus;
    coverImage1: string | null;
    coverImage2: string | null;
    coverImage3: string | null;
    courseType: CourseType | null;
    courseUrl: string | null;
    overviewSourceMedia: string | null;
    overviewFileName: string | null;
    overviewFileType: string | null;
    overviewFileSize: string | null;
    transcoderMeta: string | null;
    transcoderJobId: string | null;
    courseTrend: elCourseTrend | null;
    learnDescription: string | null;
    requirements: string | null;
    level: string | null;
    tags: string | null;
    trending: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    assets: {
      __typename: "ModelCourseAssetsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type GetCourseQuery = {
  __typename: "Course";
  id: string;
  title: string;
  description: string;
  category: {
    __typename: "CourseCategory";
    id: string;
    category: string;
    createdAt: string | null;
    updatedAt: string | null;
    status: elStatus | null;
    courses: {
      __typename: "ModelCourseConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  };
  categoryId: string;
  coverImage: string | null;
  timeAprox: string;
  status: elStatus;
  coverImage1: string | null;
  coverImage2: string | null;
  coverImage3: string | null;
  courseType: CourseType | null;
  courseUrl: string | null;
  overviewSourceMedia: string | null;
  overviewFileName: string | null;
  overviewFileType: string | null;
  overviewFileSize: string | null;
  transcoderMeta: string | null;
  transcoderJobId: string | null;
  courseTrend: elCourseTrend | null;
  learnDescription: string | null;
  requirements: string | null;
  level: string | null;
  tags: string | null;
  trending: string | null;
  updatedAt: string | null;
  createdAt: string | null;
  assets: {
    __typename: "ModelCourseAssetsConnection";
    items: Array<{
      __typename: "CourseAssets";
      id: string;
      courseId: string;
      assetId: string | null;
      assetType: elAssetType;
      title: string;
      description: string;
      order: number | null;
      timeAprox: string | null;
      starred: boolean | null;
      important: boolean | null;
      showAsOverview: boolean | null;
      showAsFree: boolean | null;
      nextStep: string | null;
      prevStep: string | null;
      status: elStatus;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type ListCoursesQuery = {
  __typename: "ModelCourseConnection";
  items: Array<{
    __typename: "Course";
    id: string;
    title: string;
    description: string;
    category: {
      __typename: "CourseCategory";
      id: string;
      category: string;
      createdAt: string | null;
      updatedAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    };
    categoryId: string;
    coverImage: string | null;
    timeAprox: string;
    status: elStatus;
    coverImage1: string | null;
    coverImage2: string | null;
    coverImage3: string | null;
    courseType: CourseType | null;
    courseUrl: string | null;
    overviewSourceMedia: string | null;
    overviewFileName: string | null;
    overviewFileType: string | null;
    overviewFileSize: string | null;
    transcoderMeta: string | null;
    transcoderJobId: string | null;
    courseTrend: elCourseTrend | null;
    learnDescription: string | null;
    requirements: string | null;
    level: string | null;
    tags: string | null;
    trending: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    assets: {
      __typename: "ModelCourseAssetsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SyncContentSubsQuery = {
  __typename: "ModelContentSubsConnection";
  items: Array<{
    __typename: "ContentSubs";
    id: string;
    contentId: string;
    userId: string;
    metaData: string | null;
    status: elSubStatus;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type GetContentSubsQuery = {
  __typename: "ContentSubs";
  id: string;
  contentId: string;
  userId: string;
  metaData: string | null;
  status: elSubStatus;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type ListContentSubssQuery = {
  __typename: "ModelContentSubsConnection";
  items: Array<{
    __typename: "ContentSubs";
    id: string;
    contentId: string;
    userId: string;
    metaData: string | null;
    status: elSubStatus;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SyncCourseAssetsQuery = {
  __typename: "ModelCourseAssetsConnection";
  items: Array<{
    __typename: "CourseAssets";
    id: string;
    courseId: string;
    assetId: string | null;
    assetType: elAssetType;
    title: string;
    description: string;
    order: number | null;
    timeAprox: string | null;
    starred: boolean | null;
    important: boolean | null;
    showAsOverview: boolean | null;
    showAsFree: boolean | null;
    nextStep: string | null;
    prevStep: string | null;
    status: elStatus;
    updatedAt: string | null;
    createdAt: string | null;
    course: {
      __typename: "Course";
      id: string;
      title: string;
      description: string;
      categoryId: string;
      coverImage: string | null;
      timeAprox: string;
      status: elStatus;
      coverImage1: string | null;
      coverImage2: string | null;
      coverImage3: string | null;
      courseType: CourseType | null;
      courseUrl: string | null;
      overviewSourceMedia: string | null;
      overviewFileName: string | null;
      overviewFileType: string | null;
      overviewFileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      courseTrend: elCourseTrend | null;
      learnDescription: string | null;
      requirements: string | null;
      level: string | null;
      tags: string | null;
      trending: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    topic: {
      __typename: "Content";
      id: string;
      courseId: string;
      contentType: ContentType;
      media: string | null;
      sourceMedia: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    quiz: {
      __typename: "Quiz";
      id: string;
      courseId: string;
      questionsRandom: number | null;
      passingScore: number | null;
      noAttempts: number | null;
      score: number | null;
      labelWhenPassed: string | null;
      labelWhenFailed: string | null;
      questionFeedback: boolean | null;
      updatedAt: string | null;
      createdAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    attachments: {
      __typename: "ModelCourseAssetsResourceConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type GetCourseAssetsQuery = {
  __typename: "CourseAssets";
  id: string;
  courseId: string;
  assetId: string | null;
  assetType: elAssetType;
  title: string;
  description: string;
  order: number | null;
  timeAprox: string | null;
  starred: boolean | null;
  important: boolean | null;
  showAsOverview: boolean | null;
  showAsFree: boolean | null;
  nextStep: string | null;
  prevStep: string | null;
  status: elStatus;
  updatedAt: string | null;
  createdAt: string | null;
  course: {
    __typename: "Course";
    id: string;
    title: string;
    description: string;
    category: {
      __typename: "CourseCategory";
      id: string;
      category: string;
      createdAt: string | null;
      updatedAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    };
    categoryId: string;
    coverImage: string | null;
    timeAprox: string;
    status: elStatus;
    coverImage1: string | null;
    coverImage2: string | null;
    coverImage3: string | null;
    courseType: CourseType | null;
    courseUrl: string | null;
    overviewSourceMedia: string | null;
    overviewFileName: string | null;
    overviewFileType: string | null;
    overviewFileSize: string | null;
    transcoderMeta: string | null;
    transcoderJobId: string | null;
    courseTrend: elCourseTrend | null;
    learnDescription: string | null;
    requirements: string | null;
    level: string | null;
    tags: string | null;
    trending: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    assets: {
      __typename: "ModelCourseAssetsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  topic: {
    __typename: "Content";
    id: string;
    courseId: string;
    contentType: ContentType;
    media: string | null;
    sourceMedia: string | null;
    fileName: string | null;
    fileType: string | null;
    fileSize: string | null;
    transcoderMeta: string | null;
    transcoderJobId: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  quiz: {
    __typename: "Quiz";
    id: string;
    courseId: string;
    questionsRandom: number | null;
    passingScore: number | null;
    noAttempts: number | null;
    score: number | null;
    labelWhenPassed: string | null;
    labelWhenFailed: string | null;
    questionFeedback: boolean | null;
    updatedAt: string | null;
    createdAt: string | null;
    status: elStatus | null;
    questions: {
      __typename: "ModelQuizQuestionConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  attachments: {
    __typename: "ModelCourseAssetsResourceConnection";
    items: Array<{
      __typename: "CourseAssetsResource";
      id: string;
      courseId: string;
      courseAssetId: string;
      description: string;
      contentType: ResourceType;
      sourceCode: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      status: elStatus;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type ListCourseAssetssQuery = {
  __typename: "ModelCourseAssetsConnection";
  items: Array<{
    __typename: "CourseAssets";
    id: string;
    courseId: string;
    assetId: string | null;
    assetType: elAssetType;
    title: string;
    description: string;
    order: number | null;
    timeAprox: string | null;
    starred: boolean | null;
    important: boolean | null;
    showAsOverview: boolean | null;
    showAsFree: boolean | null;
    nextStep: string | null;
    prevStep: string | null;
    status: elStatus;
    updatedAt: string | null;
    createdAt: string | null;
    course: {
      __typename: "Course";
      id: string;
      title: string;
      description: string;
      categoryId: string;
      coverImage: string | null;
      timeAprox: string;
      status: elStatus;
      coverImage1: string | null;
      coverImage2: string | null;
      coverImage3: string | null;
      courseType: CourseType | null;
      courseUrl: string | null;
      overviewSourceMedia: string | null;
      overviewFileName: string | null;
      overviewFileType: string | null;
      overviewFileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      courseTrend: elCourseTrend | null;
      learnDescription: string | null;
      requirements: string | null;
      level: string | null;
      tags: string | null;
      trending: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    topic: {
      __typename: "Content";
      id: string;
      courseId: string;
      contentType: ContentType;
      media: string | null;
      sourceMedia: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    quiz: {
      __typename: "Quiz";
      id: string;
      courseId: string;
      questionsRandom: number | null;
      passingScore: number | null;
      noAttempts: number | null;
      score: number | null;
      labelWhenPassed: string | null;
      labelWhenFailed: string | null;
      questionFeedback: boolean | null;
      updatedAt: string | null;
      createdAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    attachments: {
      __typename: "ModelCourseAssetsResourceConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SyncQuizzesQuery = {
  __typename: "ModelQuizConnection";
  items: Array<{
    __typename: "Quiz";
    id: string;
    courseId: string;
    questionsRandom: number | null;
    passingScore: number | null;
    noAttempts: number | null;
    score: number | null;
    labelWhenPassed: string | null;
    labelWhenFailed: string | null;
    questionFeedback: boolean | null;
    updatedAt: string | null;
    createdAt: string | null;
    status: elStatus | null;
    questions: {
      __typename: "ModelQuizQuestionConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type GetQuizQuery = {
  __typename: "Quiz";
  id: string;
  courseId: string;
  questionsRandom: number | null;
  passingScore: number | null;
  noAttempts: number | null;
  score: number | null;
  labelWhenPassed: string | null;
  labelWhenFailed: string | null;
  questionFeedback: boolean | null;
  updatedAt: string | null;
  createdAt: string | null;
  status: elStatus | null;
  questions: {
    __typename: "ModelQuizQuestionConnection";
    items: Array<{
      __typename: "QuizQuestion";
      id: string;
      quizContentId: string;
      order: number | null;
      title: string | null;
      qType: elQuestionType;
      labelCorrectMessage: string | null;
      labelIncorrectMessage: string | null;
      questionFeedBack: string | null;
      media: string | null;
      sourceMedia: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type ListQuizsQuery = {
  __typename: "ModelQuizConnection";
  items: Array<{
    __typename: "Quiz";
    id: string;
    courseId: string;
    questionsRandom: number | null;
    passingScore: number | null;
    noAttempts: number | null;
    score: number | null;
    labelWhenPassed: string | null;
    labelWhenFailed: string | null;
    questionFeedback: boolean | null;
    updatedAt: string | null;
    createdAt: string | null;
    status: elStatus | null;
    questions: {
      __typename: "ModelQuizQuestionConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SyncQuizQuestionsQuery = {
  __typename: "ModelQuizQuestionConnection";
  items: Array<{
    __typename: "QuizQuestion";
    id: string;
    quizContentId: string;
    quiz: {
      __typename: "Quiz";
      id: string;
      courseId: string;
      questionsRandom: number | null;
      passingScore: number | null;
      noAttempts: number | null;
      score: number | null;
      labelWhenPassed: string | null;
      labelWhenFailed: string | null;
      questionFeedback: boolean | null;
      updatedAt: string | null;
      createdAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    order: number | null;
    title: string | null;
    qType: elQuestionType;
    labelCorrectMessage: string | null;
    labelIncorrectMessage: string | null;
    questionFeedBack: string | null;
    media: string | null;
    sourceMedia: string | null;
    fileName: string | null;
    fileType: string | null;
    fileSize: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    status: elStatus | null;
    questionAnswers: {
      __typename: "ModelQuestionAnswersConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type GetQuizQuestionQuery = {
  __typename: "QuizQuestion";
  id: string;
  quizContentId: string;
  quiz: {
    __typename: "Quiz";
    id: string;
    courseId: string;
    questionsRandom: number | null;
    passingScore: number | null;
    noAttempts: number | null;
    score: number | null;
    labelWhenPassed: string | null;
    labelWhenFailed: string | null;
    questionFeedback: boolean | null;
    updatedAt: string | null;
    createdAt: string | null;
    status: elStatus | null;
    questions: {
      __typename: "ModelQuizQuestionConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  order: number | null;
  title: string | null;
  qType: elQuestionType;
  labelCorrectMessage: string | null;
  labelIncorrectMessage: string | null;
  questionFeedBack: string | null;
  media: string | null;
  sourceMedia: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  updatedAt: string | null;
  createdAt: string | null;
  status: elStatus | null;
  questionAnswers: {
    __typename: "ModelQuestionAnswersConnection";
    items: Array<{
      __typename: "QuestionAnswers";
      id: string;
      quizQuestionId: string;
      order: number | null;
      isCorrect: boolean | null;
      title: string | null;
      answer: string | null;
      answerFeedBack: string | null;
      qType: elQuestionType;
      media: string | null;
      sourceMedia: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      status: elStatus | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type ListQuizQuestionsQuery = {
  __typename: "ModelQuizQuestionConnection";
  items: Array<{
    __typename: "QuizQuestion";
    id: string;
    quizContentId: string;
    quiz: {
      __typename: "Quiz";
      id: string;
      courseId: string;
      questionsRandom: number | null;
      passingScore: number | null;
      noAttempts: number | null;
      score: number | null;
      labelWhenPassed: string | null;
      labelWhenFailed: string | null;
      questionFeedback: boolean | null;
      updatedAt: string | null;
      createdAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    order: number | null;
    title: string | null;
    qType: elQuestionType;
    labelCorrectMessage: string | null;
    labelIncorrectMessage: string | null;
    questionFeedBack: string | null;
    media: string | null;
    sourceMedia: string | null;
    fileName: string | null;
    fileType: string | null;
    fileSize: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    status: elStatus | null;
    questionAnswers: {
      __typename: "ModelQuestionAnswersConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SyncQuestionAnswersQuery = {
  __typename: "ModelQuestionAnswersConnection";
  items: Array<{
    __typename: "QuestionAnswers";
    id: string;
    quizQuestionId: string;
    question: {
      __typename: "QuizQuestion";
      id: string;
      quizContentId: string;
      order: number | null;
      title: string | null;
      qType: elQuestionType;
      labelCorrectMessage: string | null;
      labelIncorrectMessage: string | null;
      questionFeedBack: string | null;
      media: string | null;
      sourceMedia: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    order: number | null;
    isCorrect: boolean | null;
    title: string | null;
    answer: string | null;
    answerFeedBack: string | null;
    qType: elQuestionType;
    media: string | null;
    sourceMedia: string | null;
    fileName: string | null;
    fileType: string | null;
    fileSize: string | null;
    status: elStatus | null;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type GetQuestionAnswersQuery = {
  __typename: "QuestionAnswers";
  id: string;
  quizQuestionId: string;
  question: {
    __typename: "QuizQuestion";
    id: string;
    quizContentId: string;
    quiz: {
      __typename: "Quiz";
      id: string;
      courseId: string;
      questionsRandom: number | null;
      passingScore: number | null;
      noAttempts: number | null;
      score: number | null;
      labelWhenPassed: string | null;
      labelWhenFailed: string | null;
      questionFeedback: boolean | null;
      updatedAt: string | null;
      createdAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    order: number | null;
    title: string | null;
    qType: elQuestionType;
    labelCorrectMessage: string | null;
    labelIncorrectMessage: string | null;
    questionFeedBack: string | null;
    media: string | null;
    sourceMedia: string | null;
    fileName: string | null;
    fileType: string | null;
    fileSize: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    status: elStatus | null;
    questionAnswers: {
      __typename: "ModelQuestionAnswersConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  order: number | null;
  isCorrect: boolean | null;
  title: string | null;
  answer: string | null;
  answerFeedBack: string | null;
  qType: elQuestionType;
  media: string | null;
  sourceMedia: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  status: elStatus | null;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type ListQuestionAnswerssQuery = {
  __typename: "ModelQuestionAnswersConnection";
  items: Array<{
    __typename: "QuestionAnswers";
    id: string;
    quizQuestionId: string;
    question: {
      __typename: "QuizQuestion";
      id: string;
      quizContentId: string;
      order: number | null;
      title: string | null;
      qType: elQuestionType;
      labelCorrectMessage: string | null;
      labelIncorrectMessage: string | null;
      questionFeedBack: string | null;
      media: string | null;
      sourceMedia: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    order: number | null;
    isCorrect: boolean | null;
    title: string | null;
    answer: string | null;
    answerFeedBack: string | null;
    qType: elQuestionType;
    media: string | null;
    sourceMedia: string | null;
    fileName: string | null;
    fileType: string | null;
    fileSize: string | null;
    status: elStatus | null;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SyncCourseTagsQuery = {
  __typename: "ModelCourseTagsConnection";
  items: Array<{
    __typename: "CourseTags";
    id: string;
    tag: string;
    createdAt: string | null;
    updatedAt: string | null;
    status: elStatus | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type GetCourseTagsQuery = {
  __typename: "CourseTags";
  id: string;
  tag: string;
  createdAt: string | null;
  updatedAt: string | null;
  status: elStatus | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type ListCourseTagssQuery = {
  __typename: "ModelCourseTagsConnection";
  items: Array<{
    __typename: "CourseTags";
    id: string;
    tag: string;
    createdAt: string | null;
    updatedAt: string | null;
    status: elStatus | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SyncCourseAssetsResourcesQuery = {
  __typename: "ModelCourseAssetsResourceConnection";
  items: Array<{
    __typename: "CourseAssetsResource";
    id: string;
    courseId: string;
    courseAssetId: string;
    description: string;
    contentType: ResourceType;
    sourceCode: string | null;
    fileName: string | null;
    fileType: string | null;
    fileSize: string | null;
    status: elStatus;
    updatedAt: string | null;
    createdAt: string | null;
    courseAsset: {
      __typename: "CourseAssets";
      id: string;
      courseId: string;
      assetId: string | null;
      assetType: elAssetType;
      title: string;
      description: string;
      order: number | null;
      timeAprox: string | null;
      starred: boolean | null;
      important: boolean | null;
      showAsOverview: boolean | null;
      showAsFree: boolean | null;
      nextStep: string | null;
      prevStep: string | null;
      status: elStatus;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type GetCourseAssetsResourceQuery = {
  __typename: "CourseAssetsResource";
  id: string;
  courseId: string;
  courseAssetId: string;
  description: string;
  contentType: ResourceType;
  sourceCode: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  status: elStatus;
  updatedAt: string | null;
  createdAt: string | null;
  courseAsset: {
    __typename: "CourseAssets";
    id: string;
    courseId: string;
    assetId: string | null;
    assetType: elAssetType;
    title: string;
    description: string;
    order: number | null;
    timeAprox: string | null;
    starred: boolean | null;
    important: boolean | null;
    showAsOverview: boolean | null;
    showAsFree: boolean | null;
    nextStep: string | null;
    prevStep: string | null;
    status: elStatus;
    updatedAt: string | null;
    createdAt: string | null;
    course: {
      __typename: "Course";
      id: string;
      title: string;
      description: string;
      categoryId: string;
      coverImage: string | null;
      timeAprox: string;
      status: elStatus;
      coverImage1: string | null;
      coverImage2: string | null;
      coverImage3: string | null;
      courseType: CourseType | null;
      courseUrl: string | null;
      overviewSourceMedia: string | null;
      overviewFileName: string | null;
      overviewFileType: string | null;
      overviewFileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      courseTrend: elCourseTrend | null;
      learnDescription: string | null;
      requirements: string | null;
      level: string | null;
      tags: string | null;
      trending: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    topic: {
      __typename: "Content";
      id: string;
      courseId: string;
      contentType: ContentType;
      media: string | null;
      sourceMedia: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    quiz: {
      __typename: "Quiz";
      id: string;
      courseId: string;
      questionsRandom: number | null;
      passingScore: number | null;
      noAttempts: number | null;
      score: number | null;
      labelWhenPassed: string | null;
      labelWhenFailed: string | null;
      questionFeedback: boolean | null;
      updatedAt: string | null;
      createdAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    attachments: {
      __typename: "ModelCourseAssetsResourceConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type ListCourseAssetsResourcesQuery = {
  __typename: "ModelCourseAssetsResourceConnection";
  items: Array<{
    __typename: "CourseAssetsResource";
    id: string;
    courseId: string;
    courseAssetId: string;
    description: string;
    contentType: ResourceType;
    sourceCode: string | null;
    fileName: string | null;
    fileType: string | null;
    fileSize: string | null;
    status: elStatus;
    updatedAt: string | null;
    createdAt: string | null;
    courseAsset: {
      __typename: "CourseAssets";
      id: string;
      courseId: string;
      assetId: string | null;
      assetType: elAssetType;
      title: string;
      description: string;
      order: number | null;
      timeAprox: string | null;
      starred: boolean | null;
      important: boolean | null;
      showAsOverview: boolean | null;
      showAsFree: boolean | null;
      nextStep: string | null;
      prevStep: string | null;
      status: elStatus;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SyncContactsQuery = {
  __typename: "ModelContactConnection";
  items: Array<{
    __typename: "Contact";
    id: string;
    userID: string;
    syncID: string | null;
    contactType: ContactType | null;
    contactName: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    phone: string;
    avatar: string | null;
    avatarType: string | null;
    jobTitle: string | null;
    birthday: string | null;
    starred: boolean | null;
    frequent: boolean | null;
    status: string;
    pbProfileOptions: string | null;
    userRelatedId: string | null;
    notes: string | null;
    businessId: string | null;
    contactSince: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    contactRelId: string | null;
    contactRel: {
      __typename: "Contact";
      id: string;
      userID: string;
      syncID: string | null;
      contactType: ContactType | null;
      contactName: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      phone: string;
      avatar: string | null;
      avatarType: string | null;
      jobTitle: string | null;
      birthday: string | null;
      starred: boolean | null;
      frequent: boolean | null;
      status: string;
      pbProfileOptions: string | null;
      userRelatedId: string | null;
      notes: string | null;
      businessId: string | null;
      contactSince: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      contactRelId: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    addresses: {
      __typename: "ModelContactAddressConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    connects: {
      __typename: "ModelConnectsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    licence: {
      __typename: "ModelMembershipConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type GetContactQuery = {
  __typename: "Contact";
  id: string;
  userID: string;
  syncID: string | null;
  contactType: ContactType | null;
  contactName: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phone: string;
  avatar: string | null;
  avatarType: string | null;
  jobTitle: string | null;
  birthday: string | null;
  starred: boolean | null;
  frequent: boolean | null;
  status: string;
  pbProfileOptions: string | null;
  userRelatedId: string | null;
  notes: string | null;
  businessId: string | null;
  contactSince: string | null;
  updatedAt: string | null;
  createdAt: string | null;
  contactRelId: string | null;
  contactRel: {
    __typename: "Contact";
    id: string;
    userID: string;
    syncID: string | null;
    contactType: ContactType | null;
    contactName: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    phone: string;
    avatar: string | null;
    avatarType: string | null;
    jobTitle: string | null;
    birthday: string | null;
    starred: boolean | null;
    frequent: boolean | null;
    status: string;
    pbProfileOptions: string | null;
    userRelatedId: string | null;
    notes: string | null;
    businessId: string | null;
    contactSince: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    contactRelId: string | null;
    contactRel: {
      __typename: "Contact";
      id: string;
      userID: string;
      syncID: string | null;
      contactType: ContactType | null;
      contactName: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      phone: string;
      avatar: string | null;
      avatarType: string | null;
      jobTitle: string | null;
      birthday: string | null;
      starred: boolean | null;
      frequent: boolean | null;
      status: string;
      pbProfileOptions: string | null;
      userRelatedId: string | null;
      notes: string | null;
      businessId: string | null;
      contactSince: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      contactRelId: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    addresses: {
      __typename: "ModelContactAddressConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    connects: {
      __typename: "ModelConnectsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    licence: {
      __typename: "ModelMembershipConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  addresses: {
    __typename: "ModelContactAddressConnection";
    items: Array<{
      __typename: "ContactAddress";
      id: string;
      contactId: string;
      addressType: string | null;
      street: string | null;
      street1: string | null;
      zipCode: string | null;
      city: string | null;
      state: string | null;
      Country: string | null;
      status: string;
      updatedAt: string | null;
      createdAt: string | null;
      lat: string | null;
      lng: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  connects: {
    __typename: "ModelConnectsConnection";
    items: Array<{
      __typename: "Connects";
      id: string;
      contactId: string;
      connsContactId: string | null;
      contactType: string | null;
      contactName: string | null;
      status: string;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  licence: {
    __typename: "ModelMembershipConnection";
    items: Array<{
      __typename: "Membership";
      id: string;
      contactId: string;
      noLicenses: number | null;
      noClaimed: number | null;
      groupId: string | null;
      group: string | null;
      prefix: string | null;
      status: string;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type ListContactsQuery = {
  __typename: "ModelContactConnection";
  items: Array<{
    __typename: "Contact";
    id: string;
    userID: string;
    syncID: string | null;
    contactType: ContactType | null;
    contactName: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    phone: string;
    avatar: string | null;
    avatarType: string | null;
    jobTitle: string | null;
    birthday: string | null;
    starred: boolean | null;
    frequent: boolean | null;
    status: string;
    pbProfileOptions: string | null;
    userRelatedId: string | null;
    notes: string | null;
    businessId: string | null;
    contactSince: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    contactRelId: string | null;
    contactRel: {
      __typename: "Contact";
      id: string;
      userID: string;
      syncID: string | null;
      contactType: ContactType | null;
      contactName: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      phone: string;
      avatar: string | null;
      avatarType: string | null;
      jobTitle: string | null;
      birthday: string | null;
      starred: boolean | null;
      frequent: boolean | null;
      status: string;
      pbProfileOptions: string | null;
      userRelatedId: string | null;
      notes: string | null;
      businessId: string | null;
      contactSince: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      contactRelId: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    addresses: {
      __typename: "ModelContactAddressConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    connects: {
      __typename: "ModelConnectsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    licence: {
      __typename: "ModelMembershipConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SyncConnectsQuery = {
  __typename: "ModelConnectsConnection";
  items: Array<{
    __typename: "Connects";
    id: string;
    contactId: string;
    connsContactId: string | null;
    contactType: string | null;
    contactName: string | null;
    status: string;
    updatedAt: string | null;
    createdAt: string | null;
    contact: {
      __typename: "Contact";
      id: string;
      userID: string;
      syncID: string | null;
      contactType: ContactType | null;
      contactName: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      phone: string;
      avatar: string | null;
      avatarType: string | null;
      jobTitle: string | null;
      birthday: string | null;
      starred: boolean | null;
      frequent: boolean | null;
      status: string;
      pbProfileOptions: string | null;
      userRelatedId: string | null;
      notes: string | null;
      businessId: string | null;
      contactSince: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      contactRelId: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type GetConnectsQuery = {
  __typename: "Connects";
  id: string;
  contactId: string;
  connsContactId: string | null;
  contactType: string | null;
  contactName: string | null;
  status: string;
  updatedAt: string | null;
  createdAt: string | null;
  contact: {
    __typename: "Contact";
    id: string;
    userID: string;
    syncID: string | null;
    contactType: ContactType | null;
    contactName: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    phone: string;
    avatar: string | null;
    avatarType: string | null;
    jobTitle: string | null;
    birthday: string | null;
    starred: boolean | null;
    frequent: boolean | null;
    status: string;
    pbProfileOptions: string | null;
    userRelatedId: string | null;
    notes: string | null;
    businessId: string | null;
    contactSince: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    contactRelId: string | null;
    contactRel: {
      __typename: "Contact";
      id: string;
      userID: string;
      syncID: string | null;
      contactType: ContactType | null;
      contactName: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      phone: string;
      avatar: string | null;
      avatarType: string | null;
      jobTitle: string | null;
      birthday: string | null;
      starred: boolean | null;
      frequent: boolean | null;
      status: string;
      pbProfileOptions: string | null;
      userRelatedId: string | null;
      notes: string | null;
      businessId: string | null;
      contactSince: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      contactRelId: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    addresses: {
      __typename: "ModelContactAddressConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    connects: {
      __typename: "ModelConnectsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    licence: {
      __typename: "ModelMembershipConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type ListConnectssQuery = {
  __typename: "ModelConnectsConnection";
  items: Array<{
    __typename: "Connects";
    id: string;
    contactId: string;
    connsContactId: string | null;
    contactType: string | null;
    contactName: string | null;
    status: string;
    updatedAt: string | null;
    createdAt: string | null;
    contact: {
      __typename: "Contact";
      id: string;
      userID: string;
      syncID: string | null;
      contactType: ContactType | null;
      contactName: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      phone: string;
      avatar: string | null;
      avatarType: string | null;
      jobTitle: string | null;
      birthday: string | null;
      starred: boolean | null;
      frequent: boolean | null;
      status: string;
      pbProfileOptions: string | null;
      userRelatedId: string | null;
      notes: string | null;
      businessId: string | null;
      contactSince: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      contactRelId: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SyncContactAddressesQuery = {
  __typename: "ModelContactAddressConnection";
  items: Array<{
    __typename: "ContactAddress";
    id: string;
    contactId: string;
    addressType: string | null;
    street: string | null;
    street1: string | null;
    zipCode: string | null;
    city: string | null;
    state: string | null;
    Country: string | null;
    status: string;
    updatedAt: string | null;
    createdAt: string | null;
    lat: string | null;
    lng: string | null;
    contact: {
      __typename: "Contact";
      id: string;
      userID: string;
      syncID: string | null;
      contactType: ContactType | null;
      contactName: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      phone: string;
      avatar: string | null;
      avatarType: string | null;
      jobTitle: string | null;
      birthday: string | null;
      starred: boolean | null;
      frequent: boolean | null;
      status: string;
      pbProfileOptions: string | null;
      userRelatedId: string | null;
      notes: string | null;
      businessId: string | null;
      contactSince: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      contactRelId: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type GetContactAddressQuery = {
  __typename: "ContactAddress";
  id: string;
  contactId: string;
  addressType: string | null;
  street: string | null;
  street1: string | null;
  zipCode: string | null;
  city: string | null;
  state: string | null;
  Country: string | null;
  status: string;
  updatedAt: string | null;
  createdAt: string | null;
  lat: string | null;
  lng: string | null;
  contact: {
    __typename: "Contact";
    id: string;
    userID: string;
    syncID: string | null;
    contactType: ContactType | null;
    contactName: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    phone: string;
    avatar: string | null;
    avatarType: string | null;
    jobTitle: string | null;
    birthday: string | null;
    starred: boolean | null;
    frequent: boolean | null;
    status: string;
    pbProfileOptions: string | null;
    userRelatedId: string | null;
    notes: string | null;
    businessId: string | null;
    contactSince: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    contactRelId: string | null;
    contactRel: {
      __typename: "Contact";
      id: string;
      userID: string;
      syncID: string | null;
      contactType: ContactType | null;
      contactName: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      phone: string;
      avatar: string | null;
      avatarType: string | null;
      jobTitle: string | null;
      birthday: string | null;
      starred: boolean | null;
      frequent: boolean | null;
      status: string;
      pbProfileOptions: string | null;
      userRelatedId: string | null;
      notes: string | null;
      businessId: string | null;
      contactSince: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      contactRelId: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    addresses: {
      __typename: "ModelContactAddressConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    connects: {
      __typename: "ModelConnectsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    licence: {
      __typename: "ModelMembershipConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type ListContactAddresssQuery = {
  __typename: "ModelContactAddressConnection";
  items: Array<{
    __typename: "ContactAddress";
    id: string;
    contactId: string;
    addressType: string | null;
    street: string | null;
    street1: string | null;
    zipCode: string | null;
    city: string | null;
    state: string | null;
    Country: string | null;
    status: string;
    updatedAt: string | null;
    createdAt: string | null;
    lat: string | null;
    lng: string | null;
    contact: {
      __typename: "Contact";
      id: string;
      userID: string;
      syncID: string | null;
      contactType: ContactType | null;
      contactName: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      phone: string;
      avatar: string | null;
      avatarType: string | null;
      jobTitle: string | null;
      birthday: string | null;
      starred: boolean | null;
      frequent: boolean | null;
      status: string;
      pbProfileOptions: string | null;
      userRelatedId: string | null;
      notes: string | null;
      businessId: string | null;
      contactSince: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      contactRelId: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SyncMembershipsQuery = {
  __typename: "ModelMembershipConnection";
  items: Array<{
    __typename: "Membership";
    id: string;
    contactId: string;
    noLicenses: number | null;
    noClaimed: number | null;
    groupId: string | null;
    group: string | null;
    prefix: string | null;
    status: string;
    updatedAt: string | null;
    createdAt: string | null;
    org: {
      __typename: "Contact";
      id: string;
      userID: string;
      syncID: string | null;
      contactType: ContactType | null;
      contactName: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      phone: string;
      avatar: string | null;
      avatarType: string | null;
      jobTitle: string | null;
      birthday: string | null;
      starred: boolean | null;
      frequent: boolean | null;
      status: string;
      pbProfileOptions: string | null;
      userRelatedId: string | null;
      notes: string | null;
      businessId: string | null;
      contactSince: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      contactRelId: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type GetMembershipQuery = {
  __typename: "Membership";
  id: string;
  contactId: string;
  noLicenses: number | null;
  noClaimed: number | null;
  groupId: string | null;
  group: string | null;
  prefix: string | null;
  status: string;
  updatedAt: string | null;
  createdAt: string | null;
  org: {
    __typename: "Contact";
    id: string;
    userID: string;
    syncID: string | null;
    contactType: ContactType | null;
    contactName: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    phone: string;
    avatar: string | null;
    avatarType: string | null;
    jobTitle: string | null;
    birthday: string | null;
    starred: boolean | null;
    frequent: boolean | null;
    status: string;
    pbProfileOptions: string | null;
    userRelatedId: string | null;
    notes: string | null;
    businessId: string | null;
    contactSince: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    contactRelId: string | null;
    contactRel: {
      __typename: "Contact";
      id: string;
      userID: string;
      syncID: string | null;
      contactType: ContactType | null;
      contactName: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      phone: string;
      avatar: string | null;
      avatarType: string | null;
      jobTitle: string | null;
      birthday: string | null;
      starred: boolean | null;
      frequent: boolean | null;
      status: string;
      pbProfileOptions: string | null;
      userRelatedId: string | null;
      notes: string | null;
      businessId: string | null;
      contactSince: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      contactRelId: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    addresses: {
      __typename: "ModelContactAddressConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    connects: {
      __typename: "ModelConnectsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    licence: {
      __typename: "ModelMembershipConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type ListMembershipsQuery = {
  __typename: "ModelMembershipConnection";
  items: Array<{
    __typename: "Membership";
    id: string;
    contactId: string;
    noLicenses: number | null;
    noClaimed: number | null;
    groupId: string | null;
    group: string | null;
    prefix: string | null;
    status: string;
    updatedAt: string | null;
    createdAt: string | null;
    org: {
      __typename: "Contact";
      id: string;
      userID: string;
      syncID: string | null;
      contactType: ContactType | null;
      contactName: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      phone: string;
      avatar: string | null;
      avatarType: string | null;
      jobTitle: string | null;
      birthday: string | null;
      starred: boolean | null;
      frequent: boolean | null;
      status: string;
      pbProfileOptions: string | null;
      userRelatedId: string | null;
      notes: string | null;
      businessId: string | null;
      contactSince: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      contactRelId: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SettingsByUserIdQuery = {
  __typename: "ModelUserSettingsConnection";
  items: Array<{
    __typename: "UserSettings";
    id: string;
    userId: string;
    pageSize: number | null;
    notiType: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type ListNotifsByStatusQuery = {
  __typename: "ModelNotificationConnection";
  items: Array<{
    __typename: "Notification";
    id: string;
    typeNotify: string | null;
    userId: string | null;
    subject: string | null;
    body: string | null;
    localUrl: string | null;
    renotify: boolean | null;
    silent: boolean | null;
    dateFor: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type ListTodayNotifsByStatusQuery = {
  __typename: "ModelNotificationConnection";
  items: Array<{
    __typename: "Notification";
    id: string;
    typeNotify: string | null;
    userId: string | null;
    subject: string | null;
    body: string | null;
    localUrl: string | null;
    renotify: boolean | null;
    silent: boolean | null;
    dateFor: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type NotiByUserIdQuery = {
  __typename: "ModelNotificationConnection";
  items: Array<{
    __typename: "Notification";
    id: string;
    typeNotify: string | null;
    userId: string | null;
    subject: string | null;
    body: string | null;
    localUrl: string | null;
    renotify: boolean | null;
    silent: boolean | null;
    dateFor: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type PrivacyByUserIdQuery = {
  __typename: "ModelUserPrivacyConnection";
  items: Array<{
    __typename: "UserPrivacy";
    id: string;
    userId: string;
    profileShared: string;
    bgPhoto: string | null;
    headLine: string | null;
    secondHeadLine: string | null;
    titleLine: string | null;
    bioLine: string | null;
    showEmail: boolean | null;
    showPhone: boolean | null;
    showEmailOn: string | null;
    contactDirectlyByEmail: boolean | null;
    showConnectionsTo: string | null;
    oaConnInvitationAccepted: boolean | null;
    oaEventUpdates: boolean | null;
    oaUpdatesFromConnections: boolean | null;
    oaBreakingNews: boolean | null;
    oaTopsPicks: boolean | null;
    oaWhoViewedYourProfile: boolean | null;
    oaYourNewFollowers: boolean | null;
    emailEventsInvitations: boolean | null;
    emailEventsUpdates: boolean | null;
    emailNewsInvitations: boolean | null;
    emailNewsSubscriptions: boolean | null;
    whoRequestForNotifications: boolean | null;
    whoInvitationsToAttend: boolean | null;
    locMap: string | null;
    location: string | null;
    locTitle: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type PrivacyByUserSharedIdQuery = {
  __typename: "ModelUserPrivacyConnection";
  items: Array<{
    __typename: "UserPrivacy";
    id: string;
    userId: string;
    profileShared: string;
    bgPhoto: string | null;
    headLine: string | null;
    secondHeadLine: string | null;
    titleLine: string | null;
    bioLine: string | null;
    showEmail: boolean | null;
    showPhone: boolean | null;
    showEmailOn: string | null;
    contactDirectlyByEmail: boolean | null;
    showConnectionsTo: string | null;
    oaConnInvitationAccepted: boolean | null;
    oaEventUpdates: boolean | null;
    oaUpdatesFromConnections: boolean | null;
    oaBreakingNews: boolean | null;
    oaTopsPicks: boolean | null;
    oaWhoViewedYourProfile: boolean | null;
    oaYourNewFollowers: boolean | null;
    emailEventsInvitations: boolean | null;
    emailEventsUpdates: boolean | null;
    emailNewsInvitations: boolean | null;
    emailNewsSubscriptions: boolean | null;
    whoRequestForNotifications: boolean | null;
    whoInvitationsToAttend: boolean | null;
    locMap: string | null;
    location: string | null;
    locTitle: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type LIstCategoriesByStatusQuery = {
  __typename: "ModelCourseCategoryConnection";
  items: Array<{
    __typename: "CourseCategory";
    id: string;
    category: string;
    createdAt: string | null;
    updatedAt: string | null;
    status: elStatus | null;
    courses: {
      __typename: "ModelCourseConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type ListResourcesByStatusQuery = {
  __typename: "ModelCourseResourceConnection";
  items: Array<{
    __typename: "CourseResource";
    id: string;
    courseId: string;
    description: string;
    contentType: ResourceType;
    sourceCode: string | null;
    fileName: string | null;
    fileType: string | null;
    fileSize: string | null;
    status: elStatus;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type ListResourcesByCourseIdQuery = {
  __typename: "ModelCourseResourceConnection";
  items: Array<{
    __typename: "CourseResource";
    id: string;
    courseId: string;
    description: string;
    contentType: ResourceType;
    sourceCode: string | null;
    fileName: string | null;
    fileType: string | null;
    fileSize: string | null;
    status: elStatus;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type ListContentResourcesByStatusQuery = {
  __typename: "ModelContentResourceConnection";
  items: Array<{
    __typename: "ContentResource";
    id: string;
    courseId: string;
    description: string;
    contentType: ResourceType;
    sourceCode: string | null;
    fileName: string | null;
    fileType: string | null;
    fileSize: string | null;
    status: elStatus;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type ListContentResourcesByCourseIdQuery = {
  __typename: "ModelContentResourceConnection";
  items: Array<{
    __typename: "ContentResource";
    id: string;
    courseId: string;
    description: string;
    contentType: ResourceType;
    sourceCode: string | null;
    fileName: string | null;
    fileType: string | null;
    fileSize: string | null;
    status: elStatus;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type ListCoursesByStatusQuery = {
  __typename: "ModelCourseConnection";
  items: Array<{
    __typename: "Course";
    id: string;
    title: string;
    description: string;
    category: {
      __typename: "CourseCategory";
      id: string;
      category: string;
      createdAt: string | null;
      updatedAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    };
    categoryId: string;
    coverImage: string | null;
    timeAprox: string;
    status: elStatus;
    coverImage1: string | null;
    coverImage2: string | null;
    coverImage3: string | null;
    courseType: CourseType | null;
    courseUrl: string | null;
    overviewSourceMedia: string | null;
    overviewFileName: string | null;
    overviewFileType: string | null;
    overviewFileSize: string | null;
    transcoderMeta: string | null;
    transcoderJobId: string | null;
    courseTrend: elCourseTrend | null;
    learnDescription: string | null;
    requirements: string | null;
    level: string | null;
    tags: string | null;
    trending: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    assets: {
      __typename: "ModelCourseAssetsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type ListCoursesByCategoryQuery = {
  __typename: "ModelCourseConnection";
  items: Array<{
    __typename: "Course";
    id: string;
    title: string;
    description: string;
    category: {
      __typename: "CourseCategory";
      id: string;
      category: string;
      createdAt: string | null;
      updatedAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    };
    categoryId: string;
    coverImage: string | null;
    timeAprox: string;
    status: elStatus;
    coverImage1: string | null;
    coverImage2: string | null;
    coverImage3: string | null;
    courseType: CourseType | null;
    courseUrl: string | null;
    overviewSourceMedia: string | null;
    overviewFileName: string | null;
    overviewFileType: string | null;
    overviewFileSize: string | null;
    transcoderMeta: string | null;
    transcoderJobId: string | null;
    courseTrend: elCourseTrend | null;
    learnDescription: string | null;
    requirements: string | null;
    level: string | null;
    tags: string | null;
    trending: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    assets: {
      __typename: "ModelCourseAssetsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SubsContentsByIdsQuery = {
  __typename: "ModelContentSubsConnection";
  items: Array<{
    __typename: "ContentSubs";
    id: string;
    contentId: string;
    userId: string;
    metaData: string | null;
    status: elSubStatus;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SubsContentByStatusQuery = {
  __typename: "ModelContentSubsConnection";
  items: Array<{
    __typename: "ContentSubs";
    id: string;
    contentId: string;
    userId: string;
    metaData: string | null;
    status: elSubStatus;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type AssetsByCourseAndAssetIdQuery = {
  __typename: "ModelCourseAssetsConnection";
  items: Array<{
    __typename: "CourseAssets";
    id: string;
    courseId: string;
    assetId: string | null;
    assetType: elAssetType;
    title: string;
    description: string;
    order: number | null;
    timeAprox: string | null;
    starred: boolean | null;
    important: boolean | null;
    showAsOverview: boolean | null;
    showAsFree: boolean | null;
    nextStep: string | null;
    prevStep: string | null;
    status: elStatus;
    updatedAt: string | null;
    createdAt: string | null;
    course: {
      __typename: "Course";
      id: string;
      title: string;
      description: string;
      categoryId: string;
      coverImage: string | null;
      timeAprox: string;
      status: elStatus;
      coverImage1: string | null;
      coverImage2: string | null;
      coverImage3: string | null;
      courseType: CourseType | null;
      courseUrl: string | null;
      overviewSourceMedia: string | null;
      overviewFileName: string | null;
      overviewFileType: string | null;
      overviewFileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      courseTrend: elCourseTrend | null;
      learnDescription: string | null;
      requirements: string | null;
      level: string | null;
      tags: string | null;
      trending: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    topic: {
      __typename: "Content";
      id: string;
      courseId: string;
      contentType: ContentType;
      media: string | null;
      sourceMedia: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    quiz: {
      __typename: "Quiz";
      id: string;
      courseId: string;
      questionsRandom: number | null;
      passingScore: number | null;
      noAttempts: number | null;
      score: number | null;
      labelWhenPassed: string | null;
      labelWhenFailed: string | null;
      questionFeedback: boolean | null;
      updatedAt: string | null;
      createdAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    attachments: {
      __typename: "ModelCourseAssetsResourceConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type AssetsByCourseAndTypeQuery = {
  __typename: "ModelCourseAssetsConnection";
  items: Array<{
    __typename: "CourseAssets";
    id: string;
    courseId: string;
    assetId: string | null;
    assetType: elAssetType;
    title: string;
    description: string;
    order: number | null;
    timeAprox: string | null;
    starred: boolean | null;
    important: boolean | null;
    showAsOverview: boolean | null;
    showAsFree: boolean | null;
    nextStep: string | null;
    prevStep: string | null;
    status: elStatus;
    updatedAt: string | null;
    createdAt: string | null;
    course: {
      __typename: "Course";
      id: string;
      title: string;
      description: string;
      categoryId: string;
      coverImage: string | null;
      timeAprox: string;
      status: elStatus;
      coverImage1: string | null;
      coverImage2: string | null;
      coverImage3: string | null;
      courseType: CourseType | null;
      courseUrl: string | null;
      overviewSourceMedia: string | null;
      overviewFileName: string | null;
      overviewFileType: string | null;
      overviewFileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      courseTrend: elCourseTrend | null;
      learnDescription: string | null;
      requirements: string | null;
      level: string | null;
      tags: string | null;
      trending: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    topic: {
      __typename: "Content";
      id: string;
      courseId: string;
      contentType: ContentType;
      media: string | null;
      sourceMedia: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    quiz: {
      __typename: "Quiz";
      id: string;
      courseId: string;
      questionsRandom: number | null;
      passingScore: number | null;
      noAttempts: number | null;
      score: number | null;
      labelWhenPassed: string | null;
      labelWhenFailed: string | null;
      questionFeedback: boolean | null;
      updatedAt: string | null;
      createdAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    attachments: {
      __typename: "ModelCourseAssetsResourceConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type ListCourseAssetsByOrderQuery = {
  __typename: "ModelCourseAssetsConnection";
  items: Array<{
    __typename: "CourseAssets";
    id: string;
    courseId: string;
    assetId: string | null;
    assetType: elAssetType;
    title: string;
    description: string;
    order: number | null;
    timeAprox: string | null;
    starred: boolean | null;
    important: boolean | null;
    showAsOverview: boolean | null;
    showAsFree: boolean | null;
    nextStep: string | null;
    prevStep: string | null;
    status: elStatus;
    updatedAt: string | null;
    createdAt: string | null;
    course: {
      __typename: "Course";
      id: string;
      title: string;
      description: string;
      categoryId: string;
      coverImage: string | null;
      timeAprox: string;
      status: elStatus;
      coverImage1: string | null;
      coverImage2: string | null;
      coverImage3: string | null;
      courseType: CourseType | null;
      courseUrl: string | null;
      overviewSourceMedia: string | null;
      overviewFileName: string | null;
      overviewFileType: string | null;
      overviewFileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      courseTrend: elCourseTrend | null;
      learnDescription: string | null;
      requirements: string | null;
      level: string | null;
      tags: string | null;
      trending: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    topic: {
      __typename: "Content";
      id: string;
      courseId: string;
      contentType: ContentType;
      media: string | null;
      sourceMedia: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    quiz: {
      __typename: "Quiz";
      id: string;
      courseId: string;
      questionsRandom: number | null;
      passingScore: number | null;
      noAttempts: number | null;
      score: number | null;
      labelWhenPassed: string | null;
      labelWhenFailed: string | null;
      questionFeedback: boolean | null;
      updatedAt: string | null;
      createdAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    attachments: {
      __typename: "ModelCourseAssetsResourceConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type ListCourseTagsByStatusQuery = {
  __typename: "ModelCourseTagsConnection";
  items: Array<{
    __typename: "CourseTags";
    id: string;
    tag: string;
    createdAt: string | null;
    updatedAt: string | null;
    status: elStatus | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type ListCourseAssetsResourceByStatusQuery = {
  __typename: "ModelCourseAssetsResourceConnection";
  items: Array<{
    __typename: "CourseAssetsResource";
    id: string;
    courseId: string;
    courseAssetId: string;
    description: string;
    contentType: ResourceType;
    sourceCode: string | null;
    fileName: string | null;
    fileType: string | null;
    fileSize: string | null;
    status: elStatus;
    updatedAt: string | null;
    createdAt: string | null;
    courseAsset: {
      __typename: "CourseAssets";
      id: string;
      courseId: string;
      assetId: string | null;
      assetType: elAssetType;
      title: string;
      description: string;
      order: number | null;
      timeAprox: string | null;
      starred: boolean | null;
      important: boolean | null;
      showAsOverview: boolean | null;
      showAsFree: boolean | null;
      nextStep: string | null;
      prevStep: string | null;
      status: elStatus;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type ListAssetResourcesByIdsQuery = {
  __typename: "ModelCourseAssetsResourceConnection";
  items: Array<{
    __typename: "CourseAssetsResource";
    id: string;
    courseId: string;
    courseAssetId: string;
    description: string;
    contentType: ResourceType;
    sourceCode: string | null;
    fileName: string | null;
    fileType: string | null;
    fileSize: string | null;
    status: elStatus;
    updatedAt: string | null;
    createdAt: string | null;
    courseAsset: {
      __typename: "CourseAssets";
      id: string;
      courseId: string;
      assetId: string | null;
      assetType: elAssetType;
      title: string;
      description: string;
      order: number | null;
      timeAprox: string | null;
      starred: boolean | null;
      important: boolean | null;
      showAsOverview: boolean | null;
      showAsFree: boolean | null;
      nextStep: string | null;
      prevStep: string | null;
      status: elStatus;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type ListCourseSubssQuery = {
  __typename: "ModelCourseSubsConnection";
  items: Array<{
    __typename: "CourseSubs";
    id: string;
    courseId: string;
    userId: string;
    title: string | null;
    description: string | null;
    category: string | null;
    level: string | null;
    tags: string | null;
    metaData: string | null;
    status: elSubStatus;
    course: {
      __typename: "Course";
      id: string;
      title: string;
      description: string;
      categoryId: string;
      coverImage: string | null;
      timeAprox: string;
      status: elStatus;
      coverImage1: string | null;
      coverImage2: string | null;
      coverImage3: string | null;
      courseType: CourseType | null;
      courseUrl: string | null;
      overviewSourceMedia: string | null;
      overviewFileName: string | null;
      overviewFileType: string | null;
      overviewFileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      courseTrend: elCourseTrend | null;
      learnDescription: string | null;
      requirements: string | null;
      level: string | null;
      tags: string | null;
      trending: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
    owner: string | null;
    assetsSubs: {
      __typename: "ModelCourseAssetSubsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type GetCourseSubsQuery = {
  __typename: "CourseSubs";
  id: string;
  courseId: string;
  userId: string;
  title: string | null;
  description: string | null;
  category: string | null;
  level: string | null;
  tags: string | null;
  metaData: string | null;
  status: elSubStatus;
  course: {
    __typename: "Course";
    id: string;
    title: string;
    description: string;
    category: {
      __typename: "CourseCategory";
      id: string;
      category: string;
      createdAt: string | null;
      updatedAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    };
    categoryId: string;
    coverImage: string | null;
    timeAprox: string;
    status: elStatus;
    coverImage1: string | null;
    coverImage2: string | null;
    coverImage3: string | null;
    courseType: CourseType | null;
    courseUrl: string | null;
    overviewSourceMedia: string | null;
    overviewFileName: string | null;
    overviewFileType: string | null;
    overviewFileSize: string | null;
    transcoderMeta: string | null;
    transcoderJobId: string | null;
    courseTrend: elCourseTrend | null;
    learnDescription: string | null;
    requirements: string | null;
    level: string | null;
    tags: string | null;
    trending: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    assets: {
      __typename: "ModelCourseAssetsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
  owner: string | null;
  assetsSubs: {
    __typename: "ModelCourseAssetSubsConnection";
    items: Array<{
      __typename: "CourseAssetSubs";
      id: string;
      courseSubsId: string;
      assetId: string;
      userId: string;
      assetType: elAssetType;
      title: string | null;
      description: string | null;
      category: string | null;
      metaData: string | null;
      status: elSubStatus;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
      owner: string | null;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
};

export type SubsCourseByIdsQuery = {
  __typename: "ModelCourseSubsConnection";
  items: Array<{
    __typename: "CourseSubs";
    id: string;
    courseId: string;
    userId: string;
    title: string | null;
    description: string | null;
    category: string | null;
    level: string | null;
    tags: string | null;
    metaData: string | null;
    status: elSubStatus;
    course: {
      __typename: "Course";
      id: string;
      title: string;
      description: string;
      categoryId: string;
      coverImage: string | null;
      timeAprox: string;
      status: elStatus;
      coverImage1: string | null;
      coverImage2: string | null;
      coverImage3: string | null;
      courseType: CourseType | null;
      courseUrl: string | null;
      overviewSourceMedia: string | null;
      overviewFileName: string | null;
      overviewFileType: string | null;
      overviewFileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      courseTrend: elCourseTrend | null;
      learnDescription: string | null;
      requirements: string | null;
      level: string | null;
      tags: string | null;
      trending: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
    owner: string | null;
    assetsSubs: {
      __typename: "ModelCourseAssetSubsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SubsByUserQuery = {
  __typename: "ModelCourseSubsConnection";
  items: Array<{
    __typename: "CourseSubs";
    id: string;
    courseId: string;
    userId: string;
    title: string | null;
    description: string | null;
    category: string | null;
    level: string | null;
    tags: string | null;
    metaData: string | null;
    status: elSubStatus;
    course: {
      __typename: "Course";
      id: string;
      title: string;
      description: string;
      categoryId: string;
      coverImage: string | null;
      timeAprox: string;
      status: elStatus;
      coverImage1: string | null;
      coverImage2: string | null;
      coverImage3: string | null;
      courseType: CourseType | null;
      courseUrl: string | null;
      overviewSourceMedia: string | null;
      overviewFileName: string | null;
      overviewFileType: string | null;
      overviewFileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      courseTrend: elCourseTrend | null;
      learnDescription: string | null;
      requirements: string | null;
      level: string | null;
      tags: string | null;
      trending: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
    owner: string | null;
    assetsSubs: {
      __typename: "ModelCourseAssetSubsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SubsCourseByStatusQuery = {
  __typename: "ModelCourseSubsConnection";
  items: Array<{
    __typename: "CourseSubs";
    id: string;
    courseId: string;
    userId: string;
    title: string | null;
    description: string | null;
    category: string | null;
    level: string | null;
    tags: string | null;
    metaData: string | null;
    status: elSubStatus;
    course: {
      __typename: "Course";
      id: string;
      title: string;
      description: string;
      categoryId: string;
      coverImage: string | null;
      timeAprox: string;
      status: elStatus;
      coverImage1: string | null;
      coverImage2: string | null;
      coverImage3: string | null;
      courseType: CourseType | null;
      courseUrl: string | null;
      overviewSourceMedia: string | null;
      overviewFileName: string | null;
      overviewFileType: string | null;
      overviewFileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      courseTrend: elCourseTrend | null;
      learnDescription: string | null;
      requirements: string | null;
      level: string | null;
      tags: string | null;
      trending: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
    owner: string | null;
    assetsSubs: {
      __typename: "ModelCourseAssetSubsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SyncCourseSubsQuery = {
  __typename: "ModelCourseSubsConnection";
  items: Array<{
    __typename: "CourseSubs";
    id: string;
    courseId: string;
    userId: string;
    title: string | null;
    description: string | null;
    category: string | null;
    level: string | null;
    tags: string | null;
    metaData: string | null;
    status: elSubStatus;
    course: {
      __typename: "Course";
      id: string;
      title: string;
      description: string;
      categoryId: string;
      coverImage: string | null;
      timeAprox: string;
      status: elStatus;
      coverImage1: string | null;
      coverImage2: string | null;
      coverImage3: string | null;
      courseType: CourseType | null;
      courseUrl: string | null;
      overviewSourceMedia: string | null;
      overviewFileName: string | null;
      overviewFileType: string | null;
      overviewFileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      courseTrend: elCourseTrend | null;
      learnDescription: string | null;
      requirements: string | null;
      level: string | null;
      tags: string | null;
      trending: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
    owner: string | null;
    assetsSubs: {
      __typename: "ModelCourseAssetSubsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type GetCourseAssetSubsQuery = {
  __typename: "CourseAssetSubs";
  id: string;
  courseSubsId: string;
  assetId: string;
  userId: string;
  assetType: elAssetType;
  title: string | null;
  description: string | null;
  category: string | null;
  metaData: string | null;
  status: elSubStatus;
  asset: {
    __typename: "CourseAssets";
    id: string;
    courseId: string;
    assetId: string | null;
    assetType: elAssetType;
    title: string;
    description: string;
    order: number | null;
    timeAprox: string | null;
    starred: boolean | null;
    important: boolean | null;
    showAsOverview: boolean | null;
    showAsFree: boolean | null;
    nextStep: string | null;
    prevStep: string | null;
    status: elStatus;
    updatedAt: string | null;
    createdAt: string | null;
    course: {
      __typename: "Course";
      id: string;
      title: string;
      description: string;
      categoryId: string;
      coverImage: string | null;
      timeAprox: string;
      status: elStatus;
      coverImage1: string | null;
      coverImage2: string | null;
      coverImage3: string | null;
      courseType: CourseType | null;
      courseUrl: string | null;
      overviewSourceMedia: string | null;
      overviewFileName: string | null;
      overviewFileType: string | null;
      overviewFileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      courseTrend: elCourseTrend | null;
      learnDescription: string | null;
      requirements: string | null;
      level: string | null;
      tags: string | null;
      trending: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    topic: {
      __typename: "Content";
      id: string;
      courseId: string;
      contentType: ContentType;
      media: string | null;
      sourceMedia: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    quiz: {
      __typename: "Quiz";
      id: string;
      courseId: string;
      questionsRandom: number | null;
      passingScore: number | null;
      noAttempts: number | null;
      score: number | null;
      labelWhenPassed: string | null;
      labelWhenFailed: string | null;
      questionFeedback: boolean | null;
      updatedAt: string | null;
      createdAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    attachments: {
      __typename: "ModelCourseAssetsResourceConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
  assetsSubs: {
    __typename: "CourseSubs";
    id: string;
    courseId: string;
    userId: string;
    title: string | null;
    description: string | null;
    category: string | null;
    level: string | null;
    tags: string | null;
    metaData: string | null;
    status: elSubStatus;
    course: {
      __typename: "Course";
      id: string;
      title: string;
      description: string;
      categoryId: string;
      coverImage: string | null;
      timeAprox: string;
      status: elStatus;
      coverImage1: string | null;
      coverImage2: string | null;
      coverImage3: string | null;
      courseType: CourseType | null;
      courseUrl: string | null;
      overviewSourceMedia: string | null;
      overviewFileName: string | null;
      overviewFileType: string | null;
      overviewFileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      courseTrend: elCourseTrend | null;
      learnDescription: string | null;
      requirements: string | null;
      level: string | null;
      tags: string | null;
      trending: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
    owner: string | null;
    assetsSubs: {
      __typename: "ModelCourseAssetSubsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
  } | null;
  owner: string | null;
};

export type ListCourseAssetSubssQuery = {
  __typename: "ModelCourseAssetSubsConnection";
  items: Array<{
    __typename: "CourseAssetSubs";
    id: string;
    courseSubsId: string;
    assetId: string;
    userId: string;
    assetType: elAssetType;
    title: string | null;
    description: string | null;
    category: string | null;
    metaData: string | null;
    status: elSubStatus;
    asset: {
      __typename: "CourseAssets";
      id: string;
      courseId: string;
      assetId: string | null;
      assetType: elAssetType;
      title: string;
      description: string;
      order: number | null;
      timeAprox: string | null;
      starred: boolean | null;
      important: boolean | null;
      showAsOverview: boolean | null;
      showAsFree: boolean | null;
      nextStep: string | null;
      prevStep: string | null;
      status: elStatus;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
    assetsSubs: {
      __typename: "CourseSubs";
      id: string;
      courseId: string;
      userId: string;
      title: string | null;
      description: string | null;
      category: string | null;
      level: string | null;
      tags: string | null;
      metaData: string | null;
      status: elSubStatus;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
      owner: string | null;
    } | null;
    owner: string | null;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SubsCourseAssetByIdsQuery = {
  __typename: "ModelCourseAssetSubsConnection";
  items: Array<{
    __typename: "CourseAssetSubs";
    id: string;
    courseSubsId: string;
    assetId: string;
    userId: string;
    assetType: elAssetType;
    title: string | null;
    description: string | null;
    category: string | null;
    metaData: string | null;
    status: elSubStatus;
    asset: {
      __typename: "CourseAssets";
      id: string;
      courseId: string;
      assetId: string | null;
      assetType: elAssetType;
      title: string;
      description: string;
      order: number | null;
      timeAprox: string | null;
      starred: boolean | null;
      important: boolean | null;
      showAsOverview: boolean | null;
      showAsFree: boolean | null;
      nextStep: string | null;
      prevStep: string | null;
      status: elStatus;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
    assetsSubs: {
      __typename: "CourseSubs";
      id: string;
      courseId: string;
      userId: string;
      title: string | null;
      description: string | null;
      category: string | null;
      level: string | null;
      tags: string | null;
      metaData: string | null;
      status: elSubStatus;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
      owner: string | null;
    } | null;
    owner: string | null;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SubsCourseAssetsByCourseQuery = {
  __typename: "ModelCourseAssetSubsConnection";
  items: Array<{
    __typename: "CourseAssetSubs";
    id: string;
    courseSubsId: string;
    assetId: string;
    userId: string;
    assetType: elAssetType;
    title: string | null;
    description: string | null;
    category: string | null;
    metaData: string | null;
    status: elSubStatus;
    asset: {
      __typename: "CourseAssets";
      id: string;
      courseId: string;
      assetId: string | null;
      assetType: elAssetType;
      title: string;
      description: string;
      order: number | null;
      timeAprox: string | null;
      starred: boolean | null;
      important: boolean | null;
      showAsOverview: boolean | null;
      showAsFree: boolean | null;
      nextStep: string | null;
      prevStep: string | null;
      status: elStatus;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
    assetsSubs: {
      __typename: "CourseSubs";
      id: string;
      courseId: string;
      userId: string;
      title: string | null;
      description: string | null;
      category: string | null;
      level: string | null;
      tags: string | null;
      metaData: string | null;
      status: elSubStatus;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
      owner: string | null;
    } | null;
    owner: string | null;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SubsCourseAssetsByUserQuery = {
  __typename: "ModelCourseAssetSubsConnection";
  items: Array<{
    __typename: "CourseAssetSubs";
    id: string;
    courseSubsId: string;
    assetId: string;
    userId: string;
    assetType: elAssetType;
    title: string | null;
    description: string | null;
    category: string | null;
    metaData: string | null;
    status: elSubStatus;
    asset: {
      __typename: "CourseAssets";
      id: string;
      courseId: string;
      assetId: string | null;
      assetType: elAssetType;
      title: string;
      description: string;
      order: number | null;
      timeAprox: string | null;
      starred: boolean | null;
      important: boolean | null;
      showAsOverview: boolean | null;
      showAsFree: boolean | null;
      nextStep: string | null;
      prevStep: string | null;
      status: elStatus;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
    assetsSubs: {
      __typename: "CourseSubs";
      id: string;
      courseId: string;
      userId: string;
      title: string | null;
      description: string | null;
      category: string | null;
      level: string | null;
      tags: string | null;
      metaData: string | null;
      status: elSubStatus;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
      owner: string | null;
    } | null;
    owner: string | null;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SubsCourseAssetsByStatusQuery = {
  __typename: "ModelCourseAssetSubsConnection";
  items: Array<{
    __typename: "CourseAssetSubs";
    id: string;
    courseSubsId: string;
    assetId: string;
    userId: string;
    assetType: elAssetType;
    title: string | null;
    description: string | null;
    category: string | null;
    metaData: string | null;
    status: elSubStatus;
    asset: {
      __typename: "CourseAssets";
      id: string;
      courseId: string;
      assetId: string | null;
      assetType: elAssetType;
      title: string;
      description: string;
      order: number | null;
      timeAprox: string | null;
      starred: boolean | null;
      important: boolean | null;
      showAsOverview: boolean | null;
      showAsFree: boolean | null;
      nextStep: string | null;
      prevStep: string | null;
      status: elStatus;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
    assetsSubs: {
      __typename: "CourseSubs";
      id: string;
      courseId: string;
      userId: string;
      title: string | null;
      description: string | null;
      category: string | null;
      level: string | null;
      tags: string | null;
      metaData: string | null;
      status: elSubStatus;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
      owner: string | null;
    } | null;
    owner: string | null;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SyncCourseAssetSubsQuery = {
  __typename: "ModelCourseAssetSubsConnection";
  items: Array<{
    __typename: "CourseAssetSubs";
    id: string;
    courseSubsId: string;
    assetId: string;
    userId: string;
    assetType: elAssetType;
    title: string | null;
    description: string | null;
    category: string | null;
    metaData: string | null;
    status: elSubStatus;
    asset: {
      __typename: "CourseAssets";
      id: string;
      courseId: string;
      assetId: string | null;
      assetType: elAssetType;
      title: string;
      description: string;
      order: number | null;
      timeAprox: string | null;
      starred: boolean | null;
      important: boolean | null;
      showAsOverview: boolean | null;
      showAsFree: boolean | null;
      nextStep: string | null;
      prevStep: string | null;
      status: elStatus;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
    assetsSubs: {
      __typename: "CourseSubs";
      id: string;
      courseId: string;
      userId: string;
      title: string | null;
      description: string | null;
      category: string | null;
      level: string | null;
      tags: string | null;
      metaData: string | null;
      status: elSubStatus;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
      owner: string | null;
    } | null;
    owner: string | null;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type OnCreateSessionSubscription = {
  __typename: "Session";
  id: string;
  userId: string;
  location: string | null;
  event: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateSessionSubscription = {
  __typename: "Session";
  id: string;
  userId: string;
  location: string | null;
  event: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteSessionSubscription = {
  __typename: "Session";
  id: string;
  userId: string;
  location: string | null;
  event: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateUserSettingsSubscription = {
  __typename: "UserSettings";
  id: string;
  userId: string;
  pageSize: number | null;
  notiType: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateUserSettingsSubscription = {
  __typename: "UserSettings";
  id: string;
  userId: string;
  pageSize: number | null;
  notiType: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteUserSettingsSubscription = {
  __typename: "UserSettings";
  id: string;
  userId: string;
  pageSize: number | null;
  notiType: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateNotificationSubscription = {
  __typename: "Notification";
  id: string;
  typeNotify: string | null;
  userId: string | null;
  subject: string | null;
  body: string | null;
  localUrl: string | null;
  renotify: boolean | null;
  silent: boolean | null;
  dateFor: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateNotificationSubscription = {
  __typename: "Notification";
  id: string;
  typeNotify: string | null;
  userId: string | null;
  subject: string | null;
  body: string | null;
  localUrl: string | null;
  renotify: boolean | null;
  silent: boolean | null;
  dateFor: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteNotificationSubscription = {
  __typename: "Notification";
  id: string;
  typeNotify: string | null;
  userId: string | null;
  subject: string | null;
  body: string | null;
  localUrl: string | null;
  renotify: boolean | null;
  silent: boolean | null;
  dateFor: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateUserPrivacySubscription = {
  __typename: "UserPrivacy";
  id: string;
  userId: string;
  profileShared: string;
  bgPhoto: string | null;
  headLine: string | null;
  secondHeadLine: string | null;
  titleLine: string | null;
  bioLine: string | null;
  showEmail: boolean | null;
  showPhone: boolean | null;
  showEmailOn: string | null;
  contactDirectlyByEmail: boolean | null;
  showConnectionsTo: string | null;
  oaConnInvitationAccepted: boolean | null;
  oaEventUpdates: boolean | null;
  oaUpdatesFromConnections: boolean | null;
  oaBreakingNews: boolean | null;
  oaTopsPicks: boolean | null;
  oaWhoViewedYourProfile: boolean | null;
  oaYourNewFollowers: boolean | null;
  emailEventsInvitations: boolean | null;
  emailEventsUpdates: boolean | null;
  emailNewsInvitations: boolean | null;
  emailNewsSubscriptions: boolean | null;
  whoRequestForNotifications: boolean | null;
  whoInvitationsToAttend: boolean | null;
  locMap: string | null;
  location: string | null;
  locTitle: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateUserPrivacySubscription = {
  __typename: "UserPrivacy";
  id: string;
  userId: string;
  profileShared: string;
  bgPhoto: string | null;
  headLine: string | null;
  secondHeadLine: string | null;
  titleLine: string | null;
  bioLine: string | null;
  showEmail: boolean | null;
  showPhone: boolean | null;
  showEmailOn: string | null;
  contactDirectlyByEmail: boolean | null;
  showConnectionsTo: string | null;
  oaConnInvitationAccepted: boolean | null;
  oaEventUpdates: boolean | null;
  oaUpdatesFromConnections: boolean | null;
  oaBreakingNews: boolean | null;
  oaTopsPicks: boolean | null;
  oaWhoViewedYourProfile: boolean | null;
  oaYourNewFollowers: boolean | null;
  emailEventsInvitations: boolean | null;
  emailEventsUpdates: boolean | null;
  emailNewsInvitations: boolean | null;
  emailNewsSubscriptions: boolean | null;
  whoRequestForNotifications: boolean | null;
  whoInvitationsToAttend: boolean | null;
  locMap: string | null;
  location: string | null;
  locTitle: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteUserPrivacySubscription = {
  __typename: "UserPrivacy";
  id: string;
  userId: string;
  profileShared: string;
  bgPhoto: string | null;
  headLine: string | null;
  secondHeadLine: string | null;
  titleLine: string | null;
  bioLine: string | null;
  showEmail: boolean | null;
  showPhone: boolean | null;
  showEmailOn: string | null;
  contactDirectlyByEmail: boolean | null;
  showConnectionsTo: string | null;
  oaConnInvitationAccepted: boolean | null;
  oaEventUpdates: boolean | null;
  oaUpdatesFromConnections: boolean | null;
  oaBreakingNews: boolean | null;
  oaTopsPicks: boolean | null;
  oaWhoViewedYourProfile: boolean | null;
  oaYourNewFollowers: boolean | null;
  emailEventsInvitations: boolean | null;
  emailEventsUpdates: boolean | null;
  emailNewsInvitations: boolean | null;
  emailNewsSubscriptions: boolean | null;
  whoRequestForNotifications: boolean | null;
  whoInvitationsToAttend: boolean | null;
  locMap: string | null;
  location: string | null;
  locTitle: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateCourseCategorySubscription = {
  __typename: "CourseCategory";
  id: string;
  category: string;
  createdAt: string | null;
  updatedAt: string | null;
  status: elStatus | null;
  courses: {
    __typename: "ModelCourseConnection";
    items: Array<{
      __typename: "Course";
      id: string;
      title: string;
      description: string;
      categoryId: string;
      coverImage: string | null;
      timeAprox: string;
      status: elStatus;
      coverImage1: string | null;
      coverImage2: string | null;
      coverImage3: string | null;
      courseType: CourseType | null;
      courseUrl: string | null;
      overviewSourceMedia: string | null;
      overviewFileName: string | null;
      overviewFileType: string | null;
      overviewFileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      courseTrend: elCourseTrend | null;
      learnDescription: string | null;
      requirements: string | null;
      level: string | null;
      tags: string | null;
      trending: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateCourseCategorySubscription = {
  __typename: "CourseCategory";
  id: string;
  category: string;
  createdAt: string | null;
  updatedAt: string | null;
  status: elStatus | null;
  courses: {
    __typename: "ModelCourseConnection";
    items: Array<{
      __typename: "Course";
      id: string;
      title: string;
      description: string;
      categoryId: string;
      coverImage: string | null;
      timeAprox: string;
      status: elStatus;
      coverImage1: string | null;
      coverImage2: string | null;
      coverImage3: string | null;
      courseType: CourseType | null;
      courseUrl: string | null;
      overviewSourceMedia: string | null;
      overviewFileName: string | null;
      overviewFileType: string | null;
      overviewFileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      courseTrend: elCourseTrend | null;
      learnDescription: string | null;
      requirements: string | null;
      level: string | null;
      tags: string | null;
      trending: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteCourseCategorySubscription = {
  __typename: "CourseCategory";
  id: string;
  category: string;
  createdAt: string | null;
  updatedAt: string | null;
  status: elStatus | null;
  courses: {
    __typename: "ModelCourseConnection";
    items: Array<{
      __typename: "Course";
      id: string;
      title: string;
      description: string;
      categoryId: string;
      coverImage: string | null;
      timeAprox: string;
      status: elStatus;
      coverImage1: string | null;
      coverImage2: string | null;
      coverImage3: string | null;
      courseType: CourseType | null;
      courseUrl: string | null;
      overviewSourceMedia: string | null;
      overviewFileName: string | null;
      overviewFileType: string | null;
      overviewFileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      courseTrend: elCourseTrend | null;
      learnDescription: string | null;
      requirements: string | null;
      level: string | null;
      tags: string | null;
      trending: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateCourseResourceSubscription = {
  __typename: "CourseResource";
  id: string;
  courseId: string;
  description: string;
  contentType: ResourceType;
  sourceCode: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  status: elStatus;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateCourseResourceSubscription = {
  __typename: "CourseResource";
  id: string;
  courseId: string;
  description: string;
  contentType: ResourceType;
  sourceCode: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  status: elStatus;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteCourseResourceSubscription = {
  __typename: "CourseResource";
  id: string;
  courseId: string;
  description: string;
  contentType: ResourceType;
  sourceCode: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  status: elStatus;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateContentSubscription = {
  __typename: "Content";
  id: string;
  courseId: string;
  contentType: ContentType;
  media: string | null;
  sourceMedia: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  transcoderMeta: string | null;
  transcoderJobId: string | null;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateContentSubscription = {
  __typename: "Content";
  id: string;
  courseId: string;
  contentType: ContentType;
  media: string | null;
  sourceMedia: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  transcoderMeta: string | null;
  transcoderJobId: string | null;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteContentSubscription = {
  __typename: "Content";
  id: string;
  courseId: string;
  contentType: ContentType;
  media: string | null;
  sourceMedia: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  transcoderMeta: string | null;
  transcoderJobId: string | null;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateContentResourceSubscription = {
  __typename: "ContentResource";
  id: string;
  courseId: string;
  description: string;
  contentType: ResourceType;
  sourceCode: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  status: elStatus;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateContentResourceSubscription = {
  __typename: "ContentResource";
  id: string;
  courseId: string;
  description: string;
  contentType: ResourceType;
  sourceCode: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  status: elStatus;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteContentResourceSubscription = {
  __typename: "ContentResource";
  id: string;
  courseId: string;
  description: string;
  contentType: ResourceType;
  sourceCode: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  status: elStatus;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateCourseSubscription = {
  __typename: "Course";
  id: string;
  title: string;
  description: string;
  category: {
    __typename: "CourseCategory";
    id: string;
    category: string;
    createdAt: string | null;
    updatedAt: string | null;
    status: elStatus | null;
    courses: {
      __typename: "ModelCourseConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  };
  categoryId: string;
  coverImage: string | null;
  timeAprox: string;
  status: elStatus;
  coverImage1: string | null;
  coverImage2: string | null;
  coverImage3: string | null;
  courseType: CourseType | null;
  courseUrl: string | null;
  overviewSourceMedia: string | null;
  overviewFileName: string | null;
  overviewFileType: string | null;
  overviewFileSize: string | null;
  transcoderMeta: string | null;
  transcoderJobId: string | null;
  courseTrend: elCourseTrend | null;
  learnDescription: string | null;
  requirements: string | null;
  level: string | null;
  tags: string | null;
  trending: string | null;
  updatedAt: string | null;
  createdAt: string | null;
  assets: {
    __typename: "ModelCourseAssetsConnection";
    items: Array<{
      __typename: "CourseAssets";
      id: string;
      courseId: string;
      assetId: string | null;
      assetType: elAssetType;
      title: string;
      description: string;
      order: number | null;
      timeAprox: string | null;
      starred: boolean | null;
      important: boolean | null;
      showAsOverview: boolean | null;
      showAsFree: boolean | null;
      nextStep: string | null;
      prevStep: string | null;
      status: elStatus;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateCourseSubscription = {
  __typename: "Course";
  id: string;
  title: string;
  description: string;
  category: {
    __typename: "CourseCategory";
    id: string;
    category: string;
    createdAt: string | null;
    updatedAt: string | null;
    status: elStatus | null;
    courses: {
      __typename: "ModelCourseConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  };
  categoryId: string;
  coverImage: string | null;
  timeAprox: string;
  status: elStatus;
  coverImage1: string | null;
  coverImage2: string | null;
  coverImage3: string | null;
  courseType: CourseType | null;
  courseUrl: string | null;
  overviewSourceMedia: string | null;
  overviewFileName: string | null;
  overviewFileType: string | null;
  overviewFileSize: string | null;
  transcoderMeta: string | null;
  transcoderJobId: string | null;
  courseTrend: elCourseTrend | null;
  learnDescription: string | null;
  requirements: string | null;
  level: string | null;
  tags: string | null;
  trending: string | null;
  updatedAt: string | null;
  createdAt: string | null;
  assets: {
    __typename: "ModelCourseAssetsConnection";
    items: Array<{
      __typename: "CourseAssets";
      id: string;
      courseId: string;
      assetId: string | null;
      assetType: elAssetType;
      title: string;
      description: string;
      order: number | null;
      timeAprox: string | null;
      starred: boolean | null;
      important: boolean | null;
      showAsOverview: boolean | null;
      showAsFree: boolean | null;
      nextStep: string | null;
      prevStep: string | null;
      status: elStatus;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteCourseSubscription = {
  __typename: "Course";
  id: string;
  title: string;
  description: string;
  category: {
    __typename: "CourseCategory";
    id: string;
    category: string;
    createdAt: string | null;
    updatedAt: string | null;
    status: elStatus | null;
    courses: {
      __typename: "ModelCourseConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  };
  categoryId: string;
  coverImage: string | null;
  timeAprox: string;
  status: elStatus;
  coverImage1: string | null;
  coverImage2: string | null;
  coverImage3: string | null;
  courseType: CourseType | null;
  courseUrl: string | null;
  overviewSourceMedia: string | null;
  overviewFileName: string | null;
  overviewFileType: string | null;
  overviewFileSize: string | null;
  transcoderMeta: string | null;
  transcoderJobId: string | null;
  courseTrend: elCourseTrend | null;
  learnDescription: string | null;
  requirements: string | null;
  level: string | null;
  tags: string | null;
  trending: string | null;
  updatedAt: string | null;
  createdAt: string | null;
  assets: {
    __typename: "ModelCourseAssetsConnection";
    items: Array<{
      __typename: "CourseAssets";
      id: string;
      courseId: string;
      assetId: string | null;
      assetType: elAssetType;
      title: string;
      description: string;
      order: number | null;
      timeAprox: string | null;
      starred: boolean | null;
      important: boolean | null;
      showAsOverview: boolean | null;
      showAsFree: boolean | null;
      nextStep: string | null;
      prevStep: string | null;
      status: elStatus;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateContentSubsSubscription = {
  __typename: "ContentSubs";
  id: string;
  contentId: string;
  userId: string;
  metaData: string | null;
  status: elSubStatus;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateContentSubsSubscription = {
  __typename: "ContentSubs";
  id: string;
  contentId: string;
  userId: string;
  metaData: string | null;
  status: elSubStatus;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteContentSubsSubscription = {
  __typename: "ContentSubs";
  id: string;
  contentId: string;
  userId: string;
  metaData: string | null;
  status: elSubStatus;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateCourseSubsSubscription = {
  __typename: "CourseSubs";
  id: string;
  courseId: string;
  userId: string;
  title: string | null;
  description: string | null;
  category: string | null;
  level: string | null;
  tags: string | null;
  metaData: string | null;
  status: elSubStatus;
  course: {
    __typename: "Course";
    id: string;
    title: string;
    description: string;
    category: {
      __typename: "CourseCategory";
      id: string;
      category: string;
      createdAt: string | null;
      updatedAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    };
    categoryId: string;
    coverImage: string | null;
    timeAprox: string;
    status: elStatus;
    coverImage1: string | null;
    coverImage2: string | null;
    coverImage3: string | null;
    courseType: CourseType | null;
    courseUrl: string | null;
    overviewSourceMedia: string | null;
    overviewFileName: string | null;
    overviewFileType: string | null;
    overviewFileSize: string | null;
    transcoderMeta: string | null;
    transcoderJobId: string | null;
    courseTrend: elCourseTrend | null;
    learnDescription: string | null;
    requirements: string | null;
    level: string | null;
    tags: string | null;
    trending: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    assets: {
      __typename: "ModelCourseAssetsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
  owner: string | null;
  assetsSubs: {
    __typename: "ModelCourseAssetSubsConnection";
    items: Array<{
      __typename: "CourseAssetSubs";
      id: string;
      courseSubsId: string;
      assetId: string;
      userId: string;
      assetType: elAssetType;
      title: string | null;
      description: string | null;
      category: string | null;
      metaData: string | null;
      status: elSubStatus;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
      owner: string | null;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
};

export type OnUpdateCourseSubsSubscription = {
  __typename: "CourseSubs";
  id: string;
  courseId: string;
  userId: string;
  title: string | null;
  description: string | null;
  category: string | null;
  level: string | null;
  tags: string | null;
  metaData: string | null;
  status: elSubStatus;
  course: {
    __typename: "Course";
    id: string;
    title: string;
    description: string;
    category: {
      __typename: "CourseCategory";
      id: string;
      category: string;
      createdAt: string | null;
      updatedAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    };
    categoryId: string;
    coverImage: string | null;
    timeAprox: string;
    status: elStatus;
    coverImage1: string | null;
    coverImage2: string | null;
    coverImage3: string | null;
    courseType: CourseType | null;
    courseUrl: string | null;
    overviewSourceMedia: string | null;
    overviewFileName: string | null;
    overviewFileType: string | null;
    overviewFileSize: string | null;
    transcoderMeta: string | null;
    transcoderJobId: string | null;
    courseTrend: elCourseTrend | null;
    learnDescription: string | null;
    requirements: string | null;
    level: string | null;
    tags: string | null;
    trending: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    assets: {
      __typename: "ModelCourseAssetsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
  owner: string | null;
  assetsSubs: {
    __typename: "ModelCourseAssetSubsConnection";
    items: Array<{
      __typename: "CourseAssetSubs";
      id: string;
      courseSubsId: string;
      assetId: string;
      userId: string;
      assetType: elAssetType;
      title: string | null;
      description: string | null;
      category: string | null;
      metaData: string | null;
      status: elSubStatus;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
      owner: string | null;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
};

export type OnDeleteCourseSubsSubscription = {
  __typename: "CourseSubs";
  id: string;
  courseId: string;
  userId: string;
  title: string | null;
  description: string | null;
  category: string | null;
  level: string | null;
  tags: string | null;
  metaData: string | null;
  status: elSubStatus;
  course: {
    __typename: "Course";
    id: string;
    title: string;
    description: string;
    category: {
      __typename: "CourseCategory";
      id: string;
      category: string;
      createdAt: string | null;
      updatedAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    };
    categoryId: string;
    coverImage: string | null;
    timeAprox: string;
    status: elStatus;
    coverImage1: string | null;
    coverImage2: string | null;
    coverImage3: string | null;
    courseType: CourseType | null;
    courseUrl: string | null;
    overviewSourceMedia: string | null;
    overviewFileName: string | null;
    overviewFileType: string | null;
    overviewFileSize: string | null;
    transcoderMeta: string | null;
    transcoderJobId: string | null;
    courseTrend: elCourseTrend | null;
    learnDescription: string | null;
    requirements: string | null;
    level: string | null;
    tags: string | null;
    trending: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    assets: {
      __typename: "ModelCourseAssetsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
  owner: string | null;
  assetsSubs: {
    __typename: "ModelCourseAssetSubsConnection";
    items: Array<{
      __typename: "CourseAssetSubs";
      id: string;
      courseSubsId: string;
      assetId: string;
      userId: string;
      assetType: elAssetType;
      title: string | null;
      description: string | null;
      category: string | null;
      metaData: string | null;
      status: elSubStatus;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
      owner: string | null;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
};

export type OnCreateCourseAssetsSubscription = {
  __typename: "CourseAssets";
  id: string;
  courseId: string;
  assetId: string | null;
  assetType: elAssetType;
  title: string;
  description: string;
  order: number | null;
  timeAprox: string | null;
  starred: boolean | null;
  important: boolean | null;
  showAsOverview: boolean | null;
  showAsFree: boolean | null;
  nextStep: string | null;
  prevStep: string | null;
  status: elStatus;
  updatedAt: string | null;
  createdAt: string | null;
  course: {
    __typename: "Course";
    id: string;
    title: string;
    description: string;
    category: {
      __typename: "CourseCategory";
      id: string;
      category: string;
      createdAt: string | null;
      updatedAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    };
    categoryId: string;
    coverImage: string | null;
    timeAprox: string;
    status: elStatus;
    coverImage1: string | null;
    coverImage2: string | null;
    coverImage3: string | null;
    courseType: CourseType | null;
    courseUrl: string | null;
    overviewSourceMedia: string | null;
    overviewFileName: string | null;
    overviewFileType: string | null;
    overviewFileSize: string | null;
    transcoderMeta: string | null;
    transcoderJobId: string | null;
    courseTrend: elCourseTrend | null;
    learnDescription: string | null;
    requirements: string | null;
    level: string | null;
    tags: string | null;
    trending: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    assets: {
      __typename: "ModelCourseAssetsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  topic: {
    __typename: "Content";
    id: string;
    courseId: string;
    contentType: ContentType;
    media: string | null;
    sourceMedia: string | null;
    fileName: string | null;
    fileType: string | null;
    fileSize: string | null;
    transcoderMeta: string | null;
    transcoderJobId: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  quiz: {
    __typename: "Quiz";
    id: string;
    courseId: string;
    questionsRandom: number | null;
    passingScore: number | null;
    noAttempts: number | null;
    score: number | null;
    labelWhenPassed: string | null;
    labelWhenFailed: string | null;
    questionFeedback: boolean | null;
    updatedAt: string | null;
    createdAt: string | null;
    status: elStatus | null;
    questions: {
      __typename: "ModelQuizQuestionConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  attachments: {
    __typename: "ModelCourseAssetsResourceConnection";
    items: Array<{
      __typename: "CourseAssetsResource";
      id: string;
      courseId: string;
      courseAssetId: string;
      description: string;
      contentType: ResourceType;
      sourceCode: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      status: elStatus;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateCourseAssetsSubscription = {
  __typename: "CourseAssets";
  id: string;
  courseId: string;
  assetId: string | null;
  assetType: elAssetType;
  title: string;
  description: string;
  order: number | null;
  timeAprox: string | null;
  starred: boolean | null;
  important: boolean | null;
  showAsOverview: boolean | null;
  showAsFree: boolean | null;
  nextStep: string | null;
  prevStep: string | null;
  status: elStatus;
  updatedAt: string | null;
  createdAt: string | null;
  course: {
    __typename: "Course";
    id: string;
    title: string;
    description: string;
    category: {
      __typename: "CourseCategory";
      id: string;
      category: string;
      createdAt: string | null;
      updatedAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    };
    categoryId: string;
    coverImage: string | null;
    timeAprox: string;
    status: elStatus;
    coverImage1: string | null;
    coverImage2: string | null;
    coverImage3: string | null;
    courseType: CourseType | null;
    courseUrl: string | null;
    overviewSourceMedia: string | null;
    overviewFileName: string | null;
    overviewFileType: string | null;
    overviewFileSize: string | null;
    transcoderMeta: string | null;
    transcoderJobId: string | null;
    courseTrend: elCourseTrend | null;
    learnDescription: string | null;
    requirements: string | null;
    level: string | null;
    tags: string | null;
    trending: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    assets: {
      __typename: "ModelCourseAssetsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  topic: {
    __typename: "Content";
    id: string;
    courseId: string;
    contentType: ContentType;
    media: string | null;
    sourceMedia: string | null;
    fileName: string | null;
    fileType: string | null;
    fileSize: string | null;
    transcoderMeta: string | null;
    transcoderJobId: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  quiz: {
    __typename: "Quiz";
    id: string;
    courseId: string;
    questionsRandom: number | null;
    passingScore: number | null;
    noAttempts: number | null;
    score: number | null;
    labelWhenPassed: string | null;
    labelWhenFailed: string | null;
    questionFeedback: boolean | null;
    updatedAt: string | null;
    createdAt: string | null;
    status: elStatus | null;
    questions: {
      __typename: "ModelQuizQuestionConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  attachments: {
    __typename: "ModelCourseAssetsResourceConnection";
    items: Array<{
      __typename: "CourseAssetsResource";
      id: string;
      courseId: string;
      courseAssetId: string;
      description: string;
      contentType: ResourceType;
      sourceCode: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      status: elStatus;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteCourseAssetsSubscription = {
  __typename: "CourseAssets";
  id: string;
  courseId: string;
  assetId: string | null;
  assetType: elAssetType;
  title: string;
  description: string;
  order: number | null;
  timeAprox: string | null;
  starred: boolean | null;
  important: boolean | null;
  showAsOverview: boolean | null;
  showAsFree: boolean | null;
  nextStep: string | null;
  prevStep: string | null;
  status: elStatus;
  updatedAt: string | null;
  createdAt: string | null;
  course: {
    __typename: "Course";
    id: string;
    title: string;
    description: string;
    category: {
      __typename: "CourseCategory";
      id: string;
      category: string;
      createdAt: string | null;
      updatedAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    };
    categoryId: string;
    coverImage: string | null;
    timeAprox: string;
    status: elStatus;
    coverImage1: string | null;
    coverImage2: string | null;
    coverImage3: string | null;
    courseType: CourseType | null;
    courseUrl: string | null;
    overviewSourceMedia: string | null;
    overviewFileName: string | null;
    overviewFileType: string | null;
    overviewFileSize: string | null;
    transcoderMeta: string | null;
    transcoderJobId: string | null;
    courseTrend: elCourseTrend | null;
    learnDescription: string | null;
    requirements: string | null;
    level: string | null;
    tags: string | null;
    trending: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    assets: {
      __typename: "ModelCourseAssetsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  topic: {
    __typename: "Content";
    id: string;
    courseId: string;
    contentType: ContentType;
    media: string | null;
    sourceMedia: string | null;
    fileName: string | null;
    fileType: string | null;
    fileSize: string | null;
    transcoderMeta: string | null;
    transcoderJobId: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  quiz: {
    __typename: "Quiz";
    id: string;
    courseId: string;
    questionsRandom: number | null;
    passingScore: number | null;
    noAttempts: number | null;
    score: number | null;
    labelWhenPassed: string | null;
    labelWhenFailed: string | null;
    questionFeedback: boolean | null;
    updatedAt: string | null;
    createdAt: string | null;
    status: elStatus | null;
    questions: {
      __typename: "ModelQuizQuestionConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  attachments: {
    __typename: "ModelCourseAssetsResourceConnection";
    items: Array<{
      __typename: "CourseAssetsResource";
      id: string;
      courseId: string;
      courseAssetId: string;
      description: string;
      contentType: ResourceType;
      sourceCode: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      status: elStatus;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateQuizSubscription = {
  __typename: "Quiz";
  id: string;
  courseId: string;
  questionsRandom: number | null;
  passingScore: number | null;
  noAttempts: number | null;
  score: number | null;
  labelWhenPassed: string | null;
  labelWhenFailed: string | null;
  questionFeedback: boolean | null;
  updatedAt: string | null;
  createdAt: string | null;
  status: elStatus | null;
  questions: {
    __typename: "ModelQuizQuestionConnection";
    items: Array<{
      __typename: "QuizQuestion";
      id: string;
      quizContentId: string;
      order: number | null;
      title: string | null;
      qType: elQuestionType;
      labelCorrectMessage: string | null;
      labelIncorrectMessage: string | null;
      questionFeedBack: string | null;
      media: string | null;
      sourceMedia: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateQuizSubscription = {
  __typename: "Quiz";
  id: string;
  courseId: string;
  questionsRandom: number | null;
  passingScore: number | null;
  noAttempts: number | null;
  score: number | null;
  labelWhenPassed: string | null;
  labelWhenFailed: string | null;
  questionFeedback: boolean | null;
  updatedAt: string | null;
  createdAt: string | null;
  status: elStatus | null;
  questions: {
    __typename: "ModelQuizQuestionConnection";
    items: Array<{
      __typename: "QuizQuestion";
      id: string;
      quizContentId: string;
      order: number | null;
      title: string | null;
      qType: elQuestionType;
      labelCorrectMessage: string | null;
      labelIncorrectMessage: string | null;
      questionFeedBack: string | null;
      media: string | null;
      sourceMedia: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteQuizSubscription = {
  __typename: "Quiz";
  id: string;
  courseId: string;
  questionsRandom: number | null;
  passingScore: number | null;
  noAttempts: number | null;
  score: number | null;
  labelWhenPassed: string | null;
  labelWhenFailed: string | null;
  questionFeedback: boolean | null;
  updatedAt: string | null;
  createdAt: string | null;
  status: elStatus | null;
  questions: {
    __typename: "ModelQuizQuestionConnection";
    items: Array<{
      __typename: "QuizQuestion";
      id: string;
      quizContentId: string;
      order: number | null;
      title: string | null;
      qType: elQuestionType;
      labelCorrectMessage: string | null;
      labelIncorrectMessage: string | null;
      questionFeedBack: string | null;
      media: string | null;
      sourceMedia: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateQuizQuestionSubscription = {
  __typename: "QuizQuestion";
  id: string;
  quizContentId: string;
  quiz: {
    __typename: "Quiz";
    id: string;
    courseId: string;
    questionsRandom: number | null;
    passingScore: number | null;
    noAttempts: number | null;
    score: number | null;
    labelWhenPassed: string | null;
    labelWhenFailed: string | null;
    questionFeedback: boolean | null;
    updatedAt: string | null;
    createdAt: string | null;
    status: elStatus | null;
    questions: {
      __typename: "ModelQuizQuestionConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  order: number | null;
  title: string | null;
  qType: elQuestionType;
  labelCorrectMessage: string | null;
  labelIncorrectMessage: string | null;
  questionFeedBack: string | null;
  media: string | null;
  sourceMedia: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  updatedAt: string | null;
  createdAt: string | null;
  status: elStatus | null;
  questionAnswers: {
    __typename: "ModelQuestionAnswersConnection";
    items: Array<{
      __typename: "QuestionAnswers";
      id: string;
      quizQuestionId: string;
      order: number | null;
      isCorrect: boolean | null;
      title: string | null;
      answer: string | null;
      answerFeedBack: string | null;
      qType: elQuestionType;
      media: string | null;
      sourceMedia: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      status: elStatus | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateQuizQuestionSubscription = {
  __typename: "QuizQuestion";
  id: string;
  quizContentId: string;
  quiz: {
    __typename: "Quiz";
    id: string;
    courseId: string;
    questionsRandom: number | null;
    passingScore: number | null;
    noAttempts: number | null;
    score: number | null;
    labelWhenPassed: string | null;
    labelWhenFailed: string | null;
    questionFeedback: boolean | null;
    updatedAt: string | null;
    createdAt: string | null;
    status: elStatus | null;
    questions: {
      __typename: "ModelQuizQuestionConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  order: number | null;
  title: string | null;
  qType: elQuestionType;
  labelCorrectMessage: string | null;
  labelIncorrectMessage: string | null;
  questionFeedBack: string | null;
  media: string | null;
  sourceMedia: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  updatedAt: string | null;
  createdAt: string | null;
  status: elStatus | null;
  questionAnswers: {
    __typename: "ModelQuestionAnswersConnection";
    items: Array<{
      __typename: "QuestionAnswers";
      id: string;
      quizQuestionId: string;
      order: number | null;
      isCorrect: boolean | null;
      title: string | null;
      answer: string | null;
      answerFeedBack: string | null;
      qType: elQuestionType;
      media: string | null;
      sourceMedia: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      status: elStatus | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteQuizQuestionSubscription = {
  __typename: "QuizQuestion";
  id: string;
  quizContentId: string;
  quiz: {
    __typename: "Quiz";
    id: string;
    courseId: string;
    questionsRandom: number | null;
    passingScore: number | null;
    noAttempts: number | null;
    score: number | null;
    labelWhenPassed: string | null;
    labelWhenFailed: string | null;
    questionFeedback: boolean | null;
    updatedAt: string | null;
    createdAt: string | null;
    status: elStatus | null;
    questions: {
      __typename: "ModelQuizQuestionConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  order: number | null;
  title: string | null;
  qType: elQuestionType;
  labelCorrectMessage: string | null;
  labelIncorrectMessage: string | null;
  questionFeedBack: string | null;
  media: string | null;
  sourceMedia: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  updatedAt: string | null;
  createdAt: string | null;
  status: elStatus | null;
  questionAnswers: {
    __typename: "ModelQuestionAnswersConnection";
    items: Array<{
      __typename: "QuestionAnswers";
      id: string;
      quizQuestionId: string;
      order: number | null;
      isCorrect: boolean | null;
      title: string | null;
      answer: string | null;
      answerFeedBack: string | null;
      qType: elQuestionType;
      media: string | null;
      sourceMedia: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      status: elStatus | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateQuestionAnswersSubscription = {
  __typename: "QuestionAnswers";
  id: string;
  quizQuestionId: string;
  question: {
    __typename: "QuizQuestion";
    id: string;
    quizContentId: string;
    quiz: {
      __typename: "Quiz";
      id: string;
      courseId: string;
      questionsRandom: number | null;
      passingScore: number | null;
      noAttempts: number | null;
      score: number | null;
      labelWhenPassed: string | null;
      labelWhenFailed: string | null;
      questionFeedback: boolean | null;
      updatedAt: string | null;
      createdAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    order: number | null;
    title: string | null;
    qType: elQuestionType;
    labelCorrectMessage: string | null;
    labelIncorrectMessage: string | null;
    questionFeedBack: string | null;
    media: string | null;
    sourceMedia: string | null;
    fileName: string | null;
    fileType: string | null;
    fileSize: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    status: elStatus | null;
    questionAnswers: {
      __typename: "ModelQuestionAnswersConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  order: number | null;
  isCorrect: boolean | null;
  title: string | null;
  answer: string | null;
  answerFeedBack: string | null;
  qType: elQuestionType;
  media: string | null;
  sourceMedia: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  status: elStatus | null;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateQuestionAnswersSubscription = {
  __typename: "QuestionAnswers";
  id: string;
  quizQuestionId: string;
  question: {
    __typename: "QuizQuestion";
    id: string;
    quizContentId: string;
    quiz: {
      __typename: "Quiz";
      id: string;
      courseId: string;
      questionsRandom: number | null;
      passingScore: number | null;
      noAttempts: number | null;
      score: number | null;
      labelWhenPassed: string | null;
      labelWhenFailed: string | null;
      questionFeedback: boolean | null;
      updatedAt: string | null;
      createdAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    order: number | null;
    title: string | null;
    qType: elQuestionType;
    labelCorrectMessage: string | null;
    labelIncorrectMessage: string | null;
    questionFeedBack: string | null;
    media: string | null;
    sourceMedia: string | null;
    fileName: string | null;
    fileType: string | null;
    fileSize: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    status: elStatus | null;
    questionAnswers: {
      __typename: "ModelQuestionAnswersConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  order: number | null;
  isCorrect: boolean | null;
  title: string | null;
  answer: string | null;
  answerFeedBack: string | null;
  qType: elQuestionType;
  media: string | null;
  sourceMedia: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  status: elStatus | null;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteQuestionAnswersSubscription = {
  __typename: "QuestionAnswers";
  id: string;
  quizQuestionId: string;
  question: {
    __typename: "QuizQuestion";
    id: string;
    quizContentId: string;
    quiz: {
      __typename: "Quiz";
      id: string;
      courseId: string;
      questionsRandom: number | null;
      passingScore: number | null;
      noAttempts: number | null;
      score: number | null;
      labelWhenPassed: string | null;
      labelWhenFailed: string | null;
      questionFeedback: boolean | null;
      updatedAt: string | null;
      createdAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    order: number | null;
    title: string | null;
    qType: elQuestionType;
    labelCorrectMessage: string | null;
    labelIncorrectMessage: string | null;
    questionFeedBack: string | null;
    media: string | null;
    sourceMedia: string | null;
    fileName: string | null;
    fileType: string | null;
    fileSize: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    status: elStatus | null;
    questionAnswers: {
      __typename: "ModelQuestionAnswersConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  order: number | null;
  isCorrect: boolean | null;
  title: string | null;
  answer: string | null;
  answerFeedBack: string | null;
  qType: elQuestionType;
  media: string | null;
  sourceMedia: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  status: elStatus | null;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateCourseTagsSubscription = {
  __typename: "CourseTags";
  id: string;
  tag: string;
  createdAt: string | null;
  updatedAt: string | null;
  status: elStatus | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateCourseTagsSubscription = {
  __typename: "CourseTags";
  id: string;
  tag: string;
  createdAt: string | null;
  updatedAt: string | null;
  status: elStatus | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteCourseTagsSubscription = {
  __typename: "CourseTags";
  id: string;
  tag: string;
  createdAt: string | null;
  updatedAt: string | null;
  status: elStatus | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateCourseAssetsResourceSubscription = {
  __typename: "CourseAssetsResource";
  id: string;
  courseId: string;
  courseAssetId: string;
  description: string;
  contentType: ResourceType;
  sourceCode: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  status: elStatus;
  updatedAt: string | null;
  createdAt: string | null;
  courseAsset: {
    __typename: "CourseAssets";
    id: string;
    courseId: string;
    assetId: string | null;
    assetType: elAssetType;
    title: string;
    description: string;
    order: number | null;
    timeAprox: string | null;
    starred: boolean | null;
    important: boolean | null;
    showAsOverview: boolean | null;
    showAsFree: boolean | null;
    nextStep: string | null;
    prevStep: string | null;
    status: elStatus;
    updatedAt: string | null;
    createdAt: string | null;
    course: {
      __typename: "Course";
      id: string;
      title: string;
      description: string;
      categoryId: string;
      coverImage: string | null;
      timeAprox: string;
      status: elStatus;
      coverImage1: string | null;
      coverImage2: string | null;
      coverImage3: string | null;
      courseType: CourseType | null;
      courseUrl: string | null;
      overviewSourceMedia: string | null;
      overviewFileName: string | null;
      overviewFileType: string | null;
      overviewFileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      courseTrend: elCourseTrend | null;
      learnDescription: string | null;
      requirements: string | null;
      level: string | null;
      tags: string | null;
      trending: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    topic: {
      __typename: "Content";
      id: string;
      courseId: string;
      contentType: ContentType;
      media: string | null;
      sourceMedia: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    quiz: {
      __typename: "Quiz";
      id: string;
      courseId: string;
      questionsRandom: number | null;
      passingScore: number | null;
      noAttempts: number | null;
      score: number | null;
      labelWhenPassed: string | null;
      labelWhenFailed: string | null;
      questionFeedback: boolean | null;
      updatedAt: string | null;
      createdAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    attachments: {
      __typename: "ModelCourseAssetsResourceConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateCourseAssetsResourceSubscription = {
  __typename: "CourseAssetsResource";
  id: string;
  courseId: string;
  courseAssetId: string;
  description: string;
  contentType: ResourceType;
  sourceCode: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  status: elStatus;
  updatedAt: string | null;
  createdAt: string | null;
  courseAsset: {
    __typename: "CourseAssets";
    id: string;
    courseId: string;
    assetId: string | null;
    assetType: elAssetType;
    title: string;
    description: string;
    order: number | null;
    timeAprox: string | null;
    starred: boolean | null;
    important: boolean | null;
    showAsOverview: boolean | null;
    showAsFree: boolean | null;
    nextStep: string | null;
    prevStep: string | null;
    status: elStatus;
    updatedAt: string | null;
    createdAt: string | null;
    course: {
      __typename: "Course";
      id: string;
      title: string;
      description: string;
      categoryId: string;
      coverImage: string | null;
      timeAprox: string;
      status: elStatus;
      coverImage1: string | null;
      coverImage2: string | null;
      coverImage3: string | null;
      courseType: CourseType | null;
      courseUrl: string | null;
      overviewSourceMedia: string | null;
      overviewFileName: string | null;
      overviewFileType: string | null;
      overviewFileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      courseTrend: elCourseTrend | null;
      learnDescription: string | null;
      requirements: string | null;
      level: string | null;
      tags: string | null;
      trending: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    topic: {
      __typename: "Content";
      id: string;
      courseId: string;
      contentType: ContentType;
      media: string | null;
      sourceMedia: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    quiz: {
      __typename: "Quiz";
      id: string;
      courseId: string;
      questionsRandom: number | null;
      passingScore: number | null;
      noAttempts: number | null;
      score: number | null;
      labelWhenPassed: string | null;
      labelWhenFailed: string | null;
      questionFeedback: boolean | null;
      updatedAt: string | null;
      createdAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    attachments: {
      __typename: "ModelCourseAssetsResourceConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteCourseAssetsResourceSubscription = {
  __typename: "CourseAssetsResource";
  id: string;
  courseId: string;
  courseAssetId: string;
  description: string;
  contentType: ResourceType;
  sourceCode: string | null;
  fileName: string | null;
  fileType: string | null;
  fileSize: string | null;
  status: elStatus;
  updatedAt: string | null;
  createdAt: string | null;
  courseAsset: {
    __typename: "CourseAssets";
    id: string;
    courseId: string;
    assetId: string | null;
    assetType: elAssetType;
    title: string;
    description: string;
    order: number | null;
    timeAprox: string | null;
    starred: boolean | null;
    important: boolean | null;
    showAsOverview: boolean | null;
    showAsFree: boolean | null;
    nextStep: string | null;
    prevStep: string | null;
    status: elStatus;
    updatedAt: string | null;
    createdAt: string | null;
    course: {
      __typename: "Course";
      id: string;
      title: string;
      description: string;
      categoryId: string;
      coverImage: string | null;
      timeAprox: string;
      status: elStatus;
      coverImage1: string | null;
      coverImage2: string | null;
      coverImage3: string | null;
      courseType: CourseType | null;
      courseUrl: string | null;
      overviewSourceMedia: string | null;
      overviewFileName: string | null;
      overviewFileType: string | null;
      overviewFileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      courseTrend: elCourseTrend | null;
      learnDescription: string | null;
      requirements: string | null;
      level: string | null;
      tags: string | null;
      trending: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    topic: {
      __typename: "Content";
      id: string;
      courseId: string;
      contentType: ContentType;
      media: string | null;
      sourceMedia: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    quiz: {
      __typename: "Quiz";
      id: string;
      courseId: string;
      questionsRandom: number | null;
      passingScore: number | null;
      noAttempts: number | null;
      score: number | null;
      labelWhenPassed: string | null;
      labelWhenFailed: string | null;
      questionFeedback: boolean | null;
      updatedAt: string | null;
      createdAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    attachments: {
      __typename: "ModelCourseAssetsResourceConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateCourseAssetSubsSubscription = {
  __typename: "CourseAssetSubs";
  id: string;
  courseSubsId: string;
  assetId: string;
  userId: string;
  assetType: elAssetType;
  title: string | null;
  description: string | null;
  category: string | null;
  metaData: string | null;
  status: elSubStatus;
  asset: {
    __typename: "CourseAssets";
    id: string;
    courseId: string;
    assetId: string | null;
    assetType: elAssetType;
    title: string;
    description: string;
    order: number | null;
    timeAprox: string | null;
    starred: boolean | null;
    important: boolean | null;
    showAsOverview: boolean | null;
    showAsFree: boolean | null;
    nextStep: string | null;
    prevStep: string | null;
    status: elStatus;
    updatedAt: string | null;
    createdAt: string | null;
    course: {
      __typename: "Course";
      id: string;
      title: string;
      description: string;
      categoryId: string;
      coverImage: string | null;
      timeAprox: string;
      status: elStatus;
      coverImage1: string | null;
      coverImage2: string | null;
      coverImage3: string | null;
      courseType: CourseType | null;
      courseUrl: string | null;
      overviewSourceMedia: string | null;
      overviewFileName: string | null;
      overviewFileType: string | null;
      overviewFileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      courseTrend: elCourseTrend | null;
      learnDescription: string | null;
      requirements: string | null;
      level: string | null;
      tags: string | null;
      trending: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    topic: {
      __typename: "Content";
      id: string;
      courseId: string;
      contentType: ContentType;
      media: string | null;
      sourceMedia: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    quiz: {
      __typename: "Quiz";
      id: string;
      courseId: string;
      questionsRandom: number | null;
      passingScore: number | null;
      noAttempts: number | null;
      score: number | null;
      labelWhenPassed: string | null;
      labelWhenFailed: string | null;
      questionFeedback: boolean | null;
      updatedAt: string | null;
      createdAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    attachments: {
      __typename: "ModelCourseAssetsResourceConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
  assetsSubs: {
    __typename: "CourseSubs";
    id: string;
    courseId: string;
    userId: string;
    title: string | null;
    description: string | null;
    category: string | null;
    level: string | null;
    tags: string | null;
    metaData: string | null;
    status: elSubStatus;
    course: {
      __typename: "Course";
      id: string;
      title: string;
      description: string;
      categoryId: string;
      coverImage: string | null;
      timeAprox: string;
      status: elStatus;
      coverImage1: string | null;
      coverImage2: string | null;
      coverImage3: string | null;
      courseType: CourseType | null;
      courseUrl: string | null;
      overviewSourceMedia: string | null;
      overviewFileName: string | null;
      overviewFileType: string | null;
      overviewFileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      courseTrend: elCourseTrend | null;
      learnDescription: string | null;
      requirements: string | null;
      level: string | null;
      tags: string | null;
      trending: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
    owner: string | null;
    assetsSubs: {
      __typename: "ModelCourseAssetSubsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
  } | null;
  owner: string | null;
};

export type OnUpdateCourseAssetSubsSubscription = {
  __typename: "CourseAssetSubs";
  id: string;
  courseSubsId: string;
  assetId: string;
  userId: string;
  assetType: elAssetType;
  title: string | null;
  description: string | null;
  category: string | null;
  metaData: string | null;
  status: elSubStatus;
  asset: {
    __typename: "CourseAssets";
    id: string;
    courseId: string;
    assetId: string | null;
    assetType: elAssetType;
    title: string;
    description: string;
    order: number | null;
    timeAprox: string | null;
    starred: boolean | null;
    important: boolean | null;
    showAsOverview: boolean | null;
    showAsFree: boolean | null;
    nextStep: string | null;
    prevStep: string | null;
    status: elStatus;
    updatedAt: string | null;
    createdAt: string | null;
    course: {
      __typename: "Course";
      id: string;
      title: string;
      description: string;
      categoryId: string;
      coverImage: string | null;
      timeAprox: string;
      status: elStatus;
      coverImage1: string | null;
      coverImage2: string | null;
      coverImage3: string | null;
      courseType: CourseType | null;
      courseUrl: string | null;
      overviewSourceMedia: string | null;
      overviewFileName: string | null;
      overviewFileType: string | null;
      overviewFileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      courseTrend: elCourseTrend | null;
      learnDescription: string | null;
      requirements: string | null;
      level: string | null;
      tags: string | null;
      trending: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    topic: {
      __typename: "Content";
      id: string;
      courseId: string;
      contentType: ContentType;
      media: string | null;
      sourceMedia: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    quiz: {
      __typename: "Quiz";
      id: string;
      courseId: string;
      questionsRandom: number | null;
      passingScore: number | null;
      noAttempts: number | null;
      score: number | null;
      labelWhenPassed: string | null;
      labelWhenFailed: string | null;
      questionFeedback: boolean | null;
      updatedAt: string | null;
      createdAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    attachments: {
      __typename: "ModelCourseAssetsResourceConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
  assetsSubs: {
    __typename: "CourseSubs";
    id: string;
    courseId: string;
    userId: string;
    title: string | null;
    description: string | null;
    category: string | null;
    level: string | null;
    tags: string | null;
    metaData: string | null;
    status: elSubStatus;
    course: {
      __typename: "Course";
      id: string;
      title: string;
      description: string;
      categoryId: string;
      coverImage: string | null;
      timeAprox: string;
      status: elStatus;
      coverImage1: string | null;
      coverImage2: string | null;
      coverImage3: string | null;
      courseType: CourseType | null;
      courseUrl: string | null;
      overviewSourceMedia: string | null;
      overviewFileName: string | null;
      overviewFileType: string | null;
      overviewFileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      courseTrend: elCourseTrend | null;
      learnDescription: string | null;
      requirements: string | null;
      level: string | null;
      tags: string | null;
      trending: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
    owner: string | null;
    assetsSubs: {
      __typename: "ModelCourseAssetSubsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
  } | null;
  owner: string | null;
};

export type OnDeleteCourseAssetSubsSubscription = {
  __typename: "CourseAssetSubs";
  id: string;
  courseSubsId: string;
  assetId: string;
  userId: string;
  assetType: elAssetType;
  title: string | null;
  description: string | null;
  category: string | null;
  metaData: string | null;
  status: elSubStatus;
  asset: {
    __typename: "CourseAssets";
    id: string;
    courseId: string;
    assetId: string | null;
    assetType: elAssetType;
    title: string;
    description: string;
    order: number | null;
    timeAprox: string | null;
    starred: boolean | null;
    important: boolean | null;
    showAsOverview: boolean | null;
    showAsFree: boolean | null;
    nextStep: string | null;
    prevStep: string | null;
    status: elStatus;
    updatedAt: string | null;
    createdAt: string | null;
    course: {
      __typename: "Course";
      id: string;
      title: string;
      description: string;
      categoryId: string;
      coverImage: string | null;
      timeAprox: string;
      status: elStatus;
      coverImage1: string | null;
      coverImage2: string | null;
      coverImage3: string | null;
      courseType: CourseType | null;
      courseUrl: string | null;
      overviewSourceMedia: string | null;
      overviewFileName: string | null;
      overviewFileType: string | null;
      overviewFileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      courseTrend: elCourseTrend | null;
      learnDescription: string | null;
      requirements: string | null;
      level: string | null;
      tags: string | null;
      trending: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    topic: {
      __typename: "Content";
      id: string;
      courseId: string;
      contentType: ContentType;
      media: string | null;
      sourceMedia: string | null;
      fileName: string | null;
      fileType: string | null;
      fileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    quiz: {
      __typename: "Quiz";
      id: string;
      courseId: string;
      questionsRandom: number | null;
      passingScore: number | null;
      noAttempts: number | null;
      score: number | null;
      labelWhenPassed: string | null;
      labelWhenFailed: string | null;
      questionFeedback: boolean | null;
      updatedAt: string | null;
      createdAt: string | null;
      status: elStatus | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    attachments: {
      __typename: "ModelCourseAssetsResourceConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  updatedAt: string | null;
  createdAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
  assetsSubs: {
    __typename: "CourseSubs";
    id: string;
    courseId: string;
    userId: string;
    title: string | null;
    description: string | null;
    category: string | null;
    level: string | null;
    tags: string | null;
    metaData: string | null;
    status: elSubStatus;
    course: {
      __typename: "Course";
      id: string;
      title: string;
      description: string;
      categoryId: string;
      coverImage: string | null;
      timeAprox: string;
      status: elStatus;
      coverImage1: string | null;
      coverImage2: string | null;
      coverImage3: string | null;
      courseType: CourseType | null;
      courseUrl: string | null;
      overviewSourceMedia: string | null;
      overviewFileName: string | null;
      overviewFileType: string | null;
      overviewFileSize: string | null;
      transcoderMeta: string | null;
      transcoderJobId: string | null;
      courseTrend: elCourseTrend | null;
      learnDescription: string | null;
      requirements: string | null;
      level: string | null;
      tags: string | null;
      trending: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    updatedAt: string | null;
    createdAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
    owner: string | null;
    assetsSubs: {
      __typename: "ModelCourseAssetSubsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
  } | null;
  owner: string | null;
};

export type OnCreateContactSubscription = {
  __typename: "Contact";
  id: string;
  userID: string;
  syncID: string | null;
  contactType: ContactType | null;
  contactName: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phone: string;
  avatar: string | null;
  avatarType: string | null;
  jobTitle: string | null;
  birthday: string | null;
  starred: boolean | null;
  frequent: boolean | null;
  status: string;
  pbProfileOptions: string | null;
  userRelatedId: string | null;
  notes: string | null;
  businessId: string | null;
  contactSince: string | null;
  updatedAt: string | null;
  createdAt: string | null;
  contactRelId: string | null;
  contactRel: {
    __typename: "Contact";
    id: string;
    userID: string;
    syncID: string | null;
    contactType: ContactType | null;
    contactName: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    phone: string;
    avatar: string | null;
    avatarType: string | null;
    jobTitle: string | null;
    birthday: string | null;
    starred: boolean | null;
    frequent: boolean | null;
    status: string;
    pbProfileOptions: string | null;
    userRelatedId: string | null;
    notes: string | null;
    businessId: string | null;
    contactSince: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    contactRelId: string | null;
    contactRel: {
      __typename: "Contact";
      id: string;
      userID: string;
      syncID: string | null;
      contactType: ContactType | null;
      contactName: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      phone: string;
      avatar: string | null;
      avatarType: string | null;
      jobTitle: string | null;
      birthday: string | null;
      starred: boolean | null;
      frequent: boolean | null;
      status: string;
      pbProfileOptions: string | null;
      userRelatedId: string | null;
      notes: string | null;
      businessId: string | null;
      contactSince: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      contactRelId: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    addresses: {
      __typename: "ModelContactAddressConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    connects: {
      __typename: "ModelConnectsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    licence: {
      __typename: "ModelMembershipConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  addresses: {
    __typename: "ModelContactAddressConnection";
    items: Array<{
      __typename: "ContactAddress";
      id: string;
      contactId: string;
      addressType: string | null;
      street: string | null;
      street1: string | null;
      zipCode: string | null;
      city: string | null;
      state: string | null;
      Country: string | null;
      status: string;
      updatedAt: string | null;
      createdAt: string | null;
      lat: string | null;
      lng: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  connects: {
    __typename: "ModelConnectsConnection";
    items: Array<{
      __typename: "Connects";
      id: string;
      contactId: string;
      connsContactId: string | null;
      contactType: string | null;
      contactName: string | null;
      status: string;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  licence: {
    __typename: "ModelMembershipConnection";
    items: Array<{
      __typename: "Membership";
      id: string;
      contactId: string;
      noLicenses: number | null;
      noClaimed: number | null;
      groupId: string | null;
      group: string | null;
      prefix: string | null;
      status: string;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateContactSubscription = {
  __typename: "Contact";
  id: string;
  userID: string;
  syncID: string | null;
  contactType: ContactType | null;
  contactName: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phone: string;
  avatar: string | null;
  avatarType: string | null;
  jobTitle: string | null;
  birthday: string | null;
  starred: boolean | null;
  frequent: boolean | null;
  status: string;
  pbProfileOptions: string | null;
  userRelatedId: string | null;
  notes: string | null;
  businessId: string | null;
  contactSince: string | null;
  updatedAt: string | null;
  createdAt: string | null;
  contactRelId: string | null;
  contactRel: {
    __typename: "Contact";
    id: string;
    userID: string;
    syncID: string | null;
    contactType: ContactType | null;
    contactName: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    phone: string;
    avatar: string | null;
    avatarType: string | null;
    jobTitle: string | null;
    birthday: string | null;
    starred: boolean | null;
    frequent: boolean | null;
    status: string;
    pbProfileOptions: string | null;
    userRelatedId: string | null;
    notes: string | null;
    businessId: string | null;
    contactSince: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    contactRelId: string | null;
    contactRel: {
      __typename: "Contact";
      id: string;
      userID: string;
      syncID: string | null;
      contactType: ContactType | null;
      contactName: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      phone: string;
      avatar: string | null;
      avatarType: string | null;
      jobTitle: string | null;
      birthday: string | null;
      starred: boolean | null;
      frequent: boolean | null;
      status: string;
      pbProfileOptions: string | null;
      userRelatedId: string | null;
      notes: string | null;
      businessId: string | null;
      contactSince: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      contactRelId: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    addresses: {
      __typename: "ModelContactAddressConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    connects: {
      __typename: "ModelConnectsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    licence: {
      __typename: "ModelMembershipConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  addresses: {
    __typename: "ModelContactAddressConnection";
    items: Array<{
      __typename: "ContactAddress";
      id: string;
      contactId: string;
      addressType: string | null;
      street: string | null;
      street1: string | null;
      zipCode: string | null;
      city: string | null;
      state: string | null;
      Country: string | null;
      status: string;
      updatedAt: string | null;
      createdAt: string | null;
      lat: string | null;
      lng: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  connects: {
    __typename: "ModelConnectsConnection";
    items: Array<{
      __typename: "Connects";
      id: string;
      contactId: string;
      connsContactId: string | null;
      contactType: string | null;
      contactName: string | null;
      status: string;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  licence: {
    __typename: "ModelMembershipConnection";
    items: Array<{
      __typename: "Membership";
      id: string;
      contactId: string;
      noLicenses: number | null;
      noClaimed: number | null;
      groupId: string | null;
      group: string | null;
      prefix: string | null;
      status: string;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteContactSubscription = {
  __typename: "Contact";
  id: string;
  userID: string;
  syncID: string | null;
  contactType: ContactType | null;
  contactName: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phone: string;
  avatar: string | null;
  avatarType: string | null;
  jobTitle: string | null;
  birthday: string | null;
  starred: boolean | null;
  frequent: boolean | null;
  status: string;
  pbProfileOptions: string | null;
  userRelatedId: string | null;
  notes: string | null;
  businessId: string | null;
  contactSince: string | null;
  updatedAt: string | null;
  createdAt: string | null;
  contactRelId: string | null;
  contactRel: {
    __typename: "Contact";
    id: string;
    userID: string;
    syncID: string | null;
    contactType: ContactType | null;
    contactName: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    phone: string;
    avatar: string | null;
    avatarType: string | null;
    jobTitle: string | null;
    birthday: string | null;
    starred: boolean | null;
    frequent: boolean | null;
    status: string;
    pbProfileOptions: string | null;
    userRelatedId: string | null;
    notes: string | null;
    businessId: string | null;
    contactSince: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    contactRelId: string | null;
    contactRel: {
      __typename: "Contact";
      id: string;
      userID: string;
      syncID: string | null;
      contactType: ContactType | null;
      contactName: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      phone: string;
      avatar: string | null;
      avatarType: string | null;
      jobTitle: string | null;
      birthday: string | null;
      starred: boolean | null;
      frequent: boolean | null;
      status: string;
      pbProfileOptions: string | null;
      userRelatedId: string | null;
      notes: string | null;
      businessId: string | null;
      contactSince: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      contactRelId: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    addresses: {
      __typename: "ModelContactAddressConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    connects: {
      __typename: "ModelConnectsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    licence: {
      __typename: "ModelMembershipConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  addresses: {
    __typename: "ModelContactAddressConnection";
    items: Array<{
      __typename: "ContactAddress";
      id: string;
      contactId: string;
      addressType: string | null;
      street: string | null;
      street1: string | null;
      zipCode: string | null;
      city: string | null;
      state: string | null;
      Country: string | null;
      status: string;
      updatedAt: string | null;
      createdAt: string | null;
      lat: string | null;
      lng: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  connects: {
    __typename: "ModelConnectsConnection";
    items: Array<{
      __typename: "Connects";
      id: string;
      contactId: string;
      connsContactId: string | null;
      contactType: string | null;
      contactName: string | null;
      status: string;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  licence: {
    __typename: "ModelMembershipConnection";
    items: Array<{
      __typename: "Membership";
      id: string;
      contactId: string;
      noLicenses: number | null;
      noClaimed: number | null;
      groupId: string | null;
      group: string | null;
      prefix: string | null;
      status: string;
      updatedAt: string | null;
      createdAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateConnectsSubscription = {
  __typename: "Connects";
  id: string;
  contactId: string;
  connsContactId: string | null;
  contactType: string | null;
  contactName: string | null;
  status: string;
  updatedAt: string | null;
  createdAt: string | null;
  contact: {
    __typename: "Contact";
    id: string;
    userID: string;
    syncID: string | null;
    contactType: ContactType | null;
    contactName: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    phone: string;
    avatar: string | null;
    avatarType: string | null;
    jobTitle: string | null;
    birthday: string | null;
    starred: boolean | null;
    frequent: boolean | null;
    status: string;
    pbProfileOptions: string | null;
    userRelatedId: string | null;
    notes: string | null;
    businessId: string | null;
    contactSince: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    contactRelId: string | null;
    contactRel: {
      __typename: "Contact";
      id: string;
      userID: string;
      syncID: string | null;
      contactType: ContactType | null;
      contactName: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      phone: string;
      avatar: string | null;
      avatarType: string | null;
      jobTitle: string | null;
      birthday: string | null;
      starred: boolean | null;
      frequent: boolean | null;
      status: string;
      pbProfileOptions: string | null;
      userRelatedId: string | null;
      notes: string | null;
      businessId: string | null;
      contactSince: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      contactRelId: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    addresses: {
      __typename: "ModelContactAddressConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    connects: {
      __typename: "ModelConnectsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    licence: {
      __typename: "ModelMembershipConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateConnectsSubscription = {
  __typename: "Connects";
  id: string;
  contactId: string;
  connsContactId: string | null;
  contactType: string | null;
  contactName: string | null;
  status: string;
  updatedAt: string | null;
  createdAt: string | null;
  contact: {
    __typename: "Contact";
    id: string;
    userID: string;
    syncID: string | null;
    contactType: ContactType | null;
    contactName: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    phone: string;
    avatar: string | null;
    avatarType: string | null;
    jobTitle: string | null;
    birthday: string | null;
    starred: boolean | null;
    frequent: boolean | null;
    status: string;
    pbProfileOptions: string | null;
    userRelatedId: string | null;
    notes: string | null;
    businessId: string | null;
    contactSince: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    contactRelId: string | null;
    contactRel: {
      __typename: "Contact";
      id: string;
      userID: string;
      syncID: string | null;
      contactType: ContactType | null;
      contactName: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      phone: string;
      avatar: string | null;
      avatarType: string | null;
      jobTitle: string | null;
      birthday: string | null;
      starred: boolean | null;
      frequent: boolean | null;
      status: string;
      pbProfileOptions: string | null;
      userRelatedId: string | null;
      notes: string | null;
      businessId: string | null;
      contactSince: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      contactRelId: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    addresses: {
      __typename: "ModelContactAddressConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    connects: {
      __typename: "ModelConnectsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    licence: {
      __typename: "ModelMembershipConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteConnectsSubscription = {
  __typename: "Connects";
  id: string;
  contactId: string;
  connsContactId: string | null;
  contactType: string | null;
  contactName: string | null;
  status: string;
  updatedAt: string | null;
  createdAt: string | null;
  contact: {
    __typename: "Contact";
    id: string;
    userID: string;
    syncID: string | null;
    contactType: ContactType | null;
    contactName: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    phone: string;
    avatar: string | null;
    avatarType: string | null;
    jobTitle: string | null;
    birthday: string | null;
    starred: boolean | null;
    frequent: boolean | null;
    status: string;
    pbProfileOptions: string | null;
    userRelatedId: string | null;
    notes: string | null;
    businessId: string | null;
    contactSince: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    contactRelId: string | null;
    contactRel: {
      __typename: "Contact";
      id: string;
      userID: string;
      syncID: string | null;
      contactType: ContactType | null;
      contactName: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      phone: string;
      avatar: string | null;
      avatarType: string | null;
      jobTitle: string | null;
      birthday: string | null;
      starred: boolean | null;
      frequent: boolean | null;
      status: string;
      pbProfileOptions: string | null;
      userRelatedId: string | null;
      notes: string | null;
      businessId: string | null;
      contactSince: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      contactRelId: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    addresses: {
      __typename: "ModelContactAddressConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    connects: {
      __typename: "ModelConnectsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    licence: {
      __typename: "ModelMembershipConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateContactAddressSubscription = {
  __typename: "ContactAddress";
  id: string;
  contactId: string;
  addressType: string | null;
  street: string | null;
  street1: string | null;
  zipCode: string | null;
  city: string | null;
  state: string | null;
  Country: string | null;
  status: string;
  updatedAt: string | null;
  createdAt: string | null;
  lat: string | null;
  lng: string | null;
  contact: {
    __typename: "Contact";
    id: string;
    userID: string;
    syncID: string | null;
    contactType: ContactType | null;
    contactName: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    phone: string;
    avatar: string | null;
    avatarType: string | null;
    jobTitle: string | null;
    birthday: string | null;
    starred: boolean | null;
    frequent: boolean | null;
    status: string;
    pbProfileOptions: string | null;
    userRelatedId: string | null;
    notes: string | null;
    businessId: string | null;
    contactSince: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    contactRelId: string | null;
    contactRel: {
      __typename: "Contact";
      id: string;
      userID: string;
      syncID: string | null;
      contactType: ContactType | null;
      contactName: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      phone: string;
      avatar: string | null;
      avatarType: string | null;
      jobTitle: string | null;
      birthday: string | null;
      starred: boolean | null;
      frequent: boolean | null;
      status: string;
      pbProfileOptions: string | null;
      userRelatedId: string | null;
      notes: string | null;
      businessId: string | null;
      contactSince: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      contactRelId: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    addresses: {
      __typename: "ModelContactAddressConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    connects: {
      __typename: "ModelConnectsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    licence: {
      __typename: "ModelMembershipConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateContactAddressSubscription = {
  __typename: "ContactAddress";
  id: string;
  contactId: string;
  addressType: string | null;
  street: string | null;
  street1: string | null;
  zipCode: string | null;
  city: string | null;
  state: string | null;
  Country: string | null;
  status: string;
  updatedAt: string | null;
  createdAt: string | null;
  lat: string | null;
  lng: string | null;
  contact: {
    __typename: "Contact";
    id: string;
    userID: string;
    syncID: string | null;
    contactType: ContactType | null;
    contactName: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    phone: string;
    avatar: string | null;
    avatarType: string | null;
    jobTitle: string | null;
    birthday: string | null;
    starred: boolean | null;
    frequent: boolean | null;
    status: string;
    pbProfileOptions: string | null;
    userRelatedId: string | null;
    notes: string | null;
    businessId: string | null;
    contactSince: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    contactRelId: string | null;
    contactRel: {
      __typename: "Contact";
      id: string;
      userID: string;
      syncID: string | null;
      contactType: ContactType | null;
      contactName: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      phone: string;
      avatar: string | null;
      avatarType: string | null;
      jobTitle: string | null;
      birthday: string | null;
      starred: boolean | null;
      frequent: boolean | null;
      status: string;
      pbProfileOptions: string | null;
      userRelatedId: string | null;
      notes: string | null;
      businessId: string | null;
      contactSince: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      contactRelId: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    addresses: {
      __typename: "ModelContactAddressConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    connects: {
      __typename: "ModelConnectsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    licence: {
      __typename: "ModelMembershipConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteContactAddressSubscription = {
  __typename: "ContactAddress";
  id: string;
  contactId: string;
  addressType: string | null;
  street: string | null;
  street1: string | null;
  zipCode: string | null;
  city: string | null;
  state: string | null;
  Country: string | null;
  status: string;
  updatedAt: string | null;
  createdAt: string | null;
  lat: string | null;
  lng: string | null;
  contact: {
    __typename: "Contact";
    id: string;
    userID: string;
    syncID: string | null;
    contactType: ContactType | null;
    contactName: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    phone: string;
    avatar: string | null;
    avatarType: string | null;
    jobTitle: string | null;
    birthday: string | null;
    starred: boolean | null;
    frequent: boolean | null;
    status: string;
    pbProfileOptions: string | null;
    userRelatedId: string | null;
    notes: string | null;
    businessId: string | null;
    contactSince: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    contactRelId: string | null;
    contactRel: {
      __typename: "Contact";
      id: string;
      userID: string;
      syncID: string | null;
      contactType: ContactType | null;
      contactName: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      phone: string;
      avatar: string | null;
      avatarType: string | null;
      jobTitle: string | null;
      birthday: string | null;
      starred: boolean | null;
      frequent: boolean | null;
      status: string;
      pbProfileOptions: string | null;
      userRelatedId: string | null;
      notes: string | null;
      businessId: string | null;
      contactSince: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      contactRelId: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    addresses: {
      __typename: "ModelContactAddressConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    connects: {
      __typename: "ModelConnectsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    licence: {
      __typename: "ModelMembershipConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateMembershipSubscription = {
  __typename: "Membership";
  id: string;
  contactId: string;
  noLicenses: number | null;
  noClaimed: number | null;
  groupId: string | null;
  group: string | null;
  prefix: string | null;
  status: string;
  updatedAt: string | null;
  createdAt: string | null;
  org: {
    __typename: "Contact";
    id: string;
    userID: string;
    syncID: string | null;
    contactType: ContactType | null;
    contactName: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    phone: string;
    avatar: string | null;
    avatarType: string | null;
    jobTitle: string | null;
    birthday: string | null;
    starred: boolean | null;
    frequent: boolean | null;
    status: string;
    pbProfileOptions: string | null;
    userRelatedId: string | null;
    notes: string | null;
    businessId: string | null;
    contactSince: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    contactRelId: string | null;
    contactRel: {
      __typename: "Contact";
      id: string;
      userID: string;
      syncID: string | null;
      contactType: ContactType | null;
      contactName: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      phone: string;
      avatar: string | null;
      avatarType: string | null;
      jobTitle: string | null;
      birthday: string | null;
      starred: boolean | null;
      frequent: boolean | null;
      status: string;
      pbProfileOptions: string | null;
      userRelatedId: string | null;
      notes: string | null;
      businessId: string | null;
      contactSince: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      contactRelId: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    addresses: {
      __typename: "ModelContactAddressConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    connects: {
      __typename: "ModelConnectsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    licence: {
      __typename: "ModelMembershipConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateMembershipSubscription = {
  __typename: "Membership";
  id: string;
  contactId: string;
  noLicenses: number | null;
  noClaimed: number | null;
  groupId: string | null;
  group: string | null;
  prefix: string | null;
  status: string;
  updatedAt: string | null;
  createdAt: string | null;
  org: {
    __typename: "Contact";
    id: string;
    userID: string;
    syncID: string | null;
    contactType: ContactType | null;
    contactName: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    phone: string;
    avatar: string | null;
    avatarType: string | null;
    jobTitle: string | null;
    birthday: string | null;
    starred: boolean | null;
    frequent: boolean | null;
    status: string;
    pbProfileOptions: string | null;
    userRelatedId: string | null;
    notes: string | null;
    businessId: string | null;
    contactSince: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    contactRelId: string | null;
    contactRel: {
      __typename: "Contact";
      id: string;
      userID: string;
      syncID: string | null;
      contactType: ContactType | null;
      contactName: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      phone: string;
      avatar: string | null;
      avatarType: string | null;
      jobTitle: string | null;
      birthday: string | null;
      starred: boolean | null;
      frequent: boolean | null;
      status: string;
      pbProfileOptions: string | null;
      userRelatedId: string | null;
      notes: string | null;
      businessId: string | null;
      contactSince: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      contactRelId: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    addresses: {
      __typename: "ModelContactAddressConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    connects: {
      __typename: "ModelConnectsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    licence: {
      __typename: "ModelMembershipConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteMembershipSubscription = {
  __typename: "Membership";
  id: string;
  contactId: string;
  noLicenses: number | null;
  noClaimed: number | null;
  groupId: string | null;
  group: string | null;
  prefix: string | null;
  status: string;
  updatedAt: string | null;
  createdAt: string | null;
  org: {
    __typename: "Contact";
    id: string;
    userID: string;
    syncID: string | null;
    contactType: ContactType | null;
    contactName: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    phone: string;
    avatar: string | null;
    avatarType: string | null;
    jobTitle: string | null;
    birthday: string | null;
    starred: boolean | null;
    frequent: boolean | null;
    status: string;
    pbProfileOptions: string | null;
    userRelatedId: string | null;
    notes: string | null;
    businessId: string | null;
    contactSince: string | null;
    updatedAt: string | null;
    createdAt: string | null;
    contactRelId: string | null;
    contactRel: {
      __typename: "Contact";
      id: string;
      userID: string;
      syncID: string | null;
      contactType: ContactType | null;
      contactName: string;
      firstName: string | null;
      lastName: string | null;
      email: string;
      phone: string;
      avatar: string | null;
      avatarType: string | null;
      jobTitle: string | null;
      birthday: string | null;
      starred: boolean | null;
      frequent: boolean | null;
      status: string;
      pbProfileOptions: string | null;
      userRelatedId: string | null;
      notes: string | null;
      businessId: string | null;
      contactSince: string | null;
      updatedAt: string | null;
      createdAt: string | null;
      contactRelId: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    addresses: {
      __typename: "ModelContactAddressConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    connects: {
      __typename: "ModelConnectsConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    licence: {
      __typename: "ModelMembershipConnection";
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async CreateSession(
    input: CreateSessionInput,
    condition?: ModelSessionConditionInput
  ): Promise<CreateSessionMutation> {
    const statement = `mutation CreateSession($input: CreateSessionInput!, $condition: ModelSessionConditionInput) {
        createSession(input: $input, condition: $condition) {
          __typename
          id
          userId
          location
          event
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSessionMutation>response.data.createSession;
  }
  async UpdateSession(
    input: UpdateSessionInput,
    condition?: ModelSessionConditionInput
  ): Promise<UpdateSessionMutation> {
    const statement = `mutation UpdateSession($input: UpdateSessionInput!, $condition: ModelSessionConditionInput) {
        updateSession(input: $input, condition: $condition) {
          __typename
          id
          userId
          location
          event
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSessionMutation>response.data.updateSession;
  }
  async DeleteSession(
    input: DeleteSessionInput,
    condition?: ModelSessionConditionInput
  ): Promise<DeleteSessionMutation> {
    const statement = `mutation DeleteSession($input: DeleteSessionInput!, $condition: ModelSessionConditionInput) {
        deleteSession(input: $input, condition: $condition) {
          __typename
          id
          userId
          location
          event
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSessionMutation>response.data.deleteSession;
  }
  async CreateUserSettings(
    input: CreateUserSettingsInput,
    condition?: ModelUserSettingsConditionInput
  ): Promise<CreateUserSettingsMutation> {
    const statement = `mutation CreateUserSettings($input: CreateUserSettingsInput!, $condition: ModelUserSettingsConditionInput) {
        createUserSettings(input: $input, condition: $condition) {
          __typename
          id
          userId
          pageSize
          notiType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateUserSettingsMutation>response.data.createUserSettings;
  }
  async UpdateUserSettings(
    input: UpdateUserSettingsInput,
    condition?: ModelUserSettingsConditionInput
  ): Promise<UpdateUserSettingsMutation> {
    const statement = `mutation UpdateUserSettings($input: UpdateUserSettingsInput!, $condition: ModelUserSettingsConditionInput) {
        updateUserSettings(input: $input, condition: $condition) {
          __typename
          id
          userId
          pageSize
          notiType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateUserSettingsMutation>response.data.updateUserSettings;
  }
  async DeleteUserSettings(
    input: DeleteUserSettingsInput,
    condition?: ModelUserSettingsConditionInput
  ): Promise<DeleteUserSettingsMutation> {
    const statement = `mutation DeleteUserSettings($input: DeleteUserSettingsInput!, $condition: ModelUserSettingsConditionInput) {
        deleteUserSettings(input: $input, condition: $condition) {
          __typename
          id
          userId
          pageSize
          notiType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteUserSettingsMutation>response.data.deleteUserSettings;
  }
  async CreateNotification(
    input: CreateNotificationInput,
    condition?: ModelNotificationConditionInput
  ): Promise<CreateNotificationMutation> {
    const statement = `mutation CreateNotification($input: CreateNotificationInput!, $condition: ModelNotificationConditionInput) {
        createNotification(input: $input, condition: $condition) {
          __typename
          id
          typeNotify
          userId
          subject
          body
          localUrl
          renotify
          silent
          dateFor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateNotificationMutation>response.data.createNotification;
  }
  async UpdateNotification(
    input: UpdateNotificationInput,
    condition?: ModelNotificationConditionInput
  ): Promise<UpdateNotificationMutation> {
    const statement = `mutation UpdateNotification($input: UpdateNotificationInput!, $condition: ModelNotificationConditionInput) {
        updateNotification(input: $input, condition: $condition) {
          __typename
          id
          typeNotify
          userId
          subject
          body
          localUrl
          renotify
          silent
          dateFor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateNotificationMutation>response.data.updateNotification;
  }
  async DeleteNotification(
    input: DeleteNotificationInput,
    condition?: ModelNotificationConditionInput
  ): Promise<DeleteNotificationMutation> {
    const statement = `mutation DeleteNotification($input: DeleteNotificationInput!, $condition: ModelNotificationConditionInput) {
        deleteNotification(input: $input, condition: $condition) {
          __typename
          id
          typeNotify
          userId
          subject
          body
          localUrl
          renotify
          silent
          dateFor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteNotificationMutation>response.data.deleteNotification;
  }
  async CreateUserPrivacy(
    input: CreateUserPrivacyInput,
    condition?: ModelUserPrivacyConditionInput
  ): Promise<CreateUserPrivacyMutation> {
    const statement = `mutation CreateUserPrivacy($input: CreateUserPrivacyInput!, $condition: ModelUserPrivacyConditionInput) {
        createUserPrivacy(input: $input, condition: $condition) {
          __typename
          id
          userId
          profileShared
          bgPhoto
          headLine
          secondHeadLine
          titleLine
          bioLine
          showEmail
          showPhone
          showEmailOn
          contactDirectlyByEmail
          showConnectionsTo
          oaConnInvitationAccepted
          oaEventUpdates
          oaUpdatesFromConnections
          oaBreakingNews
          oaTopsPicks
          oaWhoViewedYourProfile
          oaYourNewFollowers
          emailEventsInvitations
          emailEventsUpdates
          emailNewsInvitations
          emailNewsSubscriptions
          whoRequestForNotifications
          whoInvitationsToAttend
          locMap
          location
          locTitle
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateUserPrivacyMutation>response.data.createUserPrivacy;
  }
  async UpdateUserPrivacy(
    input: UpdateUserPrivacyInput,
    condition?: ModelUserPrivacyConditionInput
  ): Promise<UpdateUserPrivacyMutation> {
    const statement = `mutation UpdateUserPrivacy($input: UpdateUserPrivacyInput!, $condition: ModelUserPrivacyConditionInput) {
        updateUserPrivacy(input: $input, condition: $condition) {
          __typename
          id
          userId
          profileShared
          bgPhoto
          headLine
          secondHeadLine
          titleLine
          bioLine
          showEmail
          showPhone
          showEmailOn
          contactDirectlyByEmail
          showConnectionsTo
          oaConnInvitationAccepted
          oaEventUpdates
          oaUpdatesFromConnections
          oaBreakingNews
          oaTopsPicks
          oaWhoViewedYourProfile
          oaYourNewFollowers
          emailEventsInvitations
          emailEventsUpdates
          emailNewsInvitations
          emailNewsSubscriptions
          whoRequestForNotifications
          whoInvitationsToAttend
          locMap
          location
          locTitle
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateUserPrivacyMutation>response.data.updateUserPrivacy;
  }
  async DeleteUserPrivacy(
    input: DeleteUserPrivacyInput,
    condition?: ModelUserPrivacyConditionInput
  ): Promise<DeleteUserPrivacyMutation> {
    const statement = `mutation DeleteUserPrivacy($input: DeleteUserPrivacyInput!, $condition: ModelUserPrivacyConditionInput) {
        deleteUserPrivacy(input: $input, condition: $condition) {
          __typename
          id
          userId
          profileShared
          bgPhoto
          headLine
          secondHeadLine
          titleLine
          bioLine
          showEmail
          showPhone
          showEmailOn
          contactDirectlyByEmail
          showConnectionsTo
          oaConnInvitationAccepted
          oaEventUpdates
          oaUpdatesFromConnections
          oaBreakingNews
          oaTopsPicks
          oaWhoViewedYourProfile
          oaYourNewFollowers
          emailEventsInvitations
          emailEventsUpdates
          emailNewsInvitations
          emailNewsSubscriptions
          whoRequestForNotifications
          whoInvitationsToAttend
          locMap
          location
          locTitle
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteUserPrivacyMutation>response.data.deleteUserPrivacy;
  }
  async CreateCourseCategory(
    input: CreateCourseCategoryInput,
    condition?: ModelCourseCategoryConditionInput
  ): Promise<CreateCourseCategoryMutation> {
    const statement = `mutation CreateCourseCategory($input: CreateCourseCategoryInput!, $condition: ModelCourseCategoryConditionInput) {
        createCourseCategory(input: $input, condition: $condition) {
          __typename
          id
          category
          createdAt
          updatedAt
          status
          courses {
            __typename
            items {
              __typename
              id
              title
              description
              categoryId
              coverImage
              timeAprox
              status
              coverImage1
              coverImage2
              coverImage3
              courseType
              courseUrl
              overviewSourceMedia
              overviewFileName
              overviewFileType
              overviewFileSize
              transcoderMeta
              transcoderJobId
              courseTrend
              learnDescription
              requirements
              level
              tags
              trending
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCourseCategoryMutation>response.data.createCourseCategory;
  }
  async UpdateCourseCategory(
    input: UpdateCourseCategoryInput,
    condition?: ModelCourseCategoryConditionInput
  ): Promise<UpdateCourseCategoryMutation> {
    const statement = `mutation UpdateCourseCategory($input: UpdateCourseCategoryInput!, $condition: ModelCourseCategoryConditionInput) {
        updateCourseCategory(input: $input, condition: $condition) {
          __typename
          id
          category
          createdAt
          updatedAt
          status
          courses {
            __typename
            items {
              __typename
              id
              title
              description
              categoryId
              coverImage
              timeAprox
              status
              coverImage1
              coverImage2
              coverImage3
              courseType
              courseUrl
              overviewSourceMedia
              overviewFileName
              overviewFileType
              overviewFileSize
              transcoderMeta
              transcoderJobId
              courseTrend
              learnDescription
              requirements
              level
              tags
              trending
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCourseCategoryMutation>response.data.updateCourseCategory;
  }
  async DeleteCourseCategory(
    input: DeleteCourseCategoryInput,
    condition?: ModelCourseCategoryConditionInput
  ): Promise<DeleteCourseCategoryMutation> {
    const statement = `mutation DeleteCourseCategory($input: DeleteCourseCategoryInput!, $condition: ModelCourseCategoryConditionInput) {
        deleteCourseCategory(input: $input, condition: $condition) {
          __typename
          id
          category
          createdAt
          updatedAt
          status
          courses {
            __typename
            items {
              __typename
              id
              title
              description
              categoryId
              coverImage
              timeAprox
              status
              coverImage1
              coverImage2
              coverImage3
              courseType
              courseUrl
              overviewSourceMedia
              overviewFileName
              overviewFileType
              overviewFileSize
              transcoderMeta
              transcoderJobId
              courseTrend
              learnDescription
              requirements
              level
              tags
              trending
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCourseCategoryMutation>response.data.deleteCourseCategory;
  }
  async CreateCourseResource(
    input: CreateCourseResourceInput,
    condition?: ModelCourseResourceConditionInput
  ): Promise<CreateCourseResourceMutation> {
    const statement = `mutation CreateCourseResource($input: CreateCourseResourceInput!, $condition: ModelCourseResourceConditionInput) {
        createCourseResource(input: $input, condition: $condition) {
          __typename
          id
          courseId
          description
          contentType
          sourceCode
          fileName
          fileType
          fileSize
          status
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCourseResourceMutation>response.data.createCourseResource;
  }
  async UpdateCourseResource(
    input: UpdateCourseResourceInput,
    condition?: ModelCourseResourceConditionInput
  ): Promise<UpdateCourseResourceMutation> {
    const statement = `mutation UpdateCourseResource($input: UpdateCourseResourceInput!, $condition: ModelCourseResourceConditionInput) {
        updateCourseResource(input: $input, condition: $condition) {
          __typename
          id
          courseId
          description
          contentType
          sourceCode
          fileName
          fileType
          fileSize
          status
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCourseResourceMutation>response.data.updateCourseResource;
  }
  async DeleteCourseResource(
    input: DeleteCourseResourceInput,
    condition?: ModelCourseResourceConditionInput
  ): Promise<DeleteCourseResourceMutation> {
    const statement = `mutation DeleteCourseResource($input: DeleteCourseResourceInput!, $condition: ModelCourseResourceConditionInput) {
        deleteCourseResource(input: $input, condition: $condition) {
          __typename
          id
          courseId
          description
          contentType
          sourceCode
          fileName
          fileType
          fileSize
          status
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCourseResourceMutation>response.data.deleteCourseResource;
  }
  async CreateContent(
    input: CreateContentInput,
    condition?: ModelContentConditionInput
  ): Promise<CreateContentMutation> {
    const statement = `mutation CreateContent($input: CreateContentInput!, $condition: ModelContentConditionInput) {
        createContent(input: $input, condition: $condition) {
          __typename
          id
          courseId
          contentType
          media
          sourceMedia
          fileName
          fileType
          fileSize
          transcoderMeta
          transcoderJobId
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateContentMutation>response.data.createContent;
  }
  async UpdateContent(
    input: UpdateContentInput,
    condition?: ModelContentConditionInput
  ): Promise<UpdateContentMutation> {
    const statement = `mutation UpdateContent($input: UpdateContentInput!, $condition: ModelContentConditionInput) {
        updateContent(input: $input, condition: $condition) {
          __typename
          id
          courseId
          contentType
          media
          sourceMedia
          fileName
          fileType
          fileSize
          transcoderMeta
          transcoderJobId
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateContentMutation>response.data.updateContent;
  }
  async DeleteContent(
    input: DeleteContentInput,
    condition?: ModelContentConditionInput
  ): Promise<DeleteContentMutation> {
    const statement = `mutation DeleteContent($input: DeleteContentInput!, $condition: ModelContentConditionInput) {
        deleteContent(input: $input, condition: $condition) {
          __typename
          id
          courseId
          contentType
          media
          sourceMedia
          fileName
          fileType
          fileSize
          transcoderMeta
          transcoderJobId
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteContentMutation>response.data.deleteContent;
  }
  async CreateContentResource(
    input: CreateContentResourceInput,
    condition?: ModelContentResourceConditionInput
  ): Promise<CreateContentResourceMutation> {
    const statement = `mutation CreateContentResource($input: CreateContentResourceInput!, $condition: ModelContentResourceConditionInput) {
        createContentResource(input: $input, condition: $condition) {
          __typename
          id
          courseId
          description
          contentType
          sourceCode
          fileName
          fileType
          fileSize
          status
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateContentResourceMutation>response.data.createContentResource;
  }
  async UpdateContentResource(
    input: UpdateContentResourceInput,
    condition?: ModelContentResourceConditionInput
  ): Promise<UpdateContentResourceMutation> {
    const statement = `mutation UpdateContentResource($input: UpdateContentResourceInput!, $condition: ModelContentResourceConditionInput) {
        updateContentResource(input: $input, condition: $condition) {
          __typename
          id
          courseId
          description
          contentType
          sourceCode
          fileName
          fileType
          fileSize
          status
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateContentResourceMutation>response.data.updateContentResource;
  }
  async DeleteContentResource(
    input: DeleteContentResourceInput,
    condition?: ModelContentResourceConditionInput
  ): Promise<DeleteContentResourceMutation> {
    const statement = `mutation DeleteContentResource($input: DeleteContentResourceInput!, $condition: ModelContentResourceConditionInput) {
        deleteContentResource(input: $input, condition: $condition) {
          __typename
          id
          courseId
          description
          contentType
          sourceCode
          fileName
          fileType
          fileSize
          status
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteContentResourceMutation>response.data.deleteContentResource;
  }
  async CreateCourse(
    input: CreateCourseInput,
    condition?: ModelCourseConditionInput
  ): Promise<CreateCourseMutation> {
    const statement = `mutation CreateCourse($input: CreateCourseInput!, $condition: ModelCourseConditionInput) {
        createCourse(input: $input, condition: $condition) {
          __typename
          id
          title
          description
          category {
            __typename
            id
            category
            createdAt
            updatedAt
            status
            courses {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          categoryId
          coverImage
          timeAprox
          status
          coverImage1
          coverImage2
          coverImage3
          courseType
          courseUrl
          overviewSourceMedia
          overviewFileName
          overviewFileType
          overviewFileSize
          transcoderMeta
          transcoderJobId
          courseTrend
          learnDescription
          requirements
          level
          tags
          trending
          updatedAt
          createdAt
          assets {
            __typename
            items {
              __typename
              id
              courseId
              assetId
              assetType
              title
              description
              order
              timeAprox
              starred
              important
              showAsOverview
              showAsFree
              nextStep
              prevStep
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCourseMutation>response.data.createCourse;
  }
  async UpdateCourse(
    input: UpdateCourseInput,
    condition?: ModelCourseConditionInput
  ): Promise<UpdateCourseMutation> {
    const statement = `mutation UpdateCourse($input: UpdateCourseInput!, $condition: ModelCourseConditionInput) {
        updateCourse(input: $input, condition: $condition) {
          __typename
          id
          title
          description
          category {
            __typename
            id
            category
            createdAt
            updatedAt
            status
            courses {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          categoryId
          coverImage
          timeAprox
          status
          coverImage1
          coverImage2
          coverImage3
          courseType
          courseUrl
          overviewSourceMedia
          overviewFileName
          overviewFileType
          overviewFileSize
          transcoderMeta
          transcoderJobId
          courseTrend
          learnDescription
          requirements
          level
          tags
          trending
          updatedAt
          createdAt
          assets {
            __typename
            items {
              __typename
              id
              courseId
              assetId
              assetType
              title
              description
              order
              timeAprox
              starred
              important
              showAsOverview
              showAsFree
              nextStep
              prevStep
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCourseMutation>response.data.updateCourse;
  }
  async DeleteCourse(
    input: DeleteCourseInput,
    condition?: ModelCourseConditionInput
  ): Promise<DeleteCourseMutation> {
    const statement = `mutation DeleteCourse($input: DeleteCourseInput!, $condition: ModelCourseConditionInput) {
        deleteCourse(input: $input, condition: $condition) {
          __typename
          id
          title
          description
          category {
            __typename
            id
            category
            createdAt
            updatedAt
            status
            courses {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          categoryId
          coverImage
          timeAprox
          status
          coverImage1
          coverImage2
          coverImage3
          courseType
          courseUrl
          overviewSourceMedia
          overviewFileName
          overviewFileType
          overviewFileSize
          transcoderMeta
          transcoderJobId
          courseTrend
          learnDescription
          requirements
          level
          tags
          trending
          updatedAt
          createdAt
          assets {
            __typename
            items {
              __typename
              id
              courseId
              assetId
              assetType
              title
              description
              order
              timeAprox
              starred
              important
              showAsOverview
              showAsFree
              nextStep
              prevStep
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCourseMutation>response.data.deleteCourse;
  }
  async CreateContentSubs(
    input: CreateContentSubsInput,
    condition?: ModelContentSubsConditionInput
  ): Promise<CreateContentSubsMutation> {
    const statement = `mutation CreateContentSubs($input: CreateContentSubsInput!, $condition: ModelContentSubsConditionInput) {
        createContentSubs(input: $input, condition: $condition) {
          __typename
          id
          contentId
          userId
          metaData
          status
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateContentSubsMutation>response.data.createContentSubs;
  }
  async UpdateContentSubs(
    input: UpdateContentSubsInput,
    condition?: ModelContentSubsConditionInput
  ): Promise<UpdateContentSubsMutation> {
    const statement = `mutation UpdateContentSubs($input: UpdateContentSubsInput!, $condition: ModelContentSubsConditionInput) {
        updateContentSubs(input: $input, condition: $condition) {
          __typename
          id
          contentId
          userId
          metaData
          status
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateContentSubsMutation>response.data.updateContentSubs;
  }
  async DeleteContentSubs(
    input: DeleteContentSubsInput,
    condition?: ModelContentSubsConditionInput
  ): Promise<DeleteContentSubsMutation> {
    const statement = `mutation DeleteContentSubs($input: DeleteContentSubsInput!, $condition: ModelContentSubsConditionInput) {
        deleteContentSubs(input: $input, condition: $condition) {
          __typename
          id
          contentId
          userId
          metaData
          status
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteContentSubsMutation>response.data.deleteContentSubs;
  }
  async CreateCourseSubs(
    input: CreateCourseSubsInput,
    condition?: ModelCourseSubsConditionInput
  ): Promise<CreateCourseSubsMutation> {
    const statement = `mutation CreateCourseSubs($input: CreateCourseSubsInput!, $condition: ModelCourseSubsConditionInput) {
        createCourseSubs(input: $input, condition: $condition) {
          __typename
          id
          courseId
          userId
          title
          description
          category
          level
          tags
          metaData
          status
          course {
            __typename
            id
            title
            description
            category {
              __typename
              id
              category
              createdAt
              updatedAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            categoryId
            coverImage
            timeAprox
            status
            coverImage1
            coverImage2
            coverImage3
            courseType
            courseUrl
            overviewSourceMedia
            overviewFileName
            overviewFileType
            overviewFileSize
            transcoderMeta
            transcoderJobId
            courseTrend
            learnDescription
            requirements
            level
            tags
            trending
            updatedAt
            createdAt
            assets {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          owner
          assetsSubs {
            __typename
            items {
              __typename
              id
              courseSubsId
              assetId
              userId
              assetType
              title
              description
              category
              metaData
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              owner
            }
            nextToken
            startedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCourseSubsMutation>response.data.createCourseSubs;
  }
  async UpdateCourseSubs(
    input: UpdateCourseSubsInput,
    condition?: ModelCourseSubsConditionInput
  ): Promise<UpdateCourseSubsMutation> {
    const statement = `mutation UpdateCourseSubs($input: UpdateCourseSubsInput!, $condition: ModelCourseSubsConditionInput) {
        updateCourseSubs(input: $input, condition: $condition) {
          __typename
          id
          courseId
          userId
          title
          description
          category
          level
          tags
          metaData
          status
          course {
            __typename
            id
            title
            description
            category {
              __typename
              id
              category
              createdAt
              updatedAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            categoryId
            coverImage
            timeAprox
            status
            coverImage1
            coverImage2
            coverImage3
            courseType
            courseUrl
            overviewSourceMedia
            overviewFileName
            overviewFileType
            overviewFileSize
            transcoderMeta
            transcoderJobId
            courseTrend
            learnDescription
            requirements
            level
            tags
            trending
            updatedAt
            createdAt
            assets {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          owner
          assetsSubs {
            __typename
            items {
              __typename
              id
              courseSubsId
              assetId
              userId
              assetType
              title
              description
              category
              metaData
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              owner
            }
            nextToken
            startedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCourseSubsMutation>response.data.updateCourseSubs;
  }
  async DeleteCourseSubs(
    input: DeleteCourseSubsInput,
    condition?: ModelCourseSubsConditionInput
  ): Promise<DeleteCourseSubsMutation> {
    const statement = `mutation DeleteCourseSubs($input: DeleteCourseSubsInput!, $condition: ModelCourseSubsConditionInput) {
        deleteCourseSubs(input: $input, condition: $condition) {
          __typename
          id
          courseId
          userId
          title
          description
          category
          level
          tags
          metaData
          status
          course {
            __typename
            id
            title
            description
            category {
              __typename
              id
              category
              createdAt
              updatedAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            categoryId
            coverImage
            timeAprox
            status
            coverImage1
            coverImage2
            coverImage3
            courseType
            courseUrl
            overviewSourceMedia
            overviewFileName
            overviewFileType
            overviewFileSize
            transcoderMeta
            transcoderJobId
            courseTrend
            learnDescription
            requirements
            level
            tags
            trending
            updatedAt
            createdAt
            assets {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          owner
          assetsSubs {
            __typename
            items {
              __typename
              id
              courseSubsId
              assetId
              userId
              assetType
              title
              description
              category
              metaData
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              owner
            }
            nextToken
            startedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCourseSubsMutation>response.data.deleteCourseSubs;
  }
  async CreateCourseAssets(
    input: CreateCourseAssetsInput,
    condition?: ModelCourseAssetsConditionInput
  ): Promise<CreateCourseAssetsMutation> {
    const statement = `mutation CreateCourseAssets($input: CreateCourseAssetsInput!, $condition: ModelCourseAssetsConditionInput) {
        createCourseAssets(input: $input, condition: $condition) {
          __typename
          id
          courseId
          assetId
          assetType
          title
          description
          order
          timeAprox
          starred
          important
          showAsOverview
          showAsFree
          nextStep
          prevStep
          status
          updatedAt
          createdAt
          course {
            __typename
            id
            title
            description
            category {
              __typename
              id
              category
              createdAt
              updatedAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            categoryId
            coverImage
            timeAprox
            status
            coverImage1
            coverImage2
            coverImage3
            courseType
            courseUrl
            overviewSourceMedia
            overviewFileName
            overviewFileType
            overviewFileSize
            transcoderMeta
            transcoderJobId
            courseTrend
            learnDescription
            requirements
            level
            tags
            trending
            updatedAt
            createdAt
            assets {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          topic {
            __typename
            id
            courseId
            contentType
            media
            sourceMedia
            fileName
            fileType
            fileSize
            transcoderMeta
            transcoderJobId
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
          }
          quiz {
            __typename
            id
            courseId
            questionsRandom
            passingScore
            noAttempts
            score
            labelWhenPassed
            labelWhenFailed
            questionFeedback
            updatedAt
            createdAt
            status
            questions {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          attachments {
            __typename
            items {
              __typename
              id
              courseId
              courseAssetId
              description
              contentType
              sourceCode
              fileName
              fileType
              fileSize
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCourseAssetsMutation>response.data.createCourseAssets;
  }
  async UpdateCourseAssets(
    input: UpdateCourseAssetsInput,
    condition?: ModelCourseAssetsConditionInput
  ): Promise<UpdateCourseAssetsMutation> {
    const statement = `mutation UpdateCourseAssets($input: UpdateCourseAssetsInput!, $condition: ModelCourseAssetsConditionInput) {
        updateCourseAssets(input: $input, condition: $condition) {
          __typename
          id
          courseId
          assetId
          assetType
          title
          description
          order
          timeAprox
          starred
          important
          showAsOverview
          showAsFree
          nextStep
          prevStep
          status
          updatedAt
          createdAt
          course {
            __typename
            id
            title
            description
            category {
              __typename
              id
              category
              createdAt
              updatedAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            categoryId
            coverImage
            timeAprox
            status
            coverImage1
            coverImage2
            coverImage3
            courseType
            courseUrl
            overviewSourceMedia
            overviewFileName
            overviewFileType
            overviewFileSize
            transcoderMeta
            transcoderJobId
            courseTrend
            learnDescription
            requirements
            level
            tags
            trending
            updatedAt
            createdAt
            assets {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          topic {
            __typename
            id
            courseId
            contentType
            media
            sourceMedia
            fileName
            fileType
            fileSize
            transcoderMeta
            transcoderJobId
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
          }
          quiz {
            __typename
            id
            courseId
            questionsRandom
            passingScore
            noAttempts
            score
            labelWhenPassed
            labelWhenFailed
            questionFeedback
            updatedAt
            createdAt
            status
            questions {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          attachments {
            __typename
            items {
              __typename
              id
              courseId
              courseAssetId
              description
              contentType
              sourceCode
              fileName
              fileType
              fileSize
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCourseAssetsMutation>response.data.updateCourseAssets;
  }
  async DeleteCourseAssets(
    input: DeleteCourseAssetsInput,
    condition?: ModelCourseAssetsConditionInput
  ): Promise<DeleteCourseAssetsMutation> {
    const statement = `mutation DeleteCourseAssets($input: DeleteCourseAssetsInput!, $condition: ModelCourseAssetsConditionInput) {
        deleteCourseAssets(input: $input, condition: $condition) {
          __typename
          id
          courseId
          assetId
          assetType
          title
          description
          order
          timeAprox
          starred
          important
          showAsOverview
          showAsFree
          nextStep
          prevStep
          status
          updatedAt
          createdAt
          course {
            __typename
            id
            title
            description
            category {
              __typename
              id
              category
              createdAt
              updatedAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            categoryId
            coverImage
            timeAprox
            status
            coverImage1
            coverImage2
            coverImage3
            courseType
            courseUrl
            overviewSourceMedia
            overviewFileName
            overviewFileType
            overviewFileSize
            transcoderMeta
            transcoderJobId
            courseTrend
            learnDescription
            requirements
            level
            tags
            trending
            updatedAt
            createdAt
            assets {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          topic {
            __typename
            id
            courseId
            contentType
            media
            sourceMedia
            fileName
            fileType
            fileSize
            transcoderMeta
            transcoderJobId
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
          }
          quiz {
            __typename
            id
            courseId
            questionsRandom
            passingScore
            noAttempts
            score
            labelWhenPassed
            labelWhenFailed
            questionFeedback
            updatedAt
            createdAt
            status
            questions {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          attachments {
            __typename
            items {
              __typename
              id
              courseId
              courseAssetId
              description
              contentType
              sourceCode
              fileName
              fileType
              fileSize
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCourseAssetsMutation>response.data.deleteCourseAssets;
  }
  async CreateQuiz(
    input: CreateQuizInput,
    condition?: ModelQuizConditionInput
  ): Promise<CreateQuizMutation> {
    const statement = `mutation CreateQuiz($input: CreateQuizInput!, $condition: ModelQuizConditionInput) {
        createQuiz(input: $input, condition: $condition) {
          __typename
          id
          courseId
          questionsRandom
          passingScore
          noAttempts
          score
          labelWhenPassed
          labelWhenFailed
          questionFeedback
          updatedAt
          createdAt
          status
          questions {
            __typename
            items {
              __typename
              id
              quizContentId
              order
              title
              qType
              labelCorrectMessage
              labelIncorrectMessage
              questionFeedBack
              media
              sourceMedia
              fileName
              fileType
              fileSize
              updatedAt
              createdAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateQuizMutation>response.data.createQuiz;
  }
  async UpdateQuiz(
    input: UpdateQuizInput,
    condition?: ModelQuizConditionInput
  ): Promise<UpdateQuizMutation> {
    const statement = `mutation UpdateQuiz($input: UpdateQuizInput!, $condition: ModelQuizConditionInput) {
        updateQuiz(input: $input, condition: $condition) {
          __typename
          id
          courseId
          questionsRandom
          passingScore
          noAttempts
          score
          labelWhenPassed
          labelWhenFailed
          questionFeedback
          updatedAt
          createdAt
          status
          questions {
            __typename
            items {
              __typename
              id
              quizContentId
              order
              title
              qType
              labelCorrectMessage
              labelIncorrectMessage
              questionFeedBack
              media
              sourceMedia
              fileName
              fileType
              fileSize
              updatedAt
              createdAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateQuizMutation>response.data.updateQuiz;
  }
  async DeleteQuiz(
    input: DeleteQuizInput,
    condition?: ModelQuizConditionInput
  ): Promise<DeleteQuizMutation> {
    const statement = `mutation DeleteQuiz($input: DeleteQuizInput!, $condition: ModelQuizConditionInput) {
        deleteQuiz(input: $input, condition: $condition) {
          __typename
          id
          courseId
          questionsRandom
          passingScore
          noAttempts
          score
          labelWhenPassed
          labelWhenFailed
          questionFeedback
          updatedAt
          createdAt
          status
          questions {
            __typename
            items {
              __typename
              id
              quizContentId
              order
              title
              qType
              labelCorrectMessage
              labelIncorrectMessage
              questionFeedBack
              media
              sourceMedia
              fileName
              fileType
              fileSize
              updatedAt
              createdAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteQuizMutation>response.data.deleteQuiz;
  }
  async CreateQuizQuestion(
    input: CreateQuizQuestionInput,
    condition?: ModelQuizQuestionConditionInput
  ): Promise<CreateQuizQuestionMutation> {
    const statement = `mutation CreateQuizQuestion($input: CreateQuizQuestionInput!, $condition: ModelQuizQuestionConditionInput) {
        createQuizQuestion(input: $input, condition: $condition) {
          __typename
          id
          quizContentId
          quiz {
            __typename
            id
            courseId
            questionsRandom
            passingScore
            noAttempts
            score
            labelWhenPassed
            labelWhenFailed
            questionFeedback
            updatedAt
            createdAt
            status
            questions {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          order
          title
          qType
          labelCorrectMessage
          labelIncorrectMessage
          questionFeedBack
          media
          sourceMedia
          fileName
          fileType
          fileSize
          updatedAt
          createdAt
          status
          questionAnswers {
            __typename
            items {
              __typename
              id
              quizQuestionId
              order
              isCorrect
              title
              answer
              answerFeedBack
              qType
              media
              sourceMedia
              fileName
              fileType
              fileSize
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateQuizQuestionMutation>response.data.createQuizQuestion;
  }
  async UpdateQuizQuestion(
    input: UpdateQuizQuestionInput,
    condition?: ModelQuizQuestionConditionInput
  ): Promise<UpdateQuizQuestionMutation> {
    const statement = `mutation UpdateQuizQuestion($input: UpdateQuizQuestionInput!, $condition: ModelQuizQuestionConditionInput) {
        updateQuizQuestion(input: $input, condition: $condition) {
          __typename
          id
          quizContentId
          quiz {
            __typename
            id
            courseId
            questionsRandom
            passingScore
            noAttempts
            score
            labelWhenPassed
            labelWhenFailed
            questionFeedback
            updatedAt
            createdAt
            status
            questions {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          order
          title
          qType
          labelCorrectMessage
          labelIncorrectMessage
          questionFeedBack
          media
          sourceMedia
          fileName
          fileType
          fileSize
          updatedAt
          createdAt
          status
          questionAnswers {
            __typename
            items {
              __typename
              id
              quizQuestionId
              order
              isCorrect
              title
              answer
              answerFeedBack
              qType
              media
              sourceMedia
              fileName
              fileType
              fileSize
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateQuizQuestionMutation>response.data.updateQuizQuestion;
  }
  async DeleteQuizQuestion(
    input: DeleteQuizQuestionInput,
    condition?: ModelQuizQuestionConditionInput
  ): Promise<DeleteQuizQuestionMutation> {
    const statement = `mutation DeleteQuizQuestion($input: DeleteQuizQuestionInput!, $condition: ModelQuizQuestionConditionInput) {
        deleteQuizQuestion(input: $input, condition: $condition) {
          __typename
          id
          quizContentId
          quiz {
            __typename
            id
            courseId
            questionsRandom
            passingScore
            noAttempts
            score
            labelWhenPassed
            labelWhenFailed
            questionFeedback
            updatedAt
            createdAt
            status
            questions {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          order
          title
          qType
          labelCorrectMessage
          labelIncorrectMessage
          questionFeedBack
          media
          sourceMedia
          fileName
          fileType
          fileSize
          updatedAt
          createdAt
          status
          questionAnswers {
            __typename
            items {
              __typename
              id
              quizQuestionId
              order
              isCorrect
              title
              answer
              answerFeedBack
              qType
              media
              sourceMedia
              fileName
              fileType
              fileSize
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteQuizQuestionMutation>response.data.deleteQuizQuestion;
  }
  async CreateQuestionAnswers(
    input: CreateQuestionAnswersInput,
    condition?: ModelQuestionAnswersConditionInput
  ): Promise<CreateQuestionAnswersMutation> {
    const statement = `mutation CreateQuestionAnswers($input: CreateQuestionAnswersInput!, $condition: ModelQuestionAnswersConditionInput) {
        createQuestionAnswers(input: $input, condition: $condition) {
          __typename
          id
          quizQuestionId
          question {
            __typename
            id
            quizContentId
            quiz {
              __typename
              id
              courseId
              questionsRandom
              passingScore
              noAttempts
              score
              labelWhenPassed
              labelWhenFailed
              questionFeedback
              updatedAt
              createdAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            order
            title
            qType
            labelCorrectMessage
            labelIncorrectMessage
            questionFeedBack
            media
            sourceMedia
            fileName
            fileType
            fileSize
            updatedAt
            createdAt
            status
            questionAnswers {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          order
          isCorrect
          title
          answer
          answerFeedBack
          qType
          media
          sourceMedia
          fileName
          fileType
          fileSize
          status
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateQuestionAnswersMutation>response.data.createQuestionAnswers;
  }
  async UpdateQuestionAnswers(
    input: UpdateQuestionAnswersInput,
    condition?: ModelQuestionAnswersConditionInput
  ): Promise<UpdateQuestionAnswersMutation> {
    const statement = `mutation UpdateQuestionAnswers($input: UpdateQuestionAnswersInput!, $condition: ModelQuestionAnswersConditionInput) {
        updateQuestionAnswers(input: $input, condition: $condition) {
          __typename
          id
          quizQuestionId
          question {
            __typename
            id
            quizContentId
            quiz {
              __typename
              id
              courseId
              questionsRandom
              passingScore
              noAttempts
              score
              labelWhenPassed
              labelWhenFailed
              questionFeedback
              updatedAt
              createdAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            order
            title
            qType
            labelCorrectMessage
            labelIncorrectMessage
            questionFeedBack
            media
            sourceMedia
            fileName
            fileType
            fileSize
            updatedAt
            createdAt
            status
            questionAnswers {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          order
          isCorrect
          title
          answer
          answerFeedBack
          qType
          media
          sourceMedia
          fileName
          fileType
          fileSize
          status
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateQuestionAnswersMutation>response.data.updateQuestionAnswers;
  }
  async DeleteQuestionAnswers(
    input: DeleteQuestionAnswersInput,
    condition?: ModelQuestionAnswersConditionInput
  ): Promise<DeleteQuestionAnswersMutation> {
    const statement = `mutation DeleteQuestionAnswers($input: DeleteQuestionAnswersInput!, $condition: ModelQuestionAnswersConditionInput) {
        deleteQuestionAnswers(input: $input, condition: $condition) {
          __typename
          id
          quizQuestionId
          question {
            __typename
            id
            quizContentId
            quiz {
              __typename
              id
              courseId
              questionsRandom
              passingScore
              noAttempts
              score
              labelWhenPassed
              labelWhenFailed
              questionFeedback
              updatedAt
              createdAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            order
            title
            qType
            labelCorrectMessage
            labelIncorrectMessage
            questionFeedBack
            media
            sourceMedia
            fileName
            fileType
            fileSize
            updatedAt
            createdAt
            status
            questionAnswers {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          order
          isCorrect
          title
          answer
          answerFeedBack
          qType
          media
          sourceMedia
          fileName
          fileType
          fileSize
          status
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteQuestionAnswersMutation>response.data.deleteQuestionAnswers;
  }
  async CreateCourseTags(
    input: CreateCourseTagsInput,
    condition?: ModelCourseTagsConditionInput
  ): Promise<CreateCourseTagsMutation> {
    const statement = `mutation CreateCourseTags($input: CreateCourseTagsInput!, $condition: ModelCourseTagsConditionInput) {
        createCourseTags(input: $input, condition: $condition) {
          __typename
          id
          tag
          createdAt
          updatedAt
          status
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCourseTagsMutation>response.data.createCourseTags;
  }
  async UpdateCourseTags(
    input: UpdateCourseTagsInput,
    condition?: ModelCourseTagsConditionInput
  ): Promise<UpdateCourseTagsMutation> {
    const statement = `mutation UpdateCourseTags($input: UpdateCourseTagsInput!, $condition: ModelCourseTagsConditionInput) {
        updateCourseTags(input: $input, condition: $condition) {
          __typename
          id
          tag
          createdAt
          updatedAt
          status
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCourseTagsMutation>response.data.updateCourseTags;
  }
  async DeleteCourseTags(
    input: DeleteCourseTagsInput,
    condition?: ModelCourseTagsConditionInput
  ): Promise<DeleteCourseTagsMutation> {
    const statement = `mutation DeleteCourseTags($input: DeleteCourseTagsInput!, $condition: ModelCourseTagsConditionInput) {
        deleteCourseTags(input: $input, condition: $condition) {
          __typename
          id
          tag
          createdAt
          updatedAt
          status
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCourseTagsMutation>response.data.deleteCourseTags;
  }
  async CreateCourseAssetsResource(
    input: CreateCourseAssetsResourceInput,
    condition?: ModelCourseAssetsResourceConditionInput
  ): Promise<CreateCourseAssetsResourceMutation> {
    const statement = `mutation CreateCourseAssetsResource($input: CreateCourseAssetsResourceInput!, $condition: ModelCourseAssetsResourceConditionInput) {
        createCourseAssetsResource(input: $input, condition: $condition) {
          __typename
          id
          courseId
          courseAssetId
          description
          contentType
          sourceCode
          fileName
          fileType
          fileSize
          status
          updatedAt
          createdAt
          courseAsset {
            __typename
            id
            courseId
            assetId
            assetType
            title
            description
            order
            timeAprox
            starred
            important
            showAsOverview
            showAsFree
            nextStep
            prevStep
            status
            updatedAt
            createdAt
            course {
              __typename
              id
              title
              description
              categoryId
              coverImage
              timeAprox
              status
              coverImage1
              coverImage2
              coverImage3
              courseType
              courseUrl
              overviewSourceMedia
              overviewFileName
              overviewFileType
              overviewFileSize
              transcoderMeta
              transcoderJobId
              courseTrend
              learnDescription
              requirements
              level
              tags
              trending
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            topic {
              __typename
              id
              courseId
              contentType
              media
              sourceMedia
              fileName
              fileType
              fileSize
              transcoderMeta
              transcoderJobId
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            quiz {
              __typename
              id
              courseId
              questionsRandom
              passingScore
              noAttempts
              score
              labelWhenPassed
              labelWhenFailed
              questionFeedback
              updatedAt
              createdAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            attachments {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCourseAssetsResourceMutation>(
      response.data.createCourseAssetsResource
    );
  }
  async UpdateCourseAssetsResource(
    input: UpdateCourseAssetsResourceInput,
    condition?: ModelCourseAssetsResourceConditionInput
  ): Promise<UpdateCourseAssetsResourceMutation> {
    const statement = `mutation UpdateCourseAssetsResource($input: UpdateCourseAssetsResourceInput!, $condition: ModelCourseAssetsResourceConditionInput) {
        updateCourseAssetsResource(input: $input, condition: $condition) {
          __typename
          id
          courseId
          courseAssetId
          description
          contentType
          sourceCode
          fileName
          fileType
          fileSize
          status
          updatedAt
          createdAt
          courseAsset {
            __typename
            id
            courseId
            assetId
            assetType
            title
            description
            order
            timeAprox
            starred
            important
            showAsOverview
            showAsFree
            nextStep
            prevStep
            status
            updatedAt
            createdAt
            course {
              __typename
              id
              title
              description
              categoryId
              coverImage
              timeAprox
              status
              coverImage1
              coverImage2
              coverImage3
              courseType
              courseUrl
              overviewSourceMedia
              overviewFileName
              overviewFileType
              overviewFileSize
              transcoderMeta
              transcoderJobId
              courseTrend
              learnDescription
              requirements
              level
              tags
              trending
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            topic {
              __typename
              id
              courseId
              contentType
              media
              sourceMedia
              fileName
              fileType
              fileSize
              transcoderMeta
              transcoderJobId
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            quiz {
              __typename
              id
              courseId
              questionsRandom
              passingScore
              noAttempts
              score
              labelWhenPassed
              labelWhenFailed
              questionFeedback
              updatedAt
              createdAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            attachments {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCourseAssetsResourceMutation>(
      response.data.updateCourseAssetsResource
    );
  }
  async DeleteCourseAssetsResource(
    input: DeleteCourseAssetsResourceInput,
    condition?: ModelCourseAssetsResourceConditionInput
  ): Promise<DeleteCourseAssetsResourceMutation> {
    const statement = `mutation DeleteCourseAssetsResource($input: DeleteCourseAssetsResourceInput!, $condition: ModelCourseAssetsResourceConditionInput) {
        deleteCourseAssetsResource(input: $input, condition: $condition) {
          __typename
          id
          courseId
          courseAssetId
          description
          contentType
          sourceCode
          fileName
          fileType
          fileSize
          status
          updatedAt
          createdAt
          courseAsset {
            __typename
            id
            courseId
            assetId
            assetType
            title
            description
            order
            timeAprox
            starred
            important
            showAsOverview
            showAsFree
            nextStep
            prevStep
            status
            updatedAt
            createdAt
            course {
              __typename
              id
              title
              description
              categoryId
              coverImage
              timeAprox
              status
              coverImage1
              coverImage2
              coverImage3
              courseType
              courseUrl
              overviewSourceMedia
              overviewFileName
              overviewFileType
              overviewFileSize
              transcoderMeta
              transcoderJobId
              courseTrend
              learnDescription
              requirements
              level
              tags
              trending
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            topic {
              __typename
              id
              courseId
              contentType
              media
              sourceMedia
              fileName
              fileType
              fileSize
              transcoderMeta
              transcoderJobId
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            quiz {
              __typename
              id
              courseId
              questionsRandom
              passingScore
              noAttempts
              score
              labelWhenPassed
              labelWhenFailed
              questionFeedback
              updatedAt
              createdAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            attachments {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCourseAssetsResourceMutation>(
      response.data.deleteCourseAssetsResource
    );
  }
  async CreateCourseAssetSubs(
    input: CreateCourseAssetSubsInput,
    condition?: ModelCourseAssetSubsConditionInput
  ): Promise<CreateCourseAssetSubsMutation> {
    const statement = `mutation CreateCourseAssetSubs($input: CreateCourseAssetSubsInput!, $condition: ModelCourseAssetSubsConditionInput) {
        createCourseAssetSubs(input: $input, condition: $condition) {
          __typename
          id
          courseSubsId
          assetId
          userId
          assetType
          title
          description
          category
          metaData
          status
          asset {
            __typename
            id
            courseId
            assetId
            assetType
            title
            description
            order
            timeAprox
            starred
            important
            showAsOverview
            showAsFree
            nextStep
            prevStep
            status
            updatedAt
            createdAt
            course {
              __typename
              id
              title
              description
              categoryId
              coverImage
              timeAprox
              status
              coverImage1
              coverImage2
              coverImage3
              courseType
              courseUrl
              overviewSourceMedia
              overviewFileName
              overviewFileType
              overviewFileSize
              transcoderMeta
              transcoderJobId
              courseTrend
              learnDescription
              requirements
              level
              tags
              trending
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            topic {
              __typename
              id
              courseId
              contentType
              media
              sourceMedia
              fileName
              fileType
              fileSize
              transcoderMeta
              transcoderJobId
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            quiz {
              __typename
              id
              courseId
              questionsRandom
              passingScore
              noAttempts
              score
              labelWhenPassed
              labelWhenFailed
              questionFeedback
              updatedAt
              createdAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            attachments {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          assetsSubs {
            __typename
            id
            courseId
            userId
            title
            description
            category
            level
            tags
            metaData
            status
            course {
              __typename
              id
              title
              description
              categoryId
              coverImage
              timeAprox
              status
              coverImage1
              coverImage2
              coverImage3
              courseType
              courseUrl
              overviewSourceMedia
              overviewFileName
              overviewFileType
              overviewFileSize
              transcoderMeta
              transcoderJobId
              courseTrend
              learnDescription
              requirements
              level
              tags
              trending
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            owner
            assetsSubs {
              __typename
              nextToken
              startedAt
            }
          }
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCourseAssetSubsMutation>response.data.createCourseAssetSubs;
  }
  async UpdateCourseAssetSubs(
    input: UpdateCourseAssetSubsInput,
    condition?: ModelCourseAssetSubsConditionInput
  ): Promise<UpdateCourseAssetSubsMutation> {
    const statement = `mutation UpdateCourseAssetSubs($input: UpdateCourseAssetSubsInput!, $condition: ModelCourseAssetSubsConditionInput) {
        updateCourseAssetSubs(input: $input, condition: $condition) {
          __typename
          id
          courseSubsId
          assetId
          userId
          assetType
          title
          description
          category
          metaData
          status
          asset {
            __typename
            id
            courseId
            assetId
            assetType
            title
            description
            order
            timeAprox
            starred
            important
            showAsOverview
            showAsFree
            nextStep
            prevStep
            status
            updatedAt
            createdAt
            course {
              __typename
              id
              title
              description
              categoryId
              coverImage
              timeAprox
              status
              coverImage1
              coverImage2
              coverImage3
              courseType
              courseUrl
              overviewSourceMedia
              overviewFileName
              overviewFileType
              overviewFileSize
              transcoderMeta
              transcoderJobId
              courseTrend
              learnDescription
              requirements
              level
              tags
              trending
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            topic {
              __typename
              id
              courseId
              contentType
              media
              sourceMedia
              fileName
              fileType
              fileSize
              transcoderMeta
              transcoderJobId
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            quiz {
              __typename
              id
              courseId
              questionsRandom
              passingScore
              noAttempts
              score
              labelWhenPassed
              labelWhenFailed
              questionFeedback
              updatedAt
              createdAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            attachments {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          assetsSubs {
            __typename
            id
            courseId
            userId
            title
            description
            category
            level
            tags
            metaData
            status
            course {
              __typename
              id
              title
              description
              categoryId
              coverImage
              timeAprox
              status
              coverImage1
              coverImage2
              coverImage3
              courseType
              courseUrl
              overviewSourceMedia
              overviewFileName
              overviewFileType
              overviewFileSize
              transcoderMeta
              transcoderJobId
              courseTrend
              learnDescription
              requirements
              level
              tags
              trending
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            owner
            assetsSubs {
              __typename
              nextToken
              startedAt
            }
          }
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCourseAssetSubsMutation>response.data.updateCourseAssetSubs;
  }
  async DeleteCourseAssetSubs(
    input: DeleteCourseAssetSubsInput,
    condition?: ModelCourseAssetSubsConditionInput
  ): Promise<DeleteCourseAssetSubsMutation> {
    const statement = `mutation DeleteCourseAssetSubs($input: DeleteCourseAssetSubsInput!, $condition: ModelCourseAssetSubsConditionInput) {
        deleteCourseAssetSubs(input: $input, condition: $condition) {
          __typename
          id
          courseSubsId
          assetId
          userId
          assetType
          title
          description
          category
          metaData
          status
          asset {
            __typename
            id
            courseId
            assetId
            assetType
            title
            description
            order
            timeAprox
            starred
            important
            showAsOverview
            showAsFree
            nextStep
            prevStep
            status
            updatedAt
            createdAt
            course {
              __typename
              id
              title
              description
              categoryId
              coverImage
              timeAprox
              status
              coverImage1
              coverImage2
              coverImage3
              courseType
              courseUrl
              overviewSourceMedia
              overviewFileName
              overviewFileType
              overviewFileSize
              transcoderMeta
              transcoderJobId
              courseTrend
              learnDescription
              requirements
              level
              tags
              trending
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            topic {
              __typename
              id
              courseId
              contentType
              media
              sourceMedia
              fileName
              fileType
              fileSize
              transcoderMeta
              transcoderJobId
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            quiz {
              __typename
              id
              courseId
              questionsRandom
              passingScore
              noAttempts
              score
              labelWhenPassed
              labelWhenFailed
              questionFeedback
              updatedAt
              createdAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            attachments {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          assetsSubs {
            __typename
            id
            courseId
            userId
            title
            description
            category
            level
            tags
            metaData
            status
            course {
              __typename
              id
              title
              description
              categoryId
              coverImage
              timeAprox
              status
              coverImage1
              coverImage2
              coverImage3
              courseType
              courseUrl
              overviewSourceMedia
              overviewFileName
              overviewFileType
              overviewFileSize
              transcoderMeta
              transcoderJobId
              courseTrend
              learnDescription
              requirements
              level
              tags
              trending
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            owner
            assetsSubs {
              __typename
              nextToken
              startedAt
            }
          }
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCourseAssetSubsMutation>response.data.deleteCourseAssetSubs;
  }
  async CreateContact(
    input: CreateContactInput,
    condition?: ModelContactConditionInput
  ): Promise<CreateContactMutation> {
    const statement = `mutation CreateContact($input: CreateContactInput!, $condition: ModelContactConditionInput) {
        createContact(input: $input, condition: $condition) {
          __typename
          id
          userID
          syncID
          contactType
          contactName
          firstName
          lastName
          email
          phone
          avatar
          avatarType
          jobTitle
          birthday
          starred
          frequent
          status
          pbProfileOptions
          userRelatedId
          notes
          businessId
          contactSince
          updatedAt
          createdAt
          contactRelId
          contactRel {
            __typename
            id
            userID
            syncID
            contactType
            contactName
            firstName
            lastName
            email
            phone
            avatar
            avatarType
            jobTitle
            birthday
            starred
            frequent
            status
            pbProfileOptions
            userRelatedId
            notes
            businessId
            contactSince
            updatedAt
            createdAt
            contactRelId
            contactRel {
              __typename
              id
              userID
              syncID
              contactType
              contactName
              firstName
              lastName
              email
              phone
              avatar
              avatarType
              jobTitle
              birthday
              starred
              frequent
              status
              pbProfileOptions
              userRelatedId
              notes
              businessId
              contactSince
              updatedAt
              createdAt
              contactRelId
              _version
              _deleted
              _lastChangedAt
            }
            addresses {
              __typename
              nextToken
              startedAt
            }
            connects {
              __typename
              nextToken
              startedAt
            }
            licence {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          addresses {
            __typename
            items {
              __typename
              id
              contactId
              addressType
              street
              street1
              zipCode
              city
              state
              Country
              status
              updatedAt
              createdAt
              lat
              lng
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          connects {
            __typename
            items {
              __typename
              id
              contactId
              connsContactId
              contactType
              contactName
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          licence {
            __typename
            items {
              __typename
              id
              contactId
              noLicenses
              noClaimed
              groupId
              group
              prefix
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateContactMutation>response.data.createContact;
  }
  async UpdateContact(
    input: UpdateContactInput,
    condition?: ModelContactConditionInput
  ): Promise<UpdateContactMutation> {
    const statement = `mutation UpdateContact($input: UpdateContactInput!, $condition: ModelContactConditionInput) {
        updateContact(input: $input, condition: $condition) {
          __typename
          id
          userID
          syncID
          contactType
          contactName
          firstName
          lastName
          email
          phone
          avatar
          avatarType
          jobTitle
          birthday
          starred
          frequent
          status
          pbProfileOptions
          userRelatedId
          notes
          businessId
          contactSince
          updatedAt
          createdAt
          contactRelId
          contactRel {
            __typename
            id
            userID
            syncID
            contactType
            contactName
            firstName
            lastName
            email
            phone
            avatar
            avatarType
            jobTitle
            birthday
            starred
            frequent
            status
            pbProfileOptions
            userRelatedId
            notes
            businessId
            contactSince
            updatedAt
            createdAt
            contactRelId
            contactRel {
              __typename
              id
              userID
              syncID
              contactType
              contactName
              firstName
              lastName
              email
              phone
              avatar
              avatarType
              jobTitle
              birthday
              starred
              frequent
              status
              pbProfileOptions
              userRelatedId
              notes
              businessId
              contactSince
              updatedAt
              createdAt
              contactRelId
              _version
              _deleted
              _lastChangedAt
            }
            addresses {
              __typename
              nextToken
              startedAt
            }
            connects {
              __typename
              nextToken
              startedAt
            }
            licence {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          addresses {
            __typename
            items {
              __typename
              id
              contactId
              addressType
              street
              street1
              zipCode
              city
              state
              Country
              status
              updatedAt
              createdAt
              lat
              lng
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          connects {
            __typename
            items {
              __typename
              id
              contactId
              connsContactId
              contactType
              contactName
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          licence {
            __typename
            items {
              __typename
              id
              contactId
              noLicenses
              noClaimed
              groupId
              group
              prefix
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateContactMutation>response.data.updateContact;
  }
  async DeleteContact(
    input: DeleteContactInput,
    condition?: ModelContactConditionInput
  ): Promise<DeleteContactMutation> {
    const statement = `mutation DeleteContact($input: DeleteContactInput!, $condition: ModelContactConditionInput) {
        deleteContact(input: $input, condition: $condition) {
          __typename
          id
          userID
          syncID
          contactType
          contactName
          firstName
          lastName
          email
          phone
          avatar
          avatarType
          jobTitle
          birthday
          starred
          frequent
          status
          pbProfileOptions
          userRelatedId
          notes
          businessId
          contactSince
          updatedAt
          createdAt
          contactRelId
          contactRel {
            __typename
            id
            userID
            syncID
            contactType
            contactName
            firstName
            lastName
            email
            phone
            avatar
            avatarType
            jobTitle
            birthday
            starred
            frequent
            status
            pbProfileOptions
            userRelatedId
            notes
            businessId
            contactSince
            updatedAt
            createdAt
            contactRelId
            contactRel {
              __typename
              id
              userID
              syncID
              contactType
              contactName
              firstName
              lastName
              email
              phone
              avatar
              avatarType
              jobTitle
              birthday
              starred
              frequent
              status
              pbProfileOptions
              userRelatedId
              notes
              businessId
              contactSince
              updatedAt
              createdAt
              contactRelId
              _version
              _deleted
              _lastChangedAt
            }
            addresses {
              __typename
              nextToken
              startedAt
            }
            connects {
              __typename
              nextToken
              startedAt
            }
            licence {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          addresses {
            __typename
            items {
              __typename
              id
              contactId
              addressType
              street
              street1
              zipCode
              city
              state
              Country
              status
              updatedAt
              createdAt
              lat
              lng
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          connects {
            __typename
            items {
              __typename
              id
              contactId
              connsContactId
              contactType
              contactName
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          licence {
            __typename
            items {
              __typename
              id
              contactId
              noLicenses
              noClaimed
              groupId
              group
              prefix
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteContactMutation>response.data.deleteContact;
  }
  async CreateConnects(
    input: CreateConnectsInput,
    condition?: ModelConnectsConditionInput
  ): Promise<CreateConnectsMutation> {
    const statement = `mutation CreateConnects($input: CreateConnectsInput!, $condition: ModelConnectsConditionInput) {
        createConnects(input: $input, condition: $condition) {
          __typename
          id
          contactId
          connsContactId
          contactType
          contactName
          status
          updatedAt
          createdAt
          contact {
            __typename
            id
            userID
            syncID
            contactType
            contactName
            firstName
            lastName
            email
            phone
            avatar
            avatarType
            jobTitle
            birthday
            starred
            frequent
            status
            pbProfileOptions
            userRelatedId
            notes
            businessId
            contactSince
            updatedAt
            createdAt
            contactRelId
            contactRel {
              __typename
              id
              userID
              syncID
              contactType
              contactName
              firstName
              lastName
              email
              phone
              avatar
              avatarType
              jobTitle
              birthday
              starred
              frequent
              status
              pbProfileOptions
              userRelatedId
              notes
              businessId
              contactSince
              updatedAt
              createdAt
              contactRelId
              _version
              _deleted
              _lastChangedAt
            }
            addresses {
              __typename
              nextToken
              startedAt
            }
            connects {
              __typename
              nextToken
              startedAt
            }
            licence {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateConnectsMutation>response.data.createConnects;
  }
  async UpdateConnects(
    input: UpdateConnectsInput,
    condition?: ModelConnectsConditionInput
  ): Promise<UpdateConnectsMutation> {
    const statement = `mutation UpdateConnects($input: UpdateConnectsInput!, $condition: ModelConnectsConditionInput) {
        updateConnects(input: $input, condition: $condition) {
          __typename
          id
          contactId
          connsContactId
          contactType
          contactName
          status
          updatedAt
          createdAt
          contact {
            __typename
            id
            userID
            syncID
            contactType
            contactName
            firstName
            lastName
            email
            phone
            avatar
            avatarType
            jobTitle
            birthday
            starred
            frequent
            status
            pbProfileOptions
            userRelatedId
            notes
            businessId
            contactSince
            updatedAt
            createdAt
            contactRelId
            contactRel {
              __typename
              id
              userID
              syncID
              contactType
              contactName
              firstName
              lastName
              email
              phone
              avatar
              avatarType
              jobTitle
              birthday
              starred
              frequent
              status
              pbProfileOptions
              userRelatedId
              notes
              businessId
              contactSince
              updatedAt
              createdAt
              contactRelId
              _version
              _deleted
              _lastChangedAt
            }
            addresses {
              __typename
              nextToken
              startedAt
            }
            connects {
              __typename
              nextToken
              startedAt
            }
            licence {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateConnectsMutation>response.data.updateConnects;
  }
  async DeleteConnects(
    input: DeleteConnectsInput,
    condition?: ModelConnectsConditionInput
  ): Promise<DeleteConnectsMutation> {
    const statement = `mutation DeleteConnects($input: DeleteConnectsInput!, $condition: ModelConnectsConditionInput) {
        deleteConnects(input: $input, condition: $condition) {
          __typename
          id
          contactId
          connsContactId
          contactType
          contactName
          status
          updatedAt
          createdAt
          contact {
            __typename
            id
            userID
            syncID
            contactType
            contactName
            firstName
            lastName
            email
            phone
            avatar
            avatarType
            jobTitle
            birthday
            starred
            frequent
            status
            pbProfileOptions
            userRelatedId
            notes
            businessId
            contactSince
            updatedAt
            createdAt
            contactRelId
            contactRel {
              __typename
              id
              userID
              syncID
              contactType
              contactName
              firstName
              lastName
              email
              phone
              avatar
              avatarType
              jobTitle
              birthday
              starred
              frequent
              status
              pbProfileOptions
              userRelatedId
              notes
              businessId
              contactSince
              updatedAt
              createdAt
              contactRelId
              _version
              _deleted
              _lastChangedAt
            }
            addresses {
              __typename
              nextToken
              startedAt
            }
            connects {
              __typename
              nextToken
              startedAt
            }
            licence {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteConnectsMutation>response.data.deleteConnects;
  }
  async CreateContactAddress(
    input: CreateContactAddressInput,
    condition?: ModelContactAddressConditionInput
  ): Promise<CreateContactAddressMutation> {
    const statement = `mutation CreateContactAddress($input: CreateContactAddressInput!, $condition: ModelContactAddressConditionInput) {
        createContactAddress(input: $input, condition: $condition) {
          __typename
          id
          contactId
          addressType
          street
          street1
          zipCode
          city
          state
          Country
          status
          updatedAt
          createdAt
          lat
          lng
          contact {
            __typename
            id
            userID
            syncID
            contactType
            contactName
            firstName
            lastName
            email
            phone
            avatar
            avatarType
            jobTitle
            birthday
            starred
            frequent
            status
            pbProfileOptions
            userRelatedId
            notes
            businessId
            contactSince
            updatedAt
            createdAt
            contactRelId
            contactRel {
              __typename
              id
              userID
              syncID
              contactType
              contactName
              firstName
              lastName
              email
              phone
              avatar
              avatarType
              jobTitle
              birthday
              starred
              frequent
              status
              pbProfileOptions
              userRelatedId
              notes
              businessId
              contactSince
              updatedAt
              createdAt
              contactRelId
              _version
              _deleted
              _lastChangedAt
            }
            addresses {
              __typename
              nextToken
              startedAt
            }
            connects {
              __typename
              nextToken
              startedAt
            }
            licence {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateContactAddressMutation>response.data.createContactAddress;
  }
  async UpdateContactAddress(
    input: UpdateContactAddressInput,
    condition?: ModelContactAddressConditionInput
  ): Promise<UpdateContactAddressMutation> {
    const statement = `mutation UpdateContactAddress($input: UpdateContactAddressInput!, $condition: ModelContactAddressConditionInput) {
        updateContactAddress(input: $input, condition: $condition) {
          __typename
          id
          contactId
          addressType
          street
          street1
          zipCode
          city
          state
          Country
          status
          updatedAt
          createdAt
          lat
          lng
          contact {
            __typename
            id
            userID
            syncID
            contactType
            contactName
            firstName
            lastName
            email
            phone
            avatar
            avatarType
            jobTitle
            birthday
            starred
            frequent
            status
            pbProfileOptions
            userRelatedId
            notes
            businessId
            contactSince
            updatedAt
            createdAt
            contactRelId
            contactRel {
              __typename
              id
              userID
              syncID
              contactType
              contactName
              firstName
              lastName
              email
              phone
              avatar
              avatarType
              jobTitle
              birthday
              starred
              frequent
              status
              pbProfileOptions
              userRelatedId
              notes
              businessId
              contactSince
              updatedAt
              createdAt
              contactRelId
              _version
              _deleted
              _lastChangedAt
            }
            addresses {
              __typename
              nextToken
              startedAt
            }
            connects {
              __typename
              nextToken
              startedAt
            }
            licence {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateContactAddressMutation>response.data.updateContactAddress;
  }
  async DeleteContactAddress(
    input: DeleteContactAddressInput,
    condition?: ModelContactAddressConditionInput
  ): Promise<DeleteContactAddressMutation> {
    const statement = `mutation DeleteContactAddress($input: DeleteContactAddressInput!, $condition: ModelContactAddressConditionInput) {
        deleteContactAddress(input: $input, condition: $condition) {
          __typename
          id
          contactId
          addressType
          street
          street1
          zipCode
          city
          state
          Country
          status
          updatedAt
          createdAt
          lat
          lng
          contact {
            __typename
            id
            userID
            syncID
            contactType
            contactName
            firstName
            lastName
            email
            phone
            avatar
            avatarType
            jobTitle
            birthday
            starred
            frequent
            status
            pbProfileOptions
            userRelatedId
            notes
            businessId
            contactSince
            updatedAt
            createdAt
            contactRelId
            contactRel {
              __typename
              id
              userID
              syncID
              contactType
              contactName
              firstName
              lastName
              email
              phone
              avatar
              avatarType
              jobTitle
              birthday
              starred
              frequent
              status
              pbProfileOptions
              userRelatedId
              notes
              businessId
              contactSince
              updatedAt
              createdAt
              contactRelId
              _version
              _deleted
              _lastChangedAt
            }
            addresses {
              __typename
              nextToken
              startedAt
            }
            connects {
              __typename
              nextToken
              startedAt
            }
            licence {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteContactAddressMutation>response.data.deleteContactAddress;
  }
  async CreateMembership(
    input: CreateMembershipInput,
    condition?: ModelMembershipConditionInput
  ): Promise<CreateMembershipMutation> {
    const statement = `mutation CreateMembership($input: CreateMembershipInput!, $condition: ModelMembershipConditionInput) {
        createMembership(input: $input, condition: $condition) {
          __typename
          id
          contactId
          noLicenses
          noClaimed
          groupId
          group
          prefix
          status
          updatedAt
          createdAt
          org {
            __typename
            id
            userID
            syncID
            contactType
            contactName
            firstName
            lastName
            email
            phone
            avatar
            avatarType
            jobTitle
            birthday
            starred
            frequent
            status
            pbProfileOptions
            userRelatedId
            notes
            businessId
            contactSince
            updatedAt
            createdAt
            contactRelId
            contactRel {
              __typename
              id
              userID
              syncID
              contactType
              contactName
              firstName
              lastName
              email
              phone
              avatar
              avatarType
              jobTitle
              birthday
              starred
              frequent
              status
              pbProfileOptions
              userRelatedId
              notes
              businessId
              contactSince
              updatedAt
              createdAt
              contactRelId
              _version
              _deleted
              _lastChangedAt
            }
            addresses {
              __typename
              nextToken
              startedAt
            }
            connects {
              __typename
              nextToken
              startedAt
            }
            licence {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMembershipMutation>response.data.createMembership;
  }
  async UpdateMembership(
    input: UpdateMembershipInput,
    condition?: ModelMembershipConditionInput
  ): Promise<UpdateMembershipMutation> {
    const statement = `mutation UpdateMembership($input: UpdateMembershipInput!, $condition: ModelMembershipConditionInput) {
        updateMembership(input: $input, condition: $condition) {
          __typename
          id
          contactId
          noLicenses
          noClaimed
          groupId
          group
          prefix
          status
          updatedAt
          createdAt
          org {
            __typename
            id
            userID
            syncID
            contactType
            contactName
            firstName
            lastName
            email
            phone
            avatar
            avatarType
            jobTitle
            birthday
            starred
            frequent
            status
            pbProfileOptions
            userRelatedId
            notes
            businessId
            contactSince
            updatedAt
            createdAt
            contactRelId
            contactRel {
              __typename
              id
              userID
              syncID
              contactType
              contactName
              firstName
              lastName
              email
              phone
              avatar
              avatarType
              jobTitle
              birthday
              starred
              frequent
              status
              pbProfileOptions
              userRelatedId
              notes
              businessId
              contactSince
              updatedAt
              createdAt
              contactRelId
              _version
              _deleted
              _lastChangedAt
            }
            addresses {
              __typename
              nextToken
              startedAt
            }
            connects {
              __typename
              nextToken
              startedAt
            }
            licence {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMembershipMutation>response.data.updateMembership;
  }
  async DeleteMembership(
    input: DeleteMembershipInput,
    condition?: ModelMembershipConditionInput
  ): Promise<DeleteMembershipMutation> {
    const statement = `mutation DeleteMembership($input: DeleteMembershipInput!, $condition: ModelMembershipConditionInput) {
        deleteMembership(input: $input, condition: $condition) {
          __typename
          id
          contactId
          noLicenses
          noClaimed
          groupId
          group
          prefix
          status
          updatedAt
          createdAt
          org {
            __typename
            id
            userID
            syncID
            contactType
            contactName
            firstName
            lastName
            email
            phone
            avatar
            avatarType
            jobTitle
            birthday
            starred
            frequent
            status
            pbProfileOptions
            userRelatedId
            notes
            businessId
            contactSince
            updatedAt
            createdAt
            contactRelId
            contactRel {
              __typename
              id
              userID
              syncID
              contactType
              contactName
              firstName
              lastName
              email
              phone
              avatar
              avatarType
              jobTitle
              birthday
              starred
              frequent
              status
              pbProfileOptions
              userRelatedId
              notes
              businessId
              contactSince
              updatedAt
              createdAt
              contactRelId
              _version
              _deleted
              _lastChangedAt
            }
            addresses {
              __typename
              nextToken
              startedAt
            }
            connects {
              __typename
              nextToken
              startedAt
            }
            licence {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMembershipMutation>response.data.deleteMembership;
  }
  async SyncSessions(
    filter?: ModelSessionFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncSessionsQuery> {
    const statement = `query SyncSessions($filter: ModelSessionFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncSessions(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            userId
            location
            event
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncSessionsQuery>response.data.syncSessions;
  }
  async GetSession(id: string): Promise<GetSessionQuery> {
    const statement = `query GetSession($id: ID!) {
        getSession(id: $id) {
          __typename
          id
          userId
          location
          event
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSessionQuery>response.data.getSession;
  }
  async ListSessions(
    filter?: ModelSessionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSessionsQuery> {
    const statement = `query ListSessions($filter: ModelSessionFilterInput, $limit: Int, $nextToken: String) {
        listSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userId
            location
            event
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSessionsQuery>response.data.listSessions;
  }
  async SyncUserSettings(
    filter?: ModelUserSettingsFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncUserSettingsQuery> {
    const statement = `query SyncUserSettings($filter: ModelUserSettingsFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncUserSettings(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            userId
            pageSize
            notiType
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncUserSettingsQuery>response.data.syncUserSettings;
  }
  async GetUserSettings(id: string): Promise<GetUserSettingsQuery> {
    const statement = `query GetUserSettings($id: ID!) {
        getUserSettings(id: $id) {
          __typename
          id
          userId
          pageSize
          notiType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUserSettingsQuery>response.data.getUserSettings;
  }
  async ListUserSettingss(
    filter?: ModelUserSettingsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListUserSettingssQuery> {
    const statement = `query ListUserSettingss($filter: ModelUserSettingsFilterInput, $limit: Int, $nextToken: String) {
        listUserSettingss(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userId
            pageSize
            notiType
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListUserSettingssQuery>response.data.listUserSettingss;
  }
  async SyncNotifications(
    filter?: ModelNotificationFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncNotificationsQuery> {
    const statement = `query SyncNotifications($filter: ModelNotificationFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncNotifications(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            typeNotify
            userId
            subject
            body
            localUrl
            renotify
            silent
            dateFor
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncNotificationsQuery>response.data.syncNotifications;
  }
  async GetNotification(id: string): Promise<GetNotificationQuery> {
    const statement = `query GetNotification($id: ID!) {
        getNotification(id: $id) {
          __typename
          id
          typeNotify
          userId
          subject
          body
          localUrl
          renotify
          silent
          dateFor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetNotificationQuery>response.data.getNotification;
  }
  async ListNotifications(
    filter?: ModelNotificationFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListNotificationsQuery> {
    const statement = `query ListNotifications($filter: ModelNotificationFilterInput, $limit: Int, $nextToken: String) {
        listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            typeNotify
            userId
            subject
            body
            localUrl
            renotify
            silent
            dateFor
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListNotificationsQuery>response.data.listNotifications;
  }
  async SyncUserPrivacies(
    filter?: ModelUserPrivacyFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncUserPrivaciesQuery> {
    const statement = `query SyncUserPrivacies($filter: ModelUserPrivacyFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncUserPrivacies(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            userId
            profileShared
            bgPhoto
            headLine
            secondHeadLine
            titleLine
            bioLine
            showEmail
            showPhone
            showEmailOn
            contactDirectlyByEmail
            showConnectionsTo
            oaConnInvitationAccepted
            oaEventUpdates
            oaUpdatesFromConnections
            oaBreakingNews
            oaTopsPicks
            oaWhoViewedYourProfile
            oaYourNewFollowers
            emailEventsInvitations
            emailEventsUpdates
            emailNewsInvitations
            emailNewsSubscriptions
            whoRequestForNotifications
            whoInvitationsToAttend
            locMap
            location
            locTitle
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncUserPrivaciesQuery>response.data.syncUserPrivacies;
  }
  async GetUserPrivacy(id: string): Promise<GetUserPrivacyQuery> {
    const statement = `query GetUserPrivacy($id: ID!) {
        getUserPrivacy(id: $id) {
          __typename
          id
          userId
          profileShared
          bgPhoto
          headLine
          secondHeadLine
          titleLine
          bioLine
          showEmail
          showPhone
          showEmailOn
          contactDirectlyByEmail
          showConnectionsTo
          oaConnInvitationAccepted
          oaEventUpdates
          oaUpdatesFromConnections
          oaBreakingNews
          oaTopsPicks
          oaWhoViewedYourProfile
          oaYourNewFollowers
          emailEventsInvitations
          emailEventsUpdates
          emailNewsInvitations
          emailNewsSubscriptions
          whoRequestForNotifications
          whoInvitationsToAttend
          locMap
          location
          locTitle
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUserPrivacyQuery>response.data.getUserPrivacy;
  }
  async ListUserPrivacys(
    filter?: ModelUserPrivacyFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListUserPrivacysQuery> {
    const statement = `query ListUserPrivacys($filter: ModelUserPrivacyFilterInput, $limit: Int, $nextToken: String) {
        listUserPrivacys(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userId
            profileShared
            bgPhoto
            headLine
            secondHeadLine
            titleLine
            bioLine
            showEmail
            showPhone
            showEmailOn
            contactDirectlyByEmail
            showConnectionsTo
            oaConnInvitationAccepted
            oaEventUpdates
            oaUpdatesFromConnections
            oaBreakingNews
            oaTopsPicks
            oaWhoViewedYourProfile
            oaYourNewFollowers
            emailEventsInvitations
            emailEventsUpdates
            emailNewsInvitations
            emailNewsSubscriptions
            whoRequestForNotifications
            whoInvitationsToAttend
            locMap
            location
            locTitle
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListUserPrivacysQuery>response.data.listUserPrivacys;
  }
  async SyncCourseCategories(
    filter?: ModelCourseCategoryFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncCourseCategoriesQuery> {
    const statement = `query SyncCourseCategories($filter: ModelCourseCategoryFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncCourseCategories(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            category
            createdAt
            updatedAt
            status
            courses {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncCourseCategoriesQuery>response.data.syncCourseCategories;
  }
  async GetCourseCategory(id: string): Promise<GetCourseCategoryQuery> {
    const statement = `query GetCourseCategory($id: ID!) {
        getCourseCategory(id: $id) {
          __typename
          id
          category
          createdAt
          updatedAt
          status
          courses {
            __typename
            items {
              __typename
              id
              title
              description
              categoryId
              coverImage
              timeAprox
              status
              coverImage1
              coverImage2
              coverImage3
              courseType
              courseUrl
              overviewSourceMedia
              overviewFileName
              overviewFileType
              overviewFileSize
              transcoderMeta
              transcoderJobId
              courseTrend
              learnDescription
              requirements
              level
              tags
              trending
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCourseCategoryQuery>response.data.getCourseCategory;
  }
  async ListCourseCategorys(
    filter?: ModelCourseCategoryFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCourseCategorysQuery> {
    const statement = `query ListCourseCategorys($filter: ModelCourseCategoryFilterInput, $limit: Int, $nextToken: String) {
        listCourseCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            category
            createdAt
            updatedAt
            status
            courses {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCourseCategorysQuery>response.data.listCourseCategorys;
  }
  async SyncCourseResources(
    filter?: ModelCourseResourceFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncCourseResourcesQuery> {
    const statement = `query SyncCourseResources($filter: ModelCourseResourceFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncCourseResources(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            courseId
            description
            contentType
            sourceCode
            fileName
            fileType
            fileSize
            status
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncCourseResourcesQuery>response.data.syncCourseResources;
  }
  async GetCourseResource(id: string): Promise<GetCourseResourceQuery> {
    const statement = `query GetCourseResource($id: ID!) {
        getCourseResource(id: $id) {
          __typename
          id
          courseId
          description
          contentType
          sourceCode
          fileName
          fileType
          fileSize
          status
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCourseResourceQuery>response.data.getCourseResource;
  }
  async ListCourseResources(
    filter?: ModelCourseResourceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCourseResourcesQuery> {
    const statement = `query ListCourseResources($filter: ModelCourseResourceFilterInput, $limit: Int, $nextToken: String) {
        listCourseResources(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            courseId
            description
            contentType
            sourceCode
            fileName
            fileType
            fileSize
            status
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCourseResourcesQuery>response.data.listCourseResources;
  }
  async SyncContents(
    filter?: ModelContentFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncContentsQuery> {
    const statement = `query SyncContents($filter: ModelContentFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncContents(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            courseId
            contentType
            media
            sourceMedia
            fileName
            fileType
            fileSize
            transcoderMeta
            transcoderJobId
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncContentsQuery>response.data.syncContents;
  }
  async GetContent(id: string): Promise<GetContentQuery> {
    const statement = `query GetContent($id: ID!) {
        getContent(id: $id) {
          __typename
          id
          courseId
          contentType
          media
          sourceMedia
          fileName
          fileType
          fileSize
          transcoderMeta
          transcoderJobId
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetContentQuery>response.data.getContent;
  }
  async ListContents(
    filter?: ModelContentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListContentsQuery> {
    const statement = `query ListContents($filter: ModelContentFilterInput, $limit: Int, $nextToken: String) {
        listContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            courseId
            contentType
            media
            sourceMedia
            fileName
            fileType
            fileSize
            transcoderMeta
            transcoderJobId
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListContentsQuery>response.data.listContents;
  }
  async SyncContentResources(
    filter?: ModelContentResourceFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncContentResourcesQuery> {
    const statement = `query SyncContentResources($filter: ModelContentResourceFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncContentResources(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            courseId
            description
            contentType
            sourceCode
            fileName
            fileType
            fileSize
            status
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncContentResourcesQuery>response.data.syncContentResources;
  }
  async GetContentResource(id: string): Promise<GetContentResourceQuery> {
    const statement = `query GetContentResource($id: ID!) {
        getContentResource(id: $id) {
          __typename
          id
          courseId
          description
          contentType
          sourceCode
          fileName
          fileType
          fileSize
          status
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetContentResourceQuery>response.data.getContentResource;
  }
  async ListContentResources(
    filter?: ModelContentResourceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListContentResourcesQuery> {
    const statement = `query ListContentResources($filter: ModelContentResourceFilterInput, $limit: Int, $nextToken: String) {
        listContentResources(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            courseId
            description
            contentType
            sourceCode
            fileName
            fileType
            fileSize
            status
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListContentResourcesQuery>response.data.listContentResources;
  }
  async SyncCourses(
    filter?: ModelCourseFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncCoursesQuery> {
    const statement = `query SyncCourses($filter: ModelCourseFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncCourses(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            title
            description
            category {
              __typename
              id
              category
              createdAt
              updatedAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            categoryId
            coverImage
            timeAprox
            status
            coverImage1
            coverImage2
            coverImage3
            courseType
            courseUrl
            overviewSourceMedia
            overviewFileName
            overviewFileType
            overviewFileSize
            transcoderMeta
            transcoderJobId
            courseTrend
            learnDescription
            requirements
            level
            tags
            trending
            updatedAt
            createdAt
            assets {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncCoursesQuery>response.data.syncCourses;
  }
  async GetCourse(id: string): Promise<GetCourseQuery> {
    const statement = `query GetCourse($id: ID!) {
        getCourse(id: $id) {
          __typename
          id
          title
          description
          category {
            __typename
            id
            category
            createdAt
            updatedAt
            status
            courses {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          categoryId
          coverImage
          timeAprox
          status
          coverImage1
          coverImage2
          coverImage3
          courseType
          courseUrl
          overviewSourceMedia
          overviewFileName
          overviewFileType
          overviewFileSize
          transcoderMeta
          transcoderJobId
          courseTrend
          learnDescription
          requirements
          level
          tags
          trending
          updatedAt
          createdAt
          assets {
            __typename
            items {
              __typename
              id
              courseId
              assetId
              assetType
              title
              description
              order
              timeAprox
              starred
              important
              showAsOverview
              showAsFree
              nextStep
              prevStep
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCourseQuery>response.data.getCourse;
  }
  async ListCourses(
    filter?: ModelCourseFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCoursesQuery> {
    const statement = `query ListCourses($filter: ModelCourseFilterInput, $limit: Int, $nextToken: String) {
        listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            title
            description
            category {
              __typename
              id
              category
              createdAt
              updatedAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            categoryId
            coverImage
            timeAprox
            status
            coverImage1
            coverImage2
            coverImage3
            courseType
            courseUrl
            overviewSourceMedia
            overviewFileName
            overviewFileType
            overviewFileSize
            transcoderMeta
            transcoderJobId
            courseTrend
            learnDescription
            requirements
            level
            tags
            trending
            updatedAt
            createdAt
            assets {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCoursesQuery>response.data.listCourses;
  }
  async SyncContentSubs(
    filter?: ModelContentSubsFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncContentSubsQuery> {
    const statement = `query SyncContentSubs($filter: ModelContentSubsFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncContentSubs(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            contentId
            userId
            metaData
            status
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncContentSubsQuery>response.data.syncContentSubs;
  }
  async GetContentSubs(id: string): Promise<GetContentSubsQuery> {
    const statement = `query GetContentSubs($id: ID!) {
        getContentSubs(id: $id) {
          __typename
          id
          contentId
          userId
          metaData
          status
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetContentSubsQuery>response.data.getContentSubs;
  }
  async ListContentSubss(
    filter?: ModelContentSubsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListContentSubssQuery> {
    const statement = `query ListContentSubss($filter: ModelContentSubsFilterInput, $limit: Int, $nextToken: String) {
        listContentSubss(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            contentId
            userId
            metaData
            status
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListContentSubssQuery>response.data.listContentSubss;
  }
  async SyncCourseAssets(
    filter?: ModelCourseAssetsFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncCourseAssetsQuery> {
    const statement = `query SyncCourseAssets($filter: ModelCourseAssetsFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncCourseAssets(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            courseId
            assetId
            assetType
            title
            description
            order
            timeAprox
            starred
            important
            showAsOverview
            showAsFree
            nextStep
            prevStep
            status
            updatedAt
            createdAt
            course {
              __typename
              id
              title
              description
              categoryId
              coverImage
              timeAprox
              status
              coverImage1
              coverImage2
              coverImage3
              courseType
              courseUrl
              overviewSourceMedia
              overviewFileName
              overviewFileType
              overviewFileSize
              transcoderMeta
              transcoderJobId
              courseTrend
              learnDescription
              requirements
              level
              tags
              trending
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            topic {
              __typename
              id
              courseId
              contentType
              media
              sourceMedia
              fileName
              fileType
              fileSize
              transcoderMeta
              transcoderJobId
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            quiz {
              __typename
              id
              courseId
              questionsRandom
              passingScore
              noAttempts
              score
              labelWhenPassed
              labelWhenFailed
              questionFeedback
              updatedAt
              createdAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            attachments {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncCourseAssetsQuery>response.data.syncCourseAssets;
  }
  async GetCourseAssets(id: string): Promise<GetCourseAssetsQuery> {
    const statement = `query GetCourseAssets($id: ID!) {
        getCourseAssets(id: $id) {
          __typename
          id
          courseId
          assetId
          assetType
          title
          description
          order
          timeAprox
          starred
          important
          showAsOverview
          showAsFree
          nextStep
          prevStep
          status
          updatedAt
          createdAt
          course {
            __typename
            id
            title
            description
            category {
              __typename
              id
              category
              createdAt
              updatedAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            categoryId
            coverImage
            timeAprox
            status
            coverImage1
            coverImage2
            coverImage3
            courseType
            courseUrl
            overviewSourceMedia
            overviewFileName
            overviewFileType
            overviewFileSize
            transcoderMeta
            transcoderJobId
            courseTrend
            learnDescription
            requirements
            level
            tags
            trending
            updatedAt
            createdAt
            assets {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          topic {
            __typename
            id
            courseId
            contentType
            media
            sourceMedia
            fileName
            fileType
            fileSize
            transcoderMeta
            transcoderJobId
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
          }
          quiz {
            __typename
            id
            courseId
            questionsRandom
            passingScore
            noAttempts
            score
            labelWhenPassed
            labelWhenFailed
            questionFeedback
            updatedAt
            createdAt
            status
            questions {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          attachments {
            __typename
            items {
              __typename
              id
              courseId
              courseAssetId
              description
              contentType
              sourceCode
              fileName
              fileType
              fileSize
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCourseAssetsQuery>response.data.getCourseAssets;
  }
  async ListCourseAssetss(
    filter?: ModelCourseAssetsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCourseAssetssQuery> {
    const statement = `query ListCourseAssetss($filter: ModelCourseAssetsFilterInput, $limit: Int, $nextToken: String) {
        listCourseAssetss(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            courseId
            assetId
            assetType
            title
            description
            order
            timeAprox
            starred
            important
            showAsOverview
            showAsFree
            nextStep
            prevStep
            status
            updatedAt
            createdAt
            course {
              __typename
              id
              title
              description
              categoryId
              coverImage
              timeAprox
              status
              coverImage1
              coverImage2
              coverImage3
              courseType
              courseUrl
              overviewSourceMedia
              overviewFileName
              overviewFileType
              overviewFileSize
              transcoderMeta
              transcoderJobId
              courseTrend
              learnDescription
              requirements
              level
              tags
              trending
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            topic {
              __typename
              id
              courseId
              contentType
              media
              sourceMedia
              fileName
              fileType
              fileSize
              transcoderMeta
              transcoderJobId
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            quiz {
              __typename
              id
              courseId
              questionsRandom
              passingScore
              noAttempts
              score
              labelWhenPassed
              labelWhenFailed
              questionFeedback
              updatedAt
              createdAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            attachments {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCourseAssetssQuery>response.data.listCourseAssetss;
  }
  async SyncQuizzes(
    filter?: ModelQuizFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncQuizzesQuery> {
    const statement = `query SyncQuizzes($filter: ModelQuizFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncQuizzes(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            courseId
            questionsRandom
            passingScore
            noAttempts
            score
            labelWhenPassed
            labelWhenFailed
            questionFeedback
            updatedAt
            createdAt
            status
            questions {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncQuizzesQuery>response.data.syncQuizzes;
  }
  async GetQuiz(id: string): Promise<GetQuizQuery> {
    const statement = `query GetQuiz($id: ID!) {
        getQuiz(id: $id) {
          __typename
          id
          courseId
          questionsRandom
          passingScore
          noAttempts
          score
          labelWhenPassed
          labelWhenFailed
          questionFeedback
          updatedAt
          createdAt
          status
          questions {
            __typename
            items {
              __typename
              id
              quizContentId
              order
              title
              qType
              labelCorrectMessage
              labelIncorrectMessage
              questionFeedBack
              media
              sourceMedia
              fileName
              fileType
              fileSize
              updatedAt
              createdAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetQuizQuery>response.data.getQuiz;
  }
  async ListQuizs(
    filter?: ModelQuizFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListQuizsQuery> {
    const statement = `query ListQuizs($filter: ModelQuizFilterInput, $limit: Int, $nextToken: String) {
        listQuizs(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            courseId
            questionsRandom
            passingScore
            noAttempts
            score
            labelWhenPassed
            labelWhenFailed
            questionFeedback
            updatedAt
            createdAt
            status
            questions {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListQuizsQuery>response.data.listQuizs;
  }
  async SyncQuizQuestions(
    filter?: ModelQuizQuestionFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncQuizQuestionsQuery> {
    const statement = `query SyncQuizQuestions($filter: ModelQuizQuestionFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncQuizQuestions(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            quizContentId
            quiz {
              __typename
              id
              courseId
              questionsRandom
              passingScore
              noAttempts
              score
              labelWhenPassed
              labelWhenFailed
              questionFeedback
              updatedAt
              createdAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            order
            title
            qType
            labelCorrectMessage
            labelIncorrectMessage
            questionFeedBack
            media
            sourceMedia
            fileName
            fileType
            fileSize
            updatedAt
            createdAt
            status
            questionAnswers {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncQuizQuestionsQuery>response.data.syncQuizQuestions;
  }
  async GetQuizQuestion(id: string): Promise<GetQuizQuestionQuery> {
    const statement = `query GetQuizQuestion($id: ID!) {
        getQuizQuestion(id: $id) {
          __typename
          id
          quizContentId
          quiz {
            __typename
            id
            courseId
            questionsRandom
            passingScore
            noAttempts
            score
            labelWhenPassed
            labelWhenFailed
            questionFeedback
            updatedAt
            createdAt
            status
            questions {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          order
          title
          qType
          labelCorrectMessage
          labelIncorrectMessage
          questionFeedBack
          media
          sourceMedia
          fileName
          fileType
          fileSize
          updatedAt
          createdAt
          status
          questionAnswers {
            __typename
            items {
              __typename
              id
              quizQuestionId
              order
              isCorrect
              title
              answer
              answerFeedBack
              qType
              media
              sourceMedia
              fileName
              fileType
              fileSize
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetQuizQuestionQuery>response.data.getQuizQuestion;
  }
  async ListQuizQuestions(
    filter?: ModelQuizQuestionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListQuizQuestionsQuery> {
    const statement = `query ListQuizQuestions($filter: ModelQuizQuestionFilterInput, $limit: Int, $nextToken: String) {
        listQuizQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            quizContentId
            quiz {
              __typename
              id
              courseId
              questionsRandom
              passingScore
              noAttempts
              score
              labelWhenPassed
              labelWhenFailed
              questionFeedback
              updatedAt
              createdAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            order
            title
            qType
            labelCorrectMessage
            labelIncorrectMessage
            questionFeedBack
            media
            sourceMedia
            fileName
            fileType
            fileSize
            updatedAt
            createdAt
            status
            questionAnswers {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListQuizQuestionsQuery>response.data.listQuizQuestions;
  }
  async SyncQuestionAnswers(
    filter?: ModelQuestionAnswersFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncQuestionAnswersQuery> {
    const statement = `query SyncQuestionAnswers($filter: ModelQuestionAnswersFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncQuestionAnswers(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            quizQuestionId
            question {
              __typename
              id
              quizContentId
              order
              title
              qType
              labelCorrectMessage
              labelIncorrectMessage
              questionFeedBack
              media
              sourceMedia
              fileName
              fileType
              fileSize
              updatedAt
              createdAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            order
            isCorrect
            title
            answer
            answerFeedBack
            qType
            media
            sourceMedia
            fileName
            fileType
            fileSize
            status
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncQuestionAnswersQuery>response.data.syncQuestionAnswers;
  }
  async GetQuestionAnswers(id: string): Promise<GetQuestionAnswersQuery> {
    const statement = `query GetQuestionAnswers($id: ID!) {
        getQuestionAnswers(id: $id) {
          __typename
          id
          quizQuestionId
          question {
            __typename
            id
            quizContentId
            quiz {
              __typename
              id
              courseId
              questionsRandom
              passingScore
              noAttempts
              score
              labelWhenPassed
              labelWhenFailed
              questionFeedback
              updatedAt
              createdAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            order
            title
            qType
            labelCorrectMessage
            labelIncorrectMessage
            questionFeedBack
            media
            sourceMedia
            fileName
            fileType
            fileSize
            updatedAt
            createdAt
            status
            questionAnswers {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          order
          isCorrect
          title
          answer
          answerFeedBack
          qType
          media
          sourceMedia
          fileName
          fileType
          fileSize
          status
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetQuestionAnswersQuery>response.data.getQuestionAnswers;
  }
  async ListQuestionAnswerss(
    filter?: ModelQuestionAnswersFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListQuestionAnswerssQuery> {
    const statement = `query ListQuestionAnswerss($filter: ModelQuestionAnswersFilterInput, $limit: Int, $nextToken: String) {
        listQuestionAnswerss(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            quizQuestionId
            question {
              __typename
              id
              quizContentId
              order
              title
              qType
              labelCorrectMessage
              labelIncorrectMessage
              questionFeedBack
              media
              sourceMedia
              fileName
              fileType
              fileSize
              updatedAt
              createdAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            order
            isCorrect
            title
            answer
            answerFeedBack
            qType
            media
            sourceMedia
            fileName
            fileType
            fileSize
            status
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListQuestionAnswerssQuery>response.data.listQuestionAnswerss;
  }
  async SyncCourseTags(
    filter?: ModelCourseTagsFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncCourseTagsQuery> {
    const statement = `query SyncCourseTags($filter: ModelCourseTagsFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncCourseTags(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            tag
            createdAt
            updatedAt
            status
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncCourseTagsQuery>response.data.syncCourseTags;
  }
  async GetCourseTags(id: string): Promise<GetCourseTagsQuery> {
    const statement = `query GetCourseTags($id: ID!) {
        getCourseTags(id: $id) {
          __typename
          id
          tag
          createdAt
          updatedAt
          status
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCourseTagsQuery>response.data.getCourseTags;
  }
  async ListCourseTagss(
    filter?: ModelCourseTagsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCourseTagssQuery> {
    const statement = `query ListCourseTagss($filter: ModelCourseTagsFilterInput, $limit: Int, $nextToken: String) {
        listCourseTagss(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            tag
            createdAt
            updatedAt
            status
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCourseTagssQuery>response.data.listCourseTagss;
  }
  async SyncCourseAssetsResources(
    filter?: ModelCourseAssetsResourceFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncCourseAssetsResourcesQuery> {
    const statement = `query SyncCourseAssetsResources($filter: ModelCourseAssetsResourceFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncCourseAssetsResources(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            courseId
            courseAssetId
            description
            contentType
            sourceCode
            fileName
            fileType
            fileSize
            status
            updatedAt
            createdAt
            courseAsset {
              __typename
              id
              courseId
              assetId
              assetType
              title
              description
              order
              timeAprox
              starred
              important
              showAsOverview
              showAsFree
              nextStep
              prevStep
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncCourseAssetsResourcesQuery>(
      response.data.syncCourseAssetsResources
    );
  }
  async GetCourseAssetsResource(
    id: string
  ): Promise<GetCourseAssetsResourceQuery> {
    const statement = `query GetCourseAssetsResource($id: ID!) {
        getCourseAssetsResource(id: $id) {
          __typename
          id
          courseId
          courseAssetId
          description
          contentType
          sourceCode
          fileName
          fileType
          fileSize
          status
          updatedAt
          createdAt
          courseAsset {
            __typename
            id
            courseId
            assetId
            assetType
            title
            description
            order
            timeAprox
            starred
            important
            showAsOverview
            showAsFree
            nextStep
            prevStep
            status
            updatedAt
            createdAt
            course {
              __typename
              id
              title
              description
              categoryId
              coverImage
              timeAprox
              status
              coverImage1
              coverImage2
              coverImage3
              courseType
              courseUrl
              overviewSourceMedia
              overviewFileName
              overviewFileType
              overviewFileSize
              transcoderMeta
              transcoderJobId
              courseTrend
              learnDescription
              requirements
              level
              tags
              trending
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            topic {
              __typename
              id
              courseId
              contentType
              media
              sourceMedia
              fileName
              fileType
              fileSize
              transcoderMeta
              transcoderJobId
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            quiz {
              __typename
              id
              courseId
              questionsRandom
              passingScore
              noAttempts
              score
              labelWhenPassed
              labelWhenFailed
              questionFeedback
              updatedAt
              createdAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            attachments {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCourseAssetsResourceQuery>response.data.getCourseAssetsResource;
  }
  async ListCourseAssetsResources(
    filter?: ModelCourseAssetsResourceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCourseAssetsResourcesQuery> {
    const statement = `query ListCourseAssetsResources($filter: ModelCourseAssetsResourceFilterInput, $limit: Int, $nextToken: String) {
        listCourseAssetsResources(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            courseId
            courseAssetId
            description
            contentType
            sourceCode
            fileName
            fileType
            fileSize
            status
            updatedAt
            createdAt
            courseAsset {
              __typename
              id
              courseId
              assetId
              assetType
              title
              description
              order
              timeAprox
              starred
              important
              showAsOverview
              showAsFree
              nextStep
              prevStep
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCourseAssetsResourcesQuery>(
      response.data.listCourseAssetsResources
    );
  }
  async SyncContacts(
    filter?: ModelContactFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncContactsQuery> {
    const statement = `query SyncContacts($filter: ModelContactFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncContacts(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            userID
            syncID
            contactType
            contactName
            firstName
            lastName
            email
            phone
            avatar
            avatarType
            jobTitle
            birthday
            starred
            frequent
            status
            pbProfileOptions
            userRelatedId
            notes
            businessId
            contactSince
            updatedAt
            createdAt
            contactRelId
            contactRel {
              __typename
              id
              userID
              syncID
              contactType
              contactName
              firstName
              lastName
              email
              phone
              avatar
              avatarType
              jobTitle
              birthday
              starred
              frequent
              status
              pbProfileOptions
              userRelatedId
              notes
              businessId
              contactSince
              updatedAt
              createdAt
              contactRelId
              _version
              _deleted
              _lastChangedAt
            }
            addresses {
              __typename
              nextToken
              startedAt
            }
            connects {
              __typename
              nextToken
              startedAt
            }
            licence {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncContactsQuery>response.data.syncContacts;
  }
  async GetContact(id: string): Promise<GetContactQuery> {
    const statement = `query GetContact($id: ID!) {
        getContact(id: $id) {
          __typename
          id
          userID
          syncID
          contactType
          contactName
          firstName
          lastName
          email
          phone
          avatar
          avatarType
          jobTitle
          birthday
          starred
          frequent
          status
          pbProfileOptions
          userRelatedId
          notes
          businessId
          contactSince
          updatedAt
          createdAt
          contactRelId
          contactRel {
            __typename
            id
            userID
            syncID
            contactType
            contactName
            firstName
            lastName
            email
            phone
            avatar
            avatarType
            jobTitle
            birthday
            starred
            frequent
            status
            pbProfileOptions
            userRelatedId
            notes
            businessId
            contactSince
            updatedAt
            createdAt
            contactRelId
            contactRel {
              __typename
              id
              userID
              syncID
              contactType
              contactName
              firstName
              lastName
              email
              phone
              avatar
              avatarType
              jobTitle
              birthday
              starred
              frequent
              status
              pbProfileOptions
              userRelatedId
              notes
              businessId
              contactSince
              updatedAt
              createdAt
              contactRelId
              _version
              _deleted
              _lastChangedAt
            }
            addresses {
              __typename
              nextToken
              startedAt
            }
            connects {
              __typename
              nextToken
              startedAt
            }
            licence {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          addresses {
            __typename
            items {
              __typename
              id
              contactId
              addressType
              street
              street1
              zipCode
              city
              state
              Country
              status
              updatedAt
              createdAt
              lat
              lng
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          connects {
            __typename
            items {
              __typename
              id
              contactId
              connsContactId
              contactType
              contactName
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          licence {
            __typename
            items {
              __typename
              id
              contactId
              noLicenses
              noClaimed
              groupId
              group
              prefix
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetContactQuery>response.data.getContact;
  }
  async ListContacts(
    filter?: ModelContactFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListContactsQuery> {
    const statement = `query ListContacts($filter: ModelContactFilterInput, $limit: Int, $nextToken: String) {
        listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userID
            syncID
            contactType
            contactName
            firstName
            lastName
            email
            phone
            avatar
            avatarType
            jobTitle
            birthday
            starred
            frequent
            status
            pbProfileOptions
            userRelatedId
            notes
            businessId
            contactSince
            updatedAt
            createdAt
            contactRelId
            contactRel {
              __typename
              id
              userID
              syncID
              contactType
              contactName
              firstName
              lastName
              email
              phone
              avatar
              avatarType
              jobTitle
              birthday
              starred
              frequent
              status
              pbProfileOptions
              userRelatedId
              notes
              businessId
              contactSince
              updatedAt
              createdAt
              contactRelId
              _version
              _deleted
              _lastChangedAt
            }
            addresses {
              __typename
              nextToken
              startedAt
            }
            connects {
              __typename
              nextToken
              startedAt
            }
            licence {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListContactsQuery>response.data.listContacts;
  }
  async SyncConnects(
    filter?: ModelConnectsFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncConnectsQuery> {
    const statement = `query SyncConnects($filter: ModelConnectsFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncConnects(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            contactId
            connsContactId
            contactType
            contactName
            status
            updatedAt
            createdAt
            contact {
              __typename
              id
              userID
              syncID
              contactType
              contactName
              firstName
              lastName
              email
              phone
              avatar
              avatarType
              jobTitle
              birthday
              starred
              frequent
              status
              pbProfileOptions
              userRelatedId
              notes
              businessId
              contactSince
              updatedAt
              createdAt
              contactRelId
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncConnectsQuery>response.data.syncConnects;
  }
  async GetConnects(id: string): Promise<GetConnectsQuery> {
    const statement = `query GetConnects($id: ID!) {
        getConnects(id: $id) {
          __typename
          id
          contactId
          connsContactId
          contactType
          contactName
          status
          updatedAt
          createdAt
          contact {
            __typename
            id
            userID
            syncID
            contactType
            contactName
            firstName
            lastName
            email
            phone
            avatar
            avatarType
            jobTitle
            birthday
            starred
            frequent
            status
            pbProfileOptions
            userRelatedId
            notes
            businessId
            contactSince
            updatedAt
            createdAt
            contactRelId
            contactRel {
              __typename
              id
              userID
              syncID
              contactType
              contactName
              firstName
              lastName
              email
              phone
              avatar
              avatarType
              jobTitle
              birthday
              starred
              frequent
              status
              pbProfileOptions
              userRelatedId
              notes
              businessId
              contactSince
              updatedAt
              createdAt
              contactRelId
              _version
              _deleted
              _lastChangedAt
            }
            addresses {
              __typename
              nextToken
              startedAt
            }
            connects {
              __typename
              nextToken
              startedAt
            }
            licence {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetConnectsQuery>response.data.getConnects;
  }
  async ListConnectss(
    filter?: ModelConnectsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListConnectssQuery> {
    const statement = `query ListConnectss($filter: ModelConnectsFilterInput, $limit: Int, $nextToken: String) {
        listConnectss(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            contactId
            connsContactId
            contactType
            contactName
            status
            updatedAt
            createdAt
            contact {
              __typename
              id
              userID
              syncID
              contactType
              contactName
              firstName
              lastName
              email
              phone
              avatar
              avatarType
              jobTitle
              birthday
              starred
              frequent
              status
              pbProfileOptions
              userRelatedId
              notes
              businessId
              contactSince
              updatedAt
              createdAt
              contactRelId
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListConnectssQuery>response.data.listConnectss;
  }
  async SyncContactAddresses(
    filter?: ModelContactAddressFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncContactAddressesQuery> {
    const statement = `query SyncContactAddresses($filter: ModelContactAddressFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncContactAddresses(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            contactId
            addressType
            street
            street1
            zipCode
            city
            state
            Country
            status
            updatedAt
            createdAt
            lat
            lng
            contact {
              __typename
              id
              userID
              syncID
              contactType
              contactName
              firstName
              lastName
              email
              phone
              avatar
              avatarType
              jobTitle
              birthday
              starred
              frequent
              status
              pbProfileOptions
              userRelatedId
              notes
              businessId
              contactSince
              updatedAt
              createdAt
              contactRelId
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncContactAddressesQuery>response.data.syncContactAddresses;
  }
  async GetContactAddress(id: string): Promise<GetContactAddressQuery> {
    const statement = `query GetContactAddress($id: ID!) {
        getContactAddress(id: $id) {
          __typename
          id
          contactId
          addressType
          street
          street1
          zipCode
          city
          state
          Country
          status
          updatedAt
          createdAt
          lat
          lng
          contact {
            __typename
            id
            userID
            syncID
            contactType
            contactName
            firstName
            lastName
            email
            phone
            avatar
            avatarType
            jobTitle
            birthday
            starred
            frequent
            status
            pbProfileOptions
            userRelatedId
            notes
            businessId
            contactSince
            updatedAt
            createdAt
            contactRelId
            contactRel {
              __typename
              id
              userID
              syncID
              contactType
              contactName
              firstName
              lastName
              email
              phone
              avatar
              avatarType
              jobTitle
              birthday
              starred
              frequent
              status
              pbProfileOptions
              userRelatedId
              notes
              businessId
              contactSince
              updatedAt
              createdAt
              contactRelId
              _version
              _deleted
              _lastChangedAt
            }
            addresses {
              __typename
              nextToken
              startedAt
            }
            connects {
              __typename
              nextToken
              startedAt
            }
            licence {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetContactAddressQuery>response.data.getContactAddress;
  }
  async ListContactAddresss(
    filter?: ModelContactAddressFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListContactAddresssQuery> {
    const statement = `query ListContactAddresss($filter: ModelContactAddressFilterInput, $limit: Int, $nextToken: String) {
        listContactAddresss(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            contactId
            addressType
            street
            street1
            zipCode
            city
            state
            Country
            status
            updatedAt
            createdAt
            lat
            lng
            contact {
              __typename
              id
              userID
              syncID
              contactType
              contactName
              firstName
              lastName
              email
              phone
              avatar
              avatarType
              jobTitle
              birthday
              starred
              frequent
              status
              pbProfileOptions
              userRelatedId
              notes
              businessId
              contactSince
              updatedAt
              createdAt
              contactRelId
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListContactAddresssQuery>response.data.listContactAddresss;
  }
  async SyncMemberships(
    filter?: ModelMembershipFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncMembershipsQuery> {
    const statement = `query SyncMemberships($filter: ModelMembershipFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncMemberships(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            contactId
            noLicenses
            noClaimed
            groupId
            group
            prefix
            status
            updatedAt
            createdAt
            org {
              __typename
              id
              userID
              syncID
              contactType
              contactName
              firstName
              lastName
              email
              phone
              avatar
              avatarType
              jobTitle
              birthday
              starred
              frequent
              status
              pbProfileOptions
              userRelatedId
              notes
              businessId
              contactSince
              updatedAt
              createdAt
              contactRelId
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncMembershipsQuery>response.data.syncMemberships;
  }
  async GetMembership(id: string): Promise<GetMembershipQuery> {
    const statement = `query GetMembership($id: ID!) {
        getMembership(id: $id) {
          __typename
          id
          contactId
          noLicenses
          noClaimed
          groupId
          group
          prefix
          status
          updatedAt
          createdAt
          org {
            __typename
            id
            userID
            syncID
            contactType
            contactName
            firstName
            lastName
            email
            phone
            avatar
            avatarType
            jobTitle
            birthday
            starred
            frequent
            status
            pbProfileOptions
            userRelatedId
            notes
            businessId
            contactSince
            updatedAt
            createdAt
            contactRelId
            contactRel {
              __typename
              id
              userID
              syncID
              contactType
              contactName
              firstName
              lastName
              email
              phone
              avatar
              avatarType
              jobTitle
              birthday
              starred
              frequent
              status
              pbProfileOptions
              userRelatedId
              notes
              businessId
              contactSince
              updatedAt
              createdAt
              contactRelId
              _version
              _deleted
              _lastChangedAt
            }
            addresses {
              __typename
              nextToken
              startedAt
            }
            connects {
              __typename
              nextToken
              startedAt
            }
            licence {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetMembershipQuery>response.data.getMembership;
  }
  async ListMemberships(
    filter?: ModelMembershipFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListMembershipsQuery> {
    const statement = `query ListMemberships($filter: ModelMembershipFilterInput, $limit: Int, $nextToken: String) {
        listMemberships(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            contactId
            noLicenses
            noClaimed
            groupId
            group
            prefix
            status
            updatedAt
            createdAt
            org {
              __typename
              id
              userID
              syncID
              contactType
              contactName
              firstName
              lastName
              email
              phone
              avatar
              avatarType
              jobTitle
              birthday
              starred
              frequent
              status
              pbProfileOptions
              userRelatedId
              notes
              businessId
              contactSince
              updatedAt
              createdAt
              contactRelId
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMembershipsQuery>response.data.listMemberships;
  }
  async SettingsByUserId(
    userId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelUserSettingsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SettingsByUserIdQuery> {
    const statement = `query SettingsByUserId($userId: ID, $sortDirection: ModelSortDirection, $filter: ModelUserSettingsFilterInput, $limit: Int, $nextToken: String) {
        settingsByUserId(userId: $userId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userId
            pageSize
            notiType
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (userId) {
      gqlAPIServiceArguments.userId = userId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SettingsByUserIdQuery>response.data.settingsByUserId;
  }
  async ListNotifsByStatus(
    typeNotify?: string,
    updatedAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelNotificationFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListNotifsByStatusQuery> {
    const statement = `query ListNotifsByStatus($typeNotify: String, $updatedAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelNotificationFilterInput, $limit: Int, $nextToken: String) {
        listNotifsByStatus(typeNotify: $typeNotify, updatedAt: $updatedAt, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            typeNotify
            userId
            subject
            body
            localUrl
            renotify
            silent
            dateFor
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (typeNotify) {
      gqlAPIServiceArguments.typeNotify = typeNotify;
    }
    if (updatedAt) {
      gqlAPIServiceArguments.updatedAt = updatedAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListNotifsByStatusQuery>response.data.listNotifsByStatus;
  }
  async ListTodayNotifsByStatus(
    typeNotify?: string,
    dateFor?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelNotificationFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTodayNotifsByStatusQuery> {
    const statement = `query ListTodayNotifsByStatus($typeNotify: String, $dateFor: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelNotificationFilterInput, $limit: Int, $nextToken: String) {
        listTodayNotifsByStatus(typeNotify: $typeNotify, dateFor: $dateFor, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            typeNotify
            userId
            subject
            body
            localUrl
            renotify
            silent
            dateFor
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (typeNotify) {
      gqlAPIServiceArguments.typeNotify = typeNotify;
    }
    if (dateFor) {
      gqlAPIServiceArguments.dateFor = dateFor;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTodayNotifsByStatusQuery>response.data.listTodayNotifsByStatus;
  }
  async NotiByUserId(
    userId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelNotificationFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<NotiByUserIdQuery> {
    const statement = `query NotiByUserId($userId: String, $sortDirection: ModelSortDirection, $filter: ModelNotificationFilterInput, $limit: Int, $nextToken: String) {
        notiByUserId(userId: $userId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            typeNotify
            userId
            subject
            body
            localUrl
            renotify
            silent
            dateFor
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (userId) {
      gqlAPIServiceArguments.userId = userId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <NotiByUserIdQuery>response.data.notiByUserId;
  }
  async PrivacyByUserId(
    userId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelUserPrivacyFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<PrivacyByUserIdQuery> {
    const statement = `query PrivacyByUserId($userId: ID, $sortDirection: ModelSortDirection, $filter: ModelUserPrivacyFilterInput, $limit: Int, $nextToken: String) {
        PrivacyByUserId(userId: $userId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userId
            profileShared
            bgPhoto
            headLine
            secondHeadLine
            titleLine
            bioLine
            showEmail
            showPhone
            showEmailOn
            contactDirectlyByEmail
            showConnectionsTo
            oaConnInvitationAccepted
            oaEventUpdates
            oaUpdatesFromConnections
            oaBreakingNews
            oaTopsPicks
            oaWhoViewedYourProfile
            oaYourNewFollowers
            emailEventsInvitations
            emailEventsUpdates
            emailNewsInvitations
            emailNewsSubscriptions
            whoRequestForNotifications
            whoInvitationsToAttend
            locMap
            location
            locTitle
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (userId) {
      gqlAPIServiceArguments.userId = userId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <PrivacyByUserIdQuery>response.data.PrivacyByUserId;
  }
  async PrivacyByUserSharedId(
    userId?: string,
    profileShared?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelUserPrivacyFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<PrivacyByUserSharedIdQuery> {
    const statement = `query PrivacyByUserSharedId($userId: ID, $profileShared: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelUserPrivacyFilterInput, $limit: Int, $nextToken: String) {
        PrivacyByUserSharedId(userId: $userId, profileShared: $profileShared, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userId
            profileShared
            bgPhoto
            headLine
            secondHeadLine
            titleLine
            bioLine
            showEmail
            showPhone
            showEmailOn
            contactDirectlyByEmail
            showConnectionsTo
            oaConnInvitationAccepted
            oaEventUpdates
            oaUpdatesFromConnections
            oaBreakingNews
            oaTopsPicks
            oaWhoViewedYourProfile
            oaYourNewFollowers
            emailEventsInvitations
            emailEventsUpdates
            emailNewsInvitations
            emailNewsSubscriptions
            whoRequestForNotifications
            whoInvitationsToAttend
            locMap
            location
            locTitle
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (userId) {
      gqlAPIServiceArguments.userId = userId;
    }
    if (profileShared) {
      gqlAPIServiceArguments.profileShared = profileShared;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <PrivacyByUserSharedIdQuery>response.data.PrivacyByUserSharedId;
  }
  async LIstCategoriesByStatus(
    status?: elStatus,
    updatedAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCourseCategoryFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<LIstCategoriesByStatusQuery> {
    const statement = `query LIstCategoriesByStatus($status: elStatus, $updatedAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCourseCategoryFilterInput, $limit: Int, $nextToken: String) {
        LIstCategoriesByStatus(status: $status, updatedAt: $updatedAt, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            category
            createdAt
            updatedAt
            status
            courses {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    if (updatedAt) {
      gqlAPIServiceArguments.updatedAt = updatedAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <LIstCategoriesByStatusQuery>response.data.LIstCategoriesByStatus;
  }
  async ListResourcesByStatus(
    status?: elStatus,
    updatedAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCourseResourceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListResourcesByStatusQuery> {
    const statement = `query ListResourcesByStatus($status: elStatus, $updatedAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCourseResourceFilterInput, $limit: Int, $nextToken: String) {
        ListResourcesByStatus(status: $status, updatedAt: $updatedAt, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            courseId
            description
            contentType
            sourceCode
            fileName
            fileType
            fileSize
            status
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    if (updatedAt) {
      gqlAPIServiceArguments.updatedAt = updatedAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListResourcesByStatusQuery>response.data.ListResourcesByStatus;
  }
  async ListResourcesByCourseId(
    courseId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCourseResourceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListResourcesByCourseIdQuery> {
    const statement = `query ListResourcesByCourseId($courseId: String, $sortDirection: ModelSortDirection, $filter: ModelCourseResourceFilterInput, $limit: Int, $nextToken: String) {
        ListResourcesByCourseId(courseId: $courseId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            courseId
            description
            contentType
            sourceCode
            fileName
            fileType
            fileSize
            status
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (courseId) {
      gqlAPIServiceArguments.courseId = courseId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListResourcesByCourseIdQuery>response.data.ListResourcesByCourseId;
  }
  async ListContentResourcesByStatus(
    status?: elStatus,
    updatedAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelContentResourceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListContentResourcesByStatusQuery> {
    const statement = `query ListContentResourcesByStatus($status: elStatus, $updatedAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelContentResourceFilterInput, $limit: Int, $nextToken: String) {
        ListContentResourcesByStatus(status: $status, updatedAt: $updatedAt, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            courseId
            description
            contentType
            sourceCode
            fileName
            fileType
            fileSize
            status
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    if (updatedAt) {
      gqlAPIServiceArguments.updatedAt = updatedAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListContentResourcesByStatusQuery>(
      response.data.ListContentResourcesByStatus
    );
  }
  async ListContentResourcesByCourseId(
    courseId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelContentResourceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListContentResourcesByCourseIdQuery> {
    const statement = `query ListContentResourcesByCourseId($courseId: String, $sortDirection: ModelSortDirection, $filter: ModelContentResourceFilterInput, $limit: Int, $nextToken: String) {
        ListContentResourcesByCourseId(courseId: $courseId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            courseId
            description
            contentType
            sourceCode
            fileName
            fileType
            fileSize
            status
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (courseId) {
      gqlAPIServiceArguments.courseId = courseId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListContentResourcesByCourseIdQuery>(
      response.data.ListContentResourcesByCourseId
    );
  }
  async ListCoursesByStatus(
    status?: elStatus,
    updatedAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCourseFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCoursesByStatusQuery> {
    const statement = `query ListCoursesByStatus($status: elStatus, $updatedAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCourseFilterInput, $limit: Int, $nextToken: String) {
        ListCoursesByStatus(status: $status, updatedAt: $updatedAt, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            title
            description
            category {
              __typename
              id
              category
              createdAt
              updatedAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            categoryId
            coverImage
            timeAprox
            status
            coverImage1
            coverImage2
            coverImage3
            courseType
            courseUrl
            overviewSourceMedia
            overviewFileName
            overviewFileType
            overviewFileSize
            transcoderMeta
            transcoderJobId
            courseTrend
            learnDescription
            requirements
            level
            tags
            trending
            updatedAt
            createdAt
            assets {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    if (updatedAt) {
      gqlAPIServiceArguments.updatedAt = updatedAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCoursesByStatusQuery>response.data.ListCoursesByStatus;
  }
  async ListCoursesByCategory(
    categoryId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCourseFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCoursesByCategoryQuery> {
    const statement = `query ListCoursesByCategory($categoryId: ID, $sortDirection: ModelSortDirection, $filter: ModelCourseFilterInput, $limit: Int, $nextToken: String) {
        ListCoursesByCategory(categoryId: $categoryId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            title
            description
            category {
              __typename
              id
              category
              createdAt
              updatedAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            categoryId
            coverImage
            timeAprox
            status
            coverImage1
            coverImage2
            coverImage3
            courseType
            courseUrl
            overviewSourceMedia
            overviewFileName
            overviewFileType
            overviewFileSize
            transcoderMeta
            transcoderJobId
            courseTrend
            learnDescription
            requirements
            level
            tags
            trending
            updatedAt
            createdAt
            assets {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (categoryId) {
      gqlAPIServiceArguments.categoryId = categoryId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCoursesByCategoryQuery>response.data.ListCoursesByCategory;
  }
  async SubsContentsByIds(
    userId?: string,
    contentId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelContentSubsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SubsContentsByIdsQuery> {
    const statement = `query SubsContentsByIds($userId: ID, $contentId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelContentSubsFilterInput, $limit: Int, $nextToken: String) {
        SubsContentsByIds(userId: $userId, contentId: $contentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            contentId
            userId
            metaData
            status
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (userId) {
      gqlAPIServiceArguments.userId = userId;
    }
    if (contentId) {
      gqlAPIServiceArguments.contentId = contentId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SubsContentsByIdsQuery>response.data.SubsContentsByIds;
  }
  async SubsContentByStatus(
    status?: elSubStatus,
    updatedAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelContentSubsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SubsContentByStatusQuery> {
    const statement = `query SubsContentByStatus($status: elSubStatus, $updatedAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelContentSubsFilterInput, $limit: Int, $nextToken: String) {
        SubsContentByStatus(status: $status, updatedAt: $updatedAt, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            contentId
            userId
            metaData
            status
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    if (updatedAt) {
      gqlAPIServiceArguments.updatedAt = updatedAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SubsContentByStatusQuery>response.data.SubsContentByStatus;
  }
  async AssetsByCourseAndAssetId(
    courseId?: string,
    assetIdAssetType?: ModelCourseAssetsAssetsByCourseIdCompositeKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCourseAssetsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AssetsByCourseAndAssetIdQuery> {
    const statement = `query AssetsByCourseAndAssetId($courseId: ID, $assetIdAssetType: ModelCourseAssetsAssetsByCourseIdCompositeKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCourseAssetsFilterInput, $limit: Int, $nextToken: String) {
        AssetsByCourseAndAssetId(courseId: $courseId, assetIdAssetType: $assetIdAssetType, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            courseId
            assetId
            assetType
            title
            description
            order
            timeAprox
            starred
            important
            showAsOverview
            showAsFree
            nextStep
            prevStep
            status
            updatedAt
            createdAt
            course {
              __typename
              id
              title
              description
              categoryId
              coverImage
              timeAprox
              status
              coverImage1
              coverImage2
              coverImage3
              courseType
              courseUrl
              overviewSourceMedia
              overviewFileName
              overviewFileType
              overviewFileSize
              transcoderMeta
              transcoderJobId
              courseTrend
              learnDescription
              requirements
              level
              tags
              trending
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            topic {
              __typename
              id
              courseId
              contentType
              media
              sourceMedia
              fileName
              fileType
              fileSize
              transcoderMeta
              transcoderJobId
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            quiz {
              __typename
              id
              courseId
              questionsRandom
              passingScore
              noAttempts
              score
              labelWhenPassed
              labelWhenFailed
              questionFeedback
              updatedAt
              createdAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            attachments {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (courseId) {
      gqlAPIServiceArguments.courseId = courseId;
    }
    if (assetIdAssetType) {
      gqlAPIServiceArguments.assetIdAssetType = assetIdAssetType;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AssetsByCourseAndAssetIdQuery>(
      response.data.AssetsByCourseAndAssetId
    );
  }
  async AssetsByCourseAndType(
    courseId?: string,
    assetType?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCourseAssetsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AssetsByCourseAndTypeQuery> {
    const statement = `query AssetsByCourseAndType($courseId: ID, $assetType: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCourseAssetsFilterInput, $limit: Int, $nextToken: String) {
        AssetsByCourseAndType(courseId: $courseId, assetType: $assetType, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            courseId
            assetId
            assetType
            title
            description
            order
            timeAprox
            starred
            important
            showAsOverview
            showAsFree
            nextStep
            prevStep
            status
            updatedAt
            createdAt
            course {
              __typename
              id
              title
              description
              categoryId
              coverImage
              timeAprox
              status
              coverImage1
              coverImage2
              coverImage3
              courseType
              courseUrl
              overviewSourceMedia
              overviewFileName
              overviewFileType
              overviewFileSize
              transcoderMeta
              transcoderJobId
              courseTrend
              learnDescription
              requirements
              level
              tags
              trending
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            topic {
              __typename
              id
              courseId
              contentType
              media
              sourceMedia
              fileName
              fileType
              fileSize
              transcoderMeta
              transcoderJobId
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            quiz {
              __typename
              id
              courseId
              questionsRandom
              passingScore
              noAttempts
              score
              labelWhenPassed
              labelWhenFailed
              questionFeedback
              updatedAt
              createdAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            attachments {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (courseId) {
      gqlAPIServiceArguments.courseId = courseId;
    }
    if (assetType) {
      gqlAPIServiceArguments.assetType = assetType;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AssetsByCourseAndTypeQuery>response.data.AssetsByCourseAndType;
  }
  async ListCourseAssetsByOrder(
    courseId?: string,
    order?: ModelIntKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCourseAssetsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCourseAssetsByOrderQuery> {
    const statement = `query ListCourseAssetsByOrder($courseId: ID, $order: ModelIntKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCourseAssetsFilterInput, $limit: Int, $nextToken: String) {
        ListCourseAssetsByOrder(courseId: $courseId, order: $order, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            courseId
            assetId
            assetType
            title
            description
            order
            timeAprox
            starred
            important
            showAsOverview
            showAsFree
            nextStep
            prevStep
            status
            updatedAt
            createdAt
            course {
              __typename
              id
              title
              description
              categoryId
              coverImage
              timeAprox
              status
              coverImage1
              coverImage2
              coverImage3
              courseType
              courseUrl
              overviewSourceMedia
              overviewFileName
              overviewFileType
              overviewFileSize
              transcoderMeta
              transcoderJobId
              courseTrend
              learnDescription
              requirements
              level
              tags
              trending
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            topic {
              __typename
              id
              courseId
              contentType
              media
              sourceMedia
              fileName
              fileType
              fileSize
              transcoderMeta
              transcoderJobId
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            quiz {
              __typename
              id
              courseId
              questionsRandom
              passingScore
              noAttempts
              score
              labelWhenPassed
              labelWhenFailed
              questionFeedback
              updatedAt
              createdAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            attachments {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (courseId) {
      gqlAPIServiceArguments.courseId = courseId;
    }
    if (order) {
      gqlAPIServiceArguments.order = order;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCourseAssetsByOrderQuery>response.data.ListCourseAssetsByOrder;
  }
  async ListCourseTagsByStatus(
    status?: elStatus,
    updatedAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCourseTagsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCourseTagsByStatusQuery> {
    const statement = `query ListCourseTagsByStatus($status: elStatus, $updatedAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCourseTagsFilterInput, $limit: Int, $nextToken: String) {
        ListCourseTagsByStatus(status: $status, updatedAt: $updatedAt, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            tag
            createdAt
            updatedAt
            status
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    if (updatedAt) {
      gqlAPIServiceArguments.updatedAt = updatedAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCourseTagsByStatusQuery>response.data.ListCourseTagsByStatus;
  }
  async ListCourseAssetsResourceByStatus(
    status?: elStatus,
    updatedAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCourseAssetsResourceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCourseAssetsResourceByStatusQuery> {
    const statement = `query ListCourseAssetsResourceByStatus($status: elStatus, $updatedAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCourseAssetsResourceFilterInput, $limit: Int, $nextToken: String) {
        ListCourseAssetsResourceByStatus(status: $status, updatedAt: $updatedAt, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            courseId
            courseAssetId
            description
            contentType
            sourceCode
            fileName
            fileType
            fileSize
            status
            updatedAt
            createdAt
            courseAsset {
              __typename
              id
              courseId
              assetId
              assetType
              title
              description
              order
              timeAprox
              starred
              important
              showAsOverview
              showAsFree
              nextStep
              prevStep
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    if (updatedAt) {
      gqlAPIServiceArguments.updatedAt = updatedAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCourseAssetsResourceByStatusQuery>(
      response.data.ListCourseAssetsResourceByStatus
    );
  }
  async ListAssetResourcesByIds(
    courseId?: string,
    courseAssetId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCourseAssetsResourceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAssetResourcesByIdsQuery> {
    const statement = `query ListAssetResourcesByIds($courseId: ID, $courseAssetId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCourseAssetsResourceFilterInput, $limit: Int, $nextToken: String) {
        ListAssetResourcesByIds(courseId: $courseId, courseAssetId: $courseAssetId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            courseId
            courseAssetId
            description
            contentType
            sourceCode
            fileName
            fileType
            fileSize
            status
            updatedAt
            createdAt
            courseAsset {
              __typename
              id
              courseId
              assetId
              assetType
              title
              description
              order
              timeAprox
              starred
              important
              showAsOverview
              showAsFree
              nextStep
              prevStep
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (courseId) {
      gqlAPIServiceArguments.courseId = courseId;
    }
    if (courseAssetId) {
      gqlAPIServiceArguments.courseAssetId = courseAssetId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAssetResourcesByIdsQuery>response.data.ListAssetResourcesByIds;
  }
  async ListCourseSubss(
    filter?: ModelCourseSubsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCourseSubssQuery> {
    const statement = `query ListCourseSubss($filter: ModelCourseSubsFilterInput, $limit: Int, $nextToken: String) {
        listCourseSubss(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            courseId
            userId
            title
            description
            category
            level
            tags
            metaData
            status
            course {
              __typename
              id
              title
              description
              categoryId
              coverImage
              timeAprox
              status
              coverImage1
              coverImage2
              coverImage3
              courseType
              courseUrl
              overviewSourceMedia
              overviewFileName
              overviewFileType
              overviewFileSize
              transcoderMeta
              transcoderJobId
              courseTrend
              learnDescription
              requirements
              level
              tags
              trending
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            owner
            assetsSubs {
              __typename
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCourseSubssQuery>response.data.listCourseSubss;
  }
  async GetCourseSubs(id: string): Promise<GetCourseSubsQuery> {
    const statement = `query GetCourseSubs($id: ID!) {
        getCourseSubs(id: $id) {
          __typename
          id
          courseId
          userId
          title
          description
          category
          level
          tags
          metaData
          status
          course {
            __typename
            id
            title
            description
            category {
              __typename
              id
              category
              createdAt
              updatedAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            categoryId
            coverImage
            timeAprox
            status
            coverImage1
            coverImage2
            coverImage3
            courseType
            courseUrl
            overviewSourceMedia
            overviewFileName
            overviewFileType
            overviewFileSize
            transcoderMeta
            transcoderJobId
            courseTrend
            learnDescription
            requirements
            level
            tags
            trending
            updatedAt
            createdAt
            assets {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          owner
          assetsSubs {
            __typename
            items {
              __typename
              id
              courseSubsId
              assetId
              userId
              assetType
              title
              description
              category
              metaData
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              owner
            }
            nextToken
            startedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCourseSubsQuery>response.data.getCourseSubs;
  }
  async SubsCourseByIds(
    userId?: string,
    courseId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCourseSubsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SubsCourseByIdsQuery> {
    const statement = `query SubsCourseByIds($userId: ID, $courseId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCourseSubsFilterInput, $limit: Int, $nextToken: String) {
        SubsCourseByIds(userId: $userId, courseId: $courseId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            courseId
            userId
            title
            description
            category
            level
            tags
            metaData
            status
            course {
              __typename
              id
              title
              description
              categoryId
              coverImage
              timeAprox
              status
              coverImage1
              coverImage2
              coverImage3
              courseType
              courseUrl
              overviewSourceMedia
              overviewFileName
              overviewFileType
              overviewFileSize
              transcoderMeta
              transcoderJobId
              courseTrend
              learnDescription
              requirements
              level
              tags
              trending
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            owner
            assetsSubs {
              __typename
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (userId) {
      gqlAPIServiceArguments.userId = userId;
    }
    if (courseId) {
      gqlAPIServiceArguments.courseId = courseId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SubsCourseByIdsQuery>response.data.SubsCourseByIds;
  }
  async SubsByUser(
    userId?: string,
    updatedAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCourseSubsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SubsByUserQuery> {
    const statement = `query SubsByUser($userId: ID, $updatedAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCourseSubsFilterInput, $limit: Int, $nextToken: String) {
        SubsByUser(userId: $userId, updatedAt: $updatedAt, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            courseId
            userId
            title
            description
            category
            level
            tags
            metaData
            status
            course {
              __typename
              id
              title
              description
              categoryId
              coverImage
              timeAprox
              status
              coverImage1
              coverImage2
              coverImage3
              courseType
              courseUrl
              overviewSourceMedia
              overviewFileName
              overviewFileType
              overviewFileSize
              transcoderMeta
              transcoderJobId
              courseTrend
              learnDescription
              requirements
              level
              tags
              trending
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            owner
            assetsSubs {
              __typename
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (userId) {
      gqlAPIServiceArguments.userId = userId;
    }
    if (updatedAt) {
      gqlAPIServiceArguments.updatedAt = updatedAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SubsByUserQuery>response.data.SubsByUser;
  }
  async SubsCourseByStatus(
    status?: elSubStatus,
    updatedAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCourseSubsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SubsCourseByStatusQuery> {
    const statement = `query SubsCourseByStatus($status: elSubStatus, $updatedAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCourseSubsFilterInput, $limit: Int, $nextToken: String) {
        SubsCourseByStatus(status: $status, updatedAt: $updatedAt, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            courseId
            userId
            title
            description
            category
            level
            tags
            metaData
            status
            course {
              __typename
              id
              title
              description
              categoryId
              coverImage
              timeAprox
              status
              coverImage1
              coverImage2
              coverImage3
              courseType
              courseUrl
              overviewSourceMedia
              overviewFileName
              overviewFileType
              overviewFileSize
              transcoderMeta
              transcoderJobId
              courseTrend
              learnDescription
              requirements
              level
              tags
              trending
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            owner
            assetsSubs {
              __typename
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    if (updatedAt) {
      gqlAPIServiceArguments.updatedAt = updatedAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SubsCourseByStatusQuery>response.data.SubsCourseByStatus;
  }
  async SyncCourseSubs(
    filter?: ModelCourseSubsFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncCourseSubsQuery> {
    const statement = `query SyncCourseSubs($filter: ModelCourseSubsFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncCourseSubs(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            courseId
            userId
            title
            description
            category
            level
            tags
            metaData
            status
            course {
              __typename
              id
              title
              description
              categoryId
              coverImage
              timeAprox
              status
              coverImage1
              coverImage2
              coverImage3
              courseType
              courseUrl
              overviewSourceMedia
              overviewFileName
              overviewFileType
              overviewFileSize
              transcoderMeta
              transcoderJobId
              courseTrend
              learnDescription
              requirements
              level
              tags
              trending
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            owner
            assetsSubs {
              __typename
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncCourseSubsQuery>response.data.syncCourseSubs;
  }
  async GetCourseAssetSubs(id: string): Promise<GetCourseAssetSubsQuery> {
    const statement = `query GetCourseAssetSubs($id: ID!) {
        getCourseAssetSubs(id: $id) {
          __typename
          id
          courseSubsId
          assetId
          userId
          assetType
          title
          description
          category
          metaData
          status
          asset {
            __typename
            id
            courseId
            assetId
            assetType
            title
            description
            order
            timeAprox
            starred
            important
            showAsOverview
            showAsFree
            nextStep
            prevStep
            status
            updatedAt
            createdAt
            course {
              __typename
              id
              title
              description
              categoryId
              coverImage
              timeAprox
              status
              coverImage1
              coverImage2
              coverImage3
              courseType
              courseUrl
              overviewSourceMedia
              overviewFileName
              overviewFileType
              overviewFileSize
              transcoderMeta
              transcoderJobId
              courseTrend
              learnDescription
              requirements
              level
              tags
              trending
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            topic {
              __typename
              id
              courseId
              contentType
              media
              sourceMedia
              fileName
              fileType
              fileSize
              transcoderMeta
              transcoderJobId
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            quiz {
              __typename
              id
              courseId
              questionsRandom
              passingScore
              noAttempts
              score
              labelWhenPassed
              labelWhenFailed
              questionFeedback
              updatedAt
              createdAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            attachments {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          assetsSubs {
            __typename
            id
            courseId
            userId
            title
            description
            category
            level
            tags
            metaData
            status
            course {
              __typename
              id
              title
              description
              categoryId
              coverImage
              timeAprox
              status
              coverImage1
              coverImage2
              coverImage3
              courseType
              courseUrl
              overviewSourceMedia
              overviewFileName
              overviewFileType
              overviewFileSize
              transcoderMeta
              transcoderJobId
              courseTrend
              learnDescription
              requirements
              level
              tags
              trending
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            owner
            assetsSubs {
              __typename
              nextToken
              startedAt
            }
          }
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCourseAssetSubsQuery>response.data.getCourseAssetSubs;
  }
  async ListCourseAssetSubss(
    filter?: ModelCourseAssetSubsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCourseAssetSubssQuery> {
    const statement = `query ListCourseAssetSubss($filter: ModelCourseAssetSubsFilterInput, $limit: Int, $nextToken: String) {
        listCourseAssetSubss(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            courseSubsId
            assetId
            userId
            assetType
            title
            description
            category
            metaData
            status
            asset {
              __typename
              id
              courseId
              assetId
              assetType
              title
              description
              order
              timeAprox
              starred
              important
              showAsOverview
              showAsFree
              nextStep
              prevStep
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            assetsSubs {
              __typename
              id
              courseId
              userId
              title
              description
              category
              level
              tags
              metaData
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              owner
            }
            owner
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCourseAssetSubssQuery>response.data.listCourseAssetSubss;
  }
  async SubsCourseAssetByIds(
    userId?: string,
    assetId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCourseAssetSubsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SubsCourseAssetByIdsQuery> {
    const statement = `query SubsCourseAssetByIds($userId: ID, $assetId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCourseAssetSubsFilterInput, $limit: Int, $nextToken: String) {
        SubsCourseAssetByIds(userId: $userId, assetId: $assetId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            courseSubsId
            assetId
            userId
            assetType
            title
            description
            category
            metaData
            status
            asset {
              __typename
              id
              courseId
              assetId
              assetType
              title
              description
              order
              timeAprox
              starred
              important
              showAsOverview
              showAsFree
              nextStep
              prevStep
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            assetsSubs {
              __typename
              id
              courseId
              userId
              title
              description
              category
              level
              tags
              metaData
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              owner
            }
            owner
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (userId) {
      gqlAPIServiceArguments.userId = userId;
    }
    if (assetId) {
      gqlAPIServiceArguments.assetId = assetId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SubsCourseAssetByIdsQuery>response.data.SubsCourseAssetByIds;
  }
  async SubsCourseAssetsByCourse(
    courseSubsId?: string,
    userId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCourseAssetSubsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SubsCourseAssetsByCourseQuery> {
    const statement = `query SubsCourseAssetsByCourse($courseSubsId: ID, $userId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCourseAssetSubsFilterInput, $limit: Int, $nextToken: String) {
        subsCourseAssetsByCourse(courseSubsId: $courseSubsId, userId: $userId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            courseSubsId
            assetId
            userId
            assetType
            title
            description
            category
            metaData
            status
            asset {
              __typename
              id
              courseId
              assetId
              assetType
              title
              description
              order
              timeAprox
              starred
              important
              showAsOverview
              showAsFree
              nextStep
              prevStep
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            assetsSubs {
              __typename
              id
              courseId
              userId
              title
              description
              category
              level
              tags
              metaData
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              owner
            }
            owner
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (courseSubsId) {
      gqlAPIServiceArguments.courseSubsId = courseSubsId;
    }
    if (userId) {
      gqlAPIServiceArguments.userId = userId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SubsCourseAssetsByCourseQuery>(
      response.data.subsCourseAssetsByCourse
    );
  }
  async SubsCourseAssetsByUser(
    userId?: string,
    updatedAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCourseAssetSubsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SubsCourseAssetsByUserQuery> {
    const statement = `query SubsCourseAssetsByUser($userId: ID, $updatedAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCourseAssetSubsFilterInput, $limit: Int, $nextToken: String) {
        SubsCourseAssetsByUser(userId: $userId, updatedAt: $updatedAt, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            courseSubsId
            assetId
            userId
            assetType
            title
            description
            category
            metaData
            status
            asset {
              __typename
              id
              courseId
              assetId
              assetType
              title
              description
              order
              timeAprox
              starred
              important
              showAsOverview
              showAsFree
              nextStep
              prevStep
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            assetsSubs {
              __typename
              id
              courseId
              userId
              title
              description
              category
              level
              tags
              metaData
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              owner
            }
            owner
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (userId) {
      gqlAPIServiceArguments.userId = userId;
    }
    if (updatedAt) {
      gqlAPIServiceArguments.updatedAt = updatedAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SubsCourseAssetsByUserQuery>response.data.SubsCourseAssetsByUser;
  }
  async SubsCourseAssetsByStatus(
    status?: elSubStatus,
    updatedAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCourseAssetSubsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SubsCourseAssetsByStatusQuery> {
    const statement = `query SubsCourseAssetsByStatus($status: elSubStatus, $updatedAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCourseAssetSubsFilterInput, $limit: Int, $nextToken: String) {
        SubsCourseAssetsByStatus(status: $status, updatedAt: $updatedAt, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            courseSubsId
            assetId
            userId
            assetType
            title
            description
            category
            metaData
            status
            asset {
              __typename
              id
              courseId
              assetId
              assetType
              title
              description
              order
              timeAprox
              starred
              important
              showAsOverview
              showAsFree
              nextStep
              prevStep
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            assetsSubs {
              __typename
              id
              courseId
              userId
              title
              description
              category
              level
              tags
              metaData
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              owner
            }
            owner
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    if (updatedAt) {
      gqlAPIServiceArguments.updatedAt = updatedAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SubsCourseAssetsByStatusQuery>(
      response.data.SubsCourseAssetsByStatus
    );
  }
  async SyncCourseAssetSubs(
    filter?: ModelCourseAssetSubsFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncCourseAssetSubsQuery> {
    const statement = `query SyncCourseAssetSubs($filter: ModelCourseAssetSubsFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncCourseAssetSubs(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            courseSubsId
            assetId
            userId
            assetType
            title
            description
            category
            metaData
            status
            asset {
              __typename
              id
              courseId
              assetId
              assetType
              title
              description
              order
              timeAprox
              starred
              important
              showAsOverview
              showAsFree
              nextStep
              prevStep
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            assetsSubs {
              __typename
              id
              courseId
              userId
              title
              description
              category
              level
              tags
              metaData
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              owner
            }
            owner
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncCourseAssetSubsQuery>response.data.syncCourseAssetSubs;
  }
  OnCreateSessionListener: Observable<
    OnCreateSessionSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateSession {
        onCreateSession {
          __typename
          id
          userId
          location
          event
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnCreateSessionSubscription>;

  OnUpdateSessionListener: Observable<
    OnUpdateSessionSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateSession {
        onUpdateSession {
          __typename
          id
          userId
          location
          event
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnUpdateSessionSubscription>;

  OnDeleteSessionListener: Observable<
    OnDeleteSessionSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteSession {
        onDeleteSession {
          __typename
          id
          userId
          location
          event
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnDeleteSessionSubscription>;

  OnCreateUserSettingsListener: Observable<
    OnCreateUserSettingsSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateUserSettings {
        onCreateUserSettings {
          __typename
          id
          userId
          pageSize
          notiType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnCreateUserSettingsSubscription>;

  OnUpdateUserSettingsListener: Observable<
    OnUpdateUserSettingsSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateUserSettings {
        onUpdateUserSettings {
          __typename
          id
          userId
          pageSize
          notiType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnUpdateUserSettingsSubscription>;

  OnDeleteUserSettingsListener: Observable<
    OnDeleteUserSettingsSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteUserSettings {
        onDeleteUserSettings {
          __typename
          id
          userId
          pageSize
          notiType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnDeleteUserSettingsSubscription>;

  OnCreateNotificationListener: Observable<
    OnCreateNotificationSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateNotification {
        onCreateNotification {
          __typename
          id
          typeNotify
          userId
          subject
          body
          localUrl
          renotify
          silent
          dateFor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnCreateNotificationSubscription>;

  OnUpdateNotificationListener: Observable<
    OnUpdateNotificationSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateNotification {
        onUpdateNotification {
          __typename
          id
          typeNotify
          userId
          subject
          body
          localUrl
          renotify
          silent
          dateFor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnUpdateNotificationSubscription>;

  OnDeleteNotificationListener: Observable<
    OnDeleteNotificationSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteNotification {
        onDeleteNotification {
          __typename
          id
          typeNotify
          userId
          subject
          body
          localUrl
          renotify
          silent
          dateFor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnDeleteNotificationSubscription>;

  OnCreateUserPrivacyListener: Observable<
    OnCreateUserPrivacySubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateUserPrivacy {
        onCreateUserPrivacy {
          __typename
          id
          userId
          profileShared
          bgPhoto
          headLine
          secondHeadLine
          titleLine
          bioLine
          showEmail
          showPhone
          showEmailOn
          contactDirectlyByEmail
          showConnectionsTo
          oaConnInvitationAccepted
          oaEventUpdates
          oaUpdatesFromConnections
          oaBreakingNews
          oaTopsPicks
          oaWhoViewedYourProfile
          oaYourNewFollowers
          emailEventsInvitations
          emailEventsUpdates
          emailNewsInvitations
          emailNewsSubscriptions
          whoRequestForNotifications
          whoInvitationsToAttend
          locMap
          location
          locTitle
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnCreateUserPrivacySubscription>;

  OnUpdateUserPrivacyListener: Observable<
    OnUpdateUserPrivacySubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateUserPrivacy {
        onUpdateUserPrivacy {
          __typename
          id
          userId
          profileShared
          bgPhoto
          headLine
          secondHeadLine
          titleLine
          bioLine
          showEmail
          showPhone
          showEmailOn
          contactDirectlyByEmail
          showConnectionsTo
          oaConnInvitationAccepted
          oaEventUpdates
          oaUpdatesFromConnections
          oaBreakingNews
          oaTopsPicks
          oaWhoViewedYourProfile
          oaYourNewFollowers
          emailEventsInvitations
          emailEventsUpdates
          emailNewsInvitations
          emailNewsSubscriptions
          whoRequestForNotifications
          whoInvitationsToAttend
          locMap
          location
          locTitle
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnUpdateUserPrivacySubscription>;

  OnDeleteUserPrivacyListener: Observable<
    OnDeleteUserPrivacySubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteUserPrivacy {
        onDeleteUserPrivacy {
          __typename
          id
          userId
          profileShared
          bgPhoto
          headLine
          secondHeadLine
          titleLine
          bioLine
          showEmail
          showPhone
          showEmailOn
          contactDirectlyByEmail
          showConnectionsTo
          oaConnInvitationAccepted
          oaEventUpdates
          oaUpdatesFromConnections
          oaBreakingNews
          oaTopsPicks
          oaWhoViewedYourProfile
          oaYourNewFollowers
          emailEventsInvitations
          emailEventsUpdates
          emailNewsInvitations
          emailNewsSubscriptions
          whoRequestForNotifications
          whoInvitationsToAttend
          locMap
          location
          locTitle
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnDeleteUserPrivacySubscription>;

  OnCreateCourseCategoryListener: Observable<
    OnCreateCourseCategorySubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCourseCategory {
        onCreateCourseCategory {
          __typename
          id
          category
          createdAt
          updatedAt
          status
          courses {
            __typename
            items {
              __typename
              id
              title
              description
              categoryId
              coverImage
              timeAprox
              status
              coverImage1
              coverImage2
              coverImage3
              courseType
              courseUrl
              overviewSourceMedia
              overviewFileName
              overviewFileType
              overviewFileSize
              transcoderMeta
              transcoderJobId
              courseTrend
              learnDescription
              requirements
              level
              tags
              trending
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnCreateCourseCategorySubscription>;

  OnUpdateCourseCategoryListener: Observable<
    OnUpdateCourseCategorySubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCourseCategory {
        onUpdateCourseCategory {
          __typename
          id
          category
          createdAt
          updatedAt
          status
          courses {
            __typename
            items {
              __typename
              id
              title
              description
              categoryId
              coverImage
              timeAprox
              status
              coverImage1
              coverImage2
              coverImage3
              courseType
              courseUrl
              overviewSourceMedia
              overviewFileName
              overviewFileType
              overviewFileSize
              transcoderMeta
              transcoderJobId
              courseTrend
              learnDescription
              requirements
              level
              tags
              trending
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnUpdateCourseCategorySubscription>;

  OnDeleteCourseCategoryListener: Observable<
    OnDeleteCourseCategorySubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCourseCategory {
        onDeleteCourseCategory {
          __typename
          id
          category
          createdAt
          updatedAt
          status
          courses {
            __typename
            items {
              __typename
              id
              title
              description
              categoryId
              coverImage
              timeAprox
              status
              coverImage1
              coverImage2
              coverImage3
              courseType
              courseUrl
              overviewSourceMedia
              overviewFileName
              overviewFileType
              overviewFileSize
              transcoderMeta
              transcoderJobId
              courseTrend
              learnDescription
              requirements
              level
              tags
              trending
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnDeleteCourseCategorySubscription>;

  OnCreateCourseResourceListener: Observable<
    OnCreateCourseResourceSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCourseResource {
        onCreateCourseResource {
          __typename
          id
          courseId
          description
          contentType
          sourceCode
          fileName
          fileType
          fileSize
          status
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnCreateCourseResourceSubscription>;

  OnUpdateCourseResourceListener: Observable<
    OnUpdateCourseResourceSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCourseResource {
        onUpdateCourseResource {
          __typename
          id
          courseId
          description
          contentType
          sourceCode
          fileName
          fileType
          fileSize
          status
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnUpdateCourseResourceSubscription>;

  OnDeleteCourseResourceListener: Observable<
    OnDeleteCourseResourceSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCourseResource {
        onDeleteCourseResource {
          __typename
          id
          courseId
          description
          contentType
          sourceCode
          fileName
          fileType
          fileSize
          status
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnDeleteCourseResourceSubscription>;

  OnCreateContentListener: Observable<
    OnCreateContentSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateContent {
        onCreateContent {
          __typename
          id
          courseId
          contentType
          media
          sourceMedia
          fileName
          fileType
          fileSize
          transcoderMeta
          transcoderJobId
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnCreateContentSubscription>;

  OnUpdateContentListener: Observable<
    OnUpdateContentSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateContent {
        onUpdateContent {
          __typename
          id
          courseId
          contentType
          media
          sourceMedia
          fileName
          fileType
          fileSize
          transcoderMeta
          transcoderJobId
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnUpdateContentSubscription>;

  OnDeleteContentListener: Observable<
    OnDeleteContentSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteContent {
        onDeleteContent {
          __typename
          id
          courseId
          contentType
          media
          sourceMedia
          fileName
          fileType
          fileSize
          transcoderMeta
          transcoderJobId
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnDeleteContentSubscription>;

  OnCreateContentResourceListener: Observable<
    OnCreateContentResourceSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateContentResource {
        onCreateContentResource {
          __typename
          id
          courseId
          description
          contentType
          sourceCode
          fileName
          fileType
          fileSize
          status
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnCreateContentResourceSubscription>;

  OnUpdateContentResourceListener: Observable<
    OnUpdateContentResourceSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateContentResource {
        onUpdateContentResource {
          __typename
          id
          courseId
          description
          contentType
          sourceCode
          fileName
          fileType
          fileSize
          status
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnUpdateContentResourceSubscription>;

  OnDeleteContentResourceListener: Observable<
    OnDeleteContentResourceSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteContentResource {
        onDeleteContentResource {
          __typename
          id
          courseId
          description
          contentType
          sourceCode
          fileName
          fileType
          fileSize
          status
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnDeleteContentResourceSubscription>;

  OnCreateCourseListener: Observable<OnCreateCourseSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnCreateCourse {
        onCreateCourse {
          __typename
          id
          title
          description
          category {
            __typename
            id
            category
            createdAt
            updatedAt
            status
            courses {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          categoryId
          coverImage
          timeAprox
          status
          coverImage1
          coverImage2
          coverImage3
          courseType
          courseUrl
          overviewSourceMedia
          overviewFileName
          overviewFileType
          overviewFileSize
          transcoderMeta
          transcoderJobId
          courseTrend
          learnDescription
          requirements
          level
          tags
          trending
          updatedAt
          createdAt
          assets {
            __typename
            items {
              __typename
              id
              courseId
              assetId
              assetType
              title
              description
              order
              timeAprox
              starred
              important
              showAsOverview
              showAsFree
              nextStep
              prevStep
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnCreateCourseSubscription>;

  OnUpdateCourseListener: Observable<OnUpdateCourseSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCourse {
        onUpdateCourse {
          __typename
          id
          title
          description
          category {
            __typename
            id
            category
            createdAt
            updatedAt
            status
            courses {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          categoryId
          coverImage
          timeAprox
          status
          coverImage1
          coverImage2
          coverImage3
          courseType
          courseUrl
          overviewSourceMedia
          overviewFileName
          overviewFileType
          overviewFileSize
          transcoderMeta
          transcoderJobId
          courseTrend
          learnDescription
          requirements
          level
          tags
          trending
          updatedAt
          createdAt
          assets {
            __typename
            items {
              __typename
              id
              courseId
              assetId
              assetType
              title
              description
              order
              timeAprox
              starred
              important
              showAsOverview
              showAsFree
              nextStep
              prevStep
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnUpdateCourseSubscription>;

  OnDeleteCourseListener: Observable<OnDeleteCourseSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCourse {
        onDeleteCourse {
          __typename
          id
          title
          description
          category {
            __typename
            id
            category
            createdAt
            updatedAt
            status
            courses {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          categoryId
          coverImage
          timeAprox
          status
          coverImage1
          coverImage2
          coverImage3
          courseType
          courseUrl
          overviewSourceMedia
          overviewFileName
          overviewFileType
          overviewFileSize
          transcoderMeta
          transcoderJobId
          courseTrend
          learnDescription
          requirements
          level
          tags
          trending
          updatedAt
          createdAt
          assets {
            __typename
            items {
              __typename
              id
              courseId
              assetId
              assetType
              title
              description
              order
              timeAprox
              starred
              important
              showAsOverview
              showAsFree
              nextStep
              prevStep
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnDeleteCourseSubscription>;

  OnCreateContentSubsListener: Observable<
    OnCreateContentSubsSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateContentSubs {
        onCreateContentSubs {
          __typename
          id
          contentId
          userId
          metaData
          status
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnCreateContentSubsSubscription>;

  OnUpdateContentSubsListener: Observable<
    OnUpdateContentSubsSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateContentSubs {
        onUpdateContentSubs {
          __typename
          id
          contentId
          userId
          metaData
          status
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnUpdateContentSubsSubscription>;

  OnDeleteContentSubsListener: Observable<
    OnDeleteContentSubsSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteContentSubs {
        onDeleteContentSubs {
          __typename
          id
          contentId
          userId
          metaData
          status
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnDeleteContentSubsSubscription>;

  OnCreateCourseSubsListener: Observable<
    OnCreateCourseSubsSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCourseSubs($owner: String) {
        onCreateCourseSubs(owner: $owner) {
          __typename
          id
          courseId
          userId
          title
          description
          category
          level
          tags
          metaData
          status
          course {
            __typename
            id
            title
            description
            category {
              __typename
              id
              category
              createdAt
              updatedAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            categoryId
            coverImage
            timeAprox
            status
            coverImage1
            coverImage2
            coverImage3
            courseType
            courseUrl
            overviewSourceMedia
            overviewFileName
            overviewFileType
            overviewFileSize
            transcoderMeta
            transcoderJobId
            courseTrend
            learnDescription
            requirements
            level
            tags
            trending
            updatedAt
            createdAt
            assets {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          owner
          assetsSubs {
            __typename
            items {
              __typename
              id
              courseSubsId
              assetId
              userId
              assetType
              title
              description
              category
              metaData
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              owner
            }
            nextToken
            startedAt
          }
        }
      }`
    )
  ) as Observable<OnCreateCourseSubsSubscription>;

  OnUpdateCourseSubsListener: Observable<
    OnUpdateCourseSubsSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCourseSubs($owner: String) {
        onUpdateCourseSubs(owner: $owner) {
          __typename
          id
          courseId
          userId
          title
          description
          category
          level
          tags
          metaData
          status
          course {
            __typename
            id
            title
            description
            category {
              __typename
              id
              category
              createdAt
              updatedAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            categoryId
            coverImage
            timeAprox
            status
            coverImage1
            coverImage2
            coverImage3
            courseType
            courseUrl
            overviewSourceMedia
            overviewFileName
            overviewFileType
            overviewFileSize
            transcoderMeta
            transcoderJobId
            courseTrend
            learnDescription
            requirements
            level
            tags
            trending
            updatedAt
            createdAt
            assets {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          owner
          assetsSubs {
            __typename
            items {
              __typename
              id
              courseSubsId
              assetId
              userId
              assetType
              title
              description
              category
              metaData
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              owner
            }
            nextToken
            startedAt
          }
        }
      }`
    )
  ) as Observable<OnUpdateCourseSubsSubscription>;

  OnDeleteCourseSubsListener: Observable<
    OnDeleteCourseSubsSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCourseSubs($owner: String) {
        onDeleteCourseSubs(owner: $owner) {
          __typename
          id
          courseId
          userId
          title
          description
          category
          level
          tags
          metaData
          status
          course {
            __typename
            id
            title
            description
            category {
              __typename
              id
              category
              createdAt
              updatedAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            categoryId
            coverImage
            timeAprox
            status
            coverImage1
            coverImage2
            coverImage3
            courseType
            courseUrl
            overviewSourceMedia
            overviewFileName
            overviewFileType
            overviewFileSize
            transcoderMeta
            transcoderJobId
            courseTrend
            learnDescription
            requirements
            level
            tags
            trending
            updatedAt
            createdAt
            assets {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          owner
          assetsSubs {
            __typename
            items {
              __typename
              id
              courseSubsId
              assetId
              userId
              assetType
              title
              description
              category
              metaData
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              owner
            }
            nextToken
            startedAt
          }
        }
      }`
    )
  ) as Observable<OnDeleteCourseSubsSubscription>;

  OnCreateCourseAssetsListener: Observable<
    OnCreateCourseAssetsSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCourseAssets {
        onCreateCourseAssets {
          __typename
          id
          courseId
          assetId
          assetType
          title
          description
          order
          timeAprox
          starred
          important
          showAsOverview
          showAsFree
          nextStep
          prevStep
          status
          updatedAt
          createdAt
          course {
            __typename
            id
            title
            description
            category {
              __typename
              id
              category
              createdAt
              updatedAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            categoryId
            coverImage
            timeAprox
            status
            coverImage1
            coverImage2
            coverImage3
            courseType
            courseUrl
            overviewSourceMedia
            overviewFileName
            overviewFileType
            overviewFileSize
            transcoderMeta
            transcoderJobId
            courseTrend
            learnDescription
            requirements
            level
            tags
            trending
            updatedAt
            createdAt
            assets {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          topic {
            __typename
            id
            courseId
            contentType
            media
            sourceMedia
            fileName
            fileType
            fileSize
            transcoderMeta
            transcoderJobId
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
          }
          quiz {
            __typename
            id
            courseId
            questionsRandom
            passingScore
            noAttempts
            score
            labelWhenPassed
            labelWhenFailed
            questionFeedback
            updatedAt
            createdAt
            status
            questions {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          attachments {
            __typename
            items {
              __typename
              id
              courseId
              courseAssetId
              description
              contentType
              sourceCode
              fileName
              fileType
              fileSize
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnCreateCourseAssetsSubscription>;

  OnUpdateCourseAssetsListener: Observable<
    OnUpdateCourseAssetsSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCourseAssets {
        onUpdateCourseAssets {
          __typename
          id
          courseId
          assetId
          assetType
          title
          description
          order
          timeAprox
          starred
          important
          showAsOverview
          showAsFree
          nextStep
          prevStep
          status
          updatedAt
          createdAt
          course {
            __typename
            id
            title
            description
            category {
              __typename
              id
              category
              createdAt
              updatedAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            categoryId
            coverImage
            timeAprox
            status
            coverImage1
            coverImage2
            coverImage3
            courseType
            courseUrl
            overviewSourceMedia
            overviewFileName
            overviewFileType
            overviewFileSize
            transcoderMeta
            transcoderJobId
            courseTrend
            learnDescription
            requirements
            level
            tags
            trending
            updatedAt
            createdAt
            assets {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          topic {
            __typename
            id
            courseId
            contentType
            media
            sourceMedia
            fileName
            fileType
            fileSize
            transcoderMeta
            transcoderJobId
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
          }
          quiz {
            __typename
            id
            courseId
            questionsRandom
            passingScore
            noAttempts
            score
            labelWhenPassed
            labelWhenFailed
            questionFeedback
            updatedAt
            createdAt
            status
            questions {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          attachments {
            __typename
            items {
              __typename
              id
              courseId
              courseAssetId
              description
              contentType
              sourceCode
              fileName
              fileType
              fileSize
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnUpdateCourseAssetsSubscription>;

  OnDeleteCourseAssetsListener: Observable<
    OnDeleteCourseAssetsSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCourseAssets {
        onDeleteCourseAssets {
          __typename
          id
          courseId
          assetId
          assetType
          title
          description
          order
          timeAprox
          starred
          important
          showAsOverview
          showAsFree
          nextStep
          prevStep
          status
          updatedAt
          createdAt
          course {
            __typename
            id
            title
            description
            category {
              __typename
              id
              category
              createdAt
              updatedAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            categoryId
            coverImage
            timeAprox
            status
            coverImage1
            coverImage2
            coverImage3
            courseType
            courseUrl
            overviewSourceMedia
            overviewFileName
            overviewFileType
            overviewFileSize
            transcoderMeta
            transcoderJobId
            courseTrend
            learnDescription
            requirements
            level
            tags
            trending
            updatedAt
            createdAt
            assets {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          topic {
            __typename
            id
            courseId
            contentType
            media
            sourceMedia
            fileName
            fileType
            fileSize
            transcoderMeta
            transcoderJobId
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
          }
          quiz {
            __typename
            id
            courseId
            questionsRandom
            passingScore
            noAttempts
            score
            labelWhenPassed
            labelWhenFailed
            questionFeedback
            updatedAt
            createdAt
            status
            questions {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          attachments {
            __typename
            items {
              __typename
              id
              courseId
              courseAssetId
              description
              contentType
              sourceCode
              fileName
              fileType
              fileSize
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnDeleteCourseAssetsSubscription>;

  OnCreateQuizListener: Observable<OnCreateQuizSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnCreateQuiz {
        onCreateQuiz {
          __typename
          id
          courseId
          questionsRandom
          passingScore
          noAttempts
          score
          labelWhenPassed
          labelWhenFailed
          questionFeedback
          updatedAt
          createdAt
          status
          questions {
            __typename
            items {
              __typename
              id
              quizContentId
              order
              title
              qType
              labelCorrectMessage
              labelIncorrectMessage
              questionFeedBack
              media
              sourceMedia
              fileName
              fileType
              fileSize
              updatedAt
              createdAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnCreateQuizSubscription>;

  OnUpdateQuizListener: Observable<OnUpdateQuizSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnUpdateQuiz {
        onUpdateQuiz {
          __typename
          id
          courseId
          questionsRandom
          passingScore
          noAttempts
          score
          labelWhenPassed
          labelWhenFailed
          questionFeedback
          updatedAt
          createdAt
          status
          questions {
            __typename
            items {
              __typename
              id
              quizContentId
              order
              title
              qType
              labelCorrectMessage
              labelIncorrectMessage
              questionFeedBack
              media
              sourceMedia
              fileName
              fileType
              fileSize
              updatedAt
              createdAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnUpdateQuizSubscription>;

  OnDeleteQuizListener: Observable<OnDeleteQuizSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnDeleteQuiz {
        onDeleteQuiz {
          __typename
          id
          courseId
          questionsRandom
          passingScore
          noAttempts
          score
          labelWhenPassed
          labelWhenFailed
          questionFeedback
          updatedAt
          createdAt
          status
          questions {
            __typename
            items {
              __typename
              id
              quizContentId
              order
              title
              qType
              labelCorrectMessage
              labelIncorrectMessage
              questionFeedBack
              media
              sourceMedia
              fileName
              fileType
              fileSize
              updatedAt
              createdAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnDeleteQuizSubscription>;

  OnCreateQuizQuestionListener: Observable<
    OnCreateQuizQuestionSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateQuizQuestion {
        onCreateQuizQuestion {
          __typename
          id
          quizContentId
          quiz {
            __typename
            id
            courseId
            questionsRandom
            passingScore
            noAttempts
            score
            labelWhenPassed
            labelWhenFailed
            questionFeedback
            updatedAt
            createdAt
            status
            questions {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          order
          title
          qType
          labelCorrectMessage
          labelIncorrectMessage
          questionFeedBack
          media
          sourceMedia
          fileName
          fileType
          fileSize
          updatedAt
          createdAt
          status
          questionAnswers {
            __typename
            items {
              __typename
              id
              quizQuestionId
              order
              isCorrect
              title
              answer
              answerFeedBack
              qType
              media
              sourceMedia
              fileName
              fileType
              fileSize
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnCreateQuizQuestionSubscription>;

  OnUpdateQuizQuestionListener: Observable<
    OnUpdateQuizQuestionSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateQuizQuestion {
        onUpdateQuizQuestion {
          __typename
          id
          quizContentId
          quiz {
            __typename
            id
            courseId
            questionsRandom
            passingScore
            noAttempts
            score
            labelWhenPassed
            labelWhenFailed
            questionFeedback
            updatedAt
            createdAt
            status
            questions {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          order
          title
          qType
          labelCorrectMessage
          labelIncorrectMessage
          questionFeedBack
          media
          sourceMedia
          fileName
          fileType
          fileSize
          updatedAt
          createdAt
          status
          questionAnswers {
            __typename
            items {
              __typename
              id
              quizQuestionId
              order
              isCorrect
              title
              answer
              answerFeedBack
              qType
              media
              sourceMedia
              fileName
              fileType
              fileSize
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnUpdateQuizQuestionSubscription>;

  OnDeleteQuizQuestionListener: Observable<
    OnDeleteQuizQuestionSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteQuizQuestion {
        onDeleteQuizQuestion {
          __typename
          id
          quizContentId
          quiz {
            __typename
            id
            courseId
            questionsRandom
            passingScore
            noAttempts
            score
            labelWhenPassed
            labelWhenFailed
            questionFeedback
            updatedAt
            createdAt
            status
            questions {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          order
          title
          qType
          labelCorrectMessage
          labelIncorrectMessage
          questionFeedBack
          media
          sourceMedia
          fileName
          fileType
          fileSize
          updatedAt
          createdAt
          status
          questionAnswers {
            __typename
            items {
              __typename
              id
              quizQuestionId
              order
              isCorrect
              title
              answer
              answerFeedBack
              qType
              media
              sourceMedia
              fileName
              fileType
              fileSize
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnDeleteQuizQuestionSubscription>;

  OnCreateQuestionAnswersListener: Observable<
    OnCreateQuestionAnswersSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateQuestionAnswers {
        onCreateQuestionAnswers {
          __typename
          id
          quizQuestionId
          question {
            __typename
            id
            quizContentId
            quiz {
              __typename
              id
              courseId
              questionsRandom
              passingScore
              noAttempts
              score
              labelWhenPassed
              labelWhenFailed
              questionFeedback
              updatedAt
              createdAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            order
            title
            qType
            labelCorrectMessage
            labelIncorrectMessage
            questionFeedBack
            media
            sourceMedia
            fileName
            fileType
            fileSize
            updatedAt
            createdAt
            status
            questionAnswers {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          order
          isCorrect
          title
          answer
          answerFeedBack
          qType
          media
          sourceMedia
          fileName
          fileType
          fileSize
          status
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnCreateQuestionAnswersSubscription>;

  OnUpdateQuestionAnswersListener: Observable<
    OnUpdateQuestionAnswersSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateQuestionAnswers {
        onUpdateQuestionAnswers {
          __typename
          id
          quizQuestionId
          question {
            __typename
            id
            quizContentId
            quiz {
              __typename
              id
              courseId
              questionsRandom
              passingScore
              noAttempts
              score
              labelWhenPassed
              labelWhenFailed
              questionFeedback
              updatedAt
              createdAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            order
            title
            qType
            labelCorrectMessage
            labelIncorrectMessage
            questionFeedBack
            media
            sourceMedia
            fileName
            fileType
            fileSize
            updatedAt
            createdAt
            status
            questionAnswers {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          order
          isCorrect
          title
          answer
          answerFeedBack
          qType
          media
          sourceMedia
          fileName
          fileType
          fileSize
          status
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnUpdateQuestionAnswersSubscription>;

  OnDeleteQuestionAnswersListener: Observable<
    OnDeleteQuestionAnswersSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteQuestionAnswers {
        onDeleteQuestionAnswers {
          __typename
          id
          quizQuestionId
          question {
            __typename
            id
            quizContentId
            quiz {
              __typename
              id
              courseId
              questionsRandom
              passingScore
              noAttempts
              score
              labelWhenPassed
              labelWhenFailed
              questionFeedback
              updatedAt
              createdAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            order
            title
            qType
            labelCorrectMessage
            labelIncorrectMessage
            questionFeedBack
            media
            sourceMedia
            fileName
            fileType
            fileSize
            updatedAt
            createdAt
            status
            questionAnswers {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          order
          isCorrect
          title
          answer
          answerFeedBack
          qType
          media
          sourceMedia
          fileName
          fileType
          fileSize
          status
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnDeleteQuestionAnswersSubscription>;

  OnCreateCourseTagsListener: Observable<
    OnCreateCourseTagsSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCourseTags {
        onCreateCourseTags {
          __typename
          id
          tag
          createdAt
          updatedAt
          status
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnCreateCourseTagsSubscription>;

  OnUpdateCourseTagsListener: Observable<
    OnUpdateCourseTagsSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCourseTags {
        onUpdateCourseTags {
          __typename
          id
          tag
          createdAt
          updatedAt
          status
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnUpdateCourseTagsSubscription>;

  OnDeleteCourseTagsListener: Observable<
    OnDeleteCourseTagsSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCourseTags {
        onDeleteCourseTags {
          __typename
          id
          tag
          createdAt
          updatedAt
          status
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnDeleteCourseTagsSubscription>;

  OnCreateCourseAssetsResourceListener: Observable<
    OnCreateCourseAssetsResourceSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCourseAssetsResource {
        onCreateCourseAssetsResource {
          __typename
          id
          courseId
          courseAssetId
          description
          contentType
          sourceCode
          fileName
          fileType
          fileSize
          status
          updatedAt
          createdAt
          courseAsset {
            __typename
            id
            courseId
            assetId
            assetType
            title
            description
            order
            timeAprox
            starred
            important
            showAsOverview
            showAsFree
            nextStep
            prevStep
            status
            updatedAt
            createdAt
            course {
              __typename
              id
              title
              description
              categoryId
              coverImage
              timeAprox
              status
              coverImage1
              coverImage2
              coverImage3
              courseType
              courseUrl
              overviewSourceMedia
              overviewFileName
              overviewFileType
              overviewFileSize
              transcoderMeta
              transcoderJobId
              courseTrend
              learnDescription
              requirements
              level
              tags
              trending
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            topic {
              __typename
              id
              courseId
              contentType
              media
              sourceMedia
              fileName
              fileType
              fileSize
              transcoderMeta
              transcoderJobId
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            quiz {
              __typename
              id
              courseId
              questionsRandom
              passingScore
              noAttempts
              score
              labelWhenPassed
              labelWhenFailed
              questionFeedback
              updatedAt
              createdAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            attachments {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnCreateCourseAssetsResourceSubscription>;

  OnUpdateCourseAssetsResourceListener: Observable<
    OnUpdateCourseAssetsResourceSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCourseAssetsResource {
        onUpdateCourseAssetsResource {
          __typename
          id
          courseId
          courseAssetId
          description
          contentType
          sourceCode
          fileName
          fileType
          fileSize
          status
          updatedAt
          createdAt
          courseAsset {
            __typename
            id
            courseId
            assetId
            assetType
            title
            description
            order
            timeAprox
            starred
            important
            showAsOverview
            showAsFree
            nextStep
            prevStep
            status
            updatedAt
            createdAt
            course {
              __typename
              id
              title
              description
              categoryId
              coverImage
              timeAprox
              status
              coverImage1
              coverImage2
              coverImage3
              courseType
              courseUrl
              overviewSourceMedia
              overviewFileName
              overviewFileType
              overviewFileSize
              transcoderMeta
              transcoderJobId
              courseTrend
              learnDescription
              requirements
              level
              tags
              trending
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            topic {
              __typename
              id
              courseId
              contentType
              media
              sourceMedia
              fileName
              fileType
              fileSize
              transcoderMeta
              transcoderJobId
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            quiz {
              __typename
              id
              courseId
              questionsRandom
              passingScore
              noAttempts
              score
              labelWhenPassed
              labelWhenFailed
              questionFeedback
              updatedAt
              createdAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            attachments {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnUpdateCourseAssetsResourceSubscription>;

  OnDeleteCourseAssetsResourceListener: Observable<
    OnDeleteCourseAssetsResourceSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCourseAssetsResource {
        onDeleteCourseAssetsResource {
          __typename
          id
          courseId
          courseAssetId
          description
          contentType
          sourceCode
          fileName
          fileType
          fileSize
          status
          updatedAt
          createdAt
          courseAsset {
            __typename
            id
            courseId
            assetId
            assetType
            title
            description
            order
            timeAprox
            starred
            important
            showAsOverview
            showAsFree
            nextStep
            prevStep
            status
            updatedAt
            createdAt
            course {
              __typename
              id
              title
              description
              categoryId
              coverImage
              timeAprox
              status
              coverImage1
              coverImage2
              coverImage3
              courseType
              courseUrl
              overviewSourceMedia
              overviewFileName
              overviewFileType
              overviewFileSize
              transcoderMeta
              transcoderJobId
              courseTrend
              learnDescription
              requirements
              level
              tags
              trending
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            topic {
              __typename
              id
              courseId
              contentType
              media
              sourceMedia
              fileName
              fileType
              fileSize
              transcoderMeta
              transcoderJobId
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            quiz {
              __typename
              id
              courseId
              questionsRandom
              passingScore
              noAttempts
              score
              labelWhenPassed
              labelWhenFailed
              questionFeedback
              updatedAt
              createdAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            attachments {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnDeleteCourseAssetsResourceSubscription>;

  OnCreateCourseAssetSubsListener: Observable<
    OnCreateCourseAssetSubsSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCourseAssetSubs($owner: String) {
        onCreateCourseAssetSubs(owner: $owner) {
          __typename
          id
          courseSubsId
          assetId
          userId
          assetType
          title
          description
          category
          metaData
          status
          asset {
            __typename
            id
            courseId
            assetId
            assetType
            title
            description
            order
            timeAprox
            starred
            important
            showAsOverview
            showAsFree
            nextStep
            prevStep
            status
            updatedAt
            createdAt
            course {
              __typename
              id
              title
              description
              categoryId
              coverImage
              timeAprox
              status
              coverImage1
              coverImage2
              coverImage3
              courseType
              courseUrl
              overviewSourceMedia
              overviewFileName
              overviewFileType
              overviewFileSize
              transcoderMeta
              transcoderJobId
              courseTrend
              learnDescription
              requirements
              level
              tags
              trending
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            topic {
              __typename
              id
              courseId
              contentType
              media
              sourceMedia
              fileName
              fileType
              fileSize
              transcoderMeta
              transcoderJobId
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            quiz {
              __typename
              id
              courseId
              questionsRandom
              passingScore
              noAttempts
              score
              labelWhenPassed
              labelWhenFailed
              questionFeedback
              updatedAt
              createdAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            attachments {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          assetsSubs {
            __typename
            id
            courseId
            userId
            title
            description
            category
            level
            tags
            metaData
            status
            course {
              __typename
              id
              title
              description
              categoryId
              coverImage
              timeAprox
              status
              coverImage1
              coverImage2
              coverImage3
              courseType
              courseUrl
              overviewSourceMedia
              overviewFileName
              overviewFileType
              overviewFileSize
              transcoderMeta
              transcoderJobId
              courseTrend
              learnDescription
              requirements
              level
              tags
              trending
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            owner
            assetsSubs {
              __typename
              nextToken
              startedAt
            }
          }
          owner
        }
      }`
    )
  ) as Observable<OnCreateCourseAssetSubsSubscription>;

  OnUpdateCourseAssetSubsListener: Observable<
    OnUpdateCourseAssetSubsSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCourseAssetSubs($owner: String) {
        onUpdateCourseAssetSubs(owner: $owner) {
          __typename
          id
          courseSubsId
          assetId
          userId
          assetType
          title
          description
          category
          metaData
          status
          asset {
            __typename
            id
            courseId
            assetId
            assetType
            title
            description
            order
            timeAprox
            starred
            important
            showAsOverview
            showAsFree
            nextStep
            prevStep
            status
            updatedAt
            createdAt
            course {
              __typename
              id
              title
              description
              categoryId
              coverImage
              timeAprox
              status
              coverImage1
              coverImage2
              coverImage3
              courseType
              courseUrl
              overviewSourceMedia
              overviewFileName
              overviewFileType
              overviewFileSize
              transcoderMeta
              transcoderJobId
              courseTrend
              learnDescription
              requirements
              level
              tags
              trending
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            topic {
              __typename
              id
              courseId
              contentType
              media
              sourceMedia
              fileName
              fileType
              fileSize
              transcoderMeta
              transcoderJobId
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            quiz {
              __typename
              id
              courseId
              questionsRandom
              passingScore
              noAttempts
              score
              labelWhenPassed
              labelWhenFailed
              questionFeedback
              updatedAt
              createdAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            attachments {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          assetsSubs {
            __typename
            id
            courseId
            userId
            title
            description
            category
            level
            tags
            metaData
            status
            course {
              __typename
              id
              title
              description
              categoryId
              coverImage
              timeAprox
              status
              coverImage1
              coverImage2
              coverImage3
              courseType
              courseUrl
              overviewSourceMedia
              overviewFileName
              overviewFileType
              overviewFileSize
              transcoderMeta
              transcoderJobId
              courseTrend
              learnDescription
              requirements
              level
              tags
              trending
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            owner
            assetsSubs {
              __typename
              nextToken
              startedAt
            }
          }
          owner
        }
      }`
    )
  ) as Observable<OnUpdateCourseAssetSubsSubscription>;

  OnDeleteCourseAssetSubsListener: Observable<
    OnDeleteCourseAssetSubsSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCourseAssetSubs($owner: String) {
        onDeleteCourseAssetSubs(owner: $owner) {
          __typename
          id
          courseSubsId
          assetId
          userId
          assetType
          title
          description
          category
          metaData
          status
          asset {
            __typename
            id
            courseId
            assetId
            assetType
            title
            description
            order
            timeAprox
            starred
            important
            showAsOverview
            showAsFree
            nextStep
            prevStep
            status
            updatedAt
            createdAt
            course {
              __typename
              id
              title
              description
              categoryId
              coverImage
              timeAprox
              status
              coverImage1
              coverImage2
              coverImage3
              courseType
              courseUrl
              overviewSourceMedia
              overviewFileName
              overviewFileType
              overviewFileSize
              transcoderMeta
              transcoderJobId
              courseTrend
              learnDescription
              requirements
              level
              tags
              trending
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            topic {
              __typename
              id
              courseId
              contentType
              media
              sourceMedia
              fileName
              fileType
              fileSize
              transcoderMeta
              transcoderJobId
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            quiz {
              __typename
              id
              courseId
              questionsRandom
              passingScore
              noAttempts
              score
              labelWhenPassed
              labelWhenFailed
              questionFeedback
              updatedAt
              createdAt
              status
              _version
              _deleted
              _lastChangedAt
            }
            attachments {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          assetsSubs {
            __typename
            id
            courseId
            userId
            title
            description
            category
            level
            tags
            metaData
            status
            course {
              __typename
              id
              title
              description
              categoryId
              coverImage
              timeAprox
              status
              coverImage1
              coverImage2
              coverImage3
              courseType
              courseUrl
              overviewSourceMedia
              overviewFileName
              overviewFileType
              overviewFileSize
              transcoderMeta
              transcoderJobId
              courseTrend
              learnDescription
              requirements
              level
              tags
              trending
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            owner
            assetsSubs {
              __typename
              nextToken
              startedAt
            }
          }
          owner
        }
      }`
    )
  ) as Observable<OnDeleteCourseAssetSubsSubscription>;

  OnCreateContactListener: Observable<
    OnCreateContactSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateContact {
        onCreateContact {
          __typename
          id
          userID
          syncID
          contactType
          contactName
          firstName
          lastName
          email
          phone
          avatar
          avatarType
          jobTitle
          birthday
          starred
          frequent
          status
          pbProfileOptions
          userRelatedId
          notes
          businessId
          contactSince
          updatedAt
          createdAt
          contactRelId
          contactRel {
            __typename
            id
            userID
            syncID
            contactType
            contactName
            firstName
            lastName
            email
            phone
            avatar
            avatarType
            jobTitle
            birthday
            starred
            frequent
            status
            pbProfileOptions
            userRelatedId
            notes
            businessId
            contactSince
            updatedAt
            createdAt
            contactRelId
            contactRel {
              __typename
              id
              userID
              syncID
              contactType
              contactName
              firstName
              lastName
              email
              phone
              avatar
              avatarType
              jobTitle
              birthday
              starred
              frequent
              status
              pbProfileOptions
              userRelatedId
              notes
              businessId
              contactSince
              updatedAt
              createdAt
              contactRelId
              _version
              _deleted
              _lastChangedAt
            }
            addresses {
              __typename
              nextToken
              startedAt
            }
            connects {
              __typename
              nextToken
              startedAt
            }
            licence {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          addresses {
            __typename
            items {
              __typename
              id
              contactId
              addressType
              street
              street1
              zipCode
              city
              state
              Country
              status
              updatedAt
              createdAt
              lat
              lng
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          connects {
            __typename
            items {
              __typename
              id
              contactId
              connsContactId
              contactType
              contactName
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          licence {
            __typename
            items {
              __typename
              id
              contactId
              noLicenses
              noClaimed
              groupId
              group
              prefix
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnCreateContactSubscription>;

  OnUpdateContactListener: Observable<
    OnUpdateContactSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateContact {
        onUpdateContact {
          __typename
          id
          userID
          syncID
          contactType
          contactName
          firstName
          lastName
          email
          phone
          avatar
          avatarType
          jobTitle
          birthday
          starred
          frequent
          status
          pbProfileOptions
          userRelatedId
          notes
          businessId
          contactSince
          updatedAt
          createdAt
          contactRelId
          contactRel {
            __typename
            id
            userID
            syncID
            contactType
            contactName
            firstName
            lastName
            email
            phone
            avatar
            avatarType
            jobTitle
            birthday
            starred
            frequent
            status
            pbProfileOptions
            userRelatedId
            notes
            businessId
            contactSince
            updatedAt
            createdAt
            contactRelId
            contactRel {
              __typename
              id
              userID
              syncID
              contactType
              contactName
              firstName
              lastName
              email
              phone
              avatar
              avatarType
              jobTitle
              birthday
              starred
              frequent
              status
              pbProfileOptions
              userRelatedId
              notes
              businessId
              contactSince
              updatedAt
              createdAt
              contactRelId
              _version
              _deleted
              _lastChangedAt
            }
            addresses {
              __typename
              nextToken
              startedAt
            }
            connects {
              __typename
              nextToken
              startedAt
            }
            licence {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          addresses {
            __typename
            items {
              __typename
              id
              contactId
              addressType
              street
              street1
              zipCode
              city
              state
              Country
              status
              updatedAt
              createdAt
              lat
              lng
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          connects {
            __typename
            items {
              __typename
              id
              contactId
              connsContactId
              contactType
              contactName
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          licence {
            __typename
            items {
              __typename
              id
              contactId
              noLicenses
              noClaimed
              groupId
              group
              prefix
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnUpdateContactSubscription>;

  OnDeleteContactListener: Observable<
    OnDeleteContactSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteContact {
        onDeleteContact {
          __typename
          id
          userID
          syncID
          contactType
          contactName
          firstName
          lastName
          email
          phone
          avatar
          avatarType
          jobTitle
          birthday
          starred
          frequent
          status
          pbProfileOptions
          userRelatedId
          notes
          businessId
          contactSince
          updatedAt
          createdAt
          contactRelId
          contactRel {
            __typename
            id
            userID
            syncID
            contactType
            contactName
            firstName
            lastName
            email
            phone
            avatar
            avatarType
            jobTitle
            birthday
            starred
            frequent
            status
            pbProfileOptions
            userRelatedId
            notes
            businessId
            contactSince
            updatedAt
            createdAt
            contactRelId
            contactRel {
              __typename
              id
              userID
              syncID
              contactType
              contactName
              firstName
              lastName
              email
              phone
              avatar
              avatarType
              jobTitle
              birthday
              starred
              frequent
              status
              pbProfileOptions
              userRelatedId
              notes
              businessId
              contactSince
              updatedAt
              createdAt
              contactRelId
              _version
              _deleted
              _lastChangedAt
            }
            addresses {
              __typename
              nextToken
              startedAt
            }
            connects {
              __typename
              nextToken
              startedAt
            }
            licence {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          addresses {
            __typename
            items {
              __typename
              id
              contactId
              addressType
              street
              street1
              zipCode
              city
              state
              Country
              status
              updatedAt
              createdAt
              lat
              lng
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          connects {
            __typename
            items {
              __typename
              id
              contactId
              connsContactId
              contactType
              contactName
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          licence {
            __typename
            items {
              __typename
              id
              contactId
              noLicenses
              noClaimed
              groupId
              group
              prefix
              status
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnDeleteContactSubscription>;

  OnCreateConnectsListener: Observable<
    OnCreateConnectsSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateConnects {
        onCreateConnects {
          __typename
          id
          contactId
          connsContactId
          contactType
          contactName
          status
          updatedAt
          createdAt
          contact {
            __typename
            id
            userID
            syncID
            contactType
            contactName
            firstName
            lastName
            email
            phone
            avatar
            avatarType
            jobTitle
            birthday
            starred
            frequent
            status
            pbProfileOptions
            userRelatedId
            notes
            businessId
            contactSince
            updatedAt
            createdAt
            contactRelId
            contactRel {
              __typename
              id
              userID
              syncID
              contactType
              contactName
              firstName
              lastName
              email
              phone
              avatar
              avatarType
              jobTitle
              birthday
              starred
              frequent
              status
              pbProfileOptions
              userRelatedId
              notes
              businessId
              contactSince
              updatedAt
              createdAt
              contactRelId
              _version
              _deleted
              _lastChangedAt
            }
            addresses {
              __typename
              nextToken
              startedAt
            }
            connects {
              __typename
              nextToken
              startedAt
            }
            licence {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnCreateConnectsSubscription>;

  OnUpdateConnectsListener: Observable<
    OnUpdateConnectsSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateConnects {
        onUpdateConnects {
          __typename
          id
          contactId
          connsContactId
          contactType
          contactName
          status
          updatedAt
          createdAt
          contact {
            __typename
            id
            userID
            syncID
            contactType
            contactName
            firstName
            lastName
            email
            phone
            avatar
            avatarType
            jobTitle
            birthday
            starred
            frequent
            status
            pbProfileOptions
            userRelatedId
            notes
            businessId
            contactSince
            updatedAt
            createdAt
            contactRelId
            contactRel {
              __typename
              id
              userID
              syncID
              contactType
              contactName
              firstName
              lastName
              email
              phone
              avatar
              avatarType
              jobTitle
              birthday
              starred
              frequent
              status
              pbProfileOptions
              userRelatedId
              notes
              businessId
              contactSince
              updatedAt
              createdAt
              contactRelId
              _version
              _deleted
              _lastChangedAt
            }
            addresses {
              __typename
              nextToken
              startedAt
            }
            connects {
              __typename
              nextToken
              startedAt
            }
            licence {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnUpdateConnectsSubscription>;

  OnDeleteConnectsListener: Observable<
    OnDeleteConnectsSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteConnects {
        onDeleteConnects {
          __typename
          id
          contactId
          connsContactId
          contactType
          contactName
          status
          updatedAt
          createdAt
          contact {
            __typename
            id
            userID
            syncID
            contactType
            contactName
            firstName
            lastName
            email
            phone
            avatar
            avatarType
            jobTitle
            birthday
            starred
            frequent
            status
            pbProfileOptions
            userRelatedId
            notes
            businessId
            contactSince
            updatedAt
            createdAt
            contactRelId
            contactRel {
              __typename
              id
              userID
              syncID
              contactType
              contactName
              firstName
              lastName
              email
              phone
              avatar
              avatarType
              jobTitle
              birthday
              starred
              frequent
              status
              pbProfileOptions
              userRelatedId
              notes
              businessId
              contactSince
              updatedAt
              createdAt
              contactRelId
              _version
              _deleted
              _lastChangedAt
            }
            addresses {
              __typename
              nextToken
              startedAt
            }
            connects {
              __typename
              nextToken
              startedAt
            }
            licence {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnDeleteConnectsSubscription>;

  OnCreateContactAddressListener: Observable<
    OnCreateContactAddressSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateContactAddress {
        onCreateContactAddress {
          __typename
          id
          contactId
          addressType
          street
          street1
          zipCode
          city
          state
          Country
          status
          updatedAt
          createdAt
          lat
          lng
          contact {
            __typename
            id
            userID
            syncID
            contactType
            contactName
            firstName
            lastName
            email
            phone
            avatar
            avatarType
            jobTitle
            birthday
            starred
            frequent
            status
            pbProfileOptions
            userRelatedId
            notes
            businessId
            contactSince
            updatedAt
            createdAt
            contactRelId
            contactRel {
              __typename
              id
              userID
              syncID
              contactType
              contactName
              firstName
              lastName
              email
              phone
              avatar
              avatarType
              jobTitle
              birthday
              starred
              frequent
              status
              pbProfileOptions
              userRelatedId
              notes
              businessId
              contactSince
              updatedAt
              createdAt
              contactRelId
              _version
              _deleted
              _lastChangedAt
            }
            addresses {
              __typename
              nextToken
              startedAt
            }
            connects {
              __typename
              nextToken
              startedAt
            }
            licence {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnCreateContactAddressSubscription>;

  OnUpdateContactAddressListener: Observable<
    OnUpdateContactAddressSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateContactAddress {
        onUpdateContactAddress {
          __typename
          id
          contactId
          addressType
          street
          street1
          zipCode
          city
          state
          Country
          status
          updatedAt
          createdAt
          lat
          lng
          contact {
            __typename
            id
            userID
            syncID
            contactType
            contactName
            firstName
            lastName
            email
            phone
            avatar
            avatarType
            jobTitle
            birthday
            starred
            frequent
            status
            pbProfileOptions
            userRelatedId
            notes
            businessId
            contactSince
            updatedAt
            createdAt
            contactRelId
            contactRel {
              __typename
              id
              userID
              syncID
              contactType
              contactName
              firstName
              lastName
              email
              phone
              avatar
              avatarType
              jobTitle
              birthday
              starred
              frequent
              status
              pbProfileOptions
              userRelatedId
              notes
              businessId
              contactSince
              updatedAt
              createdAt
              contactRelId
              _version
              _deleted
              _lastChangedAt
            }
            addresses {
              __typename
              nextToken
              startedAt
            }
            connects {
              __typename
              nextToken
              startedAt
            }
            licence {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnUpdateContactAddressSubscription>;

  OnDeleteContactAddressListener: Observable<
    OnDeleteContactAddressSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteContactAddress {
        onDeleteContactAddress {
          __typename
          id
          contactId
          addressType
          street
          street1
          zipCode
          city
          state
          Country
          status
          updatedAt
          createdAt
          lat
          lng
          contact {
            __typename
            id
            userID
            syncID
            contactType
            contactName
            firstName
            lastName
            email
            phone
            avatar
            avatarType
            jobTitle
            birthday
            starred
            frequent
            status
            pbProfileOptions
            userRelatedId
            notes
            businessId
            contactSince
            updatedAt
            createdAt
            contactRelId
            contactRel {
              __typename
              id
              userID
              syncID
              contactType
              contactName
              firstName
              lastName
              email
              phone
              avatar
              avatarType
              jobTitle
              birthday
              starred
              frequent
              status
              pbProfileOptions
              userRelatedId
              notes
              businessId
              contactSince
              updatedAt
              createdAt
              contactRelId
              _version
              _deleted
              _lastChangedAt
            }
            addresses {
              __typename
              nextToken
              startedAt
            }
            connects {
              __typename
              nextToken
              startedAt
            }
            licence {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnDeleteContactAddressSubscription>;

  OnCreateMembershipListener: Observable<
    OnCreateMembershipSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateMembership {
        onCreateMembership {
          __typename
          id
          contactId
          noLicenses
          noClaimed
          groupId
          group
          prefix
          status
          updatedAt
          createdAt
          org {
            __typename
            id
            userID
            syncID
            contactType
            contactName
            firstName
            lastName
            email
            phone
            avatar
            avatarType
            jobTitle
            birthday
            starred
            frequent
            status
            pbProfileOptions
            userRelatedId
            notes
            businessId
            contactSince
            updatedAt
            createdAt
            contactRelId
            contactRel {
              __typename
              id
              userID
              syncID
              contactType
              contactName
              firstName
              lastName
              email
              phone
              avatar
              avatarType
              jobTitle
              birthday
              starred
              frequent
              status
              pbProfileOptions
              userRelatedId
              notes
              businessId
              contactSince
              updatedAt
              createdAt
              contactRelId
              _version
              _deleted
              _lastChangedAt
            }
            addresses {
              __typename
              nextToken
              startedAt
            }
            connects {
              __typename
              nextToken
              startedAt
            }
            licence {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnCreateMembershipSubscription>;

  OnUpdateMembershipListener: Observable<
    OnUpdateMembershipSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateMembership {
        onUpdateMembership {
          __typename
          id
          contactId
          noLicenses
          noClaimed
          groupId
          group
          prefix
          status
          updatedAt
          createdAt
          org {
            __typename
            id
            userID
            syncID
            contactType
            contactName
            firstName
            lastName
            email
            phone
            avatar
            avatarType
            jobTitle
            birthday
            starred
            frequent
            status
            pbProfileOptions
            userRelatedId
            notes
            businessId
            contactSince
            updatedAt
            createdAt
            contactRelId
            contactRel {
              __typename
              id
              userID
              syncID
              contactType
              contactName
              firstName
              lastName
              email
              phone
              avatar
              avatarType
              jobTitle
              birthday
              starred
              frequent
              status
              pbProfileOptions
              userRelatedId
              notes
              businessId
              contactSince
              updatedAt
              createdAt
              contactRelId
              _version
              _deleted
              _lastChangedAt
            }
            addresses {
              __typename
              nextToken
              startedAt
            }
            connects {
              __typename
              nextToken
              startedAt
            }
            licence {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnUpdateMembershipSubscription>;

  OnDeleteMembershipListener: Observable<
    OnDeleteMembershipSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteMembership {
        onDeleteMembership {
          __typename
          id
          contactId
          noLicenses
          noClaimed
          groupId
          group
          prefix
          status
          updatedAt
          createdAt
          org {
            __typename
            id
            userID
            syncID
            contactType
            contactName
            firstName
            lastName
            email
            phone
            avatar
            avatarType
            jobTitle
            birthday
            starred
            frequent
            status
            pbProfileOptions
            userRelatedId
            notes
            businessId
            contactSince
            updatedAt
            createdAt
            contactRelId
            contactRel {
              __typename
              id
              userID
              syncID
              contactType
              contactName
              firstName
              lastName
              email
              phone
              avatar
              avatarType
              jobTitle
              birthday
              starred
              frequent
              status
              pbProfileOptions
              userRelatedId
              notes
              businessId
              contactSince
              updatedAt
              createdAt
              contactRelId
              _version
              _deleted
              _lastChangedAt
            }
            addresses {
              __typename
              nextToken
              startedAt
            }
            connects {
              __typename
              nextToken
              startedAt
            }
            licence {
              __typename
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnDeleteMembershipSubscription>;
}
