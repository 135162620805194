import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Course} from "../../course.model";
import {CourseService} from "../../../course.service";
import {FormBuilder, FormGroup} from "@angular/forms";
interface LevelOptions {
  value: string;
  name: string;
}

interface AgesOptions {
  value: string;
  name: string;
  group: string;
  index: number;
  selected: boolean;
}
@Component({
  selector: 'admin-course-details',
  templateUrl: './admin-course-details.component.html',
  styleUrls: ['./admin-course-details.component.scss']
})
export class AdminCourseDetailsComponent implements OnInit {

  @Input()
  course: Course;

  @Output() formChanges: EventEmitter<any> = new EventEmitter<any>();

  courseDetailsForm: FormGroup;

  levelOptions: LevelOptions[] = [
    { value: 'Beginner', name: 'Beginner'},
    { value: 'Intermediate', name: 'Intermediate'},
    { value: 'Expert', name: 'Expert'},
    { value: 'All', name: 'All Levels'},
  ];

  ages: AgesOptions[] = [
    { value: 'GradeSchool', name: 'Grade preschool - 12', group: 'Tags', index: 1, selected: false},
    { value: 'Ages_6_10', name: 'Ages 6-10', group: 'Tags', index: 2, selected: false},
    { value: 'a612', name: 'Ages 5 - 12', group: 'Tags', index: 3, selected: false}
  ];
  selectedAges: any[];
  constructor(
      private _courseService: CourseService,
      private _formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.selectedAges = this.course.tags.split(',');
    this.courseDetailsForm = this.createCourseForm();
  }

  createCourseForm(): FormGroup {
    return this._formBuilder.group({
      id                  : [this.course.id],
      learnDescription    : [this.course.learnDescription],
      requirements    : [this.course.requirements],
      level           : [this.course.level],
      _version        : [this.course._version]
    });
  }

  saveData() {
    const attr = this.courseDetailsForm.getRawValue();
    attr['tags'] = this.selectedAges.toString();
    this.formChanges.emit(attr)
  }
}
