import {Component, Inject, OnInit} from '@angular/core';
import {Contact} from "../../contact.model";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {ReplaySubject, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {ContactService} from "../../contact.service";
import * as _ from "lodash";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-add-contact-as-user-popup',
  templateUrl: './add-contact-as-user-popup.component.html',
  styleUrls: ['./add-contact-as-user-popup.component.scss']
})
export class AddContactAsUserPopupComponent implements OnInit {

  contact: Contact;
  contactForm: FormGroup;
  foundUsers: any[];
  showOnAvatar: any;
  customAvatarStyle = {
    backgroundColor: '#ff8420',
    border: '1px solid #7e7e7e',
    borderRadius: '50%',
    color: '#155a7e'
  };
  private _onDestroy = new Subject<void>();
  public userCtrl: FormControl = new FormControl();
  public userFilterCtrl: FormControl = new FormControl();
  public filteredUsers: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  selectedUserId: string;
  selectedUserToRelate: any;
  constructor(
      private _bottomSheetRef: MatBottomSheetRef<AddContactAsUserPopupComponent>,
      @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
      private _formBuilder: FormBuilder,
      private _contactService: ContactService,
      private _matSnackBar: MatSnackBar
) {
    this.contact = new Contact(data);
    // console.log(this.contact);
    this.foundUsers = this.contact.pbProfileOptions.length>0 ? this.contact.pbProfileOptions : [];
    this.selectedUserId = null;
    this.showOnAvatar = this.contact.avatarType;
    this.contactForm = this.createContactForm();

  }

  ngOnInit() {
    this.userCtrl.setValue(this.foundUsers[0]);
    this.filteredUsers.next(this.foundUsers.slice());
    this.userFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterUsers();
        });
  }

  createContactForm(): FormGroup
  {
    return this._formBuilder.group({
      id                  : [this.contact.id],
      firstName           : [this.contact.firstName, Validators.required],
      lastName            : [this.contact.lastName,  Validators.required],
      email               : [this.contact.email, [Validators.required, Validators.email]],
      phone               : [this.contact.phone],
      avatarType          : [this.contact.avatarType]
    });
  }

  onSelectUser($event) {
    this.selectedUserId = $event.value;
    this.selectedUserToRelate = {};
    this.selectedUserToRelate.id =this.selectedUserId;
    this._contactService.getListGroupsForUser(this.selectedUserId)
        .then( result => {
          this.selectedUserToRelate.groups = (result && result.Groups) ? result.Groups : result ;
          this.selectedUserToRelate.user = _.find(this.foundUsers, {id: this.selectedUserId});
          this.selectedUserToRelate.orgMeta = null;
          if (this.selectedUserToRelate.user.orgId!==null) {
            return this._contactService.getContact(this.selectedUserToRelate.user.orgId)
          } else {
            return false;
          }
        }).then(orgData =>{
          if (!orgData) {
              this.selectedUserToRelate.orgMeta = this.contact.orgMeta;
          } else {
              this.selectedUserToRelate.orgRelated =  orgData;
          }
          this.selectedUserToRelate.orgId = this.contact.orgId;
          this.selectedUserToRelate.orgRelated =  orgData;
          this.selectedUserToRelate.isOrgRelated = this.selectedUserToRelate.user.orgId!==null;
          this.selectedUserToRelate.isInstructor = false;
          if (Array.isArray(this.selectedUserToRelate.groups)) {
              const found = _.find(this.selectedUserToRelate.groups, {GroupName: 'INSTRUCTOR'});
              if (found) {
                this.selectedUserToRelate.isInstructor = true;
              }
          }
          this.selectedUserToRelate.contact = this.contact;
    })
  }

    relateUser() {
        if (!this.selectedUserToRelate) {
            return;
        }
        this._contactService.updateUserAttributes(this.selectedUserToRelate)
          .then(newUser =>{
              this._bottomSheetRef.dismiss(newUser);
          }).catch(error=>{
            this.eventMesssage('Please try to fix the error o Call for Assitance!!!', 'error')
        })
    }

    addUser() {
        if (this.foundUsers.length >0 || !this.contact) {
          return;
        }
        this._contactService.createUser(this.contact)
            .then(newUser =>{
                this._bottomSheetRef.dismiss(newUser);
            }).catch(error=>{
                this.eventMesssage('Please try to fix the error o Call for Assitance!!!', 'error')
        })
    }

    cancelAll() {
        this._bottomSheetRef.dismiss(null );
    }

    private filterUsers() {
        if (!this.foundUsers) {
          return;
        }
        // get the search keyword
        let search = this.userFilterCtrl.value;
        if (!search) {
          this.filteredUsers.next(this.foundUsers.slice());
          return;
        } else {
          search = search.toLowerCase();
        }
        // filter the banks
        this.filteredUsers.next(
            this.foundUsers.filter(bank => bank.email.toLowerCase().indexOf(search) > -1)
        );
    }

    private eventMesssage( msg: string, typeMsg: string ) {
        const customMsg = typeMsg === 'error' ? 'OOPS:' + msg : 'Success!!' + msg;
        this._matSnackBar.open(customMsg, 'OK', {
            verticalPosition: 'top',
            duration        : 2000
        });
    }

}
