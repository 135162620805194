import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  Output, SimpleChanges,
} from '@angular/core';
import {Subject} from "rxjs";
import {Router} from "@angular/router";
import {QuizViewerService} from "../../../../quiz-viewer.service";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'quiz-player',
  templateUrl: './quiz-player.component.html',
  styleUrls: ['./quiz-player.component.scss']
})
export class QuizPlayerComponent implements OnInit, OnDestroy, OnChanges {

  private _unsubscribeAll: Subject<any>;

  @Input()
  courseAsset: any;

  @Output()
  onSummary: EventEmitter<any> = new EventEmitter();

  quizData: any[];
  question: any;
  questionIndex: number = 0;
  answer: any;

  // timer
  timerPerQuestion = 30;
  timerQuiz = 18 * 60;
  timeLeft: number;
  elapsedTime: number;
  interval: any;
  hours: number;
  minutes: number;
  seconds: number;

  constructor(
      private _router: Router,
      private quizService: QuizViewerService
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.questionIndex = 0;
    // this.quizData = this.courseAsset.quiz.questions.items;
    // const timeAprox = this.courseAsset.timeAprox.split(':');
    // this.timerQuiz = timeAprox[0] * 3600 + timeAprox[1] * 60;
    this.quizService.onStepChanged$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(step => {
          if (step==='player') {
            this.questionIndex = 0;
            this.resetTimer();
            this.getQuestion();
            this.countDown();
          }
        });
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('on changes');
    console.log(changes);
    if (changes.courseAsset.currentValue) {
      this.courseAsset = changes.courseAsset.currentValue;
      this.quizData = this.courseAsset.quiz.questions.items;
      const timeAprox = this.courseAsset.timeAprox.split(':');
      this.timerQuiz = timeAprox[0] * 3600 + timeAprox[1] * 60;
    }
  }

  private getQuestion(){
    this.question = this.quizData[this.questionIndex];
  }

  nextQuestion(optionsSelected){
    this.quizService.evaluateUserAnswer(this.questionIndex, optionsSelected, this.elapsedTime, this.timeLeft);
    if((this.questionIndex + 1) < this.quizData.length){
      this.questionIndex += 1;
      this.getQuestion();
      // this.stopTimer();
      // this.countDown();
    } else {
      this.stopTimer();
      this.onSummary.next(true)
    }
  }

  // timer
  private countDown() {
    this.resetTimer();
    this.interval = setInterval(() => {
      if(this.timeLeft-1 >= 0){
        this.elapsedTime += 1;
        this.timeLeft = this.timeLeft - 1;
        this.formatTimer();
        // console.log(this.elapsedTime);
      }else{
        this.stopTimer();
      }
    }, 1000);
  }

  private resetTimer(){
    this.timeLeft = this.timerPerQuestion;
    this.timeLeft = this.timerQuiz;
    this.elapsedTime = 0;
    this.formatTimer();
  }

  // second to hh:mm:ss style
  formatTimer(){
    this.hours = Math.floor(this.timeLeft / 60 / 60);
    this.minutes = Math.floor(this.timeLeft / 60) - (this.hours * 60);
    this.seconds = this.timeLeft % 60;
  }

  private stopTimer(){
    clearInterval(this.interval);
  }

  ngOnDestroy(): void {
    this.stopTimer();

    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
