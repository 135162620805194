import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import {debounceTime, switchMap, takeUntil, tap} from 'rxjs/operators';
import { FuseConfigService } from '@fuse/services/config.service';
import {FormBuilder, FormGroup} from "@angular/forms";
import {APIService, elStatus, ModelCourseFilterInput} from "../../../app/API.service";
import {Router} from "@angular/router";
import {APICustomService} from "../../../app/API.customize.service";

@Component({
    selector   : 'fuse-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls  : ['./search-bar.component.scss']
})
export class FuseSearchBarComponent implements OnInit, OnDestroy
{
    collapsed: boolean;
    fuseConfig: any;
    isLoading = false;
    filteredItems: any[] = [];

    @Output()
    input: EventEmitter<any>;

    searchForm: FormGroup;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param fb
     * @param customApi
     * @param _router
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private fb: FormBuilder,
        private customApi: APICustomService,
        private _router: Router
    )
    {
        // Set the defaults
        this.input = new EventEmitter();
        this.collapsed = true;

        // Set the private defaults
        this._unsubscribeAll = new Subject();


    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {

        this.searchForm = this.fb.group({
            qCourse: null
        })
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (config) => {
                    this.fuseConfig = config;
                }
            );

        this.searchForm
            .get('qCourse')
            .valueChanges
            .pipe(
                debounceTime(300),
                tap(() => this.isLoading = true),
                switchMap(value => this.getCourses(value))
            )
            .subscribe(coursesList => {
                this.filteredItems = coursesList;
                this.isLoading = false;
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Collapse
     */
    collapse(): void
    {
        this.collapsed = true;
    }

    /**
     * Expand
     */
    expand(): void
    {
        this.collapsed = false;
    }

    /**
     * Search
     *
     * @param event
     */
    search(event): void
    {
        this.input.emit(event.target.value);
    }

    displayFn(course: any) {
        // console.log(course);
    }

    onEnter(course: any) {
        console.log(course);
        if (course.source.value) {
            const url = `school/courses/${course.source.value.id}`;
            this._router.navigate([url]);
        }
    }

    getCourses(searchTxt?: string): Promise<any> {
        let filter: ModelCourseFilterInput = {
            status: {eq: elStatus.active},
            title : { contains: searchTxt},
            or: [{
                description: { contains: searchTxt}
            }]
        };
        return new Promise((resolve, reject) => {
            this.customApi.ListCoursesSearchBar(filter, 10)
                .then((result) => {
                    // this.nextToken = !_.isEmpty(result['nextToken']) ? result['nextToken'] : null;
                    resolve( result.items);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    }

}
