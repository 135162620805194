import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { hmrBootstrap } from 'hmr';
import AWSConfig from './aws-exports';
import Auth from '@aws-amplify/auth';
import Storage from '@aws-amplify/storage';
import Api from '@aws-amplify/api';
import PubSub from '@aws-amplify/pubsub';
import Amplify from '@aws-amplify/core';
import {DataStore} from '@aws-amplify/datastore';
import { AmazonAIPredictionsProvider } from '@aws-amplify/predictions';

Auth.configure(AWSConfig);
Storage.configure(AWSConfig);
Api.configure(AWSConfig);
PubSub.configure(AWSConfig);
Amplify.configure(AWSConfig);
Amplify.addPluggable(new AmazonAIPredictionsProvider());
DataStore.configure({
  maxRecordsToSync: 3000,
  fullSyncInterval: 90
});



if ( environment.production )
{
    enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if ( environment.hmr )
{
    if ( module['hot'] )
    {
        hmrBootstrap(module, bootstrap);
    }
    else
    {
        console.error('HMR is not enabled for webpack-dev-server!');
        console.log('Are you using the --hmr flag for ng serve?');
    }
}
else
{
    bootstrap().catch(err => console.error(err));
}
