import { InjectionToken } from '@angular/core';

export interface StudentsOptions {
    accessKeyId: string;
    secretAccessKey: string;
    UserPoolId?: any;
    AttributesToGet?: any;
    region: string;
    pageSize?: any;
    usersAvailable: boolean;
    bucketName: string;
}

export const defaultStudentsOptions: any = {
  accessKeyId: 'AKIAY6NY73HUV5NXALUR',
  secretAccessKey: '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',
  UserPoolId: 'us-east-1_I3aBWHf14',
  region: 'us-east-1',
  bucketName: 'kidsthatcode-media-resources',
  AttributesToGet: [],
  pageSize: 10,
  usersAvailable: false
};

export const STUDENTS_MANAGER_OPTIONS = new InjectionToken<StudentsOptions>('User Manager Options');

