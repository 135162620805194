export class NotifyModel
{
  id: string;
  userId: string;
  typeNotify?: string;
  body?: string;
  subject?: string;
  localUrl?: string;
  renotify?: boolean;
  silent?: boolean;
  updatedAt?: string;

  _version?: any;
  /**
   * Constructor
   *
   * @param notify
   */
  constructor(notify?)
  {
    notify = notify || {};
    this.id = notify.id || null;
    this.userId = notify.userId || null;
    this.typeNotify = notify.typeNotify || '*';
    this.body = notify.body || '';
    this.subject = notify.subject || '';
    this.localUrl = notify.localUrl || ' ';
    this.renotify = notify.renotify || false;
    this.silent = notify.silent || false;
    this.updatedAt = notify.updatedAt || null;
    this._version = notify._version || null;

  }

}
