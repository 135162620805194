import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '../../../../../../@fuse/animations';
import {FormControl, FormGroup} from '@angular/forms';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material';
import {ContentResourcesService} from '../../content-resources.service';
import {ContentResource} from '../../content-resource.model';

@Component({
  selector: 'edit-content-file-resource',
  templateUrl: './edit-content-file-resource.component.html',
  styleUrls: ['./edit-content-file-resource.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class EditContentFileResourceComponent implements OnInit {

  resourceForm: FormGroup;
  resource: ContentResource;
  actionType: string;
  imagUrl: string;

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<EditContentFileResourceComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private _resourceListService: ContentResourcesService,
  ) {
    this.actionType = data.actionType;
    this.resource = this.data.data ;
  }

  ngOnInit() {
    this.resourceForm = this.createResourceForm();
    if (this.actionType === 'edit' && this.resource.fileName.length > 5)  {
      console.log(this.resource);
      this._resourceListService.getPresignedFile(this.resource.fileName, this.resource.courseAssetId)
        .then(res => {
          this.imagUrl = res;
        });
    }
  }
  /**
   * Create compose form
   *
   * @returns {FormGroup}
   */
  createResourceForm(): FormGroup
  {
    return new FormGroup({
      id : new FormControl(this.resource.id),
      courseId: new FormControl(this.resource.courseId),
      description: new FormControl(this.resource.description),
      _version:  new FormControl(this.resource._version),
    });
  }

  closeDialog(resourceForm) {
    this._bottomSheetRef.dismiss({data: resourceForm, actionType: this.actionType});
  }

}
