import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import {NotifyModel} from './notification.model';
import {NotifyListService} from './notify-list.service';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {FuseConfirmDialogComponent} from '../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector     : 'app-notification',
  templateUrl  : './notification.component.html',
  styleUrls    : ['./notification.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationComponent implements OnInit, OnDestroy {
  hasSelectedRows: boolean;
  isIndeterminate: boolean;
  filters: any[];
  searchInput: FormControl;
  currentNotify: NotifyModel;

  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param _notifyListService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param _matDialog
   * @param _snackBar
   */
  constructor(
    private _notifyListService: NotifyListService,
    private _fuseSidebarService: FuseSidebarService,
    public _matDialog: MatDialog,
    private _snackBar: MatSnackBar
  )
  {
    // Set the defaults
    this.searchInput = new FormControl('');

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void
  {
    this._notifyListService.onSelectedNotifiesChanged$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(selectedNotifications => {
        setTimeout(() => {
          this.hasSelectedRows = selectedNotifications.length > 0;
          this.isIndeterminate = (selectedNotifications.length !== this._notifyListService.notify.length && selectedNotifications.length > 0);
        }, 0);
      });


    this._notifyListService.onFilterChanged$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(folders => {
        this.filters = this._notifyListService.filters;
      });

    this._notifyListService.onCurrentNotifyChanged$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(currentNotify => {
        if ( !currentNotify )
        {
          this.currentNotify = null;
        }
        else
        {
          this.currentNotify = currentNotify;
        }
      });

    this.searchInput.valueChanges.pipe(
      takeUntil(this._unsubscribeAll),
      debounceTime(300),
      distinctUntilChanged()
    )
      .subscribe(searchText => {
        this._notifyListService.onSearchTextChanged.next(searchText);
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  saveData($event) {
    this._notifyListService.saveData($event)
      .then((res) => {
        this.confirmationMsg(`Success: Notification has been saved!!`);
      }).catch((error) => {
      this.confirmationMsg('Error: Please Try Again, otherwise contact IT!!');
    });
  }

  togggleResend($event) {
    this._notifyListService.toggleResend($event)
      .then((res) => {
        this.confirmationMsg(`Success: Notification has been Sent!!`);
      }).catch((error) => {
      this.confirmationMsg('Error: Please Try Again, otherwise contact IT!!');
    });
  }

  togggleSilent($event) {
    this._notifyListService.toggleSilent($event)
      .then((res) => {
        this.confirmationMsg(`Success: Notification has been saved!!`);
      }).catch((error) => {
      this.confirmationMsg('Error: Please Try Again, otherwise contact IT!!');
    });
  }
  /**
   * Toggle select all
   */
  toggleSelectAll(): void
  {
    this._notifyListService.toggleSelectAll();
  }


  /**
   * Deselect current mail
   */
  deselectCurrentNotification(): void
  {
    this._notifyListService.onCurrentNotifyChanged.next(null);
  }

  sendSelectedNotifications(): void
  {
    this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });
    this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to send/Resend?';

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if ( result ) {
        const allNotifies = this._notifyListService.getSelectedNotifications();
        allNotifies.forEach(row => {
          this._notifyListService.toggleSilent(row)
            .then((res) => {
              this._notifyListService.toggleSelectedNotification(res.id);
              this._notifyListService.onCurrentNotifyChanged.next(null);
              this.confirmationMsg('Success: Event has been sent Successfully!!');
            }).catch((error) => {
            this.confirmationMsg('Error: Please Try Again, otherwise contact IT!!');
          });
        });
      }
      this.confirmDialogRef = null;
    });
  }

  /**
   * Set folder on selected mails
   *
   */
  deleteSelectedNotifications(): void {
    this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });
    this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if ( result ) {
        const allNotifies = this._notifyListService.getSelectedNotifications();
        allNotifies.forEach(row => {
          this._notifyListService.deleteNotify(row)
            .then((res) => {
              this._notifyListService.toggleSelectedNotification(res.id);
              this._notifyListService.onCurrentNotifyChanged.next(null);
              this.confirmationMsg('Success: Event has been deleted Successfully!!');
            }).catch((error) => {
              this.confirmationMsg('Error: Please Try Again, otherwise contact IT!!');
          });
        });
      }
      this.confirmDialogRef = null;
    });
  }

  /**
   * Toggle the sidebar
   *
   * @param name
   */
  toggleSidebar(name): void
  {
    this._fuseSidebarService.getSidebar(name).toggleOpen();
  }

  confirmationMsg(msgIn: string) {
    this._snackBar.open(msgIn, 'End now', {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
}
