import { NgModule } from '@angular/core';

import {VerticalLayoutModule} from './vertical/vertical.module';
import {HorizontalLayoutModule} from './horizontal/horizontal.module';

@NgModule({
    imports: [
        VerticalLayoutModule,
        HorizontalLayoutModule
    ],
    exports: [
        VerticalLayoutModule,
        HorizontalLayoutModule
    ]
})
export class LayoutModule
{
}
