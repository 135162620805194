import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatBottomSheet } from '@angular/material';
import { CourseAsset } from 'app/main/admin/course-content/media.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { quizContent } from '../../quiz.model';
import { FileUploadComponent } from '../file-upload/file-upload.component';

@Component({
  selector: 'quiz-config',
  templateUrl: './quiz-config.component.html',
  styleUrls: ['./quiz-config.component.scss']
})
export class QuizConfigComponent implements OnInit {

  @Input()
  courseAsset: CourseAsset;

  @Input()
  pageType: string;

  @Output() formChanges: EventEmitter<any> = new EventEmitter<any>();

  configForm: FormGroup;
  quizContent: quizContent;

  constructor(
    private _formBuilder: FormBuilder,
    private _bottomSheet: MatBottomSheet,
  ) { }

  ngOnInit() {
    this.quizContent = this.courseAsset.quiz;
    this.configForm = this.createConfigForm();
  }

  createConfigForm(): FormGroup {
    return this._formBuilder.group({
      id: [this.quizContent.id],
      score: [this.quizContent.score],
      questionsRandom: [this.quizContent.questionsRandom],
      passingScore: [this.quizContent.passingScore],
      noAttempts: [this.quizContent.noAttempts]
    });
  }

  saveData(value:any){
    console.log(value);
    this.formChanges.emit(value);
  }

  uploadMedia() {
    const dataToSave = this._bottomSheet.open(FileUploadComponent, {
      // data: this.media
    });
  }

}
