export class User
{
    sub?: string;
    name?: string;
    given_name?: string;
    family_name?: string;
    avatar?: string;
    nickname?: string;
    avatarType: string;
    company?: string;
    jobTitle: string;
    email: string;
    phone: string;
    address?: string;
    birthday: string;
    notes?: string;
    theme: string;
    language?: string;
    email_verified?: boolean;
    phone_number_verified?: boolean;

    /**
     * Constructor
     *
     * @param user
     */
    constructor(user?)
    {
        {
            user = user || {};
            this.sub = user.sub || '';
            this.name = user.name || 'Anonymous';
            this.given_name = user.given_name || '';
            this.family_name = user.family_name || '';
            this.avatarType = user.avatarType || 'gd';
            this.avatar = user.avatar || 'assets/images/avatars/profile.jpg';
            this.nickname = user.nickname || '';
            this.company = user.company || '';
            this.jobTitle = user.jobTitle || '';
            this.email = user.email || '';
            this.phone = user.phone || '';
            this.address = user.address || '';
            this.birthday = user.birthday || '';
            this.email_verified = user.email_verified || false;
            this.phone_number_verified = user.phone_number_verified || false;
        }
    }
}
