import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';

import {getDeepFromObject} from '../../auth/helpers';
import {AuthService} from '../../auth/services/auth.service';
import * as _ from 'lodash';
import {APIService, ModelContactFilterInput, UpdateContactInput} from "../../../API.service";
import {ContactStatus} from "../../../../models";
import {CONTACT_MANAGER_OPTIONS} from "./contact.options";

@Injectable()
export class ContactsService implements Resolve<any>
{
    onContactsChanged: BehaviorSubject<any>;
    onLoading: Subject<any>;
    onSelectedContactsChanged: BehaviorSubject<any>;
    onUserDataChanged: BehaviorSubject<any>;
    onSearchTextChanged: Subject<any>;
    onFilterChanged: Subject<any>;
    nextPageToken: BehaviorSubject<any>;

    contacts: any[];
    user: any;
    selectedContacts: string[] = [];
    pageSize: number;
    nextToken: string;

    searchText: string;
    filterBy: string;
    currentUser: any;

    bucket: any;

    /**
     * Constructor
     *
     * @param options
     * @param api
     * @param _authService
     */
    constructor(
        @Inject(CONTACT_MANAGER_OPTIONS) protected options = {},
        private api: APIService,
        private _authService: AuthService
    )
    {
        // Set the defaults
        this.onContactsChanged = new BehaviorSubject([]);
        this.onSelectedContactsChanged = new BehaviorSubject([]);
        this.onUserDataChanged = new BehaviorSubject([]);
        this.onLoading = new Subject();
        this.onSearchTextChanged = new Subject();
        this.onFilterChanged = new Subject();
        this.nextToken = null;
        this.nextPageToken = new BehaviorSubject(this.nextToken);
        this.onLoading.next(false);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getContacts()
            ]).then(
                ([contacts]) => {
                    this.onSearchTextChanged.subscribe(searchText => {
                        this.searchText = searchText;
                        of(this.getContacts());
                    });

                    this.onFilterChanged.subscribe(filter => {
                        this.filterBy = filter;
                        of(this.getContacts());
                    });

                    resolve();

                },
                reject
            );
        });
    }


    getContacts(pageSize?: number, nextToken?: string): Promise<any[]> {
        this.onLoading.next(true);
        this.pageSize  = pageSize > 0 ? pageSize : this.getConfigValue('pageSize');
        this.nextToken = nextToken ? nextToken : null;
        return new Promise((resolve, reject) => {
            this._authService.currentUser()
                .then((user) => {
                    this.currentUser = user;
                    const userInfo = this.currentUser.attributes;
                    this.onUserDataChanged.next(userInfo);
                    let filter: ModelContactFilterInput;
                    filter = {
                        userID: {eq: userInfo.sub}
                    };
                    if (this.searchText) {
                        filter['contactName'] = {contains: this.searchText};
                    }
                    if (this.filterBy && this.filterBy === 'frequent') {
                        filter['frequent'] = {eq: true};
                    }
                    if (this.filterBy && this.filterBy === 'starred') {
                        filter['starred'] = {eq: true};
                    }
                    this.api.ListContacts(filter, this.pageSize, this.nextToken)
                        .then((contacts) => {
                            console.log(contacts);
                            this.nextToken = !_.isEmpty(contacts['nextToken']) ? contacts['nextToken'] : null;
                            this.nextPageToken.next(this.nextToken );
                            this.contacts = contacts.items;
                            this.onContactsChanged.next(this.contacts);
                            this.onLoading.next(false);
                            resolve(this.contacts);
                        })
                        .catch((err) => reject(err));
            }).catch((err) => reject(err));
        });
    }


    /**
     * Toggle selected contact by id
     *
     * @param id
     */
    toggleSelectedContact(id): void
    {
        // First, check if we already have that contact as selected...
        if ( this.selectedContacts.length > 0 )
        {
            const index = this.selectedContacts.indexOf(id);

            if ( index !== -1 )
            {
                this.selectedContacts.splice(index, 1);

                // Trigger the next event
                this.onSelectedContactsChanged.next(this.selectedContacts);

                // Return
                return;
            }
        }

        // If we don't have it, push as selected
        this.selectedContacts.push(id);

        // Trigger the next event
        this.onSelectedContactsChanged.next(this.selectedContacts);
    }

    /**
     * Toggle select all
     */
    toggleSelectAll(): void
    {
        if ( this.selectedContacts.length > 0 )
        {
            this.deselectContacts();
        }
        else
        {
            this.selectContacts();
        }
    }

    /**
     * Select contacts
     *
     * @param filterParameter
     * @param filterValue
     */
    selectContacts(filterParameter?, filterValue?): void
    {
        this.selectedContacts = [];

        // If there is no filter, select all contacts
        if ( filterParameter === undefined || filterValue === undefined )
        {
            this.selectedContacts = [];
            this.contacts.map(contact => {
                this.selectedContacts.push(contact.id);
            });
        }

        // Trigger the next event
        this.onSelectedContactsChanged.next(this.selectedContacts);
    }

    /**
     * Update contact
     *
     * @returns {Promise<any>}
     * @param contact
     */
    toggleStar(contact: any ): Promise<any> {
        const newStarred = contact.starred !== true;
        return new Promise((resolve, reject) => {
            const userInfo = this._authService.getUserId();
            const userId = userInfo.getUsername();
            const payload: UpdateContactInput = {
                id: contact.id,
                userID: userId,
                starred: newStarred,
                _version: contact._version
            };
            this.api.UpdateContact(payload)
                .then((contact) => {
                    of(this.getContacts());
                    resolve(contact);
                }).catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    /**
     * Update contact
     *
     * @returns {Promise<any>}
     * @param contact
     */
    toggleFrequent(contact: any ): Promise<any> {
        const newFrequent = contact.frequent !== true;
        return new Promise((resolve, reject) => {
            const userInfo = this._authService.getUserId();
            const userId = userInfo.getUsername();
            const payload: UpdateContactInput = {
                id: contact.id,
                userID: userId,
                frequent: newFrequent,
                _version: contact._version
            };
            this.api.UpdateContact(payload)
                .then((contact) => {
                    of(this.getContacts());
                    resolve(contact);
                }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    }

    /**
     * Deselect contacts
     */
    deselectContacts(): void
    {
        this.selectedContacts = [];

        // Trigger the next event
        this.onSelectedContactsChanged.next(this.selectedContacts);
    }

    /**
     * Delete contact
     *
     * @param contact
     */
    deleteContact(contact: any): Promise<any>
    {
        const newStatus = contact.status === 'active' || contact.status === 'new' ?
            ContactStatus.INACTIVE : ContactStatus.ACTIVE
        return new Promise((resolve, reject) => {
            const userInfo = this._authService.getUserId();
            const userId = userInfo.getUsername();
            const payload: UpdateContactInput = {
                id: contact.id,
                userID: userId,
                status: newStatus,
                _version: contact._version
            };
            this.api.UpdateContact(payload)
                .then((contact) => {
                    of(this.getContacts());
                    resolve(contact);
                }).catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    /**
     * Delete selected contacts
     */
    deleteSelectedContacts(): void
    {
        const promises = [];
        for ( const contactId of this.selectedContacts )
        {
            const contact = this.contacts.find(_contact => {
                return _contact.id === contactId;
            });
            if (contact) {
                promises.push(this.deleteContact(contact));
            }
        }
        Promise.all(promises)
            .then(response => {
                console.log(response)
                response.forEach(contact => {
                    const contactIndex = this.contacts.indexOf(contact);
                    this.contacts.splice(contactIndex, 1);
                    this.onContactsChanged.next(this.contacts);
                    this.deselectContacts();
                })
            }) // ["Completed in 1000", "Rejected in 2000", "Completed in 3000"]
            .catch(error => console.log(`Error in executing ${error}`))
    }

    getConfigValue(key: string): any {
        return getDeepFromObject(this.options, key, null);
    }

}
