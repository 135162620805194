import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {StudentsService} from "../../students.service";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'student-status-cell',
  templateUrl: './student-status-cell.component.html',
  styleUrls: ['./student-status-cell.component.scss']
})
export class StudentStatusCellComponent  implements OnInit, OnDestroy {

@Input('courseId') courseId: string;

private _onDestroy = new Subject<void>();
  status = "-----";

  constructor(
      private _studentsService: StudentsService
) { }

  ngOnInit() {
    this._studentsService.onPercentageChange$
        .pipe(takeUntil(this._onDestroy))
        .subscribe((result: any) => {
          if(result && this.courseId === result.courseId){
            const progressValue = parseInt(result.perProgress);
            if(progressValue === 0){
              this.status = "Enrolled"
            }else if(progressValue > 0 && progressValue < 100){
              this.status = "In Progress"
            }else if(progressValue === 100){
              this.status = "Completed"
            }
          }
        })
  }

  ngOnDestroy(){
    this._onDestroy.next();
    this._onDestroy.complete();
  }

}
