import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {fromEvent, of, Subject} from 'rxjs';
import {Router} from '@angular/router';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {CourseCatalogService} from '../course-catalog.service';
import {elSubStatus} from '../../../API.service';
import {fuseAnimations} from '../../../../@fuse/animations';
import {AuthService} from "../../auth/services/auth.service";

@Component({
  selector: 'app-catalog-explorer',
  templateUrl: './catalog-explorer.component.html',
  styleUrls: ['./catalog-explorer.component.scss'],
  animations : fuseAnimations
})
export class CatalogExplorerComponent implements OnInit, OnDestroy {

  categories: any[];
  courses: any[];
  coursesFilteredByCategory: any[];
  filteredCourses: any[];
  currentCategory: string;
  searchTerm: string;
  nextPage: any;
  currentUserId: string;

  @ViewChild('filter', {static: true})
  filter: ElementRef;
  filterTxt: string;
  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private router: Router,
    private _academyCoursesService: CourseCatalogService,
    private _auth: AuthService,

  ) {
    // Set the defaults
    this.currentCategory = 'all';
    this.searchTerm = '';
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void
  {
    this._auth.loggedUser$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe( userInfo => {
            this.currentUserId = userInfo.username;
        })

    this._academyCoursesService.nextPageToken$
      .subscribe(nextToken => {
        this.nextPage = nextToken;
      });
    // Subscribe to categories
    this._academyCoursesService.onCategoriesChanged$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(categories => {
        this.categories = categories;
      });

    // Subscribe to courses
    this._academyCoursesService.onCoursesChanged$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(courses => {
        // console.log(courses);
        this.filteredCourses = this.coursesFilteredByCategory = this.courses = courses;
      });

    fromEvent(this.filter.nativeElement, 'keyup')
      .pipe(
        debounceTime(550),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.filterTxt  = this.filter.nativeElement.value;
        of(this._academyCoursesService.getCourses( this.filterTxt , this.nextPage));
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  gotoNextPage(nextPage): void {
    this._academyCoursesService.gotoNextPage(this.filterTxt, this.nextPage);
  }
  /**
   * Filter courses by category
   */
  filterCoursesByCategory(): void
  {
    // Filter
    if ( this.currentCategory === 'all' )
    {
      this.coursesFilteredByCategory = this.courses;
      this.filteredCourses = this.courses;
    }
    else
    {
      this.coursesFilteredByCategory = this.courses.filter((course) => {
        return course.category.id === this.currentCategory;
      });
      this.filteredCourses = [...this.coursesFilteredByCategory];
    }
  }

}
