import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import {ReplaySubject, Subject} from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';
import * as _ from 'lodash';
import {Contact} from '../contact.model';
import {ContactService} from '../contact.service';
import {parsePhoneNumberFromString} from "libphonenumber-js";

interface ContactStatus {
    value: string;
    name: string;
}

interface AvatarType {
    value: string;
    name: string;
}

@Component({
    selector     : 'contact-details',
    templateUrl  : './contact.component.html',
    styleUrls    : ['./contact.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class ContactComponent implements OnInit, OnDestroy
{
    contact: Contact;
    pageType: string;
    contactForm: FormGroup;
    pbForm: FormGroup;

    base64Img: any;

    showOnAvatar: string;
    showOnRelOther: boolean;

    avatarOptions: AvatarType[] = [
        { value: 'ni', name: 'Name Initials'},
        { value: 'gd', name: 'Email'},
        { value: 'img', name: 'Image File'}
    ];

    statusOptions: ContactStatus[] = [
        { value: 'active', name: 'Active'},
        { value: 'inactive', name: 'Inactive'}
    ];


    customAvatarStyle = {
        backgroundColor: '#ff8420',
        border: '1px solid #7e7e7e',
        borderRadius: '50%',
        color: '#155a7e'
    };

    public statusFilterCtrl: FormControl = new FormControl();
    public filteredStatus: ReplaySubject<ContactStatus[]> = new ReplaySubject<ContactStatus[]>(1);
    private _unsubscribeAll: Subject<any>;

    public filteredAvatar: ReplaySubject<AvatarType[]> = new ReplaySubject<AvatarType[]>(1);
    public avatarFilterCtrl: FormControl = new FormControl();
    /**
     * Constructor
     *
     * @param _contactService
     * @param {FormBuilder} _formBuilder
     * @param {Location} _location
     * @param {MatSnackBar} _matSnackBar
     */
    constructor(
        private _contactService: ContactService,
        private _formBuilder: FormBuilder,
        private _location: Location,
        private _matSnackBar: MatSnackBar
    )
    {
        // Set the default
        this.contact = new Contact();

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.filteredStatus.next(this.statusOptions.slice());
        this.filteredAvatar.next(this.avatarOptions.slice());
        this.showOnAvatar = this.avatarOptions[2].value;
        this.showOnRelOther = false;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {

        this.statusFilterCtrl.valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this._filterStatus();
            });
        this.avatarFilterCtrl.valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this._filterAvatarTypes();
            });

        // Subscribe to update product on changes
        this._contactService.onContactChanged$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(contact => {
                console.log(contact);
                if ( !_.isEmpty(contact)  ) {
                    console.log(contact);
                    this.contact = new Contact(contact);
                    this.contact['base64Img'] = contact.avatar.length > 0 ? contact.avatar : '';
                    this.base64Img = this.contact['base64Img'];
                    this.pageType = 'edit';
                    this.contactForm = this.createContactForm();
                    this.pbForm = this.createPBForm();
                    this.showOnAvatar = this.contact.avatarType;

                } else {
                    this.pageType = 'new';
                    this.contact = new Contact();
                    this.contact['base64Img'] = null;
                    this.contactForm = this.createContactForm();
                    this.pbForm = this.createPBForm();
                    this.contactForm.controls['status'].setValue( this.statusOptions[0].value);
                    this.contactForm.controls['avatarType'].setValue( this.avatarOptions[2].value);
                    this.showOnRelOther = false;
                }

            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    onChangeAvatarType($event) {
        this.showOnAvatar = $event.value;
    }

    uploadError($event) {
        this._matSnackBar.open($event, 'OK', {
            verticalPosition: 'top',
            duration        : 2000
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create product form
     *
     * @returns {FormGroup}
     */
    createContactForm(): FormGroup
    {
        return this._formBuilder.group({
            id                  : [this.contact.id],
            firstName           : [this.contact.firstName, Validators.required],
            lastName            : [this.contact.lastName,  Validators.required],
            email               : [this.contact.email, [Validators.required, Validators.email]],
            phone               : [this.contact.phone],
            birthday            : [this.contact.birthday, Validators.required],
            avatarType          : [this.contact.avatarType],
            status              : [this.contact.status],
            _version           : [this.contact._version]
        });
    }

    createPBForm(): FormGroup
    {
        return this._formBuilder.group({
            id         : [this.contact.id],
            notes      : [this.contact.notes],
            _version   : [this.contact._version]
        });
    }

    /**
     * Save product
     */
    saveContact(): void
    {
        const data = this.contactForm.getRawValue();
        data.avatar = this.base64Img || 'assets/images/avatars/profile.jpg';
        const phoneNumber = parsePhoneNumberFromString(data.phone);
        if (!phoneNumber || !phoneNumber.isValid()) {
            this.eventMesssage('A Valid Phone Number is Required!', 'error');
            return;
        }
        this._contactService.save(data)
            .then((res) => {
                // Show the success message
                this._matSnackBar.open('Contact Saved Sucessfully', 'OK', {
                    verticalPosition: 'top',
                    duration        : 2000
                });
            }).catch(err => {
                this._matSnackBar.open('OOPS! Something is wrong .. Please try Again.', 'OK', {
                    verticalPosition: 'top',
                    duration        : 2000
                });
                console.log(err.errors[0].message);
            });
    }

    /**
     * Save product
     */
    saveContactPB(): void
    {
        if (this.pageType !== 'edit') {
            return;
        }
        const data = this.pbForm.getRawValue();
        this._contactService.savePB(data)
            .then((res) => {
                // Show the success message
                this._matSnackBar.open('Contact Saved Sucessfully', 'OK', {
                    verticalPosition: 'top',
                    duration        : 2000
                });
            }).catch(err => {
                this._matSnackBar.open('OOPS! Something is wrong .. Please try Again.', 'OK', {
                    verticalPosition: 'top',
                    duration        : 2000
                });
                console.log(err.errors[0].message);
        });
    }


    /**
     * Add product
     */
    addContact(): void
    {
        const data = this.contactForm.getRawValue();
        data.avatar = this.base64Img || 'assets/images/avatars/profile.jpg';
        const phoneNumber = parsePhoneNumberFromString(data.phone);
        if (!phoneNumber || !phoneNumber.isValid()) {
            this.eventMesssage('A Valid Phone Number is Required!', 'error');
            return;
        }
        this._contactService.add(data)
            .then((res) => {
                this._matSnackBar.open('Contact added', 'OK', {
                    verticalPosition: 'top',
                    duration        : 2000
                });
            }).catch(err => {
                this._matSnackBar.open('OOPS! Something is wrong .. Please try Again.', 'OK', {
                    verticalPosition: 'top',
                    duration        : 2000
                });
                console.log(err.errors[0].message);
            });
    }


    onReaderEnd($event) {
        this.base64Img = $event;
    }

    onSelectedFilesChanged($event) {
        const fileIn = $event[0];
        if (!fileIn) {
            return;
        }
        this.base64Img = '';
    }


    private _filterStatus() {
        if (!this.statusOptions) {
            return;
        }
        // get the search keyword
        let search = this.statusFilterCtrl.value;
        if (!search) {
            this.filteredStatus.next(this.statusOptions.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the banks
        this.filteredStatus.next(
            this.statusOptions.filter(lang => lang.name.toLowerCase().indexOf(search) > -1)
        );
    }


    private _filterAvatarTypes() {
        if (!this.avatarOptions) {
            return;
        }
        // get the search keyword
        let search = this.avatarFilterCtrl.value;
        if (!search) {
            this.filteredAvatar.next(this.avatarOptions.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the banks
        this.filteredAvatar.next(
            this.avatarOptions.filter(avatar => avatar.name.toLowerCase().indexOf(search) > -1)
        );
    }

    private eventMesssage( msg: string, typeMsg: string ) {
        const customMsg = typeMsg === 'error' ? 'OOPS:' + msg : 'Success!!' + msg;
        this._matSnackBar.open(customMsg, 'OK', {
            verticalPosition: 'top',
            duration        : 2000
        });
    }

}
