import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {StudentsService} from "../../students.service";
import {fuseAnimations} from "../../../../../../@fuse/animations";

export interface ProgressElement {
  title: string;
  level: string;
  id: string;
  status: string;
}

@Component({
  selector: 'student-progress-view',
  templateUrl: './student-progress-view.component.html',
  styleUrls: ['./student-progress-view.component.scss'],
  animations: fuseAnimations
})
export class StudentProgressViewComponent implements OnChanges{

  @Input('userId') userId: string;

  elementData: ProgressElement[] = [];
  displayedColumns: string[] = ['title', 'level', 'id', 'status'];
  dataSource: any;


  constructor(
      private _studenService: StudentsService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.userId.currentValue){
      this.userId = changes.userId.currentValue;
      this._studenService.getCourses(this.userId)
          .then((res:any) => {
            this.elementData = res.items;
            this.dataSource = this.elementData;
          })
    }
  }
}
