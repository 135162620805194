import {Component, ElementRef, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FuseConfirmDialogComponent} from "../../../../../@fuse/components/confirm-dialog/confirm-dialog.component";
import {CourseCatalogService} from "../../course-catalog.service";
import {elSubStatus} from "../../../../API.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'msc-course-price',
  templateUrl: './msc-course-price.component.html',
  styleUrls: ['./msc-course-price.component.scss'],
})
export class MscCoursePriceComponent implements OnInit, OnChanges {
  @Input()
  courseDetails: any;

  @Input()
  userSubs: any;

  @ViewChild('toolbarButtons', {static: false}) toolbarButtons: ElementRef;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

  color = 'primary';
  mode = 'indeterminate';
  displayProgressSpinner = false;
  constructor(
      private router: Router,
      public _matDialog: MatDialog,
      private _snackBar: MatSnackBar,
      private courseCatalogService: CourseCatalogService,
  ) { }

  ngOnInit() {
    console.log(this.userSubs)
    this.displayProgressSpinner = false;

  }

  ngOnChanges() {}

  restartResume($event) {
    this.router.navigate(['my/courses/' + $event.id]);
  }

  enrollCourse($event) {
    this.displayProgressSpinner = true;
    const updatePos = {
      top : this.toolbarButtons.nativeElement.getBoundingClientRect().top,
      right : this.toolbarButtons.nativeElement.getBoundingClientRect().right
    };
    const rightMostPos = window.innerWidth - Number(updatePos.right);
    this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false,
      autoFocus: true,
      hasBackdrop: true,
      panelClass: 'filter-popup',
      width: '250px',
      position: { top: `${updatePos.top}px`, right: `${rightMostPos}px`}
    });
    this.confirmDialogRef.componentInstance.confirmMessage = 'Happy Learning. Confirm your Enrollment?';
    this.confirmDialogRef.afterClosed().subscribe(result => {
      if ( result ) {
        this.courseCatalogService.SubsToCourse($event, elSubStatus.Enrolled )
            .then(result => {
              this.confirmDialogRef = null;
              this.router.navigate(['my/courses/' + result.id]);
            }).catch(err => {
              this._snackBar.open('Error!! Try Again , or report this issue!', 'End now', {
                duration: 2000,
                horizontalPosition:  'center',
                verticalPosition: 'top',
              });
        })
      }
      this.confirmDialogRef = null;
      this.displayProgressSpinner = false;
    });
  }
}
