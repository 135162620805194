import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {QuizViewerService} from "../../quiz-viewer.service";
import {fuseAnimations} from "../../../../../@fuse/animations";
import {MatHorizontalStepper, MatVerticalStepper} from "@angular/material/stepper";

@Component({
  selector: 'app-quiz-viewer',
  templateUrl: './quiz-viewer.component.html',
  styleUrls: ['./quiz-viewer.component.scss'],
  animations   : fuseAnimations
})
export class QuizViewerComponent implements OnInit, OnDestroy {

  currentStep: any;
  currentSubs: any;
  animationDirection: 'left' | 'right' | 'none';
  loadingProgress: boolean;

  @ViewChild(MatHorizontalStepper,{static: false})
  stepper: MatHorizontalStepper;

  private _unsubscribeAll: Subject<any>;

  constructor(
      private _quizViewerService: QuizViewerService,
      private _changeDetectorRef: ChangeDetectorRef,
  ) {

    this._unsubscribeAll = new Subject();
    this.animationDirection = 'none';
    this.loadingProgress = false;
  }

  ngOnInit(): void {
    this.loadingProgress = true;
    this._quizViewerService.onQuizAssetChanged$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(asset => {
          if (asset) {
            this.currentStep = asset;
            this.loadingProgress = false;
            this.animationDirection = 'right';
            this._changeDetectorRef.detectChanges();
          }
          this.loadingProgress = false;
        });

    this._quizViewerService.onAssetSubsChanged$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(subs => {
          this.currentSubs = subs;
        });

    this._quizViewerService.onStepChanged$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(step => {
          if (step === 'intro') {
            this.stepper.reset();
          }
        });

  }

  startQuiz() {
    this.animationDirection = 'left';
    this._quizViewerService.changeStep('player');
    this.stepper.next();
  }

  startSummary() {
    this.animationDirection = 'right';
    this._quizViewerService.changeStep('summary');
    this.stepper.next();
  }

  resetQuiz() {
    this._quizViewerService.changeStep('intro');
    this.stepper.reset();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void
  {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
