import {ModuleWithProviders, NgModule} from '@angular/core';
import { NotifyListItemComponent } from './notify-list/notify-list-item/notify-list-item.component';
import {
  _MatMenuDirectivesModule,
  MatButtonModule,
  MatCheckboxModule, MatDialogModule,
  MatFormFieldModule,
  MatIconModule, MatInputModule, MatMenuModule,
  MatRippleModule,
  MatSelectModule, MatToolbarModule, MatTooltipModule
} from '@angular/material';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {ReactiveFormsModule} from '@angular/forms';
import { NotifyListComponent } from './notify-list/notify-list.component';
import {TranslateModule} from '@ngx-translate/core';
import { NotifyMainSidebarComponent } from './notify-main-sidebar/notify-main-sidebar.component';
import {RouterModule, Routes} from '@angular/router';
import {NotifyListService} from './notify-list.service';
import {NotificationComponent} from './notification.component';
import {FuseSidebarModule} from '../../../../@fuse/components';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import { ComposeDialogComponent } from './compose-dialog/compose-dialog.component';
import {MomentAgoPipe} from './moment-ago.pipe';
import { NotifyDetailsComponent } from './notify-details/notify-details.component';
import {AuthGuard} from '../../auth/auth.guard';
import {NOTIFY_MANAGER_OPTIONS, NotifyOptions} from './notifications.options';

const routes: Routes = [
  {
    path     : 'msgs',
    component: NotificationComponent,
    resolve  : {
      mail: NotifyListService
    },
    canActivate: [AuthGuard]
  },
  {
    path     : 'msgs/:msgId',
    component: NotificationComponent,
    resolve  : {
      mail: NotifyListService
    },
    canActivate: [AuthGuard]
  }
];
@NgModule({
  declarations: [
    NotifyListItemComponent,
    NotifyListComponent,
    NotifyMainSidebarComponent,
    NotificationComponent,
    ComposeDialogComponent,
    MomentAgoPipe,
    NotifyDetailsComponent
  ],
  imports: [
    FuseSharedModule,
    MatCheckboxModule,
    FlexModule,
    MatIconModule,
    FlexLayoutModule,
    MatRippleModule,
    RouterModule.forChild(routes),
    TranslateModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    RouterModule,
    FuseSidebarModule,
    ReactiveFormsModule,
    MatDialogModule,
    _MatMenuDirectivesModule,
    MatMenuModule,
    MatToolbarModule,
    MatInputModule,
    MatTooltipModule
  ],
  exports: [
    MomentAgoPipe
  ],
  entryComponents: [ComposeDialogComponent]
})
export class PushNotifyModule {
  static forRoot(fmOptions?: NotifyOptions): ModuleWithProviders {
    return {
      ngModule: PushNotifyModule,
      providers: [
        { provide: NOTIFY_MANAGER_OPTIONS, useValue:  fmOptions},
        NotifyListService,
      ],
    } as ModuleWithProviders;
  }
}
