import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {User} from '../../profile.model';
import {ReplaySubject, Subject} from 'rxjs';
import { takeUntil} from 'rxjs/operators';
import {ProfileService} from '../../profile.service';
import * as _ from 'lodash';
import {MatBottomSheet} from '@angular/material';
import {UploadAvatarComponent} from '../../components/upload-avatar/upload-avatar.component';

interface Language {
  value: string;
  name: string;
}

interface AvatarType {
  value: string;
  name: string;
}

interface Theme {
  value: string;
  name: string;
}

@Component({
  selector: 'user-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss']
})
export class PreferencesComponent implements OnInit, OnDestroy {

  user: User;
  userAttr: any;
  userForm: FormGroup;
  themeOptions: Theme[] = [
    { value: 'theme-default', name: 'Default'},
    { value: 'theme-yellow-light', name: 'Yellow Light'},
    { value: 'theme-blue-gray-dark', name: 'Blue-Gray Dark'},
    { value: 'theme-pink-dark', name: 'Pink Dark'}
  ];

  languageOptions: Language[] = [
    { value: 'es', name: 'Spanish'},
    { value: 'en', name: 'English'}
  ];

  avatarOptions: AvatarType[] = [
    { value: 'ni', name: 'Name Initials'},
    { value: 'gd', name: 'Email'},
    { value: 'img', name: 'Image File'}
  ];
  showOnAvatar: string;
  tempAvatarLink: string;

  @Output() formChanges: EventEmitter<any> = new EventEmitter<any>();
  @Output() onMessages: EventEmitter<any> = new EventEmitter<any>();
  @Output() verifyPhone: EventEmitter<any> = new EventEmitter<any>();

  public filteredLanguages: ReplaySubject<Language[]> = new ReplaySubject<Language[]>(1);
  public filteredThemes: ReplaySubject<Theme[]> = new ReplaySubject<Theme[]>(1);
  public filteredAvatar: ReplaySubject<AvatarType[]> = new ReplaySubject<AvatarType[]>(1);
  public languageFilterCtrl: FormControl = new FormControl();
  public themeFilterCtrl: FormControl = new FormControl();
  public avatarFilterCtrl: FormControl = new FormControl();
  private _onDestroy = new Subject<void>();
  customAvatarStyle = {
    backgroundColor: '#ff8420',
    border: '1px solid #7e7e7e',
    borderRadius: '50%',
    color: '#155a7e'
  };

  onloadShow: boolean;
  constructor(
      private _formBuilder: FormBuilder,
      public _profileService: ProfileService,
      private _bottomSheet: MatBottomSheet,
  ) {
    this.user = new User({});
    this.userForm = this.createUserForm();
    this.userForm.controls['language'].setValue( this.languageOptions[0].value);
    this.userForm.controls['theme'].setValue( this.themeOptions[0].value);
    this.userForm.controls['avatarType'].setValue( this.avatarOptions[0].value);
    this.showOnAvatar = this.avatarOptions[0].value;
    this.filteredLanguages.next(this.languageOptions.slice());
    this.filteredThemes.next(this.themeOptions.slice());
    this.filteredAvatar.next(this.avatarOptions.slice());
    this.onloadShow = true;
  }

  ngOnInit() {

    this.languageFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this._filterLanguages();
        });
    this.themeFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this._filterThemes();
        });
    this.avatarFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this._filterAvatarTypes();
        });

    this._profileService.onAvatarChange$.subscribe((avatarImg: any) => {
      this.tempAvatarLink = avatarImg;
    });

    this._profileService.onPreferencesOnChanged$
        .pipe(takeUntil(this._onDestroy))
        .subscribe((user: any) => {
          if (user) {
              if (_.isEmpty(user['custom:language'])) {
                  user['custom:language'] = 'en';
              }
              if (_.isEmpty(user['custom:avatarType'])) {
                  user['custom:avatarType'] = 'gd';
              }
              if (_.isEmpty(user['custom:theme'])) {
                  user['custom:theme'] = 'theme-pink-dark';
              }

              this.userAttr = user;
              this.userForm.controls['email'].setValue( user.email);
              this.userForm.controls['given_name'].setValue( user.given_name);
              this.userForm.controls['family_name'].setValue( user.family_name);
              this.userForm.controls['phone'].setValue( user['custom:phone']);
              this.userForm.controls['theme'].setValue( user['custom:theme']);
              this.userForm.controls['language'].setValue( user['custom:language']);
              this.userForm.controls['avatarType'].setValue( user['custom:avatarType']);
              this.showOnAvatar = user['custom:avatarType'];
              this.onloadShow = false;
              this.formChanges.emit({  dirty: this.userForm.dirty });
          }
        });

    this.userForm.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(val => {
          this.formChanges.emit({  dirty: this.userForm.dirty });
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onChangeAvatarType($event) {
    this.showOnAvatar = $event.value;
  }

  private _filterAvatarTypes() {
    if (!this.avatarOptions) {
      return;
    }
    // get the search keyword
    let search = this.avatarFilterCtrl.value;
    if (!search) {
      this.filteredAvatar.next(this.avatarOptions.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredAvatar.next(
        this.avatarOptions.filter(avatar => avatar.name.toLowerCase().indexOf(search) > -1)
    );
  }

  private _filterThemes() {
    if (!this.themeOptions) {
      return;
    }
    // get the search keyword
    let search = this.themeFilterCtrl.value;
    if (!search) {
      this.filteredThemes.next(this.themeOptions.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredThemes.next(
        this.themeOptions.filter(theme => theme.name.toLowerCase().indexOf(search) > -1)
    );
  }

  private _filterLanguages() {
    if (!this.languageOptions) {
      return;
    }
    // get the search keyword
    let search = this.languageFilterCtrl.value;
    if (!search) {
      this.filteredLanguages.next(this.languageOptions.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredLanguages.next(
        this.languageOptions.filter(lang => lang.name.toLowerCase().indexOf(search) > -1)
    );
  }

  saveData(): void {
    this.onloadShow = true;
    const newAttr = this.getCurrentValues(this.userForm);
    this._profileService.updateUserAttributes( newAttr)
        .then((results: any) => {
          this.onloadShow = false;
        }).catch(reason => {
          this.onMessages.emit({type: 'error', message: reason});
          this.onloadShow = false;
    });
  }

  createUserForm(): FormGroup {
     return this._formBuilder.group({
        sub        : [ this.user.sub],
        family_name : [this.user.family_name, Validators.required],
        given_name  :  [this.user.given_name, Validators.required],
        avatarType : [this.user.avatarType],
        email     : [this.user.email, [Validators.required, Validators.email]],
        phone     : [this.user.phone],
        theme     : [this.user.theme],
        language  : [this.user.language]
      });
  }

  getDirtyValues(form: any) {
    const dirtyValues = {};
    Object.keys(form.controls)
        .forEach(key => {
          const currentControl = form.controls[key];
          if (currentControl.dirty) {
            const customKey = key === 'given_name' || key === 'email' ||  key === 'family_name' || key === 'sub' ? key : `custom:${key}`;
            if (currentControl.controls) {
              dirtyValues[customKey] = this.getDirtyValues(currentControl);
            } else {
              dirtyValues[customKey] = currentControl.value;
            }
          }
        });
    return dirtyValues;
  }

  getCurrentValues(form: any) {
    const dirtyValues = {};
    Object.keys(form.controls)
      .forEach(key => {
        const currentControl = form.controls[key];
        const customKey = key === 'given_name' || key === 'email' ||  key === 'family_name' || key === 'sub' ? key : `custom:${key}`;
        if (currentControl.controls) {
          dirtyValues[customKey] = this.getCurrentValues(currentControl);
        } else {
          dirtyValues[customKey] = currentControl.value;
        }
      });
    return dirtyValues;
  }

  openFileUploadSheet(target: string): void {
    const fileToUpload = this._bottomSheet.open(UploadAvatarComponent, {
      data: { fileTarget: target },
    });
    fileToUpload.afterDismissed().subscribe((data) => {
      if (data && data.file !== null) {
        this.onloadShow = true;
        this._profileService.updateUserAvatar( { picture : data.file})
          .then((results: any) => {
             this.onloadShow = false;
          }).catch(reason => {
              this.onMessages.emit({type: 'error', message: reason});
              this.onloadShow = false;
        });
      }

    });
  }

  onVerifyPhone(userAttr) {
      this.verifyPhone.emit(userAttr);
  }

}
