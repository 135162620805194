import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {fuseAnimations} from '../../../../../../../@fuse/animations';
import {Course} from '../../course.model';
import {of, ReplaySubject, Subject} from 'rxjs';
import {FuseSidebarService} from '../../../../../../../@fuse/components/sidebar/sidebar.service';
import {CourseService} from '../../../course.service';
import {CoursesCategoryService} from '../../../courses-category.service';
import {takeUntil} from 'rxjs/operators';
import * as _ from 'lodash';

interface CourseStatus {
  value: string;
  name: string;
}
@Component({
  selector: 'course-general-info',
  templateUrl: './course-general.component.html',
  styleUrls: ['./course-general.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class CourseGeneralComponent implements OnInit, OnDestroy  {

  @Output() formChanges: EventEmitter<any> = new EventEmitter<any>();
  course: Course;
  pageType: string;
  courseForm: FormGroup;
  imgSrc: any = [];

  statusOptions: CourseStatus[] = [
    { value: 'active', name: 'Active'},
    { value: 'inactive', name: 'Inactive'},
    { value: 'new', name: 'New'}
  ];

  public categoryFilterCtrl: FormControl = new FormControl();
  //
  public filteredCategories: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredStatus: ReplaySubject<CourseStatus[]> = new ReplaySubject<CourseStatus[]>(1);
  public statusFilterCtrl: FormControl = new FormControl();
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _fuseSidebarService: FuseSidebarService,
    private _courseService: CourseService,
    private _categoryService: CoursesCategoryService,
    private _formBuilder: FormBuilder,
  ) {
    this.course = new Course();
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.filteredStatus.next(this.statusOptions.slice());
    this._categoryService.onCategoriesChanged$
      .subscribe((res) => {
        this.filteredCategories.next(res);
      });
    this.statusFilterCtrl.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this._filterStatus();
      });
    // categories
    this.categoryFilterCtrl.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this._filterCategories();
      });
    // Subscribe to update product on changes
    this._courseService.onCourseChanged$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(course => {
        if ( course && course.id) {
          this.course = new Course(course);
          if (this.course.assets.length > 0) {
            this.course.assets = _.orderBy(this.course.assets, 'order', 'asc');
          }
          this.pageType = 'edit';
          this.courseForm = this.createCourseForm();
        } else {
          this.pageType = 'new';
          this.course = new Course();
          this.courseForm = this.createCourseForm();
          this.courseForm.controls['status'].setValue( this.statusOptions[2].value);
        }
      });
  }
  /**
   * On destroy
   */
  ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private _filterStatus() {
    if (!this.statusOptions) {
      return;
    }
    // get the search keyword
    let search = this.statusFilterCtrl.value;
    if (!search) {
      this.filteredStatus.next(this.statusOptions.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredStatus.next(
      this.statusOptions.filter(lang => lang.name.toLowerCase().indexOf(search) > -1)
    );
  }

  private _filterCategories() {
    const search = this.categoryFilterCtrl.value;
    if (!search) {
      return;
    }
    of(this._categoryService.getCategories(search));
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Create product form
   *
   * @returns {FormGroup}
   */
  createCourseForm(): FormGroup {
    return this._formBuilder.group({
      id                  : [this.course.id],
      title               : [this.course.title],
      timeAprox           : [this.course.timeAprox],
      description         : [this.course.description],
      courseCategoryId    : [this.course.courseCategoryId],
      courseUrl           : [this.course.courseUrl],
      courseType           : [this.course.courseType],
      status              : [this.course.status],
      _version            : [this.course._version]
    });
  }

  saveData() {
    const attr = this.courseForm.getRawValue();
    if (attr.courseType === 'External') {
      this._courseService.takeScreenShot(attr.courseUrl)
        .then(res => {
          attr.pageType = this.pageType;
          this.formChanges.emit(attr);
        }).catch(error => {
          console.log(error);
          this._courseService.sendMsg({type: 'error', msg: error.toString()})
      });
    } else {
      attr.courseUrl = ' ';
      attr.pageType = this.pageType;
      this.formChanges.emit(attr);
    }
  }

}
