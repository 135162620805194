import {Inject, Injectable} from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from './services/auth.service';
import {AUTH_OPTIONS} from './auth.options';
import {getDeepFromObject} from './helpers';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    private redirectEndPoint: string;
    constructor( private _router: Router ,
                 private _authService: AuthService,
                 @Inject(AUTH_OPTIONS) protected options = {}
                 ) { }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        this.redirectEndPoint = this.getConfigValue('endPoints.redirectOnUnAuthorized');
        return this._authService.checkSession()
            .then((loggedIn) => {
                const routeAllowed = this._authService.isRouteAuthorized(state.url);
                if (loggedIn && routeAllowed) {
                    return true;
                }
                this._router.navigate([this.redirectEndPoint]);
                return false;
            });

    }
    private getConfigValue(key: string): any {
      return getDeepFromObject(this.options, key, null);
    }
}
