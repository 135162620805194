import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {USER_MANAGER_OPTIONS} from './users.options';
import {getDeepFromObject} from '../../auth/helpers';
import {User, UserGroup} from './user.model';
import * as AWS from 'aws-sdk';
import * as _ from 'lodash';
import * as S3 from 'aws-sdk/clients/s3';
import {Notification} from '../../../../models';
import {format} from 'date-fns';
import {DataStore} from '@aws-amplify/datastore';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Hub} from "@aws-amplify/core";
import {AuthService} from "../../auth/services/auth.service";
import {
    APIService,
    ContactType,
    CreateNotificationInput,
    ModelContactFilterInput,
    UpdateContactInput
} from "../../../API.service";
import {API} from "aws-amplify";

@Injectable()
export class UsersService implements Resolve<User>
{

    private onUsersChanged: BehaviorSubject<any[]> = new BehaviorSubject([]);
    public onUsersChanged$: Observable<any[]> = this.onUsersChanged.asObservable();

    private onGroupsChanged: BehaviorSubject<any[]> = new BehaviorSubject([]);
    public onGroupsChanged$: Observable<any[]> = this.onGroupsChanged.asObservable();

    private onOrgsChanged: BehaviorSubject<any[]> = new BehaviorSubject([]);
    public onOrgsChanged$: Observable<any[]> = this.onOrgsChanged.asObservable();

    private onSelectedUsersChanged: BehaviorSubject<any[]> = new BehaviorSubject([]);
    public onSelectedUsersChanged$: Observable<any[]> = this.onSelectedUsersChanged.asObservable();

    private onUserDataChanged: BehaviorSubject<any[]> = new BehaviorSubject([]);
    public onUserDataChanged$: Observable<any[]> = this.onUserDataChanged.asObservable();

    private onNextPageChanged: BehaviorSubject<any> = new BehaviorSubject(null);
    public onNextPageChanged$: Observable<any> = this.onNextPageChanged.asObservable();

    onSearchTextChanged: Subject<any>;
    onFilterChanged: Subject<any>;
    onUserSelected: BehaviorSubject<any>;

    users: User[];
    groups: UserGroup[];
    user: any;
    selectedUsers: string[] = [];
    nextToken: any;
    nextTokenGroupList: any;

    searchText: string;
    filterBy: string;
    pageSize: number;
    private bucket: string;
    // tslint:disable-next-line:max-line-length
    private readonly profileBase64Img = '/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAMDAwMDAwQEBAQFBQUFBQcHBgYHBwsICQgJCAsRCwwLCwwLEQ8SDw4PEg8bFRMTFRsfGhkaHyYiIiYwLTA+PlQBAwMDAwMDBAQEBAUFBQUFBwcGBgcHCwgJCAkICxELDAsLDAsRDxIPDg8SDxsVExMVGx8aGRofJiIiJjAtMD4+VP/CABEIAGAAYAMBIgACEQEDEQH/xAAbAAEBAQADAQEAAAAAAAAAAAAACAcEBQYJA//aAAgBAQAAAAD6QAADwOO8XevYAYbJIuDSARTloq7egSXhYp6igRLmI1a0gTDOopSlAfj87eveqv4BFOWtcswHV4jLIpzdO4eHwfE+GDlbRvkIgA//xAAXAQEBAQEAAAAAAAAAAAAAAAAAAwIB/9oACAECEAAAAKtMm6o5bqlh26HHboctoZ//xAAXAQADAQAAAAAAAAAAAAAAAAAAAQMC/9oACAEDEAAAAMCGGYl2ZiW0KDsxRLOWAN//xAA2EAABAwIDBQQIBgMAAAAAAAABAgMEBREGByEAEiAxURATQYEIFCIjMGFxkSQmMkBCgmJyof/aAAgBAQABPwD9hiTMvCWGVrakzO/kp0MaOnvFg9DyCdqj6QNQUtQptHYQnwVIWpwnyTu22gekBXEOj16lQnW/EMlbSvuor2wljihYyiKdgu7rqdHYztg4g/S+o+Y+BnJjuTh+KzR6c6pqXMbK3XUmym2b29noVEc9iSo3PPtBKSCDYjkRtlXiGRiPB8Z6SvvJEVa4zqzqVluxST87KF+POZxxeYFRC+TbUdKPp3aTw5AH8t1QdKhf7tp48+aYiPiWFOQpP4uFZQ8d5o2v9lcPo+SUKgVuNdO8l9le742UCL/8484XZa8f1Jt51SktJZSyDyQgtpVYeZ4cl5MhjHsNtsqCH2JCHR1SEFYv5p487MGVV+rorsGI4/HVEAlFA3y2Wr+0oD+NuAAqNhz2yYwJWabVnK1VIbkVAjFEdDuiypwi6t3mLAcciO1KjvMOp3m3UKQtPVKxY7VenuUmqToDmq4kl1lR6ltRTftwJDM/GNCZ3N4GoMqUn/FtQUr4Ob1Tw/UsVvKpbLiXmVFma5oEPONm28mx8ie3KLEeHMPVtZqTDpky1NMR5ACShgKNlFVyLX6jjqtbpFDj+s1KYxFa8FOrAv8AIDmT9NsV550hMCTGoLT7slbZS3JWju0IvpvAE3JHhe2xJUVEquTqSeDB+eFMap0SHXmpIfZbCDKQkOJWBoFKF7362vtR6/RsQR+/pk1iU347itR/snmnz7cQ5iYSwyVtzJ6VSE847PvXL9CBon+xG2JM9azO32aLGRBaOgeXZx4/Qck7T6jUKpIVJnSXZDyubjqys/c8cKfNp0hEmJJdjPJ/S40soUPMbYbzzr9O3GawwiosjTvRZt0Dy0VthzMbCWKChqJOS1IVyjP+7cv0F9Ff1J2JKjc6k8z8MEpNxoRyO3//xAAdEQEAAwACAwEAAAAAAAAAAAABAAIQICESMTJB/9oACAECAQE/AMK2fyNbB64UBdt1Z2n1t/rRTGKugsOiMRMKV4+BP//EABsRAQACAwEBAAAAAAAAAAAAAAEAEAISISAx/9oACAEDAQE/AK2IZD4zULx+Xn8vB5aDR1gBexHrDjDIa3fO7P/Z';

    /**
     * Constructor
     *
     * @param options
     * @param _auth
     * @param api
     */
    constructor(
        @Inject(USER_MANAGER_OPTIONS) protected options = {},
        private _auth: AuthService,
        private api: APIService,
    ) {
        // Set the defaults
        this.onUserSelected = new BehaviorSubject([]);
        this.onSearchTextChanged = new Subject();
        this.onFilterChanged = new Subject();
        this.filterBy = 'all';
        this.pageSize = this._auth.getUserSettings().pageSize;
        this.bucket = this.getConfigValue('bucketName');
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User>[] | Promise<any> | any {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getUsers(),
                this.getUserGroups(),
                this.getContacts()
            ]).then(
                ([users]) => {
                    this.onSearchTextChanged.subscribe(searchText => {
                        this.searchText = `email ^= "${searchText}"`;
                        this.nextToken = null;
                        of(this.getUsers(this.pageSize, this.searchText, this.nextToken));
                    });

                    this.onFilterChanged.subscribe(filter => {
                        this.filterBy = filter;
                        if (filter === 'all') {
                            of(this.getUsers(this.pageSize,  this.searchText , this.nextToken));
                        } else {
                            of(this.getUsersByGroup(this.pageSize, this.filterBy , this.nextToken));
                        }
                    });
                    Hub.listen('settings', (data) => {
                        this.pageSize = data.payload.data.pageSize;
                        this.nextToken = null;
                        this.refresh();
                    });
                    resolve();
                },
                reject
            );
        });
    }


    refresh() {
      if (this.filterBy === 'all') {
        of(this.getUsers(this.pageSize,  this.searchText , this.nextToken));
        of(this.getContacts());
      } else {
        of(this.getUsersByGroup(this.pageSize, this.filterBy , this.nextToken));
      }
    }

    refreshContacts() {
        of(this.getContacts())
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    // UserPoolId: this.getConfigValue('UserPoolId'),
    // AttributesToGet: this.getConfigValue('AttributesToGet')

    getContacts(sTxt?:string): Promise<any[]> {
        return new Promise((resolve, reject) => {
            const userInfo = this._auth.getUserId();
            const userId = userInfo.getUsername();
            let filter: ModelContactFilterInput;
            filter = {
                userID: {eq: userId},
                contactType: {eq : ContactType.Organization},
                status: {eq: 'active'}
            };
            if (sTxt) {
                filter.contactName = {contains: sTxt};
            }
            this.api.ListContacts(filter, 5, null)
                .then((contacts) => {
                    this.onOrgsChanged.next(contacts.items)
                    resolve(contacts.items);
                })
                .catch((err) => reject(err));
        });
    }

    syncCognito(): any {
        return new AWS.CognitoIdentityServiceProvider(
            {
                apiVersion: '2016-04-18',
                region: this.getConfigValue('region'),
                accessKeyId: this.getConfigValue('accessKeyId'),
                secretAccessKey: this.getConfigValue('secretAccessKey')
            }
        );
    }
    // Sync Services
    syncS3(): any {
      return new S3(
        {
          accessKeyId: this.getConfigValue('accessKeyId'),
          secretAccessKey: this.getConfigValue('secretAccessKey'),
          region: this.getConfigValue('region')
        });
    }
    // get base64Image
    getS3AvatarFileByName(imgFile: string): Promise<any> {
        return new Promise((resolve, reject) => {
            if (imgFile) {
                const keyArray = imgFile.split('/');
                const key = `userpics/${keyArray[keyArray.length - 2] }/${keyArray[keyArray.length - 1]}`;
                const newConn = this.syncS3();
                const params = {
                    Bucket: this.bucket,
                    Key: key
                };
                newConn.getObject(params, (err, urlLink) => {
                    if (err) {
                        resolve(`data:image/jpeg;base64,${this.profileBase64Img}`);
                    } else {
                        const base64Img = 'data:image/jpeg;base64,' + urlLink.Body.toString('base64');
                        resolve(base64Img);
                    }
                });
            } else {
                resolve(`data:image/jpeg;base64,${this.profileBase64Img}`);
            }
        });
    }
    /**
     * Get users
     *
     * @returns {Promise<any>}
     */
    getUsers(pageSize?: number, filter?: string, nextToken?: string): Promise<any> {
        const strPageSize = pageSize ? pageSize.toString() : this.pageSize ;
        return new Promise((resolve, reject) => {
            const conn = this.syncCognito();
            const params = {
                UserPoolId: this.getConfigValue('UserPoolId'),
                Limit: strPageSize
            };
            if (filter) {
                params['Filter'] = filter;
            }
            if (nextToken) {
                params['PaginationToken'] = nextToken;
            }
            conn.listUsers(params, (err, data) => {
                if (err) {
                    reject(err);
                }
                this.nextToken = data && data['PaginationToken'] ? data['PaginationToken'] : null;
                this.users = this.mapListOfUsers(data.Users);
                this.onUsersChanged.next(this.users);
                this.onNextPageChanged.next(this.nextToken );
                resolve(this.users);
            });
        });
    }

    getUser(userName: string ): Promise<any> {
        return new Promise((resolve, reject) => {
            const conn = this.syncCognito();
            const params = {
                UserPoolId: this.getConfigValue('UserPoolId'),
                Username: userName
            };
            conn.adminGetUser(params, (err, data) => {
                if (err) {
                    console.log(err);
                    reject(err);
                }
                const user = this.mapUserV2(data);
                this.onUserSelected.next(user);
                resolve(user);
            });
        });
    }

    getUsersByGroup(pageSize?: number, group?: string, nextToken?: string): Promise<any[]> {
        const strPageSize = pageSize ? pageSize.toString() : this.pageSize ;

        return new Promise((resolve, reject) => {
            const conn = this.syncCognito();
            const params = {
                GroupName: group,
                UserPoolId: this.getConfigValue('UserPoolId'),
                Limit: strPageSize
            };
            if (nextToken) {
                params['PaginationToken'] = nextToken;
            }
            conn.listUsersInGroup(params, (err, data) => {
                if (err) {
                    console.log(err);
                    reject(err);
                }
                this.nextToken = data && data['PaginationToken'] ? data['PaginationToken'] : null;
                this.users = this.mapListOfUsers(data.Users);
                this.onUsersChanged.next(this.users);
                this.onNextPageChanged.next(this.nextToken );
                resolve(this.users);
            });
        });
    }

    goNextPage( nextPageToken: string) {
        if (this.filterBy === 'all') {
          of(this.getUsers(this.pageSize,  this.searchText , nextPageToken));
        } else {
          of(this.getUsersByGroup(this.pageSize, this.filterBy , nextPageToken));
        }
    }

    getUserGroups(pageSize?: number, nextToken?: string): Promise<any> {
      const strPageSize = pageSize ? pageSize.toString() : this.pageSize ;
      return new Promise((resolve, reject) => {
            const conn = this.syncCognito();
            const params = {
                UserPoolId: this.getConfigValue('UserPoolId'),
                Limit: strPageSize
            };

            if (nextToken) {
                params['NextToken'] = nextToken;
            }
            conn.listGroups(params, (err, data) => {
                if (err) {
                    console.log(err);
                    reject(err);
                }
                this.nextTokenGroupList = data && data['NextToken'] ? data['NextToken'] : null;
                this.groups = this.mapListOfGroups(data.Groups);
                this.onGroupsChanged.next(this.groups);
                resolve(this.groups);
            });
        });
    }

    getListGroupsForUser(pageSize?: number, username?: string, nextToken?: string): Promise<any> {
        const strPageSize = pageSize ? pageSize.toString() : this.pageSize ;
        return new Promise((resolve, reject) => {
            const conn = this.syncCognito();
            const params = {
                UserPoolId: this.getConfigValue('UserPoolId'),
                Username: username,
                Limit: strPageSize
            };

            if (nextToken) {
                params['NextToken'] = nextToken;
            }
            conn.adminListGroupsForUser(params, (err, data) => {
                if (err) {
                    console.log(err);
                    reject(err);
                }
                // console.log('Successfully get groupsby user.', data);
                resolve(data);
            });
        });
    }

    removeAllGroupsForUser(groupList: any, userName?: string): Promise<any>  {
      const promisesArray = [];
      groupList.forEach(group => {
        promisesArray.push(this.removeUserFromGroup(group.GroupName, userName));
      });
      return new Promise((resolve, reject) => {
          Promise.all(promisesArray).then(([res]) => {
            resolve(res);
        }, reject);
      });
    }

    removeUserFromGroup( groupName: string, userName?: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const conn = this.syncCognito();
            const params = {
                UserPoolId: this.getConfigValue('UserPoolId'),
                GroupName: groupName,
                Username: userName
            };
            conn.adminRemoveUserFromGroup(params, (err, data) => {
                if (err) {
                    console.log(err);
                    reject(err);
                }
                resolve(data);
            });
        });
    }

    addUserToGroup( groupName: string, userName?: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const conn = this.syncCognito();
            const params = {
                UserPoolId: this.getConfigValue('UserPoolId'),
                GroupName: groupName,
                Username: userName
            };
            conn.adminAddUserToGroup(params, (err, data) => {
                if (err) {
                    console.log(err);
                    reject(err);
                }
                resolve(data);
            });
        });
    }

    getConfirmationCode() {
      return new Promise((resolve, reject) => {
        const conn = this.syncCognito();
        // conn.resendConfirmationCode('phone_number', (err, data, verCode) => {
        //   if (err) {
        //     console.log(err);
        //     reject(err);
        //   }
        //   console.log(data);
        resolve(true);
        // });
      // });
      });
    }

    sendTempPassword() {
        return new Promise((resolve, reject) => {
            const init = {
                body: {
                    template: 'OnBoarding'
                }
            };
            let username = null;
            API.put('coreApiKTC', '/mail', init)
                .then(mailTemplate => {
                    resolve(mailTemplate);
                }).catch(error => {
                    reject(error);
                });
        });
    }

    disableUser(  userName?: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const conn = this.syncCognito();
            const params = {
                UserPoolId: this.getConfigValue('UserPoolId'),
                Username: userName
            };
            conn.adminDisableUser(params, (err, data) => {
                if (err) {
                    console.log(err);
                    reject(err);
                }
                resolve(data);
            });
        });
    }

    enableUser(  userName?: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const conn = this.syncCognito();
            const params = {
                UserPoolId: this.getConfigValue('UserPoolId'),
                Username: userName
            };
            conn.adminEnableUser(params, (err, data) => {
                if (err) {
                    console.log(err);
                    reject(err);
                }
                resolve(data);
            });
        });
    }

    mapListOfGroups(contents: any[]): UserGroup[] {
        const groupListDS = [];
        contents.map((item) => {
            const group = {
                id: item.RoleArn,
                groupName: item.GroupName,
                precedence: item.Precedence,
                updatedAt: item.LastModifiedDat
            };
            groupListDS.push(new UserGroup( group));
        });
        return groupListDS;
    }

    mapUserV2(item: any): User {
      let imageUrl = '';
      let external = false;
      const email = this.mapAttributeFromUser('email', item.UserAttributes);
      // tslint:disable-next-line:variable-name
      const email_verified = this.mapAttributeFromUser('email_verified', item.UserAttributes);
      const name = this.mapAttributeFromUser('name', item.UserAttributes);
      // tslint:disable-next-line:variable-name
      const phone_number_verified = this.mapAttributeFromUser('phone_number_verified', item.UserAttributes);
      // tslint:disable-next-line:variable-name
      const avatar_type = this.mapAttributeFromUser('custom:avatarType', item.UserAttributes);

      const ident = this.getIdentities(item.UserAttributes);
      const identities = item.Attributes && ident ? ident[0] : false;
      const picture = this.getPicture(item.UserAttributes);
      if (identities && picture) {
        imageUrl = item.Attributes && picture ? picture : false;
        external = true;
      } else if (picture) {
        imageUrl = picture;
      }
      // tslint:disable-next-line:variable-name
      const account_status = item.Enabled;
      const createdAt = item.UserCreateDate;
      const updatedAt = item.UserLastModifiedDate;
      const user = {
        id: item.Username,
        email_verified: email_verified,
        phone_verified: phone_number_verified,
        account_status: account_status,
        email: email,
        avatar_type: avatar_type,
        user_name: item.Username,
        name: name,
        updatedAt: updatedAt,
        createdAt: createdAt,
        user_status:  item.UserStatus,
        attributes : item.UserAttributes,
        external: external,
        picture: imageUrl
      };
      return new User(user);
    }
    // ListofUsers
    mapUser(item: any): User {
        let imageUrl = '';
        let external = false;
        const email = this.mapAttributeFromUser('email', item.Attributes);
      // tslint:disable-next-line:variable-name
        const email_verified = this.mapAttributeFromUser('email_verified', item.Attributes);
        const name = this.mapAttributeFromUser('name', item.Attributes);
      // tslint:disable-next-line:variable-name
        const phone_number_verified = this.mapAttributeFromUser('phone_number_verified', item.Attributes);
      // tslint:disable-next-line:variable-name
        const avatar_type = this.mapAttributeFromUser('custom:avatarType', item.Attributes);

        const ident = this.getIdentities(item.Attributes);
        const identities = item.Attributes && ident ? ident[0] : false;
        const picture = this.getPicture(item.Attributes);
        if (identities && picture) {
          imageUrl = item.Attributes && picture ? picture : false;
          external = true;
        } else if (picture) {
          imageUrl = picture;
        }
      // tslint:disable-next-line:variable-name
        const account_status = item.Enabled;
        const createdAt = item.UserCreateDate;
        const updatedAt = item.UserLastModifiedDate;
        const user = {
          id: item.Username,
          email_verified: email_verified,
          phone_verified: phone_number_verified,
          account_status: account_status,
          email: email,
          avatar_type: avatar_type,
          user_name: item.Username,
          name: name,
          updatedAt: updatedAt,
          createdAt: createdAt,
          user_status:  item.UserStatus,
          attributes : item.Attributes,
          external: external,
          picture: imageUrl
        };
        return new User(user);
    }

    mapListOfUsers(contents: any[]): User[] {
        const userListDS = [];
        contents.map((item) => {
          const user  = this.mapUser(item);
          userListDS.push(new User( user));
        });
        return userListDS;
    }

    getIdentities(listAttr: any[]): any {
      let identifities = null;
      listAttr.forEach(item => {
        if (item.Name === 'identities') {
          identifities = JSON.parse(item.Value);
        }
      });
      return identifities;
    }

    getPicture(listAttr: any[]): any {
      let identpic = null;
      listAttr.forEach(item => {
        if (item.Name === 'picture') {
          identpic = item.Value;
        }
      });

      return identpic;
    }

    mapAttributeFromUser(attrName: string, attrs: any[]) {
        const found = _.find(attrs, ['Name', attrName]);
        if (found) {
            return found['Value'];
        }
        return null;
    }

    /**
     * Toggle selected contact by id
     *
     * @param id
     */
    toggleSelectedUser(id): void {
        // First, check if we already have that contact as selected...
        if ( this.selectedUsers.length > 0 )
        {
            const index = this.selectedUsers.indexOf(id);

            if ( index !== -1 )
            {
                this.selectedUsers.splice(index, 1);

                // Trigger the next event
                this.onSelectedUsersChanged.next(this.selectedUsers);

                // Return
                return;
            }
        }

        // If we don't have it, push as selected
        this.selectedUsers.push(id);

        // Trigger the next event
        this.onSelectedUsersChanged.next(this.selectedUsers);
    }

    /**
     * Toggle select all
     */
    toggleSelectAll(): void
    {
        if ( this.selectedUsers.length > 0 )
        {
            this.deselectUsers();
        }
        else
        {
            this.selectUsers();
        }
    }

    /**
     * Select users
     *
     * @param filterParameter
     * @param filterValue
     */
    selectUsers(filterParameter?, filterValue?): void
    {
        this.selectedUsers = [];

        // If there is no filter, select all users
        if ( filterParameter === undefined || filterValue === undefined )
        {
            this.selectedUsers = [];
            this.users.map(contact => {
                this.selectedUsers.push(contact.id);
            });
        }

        // Trigger the next event
        this.onSelectedUsersChanged.next(this.selectedUsers);
    }


    /**
     * Deselect users
     */
    deselectUsers(): void
    {
        this.selectedUsers = [];

        // Trigger the next event
        this.onSelectedUsersChanged.next(this.selectedUsers);
    }

    /**
     * Delete selected users
     */

    addNotifications(data: any, userId: string): Promise<Notification> {
        return new Promise((resolve, reject) => {
            const urlTxt = _.isEmpty(data.localUrl) ? 'https://kidsthatcode.org/' : data.localUrl;
            const dateFor = format(new Date(), 'yyyy-MM-dd');
            const payload: CreateNotificationInput = {
                typeNotify: data.notiType,
                userId: userId,
                body: data.message,
                subject: data.subject,
                localUrl: urlTxt,
                renotify: true,
                silent: false,
                dateFor: dateFor,
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString()
            }
            this.api.CreateNotification(payload)
                .then(result => {
                    resolve(result);
                }).catch( error => {
                reject(error);
            });
        });
    }

    sendNotifications(data?: any): Promise<any>  {
      const promisesArray = [];
      this.selectedUsers.forEach(userId => {
          promisesArray.push(this.addNotifications(data, userId));
      });
      return new Promise((resolve, reject) => {
        Promise.all(promisesArray).then(([res]) => {
          resolve(res);
        }, reject);
      });
    }

    // Functions
    getConfigValue(key: string): any {
        return getDeepFromObject(this.options, key, null);
    }

}
