import {Component, Input, OnInit} from '@angular/core';
import {StudentsService} from "../../students.service";

@Component({
  selector: 'student-progress-cell',
  templateUrl: './student-progress-cell.component.html',
  styleUrls: ['./student-progress-cell.component.scss']
})
export class StudentProgressCellComponent implements OnInit {

  @Input('courseId') courseId: string;
  @Input('userId') userId: string;

  percentage = 0;

  constructor(
      private _studentService: StudentsService
  ) { }

  ngOnInit() {
    this._studentService.CalculateProgress(this.courseId, this.userId)
        .then((res: number) => {
          this.percentage = res;
        })
  }

}
