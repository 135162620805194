import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material';
import {CResource} from '../../../resource.model';
import {fuseAnimations} from '../../../../../../../@fuse/animations';
import {ResourceType} from '../../../../../../../models';
import {CoursesResourcesService} from '../../../courses-resources.service';

@Component({
  selector: 'edit-course-other-resource',
  templateUrl: './edit-course-other-resource.component.html',
  styleUrls: ['./edit-course-other-resource.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class EditCourseOtherResourceComponent implements OnInit {
  resourceForm: FormGroup;
  resource: CResource;
  actionType: string;

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<EditCourseOtherResourceComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private _resourceListService: CoursesResourcesService,
  ) {
    this.actionType = data.actionType;
    if (this.actionType === 'new') {
      this.resource = new CResource();
      this.resource.courseId = data.courseId;
    } else {
      this.resource = this.data.data ;
    }
  }

  ngOnInit() {
    this.resourceForm = this.createResourceForm();
  }
  /**
   * Create compose form
   *
   * @returns {FormGroup}
   */
  createResourceForm(): FormGroup {
    return new FormGroup({
      id : new FormControl(this.resource.id),
      courseId: new FormControl(this.resource.courseId),
      description: new FormControl(this.resource.description),
      contentType: new FormControl(ResourceType.LINK),
      sourceCode: new FormControl(this.resource.sourceCode),
      _version:  new FormControl(this.resource._version),
    });
  }

  closeDialog(resourceForm) {
    if (resourceForm) {
      this._resourceListService.takeScreenShot(resourceForm.value.sourceCode)
        .then(res => {
          this._bottomSheetRef.dismiss({data: resourceForm, actionType: this.actionType});
        }).catch(error => {
          this._resourceListService.sendMsg({type: 'error',
            msg: 'Url Invalid!!!, please provide a valid URL, ex: https://kidsthatcode.de'});
      });
    } else {
      this._bottomSheetRef.dismiss({data: resourceForm, actionType: this.actionType});
    }
  }
}
