import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table';
import { QuizService } from '../../quiz.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

export interface iquestion {
  id: string;
  title: string;
  position: number;
  qType: string;
  correctMessage: string;
  incorrectMessage: string;
}

@Component({
  selector: 'question-list',
  templateUrl: './question-list.component.html',
  styleUrls: ['./question-list.component.scss']
})
export class QuestionListComponent implements OnInit, OnDestroy {

  @Input()courseAsset: any;
  
  @Input()pageType: String;
  
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  question_data: any;
  VIDEO = "video";
  IMAGE = "image";
  dataSource: any;
  displayedColumns: string[] = ['index', 'title', 'qType'];
  quiz: any;
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _quizService: QuizService,
    private _router: Router
  ) {
    this._unsubscribeAll = new Subject();
  }
  
  ngOnInit() {
    this.quiz = this.courseAsset.quiz;
    this.question_data = this.quiz.questions.items;
    this.dataSource = new MatTableDataSource(this.question_data);
    this._quizService.onQuizQuestionChanged$
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(res => {
            if(res){
              this.question_data = res.quiz.questions.items;
              this.dataSource = new MatTableDataSource(this.question_data);
            }
          });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  goEditQuestion(element:any) {
    this._router.navigate([`admin/quiz-question/${this.courseAsset.id}/${element.id}`]);
  }

}
