import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {fromEvent, of, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {fuseAnimations} from '@fuse/animations';

import {AcademyCoursesService} from 'app/main/academy/courses.service';
import {Router} from '@angular/router';
import {AuthService} from "../../auth/services/auth.service";

@Component({
    selector   : 'academy-courses',
    templateUrl: './courses.component.html',
    styleUrls  : ['./courses.component.scss'],
    animations : fuseAnimations
})
export class AcademyCoursesComponent implements OnInit, OnDestroy
{
    categories: any[];
    courses: any[];
    filteredSubs: any[];
    currentCategory: string;
    searchTerm: string;
    nextPage: any;
    currentUserId: string;

    @ViewChild('filter', {static: true})
    filter: ElementRef;
    filterTxt: string;
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {AcademyCoursesService} _academyCoursesService
     * @param router
     * @param _auth
     */
    constructor(
        private _academyCoursesService: AcademyCoursesService,
        private router: Router,
        private _auth: AuthService,
    )
    {
        // Set the defaults
        this.currentCategory = 'all';
        this.searchTerm = '';

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {

        this._auth.loggedUser$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe( userInfo => {
                this.currentUserId = userInfo.username;
            })

        this._academyCoursesService.nextPageToken$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(nextToken => {
                this.nextPage = nextToken;
            });
        // Subscribe to categories
        this._academyCoursesService.onCategoriesChanged$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(categories => {
                this.categories = categories;
            });

        // Subscribe to courses
        this._academyCoursesService.onCoursesChanged$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(courses => {
                this.filteredSubs = this.courses = courses;
            });

        fromEvent(this.filter.nativeElement, 'keyup')
          .pipe(
            debounceTime(550),
            distinctUntilChanged()
          )
          .subscribe(() => {
            this.filterTxt  = this.filter.nativeElement.value;
            of(this._academyCoursesService.getCourses( this.filterTxt , this.nextPage));
          });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    gotoNextPage(nextPage): void {
        const filter = this.currentCategory === 'all' ? null : this.currentCategory;
        this._academyCoursesService.gotoNextPage(this.filterTxt, nextPage, filter);
    }
    /**
     * Filter courses by category
     */
    filterCoursesByCategory(): void
    {
        const filter = this.currentCategory === 'all' ? null : this.currentCategory;
        this._academyCoursesService.setFilter(filter);
    }

}
