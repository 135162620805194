import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material';

@Component({
  selector: 'user-notify-dialog',
  templateUrl: './notify-dialog.component.html',
  styleUrls: ['./notify-dialog.component.scss']
})
export class NotifyDialogComponent implements OnInit {

  showExtraToFields: boolean;
  composeForm: FormGroup;
  selectedType = 'information';

  notiTypes: any = [
    {
      full: 'System Notification',
      short: 'system'
    },
    {
      full: 'Info Notification',
      short: 'information'
    }
  ];

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<NotifyDialogComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {
    // Set the defaults
    this.composeForm = this.createComposeForm();
    this.showExtraToFields = false;
  }

  ngOnInit() {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Create compose form
   *
   * @returns {FormGroup}
   */
  createComposeForm(): FormGroup
  {
    return new FormGroup({
      subject: new FormControl(''),
      message: new FormControl(''),
      notiType: new FormControl(this.selectedType),
      localUrl: new FormControl(''),
    });
  }

  closeDialog($event) {
    this._bottomSheetRef.dismiss($event);
  }
  /**
   * Toggle extra to fields
   */
  toggleExtraToFields(): void
  {
    this.showExtraToFields = !this.showExtraToFields;
  }

}
