import {Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DataSource } from '@angular/cdk/collections';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';

import {MatSnackBar} from '@angular/material/snack-bar';
import {ContactsService} from "../contacts.service";
import {Router} from "@angular/router";

@Component({
    selector     : 'contacts-contact-list',
    templateUrl  : './contact-list.component.html',
    styleUrls    : ['./contact-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class ContactsContactListComponent implements OnInit, OnDestroy
{

    contacts: any;
    dataSource: FilesDataSource | null;
    displayedColumns = ['checkbox', 'avatar', 'name',  'email', 'phone', 'contactRel', 'buttons'];
    selectedContacts: any[];
    checkboxes: {};
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    customAvatarStyle = {
        backgroundColor: '#ff8420',
        border: '1px solid #7e7e7e',
        borderRadius: '50%',
        color: '#155a7e'
    };
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {ContactsService} _contactsService
     * @param {MatDialog} _matDialog
     * @param _matSnackBar
     * @param _router
     */
    constructor(
        private _contactsService: ContactsService,
        public _matDialog: MatDialog,
        private _matSnackBar: MatSnackBar,
        private _router: Router,
    ) {


        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.dataSource = new FilesDataSource(this._contactsService);

        this._contactsService.onContactsChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(contacts => {
                this.contacts = contacts;
                console.log(contacts);
                this.checkboxes = {};
                contacts.map(contact => {
                    this.checkboxes[contact.id] = false;
                });
            });

        this._contactsService.onSelectedContactsChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(selectedContacts => {
                for ( const id in this.checkboxes )
                {
                    if ( !this.checkboxes.hasOwnProperty(id) )
                    {
                        continue;
                    }

                    this.checkboxes[id] = selectedContacts.includes(id);
                }
                this.selectedContacts = selectedContacts;
            });

        this._contactsService.onFilterChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this._contactsService.deselectContacts();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Delete Contact
     */
    deleteContact(contact: any): void
    {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Disable?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if ( result )
            {
                this._contactsService.deleteContact(contact)
                    .then((res) => {
                        // Show the success message
                        this._matSnackBar.open('Contact Sucessfully Inactive', 'OK', {
                            verticalPosition: 'top',
                            duration        : 2000
                        });
                        // // Change the location with new one
                        // this._location.go('user/contacts' );
                    }).catch(err => {
                    this._matSnackBar.open('OOPS! Something is wrong .. Please try Again.', 'OK', {
                        verticalPosition: 'top',
                        duration        : 2000
                    });
                    console.log(err.errors[0].message);
                });
            }
            this.confirmDialogRef = null;
        });

    }

    onSelect(selected): void {
        if (selected.contactType === 'Organization') {
            this._router.navigate([`admin/company/${selected.id}`]);
        } else {
            this._router.navigate([`admin/contacts/${selected.id}`]);
        }
    }

    /**
     * On selected change
     *
     * @param contactId
     */
    onSelectedChange(contactId): void
    {
        this._contactsService.toggleSelectedContact(contactId);
    }

    /**
     * Toggle star
     *
     * @param contact
     */
    toggleStar(contact: any): void
    {
        this._contactsService.toggleStar(contact)
            .then((res) => {
                this._matSnackBar.open('Contact Saved Sucessfully', 'OK', {
                    verticalPosition: 'top',
                    duration        : 2000
                });
            }).catch(err => {
                this._matSnackBar.open('OOPS! Something is wrong .. Please try Again.', 'OK', {
                    verticalPosition: 'top',
                    duration        : 2000
                });
                console.log(err.errors[0].message);
            });
    }

    toggleFrequent(contact: any): void
    {
        this._contactsService.toggleFrequent(contact)
            .then((res) => {
                this._matSnackBar.open('Contact Saved Sucessfully', 'OK', {
                    verticalPosition: 'top',
                    duration        : 2000
                });
            }).catch(err => {
            this._matSnackBar.open('OOPS! Something is wrong .. Please try Again.', 'OK', {
                verticalPosition: 'top',
                duration        : 2000
            });
            console.log(err.errors[0].message);
        });
    }
}

export class FilesDataSource extends DataSource<any>
{
    /**
     * Constructor
     *
     * @param {ContactsService} _contactsService
     */
    constructor(
        private _contactsService: ContactsService
    )
    {
        super();
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]>
    {
        return this._contactsService.onContactsChanged;
    }

    /**
     * Disconnect
     */
    disconnect(): void
    {
    }
}
