import {Component, HostBinding, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {NotifyModel} from '../../notification.model';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {NotifyListService} from '../../notify-list.service';

@Component({
  selector: 'app-notify-list-item',
  templateUrl: './notify-list-item.component.html',
  styleUrls: ['./notify-list-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotifyListItemComponent implements OnInit, OnDestroy {

  @Input() notify: NotifyModel;

  @HostBinding('class.selected')
  selected: boolean;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _notifiyService: NotifyListService
  ) { this._unsubscribeAll = new Subject(); }

  ngOnInit() {

    this.notify = new NotifyModel(this.notify);

    // Subscribe to update on selected mail change
    this._notifiyService.onSelectedNotifiesChanged$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(selectedNotifications => {
        this.selected = false;
        if ( selectedNotifications.length > 0 ) {
          for ( const msg of selectedNotifications )
          {
            if ( msg.id === this.notify.id )
            {
              this.selected = true;
              break;
            }
          }
        }
      });


  }

  ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }


  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * On selected change
   */
  onSelectedChange(notiId: string): void
  {

    this._notifiyService.toggleSelectedNotification(notiId);
  }

}
