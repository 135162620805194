import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {CourseCatalogItemService} from "../../course-catalog-item.service";

@Component({
  selector: 'msc-course-header',
  templateUrl: './msc-course-header.component.html',
  styleUrls: ['./msc-course-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MscCourseHeaderComponent implements OnInit {

  @Input()
  courseDetails: any;

  @Input()
  userSubs: any;


  videoOptions: any;
  showImage: boolean = false;
  posterFileUrl: string;
  presignedUrl: string;
  countStudents: any;

  constructor(
      private _courseCatalogItemService: CourseCatalogItemService,
      private _changeDetectorRef: ChangeDetectorRef,
  ) {
    this.countStudents = 0;
  }

  ngOnInit() {

    this.showImage =  this.courseDetails.overviewFileName
        && this.courseDetails.overviewFileName.length > 10
        && !this.courseDetails.overviewFileName.includes('jpg');
    if (this.showImage) {
      this._courseCatalogItemService.getPresignedFile(this.courseDetails)
          .then(videoFile => {
            this.presignedUrl = videoFile;
            return this._courseCatalogItemService.getPresignedPoster(this.courseDetails);
          }).then( posterFile =>{
            this.posterFileUrl = posterFile;
            this.videoOptions = {
              autoplay: false,
              controls: true,
              liveui: true,
              height: 160,
              width: 340,
              poster: this.posterFileUrl,
              playbackRates: [0.5, 1, 1.5, 2],
              sources: [{
                src: this.presignedUrl,
                type: this.courseDetails.overviewFileType
              }]
            };
            this._changeDetectorRef.detectChanges();
          });
    }

    this._courseCatalogItemService.getStudentsSubs(this.courseDetails.id)
        .then( countStudents => {
            this.countStudents = countStudents;

        });
  }

}
