import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { FuseSharedModule } from '@fuse/shared.module';

import { ForgotPasswordComponent } from 'app/main/auth/components/forgot-password/forgot-password.component';
import {UnauthGuard} from '../../unauth.guard';
import {NgxCaptchaModule} from 'ngx-captcha';

const routes = [
    {
        path     : 'auth/reset',
        component: ForgotPasswordComponent,
        canActivate: [UnauthGuard]
    }
];

@NgModule({
    declarations: [
        ForgotPasswordComponent
    ],
  imports: [
    RouterModule.forChild(routes),

    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,

    FuseSharedModule,
    NgxCaptchaModule
  ]
})
export class ForgotPasswordModule
{
}
