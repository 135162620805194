import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatBottomSheet} from '@angular/material';
import {UploadResourceComponent} from '../upload-resource/upload-resource.component';
import {Course} from '../../course.model';

@Component({
  selector: 'course-img-resources',
  templateUrl: './course-img-resources.component.html',
  styleUrls: ['./course-img-resources.component.scss']
})
export class CourseImgResourcesComponent implements OnInit {
  @Input()
  eventData: Course;

  @Input()
  pageType: string;

  @Output()
  onFormChanges: EventEmitter<any> = new EventEmitter();

  imageArray = ['coverImage1', 'coverImage2', 'coverImage3'];

  constructor(
    private _bottomSheet: MatBottomSheet
  ) { }

  ngOnInit() {}

  openFileUploadSheet(target): void {

    const fileToUpload = this._bottomSheet.open(UploadResourceComponent, {
      data: { fileTarget: target },
    });
    fileToUpload.afterDismissed().subscribe((data) => {
      if (!data) {
        return;
      }
      if (data && data.target && data.target === 'coverImage1') {
        this.eventData.coverImage1 = data.file;
      }
      if (data && data.target && data.target === 'coverImage2') {
        this.eventData.coverImage2 = data.file;
      }
      if (data && data.target && data.target === 'coverImage3') {
        this.eventData.coverImage3 = data.file;
      }
      this.onFormChanges.emit(this.eventData);
    });

  }

}
