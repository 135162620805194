import {Inject, Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {
  APIService, CreateCourseSubsInput,
  elStatus,
  elSubStatus,
  ModelCourseCategoryFilterInput,
  ModelCourseFilterInput,
  ModelSortDirection, ModelStringKeyConditionInput
} from '../../API.service';
import * as _ from 'lodash';
import {getDeepFromObject} from '../auth/helpers';
import {SyncSettings} from '../../../@fuse/services/sync-settings.service';
import {AuthService} from '../auth/services/auth.service';
import {CATALOG_MANAGER_OPTIONS} from './course-catalog.options';
import {FuseProgressBarService} from "../../../@fuse/components/progress-bar/progress-bar.service";
import {Logger} from "aws-amplify";


@Injectable()
export class CourseCatalogService implements Resolve<any>
{

  private logger = new Logger('Course Catalog Service');

  filter: ModelCourseFilterInput;
  nextToken: string = null;

  private onCategoriesChanged = new BehaviorSubject<any>(null);
  onCategoriesChanged$ = this.onCategoriesChanged.asObservable();

  private onCoursesChanged = new BehaviorSubject<any>(null);
  onCoursesChanged$ = this.onCoursesChanged.asObservable();

  private onCoursesSubsChanged = new BehaviorSubject<any>(null);
  onCoursesSubsChanged$ = this.onCoursesSubsChanged.asObservable();

  public onCoursesFilterChanged = new BehaviorSubject<any>(null);
  onCoursesFilterChanged$ = this.onCoursesFilterChanged.asObservable();


  private nextPageToken = new BehaviorSubject<any>(this.nextToken);
  nextPageToken$ = this.nextPageToken.asObservable();

  courses: any[];
  pageSize: number;
  filterBy: string;

  /**
   * Constructor
   *
   * @param options
   * @param api
   * @param syncSettings
   * @param authService
   * @param _fuseProgressBarService
   */
  constructor(
    @Inject(CATALOG_MANAGER_OPTIONS) protected options = {},
    private api: APIService,
    private syncSettings: SyncSettings,
    private authService: AuthService,
    private _fuseProgressBarService: FuseProgressBarService,

  )
  {
    this.pageSize = this.getConfigValue('pageSize');
    this.syncSettings.onSettingsChanged$.subscribe((res) => {
      this.pageSize = res.pageSize;
    });

  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
  {
    return new Promise((resolve, reject) => {

      Promise.all([
        this.getCategories('', 20),
        this.getCourses()
      ]).then(
        () => {

          resolve();
        },
        reject
      );
    });
  }

  /**
   * Get categories
   *
   * @returns {Promise<any>}
   */
  getCategories(searchTxt?: string, pageSize?: number): Promise<any> {
    let filter: ModelCourseCategoryFilterInput;
    if (searchTxt) {
      filter = {
        category: {contains: searchTxt}
      };
    }
    const currentYear = new Date().getFullYear();
    return new Promise((resolve, reject) => {
      this.api.LIstCategoriesByStatus(
        elStatus.active,
        { beginsWith : currentYear.toString()},
        ModelSortDirection.DESC,
        filter,
        pageSize
      ).then((result) => {
        this.onCategoriesChanged.next(result.items);
        resolve(result.items);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  public refresh() {
    of(this.getCourses());
  }

  public setCatalogFilter(filterTxt: string) {
    this.onCoursesFilterChanged.next(filterTxt);
  }

  public gotoNextPage(filterTxt: string, nextPageToken: string): void {
    of(this.getCourses(filterTxt, nextPageToken));
  }
  /**
   * Get courses
   *
   * @returns {Promise<any>}
   */
  getCourses(searchTxt?: string, nextToken?: string): Promise<any> {
    this._fuseProgressBarService.show();
    let filter: ModelCourseFilterInput = {
      status: {eq: elStatus.active}
    };
    if (searchTxt !== undefined && searchTxt) {
      filter = {
        and: [{title : { contains: searchTxt}}]
      };
    }
    this.nextToken = nextToken ? nextToken : null;
    return new Promise((resolve, reject) => {
      this.api.ListCourses(filter, this.pageSize, this.nextToken)
        .then((result) => {
          this.nextToken = !_.isEmpty(result['nextToken']) ? result['nextToken'] : null;
          this.nextPageToken.next(this.nextToken );
          this.courses = result.items;
          this.onCoursesChanged.next(result.items);
          this._fuseProgressBarService.hide();
          resolve(this.courses);
        })
        .catch((err) => {
          this.catchError(err);
          console.log(err);
          reject(err);
        });
    });
  }

  SubsToCourse(course: any, status: elSubStatus): Promise<any> {
    if (!this.authService.loggedIn) {
      of(Promise.reject('User is not Logged In!'));
    }
    const userInfo = this.authService.getUserId();
    const userId = userInfo.getUsername();
    const subsInput: CreateCourseSubsInput = {
      courseId: course.id,
      title: course.title,
      description: course.description,
      category: course.category.category,
      level: course.level,
      tags: course.tags,
      userId: userId,
      status: status,
      updatedAt: new Date().toISOString(),
      createdAt: new Date().toISOString(),
      _version: null
    };
    return new Promise((resolve, reject) => {
        this.api.CreateCourseSubs(subsInput )
        .then((result) => {
          resolve(result);
        })
        .catch((err) => reject(err));
    });
  }

  getCourseSubs(courseId: string): Promise<any> {
    if (!this.authService.loggedIn) {
      of(Promise.reject('User is not Logged In!'));
    }
    const userInfo = this.authService.getUserId();
    const userId = userInfo.getUsername();
    return new Promise((resolve, reject) => {
      const filterCond: ModelStringKeyConditionInput = {
        eq: courseId
      };
      return this.api.SubsCourseByIds(
        userId,
        filterCond,
        ModelSortDirection.DESC,
        null,
        1,
        null
      ).then((result) => {
        this.onCoursesSubsChanged.next(result);
        resolve(result);
      })
        .catch((err) => reject(err));
    });
  }

  private catchError(error) {
    console.log(error);
    this.logger.debug('OOPS!', error);
    this._fuseProgressBarService.hide();
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }

}
