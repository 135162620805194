import { Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import {FuseConfigService} from '../../../../../@fuse/services/config.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AUTH_LAYOUT, AUTH_OPTIONS} from '../../auth.options';
import {getDeepFromObject} from '../../helpers';

@Component({
  selector     : 'fed-verification',
  templateUrl  : './auth-go.component.html',
  styleUrls    : ['./auth-go.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class AuthGoComponent implements OnInit
{
  errorDesc: string;
  errorOnLogin: boolean;
  private redirectDelay = 0;
  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param _router
   * @param route
   * @param options
   * @param layoutOptions
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _router: Router,
    private route: ActivatedRoute,
    @Inject(AUTH_OPTIONS) protected options = {},
    @Inject(AUTH_LAYOUT) protected layoutOptions = {}
  ) {
    this.redirectDelay = this.getConfigValue('forms.login.redirectDelay');
    // Configure the layout
    this._fuseConfigService.config = {
      layout: this.layoutOptions['layout']
    };
    this.errorOnLogin = false;
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
    // error_description=attributes+required%3A+%5Bphone_number%5D&
    // state=Lg5mBo5RjFXpkyavqIzjvC4qxDuxOMzu&
    // error=invalid_request
  ngOnInit(): void {
    let redirectTo = 'auth/login';
    this.route.queryParamMap.subscribe(queryParams => {
      const error = queryParams.has('error') ? queryParams.get('error') : null;
      if (error && error === 'invalid_request') {
        this.errorDesc = queryParams.get('error_description');
        this.errorOnLogin = true;
      } else {
        this.errorOnLogin = false;
        redirectTo = 'profile/me';
      }
      setTimeout (() => {
        this._router.navigate([redirectTo]);
      },  this.redirectDelay );
    });
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }

}
