import {Inject, Injectable} from '@angular/core';
import {UserSettings} from '../../models';
import {Hub, Logger} from '@aws-amplify/core';
import {BehaviorSubject} from 'rxjs';
import {AuthService} from '../../app/main/auth/services/auth.service';
import {UserSettingsModel} from '../../app/user-settings.model';
import {FuseProgressBarService} from '../components/progress-bar/progress-bar.service';
import {APPS_MANAGER_OPTIONS} from '../../app/app.options';
import {APIService, CreateUserSettingsInput, UpdateUserSettingsInput} from '../../app/API.service';

@Injectable()
export class SyncSettings {
  private logger = new Logger('SettingsService');
  userSettings: UserSettingsModel =  new UserSettingsModel();

  private onSettingsChanged: BehaviorSubject<any> = new BehaviorSubject(this.userSettings);
  public onSettingsChanged$ = this.onSettingsChanged.asObservable();

  constructor(
    private _auth: AuthService,
    private _fuseProgressBarService: FuseProgressBarService,
    private api: APIService,
    @Inject(APPS_MANAGER_OPTIONS) protected options = {},
  ) {}

    getUserSettings() {
        return this.userSettings;
    }

    refresh() {
      console.log(this.userSettings);
      this.onSettingsChanged.next(this.userSettings);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

  addData(data: UserSettingsModel): Promise<UserSettings> {
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
        const userInfo = this._auth.getUserId();
        const settsPayload : CreateUserSettingsInput = {
          userId :  userInfo.getUsername(),
          pageSize :  data.pageSize,
          notiType :  data.notiType,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        }
        this.api.CreateUserSettings(settsPayload)
          .then(result => {
              this.userSettings  = new UserSettingsModel(result);
              this._fuseProgressBarService.hide();
              Hub.dispatch('settings', {
                  data: this.userSettings,
                  event: 'refresh'
              });
              resolve(this.userSettings);
          }).catch( error => {
            this.catchError(error);
            reject(error);
          });
    });
  }

  saveData(data: UserSettingsModel): Promise<UserSettings> {
    this._fuseProgressBarService.show();
    console.log(data)
    return new Promise((resolve, reject) => {
      const settsPayload : UpdateUserSettingsInput = {
        id: data.id,
        pageSize :  data.pageSize,
        notiType :   data.notiType,
        updatedAt: new Date().toISOString(),
        _version: data._version
      }
      this.api.UpdateUserSettings(settsPayload)
        .then(result => {
            this.userSettings  = new UserSettingsModel(result);
            this.onSettingsChanged.next(this.userSettings);
            this._fuseProgressBarService.hide();
            Hub.dispatch('settings', {
                data: this.userSettings,
                event: 'refresh'
            });
            resolve(this.userSettings);
        }).catch( error => {
            this.catchError(error);
            reject(error);
      });
    });
  }

  private catchError(error) {
    console.log(error);
    this.logger.debug('OOPS!', error);
  }
}
