import {FuseNavigation} from '@fuse/types';

export const NOUSER: FuseNavigation[] = [
    {
        id       : 'meNoUser',
        title    : 'Me',
        permission: ['USERS', 'ADMIN'],
        translate: 'NAV.ME',
        type     : 'item',
        icon     : 'assignment_ind',
        url      : 'me'
    }
];

export const ADMINNAV: FuseNavigation[] = [
  {
    id       : 'applications',
    title    : 'Applications',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    permission: ['USERS', 'ADMIN'],
    children : [
      {
        id       : 'academy',
        title    : 'My Courses',
        permission: ['USERS', 'ADMIN'],
        translate: 'NAV.ACADEMY',
        type     : 'item',
        icon     : 'school',
        url      : '/my/courses'
      },
      {
        id       : 'catalog',
        title    : 'Courses',
        permission: ['USERS', 'ADMIN'],
        translate: 'NAV.CATALOG',
        type     : 'item',
        icon     : 'local_convenience_store',
        url      : '/school/courses'
      }
    ]
  },
  {
    id       : 'admin',
    title    : 'Administration',
    translate: 'NAV.ADMINS',
    permission: ['ADMIN'],
    type     : 'group',
    children : [
      {
        id       : 'users',
        title    : 'Users',
        permission: ['ADMIN'],
        translate: 'NAV.USERS',
        type     : 'item',
        icon     : 'supervisor_account',
        url      : '/admin/users'
      },
      {
        id       : 'adminNotifies',
        title    : 'Notifications',
        permission: ['ADMIN'],
        translate: 'NAV.NOTIFICATIONS',
        type     : 'item',
        icon     : 'notifications',
        url      : '/admin/msgs'
      },
      {
        id       : 'courses',
        title    : 'Courses',
        permission: ['ADMIN'],
        translate: 'NAV.COURSES',
        type     : 'item',
        icon     : 'notes',
        url      : '/admin/courses'
      },
      {
        id       : 'contacts',
        title    : 'Clients',
        permission: ['ADMIN'],
        translate: 'NAV.CONTACTS',
        type     : 'item',
        icon     : 'contacts',
        url      : '/admin/contacts'
      },
    ]
  },
  {
    id       : 'coach',
    title    : 'Instructor',
    translate: 'NAV.ADMINS',
    permission: ['ADMIN', 'INSTRUCTOR'],
    type     : 'group',
    children : [
      {
        id       : 'students',
        title    : 'Students',
        permission: ['ADMIN', 'INSTRUCTOR'],
        translate: 'NAV.STUDENTS',
        type     : 'item',
        icon     : 'supervisor_account',
        url      : '/coach/students'
      }
    ]
  }
];

export const USERSNAV: FuseNavigation[] = [
  {
    id       : 'applications',
    title    : 'Me',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    permission: ['USERS', 'ADMIN'],
    children : [

      {
        id       : 'academy',
        title    : 'Dashboard',
        permission: ['USERS', 'ADMIN'],
        translate: 'NAV.ACADEMY',
        type     : 'item',
        icon     : 'school',
        url      : '/my/courses'
      },
      {
        id       : 'catalog',
        title    : 'Academy',
        permission: ['USERS', 'ADMIN'],
        translate: 'NAV.CATALOG',
        type     : 'item',
        icon     : 'local_convenience_store',
        url      : '/school/courses'
      }
    ]
  },
];




