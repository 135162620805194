export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APPLICATIONS': '.',
            'ADMINS': 'Administrations',
            'USERS'    : 'Users',
            'SURVEY'    : 'Survey',
            'ACADEMY'    : 'Dashboard',
            'PROFILE'   : 'Profile',
            'EVENTS'    : 'Events',
            'COURSES'    : 'Courses',
            'CONTENT'    : 'Topics',
            'CATALOG'    : 'Explore',
            'CONTACTS'    : 'Contacts',
            'NOTIFICATIONS'    : 'Notifications',
            'STUDENTS'    : 'Students',
            'ME' : 'Me'
        }
    }
};
