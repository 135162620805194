import {Inject, Injectable} from '@angular/core';
import {AUTH_OPTIONS} from '../../main/auth/auth.options';
import {getDeepFromObject} from '../../main/auth/helpers';
import {APIService, CreateSessionInput} from '../../API.service';
import {Logger} from 'aws-amplify';
import {MapsAPILoader} from '@agm/core';

const ANALYTICS_TOKEN = 'Session';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private sessionEnable: boolean;
  private logger = new Logger('SessionService');
  private coords: any;

  public constructor(
    @Inject(AUTH_OPTIONS) protected options = {},
    private mapsAPILoader: MapsAPILoader,
    private api: APIService,
    ) {
      this.sessionEnable = this.getConfigValue('sessionManagement');
  }

  sendSession(user: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!this.sessionEnable) {
        resolve(true);
      }
      const userInfo = user.userSub;
      const payload: CreateSessionInput = {
            userId: userInfo.sub,
            location: this.coords,
            event: 'registration'
          };
      this.api.CreateSession(payload)
        .then((session) => {
        resolve(true);
      }).catch((err) => {
        this.catchError(err);
        resolve(true);
      });
    });
  }

  public getHere(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.mapsAPILoader.load().then(() => {
        if (navigator && 'geolocation' in navigator) {
          navigator.geolocation.getCurrentPosition(pos => {
            if (pos.coords) {
              this.coords = pos.coords;
              resolve(this.coords);
            }
          });
        }
      }).catch(err => {
        this.catchError(err);
        resolve(false);
      });
    });
  }

  public saveAnalytics(event: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!this.sessionEnable) {
        return Promise.resolve(true);
      }
      resolve(true);
    });
  }

  public saveAnalyticsWithUser(event: string, user: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!this.sessionEnable) {
        return Promise.resolve(true);
      }
      resolve(true);
    });
  }

  private catchError(error) {
    console.log(error);
    this.logger.debug('OOPS!', error);
  }

  private getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }
}
