import { NgModule } from '@angular/core';

import {ImgCropModule} from '../../core/img-crop/img-crop.module';
import {SyncSettings} from "../../../@fuse/services/sync-settings.service";
import {StudentsModule} from "./students/students.module";
import {defaultStudentsOptions} from "./students/students.options";

@NgModule({
    imports     : [
        ImgCropModule,
        StudentsModule.forRoot(defaultStudentsOptions),
    ],
    providers: [SyncSettings]
})
export class InstructorsModule {}
