import {Component, Input, ViewEncapsulation} from '@angular/core';

type Type = 'text' | 'password' ;

@Component({
    selector: 'password-toggle-visibility',
    templateUrl: './password-toggle-visibility.component.html',
    styleUrls: ['./password-toggle-visibility.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PasswordToggleVisibilityComponent {

    @Input()
    isVisible: boolean;

    _type: Type = 'text';

    get type(): any {
        return this.isVisible ? 'text' : 'password';
    }

}
