import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {NotifyModel} from '../../../../main/admin/push-notify/notification.model';

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss']
})
export class NotificationItemComponent implements OnInit {

  showExtraToFields: boolean;

  @Input() notify: NotifyModel;
  constructor() { }


  @HostBinding('class.selected')
  selected: boolean;

  ngOnInit() {
  }

  toggleExtraToFields(): void
  {
    this.showExtraToFields = !this.showExtraToFields;
  }


}
