import {Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from "../../../../../../@fuse/animations";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FuseConfirmDialogComponent} from "../../../../../../@fuse/components/confirm-dialog/confirm-dialog.component";
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from "@angular/material/snack-bar";
import {Observable, of, Subject} from "rxjs";
import {FuseSidebarService} from "../../../../../../@fuse/components/sidebar/sidebar.service";
import {takeUntil} from "rxjs/operators";
import {DataSource} from "@angular/cdk/collections";
import {StudentsService} from "../../students.service";

@Component({
  selector: 'student-list',
  templateUrl: './student-list.component.html',
  styleUrls: ['./student-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class StudentListComponent implements OnInit, OnDestroy
{
  @ViewChild('dialogContent', {static: false})
  dialogContent: TemplateRef<any>;
  contacts: any;
  user: any;
  dataSource: StudentsDataSource | null;
  displayedColumns = ['checkbox', 'name', 'email', 'phone_verified', 'email_verified',
    'account_status', 'user_status', 'updatedAt', 'createdAt', 'buttons'];
  selectedContacts: any[];
  checkboxes: {};
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  horizontalPosition: MatSnackBarHorizontalPosition = 'left';
  verticalPosition: MatSnackBarVerticalPosition = 'top';


  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param _studentsService
   * @param _fuseSidebarService
   * @param {MatDialog} _matDialog
   * @param _snackBar
   */
  constructor(
      private _studentsService: StudentsService,
      private _fuseSidebarService: FuseSidebarService,
      public _matDialog: MatDialog,
      private _snackBar: MatSnackBar
  )
  {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {


    this.dataSource = new StudentsDataSource(this._studentsService);

    this._studentsService.onUsersChanged$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(contacts => {
          this.contacts = contacts;

          this.checkboxes = {};
          contacts.map(contact => {
            this.checkboxes[contact.id] = false;
          });
        });

    this._studentsService.onSelectedUsersChanged$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(selectedContacts => {
          for ( const id in this.checkboxes )
          {
            if ( !this.checkboxes.hasOwnProperty(id) )
            {
              continue;
            }

            this.checkboxes[id] = selectedContacts.includes(id);
          }
          this.selectedContacts = selectedContacts;
        });

    this._studentsService.onFilterChanged
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(() => {
          this._studentsService.deselectUsers();
        });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  /**
   * On select
   *
   * @param selected
   */
  onSelect(selected): void {
    this._studentsService.setSelectedUser(selected);
    this._fuseSidebarService.getSidebar('student-view-sidebar').toggleOpen();
  }
  /**
   * Delete Contact
   */
  disableStudent(contact): void {
    this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });

    this.confirmDialogRef.componentInstance.confirmMessage = 'Disable User(s) Are your sure?';
    this.confirmDialogRef.afterClosed().subscribe(result => {
      if ( result ) {
        this._studentsService.disableUser(contact.id).then(res => {
          of(this._studentsService.refresh());
        }).catch(err => {
          const msgShow = err && (err.Message || err.message) ? err.message
              : 'OOPs!! Something went Wrong.. Try Again or Call for Support';
          this.popMsg(msgShow);
        });
      }
      this.confirmDialogRef = null;
    });

  }

  enableStudent(contact): void {
    this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });

    this.confirmDialogRef.componentInstance.confirmMessage = 'Enable User(s) Are your sure?';
    this.confirmDialogRef.afterClosed().subscribe(result => {
      if ( result ) {
        this._studentsService.enableUser(contact.id).then(res => {
          of(this._studentsService.refresh());
        }).catch(err => {
          const msgShow = err && (err.Message || err.message) ? err.message
              : 'OOPs!! Something went Wrong.. Try Again or Call for Support';
          this.popMsg(msgShow);
        });
      }
      this.confirmDialogRef = null;
    });

  }
  /**
   * On selected change
   *
   * @param contactId
   */
  onSelectedChange(contactId): void
  {
    this._studentsService.toggleSelectedUser(contactId);
  }

  private popMsg(msgIn: string) {
    this._snackBar.open(msgIn, 'End now', {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

}

export class StudentsDataSource extends DataSource<any> {
  /**
   * Constructor
   *
   */
  constructor(
      private _studentsService: StudentsService
  ) {
    super();
  }

  /**
   * Connect function called by the table to retrieve one stream containing the data to render.
   * @returns {Observable<any[]>}
   */
  connect(): Observable<any[]>
  {
    return this._studentsService.onUsersChanged$;
  }

  /**
   * Disconnect
   */
  disconnect(): void {}
}
