import {Inject, Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {
  APIService,
  CreateCourseCategoryInput,
  DeleteCourseCategoryInput,
  elStatus,
  ModelCourseCategoryFilterInput, ModelSortDirection, UpdateCourseCategoryInput,
} from '../../../API.service';
import {getDeepFromObject} from '../../auth/helpers';
import {COURSES_MANAGER_OPTIONS} from './courses.options';
import * as _ from 'lodash';
import {Logger} from '@aws-amplify/core';
import {SyncSettings} from '../../../../@fuse/services/sync-settings.service';
import {FuseProgressBarService} from '../../../../@fuse/components/progress-bar/progress-bar.service';
import {CourseCat} from './category.model';
type ModelPartial<T> = {
  [P in keyof  T]?: T[P]
};

@Injectable()
export class CoursesCategoryService {
  private logger = new Logger('Category Service');

  categories: CourseCat[];
  category: CourseCat = new CourseCat();
  pageSize: number;
  nextToken: string = null;


  private categoryOnChanged = new BehaviorSubject<CourseCat>(this.category);
  categoryOnChanged$ = this.categoryOnChanged.asObservable();

  private onCategoriesChanged: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public onCategoriesChanged$: Observable<any[]> = this.onCategoriesChanged.asObservable();

  private nextPageToken: BehaviorSubject<any> = new BehaviorSubject(this.nextToken);
  public nextPageToken$: Observable<any[]> = this.nextPageToken.asObservable();

  private _onErrorOrSuccess = new BehaviorSubject<any>(null);
  onErrorOrSuccess$ = this._onErrorOrSuccess.asObservable();

  /**
   * Constructor
   *
   * @param options
   * @param _api
   * @param syncSettings
   * @param _fuseProgressBarService
   */
  constructor(
      @Inject(COURSES_MANAGER_OPTIONS) protected options = {},
      private _api: APIService,
      private syncSettings: SyncSettings,
      private _fuseProgressBarService: FuseProgressBarService
  )
  {
    this.pageSize = this.getConfigValue('pageSize');
    this.syncSettings.onSettingsChanged$.subscribe((res) => {
      this.pageSize = res.pageSize;
      // this._api.OnCreateCourseCategoryListener
      //   .subscribe(resCreate => {
      //       this.refresh();
      //   });
      // this._api.OnDeleteCourseCategoryListener
      //   .subscribe(resDel => {
      //     this.refresh();
      //   });
      // this._api.OnUpdateCourseCategoryListener
      //   .subscribe(resDel => {
      //     this.refresh();
      //   });
    });
  }

  ngOnDestroy() {
      console.log('--------------------------------------');
  }

    /**
   * Resolver
   *
   * @returns {Observable<any> | Promise<any> | any}
   */

  public refresh() {
      of(this.getCategories());
  }

  public goNextPage(filterTxt: string, nextPageToken: string) {
    of(this.getCategories(filterTxt,  nextPageToken));
  }

  getCategories(searchTxt?: string, nextToken?: string): Promise<any> {
      let filter: ModelCourseCategoryFilterInput ;
      if (searchTxt !== undefined && searchTxt) {
          filter = {
              category: { contains: searchTxt}
          };
      }
      this.nextToken = nextToken ? nextToken : null;
      this._fuseProgressBarService.show();
      const currentYear = new Date().getFullYear();
      return new Promise((resolve, reject) => {
          this._api.LIstCategoriesByStatus(
            elStatus.active,
            { beginsWith : currentYear.toString()},
            ModelSortDirection.DESC,
            filter,
            this.pageSize,
            this.nextToken
          ).then((result) => {
              this.nextToken = !_.isEmpty(result['nextToken']) ? result['nextToken'] : null;
              this.nextPageToken.next(this.nextToken );
              this.categories = result.items;
              this.onCategoriesChanged.next(result.items);
              this._fuseProgressBarService.hide();
              resolve(this.categories);
          }).catch((err) => {
            this.catchError(err);
            reject(err);
          });
      });
  }

  createCategory(data: CourseCat ): Promise<any>  {
    this._fuseProgressBarService.show();
    return new Promise(async (resolve, reject) => {
      data.createdAt = new Date().toISOString();
      data.updatedAt = new Date().toISOString();
      data.status = elStatus.active;
      data._version = null;
      let payload: CreateCourseCategoryInput = this.mergeObjs(this.category, data);
      payload = this.cleanObj(payload);
      this._api.CreateCourseCategory(payload)
        .then(privacySetts => {
          this.category = this.mergeObjs(this.category, privacySetts);
          this.categoryOnChanged.next(this.category);
          this._onErrorOrSuccess.next({type: 'SUCCESS'});
          this._fuseProgressBarService.hide();
          resolve(this.category);
        }).catch(error => {
          this._onErrorOrSuccess.next({type: 'error', msg: error.message});
          this.catchError({type: 'error', msg: error.message});
          reject(error);
      });
    });
  }

  updateCategory(propsIn: any): Promise<any> {
    this._fuseProgressBarService.show();
    propsIn.updatedAt = new Date().toISOString();
    return new Promise((resolve, reject) => {
      const payload: UpdateCourseCategoryInput = propsIn;
      this._api.UpdateCourseCategory(payload)
        .then(catSetts => {
          this.category = this.cleanObj(catSetts);
          this.categoryOnChanged.next(this.category);
          this._fuseProgressBarService.hide();
          this._onErrorOrSuccess.next({type: 'SUCCESS'});
          resolve(this.category);
        }).catch( error => {
        this.catchError({type: 'error', msg: error});
        reject(error);
      });
    });
  }

  courseListByCategory(propsIn: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._api.ListCoursesByCategory(propsIn.id, ModelSortDirection.DESC, null, 3)
        .then(cList => {
          resolve(cList.items);
        }).catch(error => {
          this.catchError({type: 'error', msg: error});
          reject(error);
      });
    });
  }

  deleteCategory(propsIn: any): Promise<any> {
    propsIn.updatedAt = new Date().toISOString();
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
        const payloadUpdate: UpdateCourseCategoryInput = {
            id: propsIn.id,
            _version: propsIn._version,
            status: elStatus.inactive,
            updatedAt: propsIn.updatedAt
        };
        this._api.UpdateCourseCategory(payloadUpdate)
          .then(updateSetts => {
            const payload: DeleteCourseCategoryInput = {
              id: updateSetts.id,
              _version: updateSetts._version
            };
            return this._api.DeleteCourseCategory(payload);
        }).then(delSetts => {
          this._fuseProgressBarService.hide();
          this._onErrorOrSuccess.next({type: 'SUCCESS'});
          resolve(delSetts);
        }).catch( error => {
          this.catchError({type: 'error', msg: error});
          reject(error);
      });
    });
  }

  private catchError(error) {
    console.log(error);
    this.logger.debug('OOPS!', error);
    this._fuseProgressBarService.hide();
  }

  getConfigValue(key: string): any {
      return getDeepFromObject(this.options, key, null);
  }

  cleanObj(objB: any): any {
    const cleanArray = ['__typename', '_deleted', '_lastChangedAt', 'courses'];
    const newObj = {};
    for (const key in objB) {
      if (cleanArray.indexOf(key) === -1){
        newObj[key] = objB[key];
      }
    }
    return newObj;
  }

  mergeObjs(objA: any, objB: ModelPartial<any>) {
    return {...objA, ...objB};
  }
}
