import {Inject, Injectable, OnDestroy} from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {
    APIService,
    CourseCategoryInput,
    CreateCourseCategoryInput,
    CreateCourseInput,
    elStatus, elSubStatus,
    ModelCourseAssetsFilterInput,
    ModelCourseFilterInput,
    ModelCourseSubsFilterInput,
    ModelSortDirection,
    UpdateContentInput,
    UpdateCourseAssetsInput,
    UpdateCourseInput,
} from '../../../API.service';
import {COURSES_MANAGER_OPTIONS} from './courses.options';
import {getDeepFromObject} from '../../auth/helpers';
import AWSConfig from './../../../../aws-exports';
import * as S3 from 'aws-sdk/clients/s3';
import {CoursesCategoryService} from './courses-category.service';
import {FuseUtils} from '../../../../@fuse/utils';
import {FuseProgressBarService} from '../../../../@fuse/components/progress-bar/progress-bar.service';
import {CoursesResourcesService} from './courses-resources.service';
import {API} from 'aws-amplify';
import {Hub, Logger} from "@aws-amplify/core";
import {Course} from "./course/course.model";
import {APICustomService} from "../../../API.customize.service";
import * as _ from "lodash";
import {AuthService} from "../../auth/services/auth.service";


@Injectable()
export class CourseService implements Resolve<any>, OnDestroy
{
    private logger = new Logger('Courses Service');
    routeParams: any;
    course: any;
    assets: any[];
    pageSize: number;
    bucketTranscoded: any;
    nextAssetsPageToken: any = null;

    private onCourseChanged = new BehaviorSubject<any>(null);
    onCourseChanged$ = this.onCourseChanged.asObservable();

    private onAssetsChanged = new BehaviorSubject<any>(null);
    onAssetsChanged$ = this.onAssetsChanged.asObservable();

    private onCategoriesChanged = new BehaviorSubject<any>(null);
    onCategoriesChanged$ = this.onCategoriesChanged.asObservable();

    private _onErrorOrSuccess = new BehaviorSubject<any>(null);
    onErrorOrSuccess$ = this._onErrorOrSuccess.asObservable();

    private nextAssetPageToken: BehaviorSubject<any> = new BehaviorSubject(this.nextAssetsPageToken);
    nextAssetPageToken$: Observable<any> = this.nextAssetPageToken.asObservable();

    bucket: any;
    bucketTarget: any;

    private _unsubscribeAll: Subject<any>;
    /**
     * Constructor
     *
     * @param options
     * @param api
     * @param customApi
     * @param _auth
     * @param courseCategories
     * @param courseResources
     * @param _fuseProgressBarService
     */
    constructor(
        @Inject(COURSES_MANAGER_OPTIONS) protected options = {},
        private api: APIService,
        private customApi: APICustomService,
        private _auth: AuthService,
        private courseCategories: CoursesCategoryService,
        private courseResources: CoursesResourcesService,
        private _fuseProgressBarService: FuseProgressBarService,
    ) {
        // Set the defaults
        this.bucket = AWSConfig['aws_user_files_s3_bucket'];
        this.bucketTarget = this.getConfigValue('S3BucketName');
        this.bucketTranscoded = this.getConfigValue('S3BucketTranscoder');
        this.pageSize = this._auth.getUserSettings().pageSize;
    }

    getCourseId(): string {
      return this.course.id !== null ? this.course.id : this.routeParams.id;
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    public sendMsg(msg: any) {
        this._onErrorOrSuccess.next(msg);
    }
    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getCourse(null),
                this.courseCategories.getCategories(),
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }
    /**
     * Get course
     *
     * @returns {Promise<any>}
     */


    showAll(pageSize) {
        this.pageSize = pageSize;
        of(this.getAssets(this.getCourseId()));
    }

    refreshAfterUpload(courseId: string) {
        of(this.getCourseAfterUpload(courseId));
    }

    refresh(courseId: string) {
      of(this.getCourse(courseId));
    }

    refreshAssets(courseId: string) {
        this.pageSize = this._auth.getUserSettings().pageSize;
        of(this.getAssets(courseId));
    }

    public goNextAssetsPage(filterTxt: string, nextPageToken: string) {

        of(this.getAssets(this.getCourseId(), filterTxt,  nextPageToken));
    }

    getCourse(courseId?: string): Promise<any> {
        this._fuseProgressBarService.show();
        const courseIdVal = courseId !== null ? courseId : this.routeParams.id;
        return new Promise((resolve, reject) => {
            this.customApi.GetCourse(courseIdVal)
                .then((result) => {
                    this.course = result || new Course();
                    return this.getPresignedFile(this.course);
                }).then(result => {
                    this.course.presignedUrl = result;
                    return this.getPresignedPoster(this.course);
                }).then(resPoster => {
                    this.course.posterFileUrl = resPoster;
                    this.onCourseChanged.next(this.course);
                    this._fuseProgressBarService.hide();
                    resolve(this.course);
                }).catch((err) => {
                    this.catchError(err);
                    reject(err)
                }) ;
        });
    }

    getCourseAfterUpload(courseId?: string): Promise<any> {
        this._fuseProgressBarService.show();
        const courseIdVal = courseId !== null ? courseId : this.routeParams.id;
        return new Promise((resolve, reject) => {
            this.customApi.GetCourse(courseIdVal)
                .then((result) => {
                    this.course = result || new Course();
                    return this.getTransVideoInfo(this.course.transcoderJobId);
                }).then((resultTrans) => {
                    const readyTrans = resultTrans && resultTrans.Output.Status === 'Complete';
                    if (readyTrans) {
                        return this.getPresignedFile(this.course);
                    } else {
                        const sourceFile =this.course.overviewFileName ;
                        return this.getPresignedSourceFile(sourceFile);
                    }
                }).then(result => {
                    this.course.presignedUrl = result;
                    this.course.posterFileUrl = 'assets/images/courses/course-default.png';
                    this.onCourseChanged.next(this.course);
                    this._fuseProgressBarService.hide();
                    resolve(this.course);
                }).catch((err) => {
                    this.catchError(err);
                    reject(err)
                }) ;
        });
    }

    getAssets(courseId: string, searchTxt?: string, nextToken?: string): Promise<any> {
        let filter: ModelCourseAssetsFilterInput = {
            courseId: {eq: courseId}
        };
        if (searchTxt !== undefined && searchTxt) {
            filter.title = { contains: searchTxt};
        }
        this.nextAssetsPageToken = nextToken ? nextToken : null;
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            const pageSizeFix = this.pageSize < 99 ? 99 : this.pageSize ;
            this.customApi.ListCourseAssetss(
                filter,
                pageSizeFix,
                this.nextAssetsPageToken)
                .then((result) => {
                    this.nextAssetsPageToken = !_.isEmpty(result['nextToken']) ? result['nextToken'] : null;
                    this.nextAssetPageToken.next(this.nextAssetsPageToken );
                    this.assets = _.orderBy(result.items, 'order', 'asc');
                    this.onAssetsChanged.next(this.assets);
                    this._fuseProgressBarService.hide();
                    resolve(this.assets);
                })
                .catch((err) => {
                    this.catchError(err);
                    reject(err);
                });
        });
    }

    getUserSubscriptions(startMonth: string): Promise<any> {

        let filter: ModelCourseSubsFilterInput =  {
            courseId: { eq: this.getCourseId()}
        };
        return new Promise((resolve, reject) => {
            this.api.SubsCourseByStatus(
                elSubStatus.Enrolled,
                {beginsWith: startMonth},
                ModelSortDirection.DESC,
                filter,
                9999)
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    this.catchError(err);
                    reject(err);
                });
        });
    }
    /**
       * Save Course
       *
       * @returns {Promise<any>}
       * @param newContent
       */

      saveVideoMeta(newContent: any): Promise<any> {
          const cDate = new Date().toISOString();
          this._fuseProgressBarService.show();
          const courseInput: UpdateCourseInput= {
              id: newContent.id,
              timeAprox: newContent.timeAprox,
              updatedAt: cDate,
              _version: newContent._version
          };
          return new Promise((resolve, reject) => {
              this.api.UpdateCourse( courseInput)
                  .then((result) => {
                      this.course = result;
                      this._fuseProgressBarService.hide();
                      this.onCourseChanged.next(this.course);
                      resolve(this.course);
                  })
                  .catch((err) => {
                      this.catchError(err);
                      reject(err);
                  });
          });
      }

    saveCoverImages(propsIn: any): Promise<any> {
      propsIn.updatedAt = new Date().toISOString();
      return new Promise((resolve, reject) => {
        const payload: UpdateCourseInput = {
          id: propsIn.id,
          coverImage: propsIn.coverImage,
          coverImage1: propsIn.coverImage1,
          coverImage2: propsIn.coverImage2,
          coverImage3: propsIn.coverImage3,
          _version: propsIn._version
        };
        this.api.UpdateCourse(payload)
          .then(coverResults => {
              this.course = coverResults;
              this.onCourseChanged.next(this.course);
              resolve(this.course);
          }).catch( error => {
            this.catchError(error);
            reject(error);
        });
      });
    }

    saveCourseDetails(propsIn: any): Promise<any> {
        propsIn.updatedAt = new Date().toISOString();
        return new Promise((resolve, reject) => {
            const payload: UpdateCourseInput = {
                id: propsIn.id,
                learnDescription: propsIn.learnDescription,
                level: propsIn.level,
                requirements: propsIn.requirements,
                tags: propsIn.tags,
                _version: propsIn._version
            };
            this.api.UpdateCourse(payload)
                .then(couseResult => {
                    this.course = couseResult;
                    this.onCourseChanged.next(this.course);
                    resolve(this.course);
                }).catch( error => {
                    console.log(error)
                    reject(error);
            });
        });
    }

    getTransVideoInfo(jobId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            if (jobId===null || jobId===undefined) {
                resolve(null);
            } else {
                const init = {
                    queryStringParameters: {
                        jobId: jobId
                    },
                };
                API.get('coreApiKTC', '/video', init)
                    .then(result => {
                        resolve(result);
                    }).catch((error: any) => {
                    this.catchError(error);
                    reject(error);
                });
            }
        });
    }

    saveMediaVideo(media: Course,  fileInfo: any): Promise<any> {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            const transTarget = `${this.bucketTarget}/video/${media.id}/overview/${fileInfo.file.name}`;
            const videoTimeStamp = new Date().getTime();
            const body = {
                body: {
                    fileKey: transTarget,
                    filePath: `${media.id}/overview`,
                    filePrefix: `${fileInfo.file.name.split('.')[0]}-${videoTimeStamp}`
                }
            };
            API.post('coreApiKTC', '/video', body)
                .then(transResult => {
                    const transVideoUrl = `${body.body.filePath}/${body.body.filePrefix}`;
                    const transVideoJson = JSON.stringify(transResult);
                    const payload: UpdateCourseInput = {
                        id: media.id,
                        overviewFileName: transTarget,
                        overviewFileType: fileInfo.file.type,
                        overviewFileSize: fileInfo.file.size,
                        overviewSourceMedia: transVideoUrl,
                        transcoderMeta: transVideoJson,
                        transcoderJobId: transResult.id,
                        _version: media._version
                    };
                    return this.api.UpdateCourse(payload);
                }).then((result) => {
                    this._fuseProgressBarService.hide();
                    resolve(true);
                }).catch((err) => {
                    this.catchError(err);
                    reject(err);
                });
        });
    }

    addCourse(newCourse: any): Promise<any> {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this.api.GetCourseCategory(newCourse.courseCategoryId)
              .then((resCat) => {
                const categoryInput: CourseCategoryInput = FuseUtils.pluckProps(resCat, ['courses', '__typename']);
                const courseInput: CreateCourseInput = {
                  title: newCourse.title,
                  category: categoryInput,
                  description: newCourse.description,
                  timeAprox: newCourse.timeAprox,
                  courseCategoryId: newCourse.courseCategoryId,
                  categoryId: newCourse.courseCategoryId,
                  courseUrl: newCourse.courseUrl,
                  courseType: newCourse.courseType,
                  status: newCourse.status,
                  _version: newCourse._version
                };
                return this.api.CreateCourse( courseInput);
              }).then((res) => {
                this.course = res;
                this.onCourseChanged.next(res);
                this._fuseProgressBarService.hide();
                resolve(this.course);
              })
              .catch((err) => {
                this.catchError(err);
                reject(err);
              });
        });

    }

    saveCourse(newCourse: any): Promise<any> {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
          this.api.GetCourseCategory(newCourse.courseCategoryId)
            .then((resCat) => {
              const categoryInput: CreateCourseCategoryInput = FuseUtils.pluckProps(resCat, ['courses', '__typename']);
              const courseInput: UpdateCourseInput = {
                id: newCourse.id,
                title: newCourse.title,
                // category: categoryInput,
                categoryId: newCourse.courseCategoryId,
                description: newCourse.description,
                timeAprox: newCourse.timeAprox,
                courseCategoryId: newCourse.courseCategoryId,
                courseUrl: newCourse.courseUrl,
                courseType: newCourse.courseType,
                status: newCourse.status,
                _version: newCourse._version
              };
              // console.log(courseInput);
              return this.api.UpdateCourse(courseInput);
            }).then((res) => {
              this.onCourseChanged.next(res);
              this._fuseProgressBarService.hide();
              resolve(res);
            })
            .catch((err) => {
                this.catchError(err);
                reject(err);
            });
        });

    }


    toggleInactive(liveContent: any): Promise<any> {
        const isInactive = liveContent.status==='active' ? elStatus.inactive : elStatus.active;
        liveContent.updatedAt = new Date().toISOString();
        this._fuseProgressBarService.show();
        const contentInput: UpdateCourseAssetsInput = {
            id: liveContent.id,
            starred: !liveContent.starred,
            status: isInactive,
            updatedAt: liveContent.updatedAt,
            _version: liveContent._version
        };
        return new Promise((resolve, reject) => {
            this.api.UpdateCourseAssets( contentInput)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    toggleLive(liveCourse: any, status: any): Promise<any> {
        this._fuseProgressBarService.show();
        liveCourse.updatedAt = new Date().toISOString();
        const courseInput: UpdateCourseInput = {
            id: liveCourse.id,
            status: status,
            updatedAt: liveCourse.updatedAt,
            _version: liveCourse._version
        };
        return new Promise((resolve, reject) => {
            this.api.UpdateCourse( courseInput)
              .then((res) => {
                this.onCourseChanged.next(res);
                this._fuseProgressBarService.hide();
                resolve(res);
              })
              .catch((err) => {
                this.catchError(err);
                reject(err);
              });
        });
    }

    toggleCourseTrending(liveCourse: any, trending: string): Promise<any> {
        liveCourse.updatedAt = new Date().toISOString();
        const contentInput: UpdateCourseInput = {
            id: liveCourse.id,
            trending: trending,
            updatedAt: liveCourse.updatedAt,
            _version: liveCourse._version
        };
        return new Promise((resolve, reject) => {
            this.api.UpdateCourse( contentInput)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    toggleStar(liveContent: any): Promise<any> {
      const  cDate = new Date().toISOString();
      const contentInput: UpdateCourseAssetsInput = {
        id: liveContent.id,
        starred: !liveContent.starred,
        updatedAt: cDate,
        _version: liveContent._version
      };
      return new Promise((resolve, reject) => {
        this.api.UpdateCourseAssets( contentInput)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }

    toggleShowAsPreview(liveContent: any): Promise<any> {
      const  cDate = new Date().toISOString();
      const contentInput: UpdateCourseAssetsInput = {
        id: liveContent.id,
        showAsOverview: !liveContent.showAsOverview,
          updatedAt: cDate,
        _version: liveContent._version
      };
        return new Promise((resolve, reject) => {
            this.api.UpdateCourseAssets( contentInput)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    toggleImportant(liveContent: any): Promise<any> {
      const  cDate = new Date().toISOString();
      const contentInput: UpdateCourseAssetsInput = {
        id: liveContent.id,
        important: !liveContent.important,
        updatedAt: cDate,
        _version: liveContent._version
      };
        return new Promise((resolve, reject) => {
            this.api.UpdateCourseAssets( contentInput)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    saveContentList(contentList: any[]): Promise<any> {
        const promiseList = [];
        let i = 0;
        for (const content of contentList) {
            content.order = i;
            const promise = this.saveOrderedContent(content);
            promiseList.push(promise);
            i++;
        }
        return new Promise((resolve, reject) => {
            Promise.all([
                promiseList
            ]).then(
                (resArray) => {
                    // console.log(resArray)
                    resolve(resArray);
                },
                reject
            );
        });
    }

    saveOrderedContent(updContent: any): Promise<any> {
        const cDate = new Date().toISOString();
        const contentInput: UpdateCourseAssetsInput = {
            id: updContent.id,
            order: updContent.order,
            updatedAt: cDate,
            _version: updContent._version
        };
        // console.log(contentInput);
        return new Promise((resolve, reject) => {
            this.api.UpdateCourseAssets( contentInput)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    console.log(err)
                    reject(err);
                });
        });

    }

    takeScreenShot(linkUrl: string): Promise<any> {
      this._fuseProgressBarService.show();
      return new Promise((resolve, reject) => {
        const init = {
          queryStringParameters: {
            url: linkUrl
          },
        };
        API.get('coreApiKTC', '/screenshot', init)
          .then(result => {
            this._fuseProgressBarService.hide();
            resolve(result);
          }).catch(error => {
            this._fuseProgressBarService.hide();
            reject(error);
        });
      });
    }

    syncS3(): any {
        return new S3(
            {
                accessKeyId: this.getConfigValue('accessKeyId'),
                secretAccessKey: this.getConfigValue('secretAccessKey'),
                region: this.getConfigValue('region')
            });
    }

    getPresignedFile(media: Course): Promise<any> {
        return new Promise((resolve, reject) => {
            if (media && media.overviewSourceMedia && media.overviewSourceMedia.length > 10) {
                const params = {Bucket: '', Key: '', Expires: 3600};
                const newConn = this.syncS3();
                params.Bucket = this.bucketTranscoded;
                params.Key = `${media.overviewSourceMedia}-web-720p.mp4`;
                newConn.getSignedUrl('getObject', params, (err, urlLink) => {
                    if (err) {
                        this.catchError({type: 'error', msg: err.message});
                        resolve('assets/images/backgrounds/blue-background.jpg');
                    } else {
                        resolve(urlLink);
                    }
                });
            } else {
                resolve('assets/images/backgrounds/blue-background.jpg');
            }
        });
    }

    getPresignedPoster(media: Course): Promise<any> {
        return new Promise((resolve, reject) => {
            if (media && media.overviewSourceMedia && media.overviewSourceMedia.length > 10) {
                const params = {
                    Bucket:  this.bucketTranscoded,
                    Key: `${media.overviewSourceMedia}thumbs-720p-00001.png`,
                    Expires: 3600
                };
                const newConn = this.syncS3();
                newConn.getSignedUrl( 'getObject', params, (err, urlLink) => {
                    if (err) {
                        resolve('assets/images/courses/course-default.png');
                    } else {
                        resolve(urlLink);
                    }
                });
            } else {
                resolve('assets/images/courses/course-default.png');
            }
        });
    }

    getPresignedSourceFile(fileSource: string): Promise<any> {
        return new Promise((resolve, reject) => {
            if (fileSource && fileSource.length > 10) {
                const params = {Bucket: '', Key: '', Expires: 1000};
                const newConn = this.syncS3();
                params.Bucket = this.bucket;
                params.Key = fileSource;
                newConn.getSignedUrl('getObject', params, (err, urlLink) => {
                    if (err) {
                        this.catchError({type: 'error', msg: err.message});
                        resolve('assets/images/backgrounds/blue-background.jpg');
                    } else {
                        resolve(urlLink);
                    }
                });
            } else {
                resolve('assets/images/backgrounds/blue-background.jpg');
            }
        });
    }

    getConfigValue(key: string): any {
        return getDeepFromObject(this.options, key, null);
    }

    private catchError(error) {
        console.log(error);
        this.logger.debug('OOPS!', error);
        this._fuseProgressBarService.hide();
    }
}
