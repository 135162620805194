import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {MscVideoPlayerComponent} from "../msc-video-player/msc-video-player.component";
import {Subject} from "rxjs";
import {CourseCatalogItemService} from "../../course-catalog-item.service";

@Component({
  selector: 'msc-courses-overview',
  templateUrl: './msc-courses-overview.component.html',
  styleUrls: ['./msc-courses-overview.component.scss']
})
export class MscCoursesOverviewComponent implements OnInit,  OnDestroy {
  @Input()
  courseTopics: any[];

  @Input()
  userSubs: any;

  private _unsubscribeAll: Subject<any>;

  dialogRef: any;

  constructor(
      public _matDialog: MatDialog,
      private _courseCatalogService: CourseCatalogItemService,
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {}

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    onVideoPlayer(courseTopic) {
        this._courseCatalogService.getPresignedOverViewFile(courseTopic.topic)
            .then(preSignUrl => {
                if (courseTopic.topic) {
                    this.dialogRef = this._matDialog.open(MscVideoPlayerComponent, {
                        panelClass: 'contact-form-dialog',
                        data      : {
                            presignUrl: preSignUrl,
                            fileType: courseTopic.topic.fileType
                        }
                    });
                    this.dialogRef.afterClosed()
                        .subscribe(response => {
                            if (!response) {
                                return;
                            }
                        });
                }
            })
    }

    onQuizPlayer(courseTopic) {

    }

    resumeTorQ(topicId: string) {
      console.log(topicId);
    }

}
