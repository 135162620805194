import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {MemberShip} from "../../org.model";

@Component({
  selector: 'license-popup',
  templateUrl: './license-popup.component.html',
  styleUrls: ['./license-popup.component.scss']
})
export class LicensePopupComponent implements OnInit {

  memberShip: MemberShip;
  memberShipForm: FormGroup;

  constructor(
      private _bottomSheetRef: MatBottomSheetRef<LicensePopupComponent>,
      @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
      private _formBuilder: FormBuilder,
  ) {
    this.memberShip = new MemberShip(data);
    this.memberShipForm = this.createMemberShipForm();
  }

  ngOnInit() {
  }

  createMemberShipForm(): FormGroup
  {
    return this._formBuilder.group({
      id                  : [this.memberShip.id],
      contactId           : [this.memberShip.contactId],
      noLicenses          : [this.memberShip.noLicenses,  Validators.required],
      prefix               : [this.memberShip.prefix, [Validators.required]],
    });
  }

  addLicense() {
    const data = this.memberShipForm.getRawValue();
    this._bottomSheetRef.dismiss(data);
  }

  cancelAll() {
    this._bottomSheetRef.dismiss(null );
  }

}
