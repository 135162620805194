import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {Course} from "../../course.model";
import {AdminCourseVideoUploadComponent} from "../admin-course-video-upload/admin-course-video-upload.component";
import * as moment from "moment";
import {CourseService} from "../../../course.service";

@Component({
  selector: 'admin-course-video-overview',
  templateUrl: './admin-course-video-overview.component.html',
  styleUrls: ['./admin-course-video-overview.component.scss']
})
export class AdminCourseVideoOverviewComponent implements OnInit, AfterViewInit , OnChanges{

  @Input()
  media: Course;
  @Input()
  action: string;

  @Output()
  metadata = new EventEmitter<any>();

  videoExtensions : any[] =['mp4', 'OGG', 'ogg', 'MP4', 'WMV', 'wmv', 'WEBM', 'webm', 'FLV', 'flv', 'AVI', 'avi'];
  ifVideo: boolean = false;

  onloadShow: boolean;
  videoDuration: any;
  video: any;

  constructor(
      private _bottomSheet: MatBottomSheet,
      private _courseService: CourseService,
  ) {this.onloadShow = false;}

  ngOnInit() {
    const extension = this.media.presignedUrl.split('.').pop();
    this.ifVideo = this.videoExtensions.includes(extension.slice(0, 3));
  }

  ngAfterViewInit() {}

  ngOnChanges(changes: SimpleChanges) {}

  saveMetaData() {
    this.video = document.querySelector('video');
    this.videoDuration = moment.utc(moment.duration(this.video.duration, 'seconds').asMilliseconds()).format("HH:mm:ss");
    this.metadata.emit(this.videoDuration);
  }

  uploadMedia() {
    const dataToSave = this._bottomSheet.open(AdminCourseVideoUploadComponent, {
      disableClose: true,
      data: this.media
    });
    dataToSave.afterDismissed().subscribe((data) => {
      this.onloadShow = true;
        setTimeout (() => {
          this.onloadShow = false;
          this._courseService.refreshAfterUpload(this.media.id);
        }, 1600);
    });
  }
}
