import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {Logger} from '@aws-amplify/core';
import {Notification} from '../../models';
import {APIService, ModelNotificationFilterInput, ModelSortDirection} from '../../app/API.service';
import {FuseProgressBarService} from '../components/progress-bar/progress-bar.service';
import {format} from 'date-fns';
import {AuthService} from '../../app/main/auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SyncMsgs {

  private logger = new Logger('MsgsList');
  private onNotifyChanged: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public onNotifyChanged$: Observable<any[]> = this.onNotifyChanged.asObservable();

  private onUserNotifyChanged: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public onUserNotifyChanged$: Observable<any[]> = this.onUserNotifyChanged.asObservable();
  notify: Notification[];
  userNotify: Notification[];
  /**
   * Constructor
   *
   * @param _auth
   * @param api
   * @param _fuseProgressBarService
   */
  constructor(
      private _auth: AuthService,
      private api: APIService,
      private _fuseProgressBarService: FuseProgressBarService
  )
  {
    // Set the defaults

    this.api.OnCreateNotificationListener.subscribe(msg => {
        this.refresh();
    });
    this.api.OnUpdateNotificationListener.subscribe(msg => {
        this.refresh();
    });

    this.api.OnDeleteNotificationListener.subscribe(msg => {
        this.refresh();
    });

    this._auth.isLogin$.subscribe( res => {
      if (res) {
        const userInfo = this._auth.getUserId();
        const userId = userInfo.getUsername();
        of(this.listMsgs(userId));
        of(this.listMsgsByUser(userId));
      }
    });

  }

  public refresh() {
    if (this._auth.loggedIn) {
      const userInfo = this._auth.getUserId();
      const userId = userInfo.getUsername();
      of(this.listMsgs(userId));
      of(this.listMsgsByUser(userId));
    }
  }

  listMsgs(userName: string): Promise<any[]> {
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      const dateFor = format(new Date(), 'yyyy-MM-dd');
      const filterIn: ModelNotificationFilterInput = {
          silent: { eq: false},
          renotify: {eq: true},
          and: [
            { dateFor: { eq: dateFor }},
            {userId: {size: {le: 2}}}
          ]
      };
      this.api.ListNotifsByStatus(
        'information',
        null,
                  ModelSortDirection.DESC ,
                  filterIn ,
            10 ,
        null)
        .then((result) => {
          this.notify = result.items.filter(item => item._deleted !== true);
          this.onNotifyChanged.next(this.notify);
          this._fuseProgressBarService.hide();
          resolve(this.notify);
        }).catch((err) => {
          this.catchError(err);
          reject(err);
        });
    });
  }

  listMsgsByUser(userName: string): Promise<any[]> {
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      const dateFor = format(new Date(), 'yyyy-MM-dd');
      const filterIn: ModelNotificationFilterInput = {
        silent: { eq: false},
        renotify: {eq: true},
        and: [
          { dateFor: { eq: dateFor }}
        ]
      };
      this.api.NotiByUserId(
        userName,
        ModelSortDirection.DESC,
        filterIn ,
        10 ,
        null)
        .then(result => {
          this.userNotify = result.items.filter(item => item._deleted !== true);
          this.onUserNotifyChanged.next(this.userNotify);
          this._fuseProgressBarService.hide();
          resolve(this.userNotify);
        }).catch((err) => {
          this.catchError(err);
          reject(err);
      });
    });
  }

  private catchError(error) {
      console.log(error);
      this.logger.debug('OOPS!', error);
      this._fuseProgressBarService.hide();
    }
}
