import {Inject, Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import {BehaviorSubject, Observable, of} from 'rxjs';
import Auth from '@aws-amplify/auth';
import {CognitoUser} from 'amazon-cognito-identity-js';
import {FuseProgressBarService} from '../../../@fuse/components/progress-bar/progress-bar.service';
import {getDeepFromObject} from '../auth/helpers';
import {PROFILE_MANAGER_OPTIONS} from './profile.options';
import * as S3 from 'aws-sdk/clients/s3';
import {HttpClient} from '@angular/common/http';
import {Logger, API} from 'aws-amplify';
import {
    APIService,
    elStatus,
    ModelCourseAssetsFilterInput,
    ModelCourseAssetSubsFilterInput,
    ModelCourseSubsFilterInput
} from "../../API.service";
import {APICustomService} from "../../API.customize.service";

@Injectable({
    providedIn: 'root'
})
export class ProfileService implements Resolve<any> {
    userPreferences: CognitoUser|any;
    private logger = new Logger('ProfileService');
    private preferencesOnChanged: BehaviorSubject<any> = new BehaviorSubject(null);
    public onPreferencesOnChanged$: Observable<any> = this.preferencesOnChanged.asObservable();

    private onAvatarChange: BehaviorSubject<any> = new BehaviorSubject(null);
    public onAvatarChange$: Observable<any> = this.onAvatarChange.asObservable();

    private errorsOnChanged: BehaviorSubject<any> = new BehaviorSubject(null);
    public onErrorsOnChanged$: Observable<any> = this.errorsOnChanged.asObservable();

    private onPercentageChange: BehaviorSubject<any> = new BehaviorSubject(null);
    public onPercentageChange$: Observable<any> = this.onPercentageChange.asObservable();

  // tslint:disable-next-line:max-line-length

    private readonly profileBase64Img = '/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAMDAwMDAwQEBAQFBQUFBQcHBgYHBwsICQgJCAsRCwwLCwwLEQ8SDw4PEg8bFRMTFRsfGhkaHyYiIiYwLTA+PlQBAwMDAwMDBAQEBAUFBQUFBwcGBgcHCwgJCAkICxELDAsLDAsRDxIPDg8SDxsVExMVGx8aGRofJiIiJjAtMD4+VP/CABEIAGAAYAMBIgACEQEDEQH/xAAbAAEBAQADAQEAAAAAAAAAAAAACAcEBQYJA//aAAgBAQAAAAD6QAADwOO8XevYAYbJIuDSARTloq7egSXhYp6igRLmI1a0gTDOopSlAfj87eveqv4BFOWtcswHV4jLIpzdO4eHwfE+GDlbRvkIgA//xAAXAQEBAQEAAAAAAAAAAAAAAAAAAwIB/9oACAECEAAAAKtMm6o5bqlh26HHboctoZ//xAAXAQADAQAAAAAAAAAAAAAAAAAAAQMC/9oACAEDEAAAAMCGGYl2ZiW0KDsxRLOWAN//xAA2EAABAwIDBQQIBgMAAAAAAAABAgMEBREGByEAEiAxURATQYEIFCIjMGFxkSQmMkBCgmJyof/aAAgBAQABPwD9hiTMvCWGVrakzO/kp0MaOnvFg9DyCdqj6QNQUtQptHYQnwVIWpwnyTu22gekBXEOj16lQnW/EMlbSvuor2wljihYyiKdgu7rqdHYztg4g/S+o+Y+BnJjuTh+KzR6c6pqXMbK3XUmym2b29noVEc9iSo3PPtBKSCDYjkRtlXiGRiPB8Z6SvvJEVa4zqzqVluxST87KF+POZxxeYFRC+TbUdKPp3aTw5AH8t1QdKhf7tp48+aYiPiWFOQpP4uFZQ8d5o2v9lcPo+SUKgVuNdO8l9le742UCL/8484XZa8f1Jt51SktJZSyDyQgtpVYeZ4cl5MhjHsNtsqCH2JCHR1SEFYv5p487MGVV+rorsGI4/HVEAlFA3y2Wr+0oD+NuAAqNhz2yYwJWabVnK1VIbkVAjFEdDuiypwi6t3mLAcciO1KjvMOp3m3UKQtPVKxY7VenuUmqToDmq4kl1lR6ltRTftwJDM/GNCZ3N4GoMqUn/FtQUr4Ob1Tw/UsVvKpbLiXmVFma5oEPONm28mx8ie3KLEeHMPVtZqTDpky1NMR5ACShgKNlFVyLX6jjqtbpFDj+s1KYxFa8FOrAv8AIDmT9NsV550hMCTGoLT7slbZS3JWju0IvpvAE3JHhe2xJUVEquTqSeDB+eFMap0SHXmpIfZbCDKQkOJWBoFKF7362vtR6/RsQR+/pk1iU347itR/snmnz7cQ5iYSwyVtzJ6VSE847PvXL9CBon+xG2JM9azO32aLGRBaOgeXZx4/Qck7T6jUKpIVJnSXZDyubjqys/c8cKfNp0hEmJJdjPJ/S40soUPMbYbzzr9O3GawwiosjTvRZt0Dy0VthzMbCWKChqJOS1IVyjP+7cv0F9Ff1J2JKjc6k8z8MEpNxoRyO3//xAAdEQEAAwACAwEAAAAAAAAAAAABAAIQICESMTJB/9oACAECAQE/AMK2fyNbB64UBdt1Z2n1t/rRTGKugsOiMRMKV4+BP//EABsRAQACAwEBAAAAAAAAAAAAAAEAEAISISAx/9oACAEDAQE/AK2IZD4zULx+Xn8vB5aDR1gBexHrDjDIa3fO7P/Z';
    private bucket: string;
    /**
     * Constructor
     *
     * @param options
     * @param _fuseProgressBarService
     * @param httpClient
     * @param api
     * @param customApi
     */
    constructor(
        @Inject(PROFILE_MANAGER_OPTIONS) protected options = {},
        private _fuseProgressBarService: FuseProgressBarService,
        private httpClient: HttpClient,
        private api: APIService,
        private customApi: APICustomService

    ) {
        // Set the defaults
        this.bucket = this.getConfigValue('bucketName');
    }

    getbucketTarget(): string {
        return this.bucket;
    }

    getCurrentUserNameOrId(): string {
        return this.userPreferences['username'] || this.userPreferences.attributes['sub'] ;
    }
    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getCurrentUser()
            ]).then(
                () => {
                  of(this.getS3AvatarFile());
                  resolve();
                },
                reject
            );
        });
    }

    /**
     * Get photos & videos
     */
    getCurrentUser(): Promise<CognitoUser|any> {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            Auth.currentAuthenticatedUser({
                bypassCache: true
            }).then((user: CognitoUser|any) => {
              const {attributes} = user;
              this.userPreferences = user;
              this.preferencesOnChanged.next(attributes);
              return this.getS3AvatarFile();
            }).then((newImg) => {
                const {attributes} = this.userPreferences;
                this._fuseProgressBarService.hide();
                resolve(attributes);
            }).catch((error: any) => {
                this.catchError(error);
                reject(error.message);
            });
        });
    }

    changePassword(oldPassword: string, newPassword: string ): Promise<any> {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            Auth.changePassword(this.userPreferences, oldPassword, newPassword)
                .then((results: any) => {
                    this._fuseProgressBarService.hide();
                    resolve(results);
                }).catch((error: any) => {
                  this.catchError(error);
                  reject(error.message);
            });
        });
    }


    updateUserAttributes(newAttributes: any ): Promise<any> {
        this._fuseProgressBarService.show();
        newAttributes['name'] = newAttributes['given_name'] + ' ' +  newAttributes['family_name'];
        return new Promise((resolve, reject) => {
            Auth.updateUserAttributes(this.userPreferences, newAttributes )
                .then((results: any) => {
                    return Auth.currentAuthenticatedUser({
                        bypassCache: true
                    });
                }).then((user: CognitoUser|any) => {
                    const { attributes } = user;
                    this.userPreferences = user;
                    this.preferencesOnChanged.next( attributes  );
                    this._fuseProgressBarService.hide();
                    resolve('SUCCESS');
                }).catch((error: any) => {
                    this.catchError(error);
                    reject(error.message);
            });
        });
    }

    updateUserAvatar(newAttributes: any ): Promise<any> {
      this._fuseProgressBarService.show();
      return new Promise((resolve, reject) => {
        Auth.updateUserAttributes(this.userPreferences, newAttributes )
          .then((results: any) => {
            return Auth.currentAuthenticatedUser({
              bypassCache: true
            });
        }).then((user: CognitoUser|any) => {
          const {attributes} = user;
          this.userPreferences = user;
          this.preferencesOnChanged.next(attributes);
          return this.getS3AvatarFile();
        }).then((newImg) => {
          this._fuseProgressBarService.hide();
          resolve('SUCCESS');
        }).catch((error: any) => {
          this.catchError(error);
          reject(error.message);
        });
      });
    }


   sendCodeForVerification(phoneNumber: any): any {
      return new Promise((resolve, reject) => {
          const userPoolId = this.getConfigValue('UserPoolId');
          const body = {
              body: {
                  toPhone: phoneNumber.phone,
                  message: 'Your Phone Verication Code ',
                  username: this.userPreferences.username,
                  userPoolId: userPoolId
              }
          };
          API.post('coreApiKTC', '/notify', body)
              .then(result => {
                  of(this.getCurrentUser());
                  resolve(result);
              }).catch((error: any) => {
                  this.catchError(error);
                  reject(error.message);
          });
      });
    }

    codeForVerification(data: any): any {
      this._fuseProgressBarService.show();
      return new Promise((resolve, reject) => {
        Auth.updateUserAttributes(this.userPreferences,
          {
            'custom:phone' : data.phone,
            'phone_number': data.phone,
            'phone_number_verified': true,
            'custom:verifycode': ''
          } )
          .then((results: any) => {
            return Auth.currentAuthenticatedUser({
              bypassCache: true
            });
          }).then((user: CognitoUser|any) => {
          const {attributes} = user;
          this.userPreferences = user;
          this.preferencesOnChanged.next(attributes);
          return this.getS3AvatarFile();
        }).then((newImg) => {
          this._fuseProgressBarService.hide();
          resolve('SUCCESS');
        }).catch((error: any) => {
          this.catchError(error);
          reject(error.message);
        });
      });
    }

    syncS3(): any {
      return new S3(
        {
          accessKeyId: this.getConfigValue('accessKeyId'),
          secretAccessKey: this.getConfigValue('secretAccessKey'),
          region: this.getConfigValue('region')
        });
    }

    getCourses(userId: string) {
        let filter: ModelCourseSubsFilterInput =  {
            userId: { eq: userId}
        };
        let pageSize = 999;
        let nextToken = null;

        return new Promise((resolve, reject) => {
            this.api.ListCourseSubss(filter, pageSize, nextToken)
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    this.catchError(err);
                    reject(err);
                });
        });
    }

    CalculateProgress(courseId: string, userId: string): Promise<any> {
        let totalCourseAssets;
        return new Promise((resolve, reject) => {
            const filter : ModelCourseAssetsFilterInput = {
                courseId: {eq: courseId},
                or: [
                    { status: {eq: elStatus.active}},
                    { status: {eq: elStatus.new}}]
            }
            this.customApi.ListCourseAssetssToCount(filter,999)
                .then((resultAssets) => {
                    totalCourseAssets = resultAssets.items.length;
                    const filterSubs: ModelCourseAssetSubsFilterInput = {
                        courseSubsId: {eq: courseId},
                        userId: {eq: userId}
                    };
                    return this.customApi.ListCourseAssetSubssToCount(filterSubs, 999);
                })
                .then(resultAssetsSubs => {
                    let countAssetSubsCompleted = 0;
                    resultAssetsSubs.items.forEach(element => {
                        if(element.status === "Completed"){
                            countAssetSubsCompleted++;
                        }
                    });
                    let perProgress;
                    if(totalCourseAssets > 0){
                        perProgress = ((countAssetSubsCompleted/totalCourseAssets) * 100).toFixed(0);
                    }else{
                        perProgress = 0;
                    }
                    let percentageChange = {
                        perProgress: perProgress,
                        courseId: courseId
                    };
                    this.onPercentageChange.next(percentageChange);
                    resolve(perProgress);
                })
                .catch((err) => reject(err));
        })
    }

    getS3AvatarFile(): Promise<any> {
      return new Promise((resolve, reject) => {
        const { attributes } = this.userPreferences;
        const indentities = (attributes && attributes['identities']);
        const imgFileAvail =  (attributes && attributes['picture']);
        if (!indentities && imgFileAvail) {
          const imgFile = attributes['picture'];
          const keyArray = imgFile.split('/');
          const key = `userpics/${keyArray[keyArray.length - 2] }/${keyArray[keyArray.length - 1]}`;
          const newConn = this.syncS3();
          const params = {
            Bucket: this.bucket,
            Key: key
          };
          newConn.getObject(params, (err, urlLink) => {
            if (err) {
              this.catchError(err);
              resolve(`data:image/jpeg;base64,${this.profileBase64Img}`);
            } else {
              const base64Img = 'data:image/jpeg;base64,' + urlLink.Body.toString('base64');
              this.onAvatarChange.next(base64Img);
              resolve(base64Img);
            }
          });
        } else if (indentities && imgFileAvail) {
          const imgUrlFile = attributes['picture'];
          this.getBase64File(imgUrlFile)
            .then((base64Img) => {
              this.onAvatarChange.next(base64Img);
              resolve(base64Img);
          });
        } else {
          resolve(`data:image/jpeg;base64,${this.profileBase64Img}`);
        }

      });
    }

    getBase64File(imageUrl: string): Promise<any> {
      return new Promise((resolve, reject) => {
        try {
          this.httpClient
            .get(imageUrl, {responseType: 'blob'})
            .subscribe((fileBlob) => {
              const reader = new FileReader();
              reader.readAsDataURL(fileBlob);
              reader.onloadend = () => {
                resolve(reader.result);
              };
            });
        } catch (error) {
            this.catchError(error);
            resolve(`data:image/jpeg;base64,${this.profileBase64Img}`);
        }
      });
    }

    getS3AvatarFileByName(imgFile: string): Promise<any> {
      return new Promise((resolve, reject) => {
      if (imgFile) {
          const keyArray = imgFile.split('/');
          const key = `userpics/${keyArray[keyArray.length - 2] }/${keyArray[keyArray.length - 1]}`;
          const newConn = this.syncS3();
          const params = {
            Bucket: this.bucket,
            Key: key
          };
          newConn.getObject(params, (err, urlLink) => {
            if (err) {
              this.catchError(err);
              resolve(`data:image/jpeg;base64,${this.profileBase64Img}`);
            } else {
              const base64Img = 'data:image/jpeg;base64,' + urlLink.Body.toString('base64');
              this.onAvatarChange.next(base64Img);
              resolve(base64Img);
            }
          });
        } else {
          resolve(`data:image/jpeg;base64,${this.profileBase64Img}`);
        }
      });
    }

    private catchError(error) {
      console.log(error);
      this.logger.debug('OOPS!', error);
      this._fuseProgressBarService.hide();
    }

    getConfigValue(key: string): any {
      return getDeepFromObject(this.options, key, null);
    }
}
