import {elStatus} from '../../../API.service';

export class CResource
{
  id: string;
  courseId: string;
  description?: string;
  contentType?: string;
  sourceCode?: string;
  fileName?: string;
  fileType?: string;
  fileSize?: string;
  status?: string;
  createdAt?: string;
  updatedAt?: string;
  _version?: number;

  /**
   * Constructor
   *
   * @param resource
   */
  constructor(resource?) {
    resource = resource ? resource : {};
    this.id = resource.id || '';
    this.courseId = resource.courseId || null;
    this.description = resource.description || null;
    this.contentType = resource.contentType || null;
    this.sourceCode = resource.sourceCode || null;
    this.status = resource.status || elStatus.new;

    this.fileName = resource.fileName || ' ';
    this.fileType = resource.fileType || ' ';
    this.fileSize = resource.fileSize || '0';

    this.createdAt = resource.createdAt || new Date().toUTCString();
    this.updatedAt = resource.updatedAt || new Date().toUTCString();
    this._version = resource._version || null;
  }
}
