import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';

import {ImgCropComponent} from './component/img-crop.component';
import {ImgCropService} from './service/img-crop.service';
import {ImgCropEditorComponent} from './component/img-crop-editor.component';

// Export module's public API


@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    exports: [ImgCropComponent, ImgCropEditorComponent],
    declarations: [ImgCropComponent, ImgCropEditorComponent],
    providers: [ImgCropService]
})
export class ImgCropModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: ImgCropModule,
            providers: [ImgCropService]
        };
    }
}
