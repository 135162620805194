import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {MatCardModule, MatIconModule, MatProgressBarModule, MatRippleModule} from '@angular/material';

import {PasswordStrengthComponent} from './component/password-strength/password-strength.component';
import {PasswordStrengthInfoComponent} from './component/password-strength-info/password-strength-info.component';
import {PasswordToggleVisibilityComponent} from './component/password-toggle-visibility/password-toggle-visibility.component';

// Export module's public API
export {PasswordStrengthComponent} from './component/password-strength/password-strength.component';
export {
    PasswordStrengthInfoComponent
} from './component/password-strength-info/password-strength-info.component';
export {PasswordToggleVisibilityComponent} from './component/password-toggle-visibility/password-toggle-visibility.component';
export {PasswordStrengthValidator} from './validator/password-strength-validator';
// validator
export {RegExpValidator} from './validator/regexp.class';

@NgModule({
    imports: [
        CommonModule,
        MatProgressBarModule,
        MatCardModule,
        MatIconModule,
        MatRippleModule
    ],
    exports: [
        PasswordStrengthComponent,
        PasswordStrengthInfoComponent,
        PasswordToggleVisibilityComponent
    ],
    declarations: [
        PasswordStrengthComponent,
        PasswordStrengthInfoComponent,
        PasswordToggleVisibilityComponent
    ],
    entryComponents: [PasswordToggleVisibilityComponent]
})
export class PasswordStrengthModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: PasswordStrengthModule,
            providers: []
        };
    }
}
