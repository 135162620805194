import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FuseConfirmDialogComponent} from "../../../../../../@fuse/components/confirm-dialog/confirm-dialog.component";
import {fromEvent, Observable, ReplaySubject, Subject} from "rxjs";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Location} from "@angular/common";
import {debounceTime, distinctUntilChanged, takeUntil} from "rxjs/operators";
import {DataSource} from "@angular/cdk/collections";
import {ConnectsService} from "../../connects.service";
import {fuseAnimations} from "../../../../../../@fuse/animations";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Contact} from "../../contact.model";
import {ConnectPopupComponent} from "../connect-popup/connect-popup.component";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {MatSort} from "@angular/material/sort";
import {AddContactAsUserPopupComponent} from "../add-contact-as-user-popup/add-contact-as-user-popup.component";
import {ContactService} from "../../contact.service";

@Component({
  selector: 'connect-list',
  templateUrl: './connect-list.component.html',
  styleUrls: ['./connect-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class ConnectListComponent implements OnInit, OnDestroy
{

  @Input()
  contactId: string;

  @Input()
  organization: any

  @ViewChild('filter', {static: true})
  filter: ElementRef;

  @ViewChild(MatSort, {static: true})
  sort: MatSort;


  contactForm: FormGroup;
  connect: Contact;
  contacts: any[];
  nextPage: any;
  dataSource: ConnectsDataSource | null;
  displayedColumns = ['avatar', 'name',  'email', 'phone', 'isUser', 'buttons'];
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

  public contactFilterCtrl: FormControl = new FormControl();
  public filteredServerSideContacts: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  customAvatarStyle = {
    backgroundColor: '#ff8420',
    border: '1px solid #7e7e7e',
    borderRadius: '50%',
    color: '#155a7e'
  };
  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param _connectsService
   * @param _contactService
   * @param {MatDialog} _matDialog
   * @param _matSnackBar
   * @param _location
   * @param _formBuilder
   * @param _bottomSheet
   */
  constructor(
      private _connectsService: ConnectsService,
      private _contactService: ContactService,
      public _matDialog: MatDialog,
      private _matSnackBar: MatSnackBar,
      private _location: Location,
      private _formBuilder: FormBuilder,
      private _bottomSheet: MatBottomSheet,

  ) {

    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this.contacts = [];
    this.connect = new Contact();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void
  {
    this._connectsService.refreshConnects(this.contactId);

    this.contactForm = this.createConnectsForm();
    this._connectsService.getContacts(null)
        .then((res) => {
          this.filteredServerSideContacts.next(res);
        });

    this.dataSource = new ConnectsDataSource(this._connectsService,  this.sort);

    this._connectsService.onConnectsChanged$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(contacts => {
          this.contacts = contacts;
        });

    this._connectsService.nextPageToken$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(nextToken => {
          this.nextPage = nextToken;
        });

    this.contactFilterCtrl.valueChanges
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(() => {
          this._filterContacts();
        });

    fromEvent(this.filter.nativeElement, 'keyup')
        .pipe(
            takeUntil(this._unsubscribeAll),
            debounceTime(150),
            distinctUntilChanged()
        )
        .subscribe(() => {
          if ( !this.dataSource )
          {
            return;
          }
          this._connectsService.onSearchTextChanged.next(this.filter.nativeElement.value);
          this._connectsService.refreshConnects(this.contactId);
        });

  }

  /**
   * On destroy
   */
  ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  gotoNextPage(nextPage): void {
    this._connectsService.goNextPage(this.contactId, nextPage);
  }


  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  createConnectsForm(): FormGroup
  {
    return this._formBuilder.group({
      connectId            : [this.connect.id]
    });
  }

  onSelectContact($event) {
    if ($event.value === undefined) {
      this._connectsService.refreshContacts();
    } else {
      this._connectsService.getContact($event.value)
          .then(selectedContact => {
            const dataToSave = this._bottomSheet.open(ConnectPopupComponent, {
              disableClose: true,
              data: selectedContact
            });
            dataToSave.afterDismissed().subscribe((data) => {
              if (data) {
                this._connectsService.addConnect(this.contactId, selectedContact)
                    .then( newConnect => {
                      this._connectsService.refreshConnects(this.contactId);
                    });
              }
            });
          })
    }
  }

  onCreateNewUser($event) {
      console.log($event);
    if ($event === undefined) {
      this._connectsService.refreshContacts();
    } else {
      let user = null;
      this._connectsService.getContact($event.connsContactId)
          .then(selectedContact => {
              user = selectedContact;
            return this._contactService.userExistsByEmail(selectedContact.email)
          }).then(userExists =>{
              user['pbProfileOptions'] = userExists;
              user['connect'] = $event;
              user['orgId'] = $event.contactId;
              user['orgMeta'] = this.organization;
              const dataToSave = this._bottomSheet.open(AddContactAsUserPopupComponent, {
                disableClose: true,
                data: user
              });
              dataToSave.afterDismissed().subscribe((data) => {
                if (data) {
                    this._connectsService.refreshConnects(this.contactId);
                }
              });
          })
    }
  }
  /**
   * Delete Contact
   */
  deleteContact(contact: any): void
  {
    this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });

    this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if ( result ) {
        this._connectsService.deleteConnect(contact.id, contact._version)
            .then((res) => {
              // Show the success message
              this._matSnackBar.open('Contact Sucessfully Inactive', 'OK', {
                verticalPosition: 'top',
                duration        : 2000
              });
              this._connectsService.refreshConnects(this.contactId);
            }).catch(err => {
              this._matSnackBar.open('OOPS! Something is wrong .. Please try Again.', 'OK', {
                verticalPosition: 'top',
                duration        : 2000
              });
              console.log(err.errors[0].message);
            });
      }
      this.confirmDialogRef = null;
    });

  }

  private _filterContacts() {
    const search = this.contactFilterCtrl.value;
    if (!search) {
      return;
    }
    this._connectsService.getContacts(search)
        .then((res) => {
          this.filteredServerSideContacts.next(res);
        });
  }

}

export class ConnectsDataSource extends DataSource<any> {
  /**
   * Constructor
   *
   * @param {ContactsService} _connectsService
   * @param _formBuilder
   */
  constructor(
      private _connectsService: ConnectsService,
      private _matSort: MatSort
  )
  {
    super();
  }

  /**
   * Connect function called by the table to retrieve one stream containing the data to render.
   * @returns {Observable<any[]>}
   */
  connect(): Observable<any[]>
  {
    return this._connectsService.onConnectsChanged$;
  }

  /**
   * Disconnect
   */
  disconnect(): void
  {
  }
}
