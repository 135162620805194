import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ProfileService } from '../../profile.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'status-cell',
  templateUrl: './status-cell.component.html',
  styleUrls: ['./status-cell.component.scss']
})
export class StatusCellComponent implements OnInit, OnDestroy {

  @Input('courseId') courseId: string;

  private _onDestroy = new Subject<void>();
  status = "-----";
  
  constructor(
    private _profileService: ProfileService
  ) { }

  ngOnInit() {
    this._profileService.onPercentageChange$
      .pipe(takeUntil(this._onDestroy))
      .subscribe((result: any) => {
        if(result && this.courseId === result.courseId){
          const progressValue = parseInt(result.perProgress);          
          if(progressValue === 0){
            this.status = "Enrolled"
          }else if(progressValue > 0 && progressValue < 100){
            this.status = "In Progress"
          }else if(progressValue === 100){
            this.status = "Completed"
          }
        }
      })
  }

  ngOnDestroy(){
    this._onDestroy.next();
    this._onDestroy.complete();
  }

}
