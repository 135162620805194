import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import * as _ from "lodash";
import {
  APIService,
  elStatus,
  ModelCourseAssetsFilterInput,
  ModelCourseAssetSubsFilterInput
} from "../../../../API.service";
import {APICustomService} from "../../../../API.customize.service";

@Component({
  selector: 'academy-course-tile',
  templateUrl: './a-course-tile.component.html',
  styleUrls: ['./a-course-tile.component.scss']
})
export class ACourseTileComponent implements OnInit, AfterViewInit {

  @Input() courseSub;
  @Input() userId;

  calculating: boolean;
  perProgress: any;

  constructor(
      private router: Router,
      private api: APIService,
      private customApi: APICustomService
  ) {}

  ngOnInit() {
    this.calculating = true;
  }

  ngAfterViewInit() {
    this.CountTopics(this.courseSub.courseId)
        .then(progress => {
          this.perProgress = progress;
          this.calculating = false;
        })
  }

  restartResume($event) {
    this.router.navigate(['my/courses/' + $event.id]);
  }

  CountTopics(courseId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const filter : ModelCourseAssetsFilterInput = {
        courseId: {eq: courseId},
        or: [
          { status: {eq: elStatus.active}},
          { status: {eq: elStatus.new}}]
      }
      this.customApi.ListCourseAssetssToCount(
          filter,
          99
      ).then((resultAssets) => {
        this.courseSub.course.assets = resultAssets;
        const filterSubs: ModelCourseAssetSubsFilterInput = {
          courseSubsId: {eq: courseId},
          userId: {eq: this.userId}
        };
        return this.customApi.ListCourseAssetSubssToCount(filterSubs, 99);
      }).then((resultAssetsSubs) => {
        this.courseSub.assetsSubs = resultAssetsSubs;
        let topicProgressArray = [];
        let completedAssets = [];
        this.courseSub.course.assets.items.forEach(topic => {
          const found = _.find(this.courseSub.assetsSubs.items , {assetId: topic.id});
          topic['subsStatus'] = null;
          if (found) {
            topic['subsStatus'] = found.status;
          }
          if (topic['subsStatus'] ==='Completed') {
              completedAssets.push(topic)
          }
          topicProgressArray.push(topic);
        })
        const progress = ((completedAssets.length/this.courseSub.course.assets.items.length) * 100).toFixed(1);
        resolve(progress)
      }).catch((err) => reject(err));
    });
  }


}
