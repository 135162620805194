import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '../../../../../../@fuse/animations';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ReplaySubject, Subject} from 'rxjs';
import {CourseAsset} from '../../media.model';
import {takeUntil} from 'rxjs/operators';
import {ContentItemService} from '../../content-item.service';
interface ContentStatus {
  value: string;
  name: string;
}

interface ContentType {
  value: string;
  name: string;
}
@Component({
  selector: 'content-details',
  templateUrl: './content-details.component.html',
  styleUrls: ['./content-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class ContentDetailsComponent implements OnInit, OnDestroy {

  @Input()
  media: CourseAsset;

  @Input()
  pageType: string;

  @Output() formChanges: EventEmitter<any> = new EventEmitter<any>();

  mediaForm: FormGroup;

  statusOptions: ContentStatus[] = [
    { value: 'active', name: 'Active'},
    { value: 'inactive', name: 'Inactive'},
    { value: 'new', name: 'New'}
  ];

  public courseFilterCtrl: FormControl = new FormControl();
  public filteredServerSideCourses: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  //
  public filteredStatus: ReplaySubject<ContentStatus[]> = new ReplaySubject<ContentStatus[]>(1);
  public statusFilterCtrl: FormControl = new FormControl();

  private _unsubscribeAll: Subject<any>;

  constructor(
    private _formBuilder: FormBuilder,
    private _contentItemService: ContentItemService,
  ) {
    // Set the default
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.filteredStatus.next(this.statusOptions.slice());

    this.statusFilterCtrl.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this._filterStatus();
      });

    this.courseFilterCtrl.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this._filterCourses();
      });

    this.mediaForm = this.createContentForm();
    this._contentItemService.getCourses(this.media.course.id, 'id')
        .then((res) => {
            this.mediaForm.controls['courseId'].setValue(this.media.course.id);
            this.filteredServerSideCourses.next(res);
        });
    if (this.pageType === 'new') {
        this.mediaForm.controls['status'].setValue( this.statusOptions[2].value);
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  saveData() {
    const attr = this.mediaForm.getRawValue();
    attr.pageType = this.pageType;
    this.formChanges.emit(attr);
  }
  /**
   * Create product form
   *
   * @returns {FormGroup}
   */
  createContentForm(): FormGroup
  {
    return this._formBuilder.group({
      id                  : [this.media.id],
      title               : [this.media.title],
      timeAprox           : [this.media.timeAprox],
      courseId            : [this.media.courseId],
      notes               : [this.media.description],
      order               : [this.media.order],
      status              : [this.media.status],
      _version            : [this.media._version]
    });
  }

  private _filterCourses() {
    const search = this.courseFilterCtrl.value;
    if (!search) {
      return;
    }
    this._contentItemService.getCourses(search, 'title')
      .then((res) => {
        this.filteredServerSideCourses.next(res);
      });
  }

  private _filterStatus() {
    if (!this.statusOptions) {
      return;
    }
    // get the search keyword
    let search = this.statusFilterCtrl.value;
    if (!search) {
      this.filteredStatus.next(this.statusOptions.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredStatus.next(
      this.statusOptions.filter(lang => lang.name.toLowerCase().indexOf(search) > -1)
    );
  }


}
