import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, of, Subject} from 'rxjs';
import {getDeepFromObject} from '../../auth/helpers';
import {AuthService, NewUser} from '../../auth/services/auth.service';
import * as _ from 'lodash';
import {
    APIService,
    CreateMembershipInput,
    ModelMembershipFilterInput,
    UpdateMembershipInput,
} from "../../../API.service";
import {SyncSettings} from "../../../../@fuse/services/sync-settings.service";
import {CONTACT_MANAGER_OPTIONS} from "./contact.options";
import * as AWS from "aws-sdk";
import {CognitoUser} from "amazon-cognito-identity-js";
import Auth from "@aws-amplify/auth";

@Injectable()
export class LicenseService {

    licenses: any[];
    nextToken: string = null;

    private onLicenseChanged = new BehaviorSubject<any>(null);
    onLicenseChanged$ = this.onLicenseChanged.asObservable();

    private onImportsChanged = new BehaviorSubject<any[]>([]);
    onImportsChanged$ = this.onImportsChanged.asObservable();

    private onOpenSideBar = new BehaviorSubject<any>(null);
    onOpenSideBar$ = this.onOpenSideBar.asObservable();


    private nextPageToken = new BehaviorSubject<any>(this.nextToken);
    nextPageToken$ = this.nextPageToken.asObservable();

    onLoading: Subject<any>;
    onSearchTextChanged: Subject<any>;
    onFilterChanged: Subject<any>;
    pageSize: number;


    searchText: string;
    filterBy: string;

    bucket: any;

    /**
     * Constructor
     *
     * @param options
     * @param api
     * @param _authService
     * @param syncSettings
     */
    constructor(
        @Inject(CONTACT_MANAGER_OPTIONS) protected options = {},
        private api: APIService,
        private _authService: AuthService,
        private syncSettings: SyncSettings,
    )
    {
        // Set the defaults
        this.onLoading = new Subject();
        this.onSearchTextChanged = new Subject();
        this.onFilterChanged = new Subject();
        this.onLoading.next(false);
        this.pageSize = this.getConfigValue('pageSize');
        this.syncSettings.onSettingsChanged$.subscribe((res) => {
            this.pageSize = res.pageSize;
        });
        this.onSearchTextChanged.subscribe(searchText => {
            this.searchText = searchText;
            this.nextToken = null;
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // ----------------------------------------------------------------------------------------------------

    openSideBar(license: any) {
        this.onOpenSideBar.next(license);
    }

    addNewImportContact(contact: any[]) {
        this.onImportsChanged.next(contact);
    }

    refreshAll(contactId: string) {
        of(this.getLicenses(contactId))
    }


    public goNextPage(contactId: string, nextPageToken: string) {
        of(this.getLicenses(contactId, this.pageSize,  nextPageToken));
    }


    getLicenses(contactId?: string, pageSize?: number, nextToken?: string): Promise<any[]> {
        this.onLoading.next(true);
        this.pageSize  = pageSize > 0 ? pageSize : this.pageSize;
        this.nextToken = nextToken ? nextToken : null;
        return new Promise((resolve, reject) => {
            let filter: ModelMembershipFilterInput;
            filter = {
                contactId: {eq: contactId},
                status: {eq: 'system'}
            };
            if (this.searchText) {
                filter['prefix'] = {contains: this.searchText};
            }
            this.api.ListMemberships(filter, this.pageSize, this.nextToken)
                .then((connects) => {
                    this.nextToken = !_.isEmpty(connects['nextToken']) ? connects['nextToken'] : null;
                    this.nextPageToken.next(this.nextToken );
                    this.licenses = connects.items;
                    this.onLicenseChanged.next(this.licenses);
                    this.onLoading.next(false);
                    resolve(this.licenses);
                })
                .catch((err) => reject(err));
        });
    }

    addLicense(contactId: string, contactName: any, license: any): Promise<any> {
        return new Promise((resolve, reject) => {
            let filter: ModelMembershipFilterInput;
            filter = {
                contactId: {eq: contactId},
                prefix: {eq: license.prefix}
            };

            this.api.ListMemberships(filter, 1)
                .then(foundList =>{
                    if (foundList.items.length >0) {
                        reject('NO COURSE OF ACTION');
                    } else {
                        const groupName = contactName.split(' ').join('_') + "_" +license.prefix;
                        return this.CreateGroup( groupName);
                    }
                }).then(newGroup => {
                    const groupJson = JSON.stringify(newGroup);
                    const payload: CreateMembershipInput = {
                        contactId: contactId,
                        membershipOrgId: contactId,
                        noLicenses: license.noLicenses,
                        noClaimed: 0,
                        groupId: newGroup.Group.GroupName,
                        group: groupJson,
                        prefix: license.prefix,
                        createdAt: new Date().toISOString(),
                        updatedAt: new Date().toISOString(),
                        status: 'system'
                    };
                    return this.api.CreateMembership(payload);
                }).then((newLicence) => {
                    resolve(newLicence);
                }).catch((err) => {
                    console.log(err);
                    reject(err);
                });

        });
    }

    register(user: NewUser, groupName: string): Promise<CognitoUser|any> {
        return new Promise((resolve, reject) => {
            const newUser = {
                username: user.email,
                password : user.password,
                attributes: {
                    name : `${user.givenName} ${user.familyName}`,
                    email: user.email,
                    given_name: user.givenName,
                    family_name: user.familyName,
                    phone_number: ''
                }};
            Auth.signUp(newUser)
                .then((userData: CognitoUser|any) => {
                    return this.addUserToGroup(groupName, userData.username);
                }).then(groupData => {
                    resolve(groupData);
                }).catch((error: any) => {
                    reject(error);
                });
        });
    }


    syncCognito(): any {
        return new AWS.CognitoIdentityServiceProvider(
            {
                apiVersion: '2016-04-18',
                region: this.getConfigValue('region'),
                accessKeyId: this.getConfigValue('accessKeyId'),
                secretAccessKey: this.getConfigValue('secretAccessKey')
            }
        );
    }

    CreateGroup( groupName: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const conn = this.syncCognito();
            const params = {
                UserPoolId: this.getConfigValue('UserPoolId'),
                GroupName: groupName
            };
            conn.createGroup(params, (err, data) => {
                if (err) {
                    console.log(err);
                    reject(err);
                }
                resolve(data);
            });
        });
    }

    addUserToGroup( groupName: string, userName?: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const conn = this.syncCognito();
            const params = {
                UserPoolId: this.getConfigValue('UserPoolId'),
                GroupName: groupName,
                Username: userName
            };
            conn.adminAddUserToGroup(params, (err, data) => {
                if (err) {
                    console.log(err);
                    reject(err);
                }
                resolve(data);
            });
        });
    }

    suspendLicence(licId: string, _version: any ): Promise<any> {
        return new Promise((resolve, reject) => {
            const payload: UpdateMembershipInput = {
                id: licId,
                noLicenses: -1,
                updatedAt: new Date().toISOString(),
                _version: _version
            };
            this.api.UpdateMembership(payload)
                .then(connect => {
                    resolve(connect);
                }).catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    updateLicence(data: any, _version: any ): Promise<any> {
        return new Promise((resolve, reject) => {
            const payload: UpdateMembershipInput = {
                id: data.id,
                noLicenses: data.noLicenses,
                updatedAt: new Date().toISOString(),
                _version: _version
            };
            this.api.UpdateConnects(payload)
                .then(connect => {
                    resolve(connect);
                }).catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    getConfigValue(key: string): any {
        return getDeepFromObject(this.options, key, null);
    }

}
