import {ModuleWithProviders, NgModule} from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseConfirmDialogModule, FuseSidebarModule } from '@fuse/components';

import {UsersService} from './users.service';
import {USER_MANAGER_OPTIONS, UserOptions} from './users.options';
import {UserListComponent} from './user-list/user-list.component';
import {UsersSelectedBarComponent} from './selected-bar/selected-bar.component';
import {UsersMainSidebarComponent} from './sidebars/main/user-main.component';
import {UsersComponent} from './users.component';
import {AvatarModule} from 'ngx-avatar';
import {UserDetailsSidebarComponent} from './sidebars/details/user-details.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatListModule} from '@angular/material/list';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatCardModule} from '@angular/material/card';
import {AuthGuard} from '../../auth/auth.guard';
import {UserItemComponent} from './sidebars/details/user-item/user-item.component';
import {ContentLoaderModule} from '../../../core/loaders/content-loader.module';
import { UserItemAttrComponent } from './sidebars/details/user-item-attr/user-item-attr.component';
import {MomentUserAgoPipe} from './moment-user-ago.pipe';
import {MatDialogModule, MatSelectModule, MatSlideToggleModule} from '@angular/material';
import {NotifyDialogComponent} from './notify-dialog/notify-dialog.component';
import {MatSelectSearchModule} from "../../../core/mat-select-search/mat-select-search.module";

const routes: Routes = [
    {
        path     : 'users',
        component: UsersComponent,
        resolve : {
            users: UsersService
        },
        canActivate: [AuthGuard]
    }
];

@NgModule({
    declarations: [
        UsersComponent,
        UserListComponent,
        UsersSelectedBarComponent,
        UsersMainSidebarComponent,
        UserDetailsSidebarComponent,
        UserItemComponent,
        MomentUserAgoPipe,
        UserItemAttrComponent,
        NotifyDialogComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatTableModule,
        MatToolbarModule,
        MatTabsModule,
        FuseSharedModule,
        FuseConfirmDialogModule,
        FuseSidebarModule,
        AvatarModule,
        MatListModule,
        MatBottomSheetModule,
        MatCardModule,
        ContentLoaderModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatDialogModule,
        MatSelectSearchModule
    ],
    entryComponents: [NotifyDialogComponent]
})
export class UsersModule
{
    static forRoot(fmOptions?: UserOptions): ModuleWithProviders {
        return {
            ngModule: UsersModule,
            providers: [
                { provide: USER_MANAGER_OPTIONS, useValue:  fmOptions},
                UsersService
            ],
        } as ModuleWithProviders;
    }
}

