import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import { QuizQuestionService } from '../../quiz-question.service';

interface iselect {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'question-detail',
  templateUrl: './question-detail.component.html',
  styleUrls: ['./question-detail.component.scss'],
  providers: [
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always'}}
  ]
})
export class QuestionDetailComponent implements OnInit {
  
  @Input()
  questionDetails: any;

  @Input()
  state: string;

  @Output()
  saveQuestion: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  upload: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  changeType: EventEmitter<any> = new EventEmitter<any>();

  MULTIPLE = 'multiple';
  quiz: any;
  courseAsset: any;
  questionSelected: any;
  question = "";
  correctMessage: string;
  incorrectMessage: string;
  qType = 'TrueFalse';
  imageURL = 'assets/images/courses/course-image-placeholder.png';
  questionFile: File;
  originalQtype: string;
  originalAnswers: any;
  isNewTypeFromEdit = false;
  alertMessage = "";
  showAlert = false;

  questionType: iselect[] = [
    {value: 'TrueFalse', viewValue: 'True/False'},
    {value: 'Multiple', viewValue: 'Multiple Choice'},
    {value: 'Optional', viewValue: 'Optional'}
  ];

  constructor(
    private _questionService: QuizQuestionService,
  ) { }

  ngOnInit() {
    this.courseAsset = this.questionDetails.asset;
    if(this.state === "edit"){
      this.question = this.questionDetails.title;
      this.correctMessage = this.questionDetails.labelCorrectMessage;
      this.incorrectMessage = this.questionDetails.labelIncorrectMessage;
      this.qType = this.questionDetails.qType;
      this.originalQtype = this.qType;

      // image
      if(this.questionDetails.sourceMedia){
        const fileName = this.questionDetails.sourceMedia.split("/").pop();
        this._questionService.getPresignedFile(fileName, this.questionDetails.id, this.courseAsset.id)
          .then( res => {
            this.imageURL = res;
          });
      }
    }
  }

  saveData(){
    if(this.question.length === 0){
      this.alertMessage = "Question is required";
      this.showAlert = true;
    }else{
      this.alertMessage = "";
      this.showAlert = false;
      const data = {
        quizContentId: this.courseAsset.id,
        question: this.question,
        correctMessage: this.correctMessage,
        incorrectMessage: this.incorrectMessage,
        qType: this.qType,
        quizQuestionQuizId: this.courseAsset.quiz.id
      };
      if(this.originalQtype && this.originalQtype !== this.qType){
        console.log(this.originalQtype, " !== ",this.qType);
        this.updateAnswerStatus();
      }
      this.saveQuestion.emit(data);
    }
  }

  onSelectedType($event) {
    // console.log($event);
    this.changeType.emit($event.value);
  }

  // Image Preview
  showPreview(event) {
    console.log(this.questionFile);
    this.questionFile = (event.target as HTMLInputElement).files[0];
    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.imageURL = reader.result as string;
    }
    reader.readAsDataURL(this.questionFile);
  }

  saveFile(){
    // console.log("Upload File!");
    this.upload.emit(this.questionFile);
  }

  updateAnswerStatus(){
    let answers = this.questionDetails.questionAnswers.items;
    answers.forEach(item => {
      if(item.status === "active"){
        item.status = "inactive";
        this._questionService.updateQuestionAnswer(item)
          .then(res => {
            // console.log("status --> ", res);
          })
      }
    });
  }

}
