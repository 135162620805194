import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {CourseService} from '../../../course.service';
import {fuseAnimations} from '../../../../../../../@fuse/animations';
import {of, Subject} from 'rxjs';
import {Course} from '../../course.model';
import {takeUntil} from "rxjs/operators";
import * as _ from 'lodash';
@Component({
  selector: 'course-classes-list',
  templateUrl: './course-classes.component.html',
  styleUrls: ['./course-classes.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class CourseClassesComponent implements OnInit, AfterViewInit, OnChanges {

  @Input()
  eventData: Course;

  classList: any[];

  filterTxt: string;
  nextPage: string = null;
  isOrdering : boolean;

  private _unsubscribeAll: Subject<any>;

  constructor(
    private _courseService: CourseService,
  ) {
    this._unsubscribeAll = new Subject();
    this.classList = [];
    this.nextPage = null;
    this.isOrdering = false;
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.eventData.currentValue) {
      this._courseService.showAll(100);
    }
  }

  ngAfterViewInit() {
    this._courseService.nextAssetPageToken$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(nextToken => {
          this.nextPage = nextToken;
        });

    this._courseService.onAssetsChanged$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(assets => {
          if (assets && assets.length > 0) {
            this.classList = assets;
            this.eventData.assets = assets;
          }

        });
  }

  reorderingMode() {
    this.isOrdering = true;
    this._courseService.showAll(100);
  }

  cancelOrderingMode() {
    this.isOrdering = false;
    this._courseService.refreshAssets(this.eventData.id);
  }


  saveOrder() {
    if (this.classList.length === 0 || this.eventData.status === 'active') {
      return ;
    }
    this._courseService.saveContentList(this.classList)
        .then(result => {
          this.isOrdering = false;
          this._courseService.refreshAssets(this.eventData.id);
        });

  }

  gotoNextPage(nextPage): void {
    this._courseService.goNextAssetsPage(this.filterTxt, nextPage);
  }

  onDrop(ev): void {
    if (this.classList.length === 0 || this.eventData.status === 'active') {
      return ;
    }
    const promiseList = [];
    let i = 0;
    for (const content of this.classList) {
      content.order = i;
      promiseList.push(content);
      i++;
    }
    this.classList = promiseList;
    console.log(this.classList);
  }

}
