import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Subject} from 'rxjs';
import { takeUntil} from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import {ContentItemService} from '../content-item.service';
import {CourseAsset} from '../media.model';
import {FuseSidebarService} from '../../../../../@fuse/components/sidebar/sidebar.service';
import {ContentResourcesService} from '../content-resources.service';
import {ContentResource} from '../content-resource.model';

@Component({
    selector     : 'content-item',
    templateUrl  : './content-item.component.html',
    styleUrls    : ['./content-item.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class ContentItemComponent implements OnInit, OnDestroy
{
    media: CourseAsset;
    pageType: string;
    refCourse: any  = null;
    private _unsubscribeAll: Subject<any>;
    /**
     * Constructor
     *
     * @param _fuseSidebarService
     * @param _resourcesService
     * @param _contentItemService
     * @param {Location} _location
     * @param {MatSnackBar} _matSnackBar
     */
    constructor(
        private _fuseSidebarService: FuseSidebarService,
        private _resourcesService: ContentResourcesService,
        private _contentItemService: ContentItemService,
        private _location: Location,
        private _matSnackBar: MatSnackBar
    )
    {
        this.media = new CourseAsset();
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    ngOnInit(): void
    {

      this._contentItemService.onErrorOrSuccess$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(errOrSuccess => {
          if (errOrSuccess) {
            const msg = errOrSuccess.type === 'error' ||   errOrSuccess.type === 'TypeError'
              ? 'OOPS! Something is wrong .. Please try Again ' +  errOrSuccess.msg
              : 'Survey has been Saved Successfully';
            this._matSnackBar.open(msg, 'OK', {
              verticalPosition: 'top',
              duration        : 2000
            });
          }
        });

      this._contentItemService.onContentChanged$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(media => {
                this.media = media;
                this.refCourse = media.course;
                this.pageType = ( media && media.id !== null ) ? 'edit' : 'new' ;
            });
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    toggleSidebarResources(): void {
      this._resourcesService.refresh(this.media.id);
      this._fuseSidebarService.getSidebar('content-resource-sidebar').toggleOpen();
    }


    onFormResourcesChanges($event: ContentResource) {
      // this._contentItemService.saveCoverImages($event)
      //   .then((res) => {
      //     this.eventMesssage('Course Cover Images Successfully Saved!', 'success');
      //   });
    }

    onVideoMetaData($event)  {
        this.media.timeAprox = $event;
        this._contentItemService.saveVideoMeta(this.media )
            .then((res) => {
                this.eventMesssage('Topic Successfully updated', 'success')
            }).catch(error => {
                this.eventMesssage('Try Again Please or Call for Assistance!!', 'error')
        })
    }

    editContent(mediaForm) {
      if (this.pageType === 'new') {
        this._contentItemService.addContent(mediaForm)
          .then((res) => {
            this.eventMesssage('Topic Successfully  Added', 'success')
            this._location.go(`admin/content/${this.media.courseId}/${this.media.id}` );
          }).catch(error => {
            this.eventMesssage('Try Again Please or Call for Assistance!!', 'error')
        })
      } else {
        this._contentItemService.saveContent(mediaForm)
          .then((res) => {
              this.eventMesssage('Topic Successfully updated', 'success')
              this._location.go(`admin/content/${this.media.courseId}/${this.media.id}` );
          }).catch(error => {
            this.eventMesssage('Try Again Please or Call for Assistance!!', 'error')
        })
      }
    }

    private eventMesssage( msg: string, typeMsg: string ) {
      const customMsg = typeMsg === 'error' ? 'OOPS:' + msg : 'Success!!' + msg;
      this._matSnackBar.open(customMsg, 'OK', {
        verticalPosition: 'top',
        duration        : 2000
      });
    }
}
