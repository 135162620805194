import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuizQuestionService } from '../../quiz-question.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'modal-answer',
  templateUrl: './modal-answer.component.html',
  styleUrls: ['./modal-answer.component.scss']
})
export class ModalAnswerComponent implements OnInit {

  answer = "";
  answerFeedBack = "";
  answerFile: File;
  imageURL = 'assets/images/courses/course-image-placeholder.png';
  state = "new";
  isCorrect = false;
  question: any;

  constructor(
    public dialogRef: MatDialogRef<ModalAnswerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _questionService: QuizQuestionService,
    private _matSnackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.answer = this.data.item.answer;
    this.question = this.data.question;
    if(this.data.item.answerFeedBack)
      this.answerFeedBack = this.data.item.answerFeedBack;
    if(this.data.item.id)
      this.state = "edit";
    this.isCorrect = this.data.item.isCorrect;
    // image
    if(this.data.item.sourceMedia){
      const fileName = this.data.item.sourceMedia.split("/").pop();
      this._questionService.getPresignedFileAnswer(fileName, this.question.asset.id, this.question.id, this.data.item.id)
        .then( (res: string) => {
          this.imageURL = res;
        });
    }

    console.log("dialogdata --> ", this.data);
  }

  cancel() {
    this.dialogRef.close();
  }

  // Image Preview
  showPreview(event) {
    this.answerFile = (event.target as HTMLInputElement).files[0];
    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.imageURL = reader.result as string;
    }
    reader.readAsDataURL(this.answerFile);
  }

  saveAnswer(){
    if(this.reviewForm()){
      let data = {
        id: null,
        answer: this.answer,
        isCorrect: this.isCorrect,
        answerFeedBack: this.answerFeedBack,
        quizQuestionId: this.question.asset.quiz.id,
        qType: this.question.qType,
        status: "active",
        sourceMedia: null,
        fileName: null,
        fileType: null,
        fileSize: null,
        questionAnswersQuestionId: this.question.id,
        _version: null
      };
      if(this.state === "new"){
        let newAnswer: any;
        this._questionService.addQuestionAnswer(data)
          .then(res => {
            newAnswer = res;
            if(this.answerFile){
              return this._questionService.uploadAnswerFile(this.answerFile, this.question.asset.id, this.question.id, res.id)
            }else{
              return;
            }
          })
          .then(urlFile => {
            if(urlFile){
              const fileName = urlFile.split("/").pop();
              const data = {
                id: newAnswer.id,
                answer: newAnswer.answer,
                isCorrect: newAnswer.isCorrect,
                answerFeedBack: newAnswer.answerFeedBack,
                quizQuestionId: this.question.asset.quiz.id,
                qType: this.question.qType,
                status: "active",
                questionAnswersQuestionId: this.question.id,
                sourceMedia: urlFile,
                fileName: fileName,
                fileType: this.answerFile.type,
                fileSize: this.answerFile.size,
                _version: newAnswer._version
              }
              return this._questionService.updateQuestionAnswer(data);
            }else{
              return;
            }
          })
          .then(result => {
            this.dialogRef.close();
            this.eventMesssage(' Answer Successfully Added', 'success');
          })
          .catch(error => {
            this.eventMesssage(' Try Again Please or Call for Assistance!!', 'error');
          });
      }else{
        data.id = this.data.item.id;
        data._version = this.data.item._version;
        if(this.answerFile){
          this._questionService.uploadAnswerFile(this.answerFile, this.question.asset.id, this.question.id, data.id)
            .then(urlFile => {
              const fileName = urlFile.split("/").pop();
              data.sourceMedia = urlFile;
              data.fileName = fileName;
              data.fileType = this.answerFile.type;
              data.fileSize = this.answerFile.size;
              return this._questionService.updateQuestionAnswer(data);
            })
            .then(result => {
              this.dialogRef.close();
              this.eventMesssage(' Answer Successfully Updated', 'success');
            })
            .catch(error => {
              this.eventMesssage(' Try Again Please or Call for Assistance!!', 'error');
            });
        }else{
          this._questionService.updateQuestionAnswer(data)
          .then(res => {
            this.eventMesssage(' Answer Successfully Updated', 'success');
            this.dialogRef.close();
          })
          .catch(error => {
            this.eventMesssage(' Try Again Please or Call for Assistance!!', 'error');
          });
        }
      }
    }
  }

  reviewForm(): boolean {
    return this.answer.length > 0;
  }

  private eventMesssage( msg: string, typeMsg: string ) {
    const customMsg = typeMsg === 'error' ? 'OOPS:' + msg : 'Success!!' + msg;
    this._matSnackBar.open(customMsg, 'OK', {
      verticalPosition: 'top',
      duration        : 2000
    });
  }
}
