import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {User, UserGroup} from "../../admin/users/user.model";
import {BehaviorSubject, Observable, of, Subject} from "rxjs";
import {AuthService} from "../../auth/services/auth.service";
import {
  APIService, CreateCourseSubsInput,
  CreateNotificationInput, elStatus, elSubStatus,
  ModelCourseAssetsFilterInput, ModelCourseAssetSubsFilterInput, ModelCourseFilterInput,
  ModelCourseSubsFilterInput,
  ModelMembershipFilterInput
} from "../../../API.service";
import {Hub} from "@aws-amplify/core";
import * as AWS from "aws-sdk";
import * as S3 from "aws-sdk/clients/s3";
import * as _ from "lodash";
import {Notification} from "../../../../models";
import {format} from "date-fns";
import {getDeepFromObject} from "../../auth/helpers";
import {APICustomService} from "../../../API.customize.service";
import {STUDENTS_MANAGER_OPTIONS} from "./students.options";
import {FuseProgressBarService} from "../../../../@fuse/components/progress-bar/progress-bar.service";
import {SyncSettings} from "../../../../@fuse/services/sync-settings.service";

@Injectable()
export class StudentsService implements Resolve<User>
{

  private onUsersChanged: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public onUsersChanged$: Observable<any[]> = this.onUsersChanged.asObservable();

  private onLicChanged: BehaviorSubject<any[]> = new BehaviorSubject([]);
  onLicChanged$: Observable<any[]> = this.onLicChanged.asObservable();

  private onSelectedGroup: BehaviorSubject<any> = new BehaviorSubject(null);
  public onSelectedGroup$: Observable<any> = this.onSelectedGroup.asObservable();

  private onSelectedUsersChanged: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public onSelectedUsersChanged$: Observable<any[]> = this.onSelectedUsersChanged.asObservable();

  private onUserSelected: BehaviorSubject<any> = new BehaviorSubject(null);
  public onUserSelected$: Observable<any> = this.onUserSelected.asObservable();

  private onNextPageChanged: BehaviorSubject<any> = new BehaviorSubject(null);
  public onNextPageChanged$: Observable<any> = this.onNextPageChanged.asObservable();

  private onPercentageChange: BehaviorSubject<any> = new BehaviorSubject(null);
  public onPercentageChange$: Observable<any> = this.onPercentageChange.asObservable();

  private onImportsChanged = new BehaviorSubject<any[]>([]);
  onImportsChanged$ = this.onImportsChanged.asObservable();

  private onImportsIteratorChanged = new BehaviorSubject<any>(null);
  onImportsIteratorChanged$ = this.onImportsIteratorChanged.asObservable();

  private onOpenSideBar = new BehaviorSubject<any>(null);
  onOpenSideBar$ = this.onOpenSideBar.asObservable();

  private onAllCoursesChanged: BehaviorSubject<any> = new BehaviorSubject(null);
  public onAllCoursesChanged$: Observable<any> = this.onAllCoursesChanged.asObservable();

  private onGetSelectedUsersChanged: BehaviorSubject<any> = new BehaviorSubject(null);
  public onGetSelectedUsersChanged$: Observable<any> = this.onGetSelectedUsersChanged.asObservable();

  private nextPageTokenCourses: BehaviorSubject<any> = new BehaviorSubject(null);
  public nextPageTokenCourses$: Observable<any> = this.nextPageTokenCourses.asObservable();

  onSearchTextChanged: Subject<any>;
  onFilterChanged: Subject<any>;

  selectedGroup: any;
  users: User[];
  groups: UserGroup[];
  user: any;
  selectedUsers: string[] = [];
  nextToken: any;

  searchText: string;
  filterBy: string;
  pageSize: number;
  private bucket: string;
  // tslint:disable-next-line:max-line-length
  private readonly profileBase64Img = '/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAMDAwMDAwQEBAQFBQUFBQcHBgYHBwsICQgJCAsRCwwLCwwLEQ8SDw4PEg8bFRMTFRsfGhkaHyYiIiYwLTA+PlQBAwMDAwMDBAQEBAUFBQUFBwcGBgcHCwgJCAkICxELDAsLDAsRDxIPDg8SDxsVExMVGx8aGRofJiIiJjAtMD4+VP/CABEIAGAAYAMBIgACEQEDEQH/xAAbAAEBAQADAQEAAAAAAAAAAAAACAcEBQYJA//aAAgBAQAAAAD6QAADwOO8XevYAYbJIuDSARTloq7egSXhYp6igRLmI1a0gTDOopSlAfj87eveqv4BFOWtcswHV4jLIpzdO4eHwfE+GDlbRvkIgA//xAAXAQEBAQEAAAAAAAAAAAAAAAAAAwIB/9oACAECEAAAAKtMm6o5bqlh26HHboctoZ//xAAXAQADAQAAAAAAAAAAAAAAAAAAAQMC/9oACAEDEAAAAMCGGYl2ZiW0KDsxRLOWAN//xAA2EAABAwIDBQQIBgMAAAAAAAABAgMEBREGByEAEiAxURATQYEIFCIjMGFxkSQmMkBCgmJyof/aAAgBAQABPwD9hiTMvCWGVrakzO/kp0MaOnvFg9DyCdqj6QNQUtQptHYQnwVIWpwnyTu22gekBXEOj16lQnW/EMlbSvuor2wljihYyiKdgu7rqdHYztg4g/S+o+Y+BnJjuTh+KzR6c6pqXMbK3XUmym2b29noVEc9iSo3PPtBKSCDYjkRtlXiGRiPB8Z6SvvJEVa4zqzqVluxST87KF+POZxxeYFRC+TbUdKPp3aTw5AH8t1QdKhf7tp48+aYiPiWFOQpP4uFZQ8d5o2v9lcPo+SUKgVuNdO8l9le742UCL/8484XZa8f1Jt51SktJZSyDyQgtpVYeZ4cl5MhjHsNtsqCH2JCHR1SEFYv5p487MGVV+rorsGI4/HVEAlFA3y2Wr+0oD+NuAAqNhz2yYwJWabVnK1VIbkVAjFEdDuiypwi6t3mLAcciO1KjvMOp3m3UKQtPVKxY7VenuUmqToDmq4kl1lR6ltRTftwJDM/GNCZ3N4GoMqUn/FtQUr4Ob1Tw/UsVvKpbLiXmVFma5oEPONm28mx8ie3KLEeHMPVtZqTDpky1NMR5ACShgKNlFVyLX6jjqtbpFDj+s1KYxFa8FOrAv8AIDmT9NsV550hMCTGoLT7slbZS3JWju0IvpvAE3JHhe2xJUVEquTqSeDB+eFMap0SHXmpIfZbCDKQkOJWBoFKF7362vtR6/RsQR+/pk1iU347itR/snmnz7cQ5iYSwyVtzJ6VSE847PvXL9CBon+xG2JM9azO32aLGRBaOgeXZx4/Qck7T6jUKpIVJnSXZDyubjqys/c8cKfNp0hEmJJdjPJ/S40soUPMbYbzzr9O3GawwiosjTvRZt0Dy0VthzMbCWKChqJOS1IVyjP+7cv0F9Ff1J2JKjc6k8z8MEpNxoRyO3//xAAdEQEAAwACAwEAAAAAAAAAAAABAAIQICESMTJB/9oACAECAQE/AMK2fyNbB64UBdt1Z2n1t/rRTGKugsOiMRMKV4+BP//EABsRAQACAwEBAAAAAAAAAAAAAAEAEAISISAx/9oACAEDAQE/AK2IZD4zULx+Xn8vB5aDR1gBexHrDjDIa3fO7P/Z';

  /**
   * Constructor
   *
   * @param options
   * @param _auth
   * @param api
   * @param customApi
   * @param _fuseProgressBarService
   * @param syncSettings
   */
  constructor(
      @Inject(STUDENTS_MANAGER_OPTIONS) protected options = {},
      private _auth: AuthService,
      private api: APIService,
      private customApi: APICustomService,
      private _fuseProgressBarService: FuseProgressBarService,
      private syncSettings: SyncSettings,
  ) {
    // Set the defaults
    this.onSearchTextChanged = new Subject();
    this.onFilterChanged = new Subject();
    this.filterBy = 'all';
    this.bucket = this.getConfigValue('bucketName');
    this.pageSize = this.getConfigValue('pageSize');
    this.syncSettings.onSettingsChanged$.subscribe((res) => {
      this.pageSize = res.pageSize;
    });
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User>[] | Promise<any> | any {
    console.log('1')
    return new Promise((resolve, reject) => {
      Promise.all([
          this.getMemberShips()
      ]).then(
          ([users]) => {
            this.onSearchTextChanged.subscribe(sTxt => {
              this.searchText = sTxt.q;
              this.nextToken = null;
              if (this.searchText.length>0) {
                of(this.getUsersByGroupAndFilter( sTxt.groupId, this.searchText));
              } else {
                of(this.getUsersByGroup(sTxt.groupId))
              }

            });

            Hub.listen('settings', (data) => {
              this.pageSize = data.payload.data.pageSize;
              this.nextToken = null;
              this.refresh();
            });
            resolve();
          },
          reject
      );
    });
  }

  setSelectedUser(user: any) {
    this.onUserSelected.next(user);
  }

  setSelectedGroup(group: any) {
      this.selectedGroup = group;
      this.onSelectedGroup.next(group);
  }

  refreshLicense(license){
    this.onOpenSideBar.next(license);
  }

  setNextIteratorImport(item: any) {
      this.onImportsIteratorChanged.next(item);
  }

  openSideBar(license: any) {
    this.onOpenSideBar.next(license);
  }

  refresh() {
      of(this.getUsersByGroup( this.filterBy ,this.pageSize, this.nextToken));
  }

  refreshLicenses() {
    of(this.getMemberShips())
  }

  refreshStudents(groupName: string) {
    of(this.getUsersByGroup(groupName))
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  getMemberShips(sTxt?:string ,pageSize?: number): Promise<any[]> {
    console.log(2);
    const strPageSize = pageSize ? pageSize : this.pageSize ;
    return new Promise((resolve, reject) => {
      const orgId = this.getAttrValue('custom:organizationId')
      let filter: ModelMembershipFilterInput;
      filter = {
        contactId: {eq : orgId},
        status: {eq: 'system'}
      };
      if (sTxt) {
        filter.prefix = {contains: sTxt};
      }
      this.api.ListMemberships(filter, strPageSize, null)
          .then(lics => {
            this.onLicChanged.next(lics.items)
            resolve(lics.items);
          })
          .catch((err) => reject(err));
    });
  }

  syncCognito(): any {
    return new AWS.CognitoIdentityServiceProvider(
        {
          apiVersion: '2016-04-18',
          region: this.getConfigValue('region'),
          accessKeyId: this.getConfigValue('accessKeyId'),
          secretAccessKey: this.getConfigValue('secretAccessKey')
        }
    );
  }

  getUsersByGroup(group: string, pageSize?: number,  nextToken?: string): Promise<any[]> {

    const strPageSize = pageSize ? pageSize : this.pageSize ;
    return new Promise((resolve, reject) => {
      const conn = this.syncCognito();
      const params = {
        GroupName: group,
        UserPoolId: this.getConfigValue('UserPoolId'),
        Limit: strPageSize
      };
      if (nextToken) {
        params['PaginationToken'] = nextToken;
      }
      conn.listUsersInGroup(params, (err, data) => {
        if (err) {
          console.log(err);
          reject(err);
        }
        this.nextToken = data && data['PaginationToken'] ? data['PaginationToken'] : null;
        this.users = this.mapListOfUsers(data.Users);
        this.onUsersChanged.next(this.users);
        this.onNextPageChanged.next(this.nextToken );
        resolve(this.users);
      });
    });
  }

  getUsersByGroupAndFilter(group: string, filter: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const conn = this.syncCognito();
      const params = {
        GroupName: group,
        UserPoolId: this.getConfigValue('UserPoolId'),
        Limit: 60
      };
      conn.listUsersInGroup(params, (err, data) => {
        if (err) {
          console.log(err);
          reject(err);
        }
        const users = this.mapListOfUsers(data.Users);
        const found = _.find(users, function(arrayValue){
          console.log(arrayValue);
          return arrayValue.email === filter;
        });
        if (found) {
          this.users = [found];
        } else {
          this.users= [];
        }
        this.onUsersChanged.next(this.users);
        this.onNextPageChanged.next(null);
        resolve(this.users);
      });
    });
  }

  syncS3(): any {
    return new S3(
        {
          accessKeyId: this.getConfigValue('accessKeyId'),
          secretAccessKey: this.getConfigValue('secretAccessKey'),
          region: this.getConfigValue('region')
        });
  }
  // get base64Image
  getS3AvatarFileByName(imgFile: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (imgFile) {
        const keyArray = imgFile.split('/');
        const key = `userpics/${keyArray[keyArray.length - 2] }/${keyArray[keyArray.length - 1]}`;
        const newConn = this.syncS3();
        const params = {
          Bucket: this.bucket,
          Key: key
        };
        newConn.getObject(params, (err, urlLink) => {
          if (err) {
            resolve(`data:image/jpeg;base64,${this.profileBase64Img}`);
          } else {
            const base64Img = 'data:image/jpeg;base64,' + urlLink.Body.toString('base64');
            resolve(base64Img);
          }
        });
      } else {
        resolve(`data:image/jpeg;base64,${this.profileBase64Img}`);
      }
    });
  }
  /**
   * Get users
   *
   * @returns {Promise<any>}
   */

  getAttrValue(key: string): any {
    const userInfo = this._auth.getUserId().attributes;
    return getDeepFromObject(userInfo, key, null);
  }

  getUser(userName: string ): Promise<any> {
    return new Promise((resolve, reject) => {
      const conn = this.syncCognito();
      const params = {
        UserPoolId: this.getConfigValue('UserPoolId'),
        Username: userName
      };
      conn.adminGetUser(params, (err, data) => {
        if (err) {
          console.log(err);
          reject(err);
        }
        const user = this.mapUserV2(data);
        this.onUserSelected.next(user);
        resolve(user);
      });
    });
  }

  goNextPage( nextPageToken: string) {
      of(this.getUsersByGroup(this.selectedGroup.groupId, this.pageSize, nextPageToken));
  }

  getCourses(userId: string) {
    let filter: ModelCourseSubsFilterInput =  {
      userId: { eq: userId}
    };
    console.log(filter);
    return new Promise((resolve, reject) => {
      this.api.ListCourseSubss(filter, 999)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
    });
  }

  getCoursesByToken(nextToken: string, searchTxt?: string) {
    this._fuseProgressBarService.show();
    let filter: ModelCourseFilterInput;
    let text = "";
    if(searchTxt)
      text = searchTxt.trim();
    filter = {
      title: {contains: text},
      status: {eq: elStatus.active},
    };
    return new Promise((resolve, reject) => {
      this.customApi.ListCourses(filter, this.pageSize, nextToken)
          .then((result) => {
            this.onAllCoursesChanged.next(result);
            this.nextPageTokenCourses.next(result.nextToken);
            this._fuseProgressBarService.hide();
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
    });
  }

  subsToCourse(course: any, userId: string): Promise<any> {
    this._fuseProgressBarService.show();
    const subsInput: CreateCourseSubsInput = {
      courseId: course.id,
      title: course.title,
      description: course.description,
      category: course.category.category,
      level: course.level,
      tags: course.tags,
      userId: userId,
      status: elSubStatus.Enrolled,
      updatedAt: new Date().toISOString(),
      createdAt: new Date().toISOString(),
      _version: null
    };
    return new Promise((resolve, reject) => {
      this.api.CreateCourseSubs(subsInput )
          .then((result) => {
            this._fuseProgressBarService.hide();
            resolve(result);
          })
          .catch((err) => {
            this._fuseProgressBarService.hide();
            reject(err)
          });
    });
  }

  getCoursesSubs(courseId: string, userId: string): Promise<any> {
    let filter: ModelCourseSubsFilterInput =  {
      userId: { eq: userId},
      and:[
        {courseId: {eq: courseId}}
      ]
    };
    let pageSize = 999;
    let nextToken = null;
    return new Promise((resolve, reject) => {
      this.api.ListCourseSubss(filter, pageSize, nextToken)
          .then((result) => {
            resolve(result.items);
          })
          .catch((err) => {
            reject(err);
          });
    });
  }

  CalculateProgress(courseId: string, userId: string): Promise<any> {
    let totalCourseAssets;
    return new Promise((resolve, reject) => {
      const filter: ModelCourseAssetsFilterInput = {
        courseId: {eq: courseId},
        or: [
          {status: {eq: elStatus.active}},
          {status: {eq: elStatus.new}}]
      };
      this.customApi.ListCourseAssetssToCount(filter,999)
          .then((resultAssets) => {
            totalCourseAssets = resultAssets.items.length;
            const filterSubs: ModelCourseAssetSubsFilterInput = {
              courseSubsId: {eq: courseId},
              userId: {eq: userId}
            };
            return this.customApi.ListCourseAssetSubssToCount(filterSubs, 999);
          })
          .then(resultAssetsSubs => {
            let countAssetSubsCompleted = 0;
            resultAssetsSubs.items.forEach(element => {
              if(element.status === "Completed"){
                countAssetSubsCompleted++;
              }
            });
            let perProgress;
            if(totalCourseAssets > 0){
              perProgress = ((countAssetSubsCompleted/totalCourseAssets) * 100).toFixed(0);
            }else{
              perProgress = 0;
            }
            let percentageChange = {
              perProgress: perProgress,
              courseId: courseId
            };
            this.onPercentageChange.next(percentageChange);
            resolve(perProgress);
          })
          .catch((err) => reject(err));
    })
  }

  removeUserFromGroup( groupName: string, userName?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const conn = this.syncCognito();
      const params = {
        UserPoolId: this.getConfigValue('UserPoolId'),
        GroupName: groupName,
        Username: userName
      };
      conn.adminRemoveUserFromGroup(params, (err, data) => {
        if (err) {
          console.log(err);
          reject(err);
        }
        resolve(data);
      });
    });
  }

  addUserToGroup( groupName: string, userName?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const conn = this.syncCognito();
      const params = {
        UserPoolId: this.getConfigValue('UserPoolId'),
        GroupName: groupName,
        Username: userName
      };
      conn.adminAddUserToGroup(params, (err, data) => {
        if (err) {
          console.log(err);
          reject(err);
        }
        resolve(data);
      });
    });
  }

  addNewImportContact(contact: any[]) {
    this.onImportsChanged.next(contact);
  }


  getConfirmationCode() {
    return new Promise((resolve, reject) => {
      const conn = this.syncCognito();
      // conn.resendConfirmationCode('phone_number', (err, data, verCode) => {
      //   if (err) {
      //     console.log(err);
      //     reject(err);
      //   }
      //   console.log(data);
      resolve(true);
      // });
      // });
    });
  }

  disableUser(  userName?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const conn = this.syncCognito();
      const params = {
        UserPoolId: this.getConfigValue('UserPoolId'),
        Username: userName
      };
      conn.adminDisableUser(params, (err, data) => {
        if (err) {
          console.log(err);
          reject(err);
        }
        resolve(data);
      });
    });
  }

  enableUser(  userName?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const conn = this.syncCognito();
      const params = {
        UserPoolId: this.getConfigValue('UserPoolId'),
        Username: userName
      };
      conn.adminEnableUser(params, (err, data) => {
        if (err) {
          console.log(err);
          reject(err);
        }
        resolve(data);
      });
    });
  }

  mapUserV2(item: any): User {
    let imageUrl = '';
    let external = false;
    const email = this.mapAttributeFromUser('email', item.UserAttributes);
    // tslint:disable-next-line:variable-name
    const email_verified = this.mapAttributeFromUser('email_verified', item.UserAttributes);
    const name = this.mapAttributeFromUser('name', item.UserAttributes);
    // tslint:disable-next-line:variable-name
    const phone_number_verified = this.mapAttributeFromUser('phone_number_verified', item.UserAttributes);
    // tslint:disable-next-line:variable-name
    const avatar_type = this.mapAttributeFromUser('custom:avatarType', item.UserAttributes);

    const ident = this.getIdentities(item.UserAttributes);
    const identities = item.Attributes && ident ? ident[0] : false;
    const picture = this.getPicture(item.UserAttributes);
    if (identities && picture) {
      imageUrl = item.Attributes && picture ? picture : false;
      external = true;
    } else if (picture) {
      imageUrl = picture;
    }
    // tslint:disable-next-line:variable-name
    const account_status = item.Enabled;
    const createdAt = item.UserCreateDate;
    const updatedAt = item.UserLastModifiedDate;
    const user = {
      id: item.Username,
      email_verified: email_verified,
      phone_verified: phone_number_verified,
      account_status: account_status,
      email: email,
      avatar_type: avatar_type,
      user_name: item.Username,
      name: name,
      updatedAt: updatedAt,
      createdAt: createdAt,
      user_status:  item.UserStatus,
      attributes : item.UserAttributes,
      external: external,
      picture: imageUrl
    };
    return new User(user);
  }
  // ListofUsers
  mapUser(item: any): User {
    let imageUrl = '';
    let external = false;
    const email = this.mapAttributeFromUser('email', item.Attributes);
    // tslint:disable-next-line:variable-name
    const email_verified = this.mapAttributeFromUser('email_verified', item.Attributes);
    const name = this.mapAttributeFromUser('name', item.Attributes);
    // tslint:disable-next-line:variable-name
    const phone_number_verified = this.mapAttributeFromUser('phone_number_verified', item.Attributes);
    // tslint:disable-next-line:variable-name
    const avatar_type = this.mapAttributeFromUser('custom:avatarType', item.Attributes);

    const ident = this.getIdentities(item.Attributes);
    const identities = item.Attributes && ident ? ident[0] : false;
    const picture = this.getPicture(item.Attributes);
    if (identities && picture) {
      imageUrl = item.Attributes && picture ? picture : false;
      external = true;
    } else if (picture) {
      imageUrl = picture;
    }
    // tslint:disable-next-line:variable-name
    const account_status = item.Enabled;
    const createdAt = item.UserCreateDate;
    const updatedAt = item.UserLastModifiedDate;
    const user = {
      id: item.Username,
      email_verified: email_verified,
      phone_verified: phone_number_verified,
      account_status: account_status,
      email: email,
      avatar_type: avatar_type,
      user_name: item.Username,
      name: name,
      updatedAt: updatedAt,
      createdAt: createdAt,
      user_status:  item.UserStatus,
      attributes : item.Attributes,
      external: external,
      picture: imageUrl
    };
    return new User(user);
  }

  mapListOfUsers(contents: any[]): User[] {
    const userListDS = [];
    contents.map((item) => {
      const user  = this.mapUser(item);
      userListDS.push(new User( user));
    });
    return userListDS;
  }

  getIdentities(listAttr: any[]): any {
    let identifities = null;
    listAttr.forEach(item => {
      if (item.Name === 'identities') {
        identifities = JSON.parse(item.Value);
      }
    });
    return identifities;
  }

  getPicture(listAttr: any[]): any {
    let identpic = null;
    listAttr.forEach(item => {
      if (item.Name === 'picture') {
        identpic = item.Value;
      }
    });

    return identpic;
  }

  mapAttributeFromUser(attrName: string, attrs: any[]) {
    const found = _.find(attrs, ['Name', attrName]);
    if (found) {
      return found['Value'];
    }
    return null;
  }

  /**
   * Toggle selected contact by id
   *
   * @param id
   */
  toggleSelectedUser(id): void {
    // First, check if we already have that contact as selected...
    if ( this.selectedUsers.length > 0 )
    {
      const index = this.selectedUsers.indexOf(id);

      if ( index !== -1 )
      {
        this.selectedUsers.splice(index, 1);

        // Trigger the next event
        this.onSelectedUsersChanged.next(this.selectedUsers);

        // Return
        return;
      }
    }

    // If we don't have it, push as selected
    this.selectedUsers.push(id);

    // Trigger the next event
    this.onSelectedUsersChanged.next(this.selectedUsers);
  }

  /**
   * Toggle select all
   */
  toggleSelectAll(): void
  {
    if ( this.selectedUsers.length > 0 )
    {
      this.deselectUsers();
    }
    else
    {
      this.selectUsers();
    }
  }

  /**
   * Select users
   *
   * @param filterParameter
   * @param filterValue
   */
  selectUsers(filterParameter?, filterValue?): void
  {
    this.selectedUsers = [];

    // If there is no filter, select all users
    if ( filterParameter === undefined || filterValue === undefined )
    {
      this.selectedUsers = [];
      this.users.map(contact => {
        this.selectedUsers.push(contact.id);
      });
    }

    // Trigger the next event
    this.onSelectedUsersChanged.next(this.selectedUsers);
  }


  /**
   * Deselect users
   */
  deselectUsers(): void
  {
    this.selectedUsers = [];

    // Trigger the next event
    this.onSelectedUsersChanged.next(this.selectedUsers);
  }
  /**
   * Get selected users
   */
  getSelectedUsers(): void {
    this.onGetSelectedUsersChanged.next(this.selectedUsers);
  }

  /**
   * Delete selected users
   */

  addNotifications(data: any, userId: string): Promise<Notification> {
    return new Promise((resolve, reject) => {
      const urlTxt = _.isEmpty(data.localUrl) ? 'https://kidsthatcode.org/' : data.localUrl;
      const dateFor = format(new Date(), 'yyyy-MM-dd');
      const payload: CreateNotificationInput = {
        typeNotify: data.notiType,
        userId: userId,
        body: data.message,
        subject: data.subject,
        localUrl: urlTxt,
        renotify: true,
        silent: false,
        dateFor: dateFor,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      }
      this.api.CreateNotification(payload)
          .then(result => {
            resolve(result);
          }).catch( error => {
        reject(error);
      });
    });
  }

  sendNotifications(data?: any): Promise<any>  {
    const promisesArray = [];
    this.selectedUsers.forEach(userId => {
      promisesArray.push(this.addNotifications(data, userId));
    });
    return new Promise((resolve, reject) => {
      Promise.all(promisesArray).then(([res]) => {
        resolve(res);
      }, reject);
    });
  }

  // Functions
  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }

}
