import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {NotifyModel} from '../notification.model';
import {Subject} from 'rxjs';
import { Location } from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {NotifyListService} from '../notify-list.service';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-notify-list',
  templateUrl: './notify-list.component.html',
  styleUrls: ['./notify-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class NotifyListComponent implements OnInit, OnDestroy {

  notifications: NotifyModel[];
  currentNotify: NotifyModel;
  nextPage: string;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _location: Location,
    private _notifyService: NotifyListService,
  ) {  this._unsubscribeAll = new Subject(); }

  ngOnInit() {
    // Subscribe to update mails on changes

    this._notifyService.onNextPageChanged$
      .subscribe(nextToken => {
        this.nextPage = nextToken;
      });

    this._notifyService.onNotifyChanged$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(notifies => {
        this.notifications = notifies;
      });

    // Subscribe to update current mail on changes
    this._notifyService.onCurrentNotifyChanged$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(currentNotify => {
        if ( !currentNotify )
        {
          // Set the current  id to null to deselect the current mail
          this.currentNotify = null;
          this._location.go('msgs');
        }
        else
        {
          this.currentNotify = currentNotify;
        }
      });

  }

  /**
   * On destroy
   */
  ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  gotoNextPage(nextPage): void {
    this._notifyService.goNextPage( nextPage);
  }


  /**
   * Read mail
   *
   * @param notifyId
   */
  readNotification(notifyId: string): void
  {
    this._location.go('msgs/' + notifyId);
    // Set current record
    this._notifyService.setCurrentNotify(notifyId);
  }
}
