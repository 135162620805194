import { InjectionToken } from '@angular/core';

export interface AcademyOptions {
  accessKeyId: string;
  secretAccessKey: string;
  UserPoolId?: any;
  AttributesToGet?: any;
  region: string;
  pageSize?: any;
  usersAvailable: boolean;
  S3BucketName: string;
  S3BucketTranscoder: string;
}

export const defaultAcademyOptions: any = {
  accessKeyId: 'AKIAY6NY73HUV5NXALUR',
  secretAccessKey: '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',
  UserPoolId: 'us-east-1_I3aBWHf14',
  AttributesToGet: [],
  region: 'us-east-1',
  pageSize: 10,
  usersAvailable: false,
  S3BucketName: 'kidsthatcode-media-resources',
  S3BucketTranscoder: 'kidsthatcode-lms-resourses-transcoded'
};

export const ACADEMY_MANAGER_OPTIONS = new InjectionToken<AcademyOptions>('Academy Manager Options');

