import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { AcademyCourseService } from 'app/main/academy/course.service';
import * as _ from 'lodash';
import {ActivatedRoute, Router} from '@angular/router';
import {AVideoOverviewService} from '../avideo-preview.service';
import {QuizViewerService} from "../quiz-viewer.service";

@Component({
    selector     : 'academy-course',
    templateUrl  : './course.component.html',
    styleUrls    : ['./course.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class AcademyCourseComponent implements OnInit, OnDestroy, AfterViewInit
{
    course: any;
    courseStepContent: any;
    currentStep: any;
    currentSubs: any;
    courseContents: any[];
    contentTitle: any;

    @ViewChildren(FusePerfectScrollbarDirective)
    fuseScrollbarDirectives: QueryList<FusePerfectScrollbarDirective>;
    // Private
    private _unsubscribeAll: Subject<any>;
    /**
     * Constructor
     *
     * @param {AcademyCourseService} _academyCourseService
     * @param _videoOverviewService
     * @param _quizViewerService
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseSidebarService} _fuseSidebarService
     * @param router
     * @param route
     */
    constructor(
        private _academyCourseService: AcademyCourseService,
        private _videoOverviewService: AVideoOverviewService,
        private _quizViewerService: QuizViewerService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseSidebarService: FuseSidebarService,
        private router: Router,
        private route: ActivatedRoute
    )
    {
        // Set the defaults
        this.currentStep = {};
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */

    ngOnInit(): void {
      this._academyCourseService.onCourseChanged$
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(course => {
            if (course) {
              this.course = course;
              this.course['totalSteps'] = this.course.course.assets.items.length;
              if (this.course.course.assets.items.length > 0) {
                this.course.course.assets.items = _.orderBy(this.course.course.assets.items, 'order', 'asc');
              }
              const urlPath = this.router.url.split('/');
              this.courseContents = this.course.course.assets.items;
              if (urlPath.length === 4 ) {
                  const step = this.course.course.assets.items[0];
                  if (step.assetType==='Quiz') {
                      this.router.navigate([`qz/${step.id}`],  {relativeTo: this.route});
                  } else {
                      this.router.navigate([`cl/${step.id}`],  {relativeTo: this.route});
                  }
              }
            }
          });

      this._videoOverviewService.onAssetChanged$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(asset => {
          if (asset) {
            this.contentTitle = asset.title;
            this.currentStep = asset;
          }
        });

        this._quizViewerService.onQuizAssetChanged$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(asset => {
                if (asset) {
                    this.contentTitle = asset.title;
                    this.currentStep = asset;
                }
            });

        // New Subs and UPdates
        this._videoOverviewService.onAssetSubsChanged$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(subs => {
                this.currentSubs = subs;
                this.currentStep.assetSub = subs;
            });

        // New Subs and UPdates
        this._quizViewerService.onAssetSubsChanged$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(subs => {
                this.currentSubs = subs;
                this.currentStep.assetSub = subs;
            });


    }

    /**
     * After view init
     */
    ngAfterViewInit(): void {
        this.courseStepContent = this.fuseScrollbarDirectives.find((fuseScrollbarDirective) => {
            return fuseScrollbarDirective.elementRef.nativeElement.id === 'course-step-content';
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Go to next step
     */
    gotoNextStep(): void
    {
      if ( this.currentStep.order === this.course.totalSteps - 1 )
      {
        return;
      }
      // Increase the current step
      const next = this.currentStep.order + 1;
      // Decrease the current step
      const nextStep =  _.find(this.courseContents , {order: next});
      this.gotoStep(nextStep);
    }

    /**
     * Go to previous step
     */
    gotoPreviousStep(): void
    {
      if ( this.currentStep.order === 0 )
      {
        return;
      }

      const prev = this.currentStep.order - 1;
      // Decrease the current step
      const nextStep  =  _.find(this.courseContents , {order: prev});

      this.gotoStep(nextStep);
    }

    /**
     * Go to step
     *
     * @param step
     */
    gotoStep(step): void {
      if (step && step.assetType === 'Topic') {

        // this.router.navigate([`my/courses/${step.courseId}/cl/${step.id}`]);
        this.router.navigate([`cl/${step.id}`],  {relativeTo: this.route});
      } else {
          this.router.navigate([`qz/${step.id}`],  {relativeTo: this.route});
      }
    }

    /**
     * Toggle the sidebar
     *
     * @param name
     */

    toggleSidebar(name): void
    {
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    }
}
