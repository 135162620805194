import {AfterViewInit, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from "../../../../../@fuse/animations";

@Component({
    selector: 'msc-course-detail-desc',
    templateUrl: './msc-course-detail-desc.component.html',
    styleUrls: ['./msc-course-detail-desc.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations

})
export class MscCourseDetailDescComponent implements OnInit , AfterViewInit{

    @Input()
    courseDetails: any;

    @Input()
    userSubs: any;

    learnDescription: string ='';
    requirements: string ='';
    constructor() { }

    ngOnInit() {}

    ngAfterViewInit() {
        if (this.courseDetails) {
            if (this.courseDetails.learnDescription && this.courseDetails.learnDescription.length > 0) {
                const parseLi = this.courseDetails.learnDescription.split('\n');
                this.learnDescription = "<ul>\n";
                parseLi.forEach( liItem=> {
                    this.learnDescription += "<li>" + liItem + "</li>\n";
                })
                this.learnDescription += "</ul>\n";
            }
            if (this.courseDetails.requirements && this.courseDetails.requirements.length > 0) {
                const parseLi = this.courseDetails.requirements.split('\n');
                this.requirements = "<ul>\n";
                parseLi.forEach( liItem=> {
                    this.requirements += "<li>" + liItem + "</li>\n";
                })
                this.requirements += "</ul>\n";
            }
        }

    }

}
