import {Component, OnInit, OnDestroy, Input, ElementRef, ViewChild} from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { StudentsService } from '../../students.service';

import { MatTableDataSource } from '@angular/material/table';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {fromEvent, of, Subject} from 'rxjs';
import * as _ from "lodash";
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'student-assigment-sidebar',
  templateUrl: './student-assigment-sidebar.component.html',
  styleUrls: ['./student-assigment-sidebar.component.scss'],
  animations : fuseAnimations
})
export class StudentAssigmentSidebarComponent implements OnInit, OnDestroy {
  
  @Input('studentList') studentList: any[];

  @ViewChild('filter', {static: true}) filter: ElementRef;
  filterTxt: string;

  courses_data: any[];
  dataSource: any;
  checkbox = {};
  coursesSelected = [];
  displayedColumns: string[] = ['checkbox', 'title', 'category'];
  selectedUsers = [];
  private _unsubscribeAll: Subject<any>;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  nextPage: any;
  constructor(
    private _studentsService: StudentsService,
    private _matDialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this.courses_data = [];
  }

  ngOnInit() {
    this._studentsService.onAllCoursesChanged$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        if (!_.isEmpty(res)){
          this.checkbox={};
          this.coursesSelected = [];
          this.courses_data = res.items;
          this.dataSource = new MatTableDataSource(this.courses_data);
        }
      });
    
    this._studentsService.onGetSelectedUsersChanged$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(selected => {
        this.checkbox={};
        this.coursesSelected = [];
        this.filter.nativeElement.value = "";
        if(this.dataSource){
          this.dataSource.data = [];
        }
        this.selectedUsers = selected;
        console.log(this.selectedUsers);
      });

    this._studentsService.nextPageTokenCourses$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(nextToken => {
          this.nextPage = nextToken;
        })

    // filter
    fromEvent(this.filter.nativeElement, 'keyup')
        .pipe(
            debounceTime(550),
            distinctUntilChanged()
        )
        .subscribe(() => {
          if( !this.dataSource ){
            return;
          }
          this.filterTxt = this.filter.nativeElement.value;
          this._studentsService.getCoursesByToken(null, this.filterTxt)
              .then(( res ) => {})
              .catch((err) => {
                return ;
              });
        });
  }

  refreshAllCourses(){
    this.checkbox={};
    this.coursesSelected = [];
    this.nextPage = null;
    this._studentsService.getCoursesByToken(this.nextPage, this.filterTxt);
  }

  selectAllCourses() {
    this.coursesSelected = this.courses_data.map(item => {
      this.checkbox[item.id]=true;
      return item;
    });
  }

  deselectAllCourses() {
    this.checkbox = {};
    this.coursesSelected = [];
  }

  onSelectedChange(course: any){
    const index = this.coursesSelected.findIndex( item => item.id === course.id);
    if(index!==-1){
      this.coursesSelected.splice(index, 1);
    }else{
      this.coursesSelected.push(course);
    }
  }

  startAssigment() {
    this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });
    this.confirmDialogRef.componentInstance.confirmMessage = `Allowed to Assign Courses, Proceed?`;
    this.confirmDialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.assignCoursesUsers();
        }
        this.confirmDialogRef = null;
      });
  }

  assignCoursesUsers(){
    this.selectedUsers.forEach((userId: string) => {
      this.coursesSelected.forEach((course: any) => {
        this._studentsService.getCoursesSubs(course.id, userId)
          .then( (res: any) => {
            if(res && res.length === 0){
              this._studentsService.subsToCourse(course, userId)
              .then(res => {
              })
              .catch(error => {
                console.log(error);
              })
            }
            this.popMsg(`The Notifications has been Sent! to ${this._studentsService.selectedUsers.length} users`);
          })
          .catch(error => {
            console.log(error);
          })
      })
    })
  }

  nextCoursesPage(){
    this.deselectAllCourses();
    of(this._studentsService.getCoursesByToken(this.nextPage, this.filterTxt));
  }

  private popMsg(msgIn: string) {
    this._snackBar.open(msgIn, 'End now', {
      duration: 2000,
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }
}
