import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material';
import {FormControl, FormGroup} from '@angular/forms';
import {fuseAnimations} from '../../../../../../../@fuse/animations';
import {CResource} from '../../../resource.model';
import {CoursesResourcesService} from '../../../courses-resources.service';

@Component({
  selector: 'edit-course-file-resource',
  templateUrl: './edit-course-file-resource.component.html',
  styleUrls: ['./edit-course-file-resource.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class EditCourseFileResourceComponent implements OnInit {

  resourceForm: FormGroup;
  resource: CResource;
  actionType: string;
  imagUrl: string;

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<EditCourseFileResourceComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private _resourceListService: CoursesResourcesService,
  ) {
    this.actionType = data.actionType;
    this.resource = this.data.data ;
  }

  ngOnInit() {
    this.resourceForm = this.createResourceForm();
    if (this.actionType === 'edit' && this.resource.fileName.length > 5)  {
      this._resourceListService.getPresignedFile(this.resource.fileName, this.resource.courseId)
        .then(res => {
          this.imagUrl = res;
        });
    }
  }
  /**
   * Create compose form
   *
   * @returns {FormGroup}
   */
  createResourceForm(): FormGroup
  {
    return new FormGroup({
      id : new FormControl(this.resource.id),
      courseId: new FormControl(this.resource.courseId),
      description: new FormControl(this.resource.description),
      _version:  new FormControl(this.resource._version),
    });
  }

  closeDialog(resourceForm) {
    this._bottomSheetRef.dismiss({data: resourceForm, actionType: this.actionType});
  }

}
