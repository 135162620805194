import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {ACADEMY_MANAGER_OPTIONS} from './academy.options';
import {
    APIService,
    CreateCourseAssetSubsInput,
    elSubStatus, ModelIDKeyConditionInput,
    ModelSortDirection,
    ModelStringKeyConditionInput, UpdateCourseAssetSubsInput,
} from '../../API.service';
import {getDeepFromObject} from '../auth/helpers';
import AWSConfig from './../../../aws-exports';
import {FuseProgressBarService} from '../../../@fuse/components/progress-bar/progress-bar.service';
import {Logger} from 'aws-amplify';
import {SyncSettings} from '../../../@fuse/services/sync-settings.service';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import * as S3 from 'aws-sdk/clients/s3';
import {AuthService} from '../auth/services/auth.service';

@Injectable()
export class AVideoOverviewService {
    private logger = new Logger('Course Overview Service');

    pageSize: number;
    nextToken: string = null;
    asset: any;
    routeParams: any;
    parentRouteParams: any;

    private onAssetChanged = new BehaviorSubject<any>(null);
    onAssetChanged$ = this.onAssetChanged.asObservable();

    private onAssetSubsChanged = new BehaviorSubject<any>(null);
    onAssetSubsChanged$ = this.onAssetSubsChanged.asObservable();

    private nextPageToken = new BehaviorSubject<any>(this.nextToken);
    nextPageToken$ = this.nextPageToken.asObservable();

    bucket: string;
    bucketTarget: any;
    bucketTranscoded: any;
    /**
     * Constructor
     *
     * @param options
     * @param _api
     * @param authService
     * @param syncSettings
     * @param _fuseProgressBarService
     */
    constructor(
        @Inject(ACADEMY_MANAGER_OPTIONS) protected options = {},
        private _api: APIService,
        private authService: AuthService,
        private syncSettings: SyncSettings,
        private _fuseProgressBarService: FuseProgressBarService,
    )
    {
        this.bucket = AWSConfig['aws_user_files_s3_bucket'];
        this.pageSize = this.getConfigValue('pageSize');
        this.bucketTarget = this.getConfigValue('S3BucketName');
        this.bucketTranscoded = this.getConfigValue('S3BucketTranscoder');
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
     /* Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
      this.routeParams = route.params;
      this.parentRouteParams = route.parent.params;
      return new Promise((resolve, reject) => {
        Promise.all([
          this.getAsset(route.params.assetId, this.parentRouteParams.courseId)
        ]).then((values) => {
            resolve(true);
          },
          reject
        );
      });
    }

    getAsset(assetId: string, courseId: string): Promise<any> {
      return new Promise((resolve, reject) => {
        this._api.GetCourseAssets(assetId)
          .then((result) => {
            this.asset = result;
            return this.getPresignedFile(this.asset.topic);
          }).then(result => {
            this.asset.presignedUrl = result;
            return this.getPresignedPoster(this.asset.topic);
          }).then(resPoster => {
            this.asset.posterFileUrl = resPoster;
            return this.getAssetSubs(this.asset.id);
          }).then(assetSub => {
            if (assetSub.items.length=== 0) {
                return this.AssetSubscribe(this.asset, courseId, elSubStatus.Enrolled )
            } else {
                return assetSub.items[0];
            }
          }).then(subsCompleted => {
            this.asset.assetSub = subsCompleted;
            this.onAssetChanged.next(this.asset);
            this.onAssetSubsChanged.next(subsCompleted);
            resolve(this.asset)
          }).catch((err) => {
            this.catchError(err);
            reject(err);
          });
      });
    }

    syncS3(): any {
      return new S3(
        {
          accessKeyId: this.getConfigValue('accessKeyId'),
          secretAccessKey: this.getConfigValue('secretAccessKey'),
          region: this.getConfigValue('region')
        });
    }

    getPresignedFile(media: any): Promise<any> {
      return new Promise((resolve, reject) => {
        if (media.media !== null && media.media.length > 10) {
            const params = {Bucket: '', Key: '', Expires: 3600};
            const newConn = this.syncS3();
            params.Bucket = this.bucketTranscoded;
            params.Key = `${media.sourceMedia}-web-720p.mp4`;
            newConn.getSignedUrl('getObject', params, (err, urlLink) => {
                if (err) {
                  reject(err);
                } else {
                  resolve(urlLink);
                }
            });
        } else {
          resolve('assets/images/backgrounds/blue-background.jpg');
        }
      });
    }

    getPresignedPoster(media: any): Promise<any> {
      return new Promise((resolve, reject) => {
          if (media.sourceMedia && media.sourceMedia.length > 10) {
              const params = {
                Bucket:  this.bucketTranscoded,
                Key: `${media.sourceMedia}thumbs-720p-00001.png`,
                Expires: 3600
              };
              const newConn = this.syncS3();
              newConn.getSignedUrl( 'getObject', params, (err, urlLink) => {
                if (err) {
                  reject(err);
                } else {
                  resolve(urlLink);
                }
              });
            } else {
              resolve('assets/images/courses/course-default.png');
            }
      });
    }

    getAssetSubs(assetId: string): Promise<any> {
      if (!this.authService.loggedIn) {
        of(Promise.reject('User is not Logged In!'));
      }
      const userInfo = this.authService.getUserId();
      const userId = userInfo.getUsername();
      return new Promise((resolve, reject) => {
        const filterCond: ModelStringKeyConditionInput = {
          eq: assetId
        };
        this._api.SubsCourseAssetByIds(
          userId,
          filterCond,
          ModelSortDirection.DESC,
          null,
          1,
          null
        ).then((result) => {
            resolve(result);
        })
          .catch((err) => reject(err));
      });
    }

    AssetSubscribe(asset: any, courseId: string, status: elSubStatus): Promise<any> {
      if (!this.authService.loggedIn) {
        this.catchError('User is not Logged In!');
        of(Promise.reject('User is not Logged In!'));
      }
      const userInfo = this.authService.getUserId();
      const userId = userInfo.getUsername();
      return new Promise((resolve, reject) => {
        const subsInput: CreateCourseAssetSubsInput = {
          assetId: asset.id,
          courseAssetSubsAssetsSubsId: courseId,
          courseSubsId: asset.courseId,
          assetType: asset.assetType,
          title: asset.title,
          description: asset.description,
          userId: userId,
          status: status,
          updatedAt: new Date().toISOString(),
          createdAt: new Date().toISOString(),
        };
        return this._api.CreateCourseAssetSubs(subsInput )
          .then((result) => {
            resolve(result);
          })
          .catch((err) => reject(err));
      });
    }

    UpdateAssetSubscription(asset: any, statusMeta: string , status: elSubStatus): Promise<any> {
        return new Promise((resolve, reject) => {
            const subsInput: UpdateCourseAssetSubsInput = {
                id: asset.id,
                status: status,
                metaData: statusMeta,
                updatedAt: new Date().toISOString(),
                _version: asset._version
            };
            return this._api.UpdateCourseAssetSubs(subsInput )
                .then((result) => {
                    this.onAssetSubsChanged.next(result);
                    resolve(result);
                })
                .catch((err) => reject(err));
        });
    }

    private catchError(error) {
        console.log(error);
        this.logger.debug('OOPS!', error);
        this._fuseProgressBarService.hide();
      }

    getConfigValue(key: string): any {
        return getDeepFromObject(this.options, key, null);
    }

}
