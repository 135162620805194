import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import {getDeepFromObject} from '../../helpers';
import {FuseProgressBarService} from '../../../../../@fuse/components/progress-bar/progress-bar.service';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {AUTH_LAYOUT, AUTH_OPTIONS} from '../../auth.options';
import {CognitoUser} from 'amazon-cognito-identity-js';

@Component({
    selector     : 'reset-password',
    templateUrl  : './reset-password.component.html',
    styleUrls    : ['./reset-password.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class ResetPasswordComponent implements OnInit, OnDestroy
{
    redirectDelay = 0;
    showMessages: any = {};
    submitted = false;
    user: any = {
        email : null,
        password: null,
        code: null
    };
    snackDuration: number;
    horizontalPosition: MatSnackBarHorizontalPosition = 'left';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    errors: string[] = [];
    messages: string[] = [];
    showInfoCaptcha: false;
    public captchaResponse = '';

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseProgressBarService: FuseProgressBarService,
        protected cd: ChangeDetectorRef,
        protected router: Router,
        public authService: AuthService,
        private _snackBar: MatSnackBar,
        @Inject(AUTH_OPTIONS) protected options = {},
        @Inject(AUTH_LAYOUT) protected layoutOptions = {}
    )
    {
        this.redirectDelay = this.getConfigValue('forms.confirmMail.redirectDelay');
        this.showMessages = this.getConfigValue('forms.confirmMail.showMessages');
        this.horizontalPosition = this.layoutOptions['snackbar']['horizontalPosition'];
        this.verticalPosition = this.layoutOptions['snackbar']['verticalPosition'];
        this.snackDuration = this.layoutOptions['snackbar']['duration'];
        this.showInfoCaptcha = this.layoutOptions['recaptcha']['showInfo'];
        // Configure the layout
        this._fuseConfigService.config = {
            layout: this.layoutOptions['layout']
        };
        const confirmEmail = this.authService.getUser('AUTH_RESET');
        this.user.email = confirmEmail['email'];
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    ngOnInit(): void
    {
        this._fuseProgressBarService.setMode('query');
        if (!this.user.email) {
            this.router.navigateByUrl('auth/login')
                .then(() => {
                    window.location.reload();
                });
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {}

    resetPassword(): void {
        this._fuseProgressBarService.show();
        this.errors = this.messages = [];
        this.submitted = true;
        this.authService.confirmPasswordSubmit(this.user)
            .then((data: any) => {
                setTimeout (() => {
                    this.submitted = false;
                    this._fuseProgressBarService.hide();
                    this.cd.detectChanges();
                    this.authService.removeKey('AUTH_RESET');
                    this.router.navigate(['auth/login']);
                },  this.snackDuration);
            })
            .catch((error: any) => {
                this._snackBar.open(error.message, 'End now', {
                    duration: this.snackDuration,
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                });
                setTimeout (() => {
                    this.submitted = false;
                    this._fuseProgressBarService.hide();
                },  this.snackDuration);
                // @ts-ignore
                if (error.code !== 'CodeMismatchException') {
                    this.authService.removeKey('AUTH_RESET');
                    this.router.navigate(['auth/login']);
                }
            });
    }

    resendCode(): void  {
        this._fuseProgressBarService.show();
        this.authService.resetPassword(this.user)
            .then((user: CognitoUser|any) => {
                this._snackBar.open(`New Code has been Sent! Check your email!! ${user.CodeDeliveryDetails.Destination}` , 'Ok', {
                    duration: this.snackDuration,
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                });
                setTimeout (() => {
                    this.submitted = false;
                    this._fuseProgressBarService.hide();
                    this.cd.detectChanges();
                },  this.snackDuration);
            })
            .catch((error: any) => {
                this._snackBar.open(error.message, 'End now', {
                    duration: this.snackDuration,
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                });
                setTimeout (() => {
                    this.submitted = false;
                    this._fuseProgressBarService.hide();
                    this.authService.setUser('AUTH_RESET', this.user);
                    this.router.navigate(['auth/login']);
                },  this.snackDuration);
            });
    }

    getConfigValue(key: string): any {
        return getDeepFromObject(this.options, key, null);
    }
}

