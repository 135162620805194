import {ModuleWithProviders, NgModule} from '@angular/core';
import { CatalogExplorerComponent } from './catalog-explorer/catalog-explorer.component';
import {AuthGuard} from '../auth/auth.guard';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {FuseSharedModule} from '../../../@fuse/shared.module';
import {FuseSidebarModule, FuseWidgetModule, JwVideoPlayerModule} from '../../../@fuse/components';
import {ContentLoaderModule} from '../../core/loaders/content-loader.module';
import {CATALOG_MANAGER_OPTIONS, CatalogOptions} from './course-catalog.options';
import {CourseCatalogService} from './course-catalog.service';
import { MscCoursesCardComponent } from './components/msc-courses-card/msc-courses-card.component';
import {MatCardModule, MatDividerModule, MatExpansionModule, MatListModule, MatMenuModule, MatTabsModule} from '@angular/material';
import { MscCourseDescriptionComponent } from './components/msc-course-description/msc-course-description.component';
import { CatalogCourseDetailComponent } from './catalog-course-detail/catalog-course-detail.component';
import { MscCoursesOverviewComponent } from './components/msc-courses-overview/msc-courses-overview.component';
import { MscCoursePriceComponent } from './components/msc-course-price/msc-course-price.component';
import { MscCourseHeaderComponent } from './components/msc-course-header/msc-course-header.component';
import { MscCourseDetailDescComponent } from './components/msc-course-detail-desc/msc-course-detail-desc.component';
import {CourseCatalogItemService} from './course-catalog-item.service';
import {CatalogSidebarComponent} from "./components/sidebars/main/catalog-side-bar.component";
import {MscVideoPlayerComponent} from "./components/msc-video-player/msc-video-player.component";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatDialogModule} from "@angular/material/dialog";
import {MatProgressBarModule} from "@angular/material/progress-bar";

const routes = [
  {
    path     : 'courses',
    component: CatalogExplorerComponent,
    resolve  : {
      lms: CourseCatalogService
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'courses/:id',
    component: CatalogCourseDetailComponent,
    resolve: {
      course: CourseCatalogItemService
    },
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [
    CatalogExplorerComponent,
    MscCoursesCardComponent,
    MscCourseDescriptionComponent,
    CatalogCourseDetailComponent,
    MscCoursesOverviewComponent,
    MscCoursePriceComponent,
    MscCourseHeaderComponent,
    MscCourseDetailDescComponent,
    CatalogSidebarComponent,
    MscVideoPlayerComponent,
  ],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        FuseSharedModule,
        FuseSidebarModule,
        ContentLoaderModule,
        MatCardModule,
        MatTabsModule,
        FuseWidgetModule,
        MatDividerModule,
        MatExpansionModule,
        MatListModule,
        MatMenuModule,
        MatToolbarModule,
        MatDialogModule,
        MatProgressBarModule,
        JwVideoPlayerModule,
    ],
  entryComponents: [MscVideoPlayerComponent]
})
export class CourseCatalogModule {
  static forRoot(fmOptions?: CatalogOptions): ModuleWithProviders {
    return {
      ngModule: CourseCatalogModule,
      providers: [
        { provide: CATALOG_MANAGER_OPTIONS, useValue:  fmOptions},
        CourseCatalogService,
        CourseCatalogItemService
      ],
    } as ModuleWithProviders;
  }
}
