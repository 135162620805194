import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnDestroy,
    OnInit, ViewChild,
    ViewEncapsulation
} from '@angular/core';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import {AUTH_LAYOUT, AUTH_OPTIONS} from '../../auth.options';
import {getDeepFromObject} from '../../helpers';
import {Router} from '@angular/router';

import {CognitoUser} from 'amazon-cognito-identity-js';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {TermDialogComponent} from '../terms-dialog/term-dialog.component';
import {FuseProgressBarService} from '../../../../../@fuse/components/progress-bar/progress-bar.service';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {AuthService} from '../../services/auth.service';
import {SessionService} from '../../../../core/services/SessionService';
import {InvisibleReCaptchaComponent} from 'ngx-captcha';


@Component({
    selector     : 'register',
    templateUrl  : './register.component.html',
    styleUrls    : ['./register.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class RegisterComponent implements OnInit, OnDestroy
{
    @ViewChild('captchaElem', { static: false }) captchaElem: InvisibleReCaptchaComponent;
    public captchaIsReady = false;

    redirectDelay = 0;
    redirectEndPoint = '';
    showMessages: any = {};
    submitted = false;
    errors: string[] = [];
    messages: string[] = [];
    user: any = {
        givenName: null,
        familyName: null,
        email : null,
        password: null,
        termsAccepted: false,
        captcha: ''
    };
    snackDuration: number;
    showInfoCaptcha: false;
    // Private
    horizontalPosition: MatSnackBarHorizontalPosition = 'left';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseProgressBarService: FuseProgressBarService,
        protected cd: ChangeDetectorRef,
        protected router: Router,
        public authService: AuthService,
        public sessionService: SessionService,
        public dialog: MatDialog,
        private _snackBar: MatSnackBar,
        @Inject(AUTH_OPTIONS) protected options = {},
        @Inject(AUTH_LAYOUT) protected layoutOptions = {}
    )
    {
        this.redirectDelay = this.getConfigValue('forms.register.redirectDelay');
        this.redirectEndPoint = this.getConfigValue('forms.register.redirect_endpoint');
        this.showMessages = this.getConfigValue('forms.register.showMessages');
        // Configure the layout
        this._fuseConfigService.config = {
            layout: this.layoutOptions['layout']
        };
        this.horizontalPosition = this.layoutOptions['snackbar']['horizontalPosition'];
        this.verticalPosition = this.layoutOptions['snackbar']['verticalPosition'];
        this.snackDuration = this.layoutOptions['snackbar']['duration'];
        this.showInfoCaptcha = this.layoutOptions['recaptcha']['showInfo'];
        // Set the private defaults
        this.authService.removeKey('AUTH_CONFIRM');
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this._fuseProgressBarService.setMode('query');
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {}

    register(): void {
        const currentResponse = this.captchaElem.getCurrentResponse();
        if (!currentResponse) {
          this.captchaElem.resetCaptcha();
          this._snackBar.open('Your Session Expired!, Please try Again!!', 'End now', {
            duration: this.snackDuration,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
          return;
        }
        this._fuseProgressBarService.show();
        this.errors = this.messages = [];
        this.submitted = true;
        this.authService.removeKey('AUTH_CONFIRM');
        this.authService.register(this.user, currentResponse)
          .then((user: CognitoUser|any) => {
              return this.sessionService.saveAnalytics('registration');
          }).then((session: any) => {
              this.submitted = false;
              this._fuseProgressBarService.hide();
              this.cd.detectChanges();
              this.authService.setUser('AUTH_CONFIRM', this.user);
              setTimeout (() => {
                return this.router.navigate([this.redirectEndPoint]);
              },  this.redirectDelay);
          })
          .catch((error: any) => {
              this.captchaElem.resetCaptcha();
              this._snackBar.open(error.message, 'End now', {
                  duration: this.snackDuration,
                  horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,
              });
              this.submitted = false;
              setTimeout (() => {
                  this._fuseProgressBarService.hide();
              },  this.snackDuration);
              // @ts-ignore
              if (error.code === 'UsernameExistsException') {
                  this.router.navigate(['auth/login']);
              }
        });
    }

    openTermsDialog(): void {
        const  dialogConfig = new MatDialogConfig();
        dialogConfig.data = this.user.termsAccepted;
        const dialogRef = this.dialog.open(TermDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            this.user.termsAccepted = !!result;
            this.cd.detectChanges();
        });

    }

    changeTermsState(): void {
        this.user.termsAccepted = !!this.user.termsAccepted;
        this.cd.detectChanges();
    }

    handleReset(): void {
      this.captchaIsReady = false;
      this.captchaElem.execute();
      this.cd.detectChanges();
    }

    handleSuccess(): void {
      this.captchaIsReady = true;
      this.cd.detectChanges();
    }

    handleReady(): void {
      this.captchaElem.execute();
      this.cd.detectChanges();
    }

    getConfigValue(key: string): any {
        return getDeepFromObject(this.options, key, null);
    }
}
