import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {of, ReplaySubject, Subject} from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import {UsersService} from './users.service';
import {Contact} from "../contacts/contact.model";

@Component({
    selector     : 'users',
    templateUrl  : './users.component.html',
    styleUrls    : ['./users.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class UsersComponent implements OnInit, OnDestroy
{
    hasSelectedContacts: boolean;
    searchInput: FormControl;
    nextPage: any;
    userList: any;
    contactForm: FormGroup;
    connect: Contact;

    public contactFilterCtrl: FormControl = new FormControl();
    public filteredServerSideContacts: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    // Private
    private _unsubscribeAll: Subject<any>;
    /**
     * Constructor
     *
     * @param _usersService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {MatDialog} _matDialog
     * @param _formBuilder
     */
    constructor(
        private _usersService: UsersService,
        private _fuseSidebarService: FuseSidebarService,
        private _matDialog: MatDialog,
        private _formBuilder: FormBuilder,
    )
    {
        // Set the defaults
        this.searchInput = new FormControl('');
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.connect = new Contact();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    ngOnInit(): void {

      this.contactForm = this.createConnectsForm();

      this._usersService.onOrgsChanged$
            .subscribe(fiveContactList => {
                this.filteredServerSideContacts.next(fiveContactList);
            });

      this.contactFilterCtrl.valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this._filterContacts();
            });

      this._usersService.onUsersChanged$
        .subscribe(userList => {
          this.userList = userList;
        });



      this._usersService.onNextPageChanged$
          .subscribe(nextToken => {
            this.nextPage = nextToken;
        });

      this._usersService.onSelectedUsersChanged$
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(selectedContacts => {
              this.hasSelectedContacts = selectedContacts.length > 0;
          });

      this.searchInput.valueChanges
          .pipe(
              takeUntil(this._unsubscribeAll),
              debounceTime(300),
              distinctUntilChanged()
          )
          .subscribe(searchText => {
              this._usersService.onSearchTextChanged.next(searchText);
          });
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    onSelectContact($event) {
        console.log($event)
        if ($event.value === undefined) {
            this._usersService.refreshContacts();
        } else {
        }

    }

    refreshUsers() {
        of(this._usersService.getUsers());
    }

    gotoNextPage(nextPage): void {
      this._usersService.goNextPage( nextPage);
    }
    /**
     * Toggle the sidebar
     *
     * @param name
     */
    toggleSidebar(name): void {
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    }

    createConnectsForm(): FormGroup
    {
        return this._formBuilder.group({
            connectId            : [this.connect.id]
        });
    }

    private _filterContacts() {
        const search = this.contactFilterCtrl.value;
        if (!search) {
            return;
        }
        this._usersService.getContacts(search)
            .then((res) => {
                this.filteredServerSideContacts.next(res);
            });
    }
}
