import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {Course} from '../../course.model';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, merge, Observable, ReplaySubject, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {FuseUtils} from '../../../../../../../@fuse/utils';
import {CoursesResourcesService} from '../../../courses-resources.service';
import {fuseAnimations} from '../../../../../../../@fuse/animations';
import {MatBottomSheet} from '@angular/material';
import {EditCourseFileResourceComponent} from '../edit-course-file-resource/edit-course-file-resource.component';
import {EditCourseOtherResourceComponent} from '../edit-course-other-resource/edit-course-other-resource.component';

@Component({
  selector: 'course-resources',
  templateUrl: './course-resources.component.html',
  styleUrls: ['./course-resources.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class CourseResourcesComponent implements OnInit, OnDestroy {
  @Input()
  eventData: Course;

  @Input()
  pageType: string;

  @Output()
  onFormChanges: EventEmitter<any> = new EventEmitter();

  dataSource: ResourceDataSource| null;
  displayedColumns: string[] = ['description', 'contentType' , 'buttons'];
  resourceList: any[] = [];
  nextPage: any;
  filterTxt = '';
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _resourceListService: CoursesResourcesService,
    private _formBuilder: FormBuilder,
    private _bottomSheet: MatBottomSheet
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {

    this._resourceListService.nextPageToken$
      .subscribe(nextToken => {
        this.nextPage = nextToken;
      });

    this._resourceListService.resourcesOnChanged$
      .subscribe(rList => {
        this.resourceList = rList;
      });

    this.dataSource = new ResourceDataSource(this._resourceListService);

  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if ( !this.dataSource )
    {
      return;
    }
    this.filterTxt = filterValue.trim().toLowerCase();
    this._resourceListService.getResources( this.eventData.id , this.filterTxt )
      .then(( res ) => {
        this.dataSource.filter = filterValue.trim().toLowerCase();
      })
      .catch((err) => {
        return ;
      });
  }

  onUploadClicked($event: FileList) {
    const fileListAsArray = Array.from($event);
    fileListAsArray.forEach( file => {
      this._resourceListService.countResourcesByCourseIdAndName(this.eventData.id, file.name )
        .then( resourcesLength => {
            if (resourcesLength === 0) {
              this._resourceListService.uploadFile(file, this.eventData.id )
                .then(urlFile => {
                  return this._resourceListService.create(urlFile, this.eventData.id, file);
                }).then(resResource => {
                  console.log(resResource);
              }).catch(err => {
                  console.log(err);
              });
            }
        });
    });
  }

  editFileResource(resource: any, actionType: string) {
    const dataToSave = this._bottomSheet.open(EditCourseFileResourceComponent, {
      data: { data: resource, actionType: actionType}
    });
    dataToSave.afterDismissed().subscribe((data) => {
      if (data.data) {
        this._resourceListService.updateFileResource(data.data.value, this.eventData.id)
          .then(result => {
            console.log(result);
          });
      }
    });
  }

  editOtherResource(resource: any,  actionType: string) {
    const dataToSave = this._bottomSheet.open(EditCourseOtherResourceComponent, {
      data: { data: resource, actionType: actionType, courseId: this.eventData.id }
    });
    dataToSave.afterDismissed().subscribe((data) => {
      if (data.data) {
        if (data.actionType === 'new') {
          this._resourceListService.createOther(data.data.value, this.eventData.id)
            .then(result => {
              console.log(result);
            });
        } else {
          this._resourceListService.updateOther(data.data.value, this.eventData.id)
            .then(result => {
              console.log(result);
            });
        }
      }
    });
  }

  refreshResources() {
    this._resourceListService.refresh(this.eventData.id);
  }

  gotoNextPage(nextPage): void {
    this._resourceListService.goNextPage(this.eventData.id, this.filterTxt, nextPage);
  }

  previewResource(resource: any) {
    if (resource && resource.contentType === 'File') {
      this._resourceListService.getPresignedFile(resource.fileName, this.eventData.id)
        .then(res => {
          const newTab = window.open(res, '_blank');
          newTab.opener = null;
        });
    }
    if (resource && resource.contentType === 'Link') {
      const newTab = window.open(resource.sourceCode, '_blank');
      newTab.opener = null;
    }
  }

  deleteResource(resource: any) {

  }
}

export class ResourceDataSource extends DataSource<any> {

  private _filterChange = new BehaviorSubject('');
  private _filteredDataChange = new BehaviorSubject('');

  /**
   * Constructor
   *
   * @param _resourceListService
   */
  constructor(
    private _resourceListService: CoursesResourcesService
  ) {
    super();
    this.filteredData = this._resourceListService.resources;
  }

  /**
   * Connect function called by the table to retrieve one stream containing the data to render.
   *
   * @returns {Observable<any[]>}
   */
  connect(): Observable<any[]>
  {
    const displayDataChanges = [
      this._resourceListService.resourcesOnChanged$,
      this._filterChange
    ];
    return merge(...displayDataChanges)
      .pipe(
        map(() => {
            let data = this._resourceListService.resources.slice();

            data = this.filterData(data);

            this.filteredData = [...data];

            // Grab the page's slice of data.
            return data;
          }
        ));
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // Filtered data
  get filteredData(): any {
    return this._filteredDataChange.value;
  }

  set filteredData(value: any) {
    this._filteredDataChange.next(value);
  }

  // Filter
  get filter(): string {
    return this._filterChange.value;
  }

  set filter(filter: string) {
    this._filterChange.next(filter);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Filter data
   *
   * @param data
   * @returns {any}
   */
  filterData(data): any {
    if ( !this.filter )
    {
      return data;
    }

    return FuseUtils.filterArrayByString(data, this.filter);
  }


  /**
   * Disconnect
   */
  disconnect(): void {
  }
}
