import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Subject} from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';
import {Course} from './course.model';
import {CourseService} from '../course.service';
import * as _ from 'lodash';
import {FuseSidebarService} from '../../../../../@fuse/components/sidebar/sidebar.service';
import {elStatus} from '../../../../API.service';
import {ThemePalette} from '@angular/material';
import {CoursesResourcesService} from '../courses-resources.service';
import { Router} from "@angular/router";

@Component({
    selector     : 'admin-edit-course',
    templateUrl  : './course.component.html',
    styleUrls    : ['./course.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class CourseComponent implements OnInit, OnDestroy
{
    course: Course;
    pageType = 'new';
    imgSrc: any = [];
    color: ThemePalette = 'accent';
    assetList: any[];
    //
    private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param _fuseSidebarService
   * @param _courseService
   * @param _resourcesService
   * @param _router
   * @param {MatSnackBar} _matSnackBar
   */
  constructor(
    private _fuseSidebarService: FuseSidebarService,
    private _courseService: CourseService,
    private _resourcesService: CoursesResourcesService,
    private _router: Router,
    private _matSnackBar: MatSnackBar,
  ) {

    // Set the default
    this.course = new Course();
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this.assetList = [];

  }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    ngOnInit(): void
    {
      this._resourcesService.onErrorOrSuccess$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(errOrSuccess => {
          if (errOrSuccess) {
            const msg = errOrSuccess.type === 'error' ||   errOrSuccess.type === 'TypeError'
              ? 'OOPS! Something is wrong .. Please try Again ' +  errOrSuccess.msg
              : 'Attachment has been Saved Successfully';
            this._matSnackBar.open(msg, 'OK', {
              verticalPosition: 'top',
              duration        : 2000
            });
          }
        });

        this._courseService.onErrorOrSuccess$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(errOrSuccess => {
                if (errOrSuccess) {
                    const msg = errOrSuccess.type === 'error' || errOrSuccess.type === 'TypeError'
                        ? 'OOPS! Something is wrong .. Please try Again ' +  errOrSuccess.msg
                        : 'Course has been Saved Successfully';
                    this._matSnackBar.open(msg, 'OK', {
                        verticalPosition: 'top',
                        duration        : 2000
                    });
                }
            });

      this._courseService.onCourseChanged$
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(course => {
              if ( course && course.id) {
                  this.course = new Course(course);
                  this.pageType = 'edit';
              } else {
                  this.course = new Course();
                  this.pageType = 'new';
              }
          });


        this._courseService.onAssetsChanged$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(assetList => {
                this.assetList = assetList && assetList.length > 0 ? assetList : 0;
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    addNewClass(courseId: string) {
        if (courseId === this.course.id) {
            this._router.navigate(['admin/content/' + courseId + '/new']);
        }
    }

    changeStatus($event) {
        const live = $event === 'inactive' ? elStatus.active : elStatus.inactive;
        if (this.course.assets && this.course.courseType==='Internal' && this.course.assets.length === 0) {
            this.eventMesssage('Please Add Topics to this course before go live!!! ', 'error');
        }
        this._courseService.toggleLive(this.course, live)
            .then(result => {
                this.eventMesssage(`Course will be !${$event ? 'Live' : 'Inactive'}`, 'success');
            });

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    generalCourseInfo($event) {
      if ($event.pageType === 'new') {
        this._courseService.addCourse($event)
          .then((data) => {
            this.eventMesssage('Course added Successfully!!!', 'success');
            // this._location.go('admin/courses/' + data.id );
          });
      } else {
        this._courseService.saveCourse($event)
          .then((data) => {
            this.eventMesssage('Course Saved Successfully!!!', 'success');
            // this._location.go('admin/courses/' + data.id );
          });
      }
    }

    onVideoMetaData($event)  {
        this.course.timeAprox = $event;
        this._courseService.saveVideoMeta(this.course )
            .then((res) => {
                this.eventMesssage('Course Successfully updated', 'success')
            }).catch(error => {
                this.eventMesssage('Try Again Please or Call for Assistance!!', 'error')
        })
    }

    onFormMediaChanges($event: Course) {
      this._courseService.saveCoverImages($event)
        .then((res) => {
          this.eventMesssage('Course Cover Images Successfully Saved!', 'success');
        }).catch(error => {
          this.eventMesssage('Item size to update has exceeded the maximum allowed size!!!', 'error' );
        });
      }

    onFormResourcesChanges($event: Course) {
      this._courseService.saveCoverImages($event)
        .then((res) => {
          this.eventMesssage('Course Cover Images Successfully Saved!', 'success');
        }).catch(error => {
            this.eventMesssage(`!!Error !!!${error.message}`, 'error' );
        });
    }

    onFormDetailsChanges($event: any) {
        this._courseService.saveCourseDetails($event)
            .then((res) => {
                this.eventMesssage('Course Details Successfully Saved!', 'success');
            }).catch(error => {
                this.eventMesssage(`!!Error !!!${error.message}`, 'error' );
            });
    }

    onSelect($event: any) {
        this.imgSrc = [];
        switch (typeof($event)) {
            case 'string':
                this.imgSrc = [$event];
                break;
            case 'object':
                this.imgSrc = $event;
                break;
            default:
        }
    }

    onReset() {
        this.imgSrc = [];
    }

    toggleSidebar(): void {
        this._fuseSidebarService.getSidebar('course-category-sidebar').toggleOpen();
    }

    toggleSidebarResources(): void {
      this._resourcesService.refresh(this.course.id);
      this._fuseSidebarService.getSidebar('course-resource-sidebar').toggleOpen();
    }

    private eventMesssage( msg: string, typeMsg: string ) {
      const customMsg = typeMsg === 'error' ? 'OOPS:' + msg : 'Success!!' + msg;
      this._matSnackBar.open(customMsg, 'OK', {
        verticalPosition: 'top',
        duration        : 2000
      });
    }


}
