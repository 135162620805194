import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-user-item-attr',
  templateUrl: './user-item-attr.component.html',
  styleUrls: ['./user-item-attr.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 100 })),
      transition('* => void', [animate(300, style({ opacity: 0 }))])
    ])
  ]
})
export class UserItemAttrComponent implements OnInit , OnChanges {

  @Input()
  userSelected: any;

  userAttr: any[] = [];
  attrBlackList: any[] = [
    'sub',
    'given_name',
    'family_name',
    'phone_number_verified' ,
    'custom:theme' ,
    'custom:phone',
    'custom:avatarType',
    'custom:language'
  ];
  // 'picture'
  constructor() {}

  ngOnInit() {
    this.setAttrs(this.userSelected);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.userSelected = changes.userSelected.currentValue;
    this.setAttrs(this.userSelected);
  }

  setAttrs(listAttr: any[]) {
    this.userAttr = [];
    listAttr.forEach(item => {
      const index = this.attrBlackList.indexOf(item.Name);
      if (index >= 0) {
        item['index'] = index;
        this.userAttr.push(item);
      }
    });
    this.userAttr.sort((a, b) => a.index - b.index);
  }

}
