import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CourseAsset } from 'app/main/admin/course-content/media.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { quizContent } from '../../quiz.model';

@Component({
  selector: 'quiz-result',
  templateUrl: './quiz-result.component.html',
  styleUrls: ['./quiz-result.component.scss']
})
export class QuizResultComponent implements OnInit {

  @Input()
  courseAsset: CourseAsset;

  @Input()
  pageType: string;

  @Output() formChanges: EventEmitter<any> = new EventEmitter<any>();

  resultForm: FormGroup;
  quizContent: quizContent;

  constructor(
    private _formBuilder: FormBuilder
  ) { }
  
  ngOnInit() {
    this.quizContent = this.courseAsset.quiz;
    this.resultForm = this.createResultForm();
  }

  createResultForm(): FormGroup {
    return this._formBuilder.group({
      id: [this.quizContent.id],
      courseId: [this.quizContent.courseId],
      labelWhenPassed: [this.quizContent.labelWhenPassed],
      labelWhenFailed: [this.quizContent.labelWhenFailed],
      questionFeedback: [this.quizContent.questionFeedback]
    });
  }

  saveData(value:any){
    this.formChanges.emit(value);
  }

}
