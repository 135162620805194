import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import {JwVideoPlayerComponent} from './jw-video-player.component';

@NgModule({
  declarations: [
    JwVideoPlayerComponent
  ],
  entryComponents: [
    JwVideoPlayerComponent
  ],
  exports: [
    JwVideoPlayerComponent
  ]
})
export class JwVideoPlayerModule {}
