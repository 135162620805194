import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { ProfileService } from '../../profile.service';

export interface PeriodicElement {
  title: string;
  level: string;
  id: string;
  status: string;
}

@Component({
  selector: 'profile-progress',
  templateUrl: './profile-progress.component.html',
  styleUrls: ['./profile-progress.component.scss']
})
export class ProfileProgressComponent implements OnChanges{
  
  @Input('userId') userId: string;
  
  elementData: PeriodicElement[] = [];
  displayedColumns: string[] = ['title', 'level', 'id', 'status'];
  dataSource: any;
  

  constructor(
    private _profileService: ProfileService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.userId.currentValue){
      this._profileService.getCourses(this.userId)
        .then((res:any) => {
          this.elementData = res.items;
          this.dataSource = this.elementData;
        })
    }
  }
}
