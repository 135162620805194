import {Inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {
    APIService, ContactType,
    CreateContactInput,
    UpdateContactInput
} from '../../../API.service';
import {AuthService, NewUser} from '../../auth/services/auth.service';
import * as AWS from "aws-sdk";
import {getDeepFromObject} from "../../auth/helpers";
import {User} from "../users/user.model";
import * as _ from "lodash";
import {CONTACT_MANAGER_OPTIONS} from "./contact.options";
import {API} from "aws-amplify";

@Injectable()
export class ContactService implements Resolve<any>
{

    routeParams: any;

    private onConnectsChanged = new BehaviorSubject<any>(null);
    onConnectsChanged$ = this.onConnectsChanged.asObservable();

    private onContactChanged = new BehaviorSubject<any>(null);
    onContactChanged$ = this.onContactChanged.asObservable();

    onSearchTextChanged: Subject<any>;
    onFilterChanged: Subject<any>;
    nextPageToken: BehaviorSubject<any>;

    contacts: any[];
    user: any;
    pageSize: number;
    nextToken: string;

    /**
     * Constructor
     *
     * @param options
     * @param {HttpClient} _httpClient
     * @param api
     * @param _authService
     */
    constructor(
        @Inject(CONTACT_MANAGER_OPTIONS) protected options = {},
        private _httpClient: HttpClient,
        private api: APIService,
        private _authService: AuthService,

    )
    {
        // Set the defaults
        this.onSearchTextChanged = new Subject();
        this.onFilterChanged = new Subject();
        this.nextToken = null;
        this.nextPageToken = new BehaviorSubject(this.nextToken);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.routeParams = route.params;
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getContact(null),
            ]).then(
                ([contact]) => {

                    resolve();

                },
                reject
            );
        });
    }

    getContact(id: string): Promise<any> {
        const contactIdVal = id !== null ? id : this.routeParams.id;
        return new Promise((resolve, reject) => {
            this.api.GetContact(contactIdVal)
                .then((contact) => {
                    this.onContactChanged.next(contact);
                    resolve(contact);
                })
                .catch((err) => reject(err));
        });
    }

    save(data): Promise<any>  {
        return new Promise((resolve, reject) => {
            const userInfo = this._authService.getUserId();
            const userId = userInfo.getUsername();
            const payload: UpdateContactInput = {
                id: data.id,
                userID: userId,
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                phone: data.phone,
                avatar: data.avatar,
                avatarType: data.avatarType,
                birthday: data.birthday,
                status: data.status,
                _version: data._version
            };
            console.log(payload);
            this.api.UpdateContact(payload)
            .then((contact) => {
                this.onContactChanged.next(contact);
                resolve(contact);
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    }

    savePB(data): Promise<any>  {
        return new Promise((resolve, reject) => {
            const userInfo = this._authService.getUserId();
            const userId = userInfo.getUsername();
            const payload: UpdateContactInput = {
                id: data.id,
                userID: userId,
                notes: data.notes,
                _version: data._version
            };
            this.api.UpdateContact(payload)
            .then((contact) => {
                this.onContactChanged.next(contact);
                resolve(contact);
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    }

    add(data) {
        return new Promise((resolve, reject) => {
            const userInfo = this._authService.getUserId();
            const userId = userInfo.getUsername();
            const payload: CreateContactInput = {
                userID: userId,
                contactName: data.firstName + ' ' + data.lastName,
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                phone: data.phone,
                avatar: data.avatar,
                contactType: ContactType.Contact,
                avatarType: data.avatarType,
                birthday: data.birthday,
                starred: false,
                frequent: false,
                status: data.status
            };
            console.log(payload);
            this.api.CreateContact(payload)
            .then((contact) => {
                this.onContactChanged.next(contact);
                resolve(contact);
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    }

    syncCognito(): any {
        return new AWS.CognitoIdentityServiceProvider(
            {
                apiVersion: '2016-04-18',
                region: this.getConfigValue('region'),
                accessKeyId: this.getConfigValue('accessKeyId'),
                secretAccessKey: this.getConfigValue('secretAccessKey')
            }
        );
    }

    createUser(user: any): Promise<any> {
        const orgMeta= {
            id: user.orgMeta.id,
            contactName: user.orgMeta.contactName
        }
        return new Promise((resolve, reject) => {
            const body = {
                body: {
                    email: user.email,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    phone: user.phone,
                    orgId: user.orgId,
                    orgMeta: orgMeta,
                }
            };
            let username = null;
            API.post('apiAdminQueries', '/createUser', body)
                .then(userCreation => {
                    const pbProfile = JSON.stringify(userCreation.body.User);
                    username = userCreation.body.User.Username;
                    const payload: UpdateContactInput = {
                        id: user.id,
                        userRelatedId: username,
                        pbProfileOptions: pbProfile,
                        _version: user._version
                    };
                    return this.api.UpdateContact(payload);
                }).then(userUpdate => {
                    return this.addUserToGroup('INSTRUCTOR',username);
                }).then(userGroup=> {
                    resolve(userGroup);
                }).catch(error => {
                    reject(error);
                });
        });
    }

    updateUserAttributes(user: any): Promise<any> {
        let orgMeta=null;
        if (user.isOrgRelated) {
            orgMeta= {
                id: user.orgRelated.id,
                contactName: user.orgRelated.contactName
            }
        } else {
           orgMeta= {
                id: user.orgMeta.id,
                contactName: user.orgMeta.contactName
            }
        }
        return new Promise((resolve, reject) => {
            const body = {
                body: {
                    id: user.id,
                    orgId: user.orgId,
                    orgMeta: orgMeta,
                }
            };
            API.post('apiAdminQueries', '/updateUserAttributes', body)
                .then(result => {
                    const pbProfile = JSON.stringify(user.user);
                    const payload: UpdateContactInput = {
                        id: user.contact.id,
                        userRelatedId: user.id,
                        pbProfileOptions: pbProfile,
                        _version: user.contact._version
                    };
                    // console.log(payload);
                    return this.api.UpdateContact(payload);
                }).then(result => {
                    resolve(result);
                }).catch(error => {
                    reject(error);
            });
        });
    }

    addUserToGroup( groupName: string, userName: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const conn = this.syncCognito();
            const params = {
                UserPoolId: this.getConfigValue('UserPoolId'),
                GroupName: groupName,
                Username: userName
            };
            conn.adminAddUserToGroup(params, (err, data) => {
                if (err) {
                    reject(err);
                }
                resolve(data);
            });
        });
    }

    userExistsByEmail(byEmail: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const conn = this.syncCognito();
            const params = {
                UserPoolId: this.getConfigValue('UserPoolId'),
                Limit: 2
            };
            params['Filter'] = `email ^= "${byEmail}"`;
            conn.listUsers(params, (err, data) => {
                if (err) {
                    reject(err);
                }
                const users = this.mapListOfUsers(data.Users);
                resolve(users);
            });
        });
    }

    getListGroupsForUser(username?: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const conn = this.syncCognito();
            const params = {
                UserPoolId: this.getConfigValue('UserPoolId'),
                Username: username,
                Limit: 10
            };
            conn.adminListGroupsForUser(params, (err, data) => {
                if (err) {
                    resolve([]);
                }
                resolve(data);
            });
        });
    }


    mapAttributeFromUser(attrName: string, attrs: any[]) {
        const found = _.find(attrs, ['Name', attrName]);
        if (found) {
            return found['Value'];
        }
        return null;
    }

    getPicture(listAttr: any[]): any {
        let identpic = null;
        listAttr.forEach(item => {
            if (item.Name === 'picture') {
                identpic = item.Value;
            }
        });

        return identpic;
    }

    getIdentities(listAttr: any[]): any {
        let identifities = null;
        listAttr.forEach(item => {
            if (item.Name === 'identities') {
                identifities = JSON.parse(item.Value);
            }
        });
        return identifities;
    }
    mapUser(item: any): User {
        let imageUrl = '';
        let external = false;
        const email = this.mapAttributeFromUser('email', item.Attributes);
        // tslint:disable-next-line:variable-name
        const email_verified = this.mapAttributeFromUser('email_verified', item.Attributes);
        const name = this.mapAttributeFromUser('name', item.Attributes);
        const sub = this.mapAttributeFromUser('sub', item.Attributes);
        // tslint:disable-next-line:variable-name
        const phone_number_verified = this.mapAttributeFromUser('phone_number_verified', item.Attributes);
        // tslint:disable-next-line:variable-name
        const avatar_type = this.mapAttributeFromUser('custom:avatarType', item.Attributes);
        const orgId = this.mapAttributeFromUser('custom:organizationId', item.Attributes);

        const ident = this.getIdentities(item.Attributes);
        const identities = item.Attributes && ident ? ident[0] : false;
        const picture = this.getPicture(item.Attributes);
        if (identities && picture) {
            imageUrl = item.Attributes && picture ? picture : false;
            external = true;
        } else if (picture) {
            imageUrl = picture;
        }
        // tslint:disable-next-line:variable-name
        const account_status = item.Enabled;
        const createdAt = item.UserCreateDate;
        const updatedAt = item.UserLastModifiedDate;
        const user = {
            id: item.Username,
            email_verified: email_verified,
            phone_verified: phone_number_verified,
            account_status: account_status,
            email: email,
            avatar_type: avatar_type,
            user_name: item.Username,
            name: name,
            updatedAt: updatedAt,
            createdAt: createdAt,
            user_status:  item.UserStatus,
            attributes : item.Attributes,
            external: external,
            picture: imageUrl,
            sub: sub,
            orgId: orgId
        };
        return new User(user);
    }

    mapListOfUsers(contents: any[]): User[] {
        const userListDS = [];
        contents.map((item) => {
            const user  = this.mapUser(item);
            userListDS.push(new User( user));
        });
        return userListDS;
    }

    // Functions
    getConfigValue(key: string): any {
        return getDeepFromObject(this.options, key, null);
    }

}
