import {Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, fromEvent, merge, Observable, Subject } from 'rxjs';
import {debounceTime, distinctUntilChanged, map, takeUntil} from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { FuseUtils } from '@fuse/utils';
import {CourseListService} from '../course-list.service';
import { MatSort } from '@angular/material';
import {CourseService} from "../course.service";
import {elStatus} from "../../../../API.service";
import {Course} from "../course/course.model";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
    selector     : 'admin-courses',
    styleUrls    : ['./course-list.component.scss'],
    templateUrl  : './course-list.component.html',
    animations   : fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class CourseListComponent implements OnInit, OnDestroy {

    dataSource: CoursesDataSource | null;
    displayedColumns = [ 'coverImage', 'title' , 'category', 'topics', 'timeAprox', 'courseLevel' , 'lastUpdated', 'buttons'];

    @ViewChild(MatSort, {static: true})
    sort: MatSort;

    @ViewChild('filter', {static: true})
    filter: ElementRef;
    nextPage: any;
    filterTxt: string;
    coursesList: any;


    // Private
    private _onDestroy: Subject<any>;

    constructor(
        private _courseListService: CourseListService,
        private _courseService: CourseService,
        private _matSnackBar: MatSnackBar
    )
    {
      this._onDestroy = new Subject();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    ngOnInit(): void {

      this._courseListService.nextPageToken$
          .pipe(takeUntil(this._onDestroy))
        .subscribe(nextToken => {
            this.nextPage = nextToken;
        });

      this._courseListService.onCoursesChanged$
          .pipe(takeUntil(this._onDestroy))
        .subscribe(courseList => {
          this.coursesList = courseList;
        });

      this.dataSource = new CoursesDataSource(this._courseListService, this.sort);

      fromEvent(this.filter.nativeElement, 'keyup')
          .pipe(
              debounceTime(550),
              distinctUntilChanged()
          )
          .subscribe(() => {
              if ( !this.dataSource )
              {
                  return;
              }
              this.filterTxt = this.filter.nativeElement.value;
              this._courseListService.getCourses( this.filterTxt )
                  .then(( res ) => {
                      this.dataSource.filter = this.filter.nativeElement.value;
                  })
                  .catch((err) => {
                      return ;
                  });
          });
    }

    refreshCourses(): void {
        this._courseListService.refresh();
    }

    toggleLive(courseIn: Course) {
        const live = courseIn.status === 'inactive' ? elStatus.active : elStatus.inactive;
        if (courseIn.assets && courseIn.courseType==='Internal' && courseIn.assets['items'].length === 0) {
            this.refreshCourses();
            this.eventMesssage('Please Add Topics to this course before go live!!! ', 'error');
        }
        this._courseService.toggleLive(courseIn, live)
            .then(result => {
                this.refreshCourses();
                this.eventMesssage(`Course will be !${live ==='active' ? 'Live' : 'Inactive'}`, 'success');
            });
    }

    toggleTredingTo(courseIn: Course, trending: any) {
        if (courseIn.assets && courseIn.courseType==='Internal' && courseIn.assets['items'].length  === 0) {
            this.refreshCourses();
            this.eventMesssage('Please Add Topics to this course before you trend this manually!!! ', 'error');
        }
        this._courseService.toggleCourseTrending(courseIn, trending)
            .then(result => {
                this.refreshCourses();
                this.eventMesssage(`Course will be !${trending}`, 'success');
            });

    }

    gotoNextPage(nextPage): void {
        this._courseListService.goNextPage(this.filterTxt, nextPage);
    }


    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    private eventMesssage( msg: string, typeMsg: string ) {
        const customMsg = typeMsg === 'error' ? 'OOPS:' + msg : 'Success!!' + msg;
        this._matSnackBar.open(customMsg, 'OK', {
            verticalPosition: 'top',
            duration        : 2000
        });
    }

}

export class CoursesDataSource extends DataSource<any> {

    private _filterChange = new BehaviorSubject('');
    private _filteredDataChange = new BehaviorSubject('');

    /**
     * Constructor
     *
     * @param _courseListService
     * @param {MatSort} _matSort
     */
    constructor(
        private _courseListService: CourseListService,
        private _matSort: MatSort
    ) {
        super();
        this.filteredData = this._courseListService.courses;
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     *
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]>
    {
        const displayDataChanges = [
          this._courseListService.onCoursesChanged$,
          this._filterChange,
          this._matSort.sortChange
        ];
        return merge(...displayDataChanges)
            .pipe(
                map(() => {
                        let data = this._courseListService.courses.length > 0 ? this._courseListService.courses.slice() : [];

                        data = this.filterData(data);

                        this.filteredData = [...data];

                        data = this.sortData(data);

                        // Grab the page's slice of data.
                        return data;
                    }
                ));
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Filtered data
    get filteredData(): any {
        return this._filteredDataChange.value;
    }

    set filteredData(value: any) {
        this._filteredDataChange.next(value);
    }

    // Filter
    get filter(): string {
        return this._filterChange.value;
    }

    set filter(filter: string) {
        this._filterChange.next(filter);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Filter data
     *
     * @param data
     * @returns {any}
     */
    filterData(data): any {
        if ( !this.filter )
        {
            return data;
        }

        return FuseUtils.filterArrayByString(data, this.filter);
    }

    /**
     * Sort data
     *
     * @param data
     * @returns {any[]}
     */
    sortData(data): any[] {
        if ( !this._matSort.active || this._matSort.direction === '' )
        {
            return data;
        }

        return data.sort((a, b) => {
            let propertyA: number | string = '';
            let propertyB: number | string = '';

            switch ( this._matSort.active )
            {
                // case 'id':
                //     [propertyA, propertyB] = [a.id, b.id];
                //     break;
                case 'title':
                    [propertyA, propertyB] = [a.title, b.title];
                    break;
                case 'courseType':
                    [propertyA, propertyB] = [a.courseType, b.courseType];
                    break;
                case 'category':
                    [propertyA, propertyB] = [a.category[0], b.category[0]];
                    break;
                case 'timeAprox':
                    [propertyA, propertyB] = [a.timeAprox, b.timeAprox];
                    break;
                case 'status':
                    [propertyA, propertyB] = [a.status, b.status];
                    break;
            }

            const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
            const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

            return (valueA < valueB ? -1 : 1) * (data.direction === 'asc' ? 1 : -1);
        });
    }

    /**
     * Disconnect
     */
    disconnect(): void {}
}
