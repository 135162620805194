import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit, ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {fuseAnimations} from "../../../../../../@fuse/animations";
import {DataSource} from "@angular/cdk/collections";
import {Observable, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {MatPaginator} from "@angular/material/paginator";
import {StudentsService} from "../../students.service";
import {LicenseStudentService} from "../../license-student.service";
import * as _ from "lodash";
import {getDeepFromObject} from "../../../../auth/helpers";
import {FuseConfirmDialogComponent} from "../../../../../../@fuse/components/confirm-dialog/confirm-dialog.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {StudentImportPopupComponent} from "../student-import-popup/student-import-popup.component";

export interface CSVRecord {
  firstName: any;
  lastName: any;
  email: any;
  phone: any;
}

@Component({
  selector: 'contact-user-list',
  templateUrl: './contact-user-list.component.html',
  styleUrls: ['./contact-user-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class ContactUserListComponent implements OnInit ,  OnDestroy {

  @Input()
  contactId: string;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  selectedLicense:any;
  displayedColumns = ['firstName', 'lastName',  'email', 'phone'];
  dataSource : ImportDataSource | null;
  private _unsubscribeAll: Subject<any>;
  importContacts: any[];
  allowOnly: number;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  allowMultipleGroupsAssignment: boolean =  true;

  constructor(
      protected cd: ChangeDetectorRef,
      private _studentService: StudentsService,
      private _licenseStudentService: LicenseStudentService,
      public _matDialog: MatDialog,
      private _bottomSheet: MatBottomSheet,
  ) {
    this._unsubscribeAll = new Subject();
    this.allowOnly = 0;
  }

  ngOnInit() {
    this.dataSource = new ImportDataSource(this._studentService);

    this._studentService.onImportsChanged$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(contacts => {
          this.importContacts = contacts;
        });

    this._studentService.onOpenSideBar$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(license => {
          if(license) {
            console.log(license)
            this.allowOnly = license.noLicenses;
            this.selectedLicense = license;
          }
        });

    this._studentService.onImportsIteratorChanged$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(item => {
          const next = item!==null && this.importContacts.length > 0 && item <= this.importContacts.length - 1;
          if(next) {
            const selectedStudent = {
                contact: this.importContacts[item],
                orgMeta : {
                    id: this.selectedLicense.contactId,
                    contactName: this.selectedLicense.org.contactName
                },
                orgId: this.selectedLicense.contactId,
                groupId: this.selectedLicense.groupId,
                license: this.selectedLicense,
                next: item
            };
            const dataToSave = this._bottomSheet.open(StudentImportPopupComponent, {
              disableClose: true,
              data: selectedStudent
            });
            dataToSave.afterDismissed().subscribe((nextItem) => {
                this._studentService.refreshLicense(this.selectedLicense.lic);
                //this.importContacts.splice(item, 1);
                this.cd.detectChanges()
                this._studentService.refreshStudents(this.selectedLicense.groupId);
                this._studentService.setNextIteratorImport(nextItem+1);
            });
          }
        });
  }

  onUploadClicked($event: FileList) {
    const fileListAsArray = Array.from($event);
    fileListAsArray.forEach( file => {
      if (this.isValidCSVFile(file)) {
        let reader = new FileReader();
        reader.readAsText(file);
        reader.onload = () => {
          let csvData = reader.result;
          let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
          console.log(csvRecordsArray.length);
          let headersRow = this.getHeaderArray(csvRecordsArray);

          const all = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
          this._studentService.addNewImportContact(all);
          this.cd.detectChanges();
        };

        reader.onerror = function () {
          console.log('error is occured while reading file!');
        };
      }
    });
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    let csvArr = [];
    for (let i = 1; i < csvRecordsArray.length; i++) {
      let currentRecord = (<string>csvRecordsArray[i]).split(',');
      if (currentRecord.length == headerLength) {
        let csvRecord: CSVRecord = {
          firstName: currentRecord[0].trim(),
          lastName: currentRecord[1].trim(),
          email: currentRecord[2].trim(),
          phone: currentRecord[3].trim()
        }
        csvArr.push(csvRecord);
      }
    }
    return csvArr;
  }

  getHeaderArray(csvRecordsArr: any) {
    let headers = (<string>csvRecordsArr[0]).split(',');
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  isValidCSVFile(file: any) {
    return file.name.endsWith(".csv");
  }

  startImport() {
    this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });
    this.confirmDialogRef.componentInstance.confirmMessage = `Allowed to Import/Create ${this.allowOnly} Users Only, Proceed?`;
    this.confirmDialogRef.afterClosed()
        .subscribe(result => {
          if ( result ) {
              this._studentService.setNextIteratorImport(0);
          }
          this.confirmDialogRef = null;
        });

  }

  ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}


export class ImportDataSource extends DataSource<any> {
  constructor(
      private _studentService: StudentsService,
  )
  {
    super();
  }

  /**
   * Connect function called by the table to retrieve one stream containing the data to render.
   * @returns {Observable<any[]>}
   */
  connect(): Observable<any[]>
  {
    return this._studentService.onImportsChanged$;
  }

  /**
   * Disconnect
   */
  disconnect(): void
  {
  }
}
