import {ContactType} from "../../../API.service";

export class Company
{
    id: string;
    userID: string;
    syncID: string;
    contactName: string;
    contactType: any;
    contactSince: any;
    email: string;
    phone: string;
    avatar: string;
    avatarType: string;
    starred: boolean;
    frequent: boolean;
    status: any;
    base64Img: string;
    notes: string;
    licence: any[];
    _version: any;
    /**
     * Constructor
     *
     * @param org
     */
    constructor(org?)
    {
        {
            org = org || {};
            this.id = org.id || null;
            this.contactName = org.contactName || '';
            this.email = org.email || '';
            this.phone = org.phone || '';
            this.contactType = org.contactType || ContactType.Organization;
            this.avatar = org.avatar || 'assets/images/contacts/company-default.png';
            this.avatarType = org.avatarType || 'ni';
            this.starred = org.starred || false;
            this.frequent = org.frequent || false;
            this.status = org.status || 'active';
            this.syncID = org.syncID || null;
            this.userID = org.userID || null;
            this.contactSince = org.contactSince || new Date();
            this.notes = org.notes || '';
            this.licence = org.licence || [];
            this._version = org._version || null;
        }
    }
}

export class MemberShip
{
    id: string;
    contactId: string;
    noLicenses: any;
    noClaimed: any;
    prefix: any;
    status: any;
    updatedAt: string;
    createdAt: string;
    _version: any;
    /**
     * Constructor
     *
     * @param org
     */
    constructor(org?)
    {
        {
            org = org || {};
            this.id = org.id || null;
            this.contactId = org.contactId || null;
            this.noLicenses = org.noLicenses || 0;
            this.noClaimed = org.noClaimed || 0;
            this.prefix = org.prefix || null;
            this.status = org.status || 'system';
            this.updatedAt = org.updatedAt || new Date().toISOString();
            this.createdAt = org.createdAt || new Date().toISOString();
            this._version = org._version || null;
        }
    }
}
