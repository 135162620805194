import {ModuleWithProviders, NgModule} from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FuseSharedModule } from '@fuse/shared.module';

import { AcademyCoursesComponent } from 'app/main/academy/courses/courses.component';
import { AcademyCourseComponent } from 'app/main/academy/course/course.component';
import { AcademyCoursesService } from 'app/main/academy/courses.service';
import { AcademyCourseService } from 'app/main/academy/course.service';
import {FuseSidebarModule} from '@fuse/components';
import {ACADEMY_MANAGER_OPTIONS, AcademyOptions} from './academy.options';
import {AuthGuard} from '../auth/auth.guard';
import {ContentLoaderModule} from '../../core/loaders/content-loader.module';
import { VideoPreviewComponent } from './components/video-preview/video-preview.component';
import {AVideoOverviewService} from './avideo-preview.service';
import { AcademyListSidebarComponent } from './components/academy-list-sidebar/academy-list-sidebar.component';
import { ACourseTileComponent } from './components/a-course-tile/a-course-tile.component';
import {MatVideoModule} from "mat-video";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatToolbarModule} from "@angular/material/toolbar";
import {SatPopoverModule} from "@ncstate/sat-popover";
import { QuizViewerComponent } from './components/quiz-viewer/quiz-viewer.component';
import { QuizIntroComponent } from './components/quiz-viewer/components/quiz-intro/quiz-intro.component';
import {MatCardModule} from "@angular/material/card";
import { QuizPlayerComponent } from './components/quiz-viewer/components/quiz-player/quiz-player.component';
import { QuizQuestionItemComponent } from './components/quiz-viewer/components/quiz-question-item/quiz-question-item.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatRadioModule} from "@angular/material/radio";
import { QuizSummaryComponent } from './components/quiz-viewer/components/quiz-summary/quiz-summary.component';
import {MatListModule} from "@angular/material/list";
import {QuizViewerService} from "./quiz-viewer.service";
import {MatStepperModule} from "@angular/material/stepper";
const routes = [
    {
        path     : 'courses',
        component: AcademyCoursesComponent,
        resolve  : {
            academy: AcademyCoursesService
        },
        canActivate: [AuthGuard]
    },
    {
      path     : 'courses/:courseId',
      component: AcademyCourseComponent,
      resolve  : {
        academy: AcademyCourseService
      },
      children :   [
        {
          path: 'cl/:assetId',
          component: VideoPreviewComponent,
          resolve  : {
            asset: AVideoOverviewService
          }
        },
        {
          path: 'qz/:assetId',
          component: QuizViewerComponent,
          resolve  : {
              asset: QuizViewerService
          }
        }
      ],
      canActivate: [AuthGuard]
    }
];

@NgModule({
    declarations: [
        AcademyCoursesComponent,
        AcademyCourseComponent,
        VideoPreviewComponent,
        AcademyListSidebarComponent,
        ACourseTileComponent,
        QuizViewerComponent,
        QuizIntroComponent,
        QuizPlayerComponent,
        QuizQuestionItemComponent,
        QuizSummaryComponent

    ],
    imports: [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,

        FuseSharedModule,
        FuseSidebarModule,
        ContentLoaderModule,
        MatVideoModule,
        MatProgressBarModule,
        MatToolbarModule,
        SatPopoverModule,
        MatCardModule,
        MatCheckboxModule,
        MatRadioModule,
        MatListModule,
        MatStepperModule
    ]
})
export class AcademyModule
{
    static forRoot(fmOptions?: AcademyOptions): ModuleWithProviders {
        return {
            ngModule: AcademyModule,
            providers: [
                { provide: ACADEMY_MANAGER_OPTIONS, useValue:  fmOptions},
                AcademyCoursesService,
                AcademyCourseService,
                AVideoOverviewService,
                QuizViewerService
            ],
        } as ModuleWithProviders;
    }
}
