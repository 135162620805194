import {Component, Inject, OnInit} from '@angular/core';
import {ProfileService} from "../../profile.service";
import {AUTH_LAYOUT, AUTH_OPTIONS} from "../../../auth/auth.options";
import {getDeepFromObject} from "../../../auth/helpers";
import {MatBottomSheetRef} from "@angular/material/bottom-sheet";

@Component({
  selector: 'profile-chg-password',
  templateUrl: './profile-chg-password.component.html',
  styleUrls: ['./profile-chg-password.component.scss']
})
export class ProfileChgPasswordComponent implements OnInit {

  redirectDelay = 0;
  redirectEndPoint = '';
  showMessages: any = {};
  submitted = false;
  errors: string[] = [];
  user: any = {
    password: null,
    newPassword: null
  };

  showInfoCaptcha = true;
  passwordStrength: number;
  onloadShow: boolean;

  constructor(
      private _bottomSheetRef: MatBottomSheetRef<ProfileChgPasswordComponent>,
      public _profileService: ProfileService,
      @Inject(AUTH_OPTIONS) protected options = {},
      @Inject(AUTH_LAYOUT) protected layoutOptions = {}
  ) {
    this.redirectDelay = this.getConfigValue('forms.register.redirectDelay');
    this.redirectEndPoint = this.getConfigValue('forms.register.redirect_endpoint');
    this.showMessages = this.getConfigValue('forms.register.showMessages');
    this.passwordStrength = 0;
    this.onloadShow = true;
  }

  changeStrength($event) {
    this.passwordStrength = $event;
  }

  changePassword(): void {
    this.onloadShow = true;
    this._profileService.changePassword( this.user.password, this.user.newPassword)
        .then((results: any) => {
          this.onloadShow = false;
          this._bottomSheetRef.dismiss({type: 'success', message: 'Password has been changed!! check your email!!'} );
        }).catch(reason => {
          this._bottomSheetRef.dismiss({type: 'error', message: reason} );
          this.onloadShow = false;
    });
  }

  ngOnInit() {
    this.showInfoCaptcha = this.layoutOptions['recaptcha']['showInfo'];
    this.onloadShow = false;
  }

  cancel() {
    this._bottomSheetRef.dismiss({type: 'cancel', message: 'cancel'} );
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }
}
