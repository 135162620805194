import {elAssetType, elStatus} from '../../../API.service';
import {ContentType} from '../../../../models';

export class CourseAsset
{
    id: string;
    courseId?: string;
    assetId?: string;
    assetType?: string;

    title?: string;
    description?: string;
    timeAprox: string;
    order: number;
    nextStep: string;
    prevStep: string;

    starred: boolean;
    showAsOverview: boolean;
    important: boolean;

    presignedUrl?: any;
    posterFileUrl?: any;
    status: string;
    updatedAt?: string;
    createdAt?: string;
    course: any;
    topic: any;
    quiz: any;

    _version: number;
    /**
     * Constructor
     *
     * @param cmedia
     */
    constructor(cmedia?)
    {
        cmedia = cmedia || {};
        this.id = cmedia.id || null;
        this.courseId = cmedia.courseId  || null;
        this.assetId = cmedia.assetId || null;
        this.assetType = cmedia.assetType || elAssetType.Topic;
        this.title = cmedia.title || '';
        this.order = cmedia.order || 0;
        this.starred = cmedia.starred || false;
        this.showAsOverview = cmedia.showAsOverview || false;
        this.timeAprox = cmedia.timeAprox || 0;
        this.important = cmedia.important || false;
        this.nextStep = cmedia.nextStep || '';
        this.prevStep = cmedia.prevStep || '';
        this.status = cmedia.status || elStatus.new;
        this.presignedUrl = cmedia.presignedUrl || null;
        this.posterFileUrl = cmedia.posterFileUrl || null;
        this.updatedAt = cmedia.updatedAt || new Date().toISOString();
        this.createdAt = cmedia.createdAt || new Date().toISOString();

        this._version = cmedia._version || null;
    }
}


export class Content
{
    id: string;
    courseId?: string;
    contentType?: string;
    media?: string;

    sourceMedia?: string;
    fileName?: string;
    fileType?: string;
    fileSize?: string;
    transcoderJobId?:string;
    transcoderMeta?:any;
    updatedAt?: string;
    createdAt?: string;
    _version: number;
    /**
     * Constructor
     *
     * @param cmedia
     */
    constructor(cmedia?)
    {
        cmedia = cmedia || {};
        this.id = cmedia.id || null;
        this.courseId = cmedia.courseId  || null;
        this.sourceMedia = cmedia.sourceMedia || null;
        this.contentType = cmedia.contentType || ContentType.VIDEO;
        this.fileName = cmedia.fileName || '';
        this.transcoderJobId = cmedia.transcoderJobId || null;
        this.transcoderMeta = JSON.parse(cmedia.transcoderMeta) || null;
        this.fileType = cmedia.fileType || 0;
        this.fileSize = cmedia.fileSize || false;
        this.updatedAt = cmedia.updatedAt || new Date().toISOString();
        this.createdAt = cmedia.createdAt || new Date().toISOString();

        this._version = cmedia._version || null;
    }
}
