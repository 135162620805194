import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import {AUTH_LAYOUT, AUTH_OPTIONS} from '../../auth.options';
import {FuseProgressBarService} from '../../../../../@fuse/components/progress-bar/progress-bar.service';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import { Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {getDeepFromObject} from '../../helpers';
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
    selector     : 'mail-confirm',
    templateUrl  : './mail-confirm.component.html',
    styleUrls    : ['./mail-confirm.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class MailConfirmComponent implements OnInit, OnDestroy
{
    redirectDelay = 0;
    showMessages: any = {};
    submitted = false;
    user: any = {
        email : null,
        code: null
    };
    snackDuration: number;
    horizontalPosition: MatSnackBarHorizontalPosition = 'left';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    errors: string[] = [];
    messages: string[] = [];
    private _unsubscribeAll: Subject<any>;
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param _fuseProgressBarService
     * @param cd
     * @param router
     * @param authService
     * @param _snackBar
     * @param options
     * @param layoutOptions
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseProgressBarService: FuseProgressBarService,
        protected cd: ChangeDetectorRef,
        protected router: Router,
        public authService: AuthService,
        private _snackBar: MatSnackBar,
        @Inject(AUTH_OPTIONS) protected options = {},
        @Inject(AUTH_LAYOUT) protected layoutOptions = {},

    )
    {
        this._unsubscribeAll = new Subject();
        this.redirectDelay = this.getConfigValue('forms.confirmMail.redirectDelay');
        this.showMessages = this.getConfigValue('forms.confirmMail.showMessages');
        this.horizontalPosition = this.layoutOptions['snackbar']['horizontalPosition'];
        this.verticalPosition = this.layoutOptions['snackbar']['verticalPosition'];
        this.snackDuration = this.layoutOptions['snackbar']['duration'];
        // Configure the layout
        this._fuseConfigService.config = {
            layout: this.layoutOptions['layout']
        };
        const confirmEmail = this.authService.getUser('AUTH_CONFIRM');
        this.user.email = confirmEmail['email'];
    }

    ngOnInit(): void {
        this._fuseProgressBarService.setMode('query');
        if (!this.user.email) {
            this.router.navigateByUrl('auth/register')
                .then(() => {
                    window.location.reload();
            });
        }

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    confirmation(): void {
        this._fuseProgressBarService.show();
        this.errors = this.messages = [];
        this.submitted = true;
        this.authService.confirmSignUp(this.user)
            .then((data: any) => {
                setTimeout (() => {
                    this.submitted = false;
                    this._fuseProgressBarService.hide();
                    this.cd.detectChanges();
                    this.authService.removeKey('AUTH_CONFIRM');
                    this.router.navigate(['auth/login']);
                },  this.snackDuration);
            })
            .catch((error: any) => {
                this._snackBar.open(error.message, 'End now', {
                    duration: this.snackDuration,
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                });
                setTimeout (() => {
                    this.submitted = false;
                    this._fuseProgressBarService.hide();
                },  this.snackDuration);
                // @ts-ignore
                if (error.code !== 'CodeMismatchException') {
                    this.authService.removeKey('AUTH_CONFIRM');
                    this.router.navigate(['auth/login']);
                }
            });
    }

    resendCode(): void  {
        this.authService.resendSignUp(this.user).then((data) => {
            this._snackBar.open('New Code has been Sent! Check your email!!', 'Ok', {
                duration: this.snackDuration,
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
            });
        });
    }

    getConfigValue(key: string): any {
        return getDeepFromObject(this.options, key, null);
    }
}
