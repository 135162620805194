import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Subject} from 'rxjs';
import {MatBottomSheet} from '@angular/material';
import {NotifyListService} from '../notify-list.service';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {ComposeDialogComponent} from '../compose-dialog/compose-dialog.component';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-notify-main-sidebar',
  templateUrl: './notify-main-sidebar.component.html',
  styleUrls: ['./notify-main-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class NotifyMainSidebarComponent implements OnInit, OnDestroy {

  filters = [
    {
      id    : 2,
      handle: 'system',
      title : 'System',
      icon  : 'apps',
      color : 'amber-fg'
    },
    {
      id    : 1,
      handle: 'information',
      title : 'Information',
      icon  : 'info',
      color : 'red-fg'
    },
    {
      id    : 0,
      handle: 'reset',
      title : 'All',
      icon  : 'refresh',
      color : 'red-fg'
    }

  ];

  typeFiler: string;
  dialogRef: any;

  private _unsubscribeAll: Subject<any>;

  constructor(
    private _notifyService: NotifyListService,
    private _bottomSheet: MatBottomSheet
  ) {
    this._unsubscribeAll = new Subject();
    this.typeFiler = 'reset';
  }

  ngOnInit() {}

  ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  filterSelected(handle: string) {
    this.typeFiler = handle;
    if (handle === 'reset') {
      handle = null;
    }
    this._notifyService.onFilterChanged.next(handle);
  }
  /**
   * Compose dialog
   */
  composeDialog(): void {
    this.dialogRef = this._bottomSheet.open(ComposeDialogComponent, {
      data: { panelClass: 'notification-compose-dialog' },
    });
    this.dialogRef.afterDismissed()
      .subscribe(response => {
        if ( !response )
        {
          return;
        }
        const actionType: string = response[0];
        const formData: FormGroup = response[1].value;
        this._notifyService.addData(formData).then((res) => {});
      });
  }

}
