import { Injectable, Inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';

// import {Logger} from '@aws-amplify/core';
import { QUIZ_QUESTION_MANAGER_OPTIONS } from './quiz-question.options';
import { APIService, CreateQuizQuestionInput, CreateQuestionAnswersInput, UpdateQuizQuestionInput, UpdateQuestionAnswersInput, ModelQuestionAnswersFilterInput, elStatus } from 'app/API.service';
import { SyncSettings } from '@fuse/services/sync-settings.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import * as S3 from 'aws-sdk/clients/s3';
import { getDeepFromObject } from 'app/main/auth/helpers';
import { QuestionContent } from './question.model';

@Injectable({
  providedIn: 'root'
})
export class QuizQuestionService {
  bucketTarget: any;
  routeParams: any;
  questionContent: any;

  private onContentChanged = new BehaviorSubject<any>(null);
  onContentChanged$ = this.onContentChanged.asObservable();

  constructor(
    @Inject(QUIZ_QUESTION_MANAGER_OPTIONS) protected options = {},
    private api: APIService,
    private syncSettings: SyncSettings,
    private _fuseProgressBarService: FuseProgressBarService,
  ) {
    this.bucketTarget = this.getConfigValue('S3BucketName');
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any{
    this.routeParams = route.params;
    if (!this.routeParams.id || !this.routeParams.courseAssetId) {
      return false;
    }
    return new Promise((resolve, reject) => {
        Promise.all([
          this.getQuestionContent(this.routeParams.id, this.routeParams.courseAssetId)
        ]).then(() => {
            resolve();
          },
          reject
        );
    });
  }

  getQuestionContent(questionId: string, courseAssetId: string): Promise<any> {
    console.log("===== QUESTION CONTENT ===== ");
    this._fuseProgressBarService.show();
    let resResultAsset;
    return new Promise((resolve, reject) => {
      if(questionId === 'new'){
        this.api.GetCourseAssets(courseAssetId)
          .then((resultAsset) => {
            this.questionContent = new QuestionContent();
            this.questionContent.asset = resultAsset;
            this.onContentChanged.next(this.questionContent);
            resolve(resultAsset);
          }).catch(err => {
            this.catchError(err);
            reject(err);
          });
      }else{
        this.api.GetQuizQuestion(questionId)
          .then((details) => {
            // console.log("quizquestion details --=> ", details);
            this.questionContent = details;
            return this.api.GetCourseAssets(courseAssetId)
          })
          .then((resultAsset) => {
            this.questionContent.asset = resultAsset;
            resResultAsset = resultAsset;

            let filter: ModelQuestionAnswersFilterInput;
            filter = {
              quizQuestionId: {eq: this.questionContent.id},
              status: {eq: elStatus.active}
            };
            let pageSize = 999;
            let nextToken = null;
            return this.api.ListQuestionAnswerss(filter, pageSize, nextToken);
          })
          .then(listQuestionAnswers => {
            // console.log("ListQuestionAnswers --> ",listQuestionAnswers);
            this.questionContent.questionAnswers.items = listQuestionAnswers.items;
            this.onContentChanged.next(this.questionContent);
            this._fuseProgressBarService.hide();
            resolve(resResultAsset);
          })
          .catch((err) => {
            this.catchError(err);
            reject(err);
          });
      }
    });
  }

  addQuizQuestion(newQuizQuestion: any): Promise<any> {
    const dateAt = new Date().toISOString();
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      const quizQuestionInput: CreateQuizQuestionInput = {
        quizContentId: newQuizQuestion.quizContentId,
        title: newQuizQuestion.question,
        qType: newQuizQuestion.qType,
        labelCorrectMessage: newQuizQuestion.correctMessage,
        labelIncorrectMessage: newQuizQuestion.incorrectMessage,
        createdAt: dateAt,
        updatedAt: dateAt,
        quizQuestionQuizId: newQuizQuestion.quizQuestionQuizId
      };
      this.api.CreateQuizQuestion(quizQuestionInput)
        .then(result => {
          this._fuseProgressBarService.hide();
          // console.log("addquizquestion ===> ", result);
          this.onContentChanged.next(result);
          resolve(result);
        })
        .catch((err) => {
            this.catchError(err);
            reject(err);
        });
    });
  }

  updateQuizQuestion(quizQuestion: any): Promise<any>{
    const dateAt = new Date().toISOString();
    this._fuseProgressBarService.show();
    let questionContent;
    console.log("===== UPDATEQUESTION =====");
    return new Promise((resolve, reject) => {
      const updateQuizQuestionInput: UpdateQuizQuestionInput = {
        id: quizQuestion.id,
        quizContentId: quizQuestion.quizContentId,
        title: quizQuestion.question,
        qType: quizQuestion.qType,
        labelCorrectMessage: quizQuestion.correctMessage,
        labelIncorrectMessage: quizQuestion.incorrectMessage,
        updatedAt: dateAt,
        sourceMedia: quizQuestion.sourceMedia,
        fileName: quizQuestion.fileName,
        fileType: quizQuestion.fileType,
        fileSize: quizQuestion.fileSize,
        quizQuestionQuizId: quizQuestion.quizQuestionQuizId,
        _version: quizQuestion._version
      };
      this.api.UpdateQuizQuestion(updateQuizQuestionInput)
        .then(result => {
          questionContent = result;

          let filter: ModelQuestionAnswersFilterInput;
          filter = {
            quizQuestionId: {eq: this.questionContent.id},
            status: {eq: elStatus.active}
          };
          let pageSize = 999;
          let nextToken = null;
          return this.api.ListQuestionAnswerss(filter, pageSize, nextToken);
        })
        .then(listQuestionAnswers => {
          questionContent.questionAnswers.items = listQuestionAnswers.items;
          console.log("quizQuestion --> ", questionContent);
          this._fuseProgressBarService.hide();
          this.onContentChanged.next(questionContent);
          resolve(questionContent);
        })
        .catch((err) => {
            this.catchError(err);
            reject(err);
        });
    });
  }

  uploadQuestionFile(fileUpload: File, quizQuestionId: string, courseAssetId: string): Promise<any> {
    console.log("===== UPLOADQUESTIONFILE =====");
    return new Promise((resolve, reject) => {
      const bPath = `${this.bucketTarget}/quiz/${courseAssetId}/${quizQuestionId}`;
      const fileName = new Date().getTime();
      const params = {
        Bucket: bPath,
        Key: fileName+"",
        ACL: 'authenticated-read',
        Body: fileUpload,
        ContentType: fileUpload.type
      };
      const self = this;
      this._fuseProgressBarService.setMode('determinate');
      this._fuseProgressBarService.setValue(0);
      this._fuseProgressBarService.show();
      const newConn = this.syncS3();
      newConn.upload(params)
      .on('httpUploadProgress', (evt) => {
        const perUp = Math.round((evt.loaded * 100) / evt.total);
        self._fuseProgressBarService.setValue(perUp);
      }).send((err, data) => {
        self._fuseProgressBarService.hide();
        self._fuseProgressBarService.setValue(0);
        if (err) {
          console.log(err);
          reject(err);
        } else {
          resolve(data.Location);
        }
      });
    });
  }

  addQuestionAnswer(newQuestionAnswer: any): Promise<any> {
    this._fuseProgressBarService.show();
    const dateAt = new Date().toISOString();
    console.log("=== AddQuestionAnswer ===");
    let answer, questionContent;
    return new Promise((resolve, reject) => {
      const questionAnswerInput: CreateQuestionAnswersInput = {
        // quizQuestionId: newQuestionAnswer.quizQuestionId,
        quizQuestionId: newQuestionAnswer.questionAnswersQuestionId,
        answer: newQuestionAnswer.answer,
        answerFeedBack: newQuestionAnswer.answerFeedBack,
        isCorrect: newQuestionAnswer.isCorrect,
        qType: newQuestionAnswer.qType,
        createdAt: dateAt,
        updatedAt: dateAt,
        status: newQuestionAnswer.status,
        questionAnswersQuestionId: newQuestionAnswer.questionAnswersQuestionId
      };
      this.api.CreateQuestionAnswers(questionAnswerInput)
        .then(result => {
          answer = result;
          return this.api.GetQuizQuestion(result.question.id);
        })
        .then(res => {
          questionContent = res;

          let filter: ModelQuestionAnswersFilterInput;
          filter = {
            quizQuestionId: {eq: questionContent.id},
            status: {eq: elStatus.active}
          };
          let pageSize = 999;
          let nextToken = null;
          return this.api.ListQuestionAnswerss(filter, pageSize, nextToken);
        })
        .then(listQuestionAnswers => {
          questionContent.questionAnswers.items = listQuestionAnswers.items;
          // console.log("addquestionanswer -->  ",questionContent);
          this.onContentChanged.next(questionContent);
          this._fuseProgressBarService.hide();
          resolve(answer);
        })
        .catch((err) => {
          this.catchError(err);
          reject(err);
      });
    })
  }

  updateQuestionAnswer(questionAnswer: any): Promise<any> {
    console.log("=== UpdateQuestionAnswer ===");
    this._fuseProgressBarService.show();
    const dateAt = new Date().toISOString();
    let answer, questionContent;
    return new Promise((resolve, reject) => {
      const questionAnswerInput: UpdateQuestionAnswersInput = {
        id: questionAnswer.id,
        // quizQuestionId: questionAnswer.quizQuestionId,
        quizQuestionId: questionAnswer.questionAnswersQuestionId,
        answer: questionAnswer.answer,
        answerFeedBack: questionAnswer.answerFeedBack,
        isCorrect: questionAnswer.isCorrect,
        qType: questionAnswer.qType,
        updatedAt: dateAt,
        sourceMedia: questionAnswer.sourceMedia,
        fileName: questionAnswer.fileName,
        fileType: questionAnswer.fileType,
        fileSize: questionAnswer.fileSize,
        status: questionAnswer.status,
        questionAnswersQuestionId: questionAnswer.questionAnswersQuestionId,
        _version: questionAnswer._version
      };
      this.api.UpdateQuestionAnswers(questionAnswerInput)
        .then(result => {
          answer = result;
          return this.api.GetQuizQuestion(result.question.id);
        })
        .then(res => {
          questionContent = res;

          let filter: ModelQuestionAnswersFilterInput;
          filter = {
            quizQuestionId: {eq: questionContent.id},
            status: {eq: elStatus.active}
          };
          let pageSize = 999;
          let nextToken = null;
          return this.api.ListQuestionAnswerss(filter, pageSize, nextToken);
        })
        .then(listQuestionAnswers => {
          questionContent.questionAnswers.items = listQuestionAnswers.items;
          console.log("updatequestionanswer -->  ",questionContent);
          this.onContentChanged.next(questionContent);
          this._fuseProgressBarService.hide();
          resolve(answer);
        })
        .catch((err) => {
          this.catchError(err);
          reject(err);
      });
    })
  }

  uploadAnswerFile(fileUpload: File, courseAssetId: string, quizQuestionId: string, answerId: string): Promise<any> {
    console.log("===== UPLOADANSWERFILE =====");
    return new Promise((resolve, reject) => {
      const bPath = `${this.bucketTarget}/quiz/${courseAssetId}/${quizQuestionId}/${answerId}`;
      const fileName = new Date().getTime();
      const params = {
        Bucket: bPath,
        Key: fileName+"",
        ACL: 'authenticated-read',
        Body: fileUpload,
        ContentType: fileUpload.type
      };
      const self = this;
      this._fuseProgressBarService.setMode('determinate');
      this._fuseProgressBarService.setValue(0);
      this._fuseProgressBarService.show();
      const newConn = this.syncS3();
      newConn.upload(params)
      .on('httpUploadProgress', (evt) => {
        const perUp = Math.round((evt.loaded * 100) / evt.total);
        self._fuseProgressBarService.setValue(perUp);
      }).send((err, data) => {
        self._fuseProgressBarService.hide();
        self._fuseProgressBarService.setValue(0);
        if (err) {
          console.log(err);
          reject(err);
        } else {
          resolve(data.Location);
        }
      });
    });
  }

  syncS3(): any {
    return new S3({
      accessKeyId: this.getConfigValue('accessKeyId'),
      secretAccessKey: this.getConfigValue('secretAccessKey'),
      region: this.getConfigValue('region')
    });
  }

  // question image
  getPresignedFile(fileName: string, quizQuestionId: string, courseAssetId: string): Promise<any> {
    const bPath = `${this.bucketTarget}/quiz/${courseAssetId}/${quizQuestionId}`;
    const newConn = this.syncS3();
    const params = {
      Bucket: bPath,
      Key: fileName,
      Expires: 3600
    };
    return new Promise((resolve, reject) => {
      newConn.getSignedUrl( 'getObject', params, (err, urlLink) => {
        if (err) {
          console.log('There was an error Downloading your file: ', err);
          reject(err);
        } else {
          resolve(urlLink);
        }
      });
    });
  }

  // choice image, answer image
  getPresignedFileAnswer(fileName: string, courseAssetId: string, quizQuestionId: string, answerId: string){
    const bPath = `${this.bucketTarget}/quiz/${courseAssetId}/${quizQuestionId}/${answerId}`;
    const newConn = this.syncS3();
    const params = {
      Bucket: bPath,
      Key: fileName,
      Expires: 3600
    };
    return new Promise((resolve, reject) => {
      newConn.getSignedUrl( 'getObject', params, (err, urlLink) => {
        if (err) {
          console.log('There was an error Downloading your file: ', err);
          reject(err);
        } else {
          resolve(urlLink);
        }
      });
    });
  }
  

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }

  catchError(err: any) {
    throw new Error("Method not implemented.");
  }

}