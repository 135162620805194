import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {Router} from '@angular/router';
import * as _ from "lodash";

@Component({
  selector: 'academy-list-sidebar',
  templateUrl: './academy-list-sidebar.component.html',
  styleUrls: ['./academy-list-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class AcademyListSidebarComponent implements OnInit, OnChanges {

  @Input()
  courseDetails: any;

  @Input()
  courseContents: any[];

  @Input()
  currentStep: any;

  @Input()
  assetSubs: any;

  completedAssets: any = [];
  perProgress: any;

  constructor(
    private router: Router
  ) {}

  ngOnInit() {
    this.perProgress = 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ( changes.courseDetails) {
      const assetSubs = changes.courseDetails.currentValue.assetsSubs.items;
      const courseAssets = changes.courseDetails.currentValue.course.assets.items;
      this.courseContents = this.courseProgress(assetSubs, courseAssets);
      console.log(this.courseContents);
      this.perProgress = ((this.completedAssets.length/this.courseContents.length) * 100).toFixed(1);
    }
    if ( changes.assetSubs) {
      const search = changes.assetSubs.currentValue;
      console.log(changes.assetSubs.currentValue)
      if (search && search.assetId) {
        const found =  _.find(this.courseContents , {id: search.assetId});
        if (found) {
          const order = found.order;
          if (search.status === 'Completed' && this.courseContents[order].subsStatus !== 'Completed') {
            this.courseContents[order].subsStatus = search.status;
            this.completedAssets.push(found)
          }
        }
      }
    }
  }
  /**
   * Go to step
   *
   * @param step
   */
  gotoStep(step): void {
    if (step && step.assetType==='Topic') {
      this.router.navigate([`my/courses/${this.courseDetails.id}/cl/${step.id}`]);
      // this.router.navigate([`cl/${step.id}`], {relativeTo: this.route});
    } else {
      this.router.navigate([`my/courses/${this.courseDetails.id}/qz/${step.id}`]);
    }
  }

  courseProgress(
      assetSubsArray: any[],
      courseContents: any[]
  ): any[] {
    let topicProgressArray = [];
    this.completedAssets = [];
    courseContents.forEach(topic => {
      const found = _.find(assetSubsArray , {assetId: topic.id});
      topic['subsStatus'] = null;
      if (found) {
          topic['subsStatus'] = found.status;
      }
      if (topic['subsStatus'] ==='Completed') {
        this.completedAssets.push(topic)
      }
      topicProgressArray.push(topic);
    })
    return topicProgressArray;
  }


}
