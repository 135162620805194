import { Component, OnInit, Input } from '@angular/core';
import { ProfileService } from '../../profile.service';

@Component({
  selector: 'progress-cell',
  templateUrl: './progress-cell.component.html',
  styleUrls: ['./progress-cell.component.scss']
})
export class ProgressCellComponent implements OnInit {

  @Input('courseId') courseId: string;
  @Input('userId') userId: string;
  
  percentage = 0;

  constructor(
    private _profileService: ProfileService
  ) { }

  ngOnInit() {
    this._profileService.CalculateProgress(this.courseId, this.userId)
      .then((res: number) => {
        this.percentage = res;
      })
  }

}
