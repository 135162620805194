import {
  Component,
  ElementRef,
  EventEmitter,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import videojs from 'video.js';

@Component({
  selector: 'jw-video-player',
  templateUrl: './jw-video-player.component.html',
  styleUrls: ['./jw-video-player.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class JwVideoPlayerComponent implements OnInit,  OnDestroy, OnChanges {
  @ViewChild('target', {static: true}) target: ElementRef;
  @Input() options: {
    fluid: boolean,
    aspectRatio: string,
    autoplay: boolean,
    poster: any,
    playbackRates: any[],
    sources: {
      src: string,
      type: string,
    }[],
  };
  @Output() playProgress: EventEmitter<any> = new EventEmitter<any>();
  @Output() metadata: EventEmitter<any> = new EventEmitter<any>();

  player: videojs.Player;

  constructor() { }

  ngOnInit() {
    console.log('on init --------------------------');
    this.setVidoPlayer(this.options);
  }

  ngOnChanges(changes: SimpleChanges): void {
      console.log('on changes');
      console.log(changes);
    this.setVidoPlayer(changes.options.currentValue);
  }

  setVidoPlayer(options: any) {
    const self = this;
    this.player = null;
    this.player = videojs(this.target.nativeElement, options, function onPlayerReady() {
      self.playProgress.emit({event: 'ready' , progress: 0});
    });
    this.player.on('play', (res) => {
      self.playProgress.emit({event: 'play' , progress: this.player.currentTime()});
      self.metadata.emit({event: 'bufferedPercent' , data: this.player.bufferedPercent()});
      self.metadata.emit({event: 'duration' , data: self.player.duration()});
    });
    this.player.on('progress', (res) => {
      self.playProgress.emit({event: 'progress' , progress: this.player.currentTime()});
      self.metadata.emit({event: 'bufferedPercent' , data: this.player.bufferedPercent()});
      self.metadata.emit({event: 'duration' , data: self.player.duration()});
    });
    this.player.on('ended', (res) => {
      self.playProgress.emit({event: 'ended' , progress: this.player.currentTime()});
    });
  }
  ngOnDestroy() {
    // destroy player
    if (this.player) {
      this.player.dispose();
    }
  }
}
