import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {CourseCatalogItemService} from '../course-catalog-item.service';
import {Subject} from 'rxjs';
import {AuthService} from "../../auth/services/auth.service";
import {APIService, ModelSortDirection} from "../../../API.service";
import * as _ from 'lodash';
@Component({
  selector: 'catalog-course-detail',
  templateUrl: './catalog-course-detail.component.html',
  styleUrls: ['./catalog-course-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogCourseDetailComponent implements OnInit, OnDestroy, AfterViewInit {

  course: any;
  currentUserId: string;
  displayProgressSpinner: boolean = false;
  userSubs: any;
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _courseService: CourseCatalogItemService,
    private ref: ChangeDetectorRef,
    private _auth: AuthService,
    private _api: APIService,
  ) {
    this._unsubscribeAll = new Subject();
    this.userSubs = null;
  }

  ngOnInit() {
    this._auth.loggedUser$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe( userInfo => {
            this.currentUserId = userInfo.username;
        })

    this.displayProgressSpinner = true;
    this._courseService.onCourseChanged$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(course => {
          if ( course ) {
            this.course = course;
            if (this.course.assets.items.length > 0) {
              this.course.assets.items = _.orderBy(this.course.assets.items, 'order', 'asc');
            }
            console.log(this.course);
            this.getUserSubs(this.course.id, this.currentUserId)
                .then(res => {
                  this.userSubs = res && res.length > 0 ? res[0] : null;
                  this.ref.detectChanges();
                });
          }
        });
  }

  ngAfterViewInit() : void {}

  /**
   * On destroy
   */
  ngOnDestroy(): void
  {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private getUserSubs(courseId: string, userId: string): Promise<any[]> {
    this.displayProgressSpinner = true;
    return new Promise((resolve, reject) => {
      this._api.SubsCourseByIds(
          userId,
          { eq : courseId},
          ModelSortDirection.DESC,
          null,
          1
      ).then((result) => {
        this.displayProgressSpinner = false;
        resolve(result.items);
      }).catch((err) => {
        this.displayProgressSpinner = false;
        resolve([]);
      });
    });
  }
}
