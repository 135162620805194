import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'momentUserAgo'
})
export class MomentUserAgoPipe implements PipeTransform {

  transform(value: any): any {
    const date = moment(value);
    return date.calendar(null, {
      sameDay: 'LT HH:mm',
      lastDay: 'MMM D LT HH:mm',
      lastWeek: 'MMM D LT HH:mm',
      sameElse: 'l HH:mm'
    });
  }

}
