import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {UserSettingsModel} from '../../../user-settings.model';
import {MatSnackBar} from '@angular/material';
import {SyncMsgs} from '../../../../@fuse/services/sync-msgs.service';
import {SyncSettings} from "../../../../@fuse/services/sync-settings.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {AuthService} from "../../../main/auth/services/auth.service";

interface PageSizes {
  value: number;
  viewValue: string;
}

interface NotiTypes {
  value: string;
  viewValue: string;
}

@Component({
    selector     : 'quick-panel',
    templateUrl  : './quick-panel.component.html',
    styleUrls    : ['./quick-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class QuickPanelComponent implements OnInit, OnDestroy {
  settingsForm: FormGroup;

  settings: UserSettingsModel;
  msgs: any[];
  userMsgs: any[];

  pageSizes: PageSizes[] = [
    {value: 5, viewValue: '5'},
    {value: 10, viewValue: '10'},
    {value: 20, viewValue: '20'},
    {value: 30, viewValue: '30'},
    {value: 50, viewValue: '50'},
    {value: 100, viewValue: '100'}
  ];

  notiTypes: NotiTypes[] = [
    {value: 'NONE', viewValue: 'None'},
    {value: 'EMAIL', viewValue: 'Email'},
    {value: 'PHONE', viewValue: 'SMS'}
  ];
  private _onDestroy: Subject<any>;
    /**
     * Constructor
     */
    constructor(
      private _formBuilder: FormBuilder,
      private _syncMsgs: SyncMsgs,
      private _syncSettings: SyncSettings,
      private _auth: AuthService,
      private _matSnackBar: MatSnackBar
    ) {
      this._onDestroy = new Subject();
    }

    ngOnInit() {

      this._auth.onSettingsChanged$
          .pipe(takeUntil(this._onDestroy))
          .subscribe(setts => {
            this.settings = setts;
            this.settingsForm = this.createEventForm();
          });

      this._syncMsgs.onUserNotifyChanged$
          .pipe(takeUntil(this._onDestroy))
          .subscribe(msgs => {
            this.userMsgs = msgs;
          });

      this._syncMsgs.onNotifyChanged$
          .pipe(takeUntil(this._onDestroy))
          .subscribe(msgs => {
            this.msgs = msgs;
          });
    }

    ngOnDestroy() {
      this._onDestroy.next();
      this._onDestroy.complete();
    }


    /**
     *
     * @returns {FormGroup}
     */
    createEventForm(): FormGroup {
      return this._formBuilder.group({
        id: [this.settings.id],
        userId: [this.settings.userId],
        pageSize : [this.settings.pageSize],
        notiType : [this.settings.notiType],
        _version : [this.settings._version],
      });
    }

    setSettings() {
      const data = this.settingsForm.getRawValue();
      if (data.id === null ) {
        this._syncSettings.addData(data)
          .then((res) => {
            this.eventMesssage('Settings Successfully Added!', 'success');
          }).catch((err) => {
            this.eventMesssage('Something went wrong please try again!', 'error');
          });
      } else {
        this._syncSettings.saveData(data)
          .then((res) => {
            this.eventMesssage('Settings Successfully Saved!', 'success');
          }).catch((err) => {
            this.eventMesssage('Something went wrong please try again!', 'error');
        });
      }
    }

    refresh() {
      this._syncSettings.refresh();
    }

  private eventMesssage( msg: string, typeMsg: string ) {
    const customMsg = typeMsg === 'error' ? 'OOPS:' + msg : 'Success!!' + msg;
    this._matSnackBar.open(customMsg, 'OK', {
      verticalPosition: 'top',
      duration        : 2000
    });
  }
}
