import {MatFormFieldModule} from '@angular/material/form-field';
import {RouterModule, Routes} from '@angular/router';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {MatInputModule} from '@angular/material/input';
import {FuseSearchBarModule, FuseSidebarModule, JwVideoPlayerModule} from '../../../../@fuse/components';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatRippleModule} from '@angular/material/core';
import {CourseListComponent} from './course-list/course-list.component';
import {CourseComponent} from './course/course.component';
import {CourseListService} from './course-list.service';
import {CourseService} from './course.service';
import {COURSES_MANAGER_OPTIONS, CoursesOptions} from './courses.options';
import {ImgCropModule} from '../../../core/img-crop/img-crop.module';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSelectSearchModule} from '../../../core/mat-select-search/mat-select-search.module';
import {AuthGuard} from '../../auth/auth.guard';
import {NgxDnDModule} from '@swimlane/ngx-dnd';
import {ContentItemListComponent} from './course/content-item-list/content-item-list.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { CourseGeneralComponent } from './course/components/course-general/course-general.component';
import { CourseCategorySidebarComponent } from './course/components/course-category-sidebar/course-category-sidebar.component';
import {
  _MatMenuDirectivesModule,
  MatCardModule,
  MatDatepickerModule,
  MatDialogModule,
  MatSlideToggleModule,
  MatToolbarModule
} from '@angular/material';
import {CoursesCategoryService} from './courses-category.service';
import {CommonModule} from '@angular/common';
import {ExtendedModule, FlexModule} from '@angular/flex-layout';
import { CourseClassesComponent } from './course/components/course-classes/course-classes.component';
import { UploadResourceComponent } from './course/components/upload-resource/upload-resource.component';
import { CourseImgResourcesComponent } from './course/components/course-img-resources/course-img-resources.component';
import { CourseResourcesComponent } from './course/components/course-resources/course-resources.component';
import {CoursesResourcesService} from './courses-resources.service';
import { MatFileUploadModule} from 'mat-file-upload';
import {EditCourseFileResourceComponent} from './course/components/edit-course-file-resource/edit-course-file-resource.component';
import { EditCourseOtherResourceComponent } from './course/components/edit-course-other-resource/edit-course-other-resource.component';
import { AdminCourseDetailsComponent } from './course/components/admin-course-details/admin-course-details.component';
import {NgSelectModule} from "@ng-select/ng-select";
import { AdminCourseVideoOverviewComponent } from './course/components/admin-course-video-overview/admin-course-video-overview.component';
import { AdminCourseVideoUploadComponent } from './course/components/admin-course-video-upload/admin-course-video-upload.component';
import { AdminCourseUploadDetailsComponent } from './course/components/admin-course-upload-details/admin-course-upload-details.component';
import {MatVideoModule} from "mat-video";
import { CourseDashboardComponent } from './course/components/course-dashboard/course-dashboard.component';
import {NgxChartsModule} from "@swimlane/ngx-charts";
import {ContentLoaderModule} from "../../../../core/loaders/content-loader.module";
const routes: Routes = [
    {
        path     : 'courses',
        component: CourseListComponent,
        resolve  : {
            data: CourseListService
        },
        canActivate: [AuthGuard]
    },
    {
        path     : 'courses/:id',
        component: CourseComponent,
        resolve  : {
            data: CourseService
        },
        canActivate: [AuthGuard]
    }
];

@NgModule({
    declarations: [
        CourseCategorySidebarComponent,
        CourseListComponent,
        CourseComponent,
        ContentItemListComponent,
        CourseGeneralComponent,
        CourseClassesComponent,
        UploadResourceComponent,
        CourseImgResourcesComponent,
        CourseResourcesComponent,
        EditCourseFileResourceComponent,
        EditCourseOtherResourceComponent,
        AdminCourseDetailsComponent,
        AdminCourseVideoOverviewComponent,
        AdminCourseVideoUploadComponent,
        AdminCourseUploadDetailsComponent,
        CourseDashboardComponent,
    ],
    exports: [
        ContentItemListComponent,
        CourseCategorySidebarComponent
    ],
    imports: [
        CommonModule,
        MatTableModule,
        MatSortModule,
        MatIconModule,
        MatInputModule,
        MatRippleModule,
        RouterModule,
        FlexModule,
        ExtendedModule,
        MatButtonModule,
        FuseSharedModule,
        RouterModule.forChild(routes),
        MatTabsModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatToolbarModule,
        MatSelectModule,
        MatSelectSearchModule,
        MatGridListModule,
        MatProgressBarModule,
        ImgCropModule,
        MatCardModule,
        MatDialogModule,
        _MatMenuDirectivesModule,
        MatMenuModule,
        NgxDnDModule,
        MatCheckboxModule,
        DragDropModule,
        FuseSidebarModule,
        MatToolbarModule,
        FuseSearchBarModule,
        MatSlideToggleModule,
        MatFileUploadModule,
        NgSelectModule,
        JwVideoPlayerModule,
        MatVideoModule,
        NgxChartsModule,
        ContentLoaderModule,
    ],
  entryComponents: [
      UploadResourceComponent,
      EditCourseFileResourceComponent,
      EditCourseOtherResourceComponent,
      AdminCourseUploadDetailsComponent,
      AdminCourseVideoUploadComponent
  ]
})
export class CoursesModule
{
    static forRoot(fmOptions?: CoursesOptions): ModuleWithProviders {
        return {
            ngModule: CoursesModule,
            providers: [
                { provide: COURSES_MANAGER_OPTIONS, useValue:  fmOptions},
                CourseListService,
                CourseService,
                CoursesCategoryService,
                CoursesResourcesService
            ],
        } as ModuleWithProviders;
    }
}
